import {
  IconMiniApprovedOvertime,
  IconMiniDoneOvertime,
  IconMiniRejectedOvertime,
  IconMiniRequestOvertime,
  IconMiniVerifiedOvertime,
} from "../../../assets/coreHr/overtime";

const dataOvertimeFooter = [
  {
    title: "Request",
    judul: "Permintaan",
    icon: <IconMiniRequestOvertime />,
  },
  {
    title: "Approved",
    judul: "Disetujui",
    icon: <IconMiniApprovedOvertime />,
  },
  {
    title: "Verified",
    judul: "Diverifikasi",
    icon: <IconMiniVerifiedOvertime />,
  },
  {
    title: "Done",
    judul: "Selesai",
    icon: <IconMiniDoneOvertime />,
  },
  {
    title: "Rejected",
    judul: "Ditolak",
    icon: <IconMiniRejectedOvertime />,
  },
];

export default dataOvertimeFooter;
