const DataTableAttendance = [
  {
    title: "Name Employee",
    judul: "Nama Karyawan",
    url: "name",
  },
  {
    title: "Date",
    judul: "Tanggal",
    url: "name",
  },
  {
    title: "Clock In",
    judul: "Jam Masuk",
    url: "name",
  },
  {
    title: "Clock Out",
    judul: "Jam Keluar",
    url: "name",
  },
  {
    title: "Late",
    judul: "Keterlambatan",
    url: "name",
  },
  {
    title: "Overtime",
    judul: "Lembur",
    url: "name",
  },
  {
    title: "Status",
    judul: "Status",
    url: "name",
  },
  {
    title: "Total",
    judul: "Total",
    url: "name",
  },
];

export default DataTableAttendance;
