import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { getLanguage } from '../../../../features/language';
import { getCompany } from '../../../../features/companies';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ButtonDetail,
  Card,
  Pagination,
  RowPerPage,
  Search,
} from '../../../atoms';
import { useEffect } from 'react';
import {
  getListAttendance,
  getListDepartments,
} from '../../../../configs/axios/apiPath';
import { DataTableAttendance } from '../../../../data/table/timesheet';
import TableComponent from '../../../molecules/table/mainTable';
import {
  AttendanceTbody,
  AttendanceThead,
} from '../../../molecules/table/tableTimesheet/attendance';
import dayjs from 'dayjs';
import DepartmentAutocomplete from '../departmentAutocomplete';
import CustomDatePicker from '../../../molecules/datepicker';
import {
  generateExportAttendance,
  getExportStatusAttendance,
} from '../../../../configs/axios/apiPath/fetchTimesheet/fetchTimesheetAttendance';

const TableAttendances = () => {
  let localData = JSON.parse(localStorage.getItem('cvSayaData'));
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [attendance, setattendance] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [order, setOrder] = useState('name');
  const [direction, setDirection] = useState('-');
  const [department, setDepartment] = useState(null);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [datePicker, setDatePicker] = useState(
    localData?.datePicker ? dayjs(localData?.datePicker) : dayjs()
  );

  const [isGenerateLoading, setIsGenerateLoading] = useState(false);
  const [filename, setFilename] = useState('');

  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);

  const handleNavigationClick = (url) => {
    navigate('/timesheet/attendance/list');
  };

  const handleButtonExportOrGenerateClick = async () => {
    const module = 'attendanceMonthly';
    if (datePicker && company) {
      const data = {
        company_id: company,
        module,
        start_date: datePicker.format('YYYY-MM-DD'),
        end_date: datePicker.format('YYYY-MM-DD'),
      };
      setIsGenerateLoading(true);
      try {
        const response = await generateExportAttendance(data);
        const filename = `${'Attendance Daily'}_${datePicker.format(
          'DD MMMM YYYY'
        )}.xlsx`;

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsGenerateLoading(false);
      } catch (error) {
        setIsGenerateLoading(false);
        console.error(error);
        if (error?.response?.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
      }
    }
  };

  const handleChangeDate = (value) => {
    setDatePicker(value);
    localData = {
      ...localData,
      datePicker: value.format('YYYY-MM-DD'),
    };
    localStorage.setItem('cvSayaData', JSON.stringify(localData));
  };

  const handleDepartmentChange = (value) => {
    if (value) {
      setDepartment(value.id);
      localData = { ...localData, departmentId: value.id };
      localStorage.setItem('cvSayaData', JSON.stringify(localData));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          page_size: 9999999,
          company_id: company,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListDepartments(params);

          const defaultDepartment = {
            id: null,
            name: 'ALL DEPARTMENT',
            icon_url: null,
            total_employee: 0,
            manager: null,
          }; //TODO: all department
          const newDepartments = [defaultDepartment, ...data?.departments];

          !department && setDepartment(newDepartments[0].id);
          setDepartmentOptions(newDepartments);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company]);

  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          company_id: company,
          department_id: department,
          date: datePicker.format('YYYY-MM-DD'),
          search: keyword,
          page_size: 4,
          page,
          sort: `${direction}${order}`,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListAttendance(params);
          setLastPage(data?.page_info?.last_page);
          setattendance(data?.presences);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [company, direction, order, keyword, datePicker, department, page]);

  return (
    <Card sx={{ marginTop: '20px' }}>
      <Box display={'flex'} flexDirection={'column'} p={'23px 22px'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          {/* --- Search --- */}
          <Search
            placeholder={
              lang === 'Id'
                ? 'Ketik disini untuk mencari'
                : 'Type here to search'
            }
            setSearch={(e) => setKeyword(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />

          <Box display={'flex'} alignItems={'center'} gap={'10px'}>
            {/* {select department} */}
            <DepartmentAutocomplete
              departmentOptions={departmentOptions}
              department={department}
              handleDepartmentChange={handleDepartmentChange}
            />

            {/* {select date} */}
            <CustomDatePicker
              datePicker={datePicker}
              handleChangeDate={handleChangeDate}
            />

            <Button
              variant="contained"
              size="small"
              disabled={isGenerateLoading}
              onClick={handleButtonExportOrGenerateClick}
            >
              Export
            </Button>
          </Box>
        </Box>

        {/* --- Table --- */}
        <Box sx={{ mt: '24px', mb: '16px' }}>
          <TableComponent
            isLoading={isLoading}
            tbody={attendance}
            TableHead={
              <AttendanceThead
                thead={DataTableAttendance}
                setOrder={setOrder}
                setDirection={setDirection}
                direction={direction}
              />
            }
            TableBody={
              <AttendanceTbody tbody={attendance} setData={setattendance} />
            }
            col={8}
          />
        </Box>

        <Box
          display={'flex'}
          width={'100%'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          {/* --- Navigate --- */}
          <Box display={'flex'} justifyContent={'start'}>
            <ButtonDetail onclick={() => handleNavigationClick()} />
          </Box>

          {/* --- Pagination --- */}
          <Box display={'flex'} justifyContent={'end'}>
            <RowPerPage
              setPageSize={setPageSize}
              pageSize={pageSize}
              setPage={setPage}
            />
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default TableAttendances;
