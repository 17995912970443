import { Box, IconButton, Typography } from "@mui/material";
import { Theme } from "../../../../../../configs/theme";
import React from "react";
import { IconFilterDown, IconFilterUp } from "../../../../../../assets/commons";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../../../features/language";

const ReimbursementThead = ({ thead }) => {
  const lang = useSelector(getLanguage);

  return (
    <thead
      style={{ color: "#51B15C", backgroundColor: "rgba(81, 177, 92, 0.06)" }}
    >
      <tr>
        {thead.map((item, idx) => (
          <th
            key={idx}
            style={{
              textAlign: "start",
              verticalAlign: "middle",
              padding: "10px",
            }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              p={"0px 0px"}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"12px"}
                lineHeight={"130%"}
                color={"#51b15c"}
                width={"auto"}
              >
                {lang === "Id" ? item.judul : item.title}
              </Typography>
            </Box>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default ReimbursementThead;
