const DataTableEmployeeFacillities = [
  {
    title: "Employee Name",
    judul: "Nama Karyawan",
    url: "employee_id",
  },
  {
    title: "Office Item",
    judul: "Item Kantor",
  },
  {
    title: "Start Date",
    judul: "Tanggal Mulai",
  },
  {
    title: "End Date",
    judul: "Tanggal Berakhir",
  },
  {
    title: "Action",
    judul: "Aksi",
  },
];

export default DataTableEmployeeFacillities;
