import { Card, Typography, Divider, Grid, Stack } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField, InputFile } from "../../../../components/atoms";
import { getLanguage } from "../../../../features/language";
import { useSelector } from "react-redux";
import { IconFile, IconPaper } from "../../../../assets/benefit/reimbursement";
import { IconDept } from "../../../../assets/recruitment/jobs";
import { IconEmployee } from "../../../../assets/coreHr/awards";
import {
  getListRequest,
  getListEmployeeType,
  postMessageCandidate,
  uploadVacancy,
  getListVacancy,
} from "../../../../configs/axios/apiPath";

import { useEffect, useState } from "react";
import { getCompany } from "../../../../features/companies";

import { useNavigate, useLocation, useParams } from "react-router-dom";
const RecruitmentJobsAdd = () => {
  const lang = useSelector(getLanguage);
  const navigate = useNavigate();
  const { candidateId, id } = useParams();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name"); // "value1"

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState();

  const handlePost = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await postMessageCandidate(
        { vacancy_id: id, candidate_id: candidateId },
        formData
      );
      navigate(`/recruitment/candidate/${id}`);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card sx={{ padding: "20px" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        marginBottom={"16px"}
        alignItems="center"
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"20px"}
          lineHeight={"27px"}
          letterSpacing={"0.01em"}
          color={"#171717"}
        >
          Send Message
        </Typography>
      </Stack>

      <Divider />
      <form onSubmit={handlePost}>
        <Grid container spacing={"16px"}>
          <Grid item xs={6}>
            <TextField
              label={lang !== "Id" ? "To" : "Tujuan"}
              fullWidth
              value={name}
              readOnly
              required
              type="text"
              size="small"
              variant="outlined"
              icon={<IconDept />}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <TextField
              label={lang !== "Id" ? "Subject" : "Subjek"}
              fullWidth
              required
              type="text"
              size="small"
              variant="outlined"
              value={formData?.request_by?.position.name}
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  subject: e.target.value,
                }));
              }}
              icon={<IconPaper />}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={12}>
            <TextField
              label={lang !== "Id" ? "Contents" : "Konten"}
              fullWidth
              required
              type="text"
              size="small"
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  message: e.target.value,
                }));
              }}
              variant="outlined"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          sx={{ marginTop: "30px" }}
          loading={isLoading}
        >
          {lang === "Id" ? "Simpan" : "Save"}
        </LoadingButton>
      </form>
    </Card>
  );
};

export default RecruitmentJobsAdd;
