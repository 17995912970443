import { API } from "../../../API";

// --- List Permission ---
export const getListPermission = (companyId, params) =>
	API.get(`companies/${companyId}/leave-permissions`, { params });
// export const getListPermission = (params) =>
//   API.get("leave-permissions", { params: params });

// --- Get Permission By Id ---
export const getPermissionById = (companyId, id) =>
	API.get(`companies/${companyId}/leave-permissions/${id}`);

// --- Update Permission By Id ---
export const updatePermissionById = (data, companyId, id) =>
	API.put(`companies/${companyId}/leave-permissions/${id}/status`, data);

// --- Delete Permission By Id ---
export const deletePermissionById = (companyId, id) =>
	API.delete(`companies/${companyId}/leave-permissions/${id}`);
