import React, { useEffect, useState } from "react";
import {
  Card,
  RowPerPage,
  Search,
  Pagination,
} from "../../../../components/atoms";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../features/language";
import { Button, Typography } from "@mui/material";
import SelectYear from "../../../../components/molecules/year";
import moment from "moment";
import TableComponent from "../../../../components/molecules/table/mainTable";
import {
  CompanyPolicyThead,
  CompanyPolicyTbody,
} from "../../../../components/molecules/table/tableOrganization/companyPolicy";
import { DataTableCompanyPolicy } from "../../../../data/table/organization";
import { IconPlus } from "../../../../assets/commons";
import { useNavigate } from "react-router-dom";
import { getCompany } from "../../../../features/companies";
import { getListCompanyPolicies } from "../../../../configs/axios/apiPath/fetchOrganization";

const CompanyPolicyDashboard = () => {
  const lang = useSelector(getLanguage);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [keyword, setKeyword] = useState("");

  const yearNow = moment().format("YYYY");
  const year = [];
  const [selectedYear, setSelectedYear] = useState(yearNow);

  for (let i = yearNow; i > yearNow - 10; i--) {
    year.push(i);
  }

  const [direction, setDirection] = useState("");
  const [companyPolicy, setCompanyPolicy] = useState([]);

  const navigate = useNavigate();

  const handleNavigateClick = (url) => {
    navigate(`/organization/company-policy/${url}`);
  };

  const company = useSelector(getCompany);

  useEffect(() => {
    if (company) {
      const fetchCompanyPolicies = async () => {
        const params = {
          company_id: company,
          search: keyword,
          page_size: pageSize,
          page: page,
          sort: "date",
          year: selectedYear,
        };
        setLoading(true);
        try {
          const {
            data: { data },
          } = await getListCompanyPolicies(params);
          if (data) {
            setCompanyPolicy(data?.company_policies);
            setLastPage(data?.page_info?.last_page);
          }
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setLoading(false);
        }
      };
      fetchCompanyPolicies();
    }
  }, [company, keyword, navigate, page, pageSize, selectedYear]);

  return (
    <>
      <Card
        sx={{
          mt: "10px",
        }}
      >
        <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Search
              placeholder={
                lang === "id"
                  ? "ketik disini untuk mencari"
                  : "type here to search"
              }
              page={page}
              setPage={(e) => setPage(e)}
              setSearch={(e) => setKeyword(e)}
            />

            <Box display={"flex"} alignItems={"center"}>
              {/** select year */}
              <SelectYear
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                year={year}
              />

              {/** add button */}
              <Button
                variant="contained"
                sx={{
                  borderRadius: "10px",
                  ml: "10px",
                }}
                onClick={() => handleNavigateClick("add")}
                disabled={loading}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <IconPlus />
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={600}
                    fontSize={"12px"}
                    lineHeight={"14px"}
                    color="primary.contrastText"
                    letterSpacing={"0.02em"}
                    marginLeft={"10px"}
                    textTransform={"none"}
                  >
                    {lang === "Id" ? "Tambah" : "Add"}
                  </Typography>
                </Box>
              </Button>
            </Box>
          </Box>

          {/** table component */}
          <Box sx={{ my: "20px" }}>
            <TableComponent
              isLoading={loading}
              tbody={companyPolicy}
              TableHead={
                <CompanyPolicyThead
                  direction={direction}
                  setOrder={""}
                  setDirection={setDirection}
                  thead={DataTableCompanyPolicy}
                />
              }
              TableBody={
                <CompanyPolicyTbody
                  tbody={companyPolicy}
                  setData={setCompanyPolicy}
                />
              }
              col={4}
            />
          </Box>
          {/* --- Pagination --- */}
          <Box display={"flex"} justifyContent={"end"}>
            <RowPerPage
              setPageSize={setPageSize}
              pageSize={pageSize}
              setPage={setPage}
            />
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default CompanyPolicyDashboard;
