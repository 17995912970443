import {
  deleteAnnouncementById,
  deleteAwardsById,
  deletePermissionById,
  deleteOvertimeById,
  deleteComplaintsById,
  deletePromotionById,
  deleteResignById,
  deleteShift,
  deleteWarningById,
  deleteDepartmentById,
  deleteReimbursementById,
  deleteOfficeFacillitiesById,
  deleteEmployeeFacillitiesById,
  deleteJourneyById,
  deleteLocationById,
  deleteEmployeesById,
  deleteCompanyPoliciesById,
  deleteRequestById,
  deleteVacancyById,
  putVacancyCandidateById,
  deleteCalendarById,
  deleteCorrectionById,
} from "../../../configs/axios/apiPath";
export const handleModalDeletePath = (title, companyId, id) => {
  switch (title) {
    case "Awards":
      return deleteAwardsById(id);
    case "Permission":
      return deletePermissionById(companyId, id);
    case "Overtime":
      return deleteOvertimeById(id);
    case "Resign":
      return deleteResignById(id);
    case "Promotion":
      return deletePromotionById(id);
    case "Warning":
      return deleteWarningById(id);
    case "Announcement":
      return deleteAnnouncementById(id);
    case "Complaint":
      return deleteComplaintsById(id);
    case "Shift":
      return deleteShift(id);
    case "Department":
      return deleteDepartmentById(id);
    case "Reimbursement":
      return deleteReimbursementById(id);
    case "Office Facillities":
      return deleteOfficeFacillitiesById(id);
    case "Employee Facillities":
      return deleteEmployeeFacillitiesById(id);
    case "location":
      return deleteLocationById(id);
    case "Employee":
      return deleteEmployeesById(id);
    case "company policy":
      return deleteCompanyPoliciesById(id);
    case "Journey":
      return deleteJourneyById(id);
    case "Request":
      return deleteRequestById(id);
    case "Vacancies":
      return deleteVacancyById(id);
    case "Candidate":
      return putVacancyCandidateById(id);
    case "Calendar":
      return deleteCalendarById(id);
    case "Attendance Correction":
      return deleteCorrectionById(id);

    default:
      break;
  }
};

export const handleClose = (onClose, setId) => {
  onClose();
  setId(0);
};
