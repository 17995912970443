import {
  IconEmployee,
  IconEmployeeLeave,
  IconNewEmployee,
} from '../../../assets/employee';

export const dataCard = [
  {
    title: 'Employee',
    icon: <IconEmployee />,
    description: '1401',
  },
  {
    title: 'New Employee',
    icon: <IconNewEmployee />,
    description: '12',
  },
  {
    title: 'Employees leave',
    icon: <IconEmployeeLeave />,
    description: '25',
  },
];
