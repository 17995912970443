import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

export default function HRCalendarIndex () {
  return (
    <Box mt="20px">
      <Outlet />
    </Box>
  );
};

