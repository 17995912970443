export default function formatNumber(number = 0) {
  let formattedNumber = number.toString();

  if (number >= 1000) {
    formattedNumber = (number / 1000).toFixed(3).replace(/\.?0+$/, "");
    formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    formattedNumber = formattedNumber.replace(".", ",");
    formattedNumber = formattedNumber.replace(",", ".");
    formattedNumber += "*";
  } else {
    formattedNumber = formattedNumber.replace(".", ",");
  }

  return formattedNumber;
}
