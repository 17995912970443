import * as React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

export default function CircularProgressWithLabel({ value, status }) {
  const handleStatus = () => {
    if (status === 'done') {
      return 'success';
    } else if (status === 'process') {
      return 'warning';
    } else if (status === 'delayed') {
      return 'info';
    } else if (status === 'canceled') {
      return 'error';
    }
  };

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        color={handleStatus()}
        value={value}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
