import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { setAuthToken } from "../../configs/axios";
import DataLogin from "../../data/dataLogin";
import { useDispatch } from "react-redux";
import { fetchListStickyMenu } from "../../features/navigation";

const ProtectedRoute = ({ page }) => {
  const [isLoading, setIsLoading] = useState(true);
  const userData = localStorage.getItem("cvSayaLogin");
  const dispatch = useDispatch();

  const token = DataLogin().token;

  useEffect(() => {
    setAuthToken(token);
  }, [token]);

  useEffect(() => {
    const checkIsLogin = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    };

    checkIsLogin();
  }, []);

  useEffect(() => {
    userData && dispatch(fetchListStickyMenu());
  }, [userData]);

  if (isLoading) {
    return (
      <Box textAlign={"center"} my={"30px"}>
        <CircularProgress color="success" />
      </Box>
    );
  }

  if (!userData) {
    return <Navigate to="/users" replace />;
  }

  return page;
};

export default ProtectedRoute;
