import { API } from '../../../API';

export const getListCandidates = (params) =>
  API.get('candidates', { params: params });

export const getSummaryCandidates = (params) =>
  API.get('candidates/summary', { params: params });

// --- Post Employees ---
export const postCandidates = (data) => API.post('candidates', data);

// --- Get Candidates By Id ---
export const getCandidatesById = (id, params) =>
  API.get(`candidates/${id}`, { params: params });

// --- Update Candidates By Id ---
export const updateCandidatesById = (data, id) =>
  API.put(`candidates/${id}`, data);

// --- Delete Candidates By Id ---
export const deleteCandidatesById = (id) => API.delete(`candidates/${id}`);
