import { createSlice } from '@reduxjs/toolkit';

const companySlice = createSlice({
  name: 'company',
  initialState: {
    companies: '',
  },
  reducers: {
    setCompany: (state, action) => {
      state.companies = action.payload;
    },
  },
});

export const { setCompany } = companySlice.actions;
export const getCompany = (state) => state.company.companies;

export default companySlice.reducer;
