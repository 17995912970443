import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { Modals } from "../../../../components/atoms";
import {
  SelectCity,
  SelectProvince,
  SelectSubDistrict,
  SelectVillage,
} from "../../../../components/molecules/select";
import { IconLocationProvince } from "../../../../assets/department";

export default function AddressModal({
  isOpen,
  onClose,
  isLoading,
  currentData,
  setCurrentData,
  handleSubmit,
  provinces,
  setProvinces,
  dataProvinces,
  inputProvince,
  setInputProvince,
  city,
  setCity,
  dataCity,
  inputCity,
  setInputCity,
  SubDistrict,
  setSubDistrict,
  dataSubDistrict,
  inputSubDistrict,
  setInputSubDistrict,
  villages,
  setVillages,
  dataVillages,
  inputVillages,
  setInputVillages,
  loadingAddress,
}) {
  const FormLabel = ({ label }) => (
    <InputLabel
      style={{
        fontFamily: "sans-serif",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "22px",
        letterSpacing: "0.02em",
        color: "#171717",
        marginBottom: "10px",
      }}
    >
      {label}
    </InputLabel>
  );

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "background.paper",
        }}
      >
        <Card>
          <Box p={"30px"}>
            {!isLoading ? (
              <Box mt={"20px"} component={"form"} noValidate autoComplete="off">
                <Grid container columns={2} spacing={2}>
                  <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                    <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                      <FormLabel label="Province *" />
                      <Box>
                        <SelectProvince
                          loading={loadingAddress}
                          data={dataProvinces}
                          setValue={setProvinces}
                          value={provinces}
                          inputChange={setInputProvince}
                          inputValue={inputProvince}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                    <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                      <FormLabel label="City/District *" />
                      <Box>
                        <SelectCity
                          placeholder="Pilih Kota"
                          loading={loadingAddress}
                          data={dataCity}
                          setValue={setCity}
                          value={city}
                          inputChange={setInputCity}
                          inputValue={inputCity}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container columns={2} spacing={2}>
                  <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                    <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                      <FormLabel label="Sub District *" />
                      <Box>
                        <SelectSubDistrict
                          placeholder="Pilih Kecamatan"
                          loading={loadingAddress}
                          data={dataSubDistrict}
                          setValue={setSubDistrict}
                          value={SubDistrict}
                          inputChange={setInputSubDistrict}
                          inputValue={inputSubDistrict}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                    <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                      <FormLabel label="Village *" />
                      <Box>
                        <SelectVillage
                          placeholder="Pilih Desa"
                          loading={loadingAddress}
                          data={dataVillages}
                          setValue={setVillages}
                          value={villages}
                          inputChange={setInputVillages}
                          inputValue={inputVillages}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Box>
                  <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                    <FormLabel label="Living Address" />
                    <Box display={"flex"}>
                      <TextField
                        placeholder="Masukkan Alamat"
                        variant="outlined"
                        size="small"
                        fullWidth
                        multiline
                        rows={2}
                        maxRows={4}
                        value={currentData.address}
                        onChange={(e) =>
                          setCurrentData("address", e.target.value)
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{ marginRight: "10px" }}
                            >
                              <IconLocationProvince />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ textTransform: "none" }}
                    size="small"
                    onClick={handleSubmit}
                  >
                    Add
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress color="secondary" />
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </Modals>
  );
}
