import { Box, Stack } from "@mui/material"
import { Card, Search } from "../../../../components/atoms"
import { useEffect, useState } from "react";
import BoardProject from "../../../../components/organisms/project/boardProject";
import MenuAddBoard from "../../../../components/molecules/menu/project/menuAddBoard";
import MenuChangeBackgroundBoard from "../../../../components/molecules/menu/project/menuChangeBackgroundBoard";
import { getListBoardWithTask } from "../../../../configs/axios/apiPath/fetchProject/fetchBoard";
import { useNavigate } from "react-router-dom";
import { getImageDocument } from "../../../../configs/axios/apiPath";
import DataLogin from "../../../../data/dataLogin";

const IndexBoards = () => {
  const navigate = useNavigate();
  const getData = DataLogin();
  const dataProject = JSON.parse(localStorage.getItem('cvSayaProject')).itemProject

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [dataBoard, setDataBoard] = useState([]);
  const [imageId, setImageId] = useState('');
  const [bgImage, setBgImage] = useState('');


  async function fetchData() {
      setIsLoading(true)
      try {
        const {
          data: { data },
        } = await getListBoardWithTask(dataProject.id);
        setDataBoard(data?.boards);
        setImageId(data?.board_image_id)
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false)
      }
    }

  useEffect(() => {
    

    const getImage = async() => {
      try {
        const response = await getImageDocument(imageId, getData.token);
        const contentType = response.headers['content-type'];
        const blob = new Blob([response.data], { type: contentType });
        const objectURL = URL.createObjectURL(blob);
        setBgImage(objectURL);

      } catch (error) {
        console.error(error.response.status);      
      }
    }

    fetchData()
    getImage()
  }, [navigate, dataProject.id, imageId, getData.token])
  return(
    <Box
      sx={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        maxHeight: "full",
        maxWidth: 'full',
        borderRadius: '15px',
        paddingY: '15px',
        paddingX: '25px',
        position: 'relative',
         overflow: 'scroll',
      }}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Search
          placeholder="Type here to search"
          page={page}
          setPage={(e) => setPage(e)}
        />
        <MenuChangeBackgroundBoard/>
      </Stack> 

      {/* Card Projects */}
      <Box 
      marginTop={'15px'} 
         sx={{
          position: 'relative',
           
         // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
        }}
      >
        <Stack 
        direction={'row'} 
        spacing={2} 
        sx={{
          width: '100%',
          
           position: 'relative'
         // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
        }}
        >
          
          {
            dataBoard.map((item, idx) => (
              <BoardProject
                key={idx}
                detailBoard={item}
              />              
            ))
          }

          <Card sx={{
              width: '264px',
              height: '47px',
              paddingLeft: '16px',
              bgcolor: '#FFFFFF4D',
            }}
          >
            <MenuAddBoard projectId={dataProject.id} fetchHandle={fetchData}  />
          </Card>
        </Stack>
      </Box>           
    </Box>
  )
}

export default IndexBoards