import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconPlus } from '../../../assets/commons';
import {
  Card,
  Pagination,
  RowPerPage,
  Search,
} from '../../../components/atoms';
import {
  AwardsThead,
  AwardsTbody,
} from '../../../components/molecules/table/tableCoreHR/awards';
import SelectYear from '../../../components/molecules/year';
import { getCompany } from '../../../features/companies';
import { getLanguage } from '../../../features/language';
import TableComponent from './../../../components/molecules/table/mainTable';
import { getListAwards, getListCalendar } from '../../../configs/axios/apiPath';
import DataTableAwards from './../../../data/table/coreHR/awards/index';
import { DataTableCalendar } from '../../../data/table/hr-calendar';
import {
  HistoryThead,
  HistoryTbody,
} from '../../../components/molecules/table/tableHrCalendar';

const HRCalendarHistory = () => {
  const yearNow = moment().format('YYYY');
  const year = [];
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [selectedYear, setSelectedYear] = useState(yearNow);
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [order, setOrder] = useState('start_date');
  const [direction, setDirection] = useState('desc');
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);

  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }

  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          page_size: pageSize,
          page: page,
          company_id: company,
          keyword,
          year: selectedYear,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListCalendar(params);
          setLastPage(data?.page_info?.last_page);
          setHistory(data.calendar);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [page, company, pageSize, selectedYear, keyword]);

  return (
    <Card sx={{ marginTop: '20px' }}>
      <Box display={'flex'} flexDirection={'column'} p={'23px 22px'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          {/* --- Search --- */}
          <Search
            placeholder={
              lang === 'Id'
                ? 'Ketik disini untuk mencari'
                : 'Type here to search'
            }
            setSearch={(e) => setKeyword(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />

          <Box display={'flex'} alignItems={'center'}>
            {/* --- Selected Year --- */}
            <SelectYear
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              year={year}
            />
          </Box>
        </Box>

        {/* --- Table --- */}
        <Box sx={{ mt: '24px', mb: '16px' }}>
          <TableComponent
            isLoading={isLoading}
            tbody={history}
            TableHead={
              <HistoryThead
                thead={DataTableCalendar}
                setOrder={setOrder}
                setDirection={setDirection}
                direction={direction}
              />
            }
            TableBody={<HistoryTbody tbody={history} setData={setHistory} />}
            col={4}
          />
        </Box>

        {/* --- Pagination --- */}
        <Box display={'flex'} justifyContent={'end'}>
          <RowPerPage
            setPageSize={setPageSize}
            pageSize={pageSize}
            setPage={setPage}
          />
          <Pagination page={page} setPage={setPage} lastPage={lastPage} />
        </Box>
      </Box>
    </Card>
  );
};

export default HRCalendarHistory;
