import { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Grid,
  Button,
  Box,
  Avatar,
  Stack,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Search } from "../../../../components/atoms";
import { getLanguage } from "../../../../features/language";
import { ChartVacancies } from "../../../../components/organisms/recruitment";
import { getCompany } from "../../../../features/companies";
import {
  getListDepartments,
  getListVacancy,
} from "../../../../configs/axios/apiPath";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export default function RecruitmentCandidateDashboard() {
  const company = useSelector(getCompany);

  const [deptData, setDeptData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("default-value");
  const [vacancyData, setVacancyData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [frequency, setFrequency] = useState("Daily");
  const [dataChart, setDataChart] = useState({ labels: [], datasets: [] });
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  const lang = useSelector(getLanguage);
  const navigate = useNavigate();
  const handleClickDetail = (id) => {
    navigate(`${id}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          page_size: 9999999,
          company_id: company,
          year: new Date().getFullYear(),
          department_id:
            selectedDepartment !== "default-value"
              ? selectedDepartment
              : undefined,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListVacancy(params);
          setVacancyData(data?.job_vacancies);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, selectedDepartment]);
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          page_size: 9999999,
          company_id: company,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListDepartments(params);
          /* setSelectedDepartment(data?.departments[0].id); */
          setDeptData(data?.departments);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company]);
  return (
    <>
      <ChartVacancies />
      <Card sx={{ padding: 5, marginTop: 5 }}>
        <Stack direction="row" justifyContent="space-between" marginBottom={4}>
          <Search
            placeholder={
              lang === "Id"
                ? "Ketik disini untuk mencari"
                : "Type here to search"
            }
          />
          <Stack direction="row" gap="10px">
            <Select
              onChange={(value) => {
                setSelectedDepartment(value.target.value);
              }}
              value={selectedDepartment}
              sx={{
                background: "#f9f9f9",
                height: "30px",
                ".MuiSelect-root": {
                  border: "1px solid red",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
            >
              <MenuItem value="default-value">
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  Select department
                </Box>
              </MenuItem>
              {deptData &&
                deptData.map((item, idx) => (
                  <MenuItem value={item.id} key={idx}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      {item.name}
                    </Box>
                  </MenuItem>
                ))}
            </Select>
          </Stack>
        </Stack>
        <Grid
          container
          spacing={4}
          maxHeight="70vh"
          sx={{ overflowY: "scroll" }}
          paddingBottom={4}
        >
          {vacancyData.map((i, index) => (
            <Grid item xs={3} key={index}>
              <Card
                sx={{
                  borderRadius: 2,
                  padding: 4,
                  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.16)",
                }}
              >
                <Stack alignItems="center">
                  <Grid container>
                    <Grid item xs={4} />
                    <Grid item xs={4} display="flex" justifyContent="center">
                      <Avatar
                        sx={{ width: "80px", height: "80px" }}
                        src={i?.request_by.profile_picture}
                      />
                    </Grid>

                    <Grid item xs={4} display="flex" justifyContent="end">
                      <Stack direction="row" gap="5px">
                        {Boolean(i.urgent) && (
                          <Box
                            sx={{
                              borderRadius: "50%",
                              width: "24px",
                              height: "24px",
                              border: "0.2px solid var(--gray, #C8C8CC)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <svg
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.00004 0.800781C3.25049 0.800781 1.83598 2.21529 1.83598 3.96484C1.83598 5.71439 3.25049 7.12891 5.00004 7.12891C6.74959 7.12891 8.1641 5.71439 8.1641 3.96484C8.1641 2.21529 6.74959 0.800781 5.00004 0.800781ZM7.9665 0.994766L7.72728 1.25238C8.55111 2.01736 8.75019 2.77791 8.80799 3.56605L9.15859 3.54035C9.09746 2.70648 8.85689 1.82156 7.9665 0.994766ZM8.93006 1.00461L8.67303 1.24443C8.99795 1.59264 9.23934 2.08877 9.31336 2.50229L9.65943 2.44033C9.57127 1.94783 9.30728 1.40891 8.93006 1.00461ZM5.00004 3.08594C5.53521 3.08594 5.9766 3.52732 5.9766 4.0625C5.9766 4.59768 5.53521 5.03906 5.00004 5.03906C4.46486 5.03906 4.02348 4.59768 4.02348 4.0625C4.02348 3.52732 4.46486 3.08594 5.00004 3.08594ZM5.00004 3.78906C4.84486 3.78906 4.7266 3.90732 4.7266 4.0625C4.7266 4.21768 4.84486 4.33594 5.00004 4.33594C5.15521 4.33594 5.27348 4.21768 5.27348 4.0625C5.27348 3.90732 5.15521 3.78906 5.00004 3.78906ZM1.19516 4.28227L0.844531 4.30797C0.905664 5.14184 1.14623 6.0268 2.03662 6.85359L2.27584 6.59596C1.45201 5.83096 1.25295 5.07041 1.19516 4.28227ZM8.93338 5.13272C8.6658 5.13272 8.4451 5.35342 8.4451 5.621C8.4451 5.86633 8.63064 6.07211 8.86771 6.10467C9.02006 6.9532 8.74322 7.95908 7.98832 8.68264V9.14865C9.01635 8.33338 9.40934 7.08408 9.21014 6.02203C9.33748 5.93348 9.42166 5.78621 9.42166 5.621C9.42166 5.35342 9.20096 5.13272 8.93338 5.13272ZM0.689766 5.34607L0.34375 5.40803C0.431895 5.90053 0.69584 6.43947 1.07307 6.84377L1.3301 6.60389C1.00518 6.25568 0.763789 5.75959 0.689766 5.34607ZM2.67582 6.60088V7.94922H7.32426V6.60088C6.70434 7.14803 5.89057 7.48047 5.00004 7.48047C4.10951 7.48047 3.29574 7.14803 2.67582 6.60088ZM2.36332 8.30078V9.62891H7.63676V8.30078H2.36332Z"
                                fill="#E40A0A"
                              />
                            </svg>
                          </Box>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                  <Stack sx={{ marginTop: "10px", marginBottom: "6px" }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        color: "#171717",
                        textAlign: "center",
                      }}
                    >
                      {i?.position.name}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        color: "#C4C4C4",
                        fontSize: "10px",
                        textTransform: "capitalize",
                      }}
                    >
                      Posted {dayjs(i?.created_at).fromNow()}
                    </Typography>
                  </Stack>
                  <Typography
                    sx={{
                      color: "#939393",
                      fontSize: "10px",
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    {i?.description}
                  </Typography>
                  <Stack direction="row" gap="6px" marginBottom={4}>
                    <Box
                      sx={{
                        paddingY: "6px",
                        paddingX: 2,
                        backgroundColor: "#F7F7F7",
                        color: "#939393",
                        borderRadius: "88px",
                        fontSize: "8px",
                        display: "flex",
                        alignItems: "center",
                        textTransform: "capitalize",
                      }}
                    >
                      <svg
                        width="17"
                        height="14"
                        viewBox="0 0 17 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginRight: "6px" }}
                      >
                        <path
                          d="M15.15 5.325H15.125L9.65 1.725C9.7 1.575 9.75 1.425 9.75 1.25C9.75 0.575 9.2 0 8.5 0C7.825 0 7.25 0.55 7.25 1.25C7.25 1.425 7.275 1.575 7.35 1.725L1.875 5.325H1.85C1.1 5.325 0.5 5.925 0.5 6.675V12C0.5 12.75 1.1 13.35 1.85 13.35H15.15C15.9 13.35 16.5 12.75 16.5 12V6.675C16.5 5.925 15.9 5.325 15.15 5.325ZM7.625 2.125C7.85 2.35 8.175 2.5 8.5 2.5C8.85 2.5 9.15 2.35 9.375 2.125L14.225 5.325H2.775L7.625 2.125Z"
                          fill="#939393"
                        />
                        <ellipse
                          cx="4"
                          cy="9.25"
                          rx="1.75"
                          ry="1.75"
                          fill="#D9D9D9"
                        />
                        <ellipse
                          cx="4"
                          cy="9.25"
                          rx="1"
                          ry="1"
                          fill="#939393"
                        />
                        <path
                          d="M6.25 8.8125C6.25 8.08763 6.83763 7.5 7.5625 7.5H7.9375C8.45527 7.5 8.875 7.91973 8.875 8.4375C8.875 8.95527 8.45527 9.375 7.9375 9.375H7.8125C7.36377 9.375 7 9.73877 7 10.1875V10.625C7 10.8321 6.83211 11 6.625 11C6.41789 11 6.25 10.8321 6.25 10.625V8.8125Z"
                          fill="#D9D9D9"
                        />
                        <path
                          d="M9.25 8.5C9.25 7.94772 9.69772 7.5 10.25 7.5H11.5625C11.7351 7.5 11.875 7.63991 11.875 7.8125C11.875 7.98509 11.7351 8.125 11.5625 8.125H10.375C10.1679 8.125 10 8.29289 10 8.5C10 8.70711 10.1679 8.875 10.375 8.875H11.5C11.7071 8.875 11.875 9.04289 11.875 9.25C11.875 9.45711 11.7071 9.625 11.5 9.625H10.375C10.1679 9.625 10 9.79289 10 10C10 10.2071 10.1679 10.375 10.375 10.375H11.5625C11.7351 10.375 11.875 10.5149 11.875 10.6875C11.875 10.8601 11.7351 11 11.5625 11H10.25C9.69772 11 9.25 10.5523 9.25 10V8.5Z"
                          fill="#D9D9D9"
                        />
                        <path
                          d="M12.25 7.97323C12.25 7.71187 12.4619 7.5 12.7232 7.5C12.8942 7.5 13.0519 7.59221 13.1357 7.74122L14.1024 9.4598C14.1164 9.48463 14.1426 9.5 14.1711 9.5C14.2147 9.5 14.25 9.46469 14.25 9.42113V7.8125C14.25 7.63991 14.3899 7.5 14.5625 7.5C14.7351 7.5 14.875 7.63991 14.875 7.8125V10.6103C14.875 10.8255 14.7005 11 14.4853 11C14.3406 11 14.2078 10.9198 14.1404 10.7918L13.1164 8.84623C13.0864 8.78916 13 8.81051 13 8.875V9.625V10.375V10.625C13 10.8321 12.8321 11 12.625 11C12.4179 11 12.25 10.8321 12.25 10.625V7.97323Z"
                          fill="#D9D9D9"
                        />
                        <ellipse
                          cx="7.625"
                          cy="8.375"
                          rx="0.625"
                          ry="0.625"
                          fill="#939393"
                        />
                      </svg>

                      {i?.status}
                    </Box>
                    <Box
                      sx={{
                        paddingY: "6px",
                        paddingX: 2,
                        backgroundColor: "#F7F7F7",
                        color: "#939393",
                        borderRadius: "88px",
                        fontSize: "8px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginRight: "6px" }}
                      >
                        <path
                          d="M1.25 5.5C1.25 7.07125 1.25 8.69042 1.79917 9.17833C2.34833 9.66667 3.2325 9.66667 5 9.66667C6.76792 9.66667 7.65167 9.66667 8.20083 9.17833C8.75 8.69042 8.75 7.07125 8.75 5.5"
                          stroke="#939393"
                          strokeWidth="0.6"
                        />
                        <path
                          d="M6.10703 6.41717L8.61036 5.66634C8.85828 5.59176 8.98245 5.55467 9.0612 5.46551C9.07663 5.4481 9.09057 5.42942 9.10286 5.40967C9.16536 5.30842 9.16536 5.17926 9.16536 4.92009C9.16536 3.89926 9.16536 3.38884 8.88495 3.04426C8.83102 2.97795 8.77042 2.91735 8.70411 2.86342C8.35953 2.58301 7.84911 2.58301 6.82828 2.58301H3.16953C2.1487 2.58301 1.63828 2.58301 1.2937 2.86342C1.22703 2.91759 1.16661 2.97801 1.11286 3.04426C0.832031 3.38884 0.832031 3.89926 0.832031 4.92009C0.832031 5.17926 0.832031 5.30842 0.894531 5.40967C0.907031 5.42926 0.920781 5.44801 0.936198 5.46551C1.01536 5.55467 1.13911 5.59176 1.38703 5.66634L3.89036 6.41717M2.70703 2.58301C3.04995 2.57467 3.3987 2.35592 3.51536 2.03301L3.52995 1.99009L3.54036 1.95801C3.55786 1.90509 3.56703 1.87884 3.5762 1.85551C3.63506 1.70918 3.73423 1.58256 3.8622 1.49036C3.99017 1.39817 4.14168 1.34418 4.29911 1.33467C4.3237 1.33301 4.35203 1.33301 4.40703 1.33301H5.58953C5.64495 1.33301 5.67286 1.33301 5.69786 1.33467C5.8553 1.34418 6.00681 1.39817 6.13478 1.49036C6.26274 1.58256 6.36192 1.70918 6.42078 1.85551C6.43036 1.87884 6.43911 1.90551 6.45661 1.95801L6.46745 1.99009C6.47495 2.01217 6.47828 2.02342 6.48203 2.03301C6.5987 2.35592 6.94703 2.57467 7.28995 2.58301"
                          stroke="#939393"
                          strokeWidth="0.6"
                        />
                        <path
                          d="M5.83203 5.70801H4.16536C4.11011 5.70801 4.05712 5.72996 4.01805 5.76903C3.97898 5.8081 3.95703 5.86109 3.95703 5.91634V6.81717C3.95704 6.85878 3.96951 6.89943 3.99283 6.93389C4.01614 6.96834 4.04924 6.99503 4.08786 7.01051L4.37953 7.12717C4.77699 7.28621 5.22041 7.28621 5.61786 7.12717L5.90953 7.01051C5.94815 6.99503 5.98125 6.96834 6.00457 6.93389C6.02789 6.89943 6.04035 6.85878 6.04036 6.81717V5.91634C6.04036 5.86109 6.01842 5.8081 5.97935 5.76903C5.94028 5.72996 5.88728 5.70801 5.83203 5.70801Z"
                          stroke="#939393"
                          strokeWidth="0.6"
                          strokeLinecap="round"
                        />
                      </svg>
                      {i?.employment_type}
                    </Box>
                    <Box
                      sx={{
                        paddingY: "6px",
                        paddingX: 2,
                        backgroundColor: "#F7F7F7",
                        color: "#939393",
                        borderRadius: "88px",
                        fontSize: "8px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="9"
                        fill="none"
                        style={{ marginRight: "6px" }}
                        viewBox="0 0 9 9"
                      >
                        <path
                          fill="#939393"
                          fillRule="evenodd"
                          d="M3.07 2.357a1.429 1.429 0 112.858 0 1.429 1.429 0 01-2.857 0zM5.929 3.5a1.143 1.143 0 112.286 0 1.143 1.143 0 01-2.286 0zm-5.143 0a1.143 1.143 0 112.286 0 1.143 1.143 0 01-2.286 0zm1.547 2.045a2.57 2.57 0 013.217-.964 2.57 2.57 0 011.506 2.633.286.286 0 01-.142.216 4.837 4.837 0 01-2.414.642 4.834 4.834 0 01-2.414-.642.286.286 0 01-.141-.216 2.585 2.585 0 01.388-1.669z"
                          clipRule="evenodd"
                        ></path>
                        <path
                          fill="#939393"
                          d="M1.865 5.216a3.157 3.157 0 00-.499 1.956 3.691 3.691 0 01-.672-.168L.651 6.99a.214.214 0 01-.143-.185l-.003-.046a1.429 1.429 0 011.36-1.542zm5.769 1.956a3.157 3.157 0 00-.499-1.956 1.429 1.429 0 011.36 1.542l-.003.046a.215.215 0 01-.143.185l-.043.015c-.216.076-.44.133-.672.168z"
                        ></path>
                      </svg>
                      {i?.candidate_count}
                    </Box>
                  </Stack>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      borderRadius: "100px",
                      fontSize: "9px",
                    }}
                    onClick={() =>
                      handleClickDetail(
                        `${i?.id}?title=${i.position.name}&description=${i.description}&employment_type=${i.employment_type}&created_at=${i.created_at}`
                      )
                    }
                  >
                    See Detail
                  </Button>
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Card>
    </>
  );
}
