import {
  IconDashboardEmployee,
  IconDashboardLeave,
  IconDashboardSalaryPaid,
  IconDashboardSalaryUnpaid,
} from '../../../assets/dashboard/icons';

const DataDasboardCard = [
  {
    title: 'Employee',
    judul: 'Karyawan',

    icon: <IconDashboardEmployee />,
  },
  {
    title: 'Salary Unpaid',
    judul: 'Gaji Belum Dibayar',
    icon: <IconDashboardSalaryUnpaid />,
  },
  {
    title: 'Leave',
    judul: 'Cuti',
    icon: <IconDashboardLeave />,
  },
  {
    title: 'Salary Paid',
    judul: 'Gaji Dibayar',
    icon: <IconDashboardSalaryPaid />,
  },
];

export default DataDasboardCard;
