import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { ModalDelete } from '../../../../modal';
import {
  getAttendanceById,
  getAwardsById,
} from '../../../../../../configs/axios/apiPath';
import CoreHRAwardsDetail from '../../../../../../pages/coreHR/awards/detail';

const AttendanceTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState('');
  const [idEmployee, setIdEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState([]);

  // --- Load Detail ---
  useEffect(() => {
    async function fetchData() {
      if (idEmployee !== null) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getAttendanceById(idEmployee);
          setDetail(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [idEmployee]);

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Employee Name --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'left',
                verticalAlign: 'middle',
              }}
            >
              <Box display={'flex'} alignItems={'center'}>
                <img
                  src={item?.employee?.profile_picture}
                  alt={item?.employee?.name}
                  width={30}
                  height={30}
                  style={{ borderRadius: '50%' }}
                />
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={400}
                  fontSize={'14px'}
                  lineHeight={'130%'}
                  color={'#171717'}
                  ml={'10px'}
                >
                  {item.employee?.name}
                </Typography>
              </Box>
            </td>
            {/* --- Date --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {moment(item?.date).format('D MMM')}
              </Typography>
            </td>
            {/* --- Clock In --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.attendance?.clock_in}
              </Typography>
            </td>
            {/* --- Clock Out--- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.attendance?.clock_out}
              </Typography>
            </td>
            {/* --- Late --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.attendance?.late}
              </Typography>
            </td>
            {/* --- Overtime --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.attendance?.overtime}
              </Typography>
            </td>
            {/* --- Status --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <img
                src={item?.status_icon}
                alt={item?.status}
                width={30}
                height={30}
              />
            </td>
            {/* Total */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.attendance?.total}
              </Typography>
            </td>
          </tr>
        ))}
      </tbody>
      {/* --- Modal Handle --- */}
      {idEmployee !== null && modal === 'detail' ? (
        <CoreHRAwardsDetail
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          detail={detail}
          isLoading={isLoading}
        />
      ) : modal === 'delete' ? (
        <ModalDelete
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={'Awards'}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          id={idEmployee}
          data={tbody}
          setData={setData}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default AttendanceTbody;
