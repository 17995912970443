import { Box, Grid, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { getEmployeesById } from "../../../../../configs/axios/apiPath";
import { IconExport } from "../../../../../assets/employee";

const EmployeeProfile = () => {
  const path = useLocation();
  const pathname = path.pathname;
  const paths = pathname.split("/");
  const id = paths[4];
  const [dataEmployee, setDataEmployee] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        include: "profile",
      };
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await getEmployeesById(id, params);
        setDataEmployee(data);
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaData");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id, navigate]);

  return (
    <Box mt={"30px"}>
      {isLoading ? (
        <Grid container columns={3} spacing={2}>
          <Grid item xl={1} lg={1} md={3} sm={3} xs={3}>
            <Skeleton variant="text" height={50} />
            <Skeleton variant="text" height={50} />
            <Skeleton variant="text" height={50} />
            <Skeleton variant="text" height={50} />
            <Skeleton variant="text" height={50} />
            <Skeleton variant="text" height={50} />
          </Grid>
          <Grid item xl={1} lg={1} md={3} sm={3} xs={3}>
            <Skeleton variant="text" height={50} />
            <Skeleton variant="text" height={50} />
            <Skeleton variant="text" height={50} />
            <Skeleton variant="text" height={50} />
          </Grid>
          <Grid item xl={1} lg={1} md={3} sm={3} xs={3}>
            <Skeleton variant="text" height={50} />
            <Skeleton variant="text" height={50} />
            <Skeleton variant="text" height={50} />
            <Skeleton variant="text" height={50} />
            <Skeleton variant="text" height={50} />
          </Grid>
        </Grid>
      ) : (
        <Grid container columns={3} spacing={2}>
          <Grid item xl={1} lg={1} md={3} sm={3} xs={3}>
            {/* --- Phone Number --- */}
            <Box mb={"16px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"6px"}
              >
                Phone Number
              </Typography>
              <Box
                paddingY={"17px"}
                paddingLeft={"15px"}
                borderRadius={"8px"}
                sx={{ background: "#f7f7f7" }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                >
                  +{dataEmployee?.profile?.country_code}{" "}
                  {dataEmployee?.profile?.phone_number
                    ? dataEmployee?.profile?.phone_number
                    : "-"}
                </Typography>
              </Box>
            </Box>
            {/* --- Place Date of Birth --- */}
            <Box mb={"16px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"6px"}
              >
                Place, Date of birth
              </Typography>
              <Box
                paddingY={"17px"}
                paddingLeft={"15px"}
                borderRadius={"8px"}
                sx={{ background: "#f7f7f7" }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                >
                  {dataEmployee?.profile?.birth_location
                    ? dataEmployee?.profile?.birth_location
                    : "-"}
                  ,{" "}
                  {dataEmployee?.profile?.birth_date &&
                    moment(dataEmployee?.profile?.birth_date).format(
                      "DD MMMM YYYY"
                    )}
                </Typography>
              </Box>
            </Box>
            {/* --- Identity Number --- */}
            <Box mb={"16px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"6px"}
              >
                Identity Number
              </Typography>
              <Box
                paddingY={"17px"}
                paddingLeft={"15px"}
                borderRadius={"8px"}
                sx={{ background: "#f7f7f7" }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                >
                  {dataEmployee?.profile?.identity_number
                    ? dataEmployee?.profile?.identity_number
                    : "-"}
                </Typography>
              </Box>
            </Box>
            {/* --- Status --- */}
            <Box mb={"16px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"6px"}
              >
                Status
              </Typography>
              <Box
                paddingY={"17px"}
                paddingLeft={"15px"}
                borderRadius={"8px"}
                sx={{ background: "#f7f7f7" }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                >
                  {dataEmployee?.profile?.status?.name
                    ? dataEmployee?.profile?.status?.name
                    : "-"}
                </Typography>
              </Box>
            </Box>
            {/* --- Gender --- */}
            <Box mb={"16px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"6px"}
              >
                Gender
              </Typography>
              <Box
                paddingY={"17px"}
                paddingLeft={"15px"}
                borderRadius={"8px"}
                sx={{ background: "#f7f7f7" }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                >
                  {dataEmployee?.profile?.gender === "L"
                    ? "Male"
                    : dataEmployee?.profile?.gender === "P"
                    ? "Female"
                    : "-"}
                </Typography>
              </Box>
            </Box>
            {/* --- Religion --- */}
            <Box mb={"16px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"6px"}
              >
                Religion
              </Typography>
              <Box
                paddingY={"17px"}
                paddingLeft={"15px"}
                borderRadius={"8px"}
                sx={{ background: "#f7f7f7" }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                >
                  {dataEmployee?.profile?.religion?.name
                    ? dataEmployee?.profile?.religion?.name
                    : "-"}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={1} lg={1} md={3} sm={3} xs={3}>
            {/* --- Province --- */}
            <Box mb={"16px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"6px"}
              >
                Province
              </Typography>
              <Box
                paddingY={"17px"}
                paddingLeft={"15px"}
                borderRadius={"8px"}
                sx={{ background: "#f7f7f7" }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                >
                  {dataEmployee?.profile?.province?.name
                    ? dataEmployee?.profile?.province?.name
                    : "-"}
                </Typography>
              </Box>
            </Box>
            {/* --- City --- */}
            <Box mb={"16px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"6px"}
              >
                City
              </Typography>
              <Box
                paddingY={"17px"}
                paddingLeft={"15px"}
                borderRadius={"8px"}
                sx={{ background: "#f7f7f7" }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                >
                  {dataEmployee?.profile?.city?.name
                    ? dataEmployee?.profile?.city?.name
                    : "-"}
                </Typography>
              </Box>
            </Box>
            {/* --- Subdisctrics --- */}
            <Box mb={"16px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"6px"}
              >
                Subdistrics
              </Typography>
              <Box
                paddingY={"17px"}
                paddingLeft={"15px"}
                borderRadius={"8px"}
                sx={{ background: "#f7f7f7" }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                >
                  {dataEmployee?.profile?.subdistrict?.name
                    ? dataEmployee?.profile?.subdistrict?.name
                    : "-"}
                </Typography>
              </Box>
            </Box>
            {/* --- Village --- */}
            <Box mb={"16px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"6px"}
              >
                Village
              </Typography>
              <Box
                paddingY={"17px"}
                paddingLeft={"15px"}
                borderRadius={"8px"}
                sx={{ background: "#f7f7f7" }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                >
                  {dataEmployee?.profile?.village?.name
                    ? dataEmployee?.profile?.village?.name
                    : "-"}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={1} lg={1} md={3} sm={3} xs={3}>
            {/* --- Instagram --- */}
            <Box mb={"16px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"6px"}
              >
                Instagram
              </Typography>
              <Box
                paddingY={"17px"}
                paddingLeft={"15px"}
                borderRadius={"8px"}
                sx={{ background: "#f7f7f7" }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                >
                  {dataEmployee?.profile?.instagram
                    ? dataEmployee?.profile?.instagram
                    : "-"}
                </Typography>
              </Box>
            </Box>
            {/* --- Linkedin --- */}
            <Box mb={"16px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"6px"}
              >
                Linkedin
              </Typography>
              <Box
                paddingY={"17px"}
                paddingLeft={"15px"}
                borderRadius={"8px"}
                sx={{ background: "#f7f7f7" }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                >
                  {dataEmployee?.profile?.linkedin
                    ? dataEmployee?.profile?.linkedin
                    : "-"}
                </Typography>
              </Box>
            </Box>
            {/* --- Facebook --- */}
            <Box mb={"16px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"6px"}
              >
                Facebook
              </Typography>
              <Box
                paddingY={"17px"}
                paddingLeft={"15px"}
                borderRadius={"8px"}
                sx={{ background: "#f7f7f7" }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                >
                  {dataEmployee?.profile?.facebook
                    ? dataEmployee?.profile?.facebook
                    : "-"}
                </Typography>
              </Box>
            </Box>
            {/* --- Website --- */}
            <Box mb={"16px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"6px"}
              >
                Website
              </Typography>
              <Box
                paddingY={"17px"}
                paddingLeft={"15px"}
                borderRadius={"8px"}
                sx={{ background: "#f7f7f7" }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                >
                  {dataEmployee?.profile?.website_url
                    ? dataEmployee?.profile?.website_url
                    : "-"}
                </Typography>
              </Box>
            </Box>
            {/* --- Youtube --- */}
            <Box mb={"16px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"18px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"6px"}
              >
                Youtube
              </Typography>
              <Box
                paddingY={"17px"}
                paddingLeft={"15px"}
                borderRadius={"8px"}
                sx={{ background: "#f7f7f7" }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                >
                  {dataEmployee?.profile?.youtube
                    ? dataEmployee?.profile?.youtube
                    : "-"}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}

      <Box display={"flex"} justifyContent={"end"}>
        <Box
          padding={"14px"}
          boxShadow={"0px 0px 8px rgba(0, 0, 0, 0.1)"}
          borderRadius={"6px"}
          sx={{ background: "#ffffff" }}
          display={"flex"}
          alignItems={"center"}
        >
          <IconExport />
          <Typography
            fontFamily={"sans-serif"}
            fontStyle={"normal"}
            fontWeight={600}
            fontSize={"12px"}
            lineHeight={"16px"}
            color={"#171717"}
            ml={"8px"}
          >
            Export
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeProfile;
