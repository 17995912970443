export const CompanyOptions = {
  sliceVisibilityThreshold: 0,
  chartArea: {
    width: '100%',
    height: '80%',
  },
  legend: {
    position: 'left',
  },
  pieSliceText: 'value',
};
