const DataTableComplaint = [
  {
    title: 'Employee Name',
    judul: 'Nama Karyawan',
    url: 'name',
  },
  {
    title: 'Name Complaints',
    judul: 'Keluhan',
    url: 'complaint',
  },
  {
    title: 'Complaint Date',
    judul: 'Tanggal Keluhan',
    url: 'date',
  },
  {
    title: 'Description',
    judul: 'Deskripsi',
    url: 'name',
  },
  {
    title: 'Action',
    judul: 'Aksi',
    url: 'name',
  },
];

export default DataTableComplaint;
