import { Box, Button, Stack, Typography } from '@mui/material';
import CardBoard from '../../../molecules/card/project/CardBoard';
import { Card } from '../../../atoms';
import AvatarGroupStyled from '../../../molecules/avatar/avatarGroup';
import { IconClipFile, IconMoreCircle } from '../../../../assets/project/icons';
import { BackgroundBoards } from '../../../../assets/project/img';
import { Link, useNavigate } from 'react-router-dom';
import MenuActionTask from '../../../molecules/menu/project/menuActionTask';
import { showAttachmentProject } from '../../../../configs/axios/apiPath/fetchProject';
import { useEffect, useState } from 'react';
import { getImageDocument } from '../../../../configs/axios/apiPath';
import DataLogin from '../../../../data/dataLogin';

const BoardProject = ({ detailBoard }) => {
  // const URL = process.env.REACT_APP_API_BASE_URL;
  const getData = DataLogin();
  const navigate = useNavigate();
  const issetTask = detailBoard.tasks.length > 0 ? true : false;
  const dataProject = JSON.parse(
    localStorage.getItem('cvSayaProject')
  ).itemProject;

  const [image, setImage] = useState('');

  const handleNavigationClick = (url) => {
    navigate(
      `/manager-project/detail/boards/task?bx=${detailBoard.id}&px=${dataProject.id}`
    );
  };

  useEffect(() => {
    const arrImage = [];
    async function fetchImage() {
      for (let i = 0; i < detailBoard.tasks.length; i++) {
        if (detailBoard.tasks[i].design[0] !== undefined) {
          const imageBlob = await fetchDataImage(
            detailBoard.tasks[i].design[0].attachment_id
          );
          arrImage[detailBoard.tasks[i].id] = imageBlob;
        }
      }
      setImage(arrImage);
    }

    fetchImage();
  }, []);

  async function fetchDataImage(id) {
    try {
      const response = await showAttachmentProject(
        id,
        '240',
        '120',
        getData.token
      );
      const contentType = response.headers['content-type'];
      const blob = new Blob([response.data], { type: contentType });
      const objectURL = URL.createObjectURL(blob);
      return objectURL;
    } catch (error) {
      // if (error.response.status === 401) {
      //   localStorage.removeItem("cvSayaLogin");
      //   navigate(`/users`);
      // }
    } finally {
      // setIsLoading(false)
    }
  }

  return (
    <CardBoard
      title={detailBoard.name}
      type={detailBoard.type}
      hasTask={issetTask}
    >
      {detailBoard.tasks.map((item, idx) => (
        <Card
          sx={{
            width: 'full',
            maxHeight: '218px',
            padding: '8px',
            marginTop: '10px',
          }}
          key={idx}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            marginY={'5px'}
          >
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '16px',
                color: '#171717',
                letterSpacing: '0.25px',
              }}
            >
              {`${item.name.slice(0, 20)}...`}
            </Typography>
            <MenuActionTask />
          </Box>
          <Link
            to={`/manager-project/detail/boards/task/${item.id}?bx=${item.board_id}&px=${dataProject.id}`}
          >
            <img
              src={
                item.design[0] !== undefined ? image[item.id] : BackgroundBoards
              }
              alt="test"
              loading="lazy"
              width={'246px'}
              height={'120px'}
              style={{ borderRadius: '5px' }}
            />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              marginTop={'15px'}
            >
              <Box display={'flex'} alignItems="center">
                <AvatarGroupStyled
                  width={22}
                  height={22}
                  maxShow={4}
                  avatarData={item.members}
                />
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: '10px',
                    color: '#171717',
                    marginLeft: '5px',
                  }}
                >
                  Other member
                </Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'}>
                <IconMoreCircle />
                <Typography
                  sx={{
                    marginRight: '5px',
                    fontWeight: '400',
                    fontSize: '12px',
                    color: '#939393',
                  }}
                >
                  12
                </Typography>
                <IconClipFile />
                <Typography
                  sx={{
                    fontWeight: '400',
                    fontSize: '12px',
                    color: '#939393',
                  }}
                >
                  1
                </Typography>
              </Box>
            </Stack>
          </Link>
        </Card>
      ))}
      {issetTask && (
        <Button
          variant="outlined"
          sx={{ width: '260px', marginY: '10px' }}
          type="submit"
          onClick={handleNavigationClick}
        >
          ADD TASK
        </Button>
      )}
    </CardBoard>
  );
};

export default BoardProject;
