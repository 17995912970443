import React, { useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import moment from "moment";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { Card, Modals } from "../../../../../components/atoms";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../../features/language";
import { IconClose } from "../../../../../assets/commons";
import FileSaver from "file-saver";

import { getFileReimbursement } from "../../../../../configs/axios/apiPath";

const BenefitReimbursementDetail = ({ isOpen, onClose, detail, isLoading }) => {
  const lang = useSelector(getLanguage);

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "background.paper",
        }}
      >
        <Card>
          <Box p={"30px"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: "7px",
                position: "sticky",
                top: 0,
                bgcolor: "background.paper",
                p: "10px 0px 10px 0px",
                zIndex: 999,
              }}
            >
              <Box mt={"20px"} component={"form"} noValidate autoComplete="off">
                {/* --- Employee --- */}
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    {lang !== "Id" ? "Job Title" : "Nama Pekerjaan"}
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      display: "inline-block",
                    }}
                  >
                    <Typography>{detail?.title}</Typography>
                  </Box>
                </Box>
                {/* --- Date --- */}
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    {lang === "Id" ? "Deksripsi Pekerjaan" : "Job Description"}
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      display: "inline-block",
                    }}
                  >
                    <Typography>{detail?.description}</Typography>
                  </Box>
                </Box>
              </Box>
              <IconButton
                sx={{ p: "0px", position: "absolute", right: 0 }}
                onClick={onClose}
              >
                <IconClose />
              </IconButton>
            </Box>
            <Button
              variant="contained"
              disableRipple
              onClick={onClose}
              fullWidth
              sx={{ height: "62px" }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontSize={"20px"}
                fontWeight={"700px"}
                lineHeight={"27px"}
                letterSpacing={"1px"}
                textTransform={"none"}
              >
                {lang === "Id" ? "Keluar" : "Close"}
              </Typography>
            </Button>
          </Box>
        </Card>
      </Box>
    </Modals>
  );
};

export default BenefitReimbursementDetail;
