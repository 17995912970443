import {  Typography ,IconButton} from "@mui/material";
import { TooltipsAction } from "../../../../../atoms";
import { useNavigate } from "react-router-dom";
import {
  handleTooltipClose,
  handleTooltipOpen,
} from "../../../../../../utils/function/tooltips";
import { useEffect, useState } from "react";
import {
  IconDetail,
} from "../../../../../../assets/commons";
import { BenefitAccountDetail } from '../../../../../../pages/benefit';
import { useDispatch } from "react-redux";
import { getAccountById } from "../../../../../../configs/axios/apiPath";
const ReimbursementTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [idTooltipDetail, setIdTooltipDetail] = useState("");
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(null);
  const [detail, setDetail] = useState(null);



  // --- Handle Detail ---
  const handleDetail = async (id) => {
    setIsModalOpen(true);
    setOpen(false);
    setId(id);
    setModal('detail');
  };

  // --- Load Detail ---
  useEffect(() => {
    async function fetchData() {
      if (id !== null) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getAccountById(id);
          setDetail(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [id, navigate]);

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Date --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item.employe.name}
              </Typography>
            </td>

            {/* --- Clock In --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item.account_name}
              </Typography>
            </td>
            {/* --- Clock Out --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item.account_number}
              </Typography>
            </td>

            {/* --- Action --- */}
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <TooltipsAction
                title="Detail"
                open={isTooltipDetail && item.id === idTooltipDetail}
                arrow
                placement={"bottom-end"}
              >
                <IconButton
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleDetail(item.id)}
                  onMouseEnter={() =>
                    handleTooltipOpen(
                      item.id,
                      setIsTooltipDetail,
                      setIdTooltipDetail
                    )
                  }
                  onMouseLeave={() => handleTooltipClose(setIsTooltipDetail)}
                >
                  <IconDetail />
                </IconButton>
              </TooltipsAction>

            </td>
          </tr>
        ))}
      </tbody>
      { modal === 'detail'  && (
        <BenefitAccountDetail
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          detail={detail}
          isLoading={isLoading}
        />
      ) }
    </>
  );
};

export default ReimbursementTbody;
