import { Box, Typography } from '@mui/material';

const ListProjectThead = ({ thead }) => {

  return (
    <thead
      style={{ color: '#51B15C', backgroundColor: 'rgba(81, 177, 92, 0.06)' }}
    >
      <tr>
        {thead.map((item, idx) => (
          <th
            key={idx}
            style={{
              textAlign: 'center',
              verticalAlign: 'middle',
              padding: '10px',
            }}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              p={'0px 0px'}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={600}
                fontSize={'10px'}
                lineHeight={'130%'}
                color={'#51b15c'}
                width={item.title !== 'Name Employee' ? '100px' : 'auto'}
              >
                {item}
              </Typography>
            </Box>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default ListProjectThead;