import { Box } from "@mui/material"

const IndexTimeline = () => {
  return(
    <Box>
      <h3>Index Timeline Started</h3>
    </Box>
  )
}

export default IndexTimeline