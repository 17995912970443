import * as React from 'react';
import { Avatar, AvatarGroup } from '@mui/material';
import { Profile } from '../../../assets/dashboard/img';

export default function GroupedAvatar() {
  return (
    <AvatarGroup>
      <Avatar alt="Remy Sharp" src={Profile} />
      <Avatar alt="Travis Howard" src={Profile} />
      <Avatar alt="Cindy Baker" src={Profile} />
      <Avatar>+4</Avatar>
    </AvatarGroup>
  );
}
