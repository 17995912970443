import { IconProjectCancel, IconProjectDelay, IconProjectProcess } from "../../../../assets/dashboard/icons";
import { IconProjectSuccess } from "../../../../assets/dashboard/icons";
import { CircularProgressWithLabel } from "../../../../utils/format";

  
  export const dataGridProject = [
    {
      title: 'Danmogot 3D Asset',
      status: 'done',
      icon: <CircularProgressWithLabel value={100} status="done" />,
      iconProject: <IconProjectSuccess />,
      color: '#51B15C',
      date: '10 Apr 2023',
      avatar: '{ GroupedAvatars }',
    },
    {
      title: 'Design CVSaya',
      status: 'delayed',
      icon: <CircularProgressWithLabel value={60} status="delayed" />,
      iconProject: <IconProjectDelay />,
      color: '#125ACF',
      date: '21 Maret 2023',
      avatar: '{ GroupedAvatars }',
    },
    {
      title: 'Kondoku Website',
      status: 'process',
      icon: <CircularProgressWithLabel value={40} status="process" />,
      iconProject: <IconProjectProcess />,
      color: '#CF8812',
      date: '10 April 2023',
      avatar: '{ GroupedAvatars }',
    },
    {
      title: 'Website BIN',
      status: 'canceled',
      icon: <CircularProgressWithLabel value={14} status="canceled" />,
      iconProject: <IconProjectCancel />,
      color: '#FF1D1D',
      date: '04 Feb 2023',
      avatar: '{ GroupedAvatars }',
    },
    {
      title: 'Danmogot 3D Asset1',
      status: 'done',
      icon: <CircularProgressWithLabel value={100} status="done" />,
      iconProject: <IconProjectSuccess />,
      color: '#51B15C',
      date: '10 Apr 2023',
      avatar: '{ GroupedAvatars }',
    },
    {
      title: 'Design CVSaya1',
      status: 'delayed',
      icon: <CircularProgressWithLabel value={60} status="delayed" />,
      iconProject: <IconProjectDelay />,
      color: '#125ACF',
      date: '21 Maret 2023',
      avatar: '{ GroupedAvatars }',
    },
    {
      title: 'Kondoku Website1',
      status: 'process',
      icon: <CircularProgressWithLabel value={40} status="process" />,
      iconProject: <IconProjectProcess />,
      color: '#CF8812',
      date: '10 April 2023',
      avatar: '{ GroupedAvatars }',
    },
    {
      title: 'Website BIN1',
      status: 'canceled',
      icon: <CircularProgressWithLabel value={14} status="canceled" />,
      iconProject: <IconProjectCancel />,
      color: '#FF1D1D',
      date: '04 Feb 2023',
      avatar: '{ GroupedAvatars }',
    },
    {
      title: 'Danmogot 3D Asset2',
      status: 'done',
      icon: <CircularProgressWithLabel value={100} status="done" />,
      iconProject: <IconProjectSuccess />,
      color: '#51B15C',
      date: '10 Apr 2023',
      avatar: '{ GroupedAvatars }',
    },
    {
      title: 'Design CVSaya2',
      status: 'delayed',
      icon: <CircularProgressWithLabel value={60} status="delayed" />,
      iconProject: <IconProjectDelay />,
      color: '#125ACF',
      date: '21 Maret 2023',
      avatar: '{ GroupedAvatars }',
    },
    {
      title: 'Kondoku Website2',
      status: 'process',
      icon: <CircularProgressWithLabel value={40} status="process" />,
      iconProject: <IconProjectProcess />,
      color: '#CF8812',
      date: '10 April 2023',
      avatar: '{ GroupedAvatars }',
    },
    {
      title: 'Website BIN2',
      status: 'canceled',
      icon: <CircularProgressWithLabel value={14} status="canceled" />,
      iconProject: <IconProjectCancel />,
      color: '#FF1D1D',
      date: '04 Feb 2023',
      avatar: '{ GroupedAvatars }',
    },
  ];
  