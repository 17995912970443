import { IconListHistory, IconListPayroll } from '../../../assets/payroll';

const MenuPayroll = [
  {
    id: 0,
    title: 'Payroll Period',
    judul: 'Periode Pembayaran',
    description: 'List Payroll',
    deskripsi: 'Daptar Pembayaran',
    icon: <IconListPayroll />,
  },
  {
    id: 1,
    title: 'History Payslip',
    judul: 'Riwayat Slip Gaji',
    description: 'List History',
    deskripsi: 'Daptar Riwayat',
    icon: <IconListHistory />,
  },
];

export default MenuPayroll;
