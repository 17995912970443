import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { IconLocationCountry } from "../../../../assets/department";
import { Box } from "@mui/system";

export default function SelectCountry({
  data,
  setValue,
  value,
  inputValue,
  inputChange,
  loading,
}) {
  return (
    <>
      <Autocomplete
        id="country-autocomplete"
        loading={loading}
        loadingText={<div style={{ textAlign: "center" }}>loading ...</div>}
        options={data}
        inputValue={inputValue}
        onChange={setValue}
        value={value}
        onInputChange={(e, value) => inputChange(value)}
        getOptionLabel={(dept) => dept.name}
        renderOption={(props, option) => {
          return (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={option.icon}
                srcSet={option.icon}
                alt=""
              />
              {option.name}
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <IconLocationCountry />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </>
  );
}
