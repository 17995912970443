const DataTableJobs = [
  {
    title: "Position",
    judul: "Posisi",
  },
  {
    title: "Request",
    judul: "Permintaan",
  },
  {
    title: "Created Date",
    judul: "Tanggal Dibuat",
  },
  {
    title: "Status",
    judul: "Status",
  },
  {
    title: "Action",
    judul: "Aksi",
  },
];

export default DataTableJobs;
