import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IconDelete, IconDescription, IconDetail, IconEdit } from "../../../../../../assets/commons";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { TooltipsAction } from "../../../../../atoms";
import ModalDelete from "../../../../modal/modalDelete";
import { handleAction } from "../../../../../../utils/function/action";
import { handleTooltipClose, handleTooltipOpen } from "../../../../../../utils/function/tooltips";
import { DetailCompanyPolicy } from "../../../../../../pages/organization/companyPolicy";
import moment from "moment";


const CompanyPolicyTbody = ({ tbody, setData }) => {

  const [idTooltipDesc, setIdTooltipDesc] = useState('');
  const [isTooltipDesc, setIsTooltipDesc] = useState(false);

  const [idTooltipDetail, setIdTooltipDetail] = useState('');
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);
  const [idTooltipDelete, setIdTooltipDelete] = useState('');
  const [isTooltipDelete, setIsTooltipDelete] = useState(false);
  const [idTooltipEdit, setIdTooltipEdit] = useState('');
  const [isTooltipEdit, setIsTooltipEdit] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState('');
  const [companyId, setCompanyId] = useState(null);
  const [detail, setDetail] = useState([]);
  const navigate = useNavigate();
  
  useEffect(() => {
    if(companyId){
      const detail = tbody.find((data) => data.id === companyId);
      setDetail(detail);
    }

  },[companyId, tbody]);

  const handleEdit = (id) => {
    navigate(`/organization/company-policy/edit/${id}`);
  }

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            <td>
              <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={400}
                  fontSize={'14px'}
                  lineHeight={'15px'}
                  color={'#171717'}
                >
                  {item.name}
                </Typography>
                <Typography
                  fontFamily={'open-sans'}
                  fontStyle={'normal'}
                  fontWeight={600}
                  fontSize={'8px'}
                  lineHeight={'10.4px'}
                  color={'#939393'}
                >
                  Department - { item?.department?.name }
                </Typography>  
            </td>
            <td style={{
              textAlign: 'center'
            }}> 
              <TooltipsAction
                  title={item.description}
                  open={isTooltipDesc && item.id === idTooltipDesc}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{cursor: 'pointer'}}
                    onMouseEnter={() => handleTooltipOpen(item.id, setIsTooltipDesc, setIdTooltipDesc )} 
                    onMouseLeave={()=>handleTooltipClose(setIsTooltipDesc)}
                  >
                    <IconDescription />
                  </Box>
                </TooltipsAction>
            </td>
            <td>
              <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={400}
                  fontSize={'14px'}
                  lineHeight={'15px'}
                  color={'#171717'}
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  {moment(item.created_date).format('DD MMMM')}
              </Typography>
            </td>
            <td>
               {/** action */}
               <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={1}
              >
                {/* --- Detail --- */}
                <TooltipsAction
                  title='Detail'
                  open={isTooltipDetail && item.id === idTooltipDetail}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{cursor: 'pointer'}}
                    onClick={() => handleAction(item.id, setIsModalOpen, setModal, setCompanyId, 'detail')}
                    onMouseEnter={() => handleTooltipOpen(item.id, setIsTooltipDetail, setIdTooltipDetail )} 
                    onMouseLeave={()=>handleTooltipClose(setIsTooltipDetail)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>

                {/* --- Delete --- */}
                <TooltipsAction
                  title='Delete'
                  open={isTooltipDelete && item.id === idTooltipDelete}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{cursor: 'pointer'}}
                    onClick={() => handleAction(item.id, setIsModalOpen, setModal, setCompanyId, 'delete')}
                    onMouseEnter={() => handleTooltipOpen(item.id, setIsTooltipDelete, setIdTooltipDelete )} 
                    onMouseLeave={()=>handleTooltipClose(setIsTooltipDelete)}
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>

                {/* --- Edit --- */}
                <TooltipsAction
                  title='Edit'
                  open={isTooltipEdit && item.id === idTooltipEdit}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{cursor: 'pointer'}}
                    onClick={() => handleEdit(item.id)}
                    onMouseEnter={() => handleTooltipOpen(item.id, setIsTooltipEdit, setIdTooltipEdit)} 
                    onMouseLeave={()=>handleTooltipClose(setIsTooltipEdit)}
                  >
                    <IconEdit />
                  </Box>
                </TooltipsAction>

              </Box>
            </td>
          </tr>
        ))}
      </tbody>
       {/** modal */}
       { modal === 'detail' ? (
        <DetailCompanyPolicy 
          detail={detail}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
        ): modal === 'delete' ? (
          <ModalDelete
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              title={'company policy'}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              id={companyId}
              data={tbody}
              setData={setData}
            />
        ): ''}
    </>
  );
};

export default CompanyPolicyTbody;
