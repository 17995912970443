import { Box } from "@mui/material";
import {
  HomepageAttendance,
  HomepageAttendee,
  HomepageCalendar,
  HomepageCard,
  HomepageProject,
} from "../../components/organisms/homepage";

const Homepage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* --- Card --- */}
      <HomepageCard />
      {/* --- Chart --- */}
      <HomepageAttendance />
      {/* --- Attendee --- */}
      <HomepageAttendee />
      {/* --- Project --- */}
      <HomepageProject />
      {/* --- Calendar --- */}
      {/* <HomepageCalendar /> */}
    </Box>
  );
};

export default Homepage;
