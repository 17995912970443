import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import moment from 'moment';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { Card, Modals } from '../../../../components/atoms';
import { handleClose } from '../../../../utils/function/modal';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../../../features/language';
import { IconArrow, IconClose } from '../../../../assets/commons';

const CoreHRComplaintDetail = ({ isOpen, onClose, detail, isLoading, images }) => {
  const [id, setId] = useState(0);
  const data = images[id];
  const lang = useSelector(getLanguage)

  const handleNext = () => {
    setId(id + 1);
  };
  const handlePrev = () => {
    setId(id - 1);
  };

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: '80vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
        }}
      >
        <Card>
          <Box p={'30px'}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '7px',
                position: 'sticky',
                top: 0,
                bgcolor: 'background.paper',
                p: '10px 0px 10px 0px',
                zIndex: 999,
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontSize={'20px'}
                fontWeight={600}
                lineHeight={'27px'}
                color={'#171717'}
              >
                {lang === 'Id'? 'Detail Keluhan': 'Complaint Details'}
              </Typography>
              <IconButton sx={{ p: '0px' }} onClick={onClose}>
                <IconClose />
              </IconButton>
            </Box>

            {!isLoading ? (
              <Box mt={'20px'} component={'form'} noValidate autoComplete="off">
                {/* --- Name Department --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id'? 'Departemen': 'Dept'}
                  </Typography>
                  <Box
                    style={{
                      width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      display: 'inline-block',
                    }}
                  >
                    <Typography mx={'10px'} py={'10px'}>
                      {detail?.department?.name}
                    </Typography>
                  </Box>
                </Box>
                {/* --- Employee --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id'? 'Karyawan': 'Employee'}
                  </Typography>
                  <Box
                    style={{
                      width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      display: 'inline-block',
                    }}
                  >
                    <Typography mx={'10px'} py={'10px'}>
                      {detail?.employee?.name}
                    </Typography>
                  </Box>
                </Box>
                {/* --- Name Complaints --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id'? 'Nama Keluhan': 'Name Complaints'}
                  </Typography>
                  <Box
                    style={{
                      width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      display: 'inline-block',
                    }}
                  >
                    <Typography mx={'10px'} py={'10px'}>
                      {detail?.complaint_name}
                    </Typography>
                  </Box>
                </Box>
                {/* --- Date --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id'? 'Tanggal Keluhan' : 'Complaint Date'}
                  </Typography>
                  <Box
                    style={{
                      width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      display: 'inline-block',
                    }}
                  >
                    <Typography mx={'10px'} py={'10px'}>
                      {moment(detail?.date).format('D MMMM YYYY')}
                    </Typography>
                  </Box>
                </Box>

                {/* --- Award Image --- */}
                <Box mb={'20px'} p={'0px'} sx={{ position: 'relative' }}>
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id'? 'Gambar Keluhan': 'Complaint Image'}
                  </Typography>
                  <Box width={'100%'}>
                    <img
                      src={data}
                      alt="images"
                      style={{
                        width: '100%',
                        height: '200px',
                        transition: 'ease-in',
                        transitionDuration: '100s',
                        transitionDelay: '100s',
                        borderRadius: '8px',
                      }}
                    />
                    <Box
                      display={'flex'}
                      position={'absolute'}
                      top={110}
                      width={'100%'}
                      justifyContent={
                        id > 0
                          ? id < images.length - 1
                            ? 'space-between'
                            : 'start'
                          : 'end'
                      }
                    >
                      {id > 0 && (
                        <IconButton
                          onClick={handlePrev}
                          sx={{
                            transform: 'scaleX(-1)',
                            paddingX: '14px',
                          }}
                        >
                          <IconArrow />
                        </IconButton>
                      )}
                      {id < images.length - 1 && (
                        <IconButton
                          onClick={handleNext}
                          sx={{
                            paddingX: '14px',
                          }}
                        >
                          <IconArrow />
                        </IconButton>
                      )}
                    </Box>
                    <Box
                      p={'2px 6px'}
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      sx={{ background: 'rgba(23, 23, 23, 0.6)' }}
                      position={'absolute'}
                      borderRadius={'4px'}
                      top={'40px'}
                      left={'20px'}
                    >
                      <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontSize={'12px'}
                        fontWeight={600}
                        lineHeight={'13px'}
                        letterSpacing={'1px'}
                        color={'#fff'}
                      >
                        {id + 1}/{images.length}
                      </Typography>
                    </Box>
                    {/* --- Image Footer Handle --- */}
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      position={'absolute'}
                      width={'100%'}
                      top={'202px'}
                    >
                      <Box
                        display={'flex'}
                        p={'6px'}
                        sx={{
                          background: 'rgba(29, 29, 29, 0.5)',
                          borderRadius: '8px 8px 0px 0px',
                        }}
                      >
                        {images.map((item, idx) => (
                          <Box
                            width={8}
                            height={8}
                            sx={{
                              cursor: 'pointer',
                              background:
                                id === idx
                                  ? '#fff'
                                  : 'rgba(255, 255, 255, 0.5);',
                            }}
                            borderRadius={'50%'}
                            mx={'3px'}
                            key={idx}
                            onClick={() => setId(idx)}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* --- Description --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id'? 'Deskripsi': 'Description'}
                  </Typography>
                  <Box
                    style={{
                      width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      display: 'inline-block',
                    }}
                  >
                    <Typography 
                      mx={'10px'} 
                      py={'10px'} 
                      height={'auto'} 
                      maxHeight={'160px'} 
                      sx={{overflowY: 'scroll'}}
                    >
                      {detail?.description}
                    </Typography>
                  </Box>
                </Box>

                {/* --- Close --- */}
                <Button
                  variant="contained"
                  disableRipple
                  onClick={()=>handleClose(onClose, setId)}
                  fullWidth
                  sx={{ height: '62px' }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'20px'}
                    fontWeight={'700px'}
                    lineHeight={'27px'}
                    letterSpacing={'1px'}
                    textTransform={'none'}
                  >
                    {lang === 'Id'? 'Keluar': 'Close'}
                  </Typography>
                </Button>
                
              </Box>
            ) : (
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <CircularProgress color="secondary" />
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </Modals>
  );
};

export default CoreHRComplaintDetail;
