import { API } from "../../../API";

export const getListVacancy = (params) =>
  API.get("job-vacancies", { params: params });

export const getListVacancyCandidate = (id, params) =>
  API.get(`job-vacancies/${id}/candidates`, { params });

export const getSummaryVacancy = (params) =>
  API.get("job-vacancies/summary", { params: params });

// --- Post Employees ---
export const postVacancy = (data) => API.post("job-vacancies", data);

export const postMessageCandidate = (id, data) =>
  API.post(
    `job-vacancies/${id.vacancy_id}/candidates/${id.candidate_id}/message`,
    data
  );

export const postInviteCandidate = (id, data) =>
  API.post(`job-vacancies/${id}/invite`, data);

export const putRejectCandidate = (id, data) =>
  API.put(
    `job-vacancies/${id.vacancy_id}/candidates/${id.candidate_id}/reject`,
    data
  );

export const putCandidateInterview = (id, data) =>
  API.put(
    `job-vacancies/${id.vacancy_id}/candidates/${id.candidate_id}/interview`,
    data
  );

export const putVacancyCandidateById = (id) =>
  API.delete(`job-vacancies/${id.vacancy_id}/candidate/${id.candidate_id}`);

export const putCandidatetNextStage = (id, data) =>
  API.put(`job-vacancies/${id.vacancy_id}/candidates/${id.candidate_id}`, data);

export const getAppliedCandidateById = (id, candidate_id) =>
  API.get(`job-vacancies/${id}/candidates/${candidate_id}`);

export const uploadVacancy = (data) =>
  API.postForm("job-vacancies/uploads", data);

// --- Get Vacancy By Id ---
export const getVacancyById = (id, params) =>
  API.get(`job-vacancies/${id}`, { params: params });

// --- Update Vacancy By Id ---
export const updateVacancyById = (data, id) =>
  API.put(`job-vacancies/${id}`, data);

// --- Update Vacancy By Id ---
export const updateStatusVacancyById = (id) =>
  API.put(`job-vacancies/${id}/status`);

// --- Delete Vacancy By Id ---
export const deleteVacancyById = (id) => API.delete(`job-vacancies/${id}`);
