import { Box } from "@mui/material"
import { DisplayCardProject, SummaryChartProject, SummaryTableProject } from "../../../../components/organisms/project"

const summaryProject = () => {
  return(
    <Box>
      <SummaryChartProject/>
      <DisplayCardProject/>
      <SummaryTableProject/>
    </Box>
  )
}

export default summaryProject