import { Box, Typography } from "@mui/material";
import React from "react";
import { TableSkeleton } from "../../../atoms";
import Table from "../../../atoms/table";
import { IconBenefitEmployee } from "../../../../assets/employee";

const TableComponent = ({ isLoading, TableHead, TableBody, tbody, col }) => {
  return (
    <Box>
      {isLoading ? (
        <Table>
          {TableHead}
          <TableSkeleton col={col} />
        </Table>
      ) : (
        <Table>
          {TableHead}
          {tbody.length === 0 ? (
            <tbody>
              <tr>
                <td style={{ textAlign: "center" }} colSpan={col}>
                  <Typography sx={{ textAlign: "center", padding: "60px 0px" }}>
                    <IconBenefitEmployee width="199" height="155" />

                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#9E9D9D",
                        textAlign: "center",
                      }}
                    >
                      No Data
                    </Typography>
                  </Typography>
                </td>
              </tr>
            </tbody>
          ) : (
            TableBody
          )}
        </Table>
      )}
    </Box>
  );
};

export default TableComponent;
