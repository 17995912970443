import { Box, Typography } from "@mui/material"
import { Card } from "../../../atoms"
import MenuActionBoard from "../../menu/project/menuActionBoard";

const CardBoard = ({title, type, hasTask, children}) => {
  const color = type === 'closed' ? '#125ACFCC' : '#51B15C';

  const style = hasTask ? {
    width: '264px',
    minHeight: '47px',
    paddingX: '16px',
  } : 
  {
    width: '264px',
    height: '47px',
    paddingX: '16px',
  }
  

  return(
    <Card sx={style}>
      <Box
        display={'flex'}
        alignItems={'center'} 
        justifyContent={'space-between'}
        paddingTop={'5px'}
      >
        <Typography sx={{
            fontSize: '16px',
            paddingLeft: '10px',
            color: '#171717',
            letterSpacing: '0.24px',
            borderLeft: `5px solid ${color}`,
            borderRadius: '3px'
          }}
        >
          {title}
        </Typography>
        <MenuActionBoard/>
      </Box>
      {children}
    </Card>
  )
}

export default CardBoard