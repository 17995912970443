import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconClose } from '../../../../assets/commons';
import {
  IconAwards,
  IconCash,
  IconDate,
  IconDescription,
  IconEmployee,
  IconGift,
  IconImage,
} from '../../../../assets/coreHr/awards';
import { Card } from '../../../../components/atoms';
import { getListEmployee, postImage } from '../../../../configs/axios/apiPath';
import { postAwards } from '../../../../configs/axios/apiPath/fetchCoreHR';
import DataLogin from '../../../../data/dataLogin';
import { getCompany } from '../../../../features/companies';
import { getLanguage } from '../../../../features/language';
import {
  ValidateDescription,
  ValidateString,
} from '../../../../utils/validate';

const CoreHRAwardsAdd = () => {
  const [inputValue, setInputValue] = useState('');
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [name, setName] = useState('');
  const [gift, setGift] = useState('');
  const [cash, setCash] = useState(0);
  const [date, setDate] = useState('');
  const [total, setTotal] = useState(1);
  const [images, setImages] = useState('');
  const [description, setDescription] = useState('');
  const [isFocusName, setIsFocusName] = useState(false);
  const [isFocusGift, setIsFocusGift] = useState(false);
  const [isFocusCash, setIsFocusCash] = useState(false);
  const [isFocusDate, setIsFocusDate] = useState(false);
  const [isFocusEmployee, setIsFocusEmployee] = useState(false);
  const [isFocusDescription, setIsFocusDescription] = useState(false);
  const [isfocus, setIsFocus] = useState(false);
  const [isImageClick, setIsImageClick] = useState(false);

  const getData = DataLogin();
  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const lang = useSelector(getLanguage);
  const placeHolderId = 'Ketik disini';
  const placeHolderEn = 'Type here';

  const [isLoading, setIsLoading] = useState(false);
  const MAX_IMAGE_SIZE = 1 * 1024 * 1024;
  const [errorMsg, setErrorMsg] = useState('');

  // --- Handle Input Change ---
  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  // --- Handle Selected Employee ---
  const handleSelectEmployee = (event, value) => {
    setSelectedEmployee(value);
  };

  // --- List Employee ---
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          page_size: total,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListEmployee(params);

          setEmployeeData(data.employee);
          if (data.page_info.total > 0) {
            setTotal(data.page_info.total);
          }
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, total]);

  // --- Handle Upload Image ---
  const handleFileChange = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (file) {
      // Validasi tipe file
      if (!file.type.startsWith('image/')) {
        const msgId =
          'Jenis file tidak valid. Hanya file gambar yang diizinkan.';
        const msgEn = 'Invalid file type. Only image files are allowed.';
        if (lang === 'Id') {
          setErrorMsg(msgId);
        } else {
          setErrorMsg(msgEn);
        }
        return;
      }

      // Validasi ukuran file
      if (file.size > MAX_IMAGE_SIZE) {
        const msgId =
          'Ukuran file melebihi batas maksimum. Silakan pilih file yang lebih kecil.';
        const msgEn =
          'File size exceeds the maximum limit. Please choose a smaller file.';
        if (lang === 'Id') {
          setErrorMsg(msgId);
        } else {
          setErrorMsg(msgEn);
        }
        return;
      }

      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append('image', file);

        const {
          data: { data },
        } = await postImage(formData, getData.token);
        setImages(data?.image_url);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  // --- Hande Image Button ---
  const handleImageButton = () => {
    setErrorMsg('');
    setIsImageClick(true);
  };

  // --- Handle Delete Image ---
  const handleDeleteImage = () => {
    setImages('');
  };

  // --- Handle Submit ---
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      company_id: company,
      name_award: name,
      employee_id: selectedEmployee?.id,
      gift_name: gift,
      cash: cash,
      date: date,
      image_url: images,
      description: description,
    };
    setIsLoading(true);
    if (company) {
      try {
        const response = await postAwards(data);

        if (
          response.data &&
          response.data.data &&
          response.data.data.image_url
        ) {
          setImages(response.data.data.image_url);
        } else {
          setImages(null);
        }
        navigate('/core-hr');
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Card>
      <Box p={'20px'}>
        <Box borderBottom={'0.2px solid #939393'} p={'0px 0px 15px 0px'}>
          <Typography
            fontFamily={'sans-serif'}
            fontStyle={'normal'}
            fontWeight={600}
            fontSize={'20px'}
            lineHeight={'27px'}
            letterSpacing={'0.01em'}
            color={'#171717'}
          >
            {lang === 'Id' ? 'Tambah' : 'Add'}
          </Typography>
        </Box>
        <form
          onSubmit={handleSubmit}
          style={{
            marginTop: '20px',
            fontWeight: '600px',
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '0.01em',
            color: '#171717',
          }}
        >
          {/* --- Name Awards --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  {lang === 'Id' ? 'Nama Penghargaan' : 'Name Awards'}
                </InputLabel>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={lang === 'Id' ? placeHolderId : placeHolderEn}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  onFocus={() => setIsFocusName(false)}
                  onBlur={() => setIsFocusName(true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {isFocusName ? <IconAwards /> : ''}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      background: '#f9f9f9',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      },
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- List Employee --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel style={{ marginBottom: '5px' }}>
                  {lang === 'Id' ? 'Karyawan' : 'Employee'}
                </InputLabel>
                <Autocomplete
                  value={selectedEmployee}
                  onChange={handleSelectEmployee}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  options={employeeData}
                  getOptionLabel={(employee) => employee.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder={
                        lang === 'Id' ? placeHolderId : placeHolderEn
                      }
                      onFocus={() => setIsFocusEmployee(false)}
                      onBlur={() => setIsFocusEmployee(true)}
                      InputLabelProps={{ shrink: false }}
                      sx={{
                        background: '#f9f9f9',
                        '& .MuiOutlinedInput-root': {
                          background: 'transparent',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start" sx={{ pl: '7px' }}>
                            {isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              isFocusEmployee && <IconEmployee />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Gift and Cash --- */}
          <Grid container columns={2} spacing={2}>
            {/* --- Gift --- */}
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  {lang === 'Id' ? 'Hadiah' : 'Gift'}
                </InputLabel>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  placeholder={lang === 'Id' ? placeHolderId : placeHolderEn}
                  value={gift}
                  onChange={(event) => setGift(event.target.value)}
                  onFocus={() => setIsFocusGift(false)}
                  onBlur={() => setIsFocusGift(true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {isFocusGift ? <IconGift /> : ''}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: {
                        xl: '60px',
                        lg: '45px',
                        md: '45px',
                        sm: '60px',
                        xs: '60px',
                      },
                      borderRadius: {
                        xl: '16px',
                        lg: '10px',
                        md: '16px',
                        sm: '16px',
                        xs: '16px',
                      },
                      background: '#f9f9f9',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      },
                    },
                  }}
                />
              </Box>
            </Grid>
            {/* --- Cash --- */}
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  {lang === 'Id' ? 'Uang Tunai' : 'Cash'}
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  type={'number'}
                  placeholder={lang === 'Id' ? placeHolderId : placeHolderEn}
                  value={cash}
                  onChange={(event) => setCash(event.target.value)}
                  onFocus={() => setIsFocusCash(false)}
                  onBlur={() => setIsFocusCash(true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {isFocusCash ? <IconCash /> : ''}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: {
                        xl: '60px',
                        lg: '45px',
                        md: '45px',
                        sm: '60px',
                        xs: '60px',
                      },
                      borderRadius: {
                        xl: '16px',
                        lg: '10px',
                        md: '16px',
                        sm: '16px',
                        xs: '16px',
                      },
                      background: '#f9f9f9',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      },
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Date --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  {lang === 'Id' ? 'Tanggal' : 'Date'}
                </InputLabel>
                <TextField
                  fullWidth
                  required
                  type="date"
                  size="small"
                  variant="outlined"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  onFocus={() => setIsFocusDate(false)}
                  onBlur={() => setIsFocusDate(true)}
                  sx={{
                    background: '#f9f9f9',
                    '& .MuiOutlinedInput-root': {
                      background: 'transparent',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {isFocusDate ? <IconDate /> : ''}
                      </InputAdornment>
                    ),
                    style: {
                      height: '40px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Image --- */}
          <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
            <InputLabel style={{ marginBottom: '5px' }}>
              {lang == 'Id' ? 'Gambar Penghargaan' : 'Award Image'}
            </InputLabel>
            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  placeholder={lang === 'Id' ? placeHolderId : placeHolderEn}
                  value={images}
                  onChange={(e) => setImages(e.target.value)}
                  disabled
                  sx={{
                    background: '#f9f9f9',
                    '& .MuiOutlinedInput-root': {
                      background: 'transparent',
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover:not(.Mui-disabled) fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-disabled fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {isImageClick ? <IconImage /> : ''}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="contained"
                          disabled={isLoading}
                          component="label"
                          onClick={handleImageButton}
                          sx={{
                            background: '#fff',
                            borderRadius: '8px',
                            my: '5px',
                            color: '#171717',
                            fontWeight: '400px',
                            fontSize: '12px',
                            lineHeight: '14px',
                            letterSpacing: '0.02em',
                          }}
                        >
                          {lang === 'Id' ? 'Unggah' : 'Browse'}
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={handleFileChange}
                          />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={2}
                xs={2}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box ml={'10px'}>
                  {ValidateString(images) ? (
                    <Box position="relative" width={50}>
                      <img
                        src={images}
                        alt="image"
                        width={50}
                        height={50}
                        style={{ borderRadius: '8px' }}
                      />
                      <IconButton
                        size="small"
                        onClick={() => handleDeleteImage()}
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          backgroundColor: 'white',
                          p: '0px',
                        }}
                      >
                        <IconClose />
                      </IconButton>
                    </Box>
                  ) : isLoading ? (
                    <Box textAlign={'center'} my={'5px'}>
                      <CircularProgress color="warning" size={30} />
                    </Box>
                  ) : (
                    <Box>
                      <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontWeight={600}
                        fontSize={'10px'}
                        lineHeight={'14px'}
                        letterSpacing={'1px'}
                        color={'#171717'}
                      >
                        {lang === 'Id'
                          ? 'Tidak ada yg dipilih'
                          : 'No File Choosen'}
                      </Typography>
                      <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontWeight={400}
                        fontSize={'8px'}
                        lineHeight={'11px'}
                        letterSpacing={'1px'}
                        color={'#303030'}
                      >
                        {lang === 'Id'
                          ? 'Unggah hanya file png, jpg, jpeg dan ukuran maksimal 1Mb'
                          : 'Upload files only png, jpg, jpeg and size 1Mb'}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
            {isImageClick && !ValidateString(images) && (
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'15px'}
                letterSpacing={'1px'}
                color={'red'}
                my={'5px'}
              >
                {lang === 'Id' ? 'Harus diisi' : "Can't Be Empty"}
              </Typography>
            )}
            {errorMsg && (
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'15px'}
                letterSpacing={'1px'}
                color={'red'}
                my={'5px'}
              >
                {errorMsg}
              </Typography>
            )}
          </Box>

          {/* --- Description --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  {lang === 'Id' ? 'Deskripsi' : 'Description'}
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  required
                  multiline
                  minRows={5}
                  variant="outlined"
                  size="small"
                  placeholder={lang === 'Id' ? placeHolderId : placeHolderEn}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  onFocus={() => setIsFocusDescription(false)}
                  onBlur={() => setIsFocusDescription(true)}
                  onClick={() => setIsFocus(true)}
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{
                        position: 'absolute',
                        top: 23,
                      }}
                    >
                      {isFocusDescription ? <IconDescription /> : ''}
                    </InputAdornment>
                  }
                  sx={{
                    fontSize: 'normal',
                    background: '#f9f9f9',
                    '& .MuiOutlinedInput-root': {
                      background: 'transparent',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '& textarea': {
                      paddingLeft: '24px',
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          {isfocus && !ValidateDescription(description) && (
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontWeight={400}
              fontSize={'12px'}
              lineHeight={'15px'}
              letterSpacing={'1px'}
              color={'red'}
              my={'5px'}
            >
              {lang === 'Id' ? 'Minimal 10 karakter' : 'Minimals 10 characters'}
            </Typography>
          )}
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => setIsImageClick(true)}
            sx={{ width: '136px', marginTop: '21px' }}
          >
            {lang === 'Id' ? 'Simpan' : 'Save'}
          </Button>
        </form>
      </Box>
    </Card>
  );
};

export default CoreHRAwardsAdd;
