import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getListAttendee } from '../../../../configs/axios/apiPath';
import { DataTableAttendee } from '../../../../data/table/homepage';
import { getCompany } from '../../../../features/companies';
import { Card, Pagination, Search } from '../../../atoms';
import TableComponent from '../../../molecules/table/mainTable';
import {
  HomepageTbody,
  HomepageThead,
} from '../../../molecules/table/tableHomepage';

const HomepageAttendee = () => {
  const [pageSize, setPageSize] = useState(4);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [dept, setDept] = useState('');
  const [position, setPosition] = useState('');
  const [status, setStatus] = useState('');
  const [sortir, setSortir] = useState('');
  const [lastPage, setLastPage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const company = useSelector(getCompany);
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);

  // --- Fetch data ---
  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          page_size: pageSize,
          page: page,
          company_id: company,
          search: search,
          dept: dept,
          position: position,
          status: status,
          name: sortir,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListAttendee(params);
          setLastPage(data?.page_info?.last_page);
          setEmployee(data?.attendances);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [
    page,
    search,
    sortir,
    dept,
    position,
    status,
    company,
    pageSize,
    navigate,
  ]);

  return (
    <Card sx={{ padding: '20px  25px', mt: '20px' }}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        {/* --- Title --- */}
        <Typography
          fontFamily={'sans-serif'}
          fontStyle={'normal'}
          fontWeight={600}
          fontSize={'20px'}
          lineHeight={'27px'}
          letterSpacing={'0.01em'}
          color={'#171717'}
        >
          Attendee
        </Typography>
        {/* --- Search --- */}
        <Search
          placeholder="Type here to search"
          setSearch={(e) => setSearch(e)}
          page={page}
          setPage={(e) => setPage(e)}
        />
      </Box>

      {/* --- Table --- */}
      <Box sx={{ mt: '24px', mb: '16px' }}>
        <TableComponent
          isLoading={isLoading}
          tbody={employee}
          TableHead={
            <HomepageThead
              thead={DataTableAttendee}
              sortir={sortir}
              setSortir={(e) => setSortir(e)}
              dept={dept}
              setDept={(e) => setDept(e)}
              position={position}
              setPosition={(e) => setPosition(e)}
              status={status}
              setStatus={(e) => setStatus(e)}
            />
          }
          TableBody={<HomepageTbody tbody={employee} />}
          col={4}
        />
      </Box>

      {/* --- Pagination --- */}
      <Box display={'flex'} justifyContent={'end'}>
        <Pagination page={page} setPage={setPage} lastPage={lastPage} />
      </Box>
    </Card>
  );
};

export default HomepageAttendee;
