import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Edit as EditIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card } from '../../../../components/atoms';
import { getCompany } from '../../../../features/companies';
import dateFormat from '../../../../utils/format/dateFormat';
import { setNavigation } from '../../../../features/navigation';
import { IconDelete } from '../../../../assets/commons';
import { getLanguage } from '../../../../features/language';
import { postShift } from './../../../../configs/axios/apiPath/fetchEmployee/fetchEmployeeShedule/index';

export default function EmployeeScheduleAdd() {
  const [name, setName] = useState('');
  const [clockIn, setClockIn] = useState('');
  const [clockOut, setClockOut] = useState('');
  const [startBreak, setStartBreak] = useState('');
  const [endBreak, setEndBreak] = useState('');
  const [breakDuration, setBreakDuration] = useState(0);
  const company = useSelector(getCompany);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const lang = useSelector(getLanguage);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const datas = {
      name: name,
      clock_in: dateFormat(clockIn),
      clock_out: dateFormat(clockOut),
      start_break: dateFormat(startBreak),
      end_break: dateFormat(endBreak),
      break_duration: breakDuration,
      company_id: company,
    };
    setIsLoading(true);
    try {
      const response = await postShift(datas);
      console.info(response);
      if (response.data.meta.code === 20000) {
        navigate('/employee/schedule');
        dispatch(setNavigation('Schedule'));
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        localStorage.removeItem('data');
        navigate(`/`);
      }
    }
  };

  const handleClear = (setItem) => {
    setItem('');
  };

  return (
    <Card>
      <Box p={'20px'}>
        <Box borderBottom={'0.2px solid #939393'} p={'0px 0px 15px 0px'}>
          <Typography
            fontWeight={'600px'}
            fontSize={'20px'}
            lineHeight={'27px'}
            letterSpacing={'0.01em'}
            color={'#171717'}
          >
            {lang === 'Id' ? 'Tambah' : 'Add'}
          </Typography>
        </Box>
        <form
          style={{
            marginTop: '20px',
            fontWeight: '600px',
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '0.01em',
            color: '#171717',
          }}
          onSubmit={handleSubmit}
        >
          {/* --- Name --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'9px'}>
                <InputLabel style={{ marginBottom: '5px' }}>Name</InputLabel>
                <Box display={'flex'}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EditIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* --- Clock In --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'9px'}>
                <InputLabel style={{ marginBottom: '5px' }}>
                  Clock In
                </InputLabel>
                <Box display={'flex'}>
                  <TextField
                    variant="outlined"
                    size="small"
                    type="time"
                    fullWidth
                    required
                    value={clockIn}
                    onChange={(e) => setClockIn(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccessTimeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <IconButton
                    sx={{ p: '0px', ml: '30px' }}
                    onClick={() => handleClear(setClockIn)}
                  >
                    <IconDelete />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* --- Clock Out --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'9px'}>
                <InputLabel style={{ marginBottom: '5px' }}>
                  Clock Out
                </InputLabel>
                <Box display={'flex'}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    type="time"
                    value={clockOut}
                    onChange={(e) => setClockOut(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccessTimeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <IconButton
                    sx={{ p: '0px', ml: '30px' }}
                    onClick={() => handleClear(setClockOut)}
                  >
                    <IconDelete />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* --- Start Break --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'9px'}>
                <InputLabel style={{ marginBottom: '5px' }}>
                  Start Break
                </InputLabel>
                <Box display={'flex'}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    type="time"
                    value={startBreak}
                    onChange={(e) => setStartBreak(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccessTimeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <IconButton
                    sx={{ p: '0px', ml: '30px' }}
                    onClick={() => handleClear(setStartBreak)}
                  >
                    <IconDelete />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* --- End Break --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'9px'}>
                <InputLabel style={{ marginBottom: '5px' }}>
                  End Break
                </InputLabel>
                <Box display={'flex'}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    type="time"
                    value={endBreak}
                    onChange={(e) => setEndBreak(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccessTimeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <IconButton
                    sx={{ p: '0px', ml: '30px' }}
                    onClick={() => handleClear(setEndBreak)}
                  >
                    <IconDelete />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* -- Break Duration --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'9px'}>
                <InputLabel style={{ marginBottom: '5px' }}>
                  Break Duration
                </InputLabel>
                <Box display={'flex'}>
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    required
                    value={breakDuration}
                    onChange={(e) => setBreakDuration(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccessTimeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <IconButton
                    sx={{ p: '0px', ml: '30px' }}
                    onClick={() => handleClear(setBreakDuration)}
                  >
                    <IconDelete />
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: '136px', marginTop: '21px' }}
          >
            {lang === 'Id' ? 'Simpan' : 'Save'}
          </Button>
        </form>
      </Box>
    </Card>
  );
}
