import { API } from "../../../API";

// --- List CompanyPolicies ---
export const getListCompanyPolicies = (params) =>
  API.get('company-policies', { params: params });

// --- Post CompanyPolicies ---
export const postCompanyPolicies = (data) => 
  API.post('company-policies', data);

// --- Get CompanyPolicies By Id ---
export const getCompanyPoliciesById = (id) => 
API.get(`company-policies/${id}`);

// --- Update CompanyPolicies By Id ---
export const updateCompanyPoliciesById = (data, id) => 
API.put(`company-policies/${id}`, data);

// --- Delete CompanyPolicies By Id ---
export const deleteCompanyPoliciesById = (id) => 
API.delete(`company-policies/${id}`);
  