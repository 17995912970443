import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconArrowDown } from '../../../../../assets/topNav/icons';

const Setting = () => {
  const [anchorProfile, setAnchorProfile] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorProfile(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorProfile(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('cvSayaLogin');
    localStorage.removeItem('cvSayaData');
    navigate('/users');
  };

  return (
    <Box display={'flex'}>
      <img
        src="https://img.freepik.com/free-vector/cute-cool-boy-dabbing-pose-cartoon-vector-icon-illustration-people-fashion-icon-concept-isolated_138676-5680.jpg?w=740&t=st=1680315594~exp=1680316194~hmac=d93dff4a1eb0517cf4c8ef192188e8bc9864cef283354ad3b7f698bd57594835"
        style={{
          width: '30px',
          height: '30px',
          objectFit: 'cover',
          borderRadius: '50%',
          position: 'relative',
          overflow: 'hidden',
        }}
        alt="profile pict"
      />

      <IconButton sx={{ p: '0px', ml: '12px' }} onClick={handleClick}>
        <IconArrowDown cursor="pointer" />
      </IconButton>

      <Menu
        anchorEl={anchorProfile}
        open={Boolean(anchorProfile)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default Setting;
