import { Box, Typography, Avatar, Stack } from "@mui/material";
import { TooltipsAction } from "../../../../../atoms";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useState, useEffect } from "react";
import {
  IconDelete,
  IconDetail,
  IconEdit,
} from "../../../../../../assets/commons";
import { ModalDelete } from "../../../../modal";
import { handleAction } from "../../../../../../utils/function/action";
import RecruitmentCandidateDetail from "../../../../../../pages/recruitment/candidate/detail/DetailModal2";
import {
  getAppliedCandidateById,
  putCandidateInterview,
  putRejectCandidate,
  putCandidatetNextStage,
  getDocumentOfficeFacillitiesById,
} from "../../../../../../configs/axios/apiPath";

const getStatusIcon = (status) => {
  switch (status) {
    case "bad":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="20"
          fill="none"
          viewBox="0 0 25 20"
        >
          <path
            fill="#E40A0A"
            fillRule="evenodd"
            d="M5.385 3.077A3.077 3.077 0 018.462 0h3.077a3.077 3.077 0 013.077 3.077v.21c.957.087 1.904.202 2.845.343C18.952 3.853 20 5.15 20 6.622v3.11c0 1.243-.753 2.413-1.986 2.823A25.36 25.36 0 0110 13.847a25.355 25.355 0 01-8.014-1.292C.753 12.145 0 10.975 0 9.733V6.622C0 5.15 1.048 3.852 2.54 3.63a50.063 50.063 0 012.845-.343v-.21zm7.692 0v.092a50.76 50.76 0 00-6.154 0v-.092a1.539 1.539 0 011.539-1.538h3.077a1.538 1.538 0 011.538 1.538zM10 11.539A.77.77 0 1010 10a.77.77 0 000 1.539z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#E40A0A"
            d="M.766 16.564v-2.868c.233.128.478.235.73.318a26.896 26.896 0 008.5 1.37c2.967 0 5.826-.48 8.5-1.369.259-.086.503-.194.732-.319v2.868c0 1.49-1.074 2.798-2.588 2.998A50.37 50.37 0 019.997 20a50.44 50.44 0 01-6.644-.438c-1.514-.2-2.587-1.509-2.587-2.998z"
          ></path>
          <rect
            width="12.308"
            height="12.308"
            x="12.113"
            y="7.5"
            fill="#fff"
            rx="6.154"
          ></rect>
          <path
            fill="#E40A0A"
            d="M18.265 12.988l2.333-2.334.667.667-2.333 2.334 2.333 2.333-.667.666-2.333-2.333-2.333 2.333-.666-.667 2.333-2.333-2.333-2.333.666-.666 2.333 2.334v-.001z"
          ></path>
        </svg>
      );
    case "recommended":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="20"
          fill="none"
          viewBox="0 0 24 20"
        >
          <path
            fill="#51B15C"
            fillRule="evenodd"
            d="M5.185 3.077c0-.816.313-1.599.868-2.176A2.908 2.908 0 018.148 0h2.963c.786 0 1.54.324 2.096.901.555.577.867 1.36.867 2.176v.21c.922.087 1.835.202 2.74.343 1.436.223 2.446 1.52 2.446 2.992v3.11c0 1.243-.725 2.413-1.912 2.823a23.598 23.598 0 01-7.718 1.292 23.593 23.593 0 01-7.718-1.292C.725 12.145 0 10.975 0 9.733V6.622C0 5.15 1.01 3.852 2.446 3.63c.91-.141 1.823-.256 2.74-.343v-.21zm7.408 0v.092a47.071 47.071 0 00-5.926 0v-.092c0-.408.156-.8.434-1.088.278-.288.654-.45 1.047-.45h2.963c.393 0 .77.162 1.048.45.278.289.434.68.434 1.088zM9.63 11.539a.727.727 0 00.524-.226.784.784 0 00.217-.543c0-.204-.078-.4-.217-.544A.727.727 0 009.63 10a.727.727 0 00-.524.226.785.785 0 00-.217.544c0 .204.078.4.217.543a.727.727 0 00.524.226z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#51B15C"
            d="M.738 16.564v-2.868c.225.128.461.235.704.318a25.03 25.03 0 008.185 1.37c2.857 0 5.61-.48 8.185-1.369.25-.086.484-.194.705-.319v2.868c0 1.49-1.034 2.798-2.492 2.998a46.818 46.818 0 01-12.794 0c-1.459-.2-2.493-1.509-2.493-2.998z"
          ></path>
          <rect
            width="11.852"
            height="12.308"
            x="11.664"
            y="7.5"
            fill="#fff"
            rx="5.926"
          ></rect>
          <path
            fill="#51B15C"
            fillRule="evenodd"
            d="M14.59 12.654l-1 1 3 3 5-5-1-1-4 4-2-2z"
            clipRule="evenodd"
          ></path>
        </svg>
      );
    case "hold":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="20"
          fill="none"
          viewBox="0 0 25 20"
        >
          <path
            fill="#CF8812"
            fillRule="evenodd"
            d="M5.385 3.077A3.077 3.077 0 018.462 0h3.077a3.077 3.077 0 013.077 3.077v.21c.957.087 1.904.202 2.845.343C18.952 3.853 20 5.15 20 6.622v3.11c0 1.243-.753 2.413-1.986 2.823A25.36 25.36 0 0110 13.847a25.355 25.355 0 01-8.014-1.292C.753 12.145 0 10.975 0 9.733V6.622C0 5.15 1.048 3.852 2.54 3.63a50.063 50.063 0 012.845-.343v-.21zm7.692 0v.092a50.76 50.76 0 00-6.154 0v-.092a1.539 1.539 0 011.539-1.538h3.077a1.538 1.538 0 011.538 1.538zM10 11.539A.77.77 0 1010 10a.77.77 0 000 1.539z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#CF8812"
            d="M.766 16.564v-2.868c.233.128.478.235.73.318a26.896 26.896 0 008.5 1.37c2.967 0 5.826-.48 8.5-1.369.259-.086.503-.194.732-.319v2.868c0 1.49-1.074 2.798-2.588 2.998A50.37 50.37 0 019.997 20a50.44 50.44 0 01-6.644-.438c-1.514-.2-2.587-1.509-2.587-2.998z"
          ></path>
          <rect
            width="12.308"
            height="12.308"
            x="12.113"
            y="7.5"
            fill="#fff"
            rx="6.154"
          ></rect>
          <path
            stroke="#CF8812"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.4"
            d="M15.332 14.72v-2.933a.533.533 0 111.067 0v1.866-2.933a.533.533 0 111.066 0v2.666-3.2a.533.533 0 011.067 0v3.2m0 .017v-2.416a.533.533 0 011.067 0v3.733"
          ></path>
          <path
            stroke="#CF8812"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.4"
            d="M15.332 14.72c0 1.957 1.067 2.934 2.533 2.934 1.467 0 2.062-.66 2.4-1.467l.879-2.4c.11-.3.06-.58-.198-.727-.259-.147-.599-.071-.739.195L19.6 14.72"
          ></path>
        </svg>
      );
    case "unstatus":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            fill="#939393"
            fillRule="evenodd"
            d="M5.385 3.077A3.077 3.077 0 018.462 0h3.077a3.077 3.077 0 013.077 3.077v.21c.957.087 1.904.202 2.845.343C18.952 3.853 20 5.15 20 6.622v3.11c0 1.243-.753 2.413-1.986 2.823A25.36 25.36 0 0110 13.847a25.355 25.355 0 01-8.014-1.292C.753 12.145 0 10.975 0 9.733V6.622C0 5.15 1.048 3.852 2.54 3.63a50.063 50.063 0 012.845-.343v-.21zm7.692 0v.092a50.76 50.76 0 00-6.154 0v-.092a1.539 1.539 0 011.539-1.538h3.077a1.538 1.538 0 011.538 1.538zM10 11.539A.77.77 0 1010 10a.77.77 0 000 1.539z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#939393"
            d="M.766 16.564v-2.868c.233.128.478.235.73.318a26.896 26.896 0 008.5 1.37c2.967 0 5.826-.48 8.5-1.369.259-.086.503-.194.732-.319v2.868c0 1.49-1.074 2.798-2.588 2.998A50.37 50.37 0 019.997 20a50.44 50.44 0 01-6.644-.438c-1.514-.2-2.587-1.509-2.587-2.998z"
          ></path>
        </svg>
      );
    default:
      return "";
  }
};
const getPicture = async (id) => {
  if (!id) return null;
  try {
    const response = await getDocumentOfficeFacillitiesById({}, id);
    const contentType = response.headers["content-type"];
    const blob = new Blob([response.data], { type: contentType });
    const objectURL = URL.createObjectURL(blob);
    return objectURL;
  } catch (err) {
    return null;
  }
};
const RecruitmentTbody = ({ tbody, setData, onSuccess }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idEmployee, setIdEmployee] = useState(null);
  const [modal, setModal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingReject, setIsLoadingReject] = useState(false);
  const [isLoadingNextStage, setIsLoadingNextStage] = useState(false);
  const [pictures, setPictures] = useState({
    identity: null,
    frontSelfie: null,
    leftSelfie: null,
    rightSelfie: null,
  });
  const [idCandidate, setId] = useState(null);
  const [detail, setDetail] = useState(null);
  const { id } = useParams();

  // --- Handle Detail ---
  const handleDetail = async (id) => {
    setIsModalOpen(true);
    setId(id);
    setModal("detail");
  };

  useEffect(() => {
    async function fetchData() {
      if (id && idCandidate) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getAppliedCandidateById(id, idCandidate);
          if (data.candidate.documents) {
            const frontSelfie = await getPicture(
              data.candidate.documents.front_selfie.id
            );
            const rightSelfie = await getPicture(
              data.candidate.documents.right_selfie.id
            );
            const leftSelfie = await getPicture(
              data.candidate.documents.left_selfie.id
            );
            const identity = await getPicture(
              data.candidate.documents.identity_card.id
            );
            setPictures({ frontSelfie, rightSelfie, leftSelfie, identity });
          }

          setDetail(data);
        } catch (error) {
          console.error(error?.response?.status);
          if (error?.response?.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [navigate, idCandidate]);

  const handleNextStep = async () => {
    if (detail?.stages === "test") {
      navigate(`interview/${detail?.candidate?.id}`);
    } else {
      setIsLoadingNextStage(true);
      try {
        await putCandidatetNextStage({
          vacancy_id: id,
          candidate_id: detail?.candidate.id,
        });
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        onSuccess();
        setIsLoadingNextStage(false);
        setIsModalOpen(false);
        setId(null);
        setDetail(null);
      }
    }
  };

  const handleStatusChange = async (result_id) => {
    try {
      await putCandidateInterview(
        {
          vacancy_id: id,
          candidate_id: detail?.candidate.id,
        },
        { result_id }
      );
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    } finally {
      onSuccess();
    }
  };

  const handleReject = async () => {
    setIsLoadingReject(true);
    try {
      await putRejectCandidate({
        vacancy_id: id,
        candidate_id: detail?.candidate.id,
      });
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    } finally {
      onSuccess();
      setIsLoadingReject(false);
      setIsModalOpen(false);
      setId(null);
      setDetail(null);
    }
  };

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Name --- */}
            <td
              style={{
                padding: "10px",
                verticalAlign: "middle",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap="10px"
                justifyContent="center"
              >
                <Avatar src={item?.candidate?.profile_picture} />
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={400}
                  fontSize={"14px"}
                  lineHeight={"130%"}
                  color={"#171717"}
                >
                  {item?.candidate.name}
                </Typography>
              </Stack>
            </td>

            {/* --- Email --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item?.stages}
              </Typography>
            </td>
            {/* --- Contact --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {moment(item?.application_date).format("DD MMM")}
              </Typography>
            </td>
            {/* Dept */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Stack
                direction="row"
                gap="2px"
                alignItems="center"
                justifyContent="center"
              >
                {item?.attachments && item?.attachments.length > 0 ? (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="#171717"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6.378 6.338v7.242a3.104 3.104 0 006.207 0V4.958a2.07 2.07 0 00-2.86-1.912 2.069 2.069 0 00-1.278 1.912v8.62a1.035 1.035 0 002.07 0V6.339"
                      ></path>
                    </svg>
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontWeight={400}
                      fontSize={"14px"}
                      lineHeight={"130%"}
                      color={"#171717"}
                    >
                      {item?.attachments.length}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={400}
                    fontSize={"14px"}
                    lineHeight={"130%"}
                    color={"#171717"}
                  >
                    No Attachments
                  </Typography>
                )}
              </Stack>
            </td>
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              {getStatusIcon(item?.status.toLowerCase())}
            </td>
            {/* --- Action --- */}
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                <TooltipsAction title="Detail" arrow placement={"bottom-end"}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleDetail(item?.candidate.id)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>

                {/* --- Delete --- */}
                <TooltipsAction title="Delete" arrow placement={"bottom-end"}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAction(
                        item?.candidate?.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        "delete"
                      )
                    }
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
      {modal === "detail" ? (
        <RecruitmentCandidateDetail
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          detail={detail}
          isLoading={isLoading}
          onNextStage={handleNextStep}
          onReject={handleReject}
          isLoadingReject={isLoadingReject}
          isLoadingNextStage={isLoadingNextStage}
          pictures={pictures}
          onStatusChange={handleStatusChange}
        />
      ) : modal === "delete" ? (
        <ModalDelete
          onSuccess={onSuccess}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={"Candidate"}
          isLoading={isLoadingDelete}
          setIsLoading={setIsLoadingDelete}
          id={{ vacancy_id: id, candidate_id: idEmployee }}
          data={tbody}
          setData={setData}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default RecruitmentTbody;
