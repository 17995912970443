const DataTableDepartmentList = [
  {
    title: 'No',
    judul: 'No',
    path: 'name',
  },
  {
    title: 'Name',
    judul: 'Nama',
    path: 'name',
  },
  {
    title: 'Position',
    judul: 'Posisi',
    path: 'name',
  },
  {
    title: 'Service',
    judul: 'Layanan',
    path: 'name',
  },
  {
    title: 'Status',
    judul: 'Status',
    path: 'name',
  },
  {
    title: 'Action',
    judul: 'Aksi',
    path: 'name',
  },
];

export default DataTableDepartmentList;