import { Button, Typography } from '@mui/material';
import React from 'react';
import { IconDetailList } from '../../../assets/commons';
import { getLanguage } from '../../../features/language';
import { useSelector } from 'react-redux';

export default function ButtonDetail({ onclick }) {
  const lang = useSelector(getLanguage);
  return (
    <Button
      type="button"
      variant="text"
      onClick={onclick}
      display={'flex'}
      alignItems={'center'}
    >
      <IconDetailList />
      <Typography
        fontFamily={'sans-serif'}
        fontStyle={'normal'}
        fontWeight={400}
        fontSize={'12px'}
        lineHeight={'14px'}
        color="green"
        letterSpacing={'0.02em'}
        marginLeft={'10px'}
        textTransform={'none'}
      >
        {lang === 'Id'
          ? 'Klik disini untuk melihat detail'
          : 'Click here to see details'}
      </Typography>
    </Button>
  );
}
