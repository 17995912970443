export const dataTableEmployeeShedule = [
  {
    title: 'No',
    judul: 'No',
    url: 'shift',
  },
  {
    title: 'Shift',
    judul: 'Shift',
    url: 'shift',
  },
  {
    title: 'Clock In',
    judul: 'Jam Masuk',
    url: 'shift',
  },
  {
    title: 'Clock Out',
    judul: 'Jam Keluar',
    url: 'shift',
  },
  {
    title: 'Start Break',
    judul: 'Mulai Istirahat',
    url: 'shift',
  },
  {
    title: 'End Break',
    judul: 'Akhir Istirahat',
    url: 'shift',
  },
  {
    title: 'Break Duration',
    judul: 'Waktu Istirahat',
    url: 'shift',
  },
  {
    title: 'Action',
    judul: 'Aksi',
    url: 'shift',
  },
];
