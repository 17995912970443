import { Box } from "@mui/material";
import React from "react";
import {
  ChartEmployeeFacillitiesSummary,
  TableEmployeeFacillities,
} from "../../../../components/organisms/benefit";
const BenefitEmployeeFacillitiesDashboard = () => {
  return (
    <Box>
      <ChartEmployeeFacillitiesSummary />
      <TableEmployeeFacillities />
    </Box>
  );
};

export default BenefitEmployeeFacillitiesDashboard;
