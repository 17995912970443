import { Box, IconButton, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { setIdEmployee } from '../../../../../../features/idEmployee';
import { getCompany } from '../../../../../../features/companies';
import DataLogin from '../../../../../../data/dataLogin';
import { IconDelete, IconDetail, IconEdit } from '../../../../../../assets/commons';
import { TooltipsAction } from '../../../../../atoms';
import { handleTooltipClose, handleTooltipOpen } from '../../../../../../utils/function/tooltips';

const LogViewTbody = ({ tbody }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState('');
  const [detail, setDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [id, setId] = useState(null);
  const company = useSelector(getCompany);
  const getData = DataLogin();


  const [idTooltipDetail, setIdTooltipDetail] = useState('');
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);
  const [idTooltipDelete, setIdTooltipDelete] = useState('');
  const [isTooltipDelete, setIsTooltipDelete] = useState(false);
  const [idTooltipEdit, setIdTooltipEdit] = useState('');
  const [isTooltipEdit, setIsTooltipEdit] = useState(false);


  // --- Handle Delete ---
  async function handleDelete(id) {
    setIsModalOpen(true);
    dispatch(setIdEmployee(id));
    setOpen(false);
    setModal('delete');
  }

  // --- Handle Detail ---
  const handleDetail = async (id) => {
    setIsModalOpen(true);
    dispatch(setIdEmployee(id));
    setOpen(false);
    setId(id);
    setModal('detail');
  };

  // --- Handle Edit ---
  const handleEdit = (id) => {
    dispatch(setIdEmployee(id));
    navigate(`/core-hr/log-view/edit/${id}`);
  };

  // --- Data Detail ---
//   useEffect(() => {
//     async function fetchData() {
//       if (company && id !== null) {
//         setIsLoading(false);
//         try {
//           const response = await axios.get(`${Url}v1/employee-awards/${id}`, {
//             headers: {
//               Authorization: `Bearer ${getData.token}`,
//               'Content-Type': 'application/json',
//             },
//           });
//           const data = response.data.data;
//           setIsLoading(true);
//           setDetail(data);
//         } catch (error) {
//           setIsLoading(true);
//           console.error(error.response.status);
//           if (error.response.status === 401) {
//             localStorage.removeItem('data');
//             navigate(`/`);
//           }
//         }
//       }
//     }

//     fetchData();
//   }, [company, id]);

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- User --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'start',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item.name}
              </Typography>
            </td>
            {/* --- Hour --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'start',
                verticalAlign: 'middle',
              }}
            >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontWeight={400}
                    fontSize={'14px'}
                    lineHeight={'130%'}
                    color={'#171717'}
                  >
                    {item.hour}
                  </Typography>
                  
            </td>
            {/* --- IP Address --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item.ip_address ? item.ip_address : '-'}
              </Typography>
            </td>
            {/* --- Date --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {moment(item.date_login).format('D MMM')}
              </Typography>
            </td>
            {/* --- Action --- */}
            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
            <Box 
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={1}
              >
                {/* --- Detail --- */}
                <TooltipsAction
                  title='Detail'
                  open={isTooltipDetail && item.id === idTooltipDetail}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{cursor: 'pointer'}}
                    onClick={() => handleDetail(item.id)} 
                    onMouseEnter={() => handleTooltipOpen(item.id, setIsTooltipDetail, setIdTooltipDetail )} 
                    onMouseLeave={()=>handleTooltipClose(setIsTooltipDetail)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>

                {/* --- Delete --- */}
                <TooltipsAction
                  title='Delete'
                  open={isTooltipDelete && item.id === idTooltipDelete}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{cursor: 'pointer'}}
                    onClick={() => handleDelete(item.id)}
                    onMouseEnter={() => handleTooltipOpen(item.id, setIsTooltipDelete, setIdTooltipDelete )} 
                    onMouseLeave={()=>handleTooltipClose(setIsTooltipDelete)}
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>

                {/* --- Edit --- */}
                <TooltipsAction
                  title='Edit'
                  open={isTooltipEdit && item.id === idTooltipEdit}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{cursor: 'pointer'}}
                    onClick={() => handleEdit(item.id)} 
                    onMouseEnter={() => handleTooltipOpen(item.id, setIsTooltipEdit, setIdTooltipEdit )} 
                    onMouseLeave={()=>handleTooltipClose(setIsTooltipEdit)}
                  >
                    <IconEdit />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
      {/* --- Modal Handle --- */}
      {/* {modal === 'detail' ? (
        <DetailAwards
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          detail={detail}
          isLoading={isLoading}
        />
      ) : modal === 'delete' ? (
        <ModalDelete
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={'Awards'}
          path={'employee-awards'}
        />
      ) : (
        ''
      )} */}
    </>
  );
};

export default LogViewTbody;
