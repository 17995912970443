/**
 * 
 * @returns Chard Orgchart
 * Fauzan Hidayat
 */
import React from "react";
import { Avatar, Card, CardContent, CardHeader, Typography } from "@mui/material";
/**
 import { Avatar, Card, CardContent, CardHeader, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
*/
import { Box } from "@mui/system";

    
const CardOrgChart = ({nama, jabatan, data, profile}) => {
    
    //const colors = ["#64B96E", "#FF1D1D", "#CF12B8","#125ACF","#CF8812"];

    const colors = [
        {
            colorTop: '#64B96E',
            colorBody: '#64B96E0F',
        },
        {
            colorTop: '#FF1D1D',
            colorBody: '#FFF1F1',
        },
        {
            colorTop: '#CF12B8',
            colorBody: '#FCF1FB',
        },
        {
            colorTop: '#125ACF',
            colorBody: '#F1F5FC',
        },
        {
            colorTop: '#CF8812',
            colorBody: '#FCF8F1',
        },
    ];
    const randomColor = () => {
        const item = Math.floor(Math.random() * colors.length);
        return colors[item];
    }
    
    const color = randomColor();
    return (
        <>
            <Card
                sx={{
                    width: '195px',
                    minHeight: '55px',
                    mx: 'auto',
                    borderRadius: '8px',
                    borderTop: '5px solid',
                    borderTopColor: color.colorTop,
                }}
            >
               <Box
                sx={{
                    backgroundColor: color.colorBody,
                    padding: '10px',

                }}
               >
               <CardHeader
                    avatar={
                        <Avatar sx={
                            {
                                bgcolor: '#dedede',
                                marginTop: '-50px',
                                zIndex: '9999',
                                position: 'absolute',
                                ml: '47px',
                                padding: '5px',
                            }
                        } 
                        src={profile? profile : null}
                        aria-label={nama} />
                    }
                />
                <CardContent>
                    <Typography
                    fontFamily={'Open Sans'}
                    fontWeight={'600'}
                    fontSize={'16px'}
                    lineHeight={'10px'}
                    sx={{
                        color: '#171717',
                    }}
                    >
                        {nama}
                    </Typography>
                    <Typography
                    fontFamily= {'Open Sans'}
                    fontSize= {'12px'}
                    fontWeight= {'400'}
                    lineHeight= {'18px'}
                    letterSpacing= {'0em'}
                    sx={{
                        mt: '10px',
                    }}
                    >
                        {jabatan}
                    </Typography>
                </CardContent>

                {/** Array.isArray(data) ? (
                    <>
                     <Accordion 
                     sx={{
                        border: 'none',
                        boxShadow: 'unset',
                        backgroundColor: '#f9f9ff',
                     }}
                     >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                        </AccordionSummary>
                        <AccordionDetails>
                            <List dense>
                                {data.map((details, idx) => (
                                    <ListItem key={idx} alignItems="flex-start">
                                        <ListItemIcon>
                                            <Avatar src={details.profile_picture} alt={details.name} />
                                        </ListItemIcon>
                                        <ListItemText
                                            secondary={details.name}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </AccordionDetails>
                     </Accordion>
                    </>
                                ) : '' */}
               </Box>
            </Card>
        </>
    );
}

export default CardOrgChart;