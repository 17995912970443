import { Box, TextField } from '@mui/material';
import SelectYear from '../../../components/molecules/year';
import { useState } from 'react';
import { getLanguage } from '../../../features/language';
import { getCompany } from '../../../features/companies';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useEffect } from 'react';
import {
  getListDepartments,
  getListHistory,
} from '../../../configs/axios/apiPath';
import TableComponent from '../../../components/molecules/table/mainTable';
import { DataTableAttendanceHistory } from '../../../data/table/timesheet';
import {
  Card,
  Pagination,
  RowPerPage,
  Search,
} from '../../../components/atoms';
import { SelectDepartment } from '../../../components/organisms/timesheet';
// import AttendanceHistoryTbody from '../../../components/molecules/table/tableTimesheet/attendanceHistory/attendanceHistoryTbody';
// import AttendanceHistoryThead from '../../../components/molecules/table/tableTimesheet/attendanceHistory/attendanceHistoryThead';
import {
  AttendanceHistoryTbody,
  AttendanceHistoryThead,
} from '../../../components/molecules/table/tableTimesheet/attendanceHistory';
import CustomDatePicker from '../../../components/molecules/datepicker';
import dayjs from 'dayjs';
import DepartmentAutocomplete from '../../../components/organisms/timesheet/departmentAutocomplete';

const AttendanceHistory = () => {
  let localData = JSON.parse(localStorage.getItem('cvSayaData'));
  const yearNow = moment().format('YYYY');
  const year = [];
  const company = useSelector(getCompany);
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [selectedYear, setSelectedYear] = useState(yearNow);
  const [isLoading, setIsLoading] = useState(false);
  const [attendance, setattendance] = useState([], {});
  const [lastPage, setLastPage] = useState();
  const [order, setOrder] = useState('name');
  const [direction, setDirection] = useState('-');
  const [datePicker, setDatePicker] = useState(dayjs());
  const [department, setDepartment] = useState(null);
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const handleChangeDate = (value) => {
    setDatePicker(dayjs(value));
  };

  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }

  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          company_id: company,
          department_id: department,
          month_year: dayjs(datePicker).format('YYYY-MM'),
          search: keyword,
          sort: `${direction}${order}`,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListHistory(params);
          setLastPage(data?.page_info?.last_page);
          setattendance(data?.histories);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [company, direction, order, keyword, datePicker, department]);

  // department
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          page_size: 9999999,
          company_id: company,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListDepartments(params);

          const defaultDepartment = {
            id: null,
            name: 'ALL DEPARTMENT',
            icon_url: null,
            total_employee: 0,
            manager: null,
          };
          const newDepartments = [defaultDepartment, ...data?.departments];

          !department && setDepartment(newDepartments[0].id);
          setDepartmentOptions(newDepartments);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company]);

  const handleDepartmentChange = (value) => {
    if (value) {
      setDepartment(value.id);
      localData = { ...localData, departmentId: value.id };
      localStorage.setItem('cvSayaData', JSON.stringify(localData));
    }
  };

  return (
    <Card sx={{ marginTop: '20px', maxWidth: '100%', overflow: 'hidden' }}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        p={'23px 22px'}
        maxWidth={'89vw'}
        overflow={'hidden'}
      >
        <Box display={'flex'} justifyContent={'space-between'}>
          {/* --- Search --- */}
          <Search
            placeholder={
              lang === 'Id'
                ? 'Ketik disini untuk mencari'
                : 'Type here to search'
            }
            setSearch={(e) => setKeyword(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />

          <Box display={'flex'} alignItems={'center'}>
            {/* --- Selected Department --- */}
            {/* <SelectDepartment /> */}
            <DepartmentAutocomplete
              departmentOptions={departmentOptions}
              department={department}
              handleDepartmentChange={handleDepartmentChange}
            />

            {/* --- Selected Year and month --- */}
            <CustomDatePicker
              datePicker={datePicker}
              views={['year', 'month']}
              format="MMM YYYY"
              handleChangeDate={handleChangeDate}
            />
          </Box>
        </Box>

        {/* --- Table --- */}
        <Box
          maxWidth={'100%'}
          overflow={'scroll'}
          sx={{ mt: '24px', mb: '16px' }}
        >
          <TableComponent
            isLoading={isLoading}
            tbody={attendance}
            TableHead={
              <AttendanceHistoryThead
                thead={DataTableAttendanceHistory}
                setOrder={setOrder}
                setDirection={setDirection}
                direction={direction}
              />
            }
            TableBody={
              <AttendanceHistoryTbody
                tbody={attendance}
                setData={setattendance}
              />
            }
            col={32}
          />
        </Box>

        {/* --- Pagination --- */}
        <Box display={'flex'} justifyContent={'end'}>
          <RowPerPage
            setPageSize={setPageSize}
            pageSize={pageSize}
            setPage={setPage}
          />
          <Pagination page={page} setPage={setPage} lastPage={lastPage} />
        </Box>
      </Box>
    </Card>
  );
};

export default AttendanceHistory;
