export const dataTableEmployeeAttendances = [
  {
    title: 'Date',
    judul: 'Tanggal',
  },
  {
    title: 'Attendance Status',
    judul: 'Status Kehadiran',
  },
  {
    title: 'Clock In',
    judul: 'Jam Masuk',
  },
  {
    title: 'Clock Out',
    judul: 'Jam Keluar',
  },
  {
    title: 'Overtime',
    judul: 'Lembur',
  },
  {
    title: 'Late',
    judul: 'Keterlambatan',
  },
  {
    title: 'Schedule',
    judul: 'Jadwal',
  },
];
