const DataTableAnnouncement = [
    {
        title: 'Title',
        judul: 'Judul',
        url: 'title',
    },
    {
        title: 'Dept',
        judul: 'Departemen',
        url: 'title',
    },
    {
        title: 'Implementation Date',
        judul: 'Tanggal Implementasi',
        url: 'date',
    },
    {
        title: 'Action',
        judul: 'Aksi',
        url: 'title',
    },
]

export default DataTableAnnouncement;