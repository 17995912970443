import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  IconButton,
  InputLabel,
  Switch,
  TextField,
  Typography,
  Stepper,
  Step,
  StepButton,
  Card,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { IconBack, IconDept, IconSearch } from "../../../../assets/employee";
import {
  IconMinus,
  IconPlusTwo,
  IconPlusActive,
  IconMinusNonActive,
} from "../../../../assets/commons";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import debounce from "lodash/debounce";
import { Buttons, TooltipsAction } from "../../../../components/atoms";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  getEmployeeCandidates,
  getEmployeesById,
  updateEmployeesById,
} from "../../../../configs/axios/apiPath/fetchEmployee/fetchEmployeeManage";
import { getCompany } from "../../../../features/companies";
import { useSelector } from "react-redux";
import { getListPosition } from "../../../../configs/axios/apiPath/fetchCommon/fetchListPosition";
import { styled } from "@mui/material/styles";
import FirstPage from "./first-step";
import SecondPage from "./second-step";
import ThirdPage from "./third-step";
import FourthPage from "./fourth-step";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  getListEmployeeType,
  getListMarriageStatus,
  getReligions,
} from "../../../../configs/axios/apiPath";

const steps = [
  "Personal Data",
  "Family Member",
  "Contactable family",
  "Family Structure",
];

export default function EditEmployeeLayout() {
  const path = useLocation().pathname;
  const splitPath = path.split("/");
  const id = splitPath[4];

  const [currentData, setCurrent] = useState({
    candidate_id: null,
    is_attendance_required: true,
    joined_at: null,
    ktp: null,
    bpjstk: null,
    bpjs_kes: null,
    no_rek: 0,
    account_name: null,
    bank_name: null,
    branch_name: null,
    npwp: null,
    num_phone: 0,
    email: null,
    subdistrict_code: null,
    village_code: null,
    address: null,
    blood_type: null,
    husband_wife: null,
    child_1: null,
    child_2: null,
    child_3: null,
    child_4: null,
    fam_name: null,
    relationship: null,
    fam_phone: 0,
    father_name: null,
    mother_name: null,
    sibling_1: null,
    sibling_2: null,
    sibling_3: null,
    sibling_4: null,
    sibling_5: null,
    birth_location: null,
    birth_date: null,
    gender: null,
  });

  const [city, setCity] = useState(null);
  const [provinces, setProvinces] = useState(null);
  const [SubDistrict, setSubDistrict] = useState(null);
  const [villages, setVillages] = useState(null);

  const company = useSelector(getCompany);
  const navigate = useNavigate();
  const [isLoading, setLoadingSave] = useState(false);
  const [listEmployees, setEmployees] = useState([]);

  const [positionSelected, setPositionSelected] = useState(null);
  const [positionId, setPositionId] = useState("");
  const [dataPosition, setDataPosition] = useState([]);

  const [employeeTypeSelected, setEmployeeTypeSelected] = useState(null);
  const [employeeTypeId, setEmployeeTypeId] = useState("");
  const [dataEmployeeType, setDataEmployeeType] = useState([]);

  const [religionSelected, setReligionSelected] = useState(null);
  const [religionId, setReligionId] = useState("");
  const [dataReligion, setDataReligion] = useState([]);

  const [maritalStatusSelected, setMaritalStatusSelected] = useState(null);
  const [maritalStatusId, setMaritalStatusId] = useState("");
  const [dataMaritalStatus, setDataMaritalStatus] = useState([]);

  const [showAdditional, setShowAdditional] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const onSearchEmployees = debounce(async (values) => {
    const { data } = await getEmployeeCandidates(values);
    return setEmployees(data.data?.candidates || []);
  }, 700);

  const handleBack = () => {
    navigate(`/employee/employee/detail/${id}/Profile`);
  };

  const onSaveEmployees = async (e) => {
    e.preventDefault();

    const employee = {
      employee: {
        name: currentData?.candidate_id,
        position_id: positionId?.id,
        joined_at: dayjs(currentData.joined_at).format("YYYY-MM-DD"),
        employment_type_id: employeeTypeId,
      },
    };

    const data = {
      employee: {
        name: currentData?.candidate_id,
        position_id: positionId?.id,
        joined_at: dayjs(currentData.joined_at).format("YYYY-MM-DD"),
        employment_type_id: employeeTypeId,
      },
      personal_data: {
        identity_number: currentData.ktp,
        number_bpjs_kes: currentData.bpjs_kes,
        email: currentData.email,
        blood_type: currentData.blood_type,
        number_bpjs_tk: currentData.bpjstk,
        status: maritalStatusId,
        religion_id: religionId,
        phone_number: currentData?.num_phone,
        birth_location: currentData?.birth_location,
        birth_date: dayjs(currentData.birth_date).format("YYYY-MM-DD"),
        gender: currentData?.gender,
        bank: {
          account_name: currentData.account_name,
          bank_name: currentData.bank_name,
          account_number: Number(currentData.no_rek),
          bank_branch_name: currentData.branch_name || "-",
          taxpayer_identification_number: currentData.npwp,
        },
        domicile: {
          province_code: provinces?.code,
          city_code: city?.code,
          subdistrict_code: SubDistrict?.code,
          village_code: villages?.code,
          address: currentData.address,
        },
      },
      family_member: {
        name: currentData.husband_wife || "-",
        child_1: currentData.child_1 || "-",
        child_2: currentData.child_2 || "-",
        child_3: currentData.child_3 || "-",
      },
      contactable_family: {
        name: currentData.fam_name || "-",
        relation: currentData.relationship,
        phone_number: Number(currentData.fam_phone),
      },
      family_sturcture: {
        father: currentData.father_name,
        mother: currentData.mother_name,
        child_1: currentData.sibling_1,
        child_2: currentData.sibling_2,
        child_3: currentData.sibling_3,
        child_4: currentData.sibling_4,
        child_5: currentData.sibling_5,
      },
    };

    const datas = showAdditional ? data : employee;

    setLoadingSave(true);
    try {
      const response = await updateEmployeesById(datas, id); //TODO: submit Employee Edit
      if (response.data.meta.code === 20000) {
        handleBack();
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401 && 402) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    } finally {
      setLoadingSave(false);
    }
  };

  const setCurrentData = (key, value) =>
    setCurrent({ ...currentData, [key]: value });

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          include:
            "profile,resume,jobs,note,bankAccount,document,family,familyStructure,contactableFamily,domicile,oneEducation,position",
        };
        try {
          const { data } = await getEmployeesById(id, params);
          setCurrent({
            candidate_id: data?.data?.employee?.name,
            joined_at: data?.data?.joined_at,
            is_attendance_required: data?.data?.is_default,
            ktp: data?.data?.Personal_data?.data?.identity_number,
            bpjstk: data?.data?.Personal_data?.data?.no_bpjs_tk,
            bpjs_kes: data?.data?.Personal_data?.data?.no_bpjs_kes,
            no_rek: data?.data?.bank_account?.account_number,
            bank_name: data?.data?.bank_account?.bank_name,
            account_name: data?.data?.bank_account?.account_name,
            branch_name: data?.data?.bank_account?.bank_branch_name,
            npwp: data?.data?.bank_account?.taxpayer_identification_number,
            birth_location: data?.data?.profile?.birth_location,
            birth_date: data?.data?.profile?.birth_date,
            gender: data?.data?.profile?.gender,
            num_phone: data?.data?.Personal_data?.data?.phone_number,
            email: data?.data?.Personal_data?.data?.email,
            subdistrict_code: data?.data?.profile?.subdistrict,
            village_code: data?.data?.profile?.village,
            address: data?.data?.Personal_data?.data?.address,
            blood_type: data?.data?.Personal_data?.data?.blood_type,
            husband_wife: data?.data?.Personal_data?.family_member?.name,
            child_1: data?.data?.Personal_data?.family_member?.child_1,
            child_2: data?.data?.Personal_data?.family_member?.child_2,
            child_3: data?.data?.Personal_data?.family_member?.child_3,
            child_4: data?.data?.Personal_data?.family_member?.child_4,
            fam_name: data?.data?.Personal_data?.contactable_family?.name,
            relationship:
              data?.data?.Personal_data?.contactable_family?.relation,
            fam_phone:
              data?.data?.Personal_data?.contactable_family?.phone_number,
            father_name: data?.data?.Personal_data?.family_structure?.father,
            mother_name: data?.data?.Personal_data?.family_structure?.mother,
            sibling_1: data?.data?.Personal_data?.family_structure?.child_1,
            sibling_2: data?.data?.Personal_data?.family_structure?.child_2,
            sibling_3: data?.data?.Personal_data?.family_structure?.child_3,
            sibling_4: data?.data?.Personal_data?.family_structure?.child_4,
            sibling_5: data?.data?.Personal_data?.family_structure?.child_5,
          });
          setPositionSelected({
            id: data?.data?.position?.id,
            label: data?.data?.position?.name,
          });
          setPositionId(data?.data?.position?.id);
          setEmployeeTypeSelected(data?.data?.employement_type?.name);
          setEmployeeTypeId(data?.data?.employement_type?.id);
          setMaritalStatusSelected(data?.data?.Personal_data?.data?.status);
          setProvinces(data?.data?.profile?.province);
          setCity(data?.data?.profile?.city);
          setSubDistrict(data?.data?.profile?.subdistrict);
          setVillages(data?.data?.profile?.village);
          setReligionSelected(data?.data?.profile?.religion?.name);
          setReligionId(data?.data?.profile?.religion?.id);
        } catch (error) {
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        }
      }
    }
    fetchData();

    async function fetchEmployeeType() {
      if (company) {
        setLoadingSave(true);
        try {
          const { data } = await getListEmployeeType();
          const employeeType = data.data.map((options) => ({
            id: options.id,
            label: options.name,
          }));
          setDataEmployeeType(employeeType);
        } catch (error) {
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setLoadingSave(false);
        }
      }
    }
    fetchEmployeeType();

    async function fetchMarriageStatus() {
      try {
        const { data } = await getListMarriageStatus();
        const marri_status = data.data.map((options) => ({
          id: options.id,
          label: options.name,
        }));
        setDataMaritalStatus(marri_status);
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setLoadingSave(false);
      }
    }

    fetchMarriageStatus();

    async function fetchReligions() {
      try {
        const { data } = await getReligions();

        const religions = data.data.map((options) => ({
          id: options.id,
          label: options.name,
        }));
        setDataReligion(religions);
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setLoadingSave(false);
      }
    }

    fetchReligions();
  }, [company]);

  const [searchPosition, setSearchPosition] = useState("");
  const [loadingPosition, setLoadingPosition] = useState(false);

  const renderSelectOptions = (data = [], { label, value }) =>
    data.map((a) => <MenuItem value={a[value]}>{a[label]}</MenuItem>);

  const fetchDataPosition = async (search) => {
    if (company) {
      const params = {
        page_size: 10,
        company_id: company,
        search: search,
      };
      setLoadingPosition(true);
      setLoadingSave(true);
      try {
        const { data } = await getListPosition(params);
        const positions = data.data.positions.map((options) => ({
          id: options.id,
          label: options.name,
          company_id: company,
        }));
        setLoadingPosition(false);
        setDataPosition(positions);
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
        setLoadingPosition(false);
      } finally {
        setLoadingSave(false);
        setLoadingPosition(false);
      }
    }
  };
  useEffect(() => {
    fetchDataPosition(searchPosition);
  }, [company]);

  const debouncePosition = useRef(
    debounce((value) => {
      fetchDataPosition(value);
    }, 1000)
  ).current;

  const FormLabel = ({ label }) => (
    <InputLabel
      style={{
        fontFamily: "sans-serif",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "22px",
        letterSpacing: "0.02em",
        color: "#171717",
        marginBottom: "10px",
      }}
    >
      {label}
    </InputLabel>
  );

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleSkip = () => {
    if (activeStep < 3) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const ColorConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#4FD240",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#4FD240",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  return (
    <Card>
      <Box p={"25px 22px"}>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            columnGap={1.5}
            alignContent="center"
            alignItems="center"
            alig
          >
            <IconButton size="large" onClick={() => handleBack()}>
              <IconBack />
            </IconButton>
            <h2>Edit Employeee</h2>
          </Box>
          <hr color={"#F1F1F1"} width="100%" />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          rowGap="16px"
          width={"50%"}
          paddingTop={4}
        >
          <Box>
            <FormLabel label="Name" />
            <TextField
              fullWidth
              value={currentData?.candidate_id}
              onChange={(e) => setCurrentData("candidate_id", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconSearch />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box>
            <FormLabel label="Position" />
            <Autocomplete
              loading={loadingPosition}
              loadingText={
                <div style={{ textAlign: "center" }}>loading ...</div>
              }
              sx={{ sx: { width: "100%" } }}
              onChange={(v, value) => {
                setPositionSelected(value);
                // const mid = JSON.stringify(value, null, " ");
                setPositionId(value);
              }}
              onInputChange={(e, value) => debouncePosition(value)}
              options={dataPosition}
              value={positionSelected}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ marginLeft: "1%" }}
                      >
                        <IconDept />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              )}
            />
          </Box>

          <Box>
            <FormLabel label="Type" />
            <Autocomplete
              sx={{ sx: { width: "100%" } }}
              onChange={(v, value) => {
                setEmployeeTypeSelected(value);

                const mid = JSON.stringify(value.id);
                setEmployeeTypeId(mid);
              }}
              options={dataEmployeeType}
              value={employeeTypeSelected}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ marginLeft: "1%" }}
                      >
                        <IconDept />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              )}
            />
          </Box>

          <Box>
            <FormLabel label="Join Date" />
            <DatePicker
              sx={{ width: "100%" }}
              onChange={(dt) => setCurrentData("joined_at", dt)}
              value={dayjs(currentData.joined_at)}
            />
          </Box>

          <Box>
            <FormLabel label="Attendance" />
            <Switch
              // sx={{ width: "100%" }}
              onChange={(dt) =>
                setCurrentData("is_attendance_required", dt.target.checked)
              }
              checked={currentData.is_attendance_required}
              value={currentData.is_attendance_required}
            />
          </Box>
        </Box>

        <Box>
          <Box
            display="flex"
            flexDirection="row"
            columnGap={1.5}
            alignContent="center"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center">
              {" "}
              <h2>Edit Personal Data</h2>
            </Box>

            <Box display="flex" alignItems="center">
              <Box marginRight={"10%"} display="flex" alignItems="center">
                <TooltipsAction open={{}} arrow placement={"bottom-end"}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => setShowAdditional(true)}
                  >
                    {showAdditional ? <IconPlusTwo /> : <IconPlusActive />}
                  </Box>
                </TooltipsAction>
              </Box>

              <Box display="flex" alignItems="center">
                <TooltipsAction open={{}} arrow placement={"bottom-end"}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => setShowAdditional(false)}
                  >
                    {showAdditional ? <IconMinus /> : <IconMinusNonActive />}
                  </Box>
                </TooltipsAction>
              </Box>
            </Box>
          </Box>
          <hr color={"#F1F1F1"} width="100%" />

          {showAdditional ? (
            <>
              <Box>
                <Stepper
                  alternativeLabel
                  activeStep={activeStep}
                  connector={<ColorConnector />}
                  sx={{ marginTop: "30px" }}
                >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepButton color="inherit" onClick={handleStep(index)}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </Box>

              {activeStep === steps.length ? (
                <></>
              ) : (
                <>
                  {activeStep === 0 && (
                    <FirstPage
                      setCurrentData={setCurrentData}
                      currentData={currentData}
                      provinces={provinces}
                      setProvinces={setProvinces}
                      city={city}
                      setCity={setCity}
                      SubDistrict={SubDistrict}
                      setSubDistrict={setSubDistrict}
                      villages={villages}
                      setVillages={setVillages}
                      dataMaritalStatus={dataMaritalStatus}
                      maritalStatusSelected={maritalStatusSelected}
                      setMaritalStatusSelected={setMaritalStatusSelected}
                      setMaritalStatusId={setMaritalStatusId}
                      dataReligion={dataReligion}
                      religionSelected={religionSelected}
                      setReligionSelected={setReligionSelected}
                      setReligionId={setReligionId}
                      renderSelectOptions={renderSelectOptions}
                    />
                  )}
                  {activeStep === 1 && (
                    <SecondPage
                      setCurrentData={setCurrentData}
                      currentData={currentData}
                    />
                  )}
                  {activeStep === 2 && (
                    <ThirdPage
                      setCurrentData={setCurrentData}
                      currentData={currentData}
                    />
                  )}
                  {activeStep === 3 && (
                    <FourthPage
                      setCurrentData={setCurrentData}
                      currentData={currentData}
                    />
                  )}

                  <Box
                    mt={3}
                    //margin
                    display="flex"
                    justifyContent={"flex-end"}
                  >
                    {activeStep > 0 && activeStep < 3 && (
                      <Buttons
                        isLoading={isLoading}
                        onClick={handleSkip}
                        color="primary"
                        variant="contained"
                        // type="submit"
                        sx={{
                          borderRadius: "8px",
                          width: "100px",
                          marginRight: "15px",
                          background: "#FFFFFF",
                        }}
                      >
                        <Typography
                          fontFamily={"sans-serif"}
                          fontStyle={"normal"}
                          fontWeight={600}
                          fontSize={"14px"}
                          lineHeight={"25px"}
                          color={"#171717"}
                          textTransform={"none"}
                        >
                          Skip
                        </Typography>
                      </Buttons>
                    )}

                    {/* --- Employee Next/Finish Button --- */}
                    <Buttons
                      isLoading={isLoading}
                      onClick={
                        activeStep === steps.length - 1
                          ? onSaveEmployees
                          : handleNext
                      }
                      color="primary"
                      variant="contained"
                      // type="submit"
                      sx={{
                        borderRadius: "8px",
                        width: "100px",
                      }}
                    >
                      <Typography
                        fontFamily={"sans-serif"}
                        fontStyle={"normal"}
                        fontWeight={600}
                        fontSize={"14px"}
                        lineHeight={"25px"}
                        color={"#fff"}
                        textTransform={"none"}
                      >
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Typography>
                    </Buttons>
                  </Box>
                </>
              )}
            </>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Buttons
                isLoading={isLoading}
                onClick={onSaveEmployees}
                color="primary"
                variant="contained"
                // type="submit"
                sx={{
                  borderRadius: "8px",
                  width: "100px",
                }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"14px"}
                  lineHeight={"25px"}
                  color={"#fff"}
                  textTransform={"none"}
                >
                  Edit
                </Typography>
              </Buttons>
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );
}
