import {
  Card,
  Typography,
  Divider,
  Grid,
  Autocomplete,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Stack,
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';
import { TextField, InputFile } from '../../../../components/atoms';
import { getLanguage } from '../../../../features/language';
import { useSelector } from 'react-redux';
import { IconFile, IconPaper } from '../../../../assets/benefit/reimbursement';
import { IconDept, IconMoney } from '../../../../assets/recruitment/jobs';
import { IconEmployee } from '../../../../assets/coreHr/awards';
import {
  getListRequest,
  getListEmployeeType,
  updateVacancyById,
  uploadVacancy,
  getVacancyById,
  getDocumentOfficeFacillitiesById,
} from '../../../../configs/axios/apiPath';

import { useEffect, useState } from 'react';
import { getCompany } from '../../../../features/companies';

import { useNavigate, useParams } from 'react-router-dom';

const getPicture = async (id) => {
  if (!id) return null;
  try {
    const response = await getDocumentOfficeFacillitiesById({}, id);
    return response.data;
  } catch (err) {
    return null;
  }
};

const RecruitmentJobsEdit = () => {
  const lang = useSelector(getLanguage);
  const navigate = useNavigate();

  const [fileBlob, setFileBlob] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [formData, setFormData] = useState();

  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedType, setType] = useState(null);

  const { id } = useParams();

  const company = useSelector(getCompany);

  const rupiah = (number) => {
    const newNumber = number.replaceAll('.', '').replace('Rp', '');
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      maximumFractionDigits: 0,
    }).format(newNumber);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
          const {
            data: { data },
          } = await getVacancyById(id);
          const blob = await getPicture(data?.document?.id);
          setFileBlob([blob]);
          setSelectedRequest({
            id: data.id,
            position: data.position,
            department: {
              id: 153,
              name: 'IT',
              icon_url:
                'https://dev-cvsaya2.x5.com.au/storage/department-icon/default-icon.png',
            },
            type: data.employment_type,
            status: 'posted',
            request_by: data.request_by,
          });
          setType(data.employment_type);
          setFormData({
            job_request_id: data?.request_id,
            employment_type_id: data?.employment_type.id,
            detail: data?.detail,
            urgent: data?.urgent,
            min_salary: data?.min_salary,
            max_salary: data?.max_salary,
          });
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('data');
            navigate(`/`);
          }
        }
      }
    };
    fetchData();
  }, [id]);
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListEmployeeType();
          setTypeData(
            data?.map((e) => ({
              id: e.id,
              name: e.name,
              icon_url: e.icon_url,
            }))
          );
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company]);
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          page_size: 9999999,
          year: new Date().getFullYear(),
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListRequest(params);

          setRequestData(data?.job_requests);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company]);

  const handlePost = async (document_id) => {
    const data = {
      company_id: company,
      document_id: document_id,
      ...formData,
      min_salary: formData.min_salary.replaceAll('.', '').replace('Rp', ''),
      max_salary: formData.max_salary.replaceAll('.', '').replace('Rp', ''),
    };

    setIsLoading(true);
    try {
      await updateVacancyById(data, id);
      navigate('/recruitment/vacancies');
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem('cvSayaLogin');
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const data = { data };
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await uploadVacancy({ file: fileBlob[0] });
        handlePost(data.id);

        resolve();
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    });
  };

  return (
    <Card sx={{ padding: '20px' }}>
      <Typography
        fontFamily={'sans-serif'}
        fontStyle={'normal'}
        fontWeight={600}
        fontSize={'20px'}
        lineHeight={'27px'}
        letterSpacing={'0.01em'}
        color={'#171717'}
        marginBottom="15px"
      >
        Edit
      </Typography>
      <Divider />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={'16px'}>
          <Grid item xs={6}>
            <Stack>
              <Autocomplete
                value={
                  requestData.find((e) => e.id === formData?.job_request_id) ??
                  null
                }
                onChange={(_, value) => {
                  if (value) {
                    setFormData((prev) => ({
                      ...prev,
                      job_request_id: value.id,
                    }));
                    setSelectedRequest(value);
                  } else {
                    setSelectedRequest(null);
                  }
                }}
                options={requestData}
                getOptionLabel={(option) => option.request_by.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={lang === 'Id' ? 'Request' : 'Request'}
                    fullWidth
                    required
                    variant="outlined"
                    size="small"
                    icon={
                      isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <IconEmployee />
                      )
                    }
                  />
                )}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(formData?.urgent)}
                    sx={{
                      color: '#c4c4c4',
                      '&.Mui-checked': {
                        color: '#c4c4c4',
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    sx={{
                      color: '#C4C4C4',
                      fontSize: '14',
                    }}
                  >
                    Urgent
                  </Typography>
                }
                onChange={(e) => {
                  const urgent = e.target.checked ? 1 : 0;
                  setFormData((prev) => ({ ...prev, urgent }));
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <TextField
              label={lang === 'Id' ? 'Dept' : 'Dept'}
              fullWidth
              required
              type="text"
              size="small"
              variant="outlined"
              value={
                requestData.find((e) => e.id === formData?.job_request_id)
                  ?.department?.name ?? null
              }
              icon={<IconDept />}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <TextField
              label={lang === 'Id' ? 'Posisi' : 'Position'}
              fullWidth
              required
              type="text"
              size="small"
              variant="outlined"
              value={
                requestData.find((e) => e.id === formData?.job_request_id)
                  ?.position?.name ?? null
              }
              icon={<IconDept />}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Autocomplete
              value={
                typeData.find((e) => e.id === formData?.employment_type_id) ??
                null
              }
              onChange={(_, value) => {
                if (value) {
                  setType(value);
                } else {
                  setType(null);
                }
              }}
              options={typeData}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={lang === 'Id' ? 'Tipe Pekerjaan' : 'Type Jobs'}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  icon={
                    isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <IconPaper />
                    )
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <TextField
              label={
                lang === 'Id' ? 'Kisaran Gaji Terendah' : 'Range Salary Min'
              }
              fullWidth
              required
              type="text"
              size="small"
              variant="outlined"
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  min_salary: rupiah(e.target.value),
                }));
              }}
              value={rupiah(formData?.min_salary?.toString() ?? '0')}
              icon={<IconMoney />}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={
                lang === 'Id' ? 'Kisaran Gaji Tertinggi' : 'Range Salary Max'
              }
              fullWidth
              required
              type="text"
              size="small"
              variant="outlined"
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  max_salary: rupiah(e.target.value),
                }));
              }}
              value={rupiah(formData?.max_salary?.toString() ?? '0')}
              icon={<IconMoney />}
            />
          </Grid>
          <Grid item xs={12}>
            <InputFile
              onChange={(data) => setFileBlob(data)}
              value={fileBlob}
              setValue={setFileBlob}
              icon={<IconFile />}
              label={lang === 'Id' ? 'Attachment' : 'Attachment'}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={lang === 'Id' ? 'Detail Pekerjaan' : 'Jobs Detail'}
              value={formData?.detail}
              fullWidth
              required
              type="text"
              size="small"
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  detail: e.target.value,
                }));
              }}
              variant="outlined"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          sx={{ marginTop: '30px' }}
          loading={isLoading}
        >
          {lang === 'Id' ? 'Simpan' : 'Save'}
        </LoadingButton>
      </form>
    </Card>
  );
};

export default RecruitmentJobsEdit;
