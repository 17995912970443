const DataTablePositionList = [
  {
    title: "Position",
    judul: "Posisi",
    path: "name",
  },
  {
    title: "Level",
    judul: "Level",
    path: "name",
  },
  {
    title: "Action",
    judul: "Aksi",
    path: "name",
  },
];

export default DataTablePositionList;
