import { Box, Divider, Grid, TablePagination, Typography } from '@mui/material';
import { useState } from 'react';
import { dataGridProject } from '../../../../../data/card/dashboardProject';
import { MenuDashboardProject } from '../../../../../data/menu';
import { GroupedAvatar, ProjectHandle } from '../../../../../utils/format';
import { Card } from '../../../../atoms';
import { CardProject } from '../../../../molecules/card';

export default function GridProject() {
  const [tag, setTag] = useState('all');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const filteredData =
    tag === 'all'
      ? dataGridProject
      : dataGridProject.filter((item) => item.status === tag);

  return (
    <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
      <Card>
        <Box
          sx={{
            backgroundColor: '#ffffff',
            padding: '12px 18px',
            borderRadius: '10px',
          }}
        >
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography
              fontWeight={'600px'}
              fontSize={'20px'}
              lineHeight={'27px'}
              color={'#171717'}
            >
              Project
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* {DataStatus.map(({ title, color }, index) => (
                <StatusHandle title={title} color={color} key={index} />
              ))} */}
            </Box>
          </Box>
        </Box>
        <Divider
          orientation="horizontal"
          flexItem
          sx={{ borderColor: '#c4c4c4', my: '10px' }}
        />

        <Box display={'flex'} px={'18px'}>
          <ProjectHandle
            tag={tag}
            projectMenu={MenuDashboardProject}
            setTag={(e) => setTag(e)}
          />
        </Box>

        <Box mt={'7px'} mb={'16px'} px={'18px'}>
          <Grid container columns={4} spacing={2}>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, idx) => (
                <Grid item xl={2} lg={2} md={2} sm={4} xs={4} key={idx}>
                  <CardProject
                    title={item.title}
                    status={item.status}
                    icon={item.icon}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'start'}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        color={item.color}
                      >
                        {item.iconProject}
                        <Typography
                          ml={'7px'}
                          fontSize={'14px'}
                          fontWeight={'600px'}
                          letterSpacing={'0.01rem'}
                          lineHeight={'14px'}
                        >
                          {item.date}
                        </Typography>
                      </Box>
                      <Box display={'flex'} mt={'14px'}>
                        <Box>
                          <GroupedAvatar />
                        </Box>
                      </Box>
                    </Box>
                  </CardProject>
                </Grid>
              ))}
          </Grid>
          <Box mt={'16px'} display={'flex'} justifyContent={'end'}>
            <TablePagination
              component="div"
              count={dataGridProject.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={(event, newPage) => setPage(newPage)}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
              labelRowsPerPage={'Items per page'}
              rowsPerPageOptions={[4, 8, 12]}
            />
          </Box>
        </Box>
      </Card>
    </Grid>
  );
}
