import { Avatar, Box, Typography } from "@mui/material";

const AttendanceHistoryTbody = ({ tbody }) => {
  return (
    <>
      <tbody>
        {tbody?.map((item, idx) => (
          <tr key={idx}>
            {/* --- Employee Name --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "start",
                verticalAlign: "middle",
              }}
            >
              <Box display={"flex"} alignItems={"center"}>
                {/* <img
                  src={item?.employee?.profile_picture}
                  alt={item?.employee?.name}
                  width={30}
                  height={30}
                /> */}
                <Avatar
                  sx={{ width: "30px", height: "30px" }}
                  src={item?.employee?.profile_picture}
                />
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={400}
                  fontSize={"14px"}
                  lineHeight={"130%"}
                  color={"#171717"}
                  ml={"10px"}
                >
                  {item.employee?.name}
                </Typography>
              </Box>
            </td>
            {/* --- History Date --- */}
            {item.monthly.map((month, id) => (
              <td
                key={id}
                style={{
                  padding: "10px 12px",
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
              >
                {/* {month?.status} */}
                <Box
                  component="img"
                  src={month?.status_icon}
                  sx={{
                    width: month?.status === "Late" ? "5px" : "14px",
                    height: month?.status === "Late" ? "18px" : "14px",
                  }}
                />
                {/* <img
                  src={month?.status_icon}
                  alt={month?.status_icon}
                  width={20}
                  height={20}
                /> */}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </>
  );
};

export default AttendanceHistoryTbody;
