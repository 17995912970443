import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  Avatar,
} from '@mui/material';
import { IconClose, IconBell } from '../../../../assets/commons';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { Card, Modals } from '../../../../components/atoms';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../../../features/language';
import moment from 'moment';
import { padding } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { PublishOrCancelWarningLetter } from '../../../../configs/axios/apiPath/fetchCoreHR/fetchCoreHRWarning';

const CoreHRWarningDetail = ({
  isOpen,
  onClose,
  detail,
  isLoading,
  refecth,
}) => {
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const [loadingButton, setLoadingButton] = useState(false);

  const handlePublish = async () => {
    const params = {
      status: 'published',
    };
    setLoadingButton(true);
    try {
      await PublishOrCancelWarningLetter(detail?.id, params);
      setLoadingButton(false);
      onClose();
      refecth();
    } catch (error) {
      console.error(error.data.message);
    }
  };
  const handleCancel = async () => {
    const params = {
      status: 'cancelled',
    };
    setLoadingButton(true);
    try {
      await PublishOrCancelWarningLetter(detail?.id, params);
      setLoadingButton(false);
      onClose();
      refecth();
    } catch (error) {
      console.error(error.data.message);
    }
  };

  // --- Handle Edit ---
  const handleEdit = (id) => {
    navigate(`/core-hr/warning/edit/${id}`); //TODO: handle edit warning
    console.log('edit', id, detail);
  };

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: '80vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
          borderRadius: '8px',
        }}
      >
        <Card>
          <Box p={'30px'}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '7px',
                position: 'sticky',
                top: 0,
                bgcolor: 'background.paper',
                p: '10px 0px 10px 0px',
                zIndex: 999,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography
                  fontFamily={'sans-serif'}
                  fontSize={'20px'}
                  fontWeight={600}
                  lineHeight={'27px'}
                  color={'#171717'}
                >
                  {lang === 'Id' ? 'Detail Peringatan' : 'Warning Details'}
                </Typography>
                {detail?.is_notification === 1 && (
                  <Box
                    sx={{
                      width: '24px',
                      height: '24px',
                      borderRadius: '100%',
                      background: '#51B15C',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IconBell />
                  </Box>
                )}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {detail?.status === 'draft' && (
                  <Box
                    sx={{
                      background: 'rgba(81, 177, 92, 0.2)',
                      padding: '6px',
                      width: '68px',
                      borderRadius: '8px',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleEdit(detail?.id)}
                  >
                    <Typography sx={{ color: '#51B15C', fontWeight: 600 }}>
                      Edit
                    </Typography>
                  </Box>
                )}
                <IconButton
                  sx={{
                    p: '0px',
                    background: 'rgba(230, 230, 230, 1)',
                    padding: '12px',
                    borderRadius: '8px',
                  }}
                  onClick={onClose}
                >
                  <IconClose />
                </IconButton>
              </Box>
            </Box>

            {!isLoading ? (
              <Box mt={'20px'} component={'form'} noValidate autoComplete="off">
                {/* --- Employee --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id' ? 'Karyawan' : 'Employee'}
                  </Typography>
                  <Box
                    style={{
                      width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      display: 'inline-block',
                    }}
                  >
                    <Typography mx={'10px'} py={'10px'}>
                      {detail?.name}
                    </Typography>
                  </Box>
                </Box>
                {/* --- Warning Type --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id' ? 'Tipe' : 'Type'}
                  </Typography>
                  <Box
                    style={{
                      width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      display: 'inline-block',
                    }}
                  >
                    <Typography mx={'10px'} py={'10px'}>
                      {detail?.warning_type?.name}
                    </Typography>
                  </Box>
                </Box>
                {/* ---  Reason --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id' ? 'Alasam' : 'Reason'}
                  </Typography>
                  <Box
                    style={{
                      // width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      // display: 'inline-block',
                      padding: '10px',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: 1, flexFlow: 'wrap' }}>
                      {detail?.reason?.map((item, idx) => (
                        <Box
                          key={idx}
                          sx={{
                            minWidth: '100px',
                            padding: '8px',
                            background: '#FFF',
                            boxShadow: '0px 1px 1px #000',
                            borderRadius: '8px',
                            fontSize: '14px',
                          }}
                        >
                          {item?.description}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
                {/* --- Publish Date --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id' ? 'Tanggal Peringatan' : 'Publish Date'}
                  </Typography>
                  <Box
                    style={{
                      width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      display: 'inline-block',
                    }}
                  >
                    <Typography mx={'10px'} py={'10px'}>
                      {moment(detail.publish_date).format('DD MMM YYYY')}
                    </Typography>
                  </Box>
                </Box>
                {/* --- Create Date --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id' ? 'Tanggal Dibuat' : 'Created '}
                  </Typography>
                  <Box
                    style={{
                      width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      display: 'inline-block',
                    }}
                  >
                    <Typography mx={'10px'} py={'10px'}>
                      {moment(detail.created_at).format('DD MMM YYYY')}
                    </Typography>
                  </Box>
                </Box>
                {/* ---  Notifable Superior --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id'
                      ? 'Atasan yang dapat melihat'
                      : 'Notifable Superior'}
                  </Typography>
                  <Box
                    style={{
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      padding: '10px',
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: 1, flexFlow: 'wrap' }}>
                      {detail?.notifiable_supperior?.map((item, idx) => (
                        <Box
                          key={idx}
                          sx={{
                            minWidth: '100px',
                            padding: '8px',
                            background: '#51B15C',
                            borderRadius: '8px',
                            fontSize: '14px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Avatar
                            alt={item?.name}
                            src={item?.profile_picture}
                            sx={{ width: 20, height: 20 }}
                          />
                          {item?.name}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
                {/* --- Status --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id' ? 'Status' : 'Status'}
                  </Typography>
                  <Box
                    style={{
                      width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      display: 'inline-block',
                    }}
                  >
                    <Box sx={{ display: 'flex', padding: '8px' }}>
                      <Box
                        sx={{
                          padding: '6px 10px',
                          minWidth: '72px',
                          height: '24px',
                          background:
                            detail?.status === 'draft'
                              ? 'rgba(253, 121, 0, 0.1)'
                              : detail?.status === 'cancelled'
                              ? 'rgba(228, 10, 10, 0.1)'
                              : 'rgba(81, 177, 92, 0.1)',
                          borderRadius: '8px',
                          color:
                            detail?.status === 'draft'
                              ? '#FD7900'
                              : detail?.status === 'cancelled'
                              ? '#E40A0A'
                              : '#51B15C',
                          fontWeight: 600,
                          fontVariantCaps: 'all-petite-caps',
                        }}
                      >
                        {detail?.status}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* --- Button --- */}
                {detail?.status === 'draft' && (
                  <Box sx={{ display: 'flex', gap: 2, mt: 4 }}>
                    {/* --- Close --- */}
                    <Button
                      disabled={loadingButton}
                      color="error"
                      fullWidth
                      variant="outlined"
                      disableRipple
                      onClick={handleCancel}
                      sx={{ height: '50px' }}
                    >
                      <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontSize={'20px'}
                        fontWeight={'700px'}
                        lineHeight={'27px'}
                        letterSpacing={'1px'}
                        textTransform={'none'}
                      >
                        {lang === 'Id' ? 'Batal' : 'Cancel'}
                      </Typography>
                    </Button>

                    {/* --- Publish --- */}
                    <Button
                      disabled={loadingButton}
                      fullWidth
                      variant="outlined"
                      disableRipple
                      onClick={handlePublish}
                      sx={{ height: '50px' }}
                    >
                      <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontSize={'20px'}
                        fontWeight={'700px'}
                        lineHeight={'27px'}
                        letterSpacing={'1px'}
                        textTransform={'none'}
                      >
                        {lang === 'Id' ? 'Publish' : 'Publish'}
                      </Typography>
                    </Button>
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <CircularProgress color="secondary" />
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </Modals>
  );
};

export default CoreHRWarningDetail;
