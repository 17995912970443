import { API } from "../../../API";

// --- List Location ---
export const getListLocation = (params) =>
  API.get('deparment-location', { params: params });

// --- Post Location ---
export const postLocation = (data) => 
  API.post('deparment-location', data);

// --- Get Location By Id ---
export const getLocationById = (id) => 
API.get(`deparment-location/${id}`);

// --- Update Location By Id ---
export const updateLocationById = (data, id) => 
API.put(`deparment-location/${id}`, data);

// --- Delete Location By Id ---
export const deleteLocationById = (id) => 
API.delete(`deparment-location/${id}`);  