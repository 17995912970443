import { Box } from "@mui/system";
import { Modals, Search } from "../../../atoms";
import { Card, Grid, Typography } from "@mui/material";
import { useState } from "react";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { CardMember } from "../../../molecules/card";

const AddMember = ({isOpen, onClose, memberProject}) => {
  const [page, setPage] = useState(1);

  return(
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '80%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Card>
          <Box p={'30px'}>
            <Box 
              display={'flex'}
              alignItems={'left'}
            >
              <Search
                placeholder="Type here to search"
                page={page}
                setPage={(e) => setPage(e)}
              />
            </Box>
            <Grid 
              container
              spacing={3}
              columns={10}
              marginTop={'16px'}
            >
              <Grid item xs={2}>
                <Card sx={{height: '260px', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                  <AddOutlinedIcon sx={{fontSize: 40, color:"#51B15C", marginBottom: '16px'}}/>
                  <Typography sx={{color: '#51B15C', fontWeight: '600', fontSize: '16px'}}>
                    ADD TEAM
                  </Typography>
                  <Typography sx={{color: '#51B15C', fontWeight: '600', fontSize: '16px'}}>
                    MEMBER
                  </Typography>                  
                </Card>
              </Grid>
              {
                memberProject.map((item, idx) => (
                  <CardMember
                    key={idx}
                    dataMember={item}
                  />
                ))
              }
            </Grid>
          </Box>
        </Card>
      </Box>
    </Modals>
  )
}

export default AddMember;