import { Box } from "@mui/material";
import React from "react";
import {
  TableOfficeFacillities,
} from "../../../../components/organisms/benefit";
const BenefitOfficeFacillitiesDashboard = () => {
  return (
    <Box>
      <TableOfficeFacillities />
    </Box>
  );
};

export default BenefitOfficeFacillitiesDashboard;
