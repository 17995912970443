import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import {
  IconDelete,
  IconDetail,
  IconEdit,
} from '../../../../../../assets/commons';
import { Tooltips, TooltipsAction } from '../../../../../atoms';
import { IconDescription } from '../../../../../../assets/coreHr/promotion';
import {
  handleTooltipClose,
  handleTooltipOpen,
} from '../../../../../../utils/function/tooltips';
import { handleAction } from '../../../../../../utils/function/action';
import { ModalDelete } from '../../../../modal';
import CoreHRWarningDetail from '../../../../../../pages/coreHR/warning/detail';
import {
  getImageDocument,
  getWarningById,
} from '../../../../../../configs/axios/apiPath';
import DataLogin from '../../../../../../data/dataLogin';
import { Avatar, List, ListItem, ListItemText } from '@mui/material';

const WarningTbody = ({ tbody, setData, refetch }) => {
  const navigate = useNavigate();
  const getData = DataLogin();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [idEmployee, setIdEmployee] = useState(null);
  const [detail, setDetail] = useState([]);
  const [temporaryImage, setTemporaryImage] = useState(null);

  const [idTooltip, setIdTooltip] = useState('');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [idTooltipDetail, setIdTooltipDetail] = useState('');
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);
  const [idTooltipDelete, setIdTooltipDelete] = useState('');
  const [isTooltipDelete, setIsTooltipDelete] = useState(false);
  const [idTooltipEdit, setIdTooltipEdit] = useState('');
  const [isTooltipEdit, setIsTooltipEdit] = useState(false);
  const [idTooltipType, setIdTooltipType] = useState('');
  const [isTooltipType, setIsTooltipType] = useState(false);

  // --- Handle Edit ---
  const handleEdit = (id) => {
    navigate(`/core-hr/warning/edit/${id}`);
  };

  // --- Load Detail ---
  useEffect(() => {
    async function fetchDatas() {
      if (idEmployee !== null) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getWarningById(idEmployee);
          setDetail(data);
          const imageBlob = await fetchData(data.document?.id);
          setTemporaryImage(imageBlob);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchDatas();
  }, [idEmployee]);

  // --- Fetch Data ---
  const fetchData = async (newId) => {
    setIsLoading(true);
    try {
      const response = await getImageDocument(newId, getData.token);

      const contentType = response.headers['content-type'];
      const blob = new Blob([response.data], { type: contentType });
      const objectURL = URL.createObjectURL(blob);
      return objectURL;
    } catch (error) {
      console.error(error.response.status);
      if (error.response.status === 401) {
        localStorage.removeItem('data');
        navigate(`/`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Employee Name --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'start',
                verticalAlign: 'middle',
              }}
            >
              <Box
                display={'flex'}
                flexDirection={'start'}
                justifyContent={'start'}
                alignItems={'center'}
              >
                <Avatar
                  src={item?.profile_picture || ''}
                  sx={{ width: '30px', height: '30px' }}
                />
                <Box ml={'10px'}>
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontWeight={400}
                    fontSize={'14px'}
                    lineHeight={'130%'}
                    color={'#171717'}
                  >
                    {item?.name}
                  </Typography>
                </Box>
              </Box>
            </td>
            {/* --- remaining day --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {Math.floor(item?.remaining_days / 30)}m{' '}
                {item?.remaining_days % 30}d
              </Typography>
            </td>
            {/* --- Date --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {moment(item.warning_date).format('DD MMM YY')}
              </Typography>
            </td>

            {/* --- reason --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Tooltips
                title={
                  <List>
                    {item?.reason?.map((title, idx) => (
                      <ListItem sx={{ p: 0, m: 0 }} key={idx}>
                        <ListItemText primary={title?.description} />
                      </ListItem>
                    ))}
                  </List>
                }
                open={isTooltipOpen && item.id === idTooltip}
                arrow
                placement={'bottom-end'}
              >
                <Button
                  onMouseEnter={() =>
                    handleTooltipOpen(item.id, setIsTooltipOpen, setIdTooltip)
                  }
                  onMouseLeave={() => handleTooltipClose(setIsTooltipOpen)}
                >
                  <IconDescription />
                </Button>
              </Tooltips>
            </td>
            {/* --- warning Type --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <TooltipsAction
                title={item.warning_type?.name}
                open={isTooltipType && item.id === idTooltipType}
                arrow
                placement={'bottom-end'}
              >
                <Box
                  onMouseEnter={() =>
                    handleTooltipOpen(
                      item.id,
                      setIsTooltipType,
                      setIdTooltipType
                    )
                  }
                  onMouseLeave={() => handleTooltipClose(setIsTooltipType)}
                >
                  <img
                    src={item?.warning_type?.icon_url}
                    alt={item?.warning_type?.name}
                    width={30}
                    height={30}
                  />
                </Box>
              </TooltipsAction>
            </td>
            {/* --- status --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  padding: '6px 10px',
                  minWidth: '72px',
                  height: '24px',
                  background:
                    item?.status === 'draft'
                      ? 'rgba(253, 121, 0, 0.1)'
                      : item?.status === 'cancelled'
                      ? 'rgba(228, 10, 10, 0.1)'
                      : 'rgba(81, 177, 92, 0.1)',
                  borderRadius: '8px',
                  color:
                    item?.status === 'draft'
                      ? '#FD7900'
                      : item?.status === 'cancelled'
                      ? '#E40A0A'
                      : '#51B15C',
                  fontWeight: 600,
                  fontVariantCaps: 'all-petite-caps',
                }}
              >
                {item?.status}
              </Box>
            </td>
            {/* --- Action --- */}
            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={1}
              >
                {/* --- Detail --- */}
                <TooltipsAction
                  title="Detail"
                  open={isTooltipDetail && item.id === idTooltipDetail}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        'detail'
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDetail,
                        setIdTooltipDetail
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDetail)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>

                {/* --- Delete --- */}
                <TooltipsAction
                  title="Delete"
                  open={isTooltipDelete && item.id === idTooltipDelete}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        'delete'
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDelete,
                        setIdTooltipDelete
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDelete)}
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>

                {/* --- Edit --- */}
                {/* <TooltipsAction
                  title="Edit"
                  open={isTooltipEdit && item.id === idTooltipEdit}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleEdit(item.id)}
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipEdit,
                        setIdTooltipEdit
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipEdit)}
                  >
                    <IconEdit />
                  </Box>
                </TooltipsAction> */}
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
      {/* --- Modal Handle --- */}
      {idEmployee !== null && modal === 'detail' ? (
        <CoreHRWarningDetail
          refecth={refetch}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setDetail([]);
          }}
          detail={detail}
          isLoading={isLoading}
          images={temporaryImage}
        />
      ) : modal === 'delete' ? (
        <ModalDelete
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={'Warning'}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          id={idEmployee}
          data={tbody}
          setData={setData}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default WarningTbody;
