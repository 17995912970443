import { API } from "../../../API";

// --- List Announcement ---
export const getListReimbursement = (params) =>
  API.get("reimbursement", { params: params });

export const getSummaryReimbursement = (params) =>
  API.get("reimbursement/summaries", { params: params });

export const getFileReimbursement = (data) =>
  API.post("reimbursement/download", data, { responseType: "blob" });

// --- Post Announcement ---
export const postReimbursement = (data) => API.post("reimbursement", data);

export const uploadReimbursement = (data) =>
  API.postForm("reimbursement/upload", data);

// --- Get Announcement By Id ---
export const getReimbursementById = (id) => API.get(`reimbursement/${id}`);

// --- Update Announcement By Id ---
export const updateReimbursementById = (data, id) =>
  API.put(`reimbursement/${id}`, data);

// --- Delete Announcement By Id ---
export const deleteReimbursementById = (id) =>
  API.delete(`reimbursement/${id}`);
