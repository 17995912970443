import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.css';
import {
  ContentState,
  EditorState,
  Modifier,
  RichUtils,
  convertFromHTML,
  convertToRaw,
} from 'draft-js';
import { useState } from 'react';
import Template from './template';
import draftToHtml from 'draftjs-to-html';

const CustomEditor = ({ content, setDescription, type }) => {
  const [editorState, setEditorState] = useState(() => {
    const defaultTemplate = content || '<p>Add description</p>';
    const defaultContent = content || defaultTemplate;

    const blocksFromHTML = convertFromHTML(defaultContent);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(contentState);
  });

  const handleEditorChange = (state) => {
    setEditorState(state);

    if (setDescription) {
      let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      setDescription(html);
    }
  };

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return 'handled';
    }

    if (command === 'tab') {
      const contentState = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();
      const newContentState = Modifier.replaceText(
        contentState,
        selectionState
      );
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'insert-characters'
      );
      setEditorState(newEditorState);
      return 'handled';
    }

    return 'not-handled';
  };

  return (
    <div
      className="outerEditor"
      style={{
        border: '2px solid #f9f9f9',
        borderRadius: '20px',
        height: '100%',
        width: '100%',
        padding: '10px',
        color: 'black',
      }}
    >
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName=""
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        handleKeyCommand={handleKeyCommand}
      />
    </div>
  );
};

export default CustomEditor;
