export const dataPayrollHistoryFooter = [
  {
    title: 'Success',
    judul: 'Sukses',
    color: '#51B15C',
  },
  {
    title: 'Failled',
    judul: 'Gagal',
    color: '#FF1D1D',
  },
  {
    title: 'Pending',
    judul: 'Pending',
    color: '#CF8812',
  },
];
