import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import {
  Card,
  Pagination,
  RowPerPage,
  Search,
} from '../../../../components/atoms';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../../../features/language';
import { getCompany } from '../../../../features/companies';
import { Link, useNavigate } from 'react-router-dom';
import SelectYear from '../../../../components/molecules/year';
import { IconPlus } from '../../../../assets/commons';
import TableComponent from '../../../../components/molecules/table/mainTable';
import {
  WarningTbody,
  WarningThead,
} from '../../../../components/molecules/table/tableCoreHR/warning';
import { getListWarning } from '../../../../configs/axios/apiPath';
import { DataTableWarning } from '../../../../data/table/coreHR';
import { Warning1, Warning2, Warning3 } from '../../../../assets/coreHr/awards';

const CoreHRWarningList = () => {
  const yearNow = moment().format('YYYY');
  const year = [];
  const pages = [10, 15, 20, 25, 30];

  const [pageSize, setPageSize] = useState(pages[0]);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [selectedYear, setSelectedYear] = useState(yearNow);
  const [isLoading, setIsLoading] = useState(false);
  const [warning, setWarning] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [order, setOrder] = useState('name');
  const [direction, setDirection] = useState(false);
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);

  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }

  const handleNavigationClick = (url) => {
    navigate('/core-hr/warning/add');
  };

  async function fetchData() {
    if (company) {
      const params = {
        company_id: company,
        keyword: keyword,
        page_size: pageSize,
        page: page,
        sort: order,
        direction: direction ? 'asc' : 'desc',
        year: selectedYear,
      };
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await getListWarning(params);
        setLastPage(data?.page_info?.last_page);
        setWarning(data?.warnings);
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  }
  useEffect(() => {
    fetchData();
  }, [
    page,
    keyword,
    company,
    pageSize,
    selectedYear,
    order,
    direction,
    keyword,
  ]);
  return (
    <Card sx={{ marginTop: '20px' }}>
      <Box display={'flex'} flexDirection={'column'} p={'23px 22px'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          {/* --- Search --- */}
          <Search
            placeholder={
              lang === 'Id'
                ? 'Ketik disini untuk mencari'
                : 'Type here to search'
            }
            setSearch={(e) => setKeyword(e)}
            setPage={(e) => setPage(e)}
          />

          <Box display={'flex'} alignItems={'center'}>
            {/* --- Selected Year --- */}
            <SelectYear
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              year={year}
            />

            {/* --- Add --- */}
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              onClick={() => handleNavigationClick('add')}
              sx={{
                borderRadius: '4px',
                marginLeft: '10px',
              }}
            >
              <Box display={'flex'} alignItems={'center'}>
                <IconPlus />
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={600}
                  fontSize={'12px'}
                  lineHeight={'14px'}
                  color="primary.contrastText"
                  letterSpacing={'0.02em'}
                  marginLeft={'10px'}
                  textTransform={'none'}
                >
                  {lang === 'Id' ? 'Tambah' : 'Add'}
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>

        {/* --- Table --- */}
        <Box sx={{ mt: '24px', mb: '16px' }}>
          <TableComponent
            isLoading={isLoading}
            tbody={warning}
            TableHead={
              <WarningThead
                thead={DataTableWarning}
                order={order}
                setOrder={setOrder}
                setDirection={setDirection}
                direction={direction}
              />
            }
            TableBody={
              <WarningTbody
                refetch={fetchData}
                tbody={warning}
                setData={setWarning}
              />
            }
            col={7}
          />
        </Box>

        {/* --- Footers --- */}
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          {/* --- Icon Footers --- */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              flexWrap: 'wrap',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Warning1 />
              <Typography>First Warning Letter</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Warning2 />
              <Typography>Second Warning Letter</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Warning3 />
              <Typography>Final Warning Letter</Typography>
            </Box>
          </Box>
          {/* --- Pagination --- */}
          <Box display={'flex'} justifyContent={'end'}>
            <RowPerPage
              pages={pages}
              setPageSize={setPageSize}
              pageSize={pageSize}
              setPage={setPage}
            />
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
export default CoreHRWarningList;
