import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  debounce,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCompany } from '../../../../../../features/companies';
import { getShift } from '../../../../../../configs/axios/apiPath';
import { Buttons, Card, Modals } from '../../../../../../components/atoms';
import { IconQuit } from '../../../../../../assets/commons';
import DatePicker from 'react-multi-date-picker';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import { Close } from '@mui/icons-material';

export default function AddShiftOneTime({
  isOpen,
  onClose,
  handleSubmitOneTime,
  data,
  setData,
}) {
  const company = useSelector(getCompany);
  const navigate = useNavigate();
  const [date, setDate] = useState('');
  const [shiftOneTime, setShiftOneTime] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const debounceSearch = useRef(
    debounce((value) => {
      setSearch(value);
    }, 1000)
  ).current;

  // --- List Shift ---

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          search: search === 0 ? '' : search,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getShift(params);
          setShiftOneTime(data.shifts);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('data');
            navigate(`/`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, navigate, search]); //TODO: form add one time shift

  const [visibleDay, setVisibleDay] = useState([]);
  const [message, setMessage] = useState('');

  const updateDataItem = (index, newData) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = newData;
      return updatedData;
    });
  };

  const updateShiftForItem = (index, newShift) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = { ...updatedData[index], shift: newShift };
      return updatedData;
    });
  };

  const removeItem = (index) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData.splice(index, 1);
      return updatedData;
    });
  };
  const clearDataDay = (index) => {
    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = { values: [], shift: null };
      return updatedData;
    });
  };

  const [whosOpen, setWhosOpen] = useState(0);
  useEffect(() => {
    if (data.length > 1) {
      const tempData = [...data];
      tempData.splice(whosOpen, 1);

      const toVisible = tempData.map(
        (item) =>
          item.values.length !== 0 && item.values.map((iDay) => iDay.day)
      );

      const finResult = [].concat(...toVisible);
      setVisibleDay(finResult);
    }
  }, [data, whosOpen]);

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Card>
          <Box p={'30px'} sx={{ maxHeight: '600px', overflow: 'scroll' }}>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              mb={'7px'}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontSize={'16px'}
                fontWeight={600}
                lineHeight={'22px'}
                color={'#171717'}
              >
                Add One Time Shift
              </Typography>
              <IconButton sx={{ p: '0px' }} onClick={onClose}>
                <IconQuit />
              </IconButton>
            </Box>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ borderColor: '#c4c4c4', mb: '20px' }}
            />
            {message && (
              <Typography sx={{ color: '#F33A3A', fontSize: '10px', mb: 3 }}>
                {message}
              </Typography>
            )}
            <form>
              <Grid container columns={12} columnSpacing={3}>
                {data?.map((item, idx) => (
                  <>
                    {/* --- Date --- */}
                    <Grid item xl={5} lg={6} md={12} sm={12} xs={12}>
                      <Box display={'flex'} flexDirection={'column'} mb={'9px'}>
                        <InputLabel
                          style={{
                            fontSize: '12px',
                            marginBottom: '5px',
                            fontWeight: 'bold',
                          }}
                        >
                          Date
                        </InputLabel>
                        <DatePicker
                          onOpen={() => setWhosOpen(idx)}
                          highlightToday={false}
                          multiple
                          value={item.values}
                          onChange={(value) =>
                            updateDataItem(idx, { ...item, values: value })
                          }
                          format="DD/MMM/YY"
                          style={{ height: '32px', width: '100%' }}
                          mapDays={({ date }) => {
                            let props = {};

                            if (visibleDay.includes(date.day))
                              props.disabled = true;

                            return props;
                          }}
                        >
                          <Buttons onClick={() => clearDataDay(idx)}>
                            Clear
                          </Buttons>
                        </DatePicker>
                      </Box>
                    </Grid>
                    {/* --- List Shift --- */}
                    <Grid item xl={6} lg={5} md={10} sm={10} xs={10}>
                      <Box display={'flex'} flexDirection={'column'} mb={'9px'}>
                        <InputLabel
                          style={{
                            fontSize: '12px',
                            marginBottom: '5px',
                            fontWeight: 'bold',
                          }}
                        >
                          Shift
                        </InputLabel>
                        <Autocomplete
                          loading={isLoading}
                          loadingText={
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              my={'30px'}
                            >
                              <CircularProgress size={18} />
                            </Box>
                          }
                          // multiple
                          size="small"
                          value={item?.shift}
                          // onChange={() => handleSelectShift(idx, item)}
                          onChange={(event, newShift) => {
                            updateShiftForItem(idx, newShift);
                          }}
                          // inputValue={inputValue}
                          onInputChange={(e) => {
                            debounceSearch(e.target.value);
                            handleInputChange(e);
                          }}
                          options={shiftOneTime}
                          getOptionLabel={(shift) => shift?.name ?? ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Shift"
                              InputLabelProps={{ shrink: false }}
                              sx={{
                                fontSize: '10px',
                                background: '#f9f9f9',
                                '& .MuiOutlinedInput-root': {
                                  background: 'transparent',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'transparent',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                  {
                                    borderColor: 'transparent',
                                  },
                                '& .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'transparent',
                                },
                              }}
                              variant="outlined"
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    {data.length > 1 && (
                      <Grid
                        item
                        xl={1}
                        lg={1}
                        md={2}
                        sm={2}
                        xs={2}
                        sx={{
                          display: 'flex',
                          mt: 2,
                          alignItems: 'center',
                        }}
                      >
                        <Close
                          sx={{
                            color: '#F33A3A',
                            fontSize: '16px',
                            cursor: 'pointer',
                          }}
                          onClick={() => removeItem(idx)}
                        />
                      </Grid>
                    )}
                  </>
                ))}

                <Box
                  sx={{
                    width: '100%',
                    mt: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '12px',
                      fontWeight: 600,
                      cursor: 'pointer',
                      gap: 0.5,
                      justifyContent: 'end',
                    }}
                    onClick={() =>
                      setData([...data, { values: [], shift: null }])
                    }
                  >
                    Add More
                    <AddIcon
                      sx={{
                        fontSize: '12px',
                        background: '#51B15C',
                        width: '12px',
                        height: '12px',
                        borderRadius: '100px',
                        color: '#FFF',
                      }}
                    />
                  </Typography>
                </Box>

                {/* --- Buttons --- */}
                <Button
                  variant="contained"
                  disableRipple
                  fullWidth
                  sx={{ mt: 5 }}
                  onClick={() => {
                    if (
                      data.some(
                        (item) =>
                          item.shift === null || item.values.length === 0
                      )
                    ) {
                      setMessage("fields can't be null or empty");
                    } else {
                      setMessage('');
                      handleSubmitOneTime(data);
                    }
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'20px'}
                    fontWeight={'700px'}
                    lineHeight={'27px'}
                    letterSpacing={'1px'}
                    textTransform={'none'}
                  >
                    Add Shift
                  </Typography>
                </Button>
              </Grid>
            </form>
          </Box>
        </Card>
      </Box>
    </Modals>
  );
}
