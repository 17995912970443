import React from "react";
import EmployeeDetailTabPanel from "../../../../../components/molecules/tab/employeeTab/detailTab";
import { Box, Skeleton, Typography } from "@mui/material";
import { IconPrev } from "../../../../../assets/commons";
import { Card } from "../../../../../components/atoms";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getEmployeesById } from "../../../../../configs/axios/apiPath";
import moment from "moment";

const DetailDashboard = () => {
  const path = useLocation();
  const pathname = path.pathname;
  const paths = pathname.split("/");
  const id = paths[4];
  const [dataEmployee, setDataEmployee] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        include: "resume",
      };
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await getEmployeesById(id, params);
        setDataEmployee(data);
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id, navigate]);

  return (
    <Card sx={{ marginTop: "20px" }}>
      <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
        {/* --- Back --- */}
        <Link to=".." style={{ cursor: "pointer" }}>
          <Box display={"flex"} alignItems={"center"}>
            <IconPrev />
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontWeight={600}
              fontSize={"14px"}
              lineHeight={"19px"}
              letterSpacing={"0.01em"}
              color={"#171717"}
              ml={"10px"}
            >
              Back
            </Typography>
          </Box>
        </Link>

        {/* --- Content Header --- */}
        {isLoading ? (
          <Box display={"flex"} alignItems={"center"} mb={"40px"}>
            <Skeleton variant="circular" width={80} height={80} />
            <Box display={"flex"} flexDirection={"column"} ml={"36px"}>
              <Skeleton variant="text" width={"30vw"} height={40} />
              <Skeleton variant="text" width={"30vw"} height={40} />
              <Skeleton variant="text" width={"30vw"} height={40} />
            </Box>
          </Box>
        ) : (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"start"}
            mb={"40px"}
            mt={"30px"}
          >
            <img
              src={dataEmployee?.employee?.profile_picture}
              alt={dataEmployee?.employee?.name}
              width={80}
              style={{ borderRadius: "50%" }}
            />
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"start"}
              ml={"35px"}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"30px"}
                  lineHeight={"41px"}
                  letterSpacing={"0.01em"}
                  color={"#171717"}
                  mr={"21px"}
                >
                  {dataEmployee?.employee?.name}
                </Typography>
                <Box
                  p={"4px 10px"}
                  sx={{
                    background:
                      dataEmployee?.employee?.completeness < 50
                        ? "#FF1D1D"
                        : dataEmployee?.employee?.completeness > 75
                        ? "#51B15C"
                        : "#CF8812",
                  }}
                  borderRadius={"6px"}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={600}
                    fontSize={"10px"}
                    lineHeight={"11px"}
                    color={"#ffffff"}
                  >
                    {dataEmployee?.employee?.completeness}% success
                  </Typography>
                </Box>
              </Box>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"20px"}
                lineHeight={"27px"}
                letterSpacing={"0.01em"}
                color={"#171717"}
                mb={"10px"}
              >
                {dataEmployee?.resume?.expected_job?.position?.name}
              </Typography>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"16px"}
                lineHeight={"22px"}
                letterSpacing={"0.01em"}
                color={"#939393"}
              >
                Joined since{" "}
                {moment(dataEmployee?.joined_at).format("MMMM DD, YYYY")}
              </Typography>
            </Box>
          </Box>
        )}

        {/* --- TabPanel --- */}
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <EmployeeDetailTabPanel />
        </Box>
      </Box>
    </Card>
  );
};

export default DetailDashboard;
