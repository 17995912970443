import React from 'react';
import Data from '../../homepage/homepageAttendance/attendanceChart/dataMain';
import DataTooltips from '../../homepage/homepageAttendance/attendanceChart/dataTooltips';
import { Card, LineChart } from '../../../atoms';
import { Box } from '@mui/material';

const ChartAttendance = () => {
  const data = Data;
  const dataTooltips = DataTooltips;
  return (
    <Card sx={{ marginTop: '20px' }}>
      <Box display={'flex'} flexDirection={'column'} p={'23px 22px'}>
        <LineChart data={data} dataTooltips={dataTooltips} />
      </Box>
    </Card>
  );
};

export default ChartAttendance;
