import { Box, Button, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconPlus } from "../../../../assets/commons";
import {
  Card,
  Pagination,
  RowPerPage,
  Search,
} from "../../../../components/atoms";
import TableComponent from "../../../../components/molecules/table/mainTable";
import {
  AwardsTbody,
  AwardsThead,
} from "../../../../components/molecules/table/tableCoreHR/awards";
import SelectYear from "../../../../components/molecules/year";
import { getListAwards } from "../../../../configs/axios/apiPath/fetchCoreHR/fetchCoreHRAwards";
import { DataTableAwards } from "../../../../data/table/coreHR";
import { getCompany } from "../../../../features/companies";
import { getLanguage } from "../../../../features/language";
import { ChartAttendance } from "../../../../components/organisms/timesheet";

const CoreHRAwardsDashboard = () => {
  const yearNow = moment().format("YYYY");
  const year = [];
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [selectedYear, setSelectedYear] = useState(yearNow);
  const [isLoading, setIsLoading] = useState(false);
  const [awards, setAwards] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [order, setOrder] = useState("award_name");
  const [direction, setDirection] = useState("asc");
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);

  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }

  const handleNavigationClick = (url) => {
    navigate("/core-hr/awards/add");
  };

  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          page_size: pageSize,
          page: page,
          company_id: company,
          keyword: keyword,
          year: selectedYear,
          order: order,
          direction: direction,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListAwards(params);
          setLastPage(data?.page_info?.last_page);
          setAwards(data?.employee_awards);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [
    page,
    keyword,
    company,
    pageSize,
    selectedYear,
    order,
    direction,
    navigate,
  ]);

  return (
    <Card sx={{ marginTop: "20px" }}>
      <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
        <Box my={"30px"}>
          <ChartAttendance />
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          {/* --- Search --- */}
          <Search
            placeholder={
              lang === "Id"
                ? "Ketik disini untuk mencari"
                : "Type here to search"
            }
            setSearch={(e) => setKeyword(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />

          <Box display={"flex"} alignItems={"center"}>
            {/* --- Selected Year --- */}
            <SelectYear
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              year={year}
            />

            {/* --- Add --- */}
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              onClick={() => handleNavigationClick("add")}
              sx={{
                borderRadius: "4px",
                marginLeft: "10px",
              }}
            >
              <Box display={"flex"} alignItems={"center"}>
                <IconPlus />
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"14px"}
                  color="primary.contrastText"
                  letterSpacing={"0.02em"}
                  marginLeft={"10px"}
                  textTransform={"none"}
                >
                  {lang === "Id" ? "Tambah" : "Add"}
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>

        {/* --- Table --- */}
        <Box sx={{ mt: "24px", mb: "16px" }}>
          <TableComponent
            isLoading={isLoading}
            tbody={awards}
            TableHead={
              <AwardsThead
                thead={DataTableAwards}
                setOrder={setOrder}
                setDirection={setDirection}
                direction={direction}
              />
            }
            TableBody={<AwardsTbody tbody={awards} setData={setAwards} />}
            col={5}
          />
        </Box>

        {/* --- Pagination --- */}
        <Box display={"flex"} justifyContent={"end"}>
          <RowPerPage
            setPageSize={setPageSize}
            pageSize={pageSize}
            setPage={setPage}
          />
          <Pagination page={page} setPage={setPage} lastPage={lastPage} />
        </Box>
      </Box>
    </Card>
  );
};

export default CoreHRAwardsDashboard;
