const DataTableAttendanceCorrection = [
  {
    title: "Employee",
    judul: "Karyawan",
    url: "name",
  },
  {
    title: "Approve By",
    judul: "Disetujui Oleh",
    url: "name",
  },
  {
    title: "Type",
    judul: "Tipe",
    url: "name",
  },
  // {
  //   title: "Hour",
  //   judul: "Jam",
  //   url: "name",
  // },
  {
    title: "Correction Date",
    judul: "Tanngal Koreksi",
    url: "name",
  },
  {
    title: "Description",
    judul: "Deskripsi",
    url: "name",
  },
  {
    title: "Document",
    judul: "Dokumen",
    url: "name",
  },
  {
    title: "Status",
    judul: "Status",
    url: "name",
  },
  {
    title: "Action",
    judul: "Aksi",
    url: "name",
  },
];

export default DataTableAttendanceCorrection;
