import { Avatar, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import {
  IconFulltime,
  IconHybrid,
  IconRemote,
} from '../../../../../../assets/department';
import { TooltipsAction } from '../../../../../atoms';
import { IconDelete, IconDetail } from '../../../../../../assets/commons';
import { handleAction } from '../../../../../../utils/function/action';
import {
  handleTooltipClose,
  handleTooltipOpen,
} from '../../../../../../utils/function/tooltips';
import { useState } from 'react';
import { ModalDelete } from '../../../../modal';
import { DepartmentDetail } from '../../../../../../pages/organization/department';
import { useEffect } from 'react';
import { getEmployeesById } from '../../../../../../configs/axios/apiPath';
import { useNavigate } from 'react-router-dom';

const DepartmentListTbody = ({ tbody, setData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState('');
  const [idEmployee, setIdEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState([]);

  const [idTooltipDetail, setIdTooltipDetail] = useState('');
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);
  const [idTooltipDelete, setIdTooltipDelete] = useState('');
  const [isTooltipDelete, setIsTooltipDelete] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchEmployeeById() {
      if (idEmployee) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getEmployeesById(idEmployee);
          setDetail(data);
        } catch (error) {
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchEmployeeById();
  }, [idEmployee, navigate]);

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            <td
              style={{
                width: '10px',
                textAlign: 'center',
              }}
            >
              {idx + 1}
            </td>
            <td>
              <Box display={'flex'}>
                <Avatar alt={item.name} src={item.profile_picture} />
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={400}
                  fontSize={'14px'}
                  lineHeight={'45px'}
                  color={'#171717'}
                  sx={{
                    ml: '10px',
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
            </td>
            <td>
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item.position}
              </Typography>
            </td>
            <td>
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item.service}
              </Typography>
            </td>
            <td>
              <Box display={'flex'}>
                {item.status === 'Part-time' ? (
                  <IconButton
                    sx={{ p: '0px', zoom: '200%', margin: '0px auto' }}
                  >
                    <IconHybrid />
                  </IconButton>
                ) : item.status === 'Full-Time' ? (
                  <IconButton
                    sx={{ p: '0px', zoom: '200%', margin: '0px auto' }}
                  >
                    <IconFulltime />
                  </IconButton>
                ) : item.status === 'Remote' ? (
                  <IconButton
                    sx={{ p: '0px', zoom: '200%', margin: '0px auto' }}
                  >
                    <IconRemote />
                  </IconButton>
                ) : (
                  ''
                )}
              </Box>
            </td>
            <td>
              {/** action */}
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={1}
              >
                {/* --- Detail --- */}
                <TooltipsAction
                  title="Detail"
                  open={isTooltipDetail && item.id === idTooltipDetail}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        'detail'
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDetail,
                        setIdTooltipDetail
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDetail)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>

                {/* --- Delete --- */}
                <TooltipsAction
                  title="Delete"
                  open={isTooltipDelete && item.id === idTooltipDelete}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        'delete'
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDelete,
                        setIdTooltipDelete
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDelete)}
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>
              </Box>

              {/* --- Modal Handle --- */}
              {idEmployee !== null && modal === 'detail' ? (
                <DepartmentDetail
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  detail={detail}
                  isLoading={isLoading}
                />
              ) : modal === 'delete' ? (
                <ModalDelete
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  title={'Employee'}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  id={idEmployee}
                  data={tbody}
                  setData={setData}
                />
              ) : (
                ''
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

export default DepartmentListTbody;
