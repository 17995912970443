import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { Card, Modals } from "../../../../components/atoms";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../features/language";
import { IconClose } from "../../../../assets/commons";
import moment from "moment";

const BenefitReimbursementDetail = ({ isOpen, onClose, detail, isLoading }) => {
  const lang = useSelector(getLanguage);

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "background.paper",
        }}
      >
        <Card>
          <Box p={"30px"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: "7px",
                position: "sticky",
                top: 0,
                bgcolor: "background.paper",
                p: "10px 0px 10px 0px",
                zIndex: 999,
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"27px"}
                color={"#171717"}
              >
                {lang === "Id" ? "Detail" : "Details"}
              </Typography>
              <IconButton sx={{ p: "0px" }} onClick={onClose}>
                <IconClose />
              </IconButton>
            </Box>

            {!isLoading ? (
              <Box mt={"20px"} component={"form"} noValidate autoComplete="off">
                {/* --- Employee --- */}
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    {lang === "Id" ? "Karyawan" : "Employee"}
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      background: "#f7f7f7",
                      borderRadius: "4px",
                      border: "1px solid transparent",
                      display: "inline-block",
                    }}
                  >
                    <Typography mx={"10px"} py={"10px"}>
                      {detail?.request_by?.name}
                    </Typography>
                  </Box>
                </Box>
                {/* --- Dept --- */}
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    Dept
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      background: "#f7f7f7",
                      borderRadius: "4px",
                      border: "1px solid transparent",
                      display: "inline-block",
                    }}
                  >
                    <Stack
                      direction="row"
                      gap="10px"
                      alignItems="center"
                      py={"10px"}
                      px={"20px"}
                    >
                      <img
                        src={detail?.department.icon_url}
                        style={{ width: 20, height: 20 }}
                        alt="icon"
                      />

                      <Typography mx={"10px"}>
                        {detail?.department.name}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
                {/* --- Name --- */}
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    Nme
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      background: "#f7f7f7",
                      borderRadius: "4px",
                      border: "1px solid transparent",
                      display: "inline-block",
                    }}
                  >
                    <Typography mx={"10px"} py={"10px"}>
                      {detail?.position.name}
                    </Typography>
                  </Box>
                </Box>
                {/* --- Release Date --- */}
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    {lang === "Id" ? "Tanggal Rilis" : "Release Date"}
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      background: "#f7f7f7",
                      borderRadius: "4px",
                      border: "1px solid transparent",
                      display: "inline-block",
                    }}
                  >
                    <Typography mx={"10px"} py={"10px"}>
                      {moment(detail?.created_at).format("DD MMMM YYYY")}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    Status
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      background: "#f7f7f7",
                      borderRadius: "4px",
                      border: "1px solid transparent",
                      display: "inline-block",
                    }}
                  >
                    <Typography mx={"10px"} py={"10px"}>
                      {detail?.is_posted ? "Posted" : "Unposted"}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    Description
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      background: "#f7f7f7",
                      borderRadius: "4px",
                      border: "1px solid transparent",
                      display: "inline-block",
                    }}
                  >
                    <Typography mx={"10px"} py={"10px"}>
                      {detail?.description}
                    </Typography>
                  </Box>
                </Box>

                {/* --- Close --- */}
                <Button
                  variant="contained"
                  disableRipple
                  onClick={onClose}
                  fullWidth
                  sx={{ height: "62px" }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"20px"}
                    fontWeight={"700px"}
                    lineHeight={"27px"}
                    letterSpacing={"1px"}
                    textTransform={"none"}
                  >
                    {lang === "Id" ? "Keluar" : "Close"}
                  </Typography>
                </Button>
              </Box>
            ) : (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress color="secondary" />
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </Modals>
  );
};

export default BenefitReimbursementDetail;
