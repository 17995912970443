import { API } from "../../../API";

export const getListPosition = (params) =>
  API.get("positions", { params: params });

// --- Get Lov Position By Company ---
export const getPositiontByCompany = (companyID, department, search = "") =>
  API.get("positions", {
    params: { search, company_id: companyID, department_id: department },
  });

// --- Post Position ---
export const postPosition = (data) => API.post("positions", data);

// --- Get Position By Id ---
export const getPositionById = (id) => API.get(`positions/${id}`);

// --- Update Position By Id ---
export const updatePositionById = (data, id) =>
  API.put(`positions/${id}`, data);

// --- Delete Position By Id ---
export const deletePositionById = (id) => API.delete(`positions/${id}`);
