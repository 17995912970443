import { CardMedia } from "@mui/material"
import { getImageDocument } from "../../../../configs/axios/apiPath";
import DataLogin from "../../../../data/dataLogin";
import { useEffect, useState } from "react";

const CardMediaWithImage = ({id}) => {
  const getData = DataLogin();

  const [image, setImage] = useState('')

  useEffect(() => {
    const getImage = async() => {
      try {
        const response = await getImageDocument(id, getData.token);
        const contentType = response.headers['content-type'];
        const blob = new Blob([response.data], { type: contentType });
        const objectURL = URL.createObjectURL(blob);
        setImage(objectURL);

      } catch (error) {
        console.error(error.response.status);      
      }
    }

    getImage()
  },[id, getData.token])

  return(
    <CardMedia
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        right: 0,
        objectFit: 'cover',
      }}
      image={image}
    />
  )
}

export default CardMediaWithImage;