import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { LogoDanmogot } from '../../../../../assets/loginPage/img';
import DataLogin from '../../../../../data/dataLogin';
import { setCompany } from '../../../../../features/companies';

const SelectCompany = () => {
  const dispatch = useDispatch();
  const getData = DataLogin();
  const companies = getData.companies;
  const parsedData = JSON.parse(localStorage.getItem('cvSayaData'));
  const company = parsedData && parsedData.company;
  const navigate = useNavigate();

  const [selectedCompany, setSelectedCompany] = useState(
    company ? company : companies ? companies[0] : ''
  );

  const pathname = useLocation().pathname;
  const pathSegments = pathname.split('/');

  // --- Hande Change ---
  const handleChange = (event) => {
    const selected = event.target.value;
    let parsedData = JSON.parse(localStorage.getItem('cvSayaData'));

    if (!parsedData) {
      parsedData = { company: selected };
    } else {
      parsedData.company = selected;
    }

    localStorage.setItem('cvSayaData', JSON.stringify(parsedData));
    setSelectedCompany(selected);


    let newPath = pathname.split('/').slice(0, 3).join('/');
    navigate(newPath);
  };

  useEffect(() => {
    companyHandle(selectedCompany);
  });

  // --- Set company to redux ---
  const companyHandle = (companyName) => {
    dispatch(setCompany(companyName));
  };

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <FormControl>
        <Select
          value={selectedCompany}
          onChange={handleChange}
          sx={{
            height: '30px',
            '.MuiSelect-root': {
              border: '1px solid red',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          }}
        >
          {companies &&
            companies.map((item, idx) => (
              <MenuItem value={item} key={idx}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <img
                    src={LogoDanmogot}
                    alt="logo danmogot"
                    style={{ marginRight: '6px' }}
                  />
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontWeight={600}
                    fontSize={'16px'}
                    lineHeight={'22px'}
                    letterSpacing={'0.04em'}
                    color={'#171717'}
                  >
                    {item}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectCompany;
