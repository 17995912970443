import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  Stack,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconDate, IconEmployee } from '../../../../assets/coreHr/awards';
import { IconFile, IconPaper } from '../../../../assets/benefit/reimbursement';
import {
  IconItem,
  IconPrice,
} from '../../../../assets/benefit/officeFacillities';
import { Card, TextField, InputFile } from '../../../../components/atoms';
import {
  getListEmployee,
  postOfficeFacillities,
  uploadOfficeFacillities,
} from '../../../../configs/axios/apiPath';
import DataLogin from '../../../../data/dataLogin';
import { getCompany } from '../../../../features/companies';
import { getLanguage } from '../../../../features/language';
import {
  ValidateDescription,
  ValidateString,
} from '../../../../utils/validate';

const BenefitOfficeFacillitiesAdd = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [images, setImages] = useState('');
  const [fileBlob, setFileBlob] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    employee_id: null,
    price: '',
    date: '',
    description: '',
    document_ids: [],
  });
  const [total, setTotal] = useState(1);
  const [isSubmitted, setIsSubmit] = useState(false);
  const [isImageClick, setIsImageClick] = useState(false);

  const getData = DataLogin();
  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const lang = useSelector(getLanguage);
  const placeHolderId = 'Ketik disini';
  const placeHolderEn = 'Type here';

  const [isLoading, setIsLoading] = useState(false);
  const MAX_IMAGE_SIZE = 1 * 1024 * 1024;
  const [errorMsg, setErrorMsg] = useState('');

  // --- Handle Input Change ---
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // --- Handle Selected Employee ---
  const handleSelectEmployee = (event, value) => {
    setFormData({ ...formData, employee_id: value });
  };

  // --- Handle Submit ---
  const handleFileUpload = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const document_ids = [];

        for (const file of fileBlob) {
          const {
            data: { data },
          } = await uploadOfficeFacillities({ file });

          document_ids.push(data.id);
        }
        handlePost(document_ids);
        resolve();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
        reject(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  const handlePost = async (document_ids) => {
    const data = {
      ...formData,
      company_id: company,
      employee_id: formData.employee_id.id,
      document_ids,
    };
    setIsLoading(true);
    try {
      await postOfficeFacillities(data);
      navigate('/benefit/office-facilities');
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        localStorage.removeItem('cvSayaLogin');
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmit(true);
    try {
      setIsLoading(true);
      await handleFileUpload();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          page_size: total,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListEmployee(params);

          setEmployeeData(data.employee);
          if (data.page_info.total > 0) {
            setTotal(data.page_info.total);
          }
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, total]);

  return (
    <Card>
      <Box p={'20px'}>
        <Box>
          <Typography
            fontFamily={'sans-serif'}
            fontStyle={'normal'}
            fontWeight={600}
            fontSize={'20px'}
            lineHeight={'27px'}
            letterSpacing={'0.01em'}
            color={'#171717'}
            marginBottom="15px"
          >
            {lang === 'Id' ? 'Tambah' : 'Add'}
          </Typography>
          <Divider />
        </Box>
        <form
          onSubmit={handleSubmit}
          style={{
            marginTop: '20px',
            fontWeight: '600px',
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '0.01em',
            color: '#171717',
          }}
        >
          <Stack spacing={2}>
            {/* Item  */}
            <Grid container columns={2}>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <TextField
                  label={lang === 'Id' ? 'Nama' : 'Name'}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  placeholder={lang === 'Id' ? placeHolderId : placeHolderEn}
                  value={formData.name}
                  onChange={handleInputChange}
                  name="name"
                  icon={<IconItem />}
                />
              </Grid>
            </Grid>
            {/* --- Responsible Employee --- */}
            <Grid container columns={2}>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <Autocomplete
                  value={formData.employee_id}
                  onChange={handleSelectEmployee}
                  options={employeeData}
                  getOptionLabel={(employee) => employee.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        lang === 'Id'
                          ? 'Karyawan Yang Bertanggung Jawab'
                          : 'Responsible Employee'
                      }
                      required
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder={
                        lang === 'Id' ? placeHolderId : placeHolderEn
                      }
                      icon={
                        isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <IconEmployee />
                        )
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container columns={2}>
              {/* --- Price --- */}
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <TextField
                  label={lang === 'Id' ? 'Harga' : 'Price'}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  type="number"
                  placeholder={lang === 'Id' ? placeHolderId : placeHolderEn}
                  value={formData.price}
                  icon={<IconPrice />}
                  onChange={handleInputChange}
                  name="price"
                />
              </Grid>
            </Grid>
            {/* --- Receive Date --- */}
            <Grid container columns={2}>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <TextField
                  label={lang === 'Id' ? 'Tanggal Diterima' : 'Receive Date'}
                  fullWidth
                  required
                  type="date"
                  size="small"
                  variant="outlined"
                  value={formData.date}
                  onChange={handleInputChange}
                  name="date"
                  icon={<IconDate />}
                />
              </Grid>
            </Grid>

            <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
              <InputFile
                value={fileBlob}
                setValue={setFileBlob}
                multiple
                onChange={(data) => setFileBlob(data)}
                icon={<IconFile />}
                label={lang === 'Id' ? 'File' : 'File'}
                accept=".gif,.png,.pdf,.txt,.doc,.docx"
                allowedTypes={['gif', 'png', 'pdf', 'txt', 'doc', 'docx']}
              />
              {isImageClick && !ValidateString(images) && (
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={400}
                  fontSize={'12px'}
                  lineHeight={'15px'}
                  letterSpacing={'1px'}
                  color={'red'}
                  my={'5px'}
                >
                  {lang === 'Id' ? 'Harus diisi' : "Can't Be Empty"}
                </Typography>
              )}
              {isSubmitted && errorMsg && (
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={400}
                  fontSize={'12px'}
                  lineHeight={'15px'}
                  letterSpacing={'1px'}
                  color={'red'}
                  my={'5px'}
                >
                  {errorMsg}
                </Typography>
              )}
            </Box>

            {/* --- Description --- */}
            <Grid container columns={2}>
              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                <TextField
                  label={lang === 'Id' ? 'Deskripsi' : 'Description'}
                  fullWidth
                  multiline
                  rows={4}
                  required
                  variant="outlined"
                  size="small"
                  placeholder={lang === 'Id' ? placeHolderId : placeHolderEn}
                  value={formData.description}
                  onChange={handleInputChange}
                  name="description"
                  icon={<IconPaper />}
                  error={
                    isSubmitted && !ValidateDescription(formData.description)
                  }
                  errorMessage={
                    lang === 'Id'
                      ? 'Minimal 10 karakter'
                      : 'Minimals 10 characters'
                  }
                />
              </Grid>
            </Grid>
          </Stack>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => setIsImageClick(true)}
            sx={{ width: '136px', marginTop: '21px' }}
          >
            {lang === 'Id' ? 'Simpan' : 'Save'}
          </Button>
        </form>
      </Box>
    </Card>
  );
};

export default BenefitOfficeFacillitiesAdd;
