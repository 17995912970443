export const handleAction = (
  id,
  setIsModalOpen,
  setModal,
  setIdEmployee,
  title
) => {
  setIsModalOpen(true);
  setModal(title);
  setIdEmployee(id);
};
