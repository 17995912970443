import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPayrollHistoryDetail } from '../../../../configs/axios/apiPath';
import { Card, Modals } from '../../../../components/atoms';
import { IconQuit } from '../../../../assets/payroll';
import { formatNumber } from '../../../../utils/format';
import moment from 'moment';

export default function PayrollHistoryDetail({ isOpen, onClose, id }) {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [data, setData] = useState([]);
  const [position, setPosition] = useState('');
  const [join, setJoin] = useState('');
  const [salary, setSalary] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await getPayrollHistoryDetail(id);
        setName(data?.employee?.candidate?.name);
        setPosition(data?.employee?.position?.name);
        setJoin(data?.employee?.joined_at);
        setSalary(data?.payslip_details);
        setTotal(data?.take_home_pay);
        setData(data);
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [id, navigate]);

  const handleOtherMount = (item) => {
    let otherAmount = 0;
    item.forEach((payslips) => {
      const { name, amount } = payslips;
      if (name !== 'Gaji Pokok' && name !== 'Tunjangan Makan') {
        otherAmount += amount;
      }
    });

    return otherAmount;
  };

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Card
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Box margin={'31px 30px'}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            mb={'31px'}
          >
            <Typography
              fontSize={'20px'}
              fontWeight={'600px'}
              lineHeight={'27px'}
              color={'#171717'}
            >
              Employee Salary Details
            </Typography>
            <IconButton sx={{ p: '0px' }} onClick={onClose}>
              <IconQuit />
            </IconButton>
          </Box>
          {
            isLoading ? 
            <Box 
              display={'flex'} 
              alignItems={'center'}
              justifyContent={'center'} 
              my={'30px'}>
              <CircularProgress />
            </Box>:
            <Box sx={{ padding: '0px' }}>
            {/* --- Profile --- */}
            <Box display={'flex'} mb={'30px'}>
              <img
                src={data.employee?.candidate?.profile_picture}
                alt={data.employee?.candidate?.name}
                width={100}
                height={100}
                style={{ borderRadius: '50%' }}
              />
              <Box display={'flex'} flexDirection={'column'} ml={'20px'}>
                <Typography
                  fontSize={'30px'}
                  fontWeight={'600px'}
                  lineHeight={'41px'}
                  letterSpacing={'0.01em'}
                  color={'#171717'}
                >
                  {name}
                </Typography>
                <Typography
                  fontSize={'20px'}
                  fontWeight={'600px'}
                  lineHeight={'27px'}
                  letterSpacing={'0.01em'}
                  color={'#171717'}
                  mb={'10px'}
                >
                  {position}
                </Typography>
                <Typography
                  fontSize={'16px'}
                  fontWeight={'600px'}
                  lineHeight={'22px'}
                  letterSpacing={'0.01em'}
                  color={'#939393'}
                >
                  Joined since{' '}
                  {moment(join).format('DD MMMM, YYYY')}
                </Typography>
              </Box>
            </Box>

            {/* --- Basic Fee --- */}
            <Box mb={'16px'}>
              <Typography
                fontStyle={'normal'}
                fontFamily={'sans-serif'}
                fontSize={'13px'}
                lineHeight={'18px'}
                fontWeight={600}
                letterSpacing={'1px'}
                color={'#171717'}
                mb={'6px'}
              >
                Basic Fee
              </Typography>
              <Box
                p={'13px 15px'}
                borderRadius={'8px'}
                sx={{ background: '#f7f7f7' }}
              >
                {salary
                  .filter((payslips) => payslips.company_salary_type_id === 1)
                  .map((payslips, idx) => (
                    <Typography
                      fontStyle={'normal'}
                      fontFamily={'sans-serif'}
                      fontSize={'16px'}
                      lineHeight={'22px'}
                      fontWeight={600}
                      letterSpacing={'1px'}
                      color={'#171717'}
                      key={idx}
                    >
                      Rp {formatNumber(payslips.amount)}
                    </Typography>
                  ))}
              </Box>
            </Box>

            {/* -- Meal Allowance --- */}
            <Box mb={'16px'}>
              <Typography
                fontStyle={'normal'}
                fontFamily={'sans-serif'}
                fontSize={'13px'}
                lineHeight={'18px'}
                fontWeight={600}
                letterSpacing={'1px'}
                color={'#171717'}
                mb={'6px'}
              >
                Meal Allowance
              </Typography>
              <Box
                p={'13px 15px'}
                borderRadius={'8px'}
                sx={{ background: '#f7f7f7' }}
              >
                {salary
                  .filter((payslips) => payslips.company_salary_type_id === 2)
                  .map((payslips, idx) => (
                    <Typography
                      fontStyle={'normal'}
                      fontFamily={'sans-serif'}
                      fontSize={'16px'}
                      lineHeight={'22px'}
                      fontWeight={600}
                      letterSpacing={'1px'}
                      color={'#171717'}
                      key={idx}
                    >
                      Rp {formatNumber(payslips.amount)}
                    </Typography>
                  ))}
              </Box>
            </Box>

            {/* -- Others --- */}
            <Box mb={'16px'}>
              <Typography
                fontStyle={'normal'}
                fontFamily={'sans-serif'}
                fontSize={'13px'}
                lineHeight={'18px'}
                fontWeight={600}
                letterSpacing={'1px'}
                color={'#171717'}
                mb={'6px'}
              >
                Others
              </Typography>
              <Box
                p={'13px 15px'}
                borderRadius={'8px'}
                sx={{ background: '#f7f7f7' }}
              >
                {salary.length > 0 ? (
                  <Typography
                    fontStyle={'normal'}
                    fontFamily={'sans-serif'}
                    fontSize={'16px'}
                    lineHeight={'22px'}
                    fontWeight={600}
                    letterSpacing={'1px'}
                    color={'#171717'}
                  >
                    Rp {formatNumber(handleOtherMount(salary))}
                  </Typography>
                ) : (
                  <Typography
                    fontStyle={'normal'}
                    fontFamily={'sans-serif'}
                    fontSize={'16px'}
                    lineHeight={'22px'}
                    fontWeight={600}
                    letterSpacing={'1px'}
                    color={'#171717'}
                  >
                    -
                  </Typography>
                )}
              </Box>
            </Box>

            {/* --- Total --- */}
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'start'}
              color={'#51B15C'}
              mt={'20px'}
              mb={'20px'}
            >
              <Typography
                fontSize={'16px'}
                fontWeight={'600px'}
                lineHeight={'22px'}
                letterSpacing={'1px'}
              >
                Total Employee Salary
              </Typography>
              <Typography
                fontSize={'24px'}
                fontWeight={'600px'}
                lineHeight={'33px'}
                letterSpacing={'1px'}
              >
                Rp {formatNumber(total)}
              </Typography>
            </Box>

            <Button
              variant="contained"
              sx={{ width: '100%', height: '50px', color: '#fff' }}
              color="success"
              onClick={onClose}
            >
              <Typography
                fontSize={'18px'}
                fontWeight={'700px'}
                lineHeight={'20px'}
                letterSpacing={'1px'}
                p={'17px 0px'}
                textTransform={'none'}
              >
                Close
              </Typography>
            </Button>
          </Box>
          }
          
        </Box>
      </Card>
    </Modals>
  );
}
