import "./style.css";
import { useEffect, useState } from "react";
import ImageTooltips from "./images";
import SimpleTooltips from "./simple";
import { contentInjector } from "../../../../utils/others/objectMapper";

export default function TooltipContentGraph({ layouts = [], templates = {} }) {
  const { type, maxWidth = null, content = {} } = templates || {};
  const [mapperObjects, setObject] = useState([]);

  useEffect(() => {
    const mapperObject = contentInjector(content, layouts);
    setObject(mapperObject);
  }, [layouts]);

  return (
    <div className="graph-tooltip-container" style={{ maxWidth: maxWidth }}>
      {type === "simple" && <SimpleTooltips sources={mapperObjects} />}
      {type === "image" && <ImageTooltips sources={mapperObjects} />}
    </div>
  );
}
