import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import PayrollListEdit from '../../../../../../pages/payroll/payrollList/payrollListEdit';
import {
  capitalizeFirstLetter,
  formatNumber,
} from '../../../../../../utils/format';
import {
  IconAction,
  IconArrowDetail,
  IconNotif,
} from '../../../../../../assets/payroll';
import {
  patchGenerate,
  patchGeneratePaid,
  patchGeneratePay,
} from '../../../../../../configs/axios/apiPath';
import { useDispatch } from 'react-redux';
import { setPayrollId } from '../../../../../../features/idPayroll';
import { useEffect } from 'react';

const EmployeePayslipTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(null);

  const [anchorEl, setAnchorEl] = useState({});
  const path = useLocation().pathname;

  const handleDetail = (idEmployee, id) => {
    let dataToStore = {};

    // Periksa apakah data sudah ada di localStorage
    if (localStorage.getItem('cvSayaData')) {
      dataToStore = JSON.parse(localStorage.getItem('cvSayaData'));

      // Tambahkan idPayroll ke objek yang sudah ada
      dataToStore.idPayslip = id;
      dataToStore.idEmployee = idEmployee;
    } else {
      // Buat objek baru dengan idPayroll jika data belum ada di localStorage
      dataToStore = {
        idPayslip: id,
        idEmployee: idEmployee,
      };
    }

    localStorage.setItem('cvSayaData', JSON.stringify(dataToStore));
    navigate(`${path}/detail-employee/${idEmployee}`);
  };

  // useEffect(() => {
  //   if (value !== null) {
  //     const parsedData = JSON.parse(localStorage.getItem('cvSayaData'));
  //     const newData = { ...parsedData, idEmployee: value };
  //     localStorage.setItem('cvSayaData', JSON.stringify(newData));
  //   }
  // }, [value]);

  const handleMenuOpen = (event, idx) => {
    setAnchorEl((prevAnchorEl) => ({
      ...prevAnchorEl,
      [idx]: event.currentTarget,
    }));
  };

  const handleMenuClose = (idx) => {
    setAnchorEl((prevAnchorEl) => ({
      ...prevAnchorEl,
      [idx]: null,
    }));
  };

  const handleMenuGenerate = async (id) => {
    const params = {
      generated_by: 5024,
    };
    setIsLoading(true);
    if (id) {
      try {
        const response = await patchGenerate(params, id);
        if (response.data.meta.code === 20000) {
          window.location.reload();
        }
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem('cvDataLogin');
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleGeneratePay = async (id) => {
    const params = {
      paid_by: 5024,
    };
    setIsLoading(true);
    if (id) {
      try {
        const response = await patchGeneratePay(params, id);
        if (response.data.meta.code === 20000) {
          window.location.reload();
        }
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem('cvDataLogin');
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleGeneratePaid = async (id) => {
    const params = {
      employee_id: 5024,
    };
    setIsLoading(true);
    if (id) {
      try {
        const response = await patchGeneratePaid(params, id);
        if (response.data.meta.code === 20000) {
          window.location.reload();
        }
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem('data');
          navigate(`/`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Employee Name --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
                cursor: 'pointer',
              }}
              onClick={() => handleDetail(item?.employee?.id, item?.id)}
            >
              <Box display={'flex'} alignItems={'center'}>
                <img
                  src={item?.employee?.profile_picture}
                  alt={item?.employee?.name}
                  width={30}
                  height={30}
                  style={{
                    borderRadius: '50%',
                  }}
                />
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={400}
                  fontSize={'14px'}
                  lineHeight={'130%'}
                  color={'#171717'}
                  mx={'7px'}
                >
                  {item?.employee?.name}
                </Typography>
                <IconArrowDetail />
              </Box>
            </td>
            {/* --- Position --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.employee?.position?.name}
              </Typography>
            </td>
            {/* --- Basic Allowance --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {formatNumber(item?.basic_allowance)}
              </Typography>
            </td>
            {/* --- Meal Allowance --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {formatNumber(item?.meal_allowance)}
              </Typography>
            </td>
            {/* --- Overtime Fee --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {formatNumber(item?.overtime_fee)}
              </Typography>
            </td>
            {/* --- Late --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {formatNumber(item?.late)}
              </Typography>
            </td>
            {/* --- Others --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {formatNumber(item?.others)}
              </Typography>
            </td>
            {/* --- THP --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {formatNumber(item?.thp)}
              </Typography>
              <Box sx={{ position: 'absolute', top: '0px' }}>
                <IconNotif />
              </Box>
              {/* --- Edit --- */}
              {/* <TooltipsAction
                title="Edit"
                open={isTooltipDetail && item.id === idTooltipDetail}
                arrow
                placement={'bottom-end'}
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleEdit(item.id)}
                  onMouseEnter={() =>
                    handleTooltipOpen(
                      item.id,
                      setIsTooltipDetail,
                      setIdTooltipDetail
                    )
                  }
                  onMouseLeave={() => handleTooltipClose(setIsTooltipDetail)}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontWeight={400}
                    fontSize={'14px'}
                    lineHeight={'130%'}
                    color={'#171717'}
                  >
                    {formatNumber(item?.thp)}
                  </Typography>
                </Box>
              </TooltipsAction> */}
            </td>
            {/* --- Status --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Box
                display={'center'}
                alignItems={'center'}
                justifyContent={'center'}
                py={'6px'}
                borderRadius={'6px'}
                sx={{
                  border:
                    item?.status === 'unpaid'
                      ? '0.4px solid #CF8812'
                      : item?.status === 'paid'
                      ? '0.4px solid #51B15C'
                      : '0.4px solid #FF1D1D',
                  background:
                    item?.status === 'unpaid'
                      ? 'rgba(207, 136, 18, 0.2)'
                      : item?.status === 'paid'
                      ? 'rgba(81, 177, 92, 0.2)'
                      : 'rgba(255, 29, 29, 0.2)',
                }}
              >
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={400}
                  fontSize={'14px'}
                  lineHeight={'130%'}
                  color={'#171717'}
                  sx={{
                    color:
                      item?.status === 'unpaid'
                        ? '#CF8812'
                        : item?.status === 'paid'
                        ? '#51B15C'
                        : '#FF1D1D',
                  }}
                >
                  {capitalizeFirstLetter(item?.status)}
                </Typography>
              </Box>
            </td>
            {/* --- Action --- */}
            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
              <Box display={'flex'} justifyContent={'center'}>
                <IconButton
                  sx={{ p: '0px' }}
                  onClick={(event) => handleMenuOpen(event, idx)}
                >
                  <IconAction />
                </IconButton>
                <Menu
                  anchorEl={anchorEl[idx]}
                  open={Boolean(anchorEl[idx])}
                  onClose={() => handleMenuClose(idx)}
                >
                  <MenuItem
                    disabled={
                      item.status === 'paid' || item.status === 'unpaid'
                    }
                    onClick={() => handleMenuGenerate(item.id)}
                  >
                    Generate
                  </MenuItem>
                  <MenuItem
                    disabled={
                      item.status === 'paid' || item.status === 'unpaid'
                    }
                    onClick={() => handleGeneratePaid(item.id)}
                  >
                    Generate and Pay
                  </MenuItem>
                  <MenuItem
                    disabled={item.status === 'paid'}
                    onClick={() => handleGeneratePay(item.id)}
                  >
                    Pay
                  </MenuItem>
                </Menu>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

export default EmployeePayslipTbody;
