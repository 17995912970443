import { Box, Typography } from "@mui/material"
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

const CardUploadDocumentOfTask = () => {
  return(
    <Box
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{
        borderStyle: 'dashed',
        borderColor: '#C4C4C4',
        borderRadius: '10px',
        width: 'full',
        height: '60px',
        
      }}
    >
      <FileUploadOutlinedIcon
        sx={{
          color: '#C4C4C4',
          width: '20px',
          height: '20px',
        }}
      />
      <Typography
        fontFamily={'sans-serif'}
        fontStyle={'normal'}
        fontWeight={400}
        fontSize={'14px'}
        textAlign={'center'}
        color={'#C4C4C4'}
        marginX={'20px'}
      >
        Drag your document here or click to upload
      </Typography>
    </Box>
  )
}

export default CardUploadDocumentOfTask