import { Box, IconButton, Typography } from "@mui/material";
import { Theme } from "../../../../../configs/theme";
import React from "react";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../../features/language";

const TableEmployeeManageThead = ({ thead, setSortir, sortir }) => {
  const lang = useSelector(getLanguage);

  const color = {
    active: Theme.palette.primary.main,
    unactive: "#C4C4C4",
  };

  // const handleOrder = (name, direction) => {
  //   setOrder(name);
  //   if (direction === 'asc') {
  //     setDirection('desc');
  //   } else if (direction === 'desc') {
  //     setDirection('asc');
  //   }
  // };
  return (
    <thead
      style={{ color: "#51B15C", backgroundColor: "rgba(81, 177, 92, 0.06)" }}
    >
      <tr>
        {thead.map((item, idx) => (
          <th
            key={idx}
            style={{
              textAlign: "start",
              verticalAlign: "middle",
              padding: "10px",
            }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              p={"0px 0px"}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"12px"}
                lineHeight={"130%"}
                color={"#51b15c"}
                width={
                  item.title !== "No" && item.title !== "Action"
                    ? "100px"
                    : "auto"
                }
              >
                {lang === "Id" ? item.judul : item.title}
              </Typography>
              {/* {item.title === 'No' && item.title === 'Action' ? (
                ''
              ) : (
                <IconButton
                  sx={{ p: '0px', cursor: 'pointer' }}
                  onClick={() => handleOrder(item.url, direction)}
                >
                  {React.cloneElement(<IconFilterUp />, {
                    style: {
                      fill: direction === 'asc' ? color.active : color.unactive,
                    },
                  })}
                  {React.cloneElement(<IconFilterDown />, {
                    style: {
                      fill:
                        direction === 'desc' ? color.active : color.unactive,
                    },
                  })}
                </IconButton>
              )} */}
            </Box>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableEmployeeManageThead;
