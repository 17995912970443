import React from 'react';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCompany } from '../../../../../features/companies';
import { getEmployeesAttendances } from '../../../../../configs/axios/apiPath';
import TableComponent from '../../../../../components/molecules/table/mainTable';
import { dataTableEmployeeAttendances } from '../../../../../data/table/employee/employeeAttendances';
import { dataEmployeeAttendanceFooter } from '../../../../../data/footer';
import { getLanguage } from '../../../../../features/language';
import {
  DatePicker,
  Pagination,
  RowPerPage,
} from '../../../../../components/atoms';
import {
  EmployeeAttendancesTbody,
  EmployeeAttendancesThead,
} from '../../../../../components/molecules/table/tableEmployee';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const EmployeeAttendance = () => {
  const company = useSelector(getCompany);
  const [isLoading, setIsLoading] = useState(false);
  const [dataAttendances, setDataAttendances] = useState([]);
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);

  const path = useLocation();
  const pathname = path.pathname;
  const paths = pathname.split('/');
  const id = paths[4];

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const endtDate = dateRange[1];

  const [lastPage, setLastPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          start_date: dayjs(dateRange[0]).format('DD-MM-YYYY'),
          end_date: dayjs(dateRange[1]).format('DD-MM-YYYY'),
          page_size: pageSize,
          page: page,
        };
        setIsLoading(true);

        try {
          const {
            data: { data },
          } = await getEmployeesAttendances(id, params);
          setDataAttendances(data?.attendances);
          setLastPage(data?.page_info?.last_page);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [page, pageSize, dateRange, navigate]);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box display={'flex'} justifyContent={'end'}>
        <Box display={'flex'} alignItems={'center'}>
          {/* --- Selected Year --- */}
          {/* <CustomDay /> */}

          <Box sx={{ mt: 2 }}>
            <DatePicker
              onChange={(dateRange) => setDateRange(dateRange)}
              value={dateRange}
            />
          </Box>

          {/* --- Add --- */}
          {/* <Button
              disabled={!isLoading}
              type="submit"
              onClick={() => handleNavigationClick('add')}
              sx={{
                borderRadius: '4px',
                marginLeft: '10px',
              }}
            >
              <Box display={'flex'} alignItems={'center'}>
                <IconPlus />
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={600}
                  fontSize={'12px'}
                  lineHeight={'14px'}
                  color="primary.contrastText"
                  letterSpacing={'0.02em'}
                  marginLeft={'10px'}
                  textTransform={'none'}
                >
                  Add
                </Typography>
              </Box>
            </Button> */}
        </Box>
      </Box>

      {/* --- Table --- */}
      <Box sx={{ mt: '24px', mb: '16px' }}>
        <TableComponent
          isLoading={isLoading}
          tbody={dataAttendances}
          TableHead={
            <EmployeeAttendancesThead thead={dataTableEmployeeAttendances} />
          }
          TableBody={<EmployeeAttendancesTbody tbody={dataAttendances} />}
          col={7}
        />
      </Box>

      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {/* --- Footer --- */}
        <Box display={'flex'} alignItems={'center'}>
          {dataEmployeeAttendanceFooter.map((item, idx) => (
            <Box display={'flex'} alignItems={'center'} key={idx} mr={'10px'}>
              <Box
                mr={'6px'}
                width={'6px'}
                height={'6px'}
                borderRadius={'50%'}
                sx={{
                  background:
                    item.title === 'Present'
                      ? '#51B15C'
                      : item.title === 'Not Present'
                      ? '#FF1D1D'
                      : item.title === 'Permission'
                      ? '#125ACF'
                      : item.title === 'Holiday'
                      ? '#B712CF'
                      : '#CF8812',
                }}
              ></Box>
              <Typography>{lang === 'Id' ? item.judul : item.title}</Typography>
            </Box>
          ))}
        </Box>
        <Box display={'flex'} justifyContent={'end'}>
          {/* --- Row Per Page --- */}
          <RowPerPage
            setPageSize={setPageSize}
            pageSize={pageSize}
            setPage={setPage}
          />
          <Pagination page={page} setPage={setPage} lastPage={lastPage} />
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeeAttendance;
