import { Box, Divider } from '@mui/material';
import Notification from './notification';
import SelectCompany from './selectCompany';
import SelectLanguage from './selectLanguage';
import Setting from './setting';
import EditTable from './editTable';

const UserSelect = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        mt: {
          xl: '0px',
          lg: '0px',
          md: '0px',
          sm: '20px',
          xs: '20px',
        },
        flexDirection: {
          xl: 'row',
          lg: 'row',
          md: 'row',
          sm: 'row',
          xs: 'column-reverse',
        },
        alignItems: {
          xl: 'center',
          lg: 'center',
          md: 'center',
          sm: 'start',
          xs: 'end',
        },
        justifyContent: {
          xl: 'space-between',
          lg: 'space-between',
          md: 'space-between',
          sm: 'space-between',
          xs: 'space-between',
        },
      }}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          mr: {
            xl: '15px',
            lg: '15px',
            md: '15px',
            sm: '0px',
            xs: '0px',
          },
        }}
      >
        {/* --- Select Company --- */}
        <SelectCompany />
        {/* --- Selected Language --- */}
        <SelectLanguage />

        {/* --- Notification --- */}
        <Notification />
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{
          mb: {
            xl: '0px',
            lg: '0px',
            md: '0px',
            sm: '0px',
            xs: '20px',
          },
        }}
      >
        {/* edit table */}
        <EditTable />

        <Divider orientation="vertical" flexItem sx={{ mx: '30px' }} />
        {/* --- Setting --- */}
        <Setting />
      </Box>
    </Box>
  );
};

export default UserSelect;
