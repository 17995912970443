import { Card, CardContent, Grid, Typography, Stack, Button} from "@mui/material"
import { Card as CardAtoms, Search} from "../../../atoms"
import { Box } from "@mui/system"
import DoneIcon from '@mui/icons-material/Done'
import CircleProgressWithLabel from "../../../molecules/progress/circleProgress"
import { IconPlus } from "../../../../assets/commons"
import { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import AvatarGroupStyled from "../../../molecules/avatar/avatarGroup"
import { useSelector } from "react-redux"
import { getCompany } from "../../../../features/companies"
import moment from "moment"
import { getListProject } from "../../../../configs/axios/apiPath/fetchProject/fetchDashboard"
import CardMediaWithImage from "../../../molecules/card/project/CardMediaWithImage"

const  DisplayCardProject = () => {
  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const yearNow = moment().format("YYYY");

  const [isLoading, setIsLoading] = useState(false);
  const [dataProject, setDataProject] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [keyword, setKeyword] = useState("");

  const handleNavigationClick = (url) => {
    navigate('/manager-project/add');
  };

  const handleDetail = (currentCardData) => {
    const value = {
      isProvide: true,
      itemProject: {
        id: currentCardData.id,
        title: currentCardData.name,
        detail: currentCardData.detail,
        percentage: currentCardData.percentage,
        status: currentCardData.status,
        member: currentCardData.assignee
      }
    }

    localStorage.setItem('cvSayaProject', JSON.stringify(value))
  }

  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          company_id : company,
          page: page,
          page_size: pageSize,
          year: yearNow,
          search: keyword,
        };
        setIsLoading(true)
        try {
          const {
            data: { data },
          } = await getListProject(params);
          setDataProject(data?.projects);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false)
        }
      }
    }
    fetchData()

  }, [company, page, pageSize, yearNow, keyword, navigate]);

  return(
    <CardAtoms sx={{ marginTop: '20px' }}>
      <Box display={'flex'} flexDirection={'column'} p={'23px 22px'}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Box display={'flex'}>
            <Typography
              sx={{
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '20px',
                marginRight: '20px'
              }}
            >
              Your Projects
            </Typography>
            <Search
              placeholder="Type here to search"
              page={page}
              setPage={(e) => setPage(e)}
              setSearch={(e) => setKeyword(e)}
            />  
          </Box>
          <Button
            disabled={isLoading}
            type="submit"
            variant="contained"
            onClick={handleNavigationClick}
            sx={{
              borderRadius: '4px',
              marginLeft: '10px',
            }}
          >
            <Box display={'flex'} alignItems={'center'}>
              <IconPlus />
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={600}
                fontSize={'12px'}
                lineHeight={'14px'}
                color="primary.contrastText"
                letterSpacing={'0.02em'}
                marginLeft={'10px'}
                textTransform={'none'}
              >
              Add
              </Typography>
            </Box>
          </Button>
        </Box>
        <Typography
          sx={{
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '10px',
            marginTop: '6px'
          }}
        >
          *Click the project to be displayed 
        </Typography>
        <Grid container columns={3} spacing={3} marginTop={2}>
          {
            dataProject.map((item, idx) => (
            <Grid key={idx} item xl={1} lg={1} md={2} sm={3} xs={3}>
              <Link
                to="/manager-project/detail"
                onClick={() => handleDetail(item)}
              >
                <Card 
                  sx={{
                    position: 'relative',
                  }}
                >
                  <CardMediaWithImage
                    id={item.image_id}
                  />
                  <CardContent sx={{ position: 'relative' }}>
                    <Stack direction='row' justifyContent='space-between' alignItems="center">
                      <CircleProgressWithLabel value={item.percentage} status={item.status}/>
                      <DoneIcon 
                        sx={{
                          backgroundColor: '#51B15C', 
                          color: 'white', 
                          width: '24px', 
                          borderRadius: '50%', 
                          padding: '5px' 
                        }} 
                      />
                    </Stack>
                    <Typography 
                      sx={{
                        fontStyle: 'normal',
                        fontWeight: '800',
                        fontSize: '20px',
                        color: 'white',
                        lineHeight: '27px',
                        marginTop: '60px',
                        marginBottom: '5px'
                      }}
                    >
                      {item.name}
                    </Typography>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <AvatarGroupStyled
                        width={24}
                        height={24}
                        maxShow={4}
                        avatarData={item.assignee}
                      />
                      <Typography 
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: '700',
                          fontSize: '14px',
                          color: 'white',
                        }}
                      >
                      {'Other members'}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            ))
          }
        </Grid>
      </Box>
    </CardAtoms>
  )
}

export default DisplayCardProject