import { Box, InputAdornment, MenuItem, OutlinedInput, Select } from "@mui/material"
import { IconCalendar } from "../../../assets/commons"

const SelectYear = ({selectedYear, setSelectedYear ,year}) => {
    const handleChange = (event) => {
        setSelectedYear(event.target.value);
      };

    return (
        <Select
            value={selectedYear}
            onChange={handleChange}
            input={
                <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">
                        <IconCalendar />
                      </InputAdornment>
                    }
                  />
                }
                sx={{
                  background: '#f9f9f9',
                  height: '30px',
                  '.MuiSelect-root': {
                    border: '1px solid red',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                }}
              >
                {year &&
                  year.map((item, idx) => (
                    <MenuItem value={item} key={idx}>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        {item}
                      </Box>
                    </MenuItem>
                  ))}
              </Select>
    )
}

export default SelectYear