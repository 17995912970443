import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Pagination,
  RowPerPage,
  Search,
} from '../../../../components/atoms';
import { getCompany } from '../../../../features/companies';
import {
  getListPayrollPeriods,
  getPayrollHistory,
} from '../../../../configs/axios/apiPath';
import {
  PayrollHistoryTbody,
  PayrollHistoryThead,
} from '../../../../components/molecules/table/tablePayroll/tablePayrollHistory';
import { dataTablePayrollHistory } from '../../../../data/table/payroll';
import TableComponent from '../../../../components/molecules/table/mainTable';
import { dataPayrollHistoryFooter } from '../../../../data/footer';
import { getLanguage } from '../../../../features/language';

export default function PayrollHistory({ setMenu }) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [dataHistory, setDataHistory] = useState([]);
  const [total, setTotal] = useState(100);
  const [lastPage, setLastPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const lang = useSelector(getLanguage);

  const [dataPayroll, setDataPayroll] = useState([]);
  const parsedData = JSON.parse(localStorage.getItem('cvSayaData'));
  const idPayroll = parsedData && parsedData.idPayroll;
  const [selectPayroll, setSelectPayroll] = useState(
    idPayroll ? idPayroll : ''
  );

  // --- data table ---
  useEffect(() => {
    async function fetchData() {
      const params = {
        keywords: keyword,
        page: page,
        page_size: rowsPerPage,
      };
      setIsLoading(true);
      if (selectPayroll !== '') {
        try {
          const {
            data: { data },
          } = await getPayrollHistory(selectPayroll, params);
          setDataHistory(data?.page_data);
          setLastPage(data.page_info?.last_page);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [keyword, page, rowsPerPage, selectPayroll, navigate]);

  // --- data select ---
  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          company_id: company,
          page_size: total,
          page: page,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListPayrollPeriods(params);
          setDataPayroll(data?.payroll);
          setTotal(data?.page_info?.total);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [company, page, total, navigate]);

  const handleChange = (event) => {
    setSelectPayroll(event.target.value);
    let parsedData = JSON.parse(localStorage.getItem('cvSayaData'));

    if (!parsedData) {
      parsedData = { idPayroll: event.target.value };
    } else {
      parsedData.idPayroll = event.target.value;
    }

    localStorage.setItem('cvSayaData', JSON.stringify(parsedData));
  };

  return (
    <Card>
      <Box display={'flex'} flexDirection={'column'} p={'23px 22px'}>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Search
            placeholder="Type here to search"
            setSearch={(e) => setKeyword(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />
          <Box display={'flex'} justifyContent={'center'}>
            <FormControl>
              <Select
                value={selectPayroll}
                onChange={handleChange}
                sx={{
                  height: '30px',
                  '.MuiSelect-root': {},
                }}
              >
                {dataPayroll &&
                  dataPayroll.map((item, idx) => (
                    <MenuItem value={item.id} key={idx}>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        {item.name}
                      </Box>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ mt: '24px', mb: '16px' }}>
          <TableComponent
            isLoading={isLoading}
            tbody={dataHistory}
            TableHead={<PayrollHistoryThead thead={dataTablePayrollHistory} />}
            TableBody={<PayrollHistoryTbody tbody={dataHistory} />}
            col={5}
          />
        </Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box display={'flex'} justifyContent={'start'} alignItems={'center'}>
            {dataPayrollHistoryFooter.map((item, idx) => (
              <Box mr={'10px'} key={idx} display={'flex'} alignItems={'center'}>
                <Box
                  width={'6px'}
                  height={'6px'}
                  borderRadius={'50%'}
                  sx={{ background: item.color }}
                  mr={'6px'}
                ></Box>
                <Typography
                  fontStyle={'normal'}
                  fontFamily={'sans-serif'}
                  fontSize={'12px'}
                  fontWeight={600}
                  lineHeight={'14px'}
                  letterSpacing={'0.02em'}
                  color={'#171717'}
                >
                  {lang === 'Id' ? item.judul : item.title}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <RowPerPage
              setPageSize={setRowsPerPage}
              pageSize={rowsPerPage}
              setPage={setPage}
            />
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
