import { Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { IconDownloadFile } from '../../../../../assets/dashboard/icons';
import { dataGridApplicants } from '../../../../../data/card/dashboardProject';
import { Card } from '../../../../atoms';
import { CardApplicants } from '../../../../molecules/card';

export default function GridApplicant() {
  return (
    <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
      <Card>
        <Box
          sx={{
            backgroundColor: '#ffffff',
            padding: '12px 20px',
            borderRadius: '10px',
          }}
        >
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography
              fontWeight={'600px'}
              fontSize={'20px'}
              lineHeight={'27px'}
              letterSpacing={'0.01rem'}
              color={'#171717'}
            >
              Applicants
            </Typography>
            <Typography
              fontWeight={'700px'}
              fontSize={'12px'}
              lineHeight={'14px'}
              letterSpacing={'0.04rem'}
              color={'#51B15C'}
            >
              See All
            </Typography>
          </Box>
        </Box>
        <Divider
          orientation="horizontal"
          flexItem
          sx={{ borderColor: '#c4c4c4', mt: '10px' }}
        />

        <Box p={'20px'}>
          {dataGridApplicants.map((item, idx) => (
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4} mt={'12px'} key={idx}>
              <CardApplicants title={item.title} icon={<IconDownloadFile />}>
                <Typography
                  fontSize={'18px'}
                  fontWeight={'600px'}
                  lineHeight={'25px'}
                  letterSpacing={'0.04rem'}
                  mt={'2px'}
                >
                  {item.name}
                </Typography>
                <Typography
                  fontSize={'12px'}
                  fontWeight={'600px'}
                  lineHeight={'14px'}
                  letterSpacing={'0.04rem'}
                  color={'#939393'}
                  mt={'3px'}
                >
                  {item.application}
                </Typography>
              </CardApplicants>
            </Grid>
          ))}
        </Box>
      </Card>
    </Grid>
  );
}
