import { Box, Divider, MenuItem, Typography } from '@mui/material';
import { NotFoundAsset } from '../../../../assets/project/img';

const CardListFileOfDiscussion = ({ title, data }) => {
  return (
    <>
      {data ? (
        <>
          <Box height={'35px'}>
            <Typography
              sx={{
                textAlign: 'left',
                fontWeight: '600',
                fontSize: '14px',
              }}
            >
              {title}
            </Typography>
          </Box>
          <Divider />
          {data.map((item, idx) => (
            <MenuItem key={idx}>
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={2}
                width={'full'}
                paddingY={'10px'}
              >
                {item.asset}
                <Box>
                  <Typography
                    sx={{
                      fontWeight: '400',
                      fontSize: '16px',
                      color: '#171717',
                    }}
                  >
                    {item.original_file_name}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: '400',
                      fontSize: '12px',
                      color: '#939393',
                    }}
                  >
                    {item.size}
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
          ))}
        </>
      ) : (
        <Box
          height={'400px'}
          width={'full'}
          display="flex"
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems="center"
          gap={3}
        >
          <img
            alt="not-found"
            src={NotFoundAsset}
            width={'180px'}
            height={'165px'}
          />
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '18px',
              color: '#939393',
            }}
          >
            No Document to Display
          </Typography>
        </Box>
      )}
    </>
  );
};

export default CardListFileOfDiscussion;
