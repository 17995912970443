import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  IconEmail,
  IconHide,
  IconPassword,
  IconShow,
} from '../../../../assets/loginPage/icons';
import { EncryptedAES } from '../../../../utils/crypto';
import { Buttons } from '../../../atoms';

const URL = process.env.REACT_APP_API_BASE_URL;
// const KEY = process.env.REACT_APP_SECRET_KEY;
const KEY = 'cvSayaV2';

const ForgetPasswordContentFormLogin = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isFocusEmail, setIsFocusEmail] = useState(false);

  // --- Handle Submit ---
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email: email,
    };
    setIsLoading(true);
    try {
      const response = await axios.post(`${URL}login/admin`, data, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ mt: '30px' }}>
      {/* --- Input Email --- */}
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          width: {
            xl: '489px',
            lg: '489px',
            md: '489px',
            sm: '489px',
            xs: '306px',
          },
        }}
        mb={'30px'}
      >
        <InputLabel
          style={{
            fontFamily: 'sans-serif',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.02em',
            color: '#171717',
            marginBottom: '10px',
          }}
        >
          Email
        </InputLabel>
        <TextField
          variant="outlined"
          type={'email'}
          size={'medium'}
          fullWidth
          required
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          onFocus={() => setIsFocusEmail(true)}
          onBlur={() => setIsFocusEmail(false)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {!isFocusEmail && email === '' ? <IconEmail /> : ''}
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              background: '#f9f9f9',
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
              },
            },
          }}
        />
      </Box>

      {/* --- Login Button --- */}
      <Buttons
        isLoading={isLoading}
        color="primary"
        variant="contained"
        type="submit"
        sx={{
          borderRadius: '20px',
          width: '100%',
        }}
      >
        <Typography
          fontFamily={'sans-serif'}
          fontStyle={'normal'}
          fontWeight={700}
          fontSize={'12px'}
          lineHeight={'25px'}
          color={'#fff'}
          textTransform={'none'}
        >
          Pulihkan Kata Sandi
        </Typography>
      </Buttons>
    </form>
  );
};

export default ForgetPasswordContentFormLogin;
