import React from "react";
import {
  CartesianGrid,
  YAxis,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  Legend,
  LineChart,
  Line,
} from "recharts";
import mapperDataset from "../mapper";
import TooltipContentGraph from "../tooltips";

function CustomTooltipContent({ templates, layouts = [] }) {
  const _layouts = Array.isArray(layouts) ? layouts : [];
  return _layouts.length > 0 ? (
    <TooltipContentGraph templates={templates} layouts={_layouts} />
  ) : null;
}

export default function SimpleLineChart({
  type,
  yAxisOn,
  xAxisOn,
  graphOn,
  tooltipContent,
  labels,
  graphs,
  options,
  dataSources,
}) {
  const [datasets, setDataset] = React.useState([]);
  const [lineOptions, setLineOptions] = React.useState({});
  const [currentContent, setCurrentContent] = React.useState([]);

  React.useEffect(() => {
    try {
      const mappingObject = mapperDataset({
        type,
        yAxisOn,
        xAxisOn,
        graphOn,
        labels,
        graphs,
        options,
        dataSources,
      });
      setDataset(mappingObject.datasets);
      setLineOptions(mappingObject.graphs);
    } catch (er) {
      console.error(`[ERROR] Failed to mapping datase, cause: ${er.message}`);
    }
  }, [dataSources]);

  const mappingCurrentPayload = (dataKey, rawPayload = []) => {
    const _raw = Array.isArray(rawPayload) ? rawPayload : [];
    const keyCode = lineOptions[dataKey]?.key;
    setCurrentContent(_raw.filter((a) => a[graphOn] === keyCode));
  };

  const generateLineChart = () => {
    let listOptions = [];
    for (let x in lineOptions) {
      const items = lineOptions[x];

      listOptions.push(
        <Line
          connectNulls
          type="monotone"
          stroke={items.color}
          dataKey={items.label}
          strokeWidth={items.width || 1}
          dot={{
            style: { cursor: "pointer" },
            r: items.dotSize || 5,
            onMouseLeave: () => {
              // setCurrentContent([]);
            },
            onMouseOver: (dots, _) => {
              mappingCurrentPayload(dots?.dataKey, dots?.payload?.["$o-raw"]);
            },
          }}
          activeDot={{
            style: { cursor: "pointer" },
            r: items.activeDotSize || 8,
            onMouseLeave: () => {
              // setCurrentContent([]);
            },
            onMouseOver: (_, dots) => {
              mappingCurrentPayload(dots?.dataKey, dots?.payload?.["$o-raw"]);
            },
          }}
        />
      );
    }
    return listOptions;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={datasets}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="2 5" />
        <XAxis dataKey="$o-label" />
        <YAxis />
        <Tooltip
          cursor={false}
          trigger="hover"
          content={
            <CustomTooltipContent
              layouts={currentContent}
              templates={tooltipContent}
            />
          }
        />
        <Legend />
        {generateLineChart()}
      </LineChart>
    </ResponsiveContainer>
  );
}
