import { IconFulltime, IconHybrid, IconRemote } from "../../../assets/department";

export const dataDepartmentListFooter = [
  {
    title: 'Hybrid',
    judul: 'Hybrid',
    icon: <IconHybrid />,
  },
  {
    title: 'Fulltime',
    judul: 'Penuh Waktu',
    icon: <IconFulltime />,
  },
  {
    title: 'Remote',
    judul: 'Jarak jauh',
    icon: <IconRemote />,
  },
];
