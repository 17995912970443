import { Box, Typography } from "@mui/material"
import { Card, Pagination, RowPerPage, Search } from "../../../atoms"
import TableComponent from "../../../molecules/table/mainTable"
import { useEffect, useState } from "react"
import { ListProjectTbody, ListProjectThead } from "../../../molecules/table/tableProject/listProject"
import DataTableListProject from "../../../../data/table/project/listProject"
import SelectYear from "../../../molecules/year"
import moment from 'moment'
import { useSelector } from "react-redux"
import { getCompany } from "../../../../features/companies"
import { getListProject } from "../../../../configs/axios/apiPath/fetchProject/fetchDashboard"
import { useNavigate } from "react-router-dom"

const SummaryTableProject = () => {
  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const yearNow = moment().format('YYYY');
  const year = [];

  const [selectedYear, setSelectedYear] = useState(yearNow);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [keyword, setKeyword] = useState("");
  const [dataProject, setDataProject] = useState([]);

  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }
  
  useEffect(() => {
    async function fetchData() {
      if(company){
        const params = {
          company_id : company,
          page: page,
          page_size: pageSize,
          year: selectedYear,
          search: keyword,
        };
        setIsLoading(true)
        try {
          const {
            data: {data}
          } = await getListProject(params);
          setDataProject(data?.projects)
          setLastPage(data?.page_info?.last_page)
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }         
        } finally {
          setIsLoading(false)
        }
      }
    }
    fetchData()

  },[company, page, pageSize, yearNow, keyword, navigate, selectedYear])

  return(
    <Card sx={{ padding: '20px  25px', mt: '20px' }}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box display={'flex'}>
          {/* --- Title --- */}
          <Typography 
            sx={{
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '20px',
              marginRight: '20px'
            }}
          >
            Summary Project
          </Typography>

          {/* --- Search --- */}
          <Search
            placeholder="Type here to search"
            page={page}
            setPage={(e) => setPage(e)}
            setSearch={(e)=>setKeyword(e)}
          />
        </Box>
        <SelectYear
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          year={year}
        />
      </Box>

      {/* --- Table --- */}
      <Box sx={{ mt: '24px', mb: '16px' }}>
        <TableComponent
          isLoading={isLoading}
          tbody={dataProject}
          TableHead={
            <ListProjectThead
                thead={DataTableListProject}
            />
          }
          TableBody={
            <ListProjectTbody 
              tbody={dataProject} 
            />
          }
          col={6}
        />
      </Box>
    
      {/* --- Pagination --- */}
      <Box display={'flex'} justifyContent={'end'}>
        <RowPerPage
          setPageSize={setPageSize}
          pageSize={pageSize}
          setPage={setPage}
        />
        <Pagination page={page} setPage={setPage} lastPage={lastPage}/>
      </Box>
    </Card>
  )
}

export default SummaryTableProject