import { API } from "../../../API";

export const getListRequest = (params) =>
  API.get("job-requests", { params: params });

export const getSummaryRequest = (params) =>
  API.get("job-requests/summary", {
    params: params,
    headers: {
      "Content-Type": `application/json`,
      Accept: `application/json`,
    },
  });

// --- Post Employees ---
export const postRequest = (data) => API.post("job-requests", data);

// --- Get Request By Id ---
export const getRequestById = (id, params) =>
  API.get(`job-requests/${id}`, { params: params });

// --- Update Request By Id ---
export const updateRequestById = (data, id) =>
  API.put(`job-requests/${id}`, data);

// --- Delete Request By Id ---
export const deleteRequestById = (id) => API.delete(`job-requests/${id}`);
