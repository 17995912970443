import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const CoreHRPermissionIndex = () => {
  return (
    <Box mt="20px">
      <Outlet />
    </Box>
  );
};

export default CoreHRPermissionIndex;
