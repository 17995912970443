import { Grid, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import {
  getEmployeesById,
  getImageDocument,
} from "../../../../../configs/axios/apiPath";
import DataLogin from "../../../../../data/dataLogin";
import {
  ImgFront,
  ImgLeft,
  ImgRight,
  ImgSelfie,
} from "../../../../../assets/employee";

const EmployeeDocument = () => {
  const path = useLocation();
  const pathname = path.pathname;
  const paths = pathname.split("/");
  const id = paths[4];

  const getData = DataLogin();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [temporaryImageIdentity, setTemporaryImageIdentity] = useState("");
  const [temporaryImageFront, setTemporaryImageFront] = useState("");
  const [temporaryImageLeft, setTemporaryImageLeft] = useState("");
  const [temporaryImageRight, setTemporaryImageRight] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        include: "document",
      };
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await getEmployeesById(id, params);

        setTemporaryImageIdentity(
          await handleImage(data?.document?.identity_card?.id)
        );
        setTemporaryImageFront(
          await handleImage(data?.document?.front_selfie?.id)
        );
        setTemporaryImageLeft(
          await handleImage(data?.document?.left_selfie?.id)
        );
        setTemporaryImageRight(
          await handleImage(data?.document?.right_selfie?.id)
        );
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id, navigate]);

  const handleImage = async (id) => {
    setIsLoading(true);

    try {
      const response = await getImageDocument(id, getData.token);
      const contentType = response.headers["content-type"];
      const blob = new Blob([response.data], { type: contentType });
      const objectURL = URL.createObjectURL(blob);
      return objectURL;
    } catch (error) {
      console.error(error.response.status);
      if (error.response.status === 401) {
        localStorage.removeItem("data");
        navigate(`/`);
      }
    }
  };

  return (
    <Grid container columns={4} spacing={2} sx={{ mt: "30px" }}>
      {/* --- Identity Card --- */}
      <Grid item xl={1} lg={1} d={2} sm={4} xs={4}>
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"18px"}
          lineHeight={"25px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"10px"}
        >
          Identity Picture
        </Typography>

        {isLoading ? (
          <Skeleton
            variant="circular"
            width={242}
            height={242}
            style={{
              borderRadius: "50%",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          />
        ) : (
          <img
            src={temporaryImageIdentity ? temporaryImageIdentity : ImgSelfie}
            alt="cvSaya"
            width={242}
            height={284}
          />
        )}
      </Grid>

      {/* --- Front Selfie --- */}
      <Grid item xl={1} lg={1} d={2} sm={4} xs={4}>
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"18px"}
          lineHeight={"25px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"10px"}
        >
          Selfie Picture
        </Typography>
        {isLoading ? (
          <Skeleton
            variant="circular"
            width={242}
            height={242}
            style={{
              borderRadius: "50%",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          />
        ) : (
          <img
            src={temporaryImageFront ? temporaryImageFront : ImgFront}
            alt="cvSaya"
            width={242}
            height={284}
          />
        )}

        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={400}
          fontSize={"12px"}
          lineHeight={"16px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"10px"}
        >
          Front Selfie
        </Typography>
      </Grid>

      {/* --- Left Selfie --- */}
      <Grid item xl={1} lg={1} d={2} sm={4} xs={4}>
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"18px"}
          lineHeight={"25px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"10px"}
        >
          Selfie Picture
        </Typography>{" "}
        {isLoading ? (
          <Skeleton
            variant="circular"
            width={242}
            height={242}
            style={{
              borderRadius: "50%",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          />
        ) : (
          <img
            src={temporaryImageLeft ? temporaryImageLeft : ImgLeft}
            alt="cvSaya"
            width={242}
            height={284}
          />
        )}
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={400}
          fontSize={"12px"}
          lineHeight={"16px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"10px"}
        >
          Left Selfie
        </Typography>
      </Grid>

      {/* --- Right Selfie --- */}
      <Grid item xl={1} lg={1} d={2} sm={4} xs={4}>
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"18px"}
          lineHeight={"25px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"10px"}
        >
          Selfie Picture
        </Typography>

        {isLoading ? (
          <Skeleton
            variant="circular"
            width={242}
            height={242}
            style={{
              borderRadius: "50%",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          />
        ) : (
          <img
            src={temporaryImageRight ? temporaryImageRight : ImgRight}
            alt="cvSaya"
            width={242}
            height={284}
          />
        )}
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={400}
          fontSize={"12px"}
          lineHeight={"16px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"10px"}
        >
          Right Selfie
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EmployeeDocument;
