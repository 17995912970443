import { Box, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { IconFilterDown, IconFilterUp } from '../../../../../assets/commons';

export default function HomePageThead({
  thead,
  sortir,
  setSortir,
  dept,
  setDept,
  position,
  setPosition,
  status,
  setStatus,
}) {

    // --- Handle Sortir ---
    const handleSortir = (item) => {
        if (item === 'Employee name') {
        if (sortir === 'asc') {
            setDept('');
            setPosition('');
            setStatus('');
            return setSortir('desc');
        } else if (sortir === 'desc') {
            setSortir('asc');
            setDept('');
            setPosition('');
            setStatus('');
        } else {
            setSortir('asc');
            setDept('');
            setPosition('');
            setStatus('');
        }
        } else if (item === 'Dept') {
        if (dept === 'asc') {
            setSortir('');
            setDept('desc');
            setPosition('');
            setStatus('');
        } else if (dept === 'desc') {
            setSortir('');
            setDept('asc');
            setPosition('');
            setStatus('');
        } else {
            setSortir('');
            setDept('asc');
            setPosition('');
            setStatus('');
        }
        } else if (item === 'Position') {
        if (position === 'asc') {
            setSortir('');
            setDept('');
            setPosition('desc');
            setStatus('');
        } else if (position === 'desc') {
            setSortir('');
            setDept('');
            setPosition('asc');
            setStatus('');
        } else {
            setSortir('');
            setDept('');
            setPosition('asc');
            setStatus('');
        }
        } else if (item === 'Status') {
        if (status === 'asc') {
            setSortir('');
            setDept('');
            setPosition('');
            setStatus('desc');
        } else if (status === 'desc') {
            setSortir('');
            setDept('');
            setPosition('');
            setStatus('asc');
        } else {
            setSortir('');
            setDept('');
            setPosition('');
            setStatus('asc');
        }
        }
    };

    // --- Handle Color --
    const color = {
        active: ' #51B15C',
        unactive: '#C4C4C4',
    };

    return (
        <thead
            style={{
                color: '#51B15C',
                backgroundColor: 'rgba(81, 177, 92, 0.06)',
            }}
        >
            <tr>
                {thead.map((item, idx) => (
                <th
                    key={idx}
                    style={{
                        padding: '10px 12px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                    }}
                >
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Typography
                            fontFamily={'sans-serif'}
                            fontStyle={'normal'}
                            fontSize={'14px'}
                            fontWeight={600}
                            lineHeight={'16px'}
                            letterSpacing={'0.02em'}
                            mr={'5px'}
                        >
                            {item}
                        </Typography>
                        <Box
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleSortir(item)}
                        >
                            <IconButton sx={{ p: '0px' }}>
                                {React.cloneElement(<IconFilterUp />, {
                                    style: {
                                    fill: sortir === 'asc' ? color.active : color.unactive,
                                    },
                                })}
                            </IconButton>
                            <IconButton sx={{ p: '0px' }}>
                                {React.cloneElement(<IconFilterDown />, {
                                    style: {
                                    fill: sortir === 'desc' ? color.active : color.unactive,
                                    },
                                })}
                            </IconButton>
                        </Box>
                    </Box>
                </th>
                ))}
            </tr>
        </thead>
    );
}
