import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card } from '../../../atoms';
import { capitalizeFirstLetter, formatNumber } from '../../../../utils/format';
import { IconEdit, IconDelete } from '../../../../assets/commons';
import EditAdhoc from '../../../../pages/payroll/payrollList/payrollListDetail/editAdhoc';
import DeleteAdhoc from '../../../../pages/payroll/payrollList/payrollListDetail/deleteAdhoc';

export default function PayrollDetailAdhoc({
  item,
  setTotalAmount,
  totalAmount,
  id,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const dispatch = useDispatch();
  const [value, setValue] = useState(() => {
    const parsedData = JSON.parse(localStorage.getItem('cvSayaData'));
    const id = parsedData && parsedData.idEmployee;
    return id ? parseInt(id) : '';
  });

  // --- Handle Edit ---
  const handleEdit = (id) => {
    setValue(id);
    setIsModalEditOpen(true);
  };

  //   --Handle Delete ---
  const handleDelete = (id) => {
    setValue(id);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('cvSayaData'));

    if (!data || !data.idEmployee) {
      if (value !== '') {
        const newData = { ...data, idEmployee: value.toString() };
        localStorage.setItem('cvSayaData', JSON.stringify(newData));
      }
    } else if (value !== '') {
      data.idEmployee = value.toString();
      localStorage.setItem('cvSayaData', JSON.stringify(data));
    }
  }, [value]);

  useEffect(() => {
    const groupedData = item?.reduce((acc, item) => {
      const { type, amount } = item;
      if (!acc[type]) {
        acc[type] = 0;
      }
      acc[type] += amount;
      return acc;
    }, {});
    const allowance = groupedData?.allowance || 0;
    const deduction = groupedData?.deduction || 0;
    setTotalAmount(allowance - deduction);
  }, [item]);

  return (
    <Card>
      <Box padding={'20px 31px'}>
        <Typography
          fontSize={'18px'}
          fontWeight={'600px'}
          lineHeight={'25px'}
          letterSpacing={'1px'}
          color={'#51B15C'}
          mb={'12px'}
        >
          Adhoc
        </Typography>
        {item &&
          item.map((detail, idx) => (
            <Grid container columns={5} spacing={2} key={idx} mb={'11px'}>
              {/* --- Name --- */}
              <Grid item xl={1} lg={1} md={3} sm={3} xs={3}>
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontSize={'15px'}
                  fontWeight={600}
                  lineHeight={'20px'}
                  letterSpacing={'1px'}
                  color={'#171717'}
                >
                  {capitalizeFirstLetter(detail?.name)}
                </Typography>
              </Grid>
              {/* --- Type --- */}
              <Grid item xl={1} lg={1} md={3} sm={3} xs={3}>
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontSize={'15px'}
                  fontWeight={400}
                  lineHeight={'20px'}
                  letterSpacing={'1px'}
                  color={'#171717'}
                >
                  {capitalizeFirstLetter(detail?.type)}
                </Typography>
              </Grid>
              {/* --- Date --- */}
              <Grid item xl={1} lg={1} md={3} sm={3} xs={3}>
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontSize={'15px'}
                  fontWeight={400}
                  lineHeight={'20px'}
                  letterSpacing={'1px'}
                  color={'#171717'}
                >
                  {moment(detail?.date).format('DD MMMM YYYY')}
                </Typography>
              </Grid>
              {/* --- Amount --- */}
              <Grid item xl={1} lg={1} md={3} sm={3} xs={3}>
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontSize={'18px'}
                  fontWeight={600}
                  lineHeight={'25px'}
                  letterSpacing={'1px'}
                  color={
                    detail?.type === 'allowance'
                      ? '#51B15C'
                      : detail?.type === 'deduction'
                      ? '#FF1D1D'
                      : ''
                  }
                >
                  {formatNumber(detail?.amount)}
                </Typography>
              </Grid>
              {/* --- Action --- */}
              <Grid item xl={1} lg={1} md={3} sm={3} xs={3}>
                <Box display={'flex'} justifyContent={'center'}>
                  <IconButton
                    sx={{ p: '0px', marginRight: '6px' }}
                    onClick={() => handleDelete(detail.id)}
                  >
                    <IconDelete />
                  </IconButton>
                  <IconButton
                    sx={{ p: '0px' }}
                    onClick={() => handleEdit(detail.id)}
                  >
                    <IconEdit />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          ))}
      </Box>
      <Box
        padding={'20px 18px'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ background: 'rgba(81, 177, 92, 0.1)' }}
        borderRadius={'0px 0px 8px 8px'}
      >
        <Typography
          fontWeight={'700px'}
          fontSize={'12px'}
          lineHeight={'14px'}
          letterSpacing={'1px'}
          color={'#51B15C'}
        >
          Total Adhoc
        </Typography>
        <Typography
          fontFamily={'sans-serif'}
          fontStyle={'normal'}
          fontWeight={700}
          fontSize={'16px'}
          lineHeight={'22px'}
          letterSpacing={'1px'}
          color={totalAmount > 0 ? '#51B15C' : '#F33A3A'}
        >
          {totalAmount > 0
            ? formatNumber(totalAmount)
            : `-${formatNumber(Math.abs(totalAmount))}`}
        </Typography>
      </Box>

      <DeleteAdhoc
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={'Adhoc'}
        idAdhoc={value}
      />

      <EditAdhoc
        isOpen={isModalEditOpen}
        onClose={() => setIsModalEditOpen(false)}
        idAdhoc={value}
      />
    </Card>
  );
}
