const DataTableCalendar = [
    {
      title: 'Name',
      judul: 'Nama',
      url: 'name',
    },
    {
      title: 'Description',
      judul: 'Deskripsi',
      url: 'descripcion',
    },
    {
      title: 'Date',
      judul: 'Tanggal',
      url: 'date',
    },
    {
      title: 'Action',
      judul: 'Aksi',
      url: 'action',
    },
  ];
  
  export default DataTableCalendar;
