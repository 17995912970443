export default function mappingChartDataset(data) {
  let datasets = [];
  let graphs = {};

  const mappingObj = (current, incomingData, valueOn, graphOn) => {
    try {
      const keyGraph = incomingData[graphOn];
      const keyValues = incomingData[valueOn];
      const castData = current || {};
      return {
        ...castData,
        "$o-raw": [...(castData["$o-raw"] || []), incomingData],
        "$list-keys": {
          ...castData["$list-keys"],
          [data.graphs[keyGraph].label]: {
            ...data.graphs[keyGraph],
            key: keyGraph,
          },
        },
        [data.graphs[keyGraph].label]:
          (castData[data.graphs[keyGraph].label] || 0) + keyValues,
      };
    } catch (er) {
      return current;
    }
  };

  const objData = data.dataSources.reduce(
    (a, b) => ({
      ...a,
      [b[data.xAxisOn]]: mappingObj(
        a[b[data.xAxisOn]],
        b,
        data.yAxisOn,
        data.graphOn
      ),
    }),
    {}
  );

  const keyIndexData = Object.keys(objData);
  // mapping label as values
  for (let x in data.labels) {
    const items = data.labels[x];
    const regexMatcher = items.key.replace(/\%/g, ".*");
    const findKey = keyIndexData.filter((a) =>
      a.match(new RegExp(`^${regexMatcher}$`, "g"))
    )[0];

    const currentDataSet = objData[findKey] || {};

    graphs = {
      ...graphs,
      ...(currentDataSet["$list-keys"] || {}),
    };

    datasets.push({
      ...currentDataSet,
      "$o-label": items.label,
      "$o-raw": currentDataSet["$o-raw"] || [],
    });
  }

  return {
    graphs: graphs,
    datasets,
  };
}
