import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconPlus } from '../../../../assets/commons';
import {
  Card,
  Pagination,
  RowPerPage,
  Search,
} from '../../../../components/atoms';
import TableComponent from '../../../../components/molecules/table/mainTable';
import { DataTableLocation } from '../../../../data/table/organization';
import { getLanguage } from '../../../../features/language';
import { LocationThead, LocationTbody } from '../../../../components/molecules/table/tableOrganization/location';
import { getCompany } from '../../../../features/companies';
import { getListLocation } from '../../../../configs/axios/apiPath';

const LocationDashboard = () => {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [departmentLocation, setDepartmentLocation] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [order, setOrder] = useState('name');
  const [direction, setDirection] = useState('asc');
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);

  const company = useSelector(getCompany);

  const handleNavigationClick = (url) => {
    navigate('/organization/location/add');
  };

  useEffect(() => {
    async function fetchData() {
       if(company){
        const params = {
          page_size: pageSize,
          page: page,
          keyword: keyword,
          order: order,
          direction: direction,
          company_id : company,
        }

          setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListLocation(params);
          setLastPage(data?.page_info?.last_page);
          setDepartmentLocation(data?.page_data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      
       }
    }

    fetchData();
  }, [page, keyword, pageSize, order, direction, navigate, company]);

  return (
    <Card sx={{ marginTop: '20px' }}>
      <Box display={'flex'} flexDirection={'column'} p={'23px 22px'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          {/* --- Search --- */}
          <Search
            placeholder={
              lang === 'Id'
                ? 'Ketik disini untuk mencari'
                : 'Type here to search'
            }
            setSearch={(e) => setKeyword(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />

          <Box display={'flex'} alignItems={'center'}>
            {/* --- Add --- */}
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              onClick={() => handleNavigationClick('add')}
              sx={{
                borderRadius: '4px',
                marginLeft: '10px',
              }}
            >
              <Box display={'flex'} alignItems={'center'}>
                <IconPlus />
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={600}
                  fontSize={'12px'}
                  lineHeight={'14px'}
                  color="primary.contrastText"
                  letterSpacing={'0.02em'}
                  marginLeft={'10px'}
                  textTransform={'none'}
                >
                  {lang === 'Id' ? 'Tambah' : 'Add'}
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>

        {/* --- Table --- */}
        <Box sx={{ mt: '24px', mb: '16px' }}>
          <TableComponent
            isLoading={isLoading}
            tbody={departmentLocation}
            TableHead={
              <LocationThead
                thead={DataTableLocation}
                setOrder={setOrder}
                setDirection={setDirection}
                direction={direction}
              />
            }
            TableBody={<LocationTbody tbody={departmentLocation} setData={setDepartmentLocation} />}
            col={4}
          />
        </Box>

        {/* --- Pagination --- */}
        <Box display={'flex'} justifyContent={'end'}>
          <RowPerPage
            setPageSize={setPageSize}
            pageSize={pageSize}
            setPage={setPage}
          />
          <Pagination page={page} setPage={setPage} lastPage={lastPage} />
        </Box>
      </Box>
    </Card>
  );
};

export default LocationDashboard;
