import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  Stack,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconDate, IconEmployee } from '../../../../assets/coreHr/awards';
import {
  IconPaper,
  IconExpectedBudget,
} from '../../../../assets/benefit/reimbursement';
import { IconTravel, IconMaps } from '../../../../assets/benefit/journey';
import { Card, TextField } from '../../../../components/atoms';
import { getCompany } from '../../../../features/companies';
import { getLanguage } from '../../../../features/language';
import { ValidateDescription } from '../../../../utils/validate';
import {
  getListEmployee,
  getListTravelMode,
  postJourney,
} from '../../../../configs/axios/apiPath';

const BenefitJourneyAdd = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [travelModeData, setTravelModeData] = useState([]);

  const [isSubmitted, setIsSubmit] = useState(false);
  const [total, setTotal] = useState(1);
  const [totalTravelMode, setTotalTravelMode] = useState(1);

  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const lang = useSelector(getLanguage);
  const placeHolderId = 'Ketik disini';
  const placeHolderEn = 'Type here';

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    employee_id: null,
    place: '',
    start_date: '',
    end_date: '',
    travel_mode_id: null,
    budget: '',
    description: '',
  });

  // --- Handle Input Change ---
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // --- Handle Submit ---
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmit(true);
    const data = {
      ...formData,
      company_id: company,
      employee_id: formData.employee_id?.id,
      travel_mode_id: formData.travel_mode_id?.id,
    };
    setIsLoading(true);
    if (company) {
      try {
        await postJourney(data);

        navigate('/benefit/journey');
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          page_size: total,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListEmployee(params);

          setEmployeeData(data.employee);
          if (data.page_info.total > 0) {
            setTotal(data.page_info.total);
          }
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, total]);

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          page_size: totalTravelMode,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListTravelMode(params);

          setTravelModeData(data.page_data);
          if (data.page_info.total > 0) {
            setTotalTravelMode(data.page_info.total);
          }
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, navigate, totalTravelMode]);

  return (
    <Card>
      <Box p={'20px'}>
        <Box>
          <Typography
            fontFamily={'sans-serif'}
            fontStyle={'normal'}
            fontWeight={600}
            fontSize={'20px'}
            lineHeight={'27px'}
            letterSpacing={'0.01em'}
            color={'#171717'}
            marginBottom="15px"
          >
            {lang === 'Id' ? 'Tambah' : 'Add'}
          </Typography>
          <Divider />
        </Box>
        <form
          onSubmit={handleSubmit}
          style={{
            marginTop: '20px',
            fontWeight: '600px',
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '0.01em',
            color: '#171717',
          }}
        >
          {/* --- List Employee --- */}
          <Stack spacing={2}>
            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <Autocomplete
                  value={formData.employee_id}
                  onChange={(_, value) =>
                    setFormData({ ...formData, employee_id: value })
                  }
                  options={employeeData}
                  getOptionLabel={(employee) => employee.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={lang === 'Id' ? 'Karyawan' : 'Employee'}
                      required
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder={
                        lang === 'Id' ? placeHolderId : placeHolderEn
                      }
                      icon={
                        isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <IconEmployee />
                        )
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>

            {/* --- place Of Visit --- */}
            <Grid container columns={2}>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <TextField
                  label={lang === 'Id' ? 'Tempat Tujuan' : 'Place Of Visit'}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  placeholder={lang === 'Id' ? placeHolderId : placeHolderEn}
                  value={formData.place}
                  onChange={handleInputChange}
                  name="place"
                  icon={<IconMaps />}
                />
              </Grid>
            </Grid>
            {/* --- Date --- */}
            <Stack direction="row" spacing={2} sx={{ width: '100%' }}>
              <TextField
                label={lang === 'Id' ? 'Tanggal' : 'Date'}
                fullWidth
                required
                type="date"
                size="small"
                variant="outlined"
                value={formData.start_date}
                onChange={handleInputChange}
                name="start_date"
                icon={<IconDate />}
                sx={{ flexGrow: 1 }}
              />
              <TextField
                label={lang === 'Id' ? 'Tanggal Berakhir' : 'End Date'}
                fullWidth
                required
                type="date"
                size="small"
                variant="outlined"
                value={formData.end_date}
                onChange={handleInputChange}
                name="end_date"
                icon={<IconDate />}
                sx={{ flexGrow: 1 }}
              />
            </Stack>

            <Grid container columns={2}>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <Autocomplete
                  value={formData.travel_mode_id}
                  onChange={(_, value) =>
                    setFormData({ ...formData, travel_mode_id: value })
                  }
                  options={travelModeData}
                  getOptionLabel={(employee) => `By ${employee.name}`}
                  renderOption={(props, option) => {
                    return (
                      <Box
                        component="li"
                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={option.image_url}
                          srcSet={option.image_url}
                          alt="icon"
                        />
                        {option.name}
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={lang === 'Id' ? 'Mode Perjalanan' : 'Travel Mode'}
                      required
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder={
                        lang === 'Id' ? placeHolderId : placeHolderEn
                      }
                      icon={
                        isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <IconTravel />
                        )
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container columns={2}>
              {/* --- Expected Budget --- */}
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <TextField
                  label={lang === 'Id' ? 'Ekspetasi Biaya' : 'Expected Budget'}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  type="number"
                  placeholder={lang === 'Id' ? placeHolderId : placeHolderEn}
                  value={formData.budget}
                  onChange={handleInputChange}
                  name="budget"
                  icon={<IconExpectedBudget />}
                />
              </Grid>
            </Grid>

            {/* --- Description --- */}
            <Grid container columns={2}>
              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                <TextField
                  label={lang === 'Id' ? 'Deskripsi' : 'Description'}
                  fullWidth
                  multiline
                  rows={4}
                  required
                  variant="outlined"
                  size="small"
                  placeholder={lang === 'Id' ? placeHolderId : placeHolderEn}
                  value={formData.description}
                  onChange={handleInputChange}
                  name="description"
                  icon={<IconPaper />}
                  error={
                    isSubmitted && !ValidateDescription(formData.description)
                  }
                  errorMessage={
                    lang === 'Id'
                      ? 'Minimal 10 karakter'
                      : 'Minimals 10 characters'
                  }
                />
              </Grid>
            </Grid>
          </Stack>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: '136px', marginTop: '21px' }}
          >
            {lang === 'Id' ? 'Simpan' : 'Save'}
          </Button>
        </form>
      </Box>
    </Card>
  );
};

export default BenefitJourneyAdd;
