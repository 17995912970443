import { API } from '../../../API';
import { createAsyncThunk } from '@reduxjs/toolkit';

// --- List history ---
export const getListHistory = (params) =>
  API.get('timesheet/histories', { params: params });

// export const getListHistory = createAsyncThunk('timesheet/getListHistory', async (params) => {
//   try {
//     const response = await API.get('timesheet/histories', { params: params });
//     return response.data;
//   } catch (error) {
//     throw new Error('Failed to fetch list history.');
//   }
// });
