const DataTableCandidate = [
  {
    title: "Name",
    judul: "Name",
  },
  {
    title: "Education",
    judul: "Pendidikan",
  },
  {
    title: "Experience",
    judul: "Pengalaman",
  },
  {
    title: "Join Date",
    judul: "Tanggal Bergabung",
  },
  {
    title: "Domicile",
    judul: "Domisili",
  },
  {
    title: "As",
    judul: "Sebagai",
  },
  {
    title: "Action",
    judul: "Aksi",
  },
];

export default DataTableCandidate;
