import capitalizeFirstLetter from "../capitalCase";

function createNavigation(path) {
const paths = path.split('/').filter(Boolean);
const navigation = [];

let currentPath = '/';

for (let i = 0; i < paths.length; i++) {
const pathSegment = paths[i];
const isNumber = /^\d+$/.test(pathSegment);

let currentName = '';

currentPath += pathSegment + '/';

if (isNumber) {
  const prevPathSegment = paths[i - 1];
  currentName = capitalizeFirstLetter(prevPathSegment);
  if (navigation.find(item => item.name === currentName)) {
    navigation.pop();
  }
} else {
  currentName = capitalizeFirstLetter(pathSegment);
}

const menuItem = {
  name: currentName,
  path: currentPath.slice(0, -1),
};

navigation.push(menuItem);

}

return navigation;
}

export default createNavigation;