import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import moment from 'moment';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { Card, Modals } from '../../../../components/atoms';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../../../features/language';
import { patchPromotionById } from '../../../../configs/axios/apiPath/fetchCoreHR/fetchCoreHRPromotion';
import { useNavigate } from 'react-router-dom';
import { IconClose } from '../../../../assets/commons';
import { capitalizeFirstLetter } from '../../../../utils/format';

const CoreHRPromotionDetail = ({
  isOpen,
  onClose,
  detail,
  id,
  isLoading,
  setIsLoading,
  company,
}) => {
  const lang = useSelector(getLanguage);
  const navigate = useNavigate();
  // --- Handle Submit ---
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (company) {
      const data = {
        company_id: 'MRI',
      };
      setIsLoading(true);
      try {
        const response = await patchPromotionById(data, id);

        if (response.data.meta.code === 20000) {
          onClose();
          window.location.reload();
        }
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: '80vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
        }}
      >
        <Card>
          <Box p={'30px'}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '7px',
                position: 'sticky',
                top: 0,
                bgcolor: 'background.paper',
                p: '10px 0px 10px 0px',
                zIndex: 999,
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontSize={'20px'}
                fontWeight={600}
                lineHeight={'27px'}
                color={'#171717'}
              >
                {lang === 'Id' ? 'Detail Promosi' : 'Promotion Details'}
              </Typography>
              <IconButton sx={{ p: '0px' }} onClick={onClose}>
                <IconClose />
              </IconButton>
            </Box>

            {!isLoading ? (
              <Box mt={'20px'} component={'form'} noValidate autoComplete="off">
                {/* --- Dept --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id' ? 'Departemen' : 'Dept'}
                  </Typography>
                  <Box
                    style={{
                      width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      display: 'inline-block',
                    }}
                  >
                    <Typography mx={'10px'} py={'10px'}>
                      {detail?.department?.name}
                    </Typography>
                  </Box>
                </Box>

                {/* --- Employee --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id' ? 'Karyawan' : 'Employee'}
                  </Typography>
                  <Box
                    style={{
                      width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      display: 'inline-block',
                    }}
                  >
                    <Typography mx={'10px'} py={'10px'}>
                      {detail?.employee?.name}
                    </Typography>
                  </Box>
                </Box>

                {/* --- Promotion --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id' ? 'Promosi' : 'Promotion'}
                  </Typography>
                  <Box
                    style={{
                      width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      display: 'inline-block',
                    }}
                  >
                    <Typography mx={'10px'} py={'10px'}>
                      {detail?.promotion?.name}
                    </Typography>
                  </Box>
                </Box>

                {/* --- Date --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id' ? 'Tanggal Promosi' : 'Date of Appointment'}
                  </Typography>
                  <Box
                    style={{
                      width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      display: 'inline-block',
                    }}
                  >
                    <Typography mx={'10px'} py={'10px'}>
                      {moment(detail?.date_of_appointment).format(
                        'D MMMM YYYY'
                      )}
                    </Typography>
                  </Box>
                </Box>

                {/* --- Description --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    {lang === 'Id' ? 'Deskripsi' : 'Description'}
                  </Typography>
                  <Box
                    style={{
                      width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      display: 'inline-block',
                    }}
                  >
                    <Typography
                      mx={'10px'}
                      py={'10px'}
                      height={'auto'}
                      maxHeight={'160px'}
                      sx={{ overflowY: 'scroll' }}
                    >
                      {detail?.description}
                    </Typography>
                  </Box>
                </Box>

                {/* --- Status --- */}
                <Box
                  mb={'20px'}
                  p={'0px'}
                  sx={{
                    position: 'relative',
                  }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={600}
                    lineHeight={'18px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                    mb={'6px'}
                  >
                    Status
                  </Typography>
                  <Box
                    style={{
                      width: '100%',
                      background: '#f7f7f7',
                      borderRadius: '4px',
                      border: '1px solid transparent',
                      display: 'inline-block',
                    }}
                  >
                    <Typography mx={'10px'} py={'10px'}>
                      {capitalizeFirstLetter(detail?.status)}
                    </Typography>
                  </Box>
                </Box>

                {/* --- Approve --- */}
                <Button
                  variant="outlined"
                  disabled={detail?.status === 'approved'}
                  fullWidth
                  sx={{ height: '62px', mb: '15px' }}
                  onClick={handleSubmit}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'20px'}
                    fontWeight={'700px'}
                    lineHeight={'27px'}
                    letterSpacing={'1px'}
                    textTransform={'none'}
                  >
                    {lang === 'Id' ? 'Terima' : 'Approve'}
                  </Typography>
                </Button>

                {/* --- Close --- */}
                <Button
                  variant="contained"
                  disableRipple
                  onClick={onClose}
                  fullWidth
                  sx={{ height: '62px' }}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'20px'}
                    fontWeight={'700px'}
                    lineHeight={'27px'}
                    letterSpacing={'1px'}
                    textTransform={'none'}
                  >
                    {lang === 'Id' ? 'Keluar' : 'Close'}
                  </Typography>
                </Button>
              </Box>
            ) : (
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <CircularProgress color="secondary" />
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </Modals>
  );
};

export default CoreHRPromotionDetail;
