const DataTableResign = [
    {
        title: 'Employee Name',
        judul: 'Nama Karyawan',
        url: 'name',
    },
    {
        title: 'Resignation Type',
        judul: 'Tipe Resign',
        url: 'resign_type',
    },
    {
        title: 'Resignation Date',
        judul: 'Tanggal Resign',
        url: 'resignation_date',
    },
    {
        title: 'Resignation Reason',
        judul: 'Alasan Resign',
        url: 'planning',
    },
    {
        title: 'Status',
        judul: 'Status',
        url: 'status',
    },
    {
        title: 'Action',
        judul: 'Aksi',
        url: 'name',
    }
]

export default DataTableResign;