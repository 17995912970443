import {
  Autocomplete,
  Box,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
  Grid,
  InputAdornment,
  Stepper,
  Step,
  StepButton,
  StepLabel,
  Button,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";

export default function ThirdPage({ currentData, setCurrentData }) {
  const FormLabel = ({ label }) => (
    <InputLabel
      style={{
        fontFamily: "sans-serif",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "22px",
        letterSpacing: "0.02em",
        color: "#171717",
        marginBottom: "10px",
      }}
    >
      {label}
    </InputLabel>
  );

  return (
    <Box>
      <Box mt={3}>
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={700}
          fontSize={"13px"}
          lineHeight={"32px"}
          color={"#939393"}
          textTransform={"none"}
        >
          Contactable family
        </Typography>
      </Box>

      <Box display="flex">
        <Grid container columns={2} spacing={2}>
          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Name *" />
              <Box display={"flex"}>
                <TextField
                  required
                  variant="outlined"
                  size="large"
                  fullWidth
                  placeholder="Nama kontak yang dapat dihubungi"
                  value={currentData.fam_name}
                  onChange={(e) => setCurrentData("fam_name", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DescriptionIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Relationship *" />
              <Box display={"flex"}>
                <TextField
                  required
                  variant="outlined"
                  size="large"
                  fullWidth
                  placeholder="Status hubungan"
                  value={currentData.relationship}
                  onChange={(e) =>
                    setCurrentData("relationship", e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DescriptionIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        mt={3}
        //margin
        display="flex"
      >
        <Grid container columns={2} spacing={2}>
          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Phone *" />
              <Box display={"flex"}>
                <TextField
                  required
                  type="number"
                  variant="outlined"
                  size="large"
                  fullWidth
                  placeholder="Nomor Telepon / HP"
                  value={currentData.fam_phone}
                  onChange={(e) => setCurrentData("fam_phone", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DescriptionIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
