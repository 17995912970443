import { Box, Typography, Avatar, Stack } from "@mui/material";
import { TooltipsAction } from "../../../../../atoms";
import { ModalInvatation } from "../../../../../organisms/recruitment";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useState, useEffect } from "react";
import { IconDetail, IconAirplane } from "../../../../../../assets/commons";
import RecruitmentCandidateDetail from "../../../../../../pages/recruitment/candidate/detail/DetailModal2";
import {
  getCandidatesById,
  getDocumentOfficeFacillitiesById,
} from "../../../../../../configs/axios/apiPath";

const getPicture = async (id) => {
  if (!id) return null;
  try {
    const response = await getDocumentOfficeFacillitiesById({}, id);
    const contentType = response.headers["content-type"];
    const blob = new Blob([response.data], { type: contentType });
    const objectURL = URL.createObjectURL(blob);
    return objectURL;
  } catch (err) {
    return null;
  }
};

const RecruitmentTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [idCandidate, setId] = useState(null);
  const [detail, setDetail] = useState(null);
  const [pictures, setPictures] = useState({
    identity: null,
    frontSelfie: null,
    leftSelfie: null,
    rightSelfie: null,
  });

  const [idVacancy, setIdVacancy] = useState("");
  const [nameCandidate, setNameCandidate] = useState("");
  const { id } = useParams();

  // --- Handle Detail ---
  const handleDetail = async (id) => {
    setIsModalOpen(true);
    setId(id);
    setModal("detail");
  };

  useEffect(() => {
    async function fetchData() {
      if (idCandidate) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getCandidatesById(idCandidate);

          const frontSelfie = await getPicture(
            data.documents?.front_selfie?.id
          );
          const rightSelfie = await getPicture(
            data.documents?.right_selfie?.id
          );
          const leftSelfie = await getPicture(data.documents?.left_selfie?.id);
          const identity = await getPicture(data.documents?.identity_card?.id);
          setPictures({ frontSelfie, rightSelfie, leftSelfie, identity });
          setDetail(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [id, navigate, idCandidate]);

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Name --- */}
            <td
              style={{
                padding: "10px",
                verticalAlign: "middle",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap="10px"
                justifyContent="center"
              >
                <Avatar src={item?.candidate?.profile_picture} />
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={400}
                  fontSize={"14px"}
                  lineHeight={"130%"}
                  color={"#171717"}
                >
                  {item?.name}
                </Typography>
              </Stack>
            </td>

            {/* --- Email --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item?.education?.degree ?? "-"}
              </Typography>
            </td>
            {/* --- Contact --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item.experience.length > 0
                  ? `${Math.round(
                      item.experience.reduce((accumulator, currentObject) => {
                        return accumulator + currentObject.time_in_months;
                      }, 0) / 12
                    )} years`
                  : "-"}
              </Typography>
            </td>
            {/* Dept */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {moment(item?.registered_at).format("DD MMM")}
              </Typography>
            </td>
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item?.domicile?.city ?? "-"}
              </Typography>
            </td>
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item?.as?.name ?? "-"}
              </Typography>
            </td>
            {/* --- Action --- */}
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                <TooltipsAction title="Detail" arrow placement={"bottom-end"}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleDetail(item?.id)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>
                <TooltipsAction
                  title="Send Invatation"
                  arrow
                  placement={"bottom-end"}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setIsModalOpen(true);
                      setId(item?.id);
                      setIdVacancy(id);
                      setNameCandidate(item?.name);
                      setModal("invatation");
                    }}
                  >
                    <IconAirplane />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
      {modal === "detail" && (
        <RecruitmentCandidateDetail
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          detail={detail}
          type="candidate"
          isLoading={isLoading}
          pictures={pictures}
        />
      )}
      {modal === "invatation" && (
        <ModalInvatation
          isOpen={isModalOpen}
          vacancyId={idVacancy}
          candidateId={idCandidate}
          candidateName={nameCandidate}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export default RecruitmentTbody;
