import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { TooltipsAction } from "../../../../../atoms";
import { IconDetail, IconEdit } from "../../../../../../assets/commons";
import { handleAction } from "../../../../../../utils/function/action";
import {
  handleTooltipClose,
  handleTooltipOpen,
} from "../../../../../../utils/function/tooltips";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCompany } from "../../../../../../features/companies";
import { useSelector } from "react-redux";
import { getPositionById } from "../../../../../../configs/axios/apiPath";
import PositionDetail from "../../../../../../pages/organization/position/detail";

const PositionListTbody = ({ tbody, setData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [positionId, setPositionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState([]);
  const company = useSelector(getCompany);
  const [idTooltipDetail, setIdTooltipDetail] = useState("");
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);

  const [idTooltipEdit, setIdTooltipEdit] = useState("");
  const [isTooltipEdit, setIsTooltipEdit] = useState(false);

  const navigate = useNavigate();

  // --- Handle Edit ---
  const handleEdit = (id) => {
    navigate(`/organization/department/position/edit/${id}`);
  };

  useEffect(() => {
    async function fetchDataDetail() {
      if (positionId) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getPositionById(positionId);
          setDetail(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchDataDetail();
  }, [positionId, navigate]);

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            <td>
              <Box display={"flex"}>
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={400}
                  fontSize={"14px"}
                  lineHeight={"45px"}
                  color={"#171717"}
                  sx={{
                    ml: "10px",
                  }}
                >
                  {item.name}
                </Typography>
              </Box>
            </td>
            <td>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item.level?.name}
              </Typography>
            </td>
            <td>
              {/** action */}
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                {/* --- Detail --- */}
                <TooltipsAction
                  title="Detail"
                  open={isTooltipDetail && item.id === idTooltipDetail}
                  arrow
                  placement={"bottom-end"}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setPositionId,
                        "detail"
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDetail,
                        setIdTooltipDetail
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDetail)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>

                {/* --- Edit --- */}
                <TooltipsAction
                  title="Edit"
                  open={isTooltipEdit && item.id === idTooltipEdit}
                  arrow
                  placement={"bottom-end"}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleEdit(item.id)}
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipEdit,
                        setIdTooltipEdit
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipEdit)}
                  >
                    <IconEdit />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>

      {/** modal */}
      {modal === "detail" && (
        <PositionDetail
          detail={detail}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default PositionListTbody;
