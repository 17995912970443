import { Box, Typography, Grid } from "@mui/material";
import { IconApprovedBy } from "../../../../assets/coreHr/permission";
import moment from "moment";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../features/language";

export default function DoneStep({ detail }) {
  const lang = useSelector(getLanguage);

  const StatusMap = {
    100: "Request",
    200: "Approved",
    201: "Done",
    202: "Verified",
    301: "Rejected",
  };

  return (
    <>
      <Box mt={"10px"} noValidate autoComplete="off">
        {/* --- Date --- */}
        <Box
          mb={"20px"}
          p={"0px"}
          sx={{
            position: "relative",
          }}
        >
          <Typography
            fontFamily={"sans-serif"}
            fontStyle={"normal"}
            fontSize={"13px"}
            fontWeight={600}
            lineHeight={"18px"}
            letterSpacing={"1px"}
            color={"#171717"}
            mb={"6px"}
          >
            {lang === "Id" ? "Tanggal" : "Date"}
          </Typography>
          <Box
            style={{
              width: "100%",
              background: "#f7f7f7",
              borderRadius: "4px",
              border: "1px solid transparent",
              display: "inline-block",
            }}
          >
            <Typography mx={"10px"} py={"10px"}>
              {moment(detail?.date).format("DD MMMM YYYY")}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Grid container columns={2} spacing={2}>
            {/* --- Time --- */}
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box
                mb={"20px"}
                p={"0px"}
                sx={{
                  position: "relative",
                }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontSize={"13px"}
                  fontWeight={600}
                  lineHeight={"18px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                  mb={"6px"}
                >
                  {lang === "Id" ? "Mulai" : "Start"}
                </Typography>
                <Box
                  style={{
                    width: "100%",
                    background: "#f7f7f7",
                    borderRadius: "4px",
                    border: "1px solid transparent",
                    display: "inline-block",
                  }}
                >
                  <Typography mx={"10px"} py={"10px"}>
                    {detail?.time?.slice(0, 5)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {/* --- Time --- */}
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box
                mb={"20px"}
                p={"0px"}
                sx={{
                  position: "relative",
                }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontSize={"13px"}
                  fontWeight={600}
                  lineHeight={"18px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                  mb={"6px"}
                >
                  {lang === "Id" ? "Selesai" : "End"}
                </Typography>
                <Box
                  style={{
                    width: "100%",
                    background: "#f7f7f7",
                    borderRadius: "4px",
                    border: "1px solid transparent",
                    display: "inline-block",
                  }}
                >
                  <Typography mx={"10px"} py={"10px"}>
                    {detail?.time?.slice(8, 13)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* --- Description --- */}
        <Box
          mb={"20px"}
          p={"0px"}
          sx={{
            position: "relative",
          }}
        >
          <Typography
            fontFamily={"sans-serif"}
            fontStyle={"normal"}
            fontSize={"13px"}
            fontWeight={600}
            lineHeight={"18px"}
            letterSpacing={"1px"}
            color={"#171717"}
            mb={"6px"}
          >
            {lang === "Id" ? "Deskripsi" : "Description"}
          </Typography>
          <Box
            style={{
              width: "100%",
              background: "#f7f7f7",
              borderRadius: "4px",
              border: "1px solid transparent",
              display: "inline-block",
            }}
          >
            <Typography
              mx={"10px"}
              py={"10px"}
              height={"auto"}
              maxHeight={"160px"}
              sx={{ overflowY: "scroll" }}
            >
              {detail?.description}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* --- Status --- */}
      <Box
        mb={"20px"}
        p={"0px"}
        sx={{
          position: "relative",
        }}
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontSize={"13px"}
          fontWeight={600}
          lineHeight={"18px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"6px"}
        >
          {lang === "Id" ? "Status" : "Status"}
        </Typography>
        <Box
          style={{
            width: "100%",
            background: "#f7f7f7",
            borderRadius: "4px",
            border: "1px solid transparent",
            display: "inline-block",
          }}
        >
          <Typography mx={"10px"} py={"10px"}>
            {StatusMap[detail?.status]}
          </Typography>
        </Box>
      </Box>

      {/* --- Verified By / Rejected By --- */}
      <Box mt={"10px"} component={"form"} noValidate autoComplete="off">
        {/* --- Approved By / Rejected By --- */}
        {detail?.status === 201 && (
          <Box
            mb={"20px"}
            p={"0px"}
            sx={{
              position: "relative",
            }}
          >
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"18px"}
              letterSpacing={"1px"}
              color={"#171717"}
              mb={"6px"}
            >
              {lang === "Id" ? "Diverifikasi Oleh" : "Verified By"}
            </Typography>
            <Box
              style={{
                width: "100%",
                background: "#f7f7f7",
                borderRadius: "4px",
                border: "1px solid transparent",
                display: "inline-block",
              }}
            >
              <Typography mx={"10px"} py={"10px"}>
                {detail?.map_statuses?.length === 4
                  ? detail?.map_statuses[2]?.employee?.name
                  : null}{" "}
                <IconApprovedBy />
              </Typography>
            </Box>
          </Box>
        )}

        {detail?.status === 202 && (
          <Box
            mb={"20px"}
            p={"0px"}
            sx={{
              position: "relative",
            }}
          >
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"18px"}
              letterSpacing={"1px"}
              color={"#171717"}
              mb={"6px"}
            >
              {lang === "Id" ? "Diverifikasi Oleh" : "Verified By"}
            </Typography>
            <Box
              style={{
                width: "100%",
                background: "#f7f7f7",
                borderRadius: "4px",
                border: "1px solid transparent",
                display: "inline-block",
              }}
            >
              <Typography mx={"10px"} py={"10px"}>
                {detail?.map_statuses?.length === 4
                  ? detail?.map_statuses[2]?.employee?.name
                  : null}{" "}
                <IconApprovedBy />
              </Typography>
            </Box>
          </Box>
        )}

        {detail?.map_statuses?.length === 3 && (
          <Box
            mb={"20px"}
            p={"0px"}
            sx={{
              position: "relative",
            }}
          >
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"18px"}
              letterSpacing={"1px"}
              color={"#171717"}
              mb={"6px"}
            >
              {detail?.map_statuses?.length === 3 &&
              detail?.map_statuses[2]?.status === 301
                ? "Rejected By"
                : "Approved By"}
            </Typography>
            <Box
              style={{
                width: "100%",
                background: "#f7f7f7",
                borderRadius: "4px",
                border: "1px solid transparent",
                display: "inline-block",
              }}
            >
              <Typography mx={"10px"} py={"10px"}>
                {detail?.map_statuses?.length === 3
                  ? detail?.map_statuses[2]?.employee?.name
                  : null}{" "}
                <IconApprovedBy />
              </Typography>
            </Box>
          </Box>
        )}

        {detail?.map_statuses?.length === 2 && (
          <Box
            mb={"20px"}
            p={"0px"}
            sx={{
              position: "relative",
            }}
          >
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"18px"}
              letterSpacing={"1px"}
              color={"#171717"}
              mb={"6px"}
            >
              Rejected By
            </Typography>
            <Box
              style={{
                width: "100%",
                background: "#f7f7f7",
                borderRadius: "4px",
                border: "1px solid transparent",
                display: "inline-block",
              }}
            >
              <Typography mx={"10px"} py={"10px"}>
                {detail?.map_statuses?.length === 2
                  ? detail?.map_statuses[1]?.employee?.name
                  : null}{" "}
                <IconApprovedBy />
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
