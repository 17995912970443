import React from "react";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { IconSearch } from "../../../../assets/department";
import { Box } from "@mui/system";

export default function SelectDepartment({
	data,
	setValue,
	value,
	inputValue,
	inputChange,
	backgroundColor,
}) {
	return (
		<>
			<Autocomplete
				id="country-autocomplete"
				options={data}
				getOptionLabel={(dept) => dept.name}
				autoHighlight
				onChange={(e, value) => setValue(value)}
				inputValue={inputValue}
				value={value}
				onInputChange={(e, value) => inputChange(value)}
				renderOption={(props, option) => {
					return (
						<Box
							component="li"
							sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
							{...props}
						>
							<img
								loading="lazy"
								width="20"
								src={option.icon_url}
								srcSet={option.icon_url}
								alt=""
							/>
							{option.name}
						</Box>
					);
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						fullWidth
						variant="outlined"
						size="small"
						sx={{
							backgroundColor: backgroundColor,
						}}
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<InputAdornment position="start">
									<IconSearch />
								</InputAdornment>
							),
						}}
					/>
				)}
			/>
		</>
	);
}
