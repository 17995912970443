const DataTableLocation = [
    {
      title: 'Location Name',
      judul: 'Nama Lokasi',
      url: 'name',
    },
    {
      title: 'City',
      judul: 'Kota',
      url: 'city',
    },
    {
      title: 'Country',
      judul: 'Negara',
      url: 'country',
    },
    {
      title: 'Action',
      judul: 'Aksi',
      url: 'name',
    },
  ];
  
  export default DataTableLocation;