import { AppBar, Box } from '@mui/material';
import NavigationTopbar from './navigation';
import UserSelect from './userSelect';

const TopbarHomepage = () => {
  return (
    <AppBar
      position="sticky"
      sx={{
        bgcolor: 'white',
        shadow: 'none',
        border: 'none',
        boxShadow: 'none',
      }}
    >
      <Box
        p={'21px 17px'}
        sx={{
          display: 'flex',
          flexDirection: {
            xl: 'row',
            lg: 'row',
            md: 'row',
            sm: 'column',
            xs: 'column',
          },
          justifyContent: {
            xl: 'space-between',
            lg: 'space-between',
            md: 'space-between',
            sm: '',
            xs: '',
          },
          alignItems: {
            xl: 'center',
            lg: 'center',
            md: 'center',
            sm: '',
            xs: '',
          },
        }}
      >
        {/* --- Navigation --- */}
        <NavigationTopbar />

        {/* --- User Select --- */}
        <UserSelect /> 
      </Box>
    </AppBar>
  );
};

export default TopbarHomepage;
