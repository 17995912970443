import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Box,
  InputLabel,
  IconButton,
  Stack,
} from "@mui/material";
import { IconClose } from "../../../assets/commons";
import { getLanguage } from "../../../features/language";
import { useSelector } from "react-redux";

/**
 * InputFile component for selecting and displaying a file.
 * @component
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.icon - The icon to display alongside the input.
 * @param {string} props.label - The label for the input.
 * @param {string} props.accept - The accepted file types (e.g., ".gif,.png").
 * @param {string[]} props.allowedTypes - The allowed file types (e.g., ["gif", "png", "pdf"]).
 * @param {FileList|null} props.value - The selected file. Pass null for no file.
 * @param {function} props.onChange - The callback function called when the file selection changes.
 *
 * @example
 * // Example usage of InputFile component
 * <InputFile
 *   icon={<YourIconComponent />}
 *   label="File"
 *   accept=".gif,.png,.pdf,.txt,.doc,.docx"
 *   allowedTypes={["gif", "png", "pdf", "txt", "doc", "docx"]}
 *   value={selectedFile}
 *   onChange={handleFileChange}
 * />
 */
const InputFile = ({
  icon,
  accept,
  label,
  allowedTypes = [],
  value,
  setValue,
  onChange,
  multiple,
}) => {
  const lang = useSelector(getLanguage);
  const selectedFileArray = Array.from(value);
  const [previewUrls, setPreviewUrls] = useState([]);

  const handleFileChange = (event) => {
    const file = event.target.files;
    onChange(file);
  };

  useEffect(() => {
    const generatedPreviewUrls = Array.from(value).map((file) => {
      return URL.createObjectURL(file);
    });
    setPreviewUrls(generatedPreviewUrls);
  }, [value]);

  const handleRemoveFile = (fileRemoveIndex) => {
    setValue(selectedFileArray.filter((_, index) => index !== fileRemoveIndex));
  };
  const allowedTypesString = allowedTypes.join(", ");

  return (
    <Box>
      <InputLabel
        style={{
          fontFamily: "sans-serif",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "13px",
          lineHeight: "18px",
          letterSpacing: "1px",
          marginBottom: "6px",
        }}
      >
        {label}
      </InputLabel>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box
            sx={{
              borderRadius: "8px",
              background: "#F7F7F7",
              borderColor: "transparent",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 1,
              px: 1,
            }}
          >
            <Stack direction="row">
              <Box mx={"10px"}>{icon}</Box>
              <Typography
                sx={{
                  fontFamily: "sans-serif",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "13px",
                  lineHeight: "18px",
                  letterSpacing: "1px",
                }}
              >
                {selectedFileArray.map((e) => e.name).join(",")}
              </Typography>
            </Stack>
            <input
              accept={accept} // Specify the accepted file types if needed
              id="file-upload"
              multiple={multiple}
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <Box>
              <label htmlFor="file-upload">
                <Button
                  variant="contained"
                  component="span"
                  size="small"
                  sx={{
                    backgroundColor: "#ffffff",
                    color: "#171717",
                    fontSize: "10px",
                    fontWeight: "400",
                  }}
                >
                  Browse
                </Button>
              </label>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} alignSelf="center" display="flex" gap="12px">
          {selectedFileArray.length > 0 ? (
            selectedFileArray.map((file, index) => (
              <Box position="relative" width={50} key={index}>
                {file.type.includes("image") ? (
                  <img
                    alt="file preview"
                    src={previewUrls[index]}
                    width={50}
                    height={50}
                    style={{ borderRadius: "8px", objectFit: "cover" }}
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="none"
                    viewBox="0 0 50 50"
                  >
                    <rect width="50" height="50" fill="#51B15C" rx="8"></rect>
                    <path
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeWidth="2"
                      d="M33.003 22.336h-6.667"
                    ></path>
                    <path
                      stroke="#fff"
                      strokeWidth="2"
                      d="M11.668 18.268c0-1.177 0-1.765.093-2.256a5.334 5.334 0 014.25-4.25c.492-.094 1.081-.094 2.257-.094.515 0 .773 0 1.021.023 1.068.1 2.081.52 2.907 1.205.192.159.373.34.739.705l.733.734c1.088 1.088 1.632 1.632 2.283 1.993.357.2.737.357 1.13.47.718.203 1.487.203 3.024.203h.499c3.51 0 5.265 0 6.405 1.027.106.093.206.193.299.299 1.027 1.14 1.027 2.896 1.027 6.405v2.936c0 5.028 0 7.543-1.563 9.104-1.561 1.563-4.076 1.563-9.104 1.563h-5.333c-5.028 0-7.543 0-9.104-1.563-1.563-1.561-1.563-4.076-1.563-9.104v-9.4z"
                    ></path>
                  </svg>
                )}
                <IconButton
                  size="small"
                  onClick={() => handleRemoveFile(index)}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: "white",
                    p: "0px",
                  }}
                >
                  <IconClose />
                </IconButton>
              </Box>
            ))
          ) : (
            <Box>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"10px"}
                lineHeight={"14px"}
                letterSpacing={"1px"}
                color={"#171717"}
              >
                {lang === "Id" ? "Tidak ada yang dipilih" : "No File Chosen"}
              </Typography>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"8px"}
                lineHeight={"11px"}
                letterSpacing={"1px"}
                color={"#303030"}
              >
                {lang === "Id"
                  ? `Unggah hanya file ${allowedTypesString}`
                  : `Upload files only ${allowedTypesString}`}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default InputFile;
