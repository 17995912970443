import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  Stack,
  Avatar,
  Grid,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { Card, Modals, TextField } from "../../../../../components/atoms";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../../features/language";
import { IconClose } from "../../../../../assets/commons";
import {
  postMessageCandidate,
  postInviteCandidate,
  putCandidatetNextStage,
} from "../../../../../configs/axios/apiPath";
import { useNavigate, useParams } from "react-router-dom";

const ModalInvatation = ({
  isOpen,
  onClose,
  candidateId,
  candidateName,
  vacancyId,
  type = "applied",
}) => {
  const lang = useSelector(getLanguage);
  const [value, setValue] = useState(0);
  const [isLoadingReject, setIsLoadingReject] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const MenuRecruitment = [
    {
      title: "Document",
      judul: "Dokumen",
    },
    {
      title: "Job",
      judul: "Pekerjaan",
    },
    {
      title: "Note",
      judul: "Catatan",
    },

    {
      title: "Resume",
      judul: "Resume",
    },
  ];

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handlePost = async () => {
    setIsLoadingReject(true);
    try {
      setIsLoadingReject(false);
      await postInviteCandidate(vacancyId, {
        candidate_id: candidateId,
        message: value,
      });
      onClose();
    } catch (error) {
      setIsLoadingReject(false);
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    } finally {
      onClose();
      setIsLoadingReject(false);
    }
  };

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "background.paper",
        }}
      >
        <Stack
          direction="row"
          sx={{
            backgroundColor: "#51B15C",
            alignItems: "center",
            gap: "6px",
            paddingX: "20px",
            paddingY: "17px",
          }}
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            sx={{
              gap: "6px",
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4690_45693)">
                <path
                  d="M15.751 0.0982143C15.9474 0.241071 16.0278 0.431548 15.9921 0.669643L13.7063 14.3839C13.6766 14.5565 13.5813 14.6905 13.4206 14.7857C13.3373 14.8333 13.245 14.8571 13.1438 14.8571C13.0784 14.8571 13.0069 14.8423 12.9296 14.8125L8.88492 13.1607L6.72421 15.7946C6.61706 15.9315 6.47123 16 6.28671 16C6.20933 16 6.14385 15.9881 6.09028 15.9643C5.97718 15.9226 5.88641 15.8527 5.81796 15.7545C5.7495 15.6563 5.71528 15.5476 5.71528 15.4286V12.3125L13.4296 2.85714L3.88492 11.1161L0.358135 9.66964C0.137897 9.58631 0.0188492 9.42262 0.000992064 9.17857C-0.0109127 8.94048 0.0843254 8.76488 0.286706 8.65179L15.1438 0.0803571C15.2331 0.0267857 15.3284 0 15.4296 0C15.5486 0 15.6558 0.0327381 15.751 0.0982143Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_4690_45693">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <Typography sx={{ color: "white" }}>
              Send Invitation to {candidateName}
            </Typography>
          </Stack>
          <IconClose />
        </Stack>
        <Box
          sx={{
            paddingX: "20px",
            paddingTop: "45px",
            paddingBottom: "30px",
          }}
        >
          <TextField onChange={(e) => handleChange(e.target.value)} />
          <Stack
            direction="row"
            gap="10px"
            justifyContent="flex-end"
            marginTop="20px"
          >
            <Button onClick={onClose}>Back</Button>
            <LoadingButton
              loading={isLoadingReject}
              variant="contained"
              color="primary"
              onClick={handlePost}
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </Box>
    </Modals>
  );
};

export default ModalInvatation;
