import { Box } from "@mui/system";
import React from "react";
import {
  ChartAttendances,
  TableAttendanceCorrection,
} from "../../../../components/organisms/timesheet";

const TimesheetAttandanceCorrection = () => {
  return (
    <Box>
      <ChartAttendances />
      <TableAttendanceCorrection />
    </Box>
  );
};

export default TimesheetAttandanceCorrection;
