import { Box, Button, Typography, Stack } from "@mui/material";
import SelectYear from "../../../../molecules/year";
import { useState } from "react";
import { getLanguage } from "../../../../../features/language";
import { getCompany } from "../../../../../features/companies";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { Card, Pagination, RowPerPage, Search } from "../../../../atoms";
import { useEffect } from "react";
import { getListCandidates } from "../../../../../configs/axios/apiPath";
import { DataTableAllCandidate } from "../../../../../data/table/recruitment";
import TableComponent from "../../../../molecules/table/mainTable";
import {
  AllCandidateTbody,
  AllCandidateThead,
} from "../../../../molecules/table/tableRecruitment/allCandidate";

const TableCandidate = () => {
  const yearNow = moment().format("YYYY");
  const year = [];
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [selectedYear, setSelectedYear] = useState(yearNow);
  const [isLoading, setIsLoading] = useState(false);
  const [attendance, setattendance] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState("name");
  const [direction, setDirection] = useState("-");
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);
  const { id } = useParams();

  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }

  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          keyword: keyword,
          page: page,
          page_size: pageSize,
          year: selectedYear,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListCandidates(params);
          setLastPage(data?.page_info?.last_page);
          setTotal(data?.page_info?.total);
          setattendance(data?.candidates);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [page, pageSize, keyword, selectedYear, company]);
  return (
    <Card sx={{ marginTop: "20px" }}>
      <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
        <Box display={"flex"} justifyContent={"space-between"}>
          {/* --- Search --- */}
          <Search
            placeholder={
              lang === "Id"
                ? "Ketik disini untuk mencari"
                : "Type here to search"
            }
            setSearch={(e) => setKeyword(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />

          <Stack direction="row" alignItems={"center"} gap={5}>
            <SelectYear
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              year={year}
            />
          </Stack>
        </Box>

        {/* --- Table --- */}
        <Box sx={{ mt: "24px", mb: "16px" }}>
          <TableComponent
            isLoading={isLoading}
            tbody={attendance}
            TableHead={
              <AllCandidateThead
                thead={DataTableAllCandidate}
                setOrder={setOrder}
                setDirection={setDirection}
                direction={direction}
              />
            }
            TableBody={
              <AllCandidateTbody tbody={attendance} setData={setattendance} />
            }
            col={7}
          />
        </Box>

        {/* --- Pagination --- */}
        <Box display={"flex"} justifyContent={"end"}>
          <Stack direction="row">
            <RowPerPage
              setPageSize={setPageSize}
              pageSize={pageSize}
              setPage={setPage}
            />
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
          </Stack>
        </Box>
      </Box>
    </Card>
  );
};

export default TableCandidate;
