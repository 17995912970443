import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const CustomDatePicker = ({ datePicker, handleChangeDate, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={datePicker}
        onChange={(value) => handleChangeDate(value)}
        inputRef={{
          border: 'none',
        }}
        sx={{
          background: '#F7F7F7',
          borderRadius: '8px',
          paddingLeft: '4px',
          width: '140px',
        }}
        // format="DD/MMM/YY"
        slotProps={{ textField: { variant: 'standard' } }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
