import { Avatar, Box, Stack, Typography } from "@mui/material";
import { TooltipsAction } from "../../../../../atoms";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  IconDelete,
  IconDetail,
  IconEdit,
} from "../../../../../../assets/commons";
import { BenefitEmployeeFacillitiesDetail } from "../../../../../../pages/benefit";
import { ModalDelete } from "../../../../modal";
import { handleAction } from "../../../../../../utils/function/action";
import { getEmployeeFacillitiesById } from "../../../../../../configs/axios/apiPath";
const OfficeFacillitiesTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idEmployee, setIdEmployee] = useState(null);
  const [modal, setModal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState();
  const [id, setId] = useState(null);

  const handleEdit = (id) => {
    navigate(`edit/${id}`);
  };

  // --- Handle Detail ---
  const handleDetail = async (id) => {
    setIsModalOpen(true);
    setId(id);
    setModal("detail");
  };


  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getEmployeeFacillitiesById(id);
          setDetail(data)
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("data");
            navigate(`/`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [id]);
  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* Employee name */}
            <td
              style={{
                padding: "10px",
                verticalAlign: "middle",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap="10px"
                justifyContent="center"
              >
                <Avatar src={item?.employee.profile_picture} />
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={400}
                  fontSize={"14px"}
                  lineHeight={"130%"}
                  color={"#171717"}
                >
                  {item?.employee.name}
                </Typography>
              </Stack>
            </td>
            {/* --- Office Item --- */}
            <td
              style={{
                padding: "10px",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
                textAlign="center"
              >
                {item.office_item}
              </Typography>
            </td>

            {/* --- Start Date --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
                textAlign="center"
              >
                {moment(item?.start_date).format("D MMM")}
              </Typography>
            </td>
            {/* --- Receive Date --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {moment(item?.end_date).format("D MMM")}
              </Typography>
            </td>

            {/* --- Action --- */}
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                {/* --- Delete --- */}
                <TooltipsAction title="Delete" arrow placement={"bottom-end"}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        "delete"
                      )
                    }
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>
                {/* --- Edit --- */}
                <TooltipsAction title="Edit" arrow placement={"bottom-end"}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleEdit(item.id)}
                  >
                    <IconEdit />
                  </Box>
                </TooltipsAction>
                {/* Detail */}
                <TooltipsAction title="Detail" arrow placement={"bottom-end"}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleDetail(item.id)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
      {modal === "detail" ? (
        <BenefitEmployeeFacillitiesDetail
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          detail={detail}
          isLoading={isLoading}
        />
      ) : modal === "delete" ? (
        <ModalDelete
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={"Employee Facillities"}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          id={idEmployee}
          data={tbody}
          setData={setData}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default OfficeFacillitiesTbody;
