import { API } from "../../../API";

/** Chart */
export const getChartProgressProject = (params) => API.get('projects/summary', {params: params});

/** List Project */
export const getListProject = (params) => API.get('projects', {params: params});

/** Show Project by projectId*/
export const showProject = (id) => API.get(`projects/${id}`);

/** Create New Project */
export const createNewProject = (data) => API.post('projects', data);

/** Update Progress Project */
export const updateProgressProject = (id, data) => API.put(`projects/${id}/members`, data);