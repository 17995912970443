import { Avatar, Box, Button, Divider, IconButton, TextField, Typography } from "@mui/material"
import { Search } from "../../../../atoms"
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import AvatarGroupStyled from "../../../../molecules/avatar/avatarGroup";
import { BackgroundBoards } from "../../../../../assets/project/img";
import CardUploadDocumentOfTask from "../../../../molecules/card/project/CardUploadDocument";
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import ActivityLog from "../../../../molecules/log/activity";
import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate, useSearchParams } from "react-router-dom";
import { showDetailTask, postAttachmentProject, postCommentTask } from "../../../../../configs/axios/apiPath/fetchProject";
import DataLogin from '../../../../../data/dataLogin';
import { IconGreenSend, IconLinkSend, IconPicSend } from "../../../../../assets/project/icons"
import { getLanguage } from '../../../../../features/language';

const MainDetailTask = ({projectId, boardId, taskId}) => {
  const navigate = useNavigate();
  const getData = DataLogin();
  const MAX_IMAGE_SIZE = 1 * 1024 * 1024;
  const lang = useSelector(getLanguage);

  const [searchParams] = useSearchParams();

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [detailTask, setDetailtask] = useState([])
  const [memberTask, setMembertask] = useState([])

  const [errorMsg, setErrorMsg] = useState('');
  const [file, setFile] = useState('');
  const [message, setMessage] = useState('')


  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      try {
        const {
          data: { data },
        } = await showDetailTask(projectId, boardId, taskId)
        setDetailtask(data?.task)
        setMembertask(data?.task?.members)     
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  },[projectId, boardId, taskId, navigate])


  const handleFileUpload = (taskId) => {
    return new Promise(async (resolve, reject) => {
      try {
        var postData;
        if (file !== '') {
          const formData = new FormData();
          formData.append('file', file);
          const {
            data : {data}
          } = await postAttachmentProject(formData, getData.token);
           postData = {
            "message" : message,
            "attachment_id" : data.id
          }
        } else {
           postData = {
            "message" : message,
            "attachment_id" : ""
          }
        }
        
        await postCommentTask(searchParams.get('px'), searchParams.get('bx'), window.location.pathname[window.location.pathname.length - 1], postData)
        resolve()
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
        reject(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

    // --- Handle Upload Image ---
  const handleFileChange = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (file) {
      // Validasi tipe file
      if (!file.type.startsWith('image/')) {
        const msgId = 'Jenis file tidak valid. Hanya file gambar yang diizinkan.'
        const msgEn = 'Invalid file type. Only image files are allowed.';
        if (lang === 'Id'){
          setErrorMsg(msgId) 
        } else {
          setErrorMsg(msgEn)
        }
        return;
      }

      // Validasi ukuran file
      if (file.size > MAX_IMAGE_SIZE) {
        const msgId = 'Ukuran file melebihi batas maksimum. Silakan pilih file yang lebih kecil.'
        const msgEn =
          'File size exceeds the maximum limit. Please choose a smaller file.';
        if (lang === 'Id'){
          setErrorMsg(msgId) 
        } else {
          setErrorMsg(msgEn)
        }
        return;
      }
      setFile(file);
    }
  };


  
  return(
    <Box 
      display={'flex'}
      flexDirection={'column'}
      sx={{
        width: 'full',
        height: '100%', 
        backgroundColor: 'white',
      }}
    >
      <Box 
        display={'flex'} 
        justifyContent={'space-between'} 
        alignItems={'center'} 
        width={'full'} 
        height={'45px'} 
        padding={'20px'}
      >
        <Search
          placeholder="Type here to search"
          page={page}
          setPage={(e) => setPage(e)}
        />
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '10px',
              color: '#171717'
            }}
          >
            Task created {` ${detailTask.created_at} `} by
          </Typography>
          <Avatar
            alt={memberTask?.[0]?.name}
            src={memberTask?.[0]?.profile_picture}
            sx={{ width: 20, height: 20 }}
          />
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '10px',
              color: '#171717'
            }}
          >
            {memberTask?.[0]?.name}
          </Typography>
        </Box>
      </Box>
      <Divider/>

      <Box 
        display={'flex'} 
        justifyContent={'space-between'} 
        alignItems={'center'} 
        width={'full'} 
        height={'45px'} 
        marginBottom={'20px'} 
        padding={'20px'}
      >
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '24px',
            color: '#171717'
          }}
        >
          {detailTask.name}
        </Typography>
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <AvatarGroupStyled
            width={'26px'}
            height={'26px'}
            maxShow={4}
            avatarData={memberTask}
          />
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '16px',
              color: '#171717'
            }}
          >
           Other members
          </Typography>
        </Box>  
      </Box>

      <Box paddingX={'20px'} marginBottom={'20px'} justifyContent={'center'}>
        <Box
          sx={{
            backgroundImage: `url(${BackgroundBoards})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            borderRadius: '10px',
            height: "300px",
            width: 'full',
          }}
        />
      </Box>

      <Box paddingX={'20px'} marginBottom={'20px'} justifyContent={'center'}>
        <CardUploadDocumentOfTask/>
      </Box>

      <Box
        display={'flex'} 
        justifyContent={'space-between'} 
        alignItems={'center'} 
        width={'full'} 
        paddingX={'20px'} 
        marginBottom={'20px'}
      >
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <EqualizerOutlinedIcon sx={{width: '30px', height: '30px'}}/>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '16px',
              color: '#171717'
            }}
          >
            Activity
          </Typography>
        </Box>
        <Button sx={{backgroundColor: "#F7F7F7", height: '30px', padding: '10px'}}>
        <Typography
            sx={{
              fontWeight: '600',
              fontSize: '10px',
              color: '#303030',
              textTransform: 'none'
            }}
          >
            Hide Details
          </Typography>
        </Button>
      </Box>

      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        paddingX={'20px'} 
        marginBottom={'20px'}
        height={'800px'}
        overflow={'scroll'}
      >
        {
          detailTask?.activity?.map((item, idx) => (
            <ActivityLog
              key={idx}
              picture={item.employee.profile_picture}
              name={item.employee.name}
              log={item.message}
              time={item.created_at}
              asset={item.attachment_id}
            />
          ))
        }
      </Box>
      <Box paddingX={'20px'} marginY={'10px'} justifyContent={'center'} width={'full'}>

        {
          file !== '' ?   <Box paddingX={'20px'} justifyContent={'center'} width={'full'} backgroundColor={'#C8C8CC26'}> 
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '16px',
              color: '#9D9D9D'
            }}
          >
            {file.name}
          </Typography>
        </Box> : 
        <div></div>

        }
      
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type Something..."
          onChange={e => setMessage(e.target.value)}
          margin="normal"
          InputProps={{
            startAdornment: (
              <IconButton edge="start" color="inherit" aria-label="microphone">
                <MicIcon />
              </IconButton>
            ),
            endAdornment: (
              <Box display={'flex'} gap={1} alignItems={'center'}>
               <IconButton edge="" color="inherit" aria-label="send">
                <IconLinkSend />
              </IconButton>
             
               <IconButton 
                  edge="" 
                  color="inherit"
                  aria-label="send" 
                  // variant="contained"
                  // disabled={isLoading}
                  component="label"
                  >
                  <IconPicSend />
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={handleFileChange}
                    />
              </IconButton>
                <IconButton edge="" color="inherit" aria-label="send" onClick={handleFileUpload}>
                < IconGreenSend />
              </IconButton>
                     
            </Box>
             
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              background: '#C8C8CC26',
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#51B15C',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#51B15C',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#C8C8CC26',
              },
            }
          }}
        />
      </Box>
    </Box>
  )
}

export default MainDetailTask