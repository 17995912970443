import { Box, IconButton, Typography } from "@mui/material";
import { IconDelete } from "../../../../../../assets/commons";

const EmployeeShiftTbody = ({ tbody }) => {
  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Profile --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item?.profile}
              </Typography>
            </td>

            {/* --- Shift --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item?.shift}
              </Typography>
            </td>
            {/* --- Status --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box
                  sx={{
                    width: "88px",
                    borderRadius: "6px",
                    py: "6px",
                    border:
                      item?.status === "Active"
                        ? " 0.4px solid #51B15C"
                        : "0.4px solid #FF1D1D",
                    background:
                      item?.status === "Active"
                        ? "rgba(81, 177, 92, 0.2)"
                        : "rgba(255, 29, 29, 0.2)",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={600}
                    fontSize={"10px"}
                    lineHeight={"12px"}
                    color={item?.status === "Active" ? "#51B15C" : "#FF1D1D"}
                  >
                    {item?.status}
                  </Typography>
                </Box>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

export const EmployeeOneTimeShiftTbody = ({ tbody, handleDelete }) => {
  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Profile --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item?.profile}
              </Typography>
            </td>

            {/* --- Shift --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item?.shift}
              </Typography>
            </td>
            {/* --- Status --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                position={"relative"}
              >
                <IconButton
                  sx={{
                    p: "0px",
                    position: "absolute",
                    top: -10,
                    right: "auto",
                    marginLeft: "85px",
                  }}
                  onClick={(event) => handleDelete(item.id, event)}
                >
                  <IconDelete />
                </IconButton>
                <Box
                  sx={{
                    width: "88px",
                    borderRadius: "6px",
                    py: "6px",
                    border:
                      item?.status === "Active"
                        ? " 0.4px solid #51B15C"
                        : "0.4px solid #FF1D1D",
                    background:
                      item?.status === "Active"
                        ? "rgba(81, 177, 92, 0.2)"
                        : "rgba(255, 29, 29, 0.2)",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={600}
                    fontSize={"10px"}
                    lineHeight={"12px"}
                    color={item?.status === "Active" ? "#51B15C" : "#FF1D1D"}
                  >
                    {item?.status}
                  </Typography>
                </Box>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

export default EmployeeShiftTbody;
