import { Box, Typography } from '@mui/material';
import { TooltipsAction } from '../../../../../atoms';
import {
  handleTooltipClose,
  handleTooltipOpen,
} from '../../../../../../utils/function/tooltips';
import moment from 'moment';
import { useState } from 'react';

const EmployeeAttendancesTbody = ({ tbody, setData }) => {
  const [idTooltipStatus, setIdTooltipStatus] = useState('');
  const [isTooltipStatus, setIsTooltipStatus] = useState(false);

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Date --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {moment(item?.date).format('DD MMM')}
              </Typography>
            </td>
            {/* --- Status --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              {/* --- Status --- */}
              {/* <TooltipsAction
                title={item?.status}
                open={isTooltipStatus && item.status === idTooltipStatus}
                arrow
                placement={'bottom-end'}
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onMouseEnter={() =>
                    handleTooltipOpen(
                      item.status,
                      setIsTooltipStatus,
                      setIdTooltipStatus
                    )
                  }
                  onMouseLeave={() => handleTooltipClose(setIsTooltipStatus)}
                > */}
              <img
                src={item?.status_icon}
                alt={item?.status}
                width={30}
                height={30}
                style={{ borderRadius: '50%' }}
              />
              {/* </Box>
              </TooltipsAction> */}
            </td>
            {/* --- Clock In --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.clock_in}
              </Typography>
            </td>
            {/* --- Clock Out --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.clock_out}
              </Typography>
            </td>
            {/* --- Overtime --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.overtime}
              </Typography>
            </td>
            {/* --- Late --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.late}
              </Typography>
            </td>
            {/* --- Schedule --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.schedule}
              </Typography>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

export default EmployeeAttendancesTbody;
