import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Menu,
} from "@mui/material";
import { useState } from "react";
import { SelectTimeFrequency } from "../../../../../data/select";

const SelectFrequency = () => {
  const frequency = SelectTimeFrequency;

  const [selectedFrequency, setSelectedFrequency] = useState(frequency[0]);

  // --- Hande Change ---
  const handleChange = (event) => {
    const selected = event.target.value;
    setSelectedFrequency(selected);
  };

  return (
    <Box display={"flex"} justifyContent={"center"}>
      <Select
        value={selectedFrequency}
        onChange={handleChange}
        size="small"
        sx={{
          ".MuiSelect-root": {
            border: "1px solid red",
            verticalAlign: "middle",
          },

          ".MuiSelect-select p": {
            zIndex: 999,
          },

          "& .MuiSelect-outlined": {
            backgroundColor: "#F7F7F7",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            /* backgroundColor: "#F7F7F7", */
            borderColor: "transparent",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "transparent",
          },
        }}
      >
        {frequency &&
          frequency.map((item, idx) => (
            <MenuItem value={item} key={idx}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"14px"}
                  lineHeight={"16px"}
                  letterSpacing={"0.02em"}
                  color={"#171717"}
                  textAlign={"center"}
                >
                  {item}
                </Typography>
              </Box>
            </MenuItem>
          ))}
      </Select>
    </Box>
  );
};

export default SelectFrequency;
