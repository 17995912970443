const DataTableCompanyPolicy = [
    {
      title: 'Location Name',
      judul: 'Nama Lokasi',
      url: 'name',
    },
    {
      title: 'Description',
      judul: 'Deskripsi',
      url: 'desc',
    },
    {
      title: 'Created Date',
      judul: 'Tanggal Dibuat',
      url: 'created_at',
    },
    {
      title: 'Action',
      judul: 'Aksi',
      url: 'name',
    },
  ];
  
  export default DataTableCompanyPolicy;