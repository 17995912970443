import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getListPayrollPeriods } from '../../../../configs/axios/apiPath';
import { getCompany } from '../../../../features/companies';
import moment from 'moment';

const SelectPayrollPeriods = ({ setId }) => {
  const path = useLocation().pathname;
  const splitPath = path.split('/');
  const id = splitPath[4];

  const company = useSelector(getCompany);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [total, setTotal] = useState(pageSize);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const parsedData = JSON.parse(localStorage.getItem('cvSayaData'));
  const idPayroll = parsedData && parsedData.idPayroll;

  const [selectedPayroll, setSelectedPayroll] = useState(idPayroll);

  // --- Hande Change ---
  const handleChange = (event) => {
    const selected = event.target.value;
    const selectedId = selected.id;

    let parsedData = JSON.parse(localStorage.getItem('cvSayaData'));

    if (!parsedData) {
      parsedData = { payrollPeriods: selected };
    } else {
      parsedData.payrollPeriods = selected;
    }

    localStorage.setItem('cvSayaData', JSON.stringify(parsedData));
    setId(selectedId);
    setSelectedPayroll(selected);

    const newPath = `/payroll/payroll-period/detail/${selectedId}`;
    navigate(newPath);
  };

  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          company_id: company,
          page_size: total,
          page: page,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListPayrollPeriods(params);
          setData(data?.payroll);
          setTotal(data?.page_info?.total);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [company, total]);

  return (
    <Box display={'flex'} justifyContent={'center'}>
      <FormControl>
        <Select
          value={selectedPayroll}
          onChange={handleChange}
          sx={{
            height: '30px',
            '.MuiSelect-root': {
              border: '1px solid red',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          }}
        >
          {isLoading ? (
            <MenuItem disabled>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <CircularProgress size={20} />
              </Box>
            </MenuItem>
          ) : (
            data &&
            data.map((item, idx) => (
              <MenuItem value={item} key={idx}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontWeight={600}
                    fontSize={'10px'}
                    lineHeight={'12px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                  >
                    {item?.name} (
                    {moment(item?.started_at).format('DD/MMM/YYY')} /{' '}
                    {moment(item?.ended_at).format('DD/MMM/YYY')})
                  </Typography>
                </Box>
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectPayrollPeriods;
