const DataTableRequest = [
  {
    title: "Name",
    judul: "Nama",
  },
  {
    title: "Status",
    judul: "Status",
  },
  {
    title: "Jobs",
    judul: "Pekerjaan",
  },
  {
    title: "Dept",
    judul: "Dept",
  },
  {
    title: "Action",
    judul: "Aksi",
  },
];

export default DataTableRequest;
