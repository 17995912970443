import { API } from "../../../API";

// --- List Announcement ---
export const getListOfficeFacillities = (params) =>
  API.get("office-facilities", { params: params });

export const getTrackOfficeFacillitiesById = (params,id) =>
  API.get(`office-facilities/${id}/tracks`, { params: params });

export const getDocumentOfficeFacillitiesById = (params,id) =>
  API.get(`documents/${id}`, { params: params ,responseType:"blob"});

export const deleteDocumentOfficeFacillitiesById = (id) =>
  API.delete(`documents/${id}`);

// --- Post Announcement ---
export const postOfficeFacillities = (data) => API.post("office-facilities", data);

export const uploadOfficeFacillities = (data) =>
  API.postForm("facilities/upload", data);

// --- Get Announcement By Id ---
export const getOfficeFacillitiesById = (id) => API.get(`office-facilities/${id}`);

// --- Update Announcement By Id ---
export const updateOfficeFacillitiesById = (data, id) =>
  API.put(`office-facilities/${id}`, data);

// --- Delete Announcement By Id ---
export const deleteOfficeFacillitiesById = (id) =>
  API.delete(`office-facilities/${id}`);
