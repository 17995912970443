import { Tooltip } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

const TooltipsAction = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      width: 'auto',
      height: 'auto',
      boxShadow: theme.shadows[1],
      fontSize: 14,
      padding: '21px',
      fontFamily: 'sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '19px',
      letterSpacing: '1px',
      maxHeight: '360px',
      overflowY: 'auto',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.primary,
    },
}));

export default TooltipsAction;