import React from 'react';
import { Box } from '@mui/material';
import { ChartAttendance } from '../../../components/organisms/timesheet';
import { PerformanceCard, PerformancePieCard } from '../../../components/organisms/employee/employeePerformance';

const EmployeePerformance = () => {
  return (
    <Box mt={'20px'}>
      <PerformanceCard />
      <PerformancePieCard/>
        <Box my={'30px'}>
          <ChartAttendance />
        </Box>
    </Box>
  );
};

export default EmployeePerformance;

