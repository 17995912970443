export const DataPayrollEmployeePayslip = [
    {
        title: 'Name',
        judul: 'Nama',
    },
    {
        title: 'Position',
        judul: 'Posisi',
    },
    {
        title: 'Basic Fee',
        judul: 'Gaji Pokok',
    },
    {
        title: 'Meal Allowance',
        judul: 'Tunjangan Makan',
    },
    {
        title: 'Overtime Fee',
        judul: 'Gaji Lembur',
    },
    {
        title: 'Late',
        judul: 'Keterlambatan',
    },
    {
        title: 'Others*',
        judul: 'Lainnya*',
    },
    {
        title: 'THP*',
        judul: 'THP*',
    },
    {
        title: 'Status',
        judul: 'Status',
    },
    {
        title: 'Action',
        judul: 'Aksi',
    },
    
]