import React from "react";
import { Box, Card, Typography } from "@mui/material";
import { Modals } from "../../../atoms";
import { IconDeleteModal } from "../../../../assets/commons";
import { handleModalDeletePath } from "../../../../utils/function/modal";
import { useNavigate } from "react-router-dom";

const ModalDelete = ({
  isOpen,
  onClose,
  title,
  isLoading,
  setIsLoading,
  id,
  data,
  setData,
  onSuccess,
  company,
}) => {
  const navigate = useNavigate();

  const handleDelete = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    try {
      const response = await handleModalDeletePath(title, company, id);

      if (response.data.meta.code === 20000) {
        onClose();
        const newData = [...data];
        const filteredData = newData.filter((item) => item.id !== id);
        setData(filteredData);
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
      if (onSuccess) {
        onSuccess();
      }
    }
  };

  const handleNull = () => {};

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Card>
          <Box p={"30px"}>
            {/* --- Header --- */}
            <Box display={"flex"} alignItems={"center"} mb={"7px"}>
              <IconDeleteModal />
              <Typography
                fontFamily={"sans-serif"}
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"150%"}
                color={"#171717"}
                ml={"10px"}
              >
                Delete {title}
              </Typography>
            </Box>

            {/* --- Content --- */}
            <Box>
              <Typography
                fontFamily={"sans-serif"}
                fontSize={"16px"}
                fontWeight={400}
                lineHeight={"150%"}
                letterSpacing={"0.02em"}
                color={"#171717"}
              >
                Are you sure want to delete it? You can't undo this action.
              </Typography>
              <Box
                display={"flex"}
                justifyContent={"end"}
                fontFamily={"sans-serif"}
                fontSize={"14px"}
                fontWeight={600}
                lineHeight={"150%"}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"100px"}
                  height={"40px"}
                  sx={{ cursor: "pointer" }}
                  onClick={onClose}
                >
                  <Typography color={"#9E9D9D"}>Cancel</Typography>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"100px"}
                  height={"40px"}
                  borderRadius={"8px"}
                  sx={{
                    background: isLoading ? "#fff" : "#F33A3A",
                    cursor: "pointer",
                  }}
                  onClick={!isLoading ? handleDelete : handleNull}
                >
                  <Typography color={isLoading ? "#9E9D9D" : "#fff"}>
                    Delete
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    </Modals>
  );
};

export default ModalDelete;
