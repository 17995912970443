import { Box, MenuItem, Select } from "@mui/material"
import { ProjectStatus } from "../../../../data/select"
import handleProjectLabel from "../../../../utils/format/projectLabel";

const SelectProjectStatus = ({selectedStatus, setSelectedStatus}) => {

  const statusInfo = handleProjectLabel(selectedStatus);

  const handleChange = (event) => {
    setSelectedStatus(event.target.value);
  }

  return(
    <Select
      value={statusInfo.label}
      onChange={handleChange}
      sx={{
        height: '30px',
        paddingX: '5px',
        borderRadius: '10px',
        backgroundColor: statusInfo.color,
        color: 'white',
        '& .MuiSelect-icon': {
          color: 'white',
        },
        '& .MuiInputBase-root': {
          outline: 'none',
        },
      }}
    >
      {
        ProjectStatus.map((item, idx) => (
          <MenuItem value={item} key={idx}>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              {item}
            </Box>
          </MenuItem>
        ))
      }
    </Select>
  )
}

export default SelectProjectStatus