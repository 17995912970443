import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  IconDelete,
  IconDetail,
  IconEdit,
} from "../../../../../../assets/commons";
import { TooltipsAction } from "../../../../../atoms";
import {
  handleTooltipClose,
  handleTooltipOpen,
} from "../../../../../../utils/function/tooltips";
import { handleAction } from "../../../../../../utils/function/action";
import { ModalDelete } from "../../../../modal";
import CoreHRAnnouncementDetail from "../../../../../../pages/coreHR/announcement/detail";
import { getAnnouncementById } from "../../../../../../configs/axios/apiPath";

const AnnouncementTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [idEmployee, setIdEmployee] = useState(null);
  const [detail, setDetail] = useState([]);
  const [dept, setDept] = useState([]);

  const [idTooltipDetail, setIdTooltipDetail] = useState("");
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);
  const [idTooltipDelete, setIdTooltipDelete] = useState("");
  const [isTooltipDelete, setIsTooltipDelete] = useState(false);
  const [idTooltipEdit, setIdTooltipEdit] = useState("");
  const [isTooltipEdit, setIsTooltipEdit] = useState(false);
  const [idTooltipDept, setIdTooltipDept] = useState("");
  const [isTooltipDept, setIsTooltipDept] = useState(false);

  // --- Handle Edit ---
  const handleEdit = (id) => {
    navigate(`/core-hr/announcement/edit/${id}`);
  };

  useEffect(() => {
    async function fetchDataDetail() {
      if (idEmployee !== null) {
        setIsLoading(true);

        try {
          const {
            data: { data },
          } = await getAnnouncementById(idEmployee);
          setDetail(data);
          setDept(data?.departments);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchDataDetail();
  }, [idEmployee]);

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Title --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "start",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item.title}
              </Typography>
            </td>
            {/* --- Dept --- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"wrap"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {item.departments.map((detail, idx) => (
                  <TooltipsAction
                    title={detail.name}
                    open={isTooltipDept && item.id === idTooltipDept}
                    arrow
                    placement={"bottom-end"}
                    key={idx}
                  >
                    <Box
                      sx={{ cursor: "pointer" }}
                      onMouseEnter={() =>
                        handleTooltipOpen(
                          item.id,
                          setIsTooltipDept,
                          setIdTooltipDept
                        )
                      }
                      onMouseLeave={() => handleTooltipClose(setIsTooltipDept)}
                    >
                      <img
                        src={detail.icon_url}
                        alt={detail.name}
                        width={30}
                        style={{ borderRadius: "50%" }}
                      />
                    </Box>
                  </TooltipsAction>
                ))}
              </Box>
            </td>
            {/* --- Date --- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {moment(item?.implementation_date).format("D MMM")}
              </Typography>
            </td>
            {/* --- Action --- */}
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                {/* --- Detail --- */}
                <TooltipsAction
                  title="Detail"
                  open={isTooltipDetail && item.id === idTooltipDetail}
                  arrow
                  placement={"bottom-end"}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        "detail"
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDetail,
                        setIdTooltipDetail
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDetail)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>

                {/* --- Delete --- */}
                <TooltipsAction
                  title="Delete"
                  open={isTooltipDelete && item.id === idTooltipDelete}
                  arrow
                  placement={"bottom-end"}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        "delete"
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDelete,
                        setIdTooltipDelete
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDelete)}
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>

                {/* --- Edit --- */}
                <TooltipsAction
                  title="Edit"
                  open={isTooltipEdit && item.id === idTooltipEdit}
                  arrow
                  placement={"bottom-end"}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleEdit(item.id)}
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipEdit,
                        setIdTooltipEdit
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipEdit)}
                  >
                    <IconEdit />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
      {/* --- Modal Handle --- */}
      {idEmployee !== null && modal === "detail" ? (
        <CoreHRAnnouncementDetail
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          detail={detail}
          isLoading={isLoading}
          dept={dept}
        />
      ) : modal === "delete" ? (
        <ModalDelete
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={"Announcement"}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          id={idEmployee}
          data={tbody}
          setData={setData}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AnnouncementTbody;
