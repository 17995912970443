import React from 'react';
import { Box, Modal } from '@mui/material';

const Modals = ({ open, onClose, children, sx, ...props }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={[
        {
          boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1);',
        },
        { ...sx },
      ]}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default Modals;