import { API } from "../../../API";

/** Show Detail Task */
export const showDetailTask = (projectId, boardId, taskId) => API.get(`projects/${projectId}/boards/${boardId}/tasks/${taskId}`);

/** --- Post Task */
export const postTask = (projectId, boardId, data) => API.post(`projects/${projectId}/boards/${boardId}/tasks`, data);

/** --- Post Task Design*/
export const postTaskDesign = (projectId, boardId, taskId, data) => API.post(`projects/${projectId}/boards/${boardId}/tasks/${taskId}/designs`, data);

// Post Comment Task
export const postCommentTask = (projectId, boardId, taskId, data) => API.post(`projects/${projectId}/boards/${boardId}/tasks/${taskId}/comments`, data);