import { Box, Typography } from '@mui/material';

const ProjectHandle = ({ tag, projectMenu, setTag }) => {
  const handleClick = (e) => {
    setTag(e.toLowerCase());
  };
  return projectMenu.map((item, idx) => (
    <Box
      sx={{
        padding: '4px 16px',
        backgroundColor:
          item === tag || item.toLowerCase() === tag ? '#51B15C' : '#F7F7F7',
        marginRight: '8px',
        borderRadius: '100px',
        color: item === tag || item.toLowerCase() === tag ? '#fff' : '#939393',
        cursor: 'pointer',
      }}
      key={idx}
    >
      <Typography
        onClick={() => handleClick(item)}
        name={idx}
        fontSize={10}
        fontWeight={600}
      >
        {item}
      </Typography>
    </Box>
  ));
};

export default ProjectHandle;
