import { Avatar, Box, Typography } from '@mui/material';
import { IconDelete, IconEdit } from '../../../../assets/commons';
import { IconChat } from '../../../../assets/project/icons';
import { showAttachmentProject } from '../../../../configs/axios/apiPath/fetchProject';
import { useEffect, useState } from 'react';
import DataLogin from '../../../../data/dataLogin';
const getData = DataLogin();

const ActivityLog = ({ picture, name, log, time, asset }) => {
  const [image, setImage] = useState('');

  useEffect(() => {
    async function fetchDataImage(id) {
      try {
        const response = await showAttachmentProject(
          id,
          '',
          '330',
          getData.token
        );
        const contentType = response.headers['content-type'];
        const blob = new Blob([response.data], { type: contentType });
        const objectURL = URL.createObjectURL(blob);
        setImage(objectURL);
      } catch (error) {
        // if (error.response.status === 401) {
        //   localStorage.removeItem("cvSayaLogin");
        //   navigate(`/users`);
        // }
      } finally {
        // setIsLoading(false)
      }
    }
    fetchDataImage(asset);
  }, []);

  return (
    <Box width={'full'} alignItems={'center'} paddingY={'5px'}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={'full'}
      >
        <Box display={'flex'} gap={2} alignItems={'center'}>
          <Avatar
            alt={name}
            src={picture}
            sx={{
              width: '30px',
              height: '30px',
            }}
          />
          {asset ? (
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '14px',
                  color: '#171717',
                }}
              >
                {name}
              </Typography>
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#171717',
                }}
              >
                {log}
              </Typography>
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '10px',
                  color: '#939393',
                }}
              >
                {time}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Box display={'flex'} gap={1}>
                <Typography
                  sx={{
                    fontWeight: '600',
                    fontSize: '14px',
                    color: '#171717',
                  }}
                >
                  {name}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '400',
                    fontSize: '14px',
                    color: '#171717',
                  }}
                >
                  {log}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '10px',
                  color: '#939393',
                }}
              >
                {time}
              </Typography>
            </Box>
          )}
        </Box>
        {asset && (
          <Box display={'flex'} gap={1} alignItems={'center'}>
            <IconChat />
            <IconDelete />
            <IconEdit />
          </Box>
        )}
      </Box>
      {asset && (
        <Box paddingX={'60px'} marginTop={'10px'}>
          <Box
            sx={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              borderRadius: '10px',
              width: 'full',
              height: '330px',
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ActivityLog;
