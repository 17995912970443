import { ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import Routing from "./routes";
import { Theme } from "./configs/theme";
import Store from "./features/store";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <Provider store={Store}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Routing />
        </LocalizationProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
