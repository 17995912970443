const DataTablePermission = [
  {
    title: "Name",
    judul: "Nama",
    url: "title",
  },
  {
    title: "Description",
    judul: "Deskripsi",
    url: "title",
  },
  {
    title: "Start",
    judul: "Tanggal Mulai",
    url: "date",
  },
  {
    title: "End",
    judul: "Tanggal Selesai",
    url: "date",
  },
  {
    title: "Status",
    judul: "Status",
    url: "title",
  },
  {
    title: "Action",
    judul: "Aksi",
    url: "title",
  },
];

export default DataTablePermission;
