export default function capitalizeFirstLetter(sentence) {
  if (sentence) {
    const words = sentence.split(' ');

    const capitalizedWords = words.map((word) => {
      const firstLetter = word.charAt(0).toUpperCase();
      const restOfWord = word.slice(1);
      return firstLetter + restOfWord;
    });

    const capitalizedSentence = capitalizedWords.join(' ');

    return capitalizedSentence;
  }
}
