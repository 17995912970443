import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Box,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
  Stepper,
  Step,
  StepButton,
} from "@mui/material";
import { IconBack } from "../../../../assets/employee";
import {
  IconMinus,
  IconPlusTwo,
  IconPlusActive,
  IconMinusNonActive,
} from "../../../../assets/commons";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import debounce from "lodash/debounce";
import { Buttons, TooltipsAction } from "../../../../components/atoms";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  getEmployeeCandidates,
  getEmployeeTypes,
  postEmployees,
} from "../../../../configs/axios/apiPath/fetchEmployee/fetchEmployeeManage";
import { getDepartmentByCompany } from "../../../../configs/axios/apiPath/fetchOrganization/fetchDepartment";
import { getCompany } from "../../../../features/companies";
import { useSelector } from "react-redux";
import {
  getListPosition,
  getPositiontByCompany,
} from "../../../../configs/axios/apiPath/fetchCommon/fetchListPosition";

import { styled } from "@mui/material/styles";
import FirstPage from "./first-step";
import SecondPage from "./second-step";
import ThirdPage from "./third-step";
import FourthPage from "./fourth-step";
import { useNavigate } from "react-router-dom";

const steps = [
  "Personal Data",
  "Family Member",
  "Contactable family",
  "Family Structure",
];

export default function AddEmployeeLayout({ onChangeView }) {
  const [currentData, setCurrent] = useState({
    candidate_id: null,
    position_id: null,
    is_attendance_required: true,
    department_id: null,
    joined_at: null,
    employment_type_id: null,
    type: null,
    years_service: null,
  });

  const company = useSelector(getCompany);
  const navigate = useNavigate();

  const [isLoading, setLoadingSave] = useState(false);
  const [listEmployees, setEmployees] = useState([]);
  const [listDepartments, setDepartment] = useState([]);
  const [listPositions, setPositions] = useState([]);
  const [listEmployeeTypes, setEmployeeTypes] = useState([]);

  const [showAdditional, setShowAdditional] = useState(false);
  const [searchPosition, setSearchPosition] = useState("");

  const onSearchEmployees = debounce(async (values) => {
    const { data } = await getEmployeeCandidates(values);
    return setEmployees(data.data?.candidates || []);
  }, 700);

  const onSaveEmployees = async () => {
    setLoadingSave(true);
    try {
      const ok = await postEmployees(currentData);
      // back to list
      setLoadingSave(false);
      onChangeView("main");
      setCurrent({
        candidate_id: null,
        position_id: null,
        is_attendance_required: true,
        department_id: null,
        joined_at: null,
        employment_type_id: null,
        type: null,
        years_service: null,
      });
    } catch ({ data: data_1 }) {
      setLoadingSave(false);
      Snackbar({ message: "Failed to add employee" });
    }
  };

  const setCurrentData = (key, value) =>
    setCurrent({ ...currentData, [key]: value });

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  useEffect(() => {
    // getEmployeeCandidates()
    getEmployeeTypes("").then(({ data }) => setEmployeeTypes(data.data));

    fetchDataPosition(searchPosition);
  }, [company]);

  const fetchDataPosition = async (search) => {
    if (company) {
      const params = {
        page_size: 10,
        company_id: company,
        search: search,
      };
      setLoadingSave(true);
      try {
        const { data } = await getListPosition(params);
        const positions = data.data.positions.map((options) => ({
          id: options.id,
          label: options.name,
          company_id: company,
        }));
        setPositions(positions);
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setLoadingSave(false);
      }
    }
  };

  const debouncePosition = useRef(
    debounce((value) => {
      fetchDataPosition(value);
    }, 1000)
  ).current;

  const onGetDepartments = debounce((value) => {
    getDepartmentByCompany(company, value).then(({ data }) =>
      setDepartment(data.data?.departments || [])
    );
  }, 700);

  const renderSelectOptions = (data = [], { label, value }) =>
    data.map((a) => <MenuItem value={a[value]}>{a[label]}</MenuItem>);

  const FormLabel = ({ label }) => (
    <InputLabel
      style={{
        fontFamily: "sans-serif",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "22px",
        letterSpacing: "0.02em",
        color: "#171717",
        marginBottom: "10px",
      }}
    >
      {label}
    </InputLabel>
  );

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleSkip = () => {
    if (activeStep < 3) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const ColorConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#4FD240",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#4FD240",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  return (
    <Box>
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          columnGap={1.5}
          alignContent="center"
          alignItems="center"
          alig
        >
          <IconButton size="large" onClick={() => onChangeView("main")}>
            <IconBack />
          </IconButton>
          <h2>Add Employeee</h2>
        </Box>
        <hr color={"#F1F1F1"} width="100%" />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        rowGap="16px"
        width={"50%"}
        paddingTop={4}
      >
        <Box>
          <FormLabel label="Name" />
          <Autocomplete
            sx={{ sx: { width: "100%" } }}
            onChange={(v, value) => setCurrentData("candidate_id", value.id)}
            options={listEmployees.map((a) => ({ label: a.name, id: a.id }))}
            onInputChange={(e) => onSearchEmployees(e.target.value)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>

        <Box>
          <FormLabel label="Position" />
          <Autocomplete
            sx={{ sx: { width: "100%" } }}
            onChange={(v, value) => setCurrentData("position_id", value.id)}
            options={listPositions}
            onInputChange={(e, value) => debouncePosition(value)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>

        <Box>
          <FormLabel label="Employment Type" />
          <Select
            style={{ width: "100%" }}
            MenuProps={{ style: { maxHeight: 350 } }}
            value={currentData.employment_type_id}
            onChange={(v) =>
              setCurrentData("employment_type_id", v.target.value)
            }
          >
            {renderSelectOptions(listEmployeeTypes, {
              label: "name",
              value: "id",
            })}
          </Select>
        </Box>

        <Box>
          <FormLabel label="Type" />
          <Select
            style={{ width: "100%" }}
            MenuProps={{ style: { maxHeight: 350 } }}
            value={currentData.type}
            onChange={(v) => setCurrentData("type", v.target.value)}
          >
            {renderSelectOptions(
              [
                { id: "daily", name: "Daily" },
                { id: "monthly", name: "Monthly" },
              ],
              {
                label: "name",
                value: "id",
              }
            )}
          </Select>
        </Box>

        <Box>
          <FormLabel label="Join Date" />
          <DatePicker
            sx={{ width: "100%" }}
            onChange={(dt) => setCurrentData("joined_at", dt)}
            value={currentData.joined_at}
          />
        </Box>

        <Box>
          <FormLabel label="Years of service" />
          <DatePicker
            sx={{ width: "100%" }}
            onChange={(dt) => setCurrentData("years_service", dt)}
            value={currentData.years_service}
          />
        </Box>

        <Box>
          <FormLabel label="Attendance" />
          <Switch
            // sx={{ width: "100%" }}
            onChange={(dt) =>
              setCurrentData("is_attendance_required", dt.target.checked)
            }
            checked={currentData.is_attendance_required}
            value={currentData.is_attendance_required}
          />
        </Box>
      </Box>

      <Box>
        <Box
          display="flex"
          flexDirection="row"
          columnGap={1.5}
          alignContent="center"
          alignItems="center"
          justifyContent="space-between"
        >
          {/* <Box display="flex" alignItems="center">
            {" "}
            <h2>Add Personal Data</h2>
          </Box> */}

          {/* <Box display="flex" alignItems="center">
            <Box marginRight={"10%"} display="flex" alignItems="center">
              <TooltipsAction open={{}} arrow placement={"bottom-end"}>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => setShowAdditional(true)}
                >
                  {showAdditional ? <IconPlusTwo /> : <IconPlusActive />}
                </Box>
              </TooltipsAction>
            </Box>

            <Box display="flex" alignItems="center">
              <TooltipsAction open={{}} arrow placement={"bottom-end"}>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => setShowAdditional(false)}
                >
                  {showAdditional ? <IconMinus /> : <IconMinusNonActive />}
                </Box>
              </TooltipsAction>
            </Box>
          </Box> */}
        </Box>
        {/* <hr color={"#F1F1F1"} width="100%" /> */}

        {/* {showAdditional ? (
          <>
            <Box>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorConnector />}
                sx={{ marginTop: "30px" }}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Box>

            {activeStep === steps.length ? (
              <></>
            ) : (
              <>
                {activeStep === 0 && <FirstPage />}
                {activeStep === 1 && <SecondPage />}
                {activeStep === 2 && <ThirdPage />}
                {activeStep === 3 && <FourthPage />}

                <Box
                  mt={3}
                  display="flex"
                  justifyContent={"flex-end"}
                >
                  {activeStep > 0 && activeStep < 3 && (
                    <Buttons
                      isLoading={isLoading}
                      onClick={handleSkip}
                      color="primary"
                      variant="contained"
                      sx={{
                        borderRadius: "8px",
                        width: "100px",
                        marginRight: "15px",
                        background: "#FFFFFF",
                      }}
                    >
                      <Typography
                        fontFamily={"sans-serif"}
                        fontStyle={"normal"}
                        fontWeight={600}
                        fontSize={"14px"}
                        lineHeight={"25px"}
                        color={"#171717"}
                        textTransform={"none"}
                      >
                        Skip
                      </Typography>
                    </Buttons>
                  )}

                  <Buttons
                    isLoading={isLoading}
                    onClick={
                      activeStep === steps.length - 1
                        ? onSaveEmployees
                        : handleNext
                    }
                    color="primary"
                    variant="contained"
                    // type="submit"
                    sx={{
                      borderRadius: "8px",
                      width: "100px",
                    }}
                  >
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontWeight={600}
                      fontSize={"14px"}
                      lineHeight={"25px"}
                      color={"#fff"}
                      textTransform={"none"}
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Typography>
                  </Buttons>
                </Box>
              </>
            )}
          </>
        ) : (

        )} */}
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          <Buttons
            isLoading={isLoading}
            onClick={onSaveEmployees}
            color="primary"
            variant="contained"
            // type="submit"
            sx={{
              borderRadius: "8px",
              width: "100px",
            }}
          >
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontWeight={600}
              fontSize={"14px"}
              lineHeight={"25px"}
              color={"#fff"}
              textTransform={"none"}
            >
              Add
            </Typography>
          </Buttons>
        </Box>
      </Box>
    </Box>
  );
}
