import { LogoAlere, LogoDanmogot, LogoKanpai, LogoMRI } from "../../../assets/loginPage/img"

const dataLoginFooter = [
    {
        name: "MRI",
        icon: LogoMRI
    },
    {
        name: "Kanpai",
        icon: LogoKanpai
    },
    {
        name: "Danmogot",
        icon: LogoDanmogot
    },
    {
        name: "Alere",
        icon: LogoAlere
    }   
]

export default dataLoginFooter