import { Box, Typography } from "@mui/material"
import DataLogin from "../../../../data/dataLogin";
import { useEffect, useState } from "react";
import { getImageDocument } from "../../../../configs/axios/apiPath";

const CardAttachmentFileOfTask = ({asset, fileName, date}) => {
  const getData = DataLogin();

  const [image, setImage] = useState('')

  useEffect(() => {
    const getImage = async() => {
      try {
        const response = await getImageDocument(asset, getData.token);
        const contentType = response.headers['content-type'];
        const blob = new Blob([response.data], { type: contentType });
        const objectURL = URL.createObjectURL(blob);
        setImage(objectURL);

      } catch (error) {
        console.error(error.response.status);      
      }
    }

    getImage()
  },[asset, getData.token])
  return(
    <Box
      display={'flex'}
      alignItems={'center'}
      width={'full'}
      height={'80px'}
      gap={2}
    >
      <img
        src={image}
        alt={fileName}
        loading="lazy"
        width={'70px'}
        height={'70px'}
      />
      <Box>
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '12px',
            color: '#171717',
          }}
        >
          {fileName}
        </Typography>
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '12px',
            color: '#939393',
          }}
        >
          {`Added ${date}`}
        </Typography>
      </Box>   
    </Box>
  )
}

export default CardAttachmentFileOfTask