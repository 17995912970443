import { Box, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import { LoginBackground } from "../../assets/loginPage/img";
import LoginRouting from "../../routes/loginRouting";
import LoginFooter from "./footerContent";
import LoginHeader from "./headerContent";

const LoginLayout = () => {
    const location = useLocation();
    const isMainRoute = location.pathname === '/users' || location.pathname === '/users/login';
  
    return (
      <Box
        sx={{
          backgroundImage: `linear-gradient(to top, rgba(81, 177, 92, 0.2), rgba(81, 177, 92, 0.2)), url(${LoginBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top center',
          backgroundSize: 'cover',
          height: {
            xl: '100vh',
            lg: isMainRoute ? 'auto': '100vh',
            md: '100vh',
            sm: '100vh',
            xs: '100vh',
          },
        }}
      >
        <Grid container columns={2}>
          <Grid item xl={1} lg={1} md={2} sm={2} xs={2} bgcolor={'#fff'} sx={{
            height: {
              xl: '100vh',
              lg: isMainRoute ? 'auto': '100vh',
              md: '100vh',
              sm: '100vh',
              xs: '100vh',
            }
          }}>
            <Box 
              display={'flex'} p={'20px 30px'} 
              flexDirection={'column'}
              justifyContent={'space-between'}
              sx={{
                height: {
                  xl: '100vh',
                  lg: isMainRoute ? 'auto': '93vh',
                  md: '100vh',
                  sm: '100vh',
                  xs: '100vh',
                }
              }}
            >
              {/* --- Login Header --- */}
              <LoginHeader />
              {/* --- Login Route --- */}
              <Box my={'20px'}>
                <LoginRouting />
              </Box>
              
              {/* --- Login Footer --- */}
              <LoginFooter />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };
  
  export default LoginLayout;