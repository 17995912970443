import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import CardOrgChart from '../orgCard';

/**
 *
 * @returns Orgchart
 */

const OrgChart = ({ data, ...props }) => {
  const OrgChild = ({ data, color }) => {
    if (data.length) {
      return (
        <>
          {data.map((child) =>
            child.subordinates.length ? (
              <TreeNode
                label={
                  <CardOrgChart
                    nama={child?.details[0]?.name}
                    jabatan={child?.name}
                    data={child?.details}
                    color={color}
                  />
                }
              >
                <OrgChild
                  data={
                    child?.subordinates.length
                      ? child?.subordinates
                      : child?.details
                  }
                  color={color}
                />
              </TreeNode>
            ) : (
              <TreeNode
                label={
                  <CardOrgChart
                    nama={child?.details[0]?.name}
                    jabatan={child?.name}
                    data={child?.details}
                    color={color}
                  />
                }
              />
            )
          )}
        </>
      );
    }
  };

  return (
    <>
      {data.length ? (
        <Tree
          lineColor="#939393"
          lineBorderRadius="15px"
          lineHeight="30px"
          lineWidth="2.5px"
          nodePadding="15px"
          label={
            <CardOrgChart
              nama={data[0].details[0].name}
              jabatan={data[0].name}
              profile={data[0].details.profile_picture}
              data={data[0].details}
            />
          }
        >
          {data[0].subordinates.length ? (
            <OrgChild data={data[0]?.subordinates} />
          ) : (
            ''
          )}
        </Tree>
      ) : (
        ''
      )}
    </>
  );
};

export default OrgChart;
