const Data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Sammy',
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1,
      pointStyle: 'circle',
      pointRadius: 10,
      pointHoverRadius: 15,
    },
    {
      label: 'Sobirin Obiyoso',
      data: [10, 39, 30, 51, 16, 25, 20],
      fill: false,
      borderColor: 'green',
      tension: 0.1,
      pointStyle: 'circle',
      pointRadius: 10,
      pointHoverRadius: 15,
    },
    {
      label: 'Sammy',
      data: [20, 29, 20, 21, 56, 5, 10],
      fill: false,
      borderColor: 'red',
      tension: 0.1,
      pointStyle: 'circle',
      pointRadius: 10,
      pointHoverRadius: 15,
    },
  ],
};

export default Data