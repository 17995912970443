import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const CoreHRComplaintIndex = () => {
    return (
        <Box mt='20px'>
            <Outlet />
        </Box>
    )
}

export default CoreHRComplaintIndex;