import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { Card } from '../../../../atoms';

const CardCompany = ({ title, children }) => {
  return (
    <Card sx={{ height: '100%' }}>
      <Typography
        fontWeight={'600px'}
        fontSize={'20px'}
        lineHeight={'27px'}
        letterSpacing={'0.01em'}
        p={'16px 18px'}
        color={'#171717'}
      >
        {title}
      </Typography>
      <Divider
        orientation="horizontal"
        flexItem
        sx={{ borderColor: '#c4c4c4' }}
      />

      <Box p={'20px 18px'}>
        <Box>{children}</Box>
      </Box>
    </Card>
  );
};

export default CardCompany;
