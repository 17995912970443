import Data from './dataChart';
import DataTooltips from './dataTooltips';
import { Card, LineChart } from '../../../atoms';
import { Box, Typography } from '@mui/material';
import SelectYear from '../../../molecules/year';
import moment from 'moment'
import { useState } from 'react';

const SummaryChartProject = () => {
  const yearNow = moment().format('YYYY');
  const year = [2023];
  const data = Data;
  const dataTooltips = DataTooltips;
  const [selectedYear, setSelectedYear] = useState(yearNow);

  return (
    <Card sx={{ marginTop: '20px' }}>
      <Box display={'flex'} flexDirection={'column'} p={'23px 22px'}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography sx={{
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '20px',
          }}>
            Summary
          </Typography>

          <SelectYear
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
            year={year}
          />
        </Box>
        <LineChart data={data} dataTooltips={dataTooltips} />
      </Box>
    </Card>
  );
}

export default SummaryChartProject