import { Box, Typography } from '@mui/material';

export default function HomepageTbody({ tbody }) {
  return (
    <tbody>
      {tbody.map((item, idx) => (
        <tr key={idx}>
          {/* --- Employee Name --- */}
          <td style={{ padding: '10px 12px' }}>
            <Box display={'flex'} alignItems={'center'}>
              <img src={item.profile_picture} alt={item.name} width={30} />
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={600}
                fontSize={'14px'}
                lineHeight={'19px'}
                color={'#171717'}
                letterSpacing={'0.04rem'}
                ml={'16px'}
              >
                {item.name}
              </Typography>
            </Box>
          </td>
          {/* --- Dept --- */}
          <td
            style={{
              padding: '10px 12px',
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
          >
            <img src={item.dept_icon_url} alt={item.dept} width={30} />
          </td>
          {/* --- Position --- */}
          <td style={{ padding: '10px 12px', verticalAlign: 'middle' }}>
            <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={600}
                fontSize={'14px'}
                letterSpacing={'0.04rem'}
                lineHeight={'19px'}
                color={'#171717'}
            >
              {item.position}
            </Typography>
          </td>
          {/* --- Status --- */}
          <td
            style={{
              padding: '10px 12px',
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
          >
            <img
              src={item.status_icon}
              alt={item.status}
              width={35}
              height={30}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
}
