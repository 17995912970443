import { Box } from "@mui/material";
import React from "react";
import {
  ChartReimbursementSummary,
  TableImbursement,
} from "../../../../components/organisms/benefit";
const BenefitReimbursementDashboard = () => {
  return (
    <Box>
      <ChartReimbursementSummary />
      <TableImbursement />
    </Box>
  );
};

export default BenefitReimbursementDashboard;
