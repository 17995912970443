import {
  IconAccount,
  IconReimbursement,
  IconJourney,
  IconOfficeFacilities,
  IconEmployeeFacilities,
} from "../../../assets/benefit/menuNavigation";

const MenuBenefit = [
  {
    id: 0,
    title: "Reimbursement",
    judul: "Pengembalian",
    icon: <IconReimbursement />,
  },
  {
    id: 1,
    title: "Account",
    judul: "Akun",
    icon: <IconAccount />,
  },
  {
    id: 2,
    title: "Journey",
    judul: "Perjalanan",
    icon: <IconJourney />,
  },
  {
    id: 3,
    title: "Office Facilities",
    judul: "Fasilitas Kantor",
    icon: <IconOfficeFacilities />,
  },
  {
    id: 4,
    title: "Employee Facilities",
    judul: "Fasilitas Karyawan",
    icon: <IconEmployeeFacilities />,
  },
];

export default MenuBenefit;
