import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { getLanguage } from "../../../../features/language";
import { IconButton, Typography } from "@mui/material";
import { setNavigation } from "../../../../features/navigation";
import { Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import { convertToDashCase } from "../../../../utils/format";
import { MenuHRCalendar } from "../../../../data/menu";

const HrCalendarTabPanel = () => {
  const params = useParams();
  const url = params["*"].split("/")[1];
  const urlWithDefaultValue = Boolean(url)
    ? url
    : convertToDashCase(MenuHRCalendar[0].title).toLowerCase();

  const lang = useSelector(getLanguage);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    const url = convertToDashCase(newValue);
    navigate(`${url}`);
  };

  return (
    <Box>
      {/* --- Tab Panel --- */}
      <Box
        sx={{
          maxWidth: {
            xl: "96vw",
            lg: "96vw",
            md: "89vw",
            xs: "75vw",
            sm: "84vw",
          },
          p: "0px",
        }}
      >
        <Tabs
          value={urlWithDefaultValue}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="coreHR"
        >
          {MenuHRCalendar.map((item, idx) => (
            <Tab
              value={convertToDashCase(item.title).toLowerCase()}
              label={
                <Box display={"flex"} alignItems={"center"}>
                  {/* --- Icon --- */}
                  <IconButton
                    sx={{
                      mr: "5px",
                      border:
                        convertToDashCase(item.title).toLowerCase() ===
                        urlWithDefaultValue
                          ? "1px solid #51B15C"
                          : "1px solid #303030",
                    }}
                  >
                    {React.cloneElement(item.icon, {
                      style: {
                        fill:
                          convertToDashCase(item.title).toLowerCase() ===
                          urlWithDefaultValue
                            ? "#51B15C"
                            : "#303030",
                      },
                    })}
                  </IconButton>
                  {/* --- Title --- */}
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={600}
                    fontSize={"14px"}
                    lineHeight={"19px"}
                    textTransform={"none"}
                    ml={"10px"}
                  >
                    {lang === "Id" ? item.judul : item.title}
                  </Typography>
                </Box>
              }
              key={idx}
            />
          ))}
        </Tabs>
      </Box>
      {/* --- Outlet --- */}
      <Outlet />
    </Box>
  );
};

export default HrCalendarTabPanel;
