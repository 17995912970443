import React, { useRef } from 'react';
import OrgChart from '../../../components/organisms/organization/organization/orgchart';
import { Button, ButtonGroup, Card, Grid } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCompany } from '../../../features/companies';
import { getOrganization } from '../../../configs/axios/apiPath/fetchOrganization';
import AddIcon from '@mui/icons-material/Add';
import MinimizeIcon from '@mui/icons-material/Minimize';

const DepartmentOrganization = () => {
  const [orgData, setOrgData] = useState([]);
  const company = useSelector(getCompany);
  //    const boxRef = useRef(null);

  const [zoomLevel, setZoomLevel] = useState(80);

  const handleZoomIn = () => {
    setZoomLevel((prevZoomLevel) => prevZoomLevel + 10);
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoomLevel) => prevZoomLevel - 10);
  };

  useEffect(() => {
    if (company) {
      async function fetchOrganization() {
        const params = {
          company_id: company,
        };
        try {
          const {
            data: { data },
          } = await getOrganization(params);
          setOrgData(data);
        } catch (error) {
          console.error(error);
        }
      }
      fetchOrganization();
    }
  }, [company]);

  /**
    useEffect(() => {
        const handleMouseMove = (event) => {
            const box = boxRef.current;
            const boxRect = box.getBoundingClientRect();
            const boxCenterX = boxRect.left + boxRect.width / 2;
            const boxCenterY = boxRect.top + boxRect.height / 2;
            const mouseX = event.clientX;
            const mouseY = event.clientY;
            const moveX = (mouseX - boxCenterX) / 5;
            const moveY = (mouseY - boxCenterY) / 5;
            box.style.transform = `translate(${moveX}px, ${moveY}px)`;
          };
      
          document.addEventListener('mousemove', handleMouseMove);
      
          return () => {
            document.removeEventListener('mousemove', handleMouseMove);
          };
    },[]);
*/

  return (
    <Card
      sx={{
        mt: '20px',
        padding: '20px',
        minHeight: '750px',
      }}
    >
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: '80vh' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ButtonGroup orientation="vertical" size="small">
              <Button variant="outlined" onClick={handleZoomIn}>
                <AddIcon />
              </Button>
              <Button variant="outlined" onClick={handleZoomOut}>
                <MinimizeIcon />
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            overflowX: 'auto',
            width: '100vw',
            minHeight: '750px',
            padding: '20px 10px',
            transition: 'transform 0.1s ease-out',
            zoom: `${zoomLevel}%`,
          }}
        >
          <OrgChart data={orgData} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default DepartmentOrganization;
