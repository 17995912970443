import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

const Outlets = () => {
  return (
    <Box mt="20px">
      <Outlet />
    </Box>
  );
};

export default Outlets;
