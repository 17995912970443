import { useEffect, useState } from 'react';
import {
  Card,
  Typography,
  Divider,
  Grid,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from '../../../../components/atoms';
import { getLanguage } from '../../../../features/language';
import { useSelector } from 'react-redux';
import { IconName } from '../../../../assets/recruitment/jobs';
import { IconEmployee } from '../../../../assets/coreHr/awards';
import {
  getListDepartments,
  getListPosition,
  getRequestById,
  updateRequestById,
} from '../../../../configs/axios/apiPath';
import { getCompany } from '../../../../features/companies';
import { useNavigate, useParams } from 'react-router-dom';

const RecruitmentJobsAdd = () => {
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const [deptData, setDeptData] = useState([]);
  const [positionData, setPositionData] = useState([]);

  const [selectedDept, setSelectedDept] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const handleChangeAutoComplete = (value, setState) => {
    if (value) {
      setState(value);
    } else {
      setState(null);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          departments: [selectedDept?.id],
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListPosition(params);
          setPositionData(
            data?.positions.map((position) => ({
              id: position.id,
              name: position.name,
            }))
          );
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, selectedDept]);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getRequestById(id);
          setSelectedDept(data?.department);
          setSelectedPosition(data?.position);
          setFormData({
            description: data?.description,
          });
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('data');
            navigate(`/`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [id]);
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          page_size: 9999999,
          company_id: company,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListDepartments(params);
          setDeptData(
            data?.departments.map((department) => ({
              id: department.id,
              name: department.name,
              icon_url: department.icon_url,
            }))
          );
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company]);

  const navigate = useNavigate();

  const handleInputChange = (_, value) => {
    setFormData({ ...formData, inputValue: value });
  };

  // --- Handle Selected Employee ---
  const handleInputChange2 = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const data = {
      company_id: company,
      position_id: selectedPosition?.id,
      description: formData?.description,
    };

    setIsLoading(true);
    try {
      await updateRequestById(data, id);
      navigate('/recruitment/request');
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        localStorage.removeItem('cvSayaLogin');
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Card sx={{ padding: '20px' }}>
      <Typography
        fontFamily={'sans-serif'}
        fontStyle={'normal'}
        fontWeight={600}
        fontSize={'20px'}
        lineHeight={'27px'}
        letterSpacing={'0.01em'}
        color={'#171717'}
        marginBottom="15px"
      >
        Edit
      </Typography>
      <Divider />
      <form>
        <Grid container spacing={'16px'}>
          <Grid item xs={6}>
            <Autocomplete
              value={selectedDept}
              onChange={(_, value) => {
                setSelectedPosition(null);
                handleChangeAutoComplete(value, setSelectedDept);
              }}
              options={deptData}
              getOptionLabel={(employee) => employee.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={lang === 'Id' ? 'Dept' : 'Dept'}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  icon={
                    isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <IconEmployee />
                    )
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Autocomplete
              value={selectedPosition}
              onChange={(_, value) => {
                handleChangeAutoComplete(value, setSelectedPosition);
              }}
              onInputChange={handleInputChange}
              options={positionData}
              getOptionLabel={(employee) => employee.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={lang === 'Id' ? 'Posisi' : 'Position'}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  icon={
                    isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <IconName />
                    )
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={6} />

          <Grid item xs={12}>
            <TextField
              label={lang === 'Id' ? 'Deskripsi' : 'Description'}
              fullWidth
              required
              type="text"
              onChange={handleInputChange2}
              size="small"
              variant="outlined"
              multiline
              name="description"
              value={formData?.description}
              rows={4}
            />
          </Grid>
        </Grid>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          sx={{ marginTop: '30px' }}
          onClick={handleSubmit}
          loading={isLoading}
        >
          {lang === 'Id' ? 'Simpan' : 'Save'}
        </LoadingButton>
      </form>
    </Card>
  );
};

export default RecruitmentJobsAdd;
