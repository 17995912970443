import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import moment from 'moment';
import { IconDetail, IconEdit } from '../../../../../../assets/commons';
import { TooltipsAction } from '../../../../../atoms';
import {
  handleTooltipClose,
  handleTooltipOpen,
} from '../../../../../../utils/function/tooltips';
import PayrollListEdit from '../../../../../../pages/payroll/payrollList/payrollListEdit';
import {
  capitalizeFirstLetter,
  formatNumber,
} from '../../../../../../utils/format';
import {
  IconDanger,
  IconDownload,
  IconSuccess,
  IconWarning,
} from '../../../../../../assets/payroll';
import { PayrollHistoryDetail } from '../../../../../../pages/payroll/payrollHistory';

const PayrollHistoryTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idEmployee, setIdEmployee] = useState(null);

  const [idTooltipDetail, setIdTooltipDetail] = useState('');
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);
  const [idTooltipDownload, setIdTooltipDownload] = useState('');
  const [isTooltipDownload, setIsTooltipDownload] = useState(false);
  const [idTooltipStatus, setIdTooltipStatus] = useState('');
  const [isTooltipStatus, setIsTooltipStatus] = useState(false);

  // --- Handle Detail ---
  const handleDetail = (id) => {
    setIdEmployee(id);
    setIsModalOpen(true);
  };

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Payment Invoice --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'start',
                verticalAlign: 'middle',
              }}
            >
              <Box display={'flex'} alignItems={'center'}>
                <img
                  src={item?.profile_picture}
                  alt={item?.name}
                  width={30}
                  height={30}
                  style={{ borderRadius: '50%' }}
                />
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={400}
                  fontSize={'14px'}
                  lineHeight={'130%'}
                  color={'#171717'}
                  ml={'10px'}
                >
                  {item?.name}
                </Typography>
              </Box>
            </td>
            {/* --- Amount --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                Rp. {formatNumber(item?.amount)}
              </Typography>
            </td>
            {/* --- Date --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.paid_at ? moment(item?.paid_at).format('D MMM') : '-'}
              </Typography>
            </td>
            {/* --- Status --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <TooltipsAction
                title={capitalizeFirstLetter(item?.status)}
                open={isTooltipStatus && item.id === idTooltipStatus}
                arrow
                placement={'bottom-end'}
              >
                <Box
                  sx={{ cursor: 'pointer' }}
                  onMouseEnter={() =>
                    handleTooltipOpen(
                      item.id,
                      setIsTooltipStatus,
                      setIdTooltipStatus
                    )
                  }
                  onMouseLeave={() => handleTooltipClose(setIsTooltipStatus)}
                >
                  {item.status === 'paid' ? (
                    <IconSuccess />
                  ) : item?.status == 'unpaid' ? (
                    <IconWarning />
                  ) : (
                    <IconDanger />
                  )}
                </Box>
              </TooltipsAction>
            </td>

            {/* --- Action --- */}
            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={1}
              >
                {/* --- Detail --- */}
                <TooltipsAction
                  title="Detail"
                  open={isTooltipDetail && item.id === idTooltipDetail}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleDetail(item.id)}
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDetail,
                        setIdTooltipDetail
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDetail)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>

                {/* --- Download --- */}
                <TooltipsAction
                  title="Edit"
                  open={isTooltipDownload && item.id === idTooltipDownload}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDownload,
                        setIdTooltipDownload
                      )
                    }
                    onMouseLeave={() =>
                      handleTooltipClose(setIsTooltipDownload)
                    }
                  >
                    <IconDownload />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>

      {/* --- Modal Handle --- */}
      {idEmployee !== null && (
        <PayrollHistoryDetail
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          id={idEmployee}
        />
      )}
    </>
  );
};

export default PayrollHistoryTbody;
