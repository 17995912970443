import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ForgetPasswordPage, LoginPage, NotFoundPage } from '../../pages';

const LoginRouting = () => {
  return (
    <Routes>
      <Route index element={<LoginPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="forget-password" element={<ForgetPasswordPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default LoginRouting;
