import {
  IconAccounting,
  IconHRD,
  IconIT,
  IconSales,
} from '../../../assets/coreHr/announcement';

const dataAnnouncementFooter = [
  {
    title: 'IT',
    judul: 'IT',
    icon: <IconIT />,
  },
  {
    title: 'HRD',
    judul: 'HRD',
    icon: <IconHRD />,
  },
  {
    title: 'Accounting',
    judul: 'Accounting',
    icon: <IconAccounting />,
  },
  {
    title: 'Sales & Marketing',
    judul: 'Sales & Marketing',
    icon: <IconSales />,
  },
];

export default dataAnnouncementFooter;
