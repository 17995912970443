import { AvatarGroup, Avatar, styled } from "@mui/material";

const AvatarGroupStyled = ({width, height, maxShow, avatarData }) => {

  const StyledAvatarGroup = styled(AvatarGroup)({
    '& .MuiAvatarGroup-avatar': {
      width: `${width}px`,
      height: `${height}px`,
    },
  });

  return(
    <StyledAvatarGroup max={maxShow}>
      {avatarData?.map((item, idx) => (
        <Avatar key={idx} alt={item?.name} src={item?.profile_picture} />
      ))}
  </StyledAvatarGroup>
  )
}

export default AvatarGroupStyled