import { Box, Button, CircularProgress } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  Search,
  RowPerPage,
  Pagination,
} from "../../../../../components/atoms";
import { getLanguage } from "../../../../../features/language";
import { getCompany } from "../../../../../features/companies";
import { SelectPayrollPeriods } from "../../../../../components/molecules/select";
import { getListEmployeePayslip } from "../../../../../configs/axios/apiPath";
import TableComponent from "../../../../../components/molecules/table/mainTable";
import EmployeePayslipThead from "../../../../../components/molecules/table/tablePayroll/tablePayrollEmployeePayslip/employeePayslipThead";
import { DataPayrollEmployeePayslip } from "../../../../../data/table/payroll";
import EmployeePayslipTbody from "../../../../../components/molecules/table/tablePayroll/tablePayrollEmployeePayslip/employeePayslipTbody";
import {
  generateExportPayroll,
  getExportStatusPayroll,
} from "../../../../../configs/axios/apiPath/fetchPayroll/fetchPayrollList";

const PayrollListEmployee = () => {
  const yearNow = moment().format("YYYY");
  const year = [];
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState();

  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);

  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }

  const path = useLocation().pathname;
  const splitPath = path.split("/");
  const id = splitPath[4];

  const [idPayroll, setIdPayroll] = useState(id);
  const [loadingGenerate, setLoadingGenerate] = useState(false);

  const handleButtonExportClick = async () => {
    if (idPayroll && company) {
      const data = {
        company_id: company,
        module: "attendanceMonthly",
        payroll_period_id: idPayroll,
      };
      setLoadingGenerate(true);
      try {
        const response = await generateExportPayroll(data);
        const filename = `payroll_attendances.xlsx`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoadingGenerate(false);
      } catch (error) {
        setLoadingGenerate(false);
        console.error(error);
        if (error?.response?.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (idPayroll && company) {
        const params = {
          company_id: company,
          payroll_period_id: idPayroll,
          page: page,
          page_size: pageSize,
          keyword: keyword,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListEmployeePayslip(params);
          setLastPage(data.page_info?.last_page);
          setData(data?.payslips);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [page, pageSize, idPayroll, company, keyword, navigate]);

  return (
    <Card sx={{ marginTop: "20px" }}>
      <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
        <Box display={"flex"}>
          {/* --- Search --- */}
          <Search
            placeholder={
              lang === "Id"
                ? "Ketik disini untuk mencari"
                : "Type here to search"
            }
            setSearch={(e) => setKeyword(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />

          <Box display={"flex"} alignItems={"center"} marginLeft={"auto"}>
            {/* --- Selected Year --- */}
            <SelectPayrollPeriods setId={setIdPayroll} />
          </Box>
          <Box>
            <Button
              variant="contained"
              size="small"
              disabled={loadingGenerate}
              sx={{ minWidth: "100px", padding: "4px" }}
              onClick={handleButtonExportClick}
            >
              Export
            </Button>
          </Box>
        </Box>

        {/* --- Table --- */}
        <Box sx={{ mt: "24px", mb: "16px" }}>
          <TableComponent
            isLoading={isLoading}
            tbody={data}
            TableHead={
              <EmployeePayslipThead thead={DataPayrollEmployeePayslip} />
            }
            TableBody={<EmployeePayslipTbody tbody={data} setData={setData} />}
            col={10}
          />
        </Box>

        {/* --- Pagination --- */}
        <Box display={"flex"} justifyContent={"end"}>
          <RowPerPage
            setPageSize={setPageSize}
            pageSize={pageSize}
            setPage={setPage}
          />
          <Pagination page={page} setPage={setPage} lastPage={lastPage} />
        </Box>
      </Box>
    </Card>
  );
};

export default PayrollListEmployee;
