import * as React from 'react';
import { Box, IconButton } from '@mui/material';
import {
  IconSidebarHide,
  IconSidebarShow,
} from '../../../assets/sideNav/icons';
import { Drawer, DrawerHeader } from '../../../configs/theme/drawerTheme';
import HeaderContent from './headerContent';
import MainContent from './mainContent';

const SidebarHomepage = ({ open, setOpen }) => {
  return (
    <Drawer variant="permanent" open={open}>
      <Box
        sx={{
          display: 'flex',
          alignItems: open ? '' : 'center',
          flexDirection: open ? 'row' : 'column-reverse',
        }}
      >
        <HeaderContent open={open} />
        <DrawerHeader>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <IconSidebarHide /> : <IconSidebarShow />}
          </IconButton>
        </DrawerHeader>
      </Box>

      <MainContent open={open} />
    </Drawer>
  );
};

export default SidebarHomepage;
