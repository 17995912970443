import { Box, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { IconArrowLeft, IconLogoMd } from "../../../assets/loginPage/icons";

const LoginHeader = () => {
    const location = useLocation();
    const isMainRoute = location.pathname === '/users' || location.pathname === '/users/login';

    return (
        <Box display={'flex'} >
            {
                isMainRoute ?
                    <Box display={'flex'} alignItems={'center'}>
                        {/* --- Logo --- */}
                        <IconLogoMd />
                        {/* --- Title --- */}
                        <Typography
                            fontFamily={'sans-serif'}
                            fontStyle={'normal'}
                            fontWeight={700}
                            fontSize={'20px'}
                            lineHeight={'27px'}
                            color={'#51B15C'}
                            ml={'10px'}
                        >CVSaya</Typography>
                    </Box> 
                : <Link to=".." relative="path"
                >
                    <IconArrowLeft />
                </Link>
            }
        </Box>
    );
}

export default LoginHeader;