import axios from "axios";
import { API } from "../../../API";

const URL = process.env.REACT_APP_API_BASE_URL;
/** --- Post Attachment */
export const postAttachmentProject = (formData, token) => {
    return axios.post(
        `${URL}projects/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
    );
}

/** Show Detail Task */
// export const showAttachmentProject = (docId, width, height) => API.get(`documents/${docId}?width=${width}&height=${height}`);
export const showAttachmentProject = (docId, width, height, token) => {
  return axios.get(`${URL}documents/${docId}?width=${width}&height=${height}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    responseType: 'blob',
  });
}