import { Box, Divider, IconButton, Menu, Typography } from "@mui/material"
import { useState } from "react";
import CardListFileOfDiscussion from "../../card/project/CardListFileOfDiscussion";


const MenuAttachmentFileOfDiscussion = ({picture, title, subtitile, data}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  return(
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        gap={2}
        width={'full'}
        padding={'20px'}
      >
        <IconButton
          onClick={handleOpen}
          sx={{ 
            "&:hover": {
              bgcolor: 'transparent',
            }
          }}
        >
          {picture}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              borderRadius: '10px',
              width: '325px',
              padding: '20px',
              maxHeight: '420px',
            },
          }}
        >
          <CardListFileOfDiscussion
            title={title}
            data={data}
          />
        </Menu>
        <Box>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '16px',
              color: '#171717'
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '12px',
              color: '#939393'
            }}
          >
            {subtitile}
          </Typography>        
        </Box>
      </Box>
      <Divider/>

    </>
  )
}

export default MenuAttachmentFileOfDiscussion