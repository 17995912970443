import { Box, IconButton, Popover, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import { useState } from "react";
import CardAddListBoard from "../../card/project/CardAddListBoard";

const MenuAddBoard = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  return(
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'} 
      justifyContent={'space-between'}
    >
      <Typography sx={{
          fontSize: '16px',
          color: 'white',
          letterSpacing: '0.24px',
        }}
      >
        Add Another List
      </Typography>
      <IconButton 
        color="inherit" 
        onClick={handleOpen} 
        sx={{ 
          borderRadius: 0,
          width: '47px',
          height: '47px',
          "&:hover": {
            bgcolor: 'transparent',
          }
        }}
      >
        <AddIcon sx={{color: 'white'}}/>
      </IconButton>

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            borderRadius: '10px',
            boxShadow: 'none',
          },
        }}
      >
        <CardAddListBoard projectId={props.projectId} fetchHandle={props.fetchHandle} closeHandle={handleClose} />
      </Popover>
    </Box>
  )
}

export default MenuAddBoard