import {
  IconEmployeeDashboard,
  IconEmployeeManage,
  IconEmployeeSchedule,
} from '../../../assets/employee';

const MenuEmployee = [
  {
    id: 0,
    title: 'Employee Dashboard',
    judul: 'Dashboard Karyawan',
    description: 'Performance employee',
    deskripsi: 'Kinerja Karyawan',
    icon: <IconEmployeeDashboard />,
  },
  {
    id: 1,
    title: 'Employee',
    judul: 'Karyawan',
    description: 'Manage Employees',
    deskripsi: 'Mengelola Karyawan',
    icon: <IconEmployeeManage />,
  },
  {
    id: 2,
    title: 'Schedule',
    judul: 'Jadwal',
    description: 'Manage Schedule',
    deskripsi: 'Mengelola Jadwal',
    icon: <IconEmployeeSchedule />,
  },
];

export default MenuEmployee;
