import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import {
  IconDelete,
  IconDetail,
  IconEdit,
  IconDescription,
} from '../../../../../../assets/commons';
import { TooltipsAction } from '../../../../../atoms';
import {
  handleTooltipClose,
  handleTooltipOpen,
} from '../../../../../../utils/function/tooltips';
import { ModalDelete } from '../../../../modal';
import { handleAction } from '../../../../../../utils/function/action';
import { getCalendarById } from '../../../../../../configs/axios/apiPath';

const HistoryTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState('');
  const [idEmployee, setIdEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState([]);

  const [idTooltipDetail, setIdTooltipDetail] = useState('');
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);
  const [idTooltipDelete, setIdTooltipDelete] = useState('');
  const [isTooltipDelete, setIsTooltipDelete] = useState(false);
  const [idTooltipEdit, setIdTooltipEdit] = useState('');
  const [isTooltipEdit, setIsTooltipEdit] = useState(false);
  const [idTooltipDoc, setIdTooltipDoc] = useState('');
  const [isTooltipDoc, setIsTooltipDoc] = useState(false);

  // --- Handle Edit ---
  const handleEdit = (id) => {
    navigate(`/core-hr/awards/edit/${id}`);
  };

  // --- Load Detail ---
  useEffect(() => {
    async function fetchData() {
      if (idEmployee !== null) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getCalendarById(idEmployee);
          setDetail(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [idEmployee]);
  return (
    <>
      <tbody>
        {tbody.map((item, idx) => {
          return (
            <tr key={idx}>
              {/* --- Name --- */}
              <td
                style={{
                  padding: '10px 40px',
                  textAlign: 'start',
                  verticalAlign: 'middle',
                }}
              >
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={400}
                  fontSize={'14px'}
                  lineHeight={'130%'}
                  color={'#171717'}
                >
                  {item.name}
                </Typography>
              </td>
              {/* --- Description --- */}
              <td
                style={{
                  padding: '10px 12px',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                }}
              >
                <TooltipsAction
                  title={item.description}
                  open={isTooltipDoc && item.id === idTooltipDoc}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{ cursor: 'pointer' }}
                    // onClick={() => handleAction(item.id, setIsModalOpen, setModal, setIdEmployee, 'detail')}
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDoc,
                        setIdTooltipDoc
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDoc)}
                  >
                    <IconDescription />
                  </Box>
                </TooltipsAction>
              </td>
              {/* <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item.description}
              </Typography>
            </td> */}
              {/* --- Date --- */}
              <td
                style={{
                  padding: '10px 12px',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                }}
              >
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={400}
                  fontSize={'14px'}
                  lineHeight={'130%'}
                  color={'#171717'}
                >
                  {moment(item.start_date).format('D MMM')}
                </Typography>
              </td>
              {/* --- Action --- */}
              <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  gap={1}
                >
                  {/* --- Detail --- */}
                  <TooltipsAction
                    title="Detail"
                    open={isTooltipDetail && item.id === idTooltipDetail}
                    arrow
                    placement={'bottom-end'}
                  >
                    <Box
                      sx={{ cursor: 'pointer' }}
                      onClick={() =>
                        navigate(
                          `/calendar-hr/hr-calendar?id=${item.id}&start_date=${item.start_date}`
                        )
                      }
                      onMouseEnter={() =>
                        handleTooltipOpen(
                          item.id,
                          setIsTooltipDetail,
                          setIdTooltipDetail
                        )
                      }
                      onMouseLeave={() =>
                        handleTooltipClose(setIsTooltipDetail)
                      }
                    >
                      <IconDetail />
                    </Box>
                  </TooltipsAction>

                  {/* --- Delete --- */}
                  <TooltipsAction
                    title="Delete"
                    open={isTooltipDelete && item.id === idTooltipDelete}
                    arrow
                    placement={'bottom-end'}
                  >
                    <Box
                      sx={{ cursor: 'pointer' }}
                      onClick={() =>
                        handleAction(
                          item.id,
                          setIsModalOpen,
                          setModal,
                          setIdEmployee,
                          'delete'
                        )
                      }
                      onMouseEnter={() =>
                        handleTooltipOpen(
                          item.id,
                          setIsTooltipDelete,
                          setIdTooltipDelete
                        )
                      }
                      onMouseLeave={() =>
                        handleTooltipClose(setIsTooltipDelete)
                      }
                    >
                      <IconDelete />
                    </Box>
                  </TooltipsAction>
                </Box>
              </td>
            </tr>
          );
        })}
      </tbody>
      {/* --- Modal Handle --- */}
      {modal === 'delete' && (
        <ModalDelete
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={'Calendar'}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          id={idEmployee}
          data={tbody}
          setData={setData}
        />
      )}
    </>
  );
};

export default HistoryTbody;
