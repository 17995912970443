import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCompany } from '../../../../features/companies';
import { setNavigation } from '../../../../features/navigation';
import { getShift } from '../../../../configs/axios/apiPath';
import {
  Card,
  Pagination,
  RowPerPage,
  Search,
} from '../../../../components/atoms';
import { IconPlus } from '../../../../assets/commons';
import TableComponent from '../../../../components/molecules/table/mainTable';
import { 
    TableScheduleTbody,
    TableScheduleThead
 } from '../../../../components/organisms/employee/employeeSchedule';
 import { dataTableEmployeeShedule } from '../../../../data/table/employee/employeeShedule';

export default function EmployeeSchedule() {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sortir, setSortir] = useState('');
  const [lastPage, setLastPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const company = useSelector(getCompany);
  const navigate = useNavigate();
  const [schedule, setSchedule] = useState([]);

  // --- Fetch Shift --
  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          name: sortir,
          search: search,
          company_id: company,
          page: page,
          page_size: pageSize,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getShift(params);
          setLastPage(data.page_info.last_page);
          setSchedule(data.shifts);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('data');
            navigate(`/`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [page, search, sortir, company, pageSize, navigate]);

  const last = [];
  for (let i = 1; i <= lastPage; i++) {
    last.push(i);
  }

  const handleAdd = () => {
    dispatch(setNavigation('Add'));
    navigate('add');
  };

  return (
    <Card>
      <Box display={'flex'} flexDirection={'column'} p={'25px 22px'}>
        {/* --- Heading --- */}
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Search
            placeholder="Type here to search"
            setSearch={(e) => setSearch(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />
          <Button
            variant="contained"
            startIcon={<IconPlus />}
            disableRipple
            onClick={handleAdd}
            sx={{ width: '52px', height: '23px' }}
          >
            <Typography
              fontSize={'12px'}
              fontWeight={'600px'}
              lineHeight={'130%'}
              letterSpacing={'0.02em'}
              textTransform={'none'}
            >
              Add
            </Typography>
          </Button>
        </Box>
        {/* --- Table --- */}
        <Box mt={'24px'} mb={'16px'}>
          <TableComponent
            isLoading={isLoading}
            tbody={schedule}
            TableHead={
              <TableScheduleThead
                thead={dataTableEmployeeShedule}
                sortir={sortir}
                setSortir={setSortir}
              />
            }
            TableBody={
              <TableScheduleTbody tbody={schedule} setData={setSchedule} />
            }
            col={8}
          />
        </Box>

        {/* --- Row Per Page --- */}
        <Box display={'flex'} justifyContent={'end'}>
          <RowPerPage
            setPageSize={setPageSize}
            pageSize={pageSize}
            setPage={setPage}
          />
          <Pagination page={page} setPage={setPage} lastPage={lastPage} />
        </Box>
      </Box>
    </Card>
  );
}
