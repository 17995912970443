import React from "react";
import DataTooltips from "../../../homepage/homepageAttendance/attendanceChart/dataTooltips";
import { Card, BarChart } from "../../../../atoms";
import { Box, Typography } from "@mui/material";

const ChartSumarry = () => {
const data = {
    labels: ['BCA', 'BNI', 'BRI', 'BSI', 'MANDIRI', 'MEGA', 'BRI Syariah'],
    datasets: [
      {
        label: 'IT',
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        tension: 0.1,
        pointStyle: 'circle',
        pointRadius: 10,
        pointHoverRadius: 15,
      },
      {
        label: 'Accounting',
        data: [10, 39, 30, 51, 16, 25, 20],
        fill: false,
        backgroundColor: 'green',
        tension: 0.1,
        pointStyle: 'circle',
        pointRadius: 10,
        pointHoverRadius: 15,
      },
      {
        label: 'marketing',
        data: [20, 29, 20, 21, 56, 5, 10],
        fill: false,
        backgroundColor: 'red',
        tension: 0.1,
        pointStyle: 'circle',
        pointRadius: 10,
        pointHoverRadius: 15,
      },
    ],
  };

  const dataTooltips = DataTooltips;
  return (
    <Card sx={{ padding: "20px  25px", mt: "20px" }}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box sx={{display:'flex'}}>
          <Typography fontWeight={600} fontSize={20}>
           Summarie 
          </Typography>
          <Typography fontWeight={600} fontSize={20} sx={{color:"#939393"}}>
            (s)
          </Typography>
      </Box>
      </Box>
      <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
        <BarChart data={data} dataTooltips={dataTooltips} />
      </Box>
    </Card>
  );
};

export default ChartSumarry;
