import { Box, IconButton, Popover } from "@mui/material"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import CardChangeBackgroundBoard from "../../card/project/CardChangeBackgroundBoard";

const MenuChangeBackgroundBoard = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  return(
    <Box>
      <IconButton 
        onClick={handleOpen}
        sx={{
          backgroundColor: '#F7F7F750',
          width: '27px',
          height: '27px',
          '&:hover': {
            backgroundColor: "#F7F7F760"
          }
        }}
      >
        <MoreVertIcon sx={{color: 'white'}}/>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: 0,
            boxShadow: 'none',
          },
        }}
      >
        <CardChangeBackgroundBoard/>
      </Popover>
    </Box>
  )
}

export default MenuChangeBackgroundBoard