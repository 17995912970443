import {
  IConPermission,
  IconAnnouncement,
  IconAward,
  IconComplaint,
  IconLogView,
  IconPromotion,
  IconResign,
  IconWarning,
  IconOvertime,
} from "../../../assets/coreHr/menuNavigation";

const MenuCoreHR = [
  {
    id: 0,
    title: "Awards",
    judul: "Penghargaan",
    icon: <IconAward />,
  },
  {
    id: 1,
    title: "Resign",
    judul: "Berhenti",
    icon: <IconResign />,
  },
  {
    id: 2,
    title: "Promotion",
    judul: "Promosi",
    icon: <IconPromotion />,
  },
  {
    id: 3,
    title: "Warning",
    judul: "Peringatan",
    icon: <IconWarning />,
  },
  {
    id: 4,
    title: "Announcement",
    judul: "Pemberitahuan",
    icon: <IconAnnouncement />,
  },
  {
    id: 5,
    title: "Log View",
    judul: "Tampilan Log",
    icon: <IconLogView />,
  },
  {
    id: 6,
    title: "Complaint",
    judul: "Keluhan",
    icon: <IconComplaint />,
  },
  {
    id: 7,
    title: "Permission",
    judul: "Perizinan",
    icon: <IConPermission />,
  },
  {
    id: 8,
    title: "Overtime",
    judul: "Lembur",
    icon: <IconOvertime />,
  },
];

export default MenuCoreHR;
