import { Box } from "@mui/material";
import React from "react";
import {
  TableJobs,
  ChartVacancies,
} from "../../../../components/organisms/recruitment";
const BenefitAccountDashboard = () => {
  return (
    <Box>
      <ChartVacancies />
      <TableJobs />
    </Box>
  );
};

export default BenefitAccountDashboard;
