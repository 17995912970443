export const contentInjector = (content = {}, layouts = []) => {
  const contents = content || {};
  const attr = Object.keys(contents);

  return layouts.map((x) => {
    return attr.reduce(
      (a, b) => ({
        ...a,
        [b]: (contents[b] || "").replace(/\{+[\S]+\}/g, (m) => {
          return (x || {})[m.replace(/\{|\}/g, "")];
        }),
      }),
      {}
    );
  });
};
