export const dataTableEmployeeShift = [
  {
    title: 'Profile',
    judul: 'Profil',
  },
  {
    title: 'Shift',
    judul: 'Shift',
  },
  {
    title: 'Status',
    judul: 'Status',
  },
];
