import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import moment from "moment";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { Card, Modals } from "../../../../components/atoms";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../features/language";
import { IconClose, IconRejectedStep } from "../../../../assets/commons";
import { styled } from "@mui/material/styles";
import VerifiedStep from "./verified-step";
import DoneStep from "./done-step";

const AttendanceCorrectionDetail = ({
  isOpen,
  onClose,
  detail,
  isLoading,
  handleVerified,
  handleReject,
}) => {
  const lang = useSelector(getLanguage);

  const ColorConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#4FD240",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#4FD240",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "40%",
          transform: "translate(-50%, -50%)",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "background.paper",
        }}
      >
        <Card>
          <Box p={"30px"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: "7px",
                position: "sticky",
                top: 0,
                bgcolor: "background.paper",
                p: "10px 0px 10px 0px",
                zIndex: 999,
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"27px"}
                color={"#171717"}
              >
                {lang === "Id" ? "Koreksi Kehadiran" : "Attendance Correction"}
              </Typography>
              <IconButton sx={{ p: "0px" }} onClick={onClose}>
                <IconClose />
              </IconButton>
            </Box>

            {!isLoading ? (
              <>
                {detail?.statuses?.length === 3 && (
                  <>
                    <Box>
                      <Stepper
                        size="sm"
                        alternativeLabel
                        sx={{
                          "& .MuiStepConnector-line": {
                            marginTop: "28px",
                          },
                        }}
                        connector={<ColorConnector />}
                      >
                        <Step
                          completed={
                            detail?.statuses?.length === 3 &&
                            detail?.statuses[0]?.created_at !== null
                              ? true
                              : false
                          }
                        >
                          <Typography
                            align="center"
                            fontSize={"12px"}
                            sx={{ marginBottom: "12px" }}
                          >
                            Request
                          </Typography>
                          <StepLabel fontSize="9px">
                            <Typography fontSize={"10px"}>
                              {detail?.statuses?.length === 3
                                ? detail?.statuses[0]?.name
                                : null}
                            </Typography>
                            <Typography fontSize={"10px"}>
                              {detail?.statuses?.length === 3
                                ? moment(detail?.statuses[0]?.create_at).format(
                                    "DD/MMM/YY, HH:MM"
                                  )
                                : null}
                            </Typography>
                          </StepLabel>
                        </Step>

                        <Step
                          completed={
                            detail?.statuses?.length === 3 &&
                            detail?.statuses[1]?.created_at !== null
                              ? true
                              : false
                          }
                        >
                          <Typography
                            align="center"
                            fontSize={"12px"}
                            sx={{ marginBottom: "12px" }}
                          >
                            Approved
                          </Typography>
                          <StepLabel fontSize="9px">
                            <Typography fontSize={"10px"}>
                              {detail?.statuses?.length === 3
                                ? detail?.statuses[1]?.name
                                : null}
                            </Typography>
                            <Typography fontSize={"10px"}>
                              {detail?.statuses?.length === 3
                                ? moment(detail?.statuses[1].created_at).format(
                                    "DD/MM/YY, HH:MM"
                                  )
                                : null}
                            </Typography>
                          </StepLabel>
                        </Step>

                        <Step
                          completed={
                            detail?.statuses?.length === 3 &&
                            detail?.statuses[2]?.created_at !== null
                              ? true
                              : false
                          }
                        >
                          <Typography
                            align="center"
                            fontSize={"12px"}
                            sx={{ marginBottom: "12px" }}
                          >
                            Verified
                          </Typography>
                          <StepLabel fontSize="9px">
                            <Typography fontSize={"10px"}>
                              {detail?.statuses?.length === 3
                                ? detail?.statuses[2]?.name
                                : null}
                            </Typography>
                            <Typography fontSize={"10px"}>
                              {detail?.statuses?.length === 3
                                ? moment(detail?.statuses[2].created_at).format(
                                    "DD/MM/YY, HH:MM"
                                  )
                                : null}
                            </Typography>
                          </StepLabel>
                        </Step>
                      </Stepper>

                      {detail?.status === 202 ? (
                        <Box mt={"15px"}>
                          <VerifiedStep
                            detail={detail}
                            handleVerified={handleVerified}
                            handleReject={handleReject}
                          />
                        </Box>
                      ) : (
                        <DoneStep detail={detail} />
                      )}
                    </Box>
                  </>
                )}

                {detail?.statuses?.length === 2 && (
                  <>
                    <Box>
                      <Stepper
                        size="sm"
                        alternativeLabel
                        sx={{
                          "& .MuiStepConnector-line": {
                            marginTop: "28px",
                          },
                        }}
                        connector={<ColorConnector />}
                      >
                        <Step
                          completed={
                            detail?.statuses?.length === 2 &&
                            detail?.statuses[0]?.created_at !== null
                              ? true
                              : false
                          }
                        >
                          <Typography
                            align="center"
                            fontSize={"12px"}
                            sx={{ marginBottom: "12px" }}
                          >
                            Request
                          </Typography>
                          <StepLabel fontSize="9px">
                            <Typography fontSize={"10px"}>
                              {detail?.statuses?.length === 2
                                ? detail?.statuses[0]?.name
                                : null}
                            </Typography>
                            <Typography fontSize={"10px"}>
                              {detail?.statuses?.length === 2
                                ? moment(detail?.statuses[0]?.create_at).format(
                                    "DD/MMM/YY, HH:MM"
                                  )
                                : null}
                            </Typography>
                          </StepLabel>
                        </Step>

                        <Step
                          completed={
                            detail?.approved_by !== null ? true : false
                          }
                        >
                          <Typography
                            align="center"
                            fontSize={"12px"}
                            sx={{ marginBottom: "12px" }}
                          >
                            Approved
                          </Typography>
                          <StepLabel fontSize="9px">
                            <Typography fontSize={"10px"}>
                              {detail?.approved_by !== null
                                ? detail?.approved_by?.name
                                : null}
                            </Typography>
                            <Typography fontSize={"10px"}>
                              {/* {detail?.statuses?.length === 2
                                ? moment(detail?.statuses[0].created_at).format(
                                    "DD/MM/YY, HH:MM"
                                  )
                                : null} */}{" "}
                              Invalid Date
                            </Typography>
                          </StepLabel>
                        </Step>

                        <Step
                          completed={
                            detail?.statuses?.length === 2 &&
                            detail?.statuses[1]?.created_at !== null
                              ? true
                              : false
                          }
                        >
                          <Typography
                            align="center"
                            fontSize={"12px"}
                            color={detail?.status === 301 && "#E40A0A"}
                            sx={{ marginBottom: "12px" }}
                          >
                            {detail?.status === 301 ? "Rejected" : "Verified"}
                          </Typography>
                          <StepLabel
                            fontSize="9px"
                            icon={
                              detail?.status === 301 ? (
                                <IconRejectedStep />
                              ) : null
                            }
                          >
                            <Typography fontSize={"10px"}>
                              {detail?.statuses?.length === 2 &&
                              detail?.statuses[1]?.name !== null
                                ? detail?.statuses[1]?.name
                                : null}
                            </Typography>
                            <Typography fontSize={"10px"}>
                              {detail?.statuses?.length === 2
                                ? moment(
                                    detail?.statuses[1]?.created_at
                                  ).format("DD/MM/YY, HH:MM")
                                : null}
                            </Typography>
                          </StepLabel>
                        </Step>
                      </Stepper>

                      <DoneStep detail={detail} />
                    </Box>
                  </>
                )}
              </>
            ) : (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress color="secondary" />
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </Modals>
  );
};

export default AttendanceCorrectionDetail;
