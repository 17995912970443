import { Box, Button, Typography, Stack } from "@mui/material";
import SelectYear from "../../../../molecules/year";
import SelectStage from "../../../../molecules/select/selectStage";
import SelectStatus from "../../../../molecules/select/selectStatus";
import dayjs from "dayjs";
import ModalJobsDescription from "../modal/modalJobsDescription.js";
import { useState, useCallback } from "react";
import { getLanguage } from "../../../../../features/language";
import { getCompany } from "../../../../../features/companies";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { Card, Pagination, RowPerPage, Search } from "../../../../atoms";
import { useEffect } from "react";
import { getListVacancyCandidate } from "../../../../../configs/axios/apiPath";
import { DataTableCandidate } from "../../../../../data/table/recruitment";
import TableComponent from "../../../../molecules/table/mainTable";
import {
  RecruitmentTbody,
  RecruitmentThead,
} from "../../../../molecules/table/tableRecruitment/candidate";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

const TableCandidate = () => {
  const yearNow = moment().format("YYYY");
  const year = [];
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const title = searchParams.get("title"); // "value1"
  const description = searchParams.get("description"); // "value2"
  const employmentType = searchParams.get("employment_type"); // "value2"
  const createdAt = searchParams.get("created_at"); // "value2"
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [selectedYear, setSelectedYear] = useState(yearNow);
  const [selectedStatus, setSelectedStatus] = useState("Status");
  const [selectedStage, setSelectedStage] = useState("Stage");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [attendance, setattendance] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState("name");
  const [direction, setDirection] = useState("-");
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);
  const { id } = useParams();

  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }

  const fetchData = useCallback(async () => {
    if (company) {
      const params = {
        keyword: keyword,
        page: page,
        page_size: pageSize,
        year: selectedYear,
        stage: selectedStage !== "Stage" ? selectedStage : undefined,
        status: selectedStatus !== "Status" ? selectedStatus : undefined,
      };
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await getListVacancyCandidate(id, params);
        setLastPage(data?.page_info?.last_page);
        setTotal(data?.page_info?.total);
        setattendance(data?.applied_candidate);
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  }, [
    company,
    keyword,
    page,
    pageSize,
    selectedYear,
    setIsLoading,
    setLastPage,
    setTotal,
    setattendance,
    id,
    navigate,
    selectedStage,
    selectedStatus,
  ]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" gap="10px" alignItems="center">
          <Stack>
            <Typography
              sx={{ fontSize: "25px", fontWeight: "600", color: "#171717" }}
            >
              {title}
            </Typography>
            <Typography sx={{ fontSize: "15px", color: "#303030" }}>
              Posted {dayjs(createdAt).fromNow()}
            </Typography>
          </Stack>
          <Stack direction="row" gap="6px">
            <Box
              sx={{
                paddingY: "6px",
                paddingX: 4,
                backgroundColor: "rgba(200, 200, 204, 0.14)",
                height: "max-content",
                color: "#939393",
                borderRadius: "88px",
                fontSize: "13px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="11"
                fill="none"
                viewBox="0 0 10 11"
                style={{ marginRight: "8px" }}
              >
                <g clipPath="url(#clip0_4948_37557)">
                  <path
                    fill="#939393"
                    d="M9.834 3.974l-4.687-2.5a.312.312 0 00-.294 0l-4.687 2.5a.312.312 0 000 .552l1.084.578v1.892c0 .153.056.301.159.416.511.57 1.658 1.525 3.59 1.525a5.078 5.078 0 001.876-.342v1.28a.312.312 0 00.625 0V8.293a4.517 4.517 0 001.091-.881.621.621 0 00.159-.416V5.104l1.084-.578a.313.313 0 000-.552zM5 8.312c-1.69 0-2.684-.825-3.125-1.316V5.437l2.978 1.589a.313.313 0 00.294 0l1.728-.922v1.81A4.383 4.383 0 015 8.312zm3.125-1.318a3.821 3.821 0 01-.625.557V5.77l.625-.334v1.557zm-.781-1.848l-.009-.005-2.187-1.167a.312.312 0 00-.294.552L6.68 5.5 5 6.396.976 4.25 5 2.104 9.023 4.25l-1.68.896z"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_4948_37557">
                    <path
                      fill="#fff"
                      d="M0 0H10V10H0z"
                      transform="translate(0 .5)"
                    ></path>
                  </clipPath>
                </defs>
              </svg>
              3 years exp
            </Box>
            <Box
              sx={{
                paddingY: "6px",
                paddingX: 4,
                height: "max-content",
                backgroundColor: "rgba(200, 200, 204, 0.14)",
                color: "#939393",
                borderRadius: "88px",
                fontSize: "13px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                style={{ marginRight: "8px" }}
                height="11"
                viewBox="0 0 10 11"
                fill="none"
              >
                <path
                  d="M1.25 5.5C1.25 7.07125 1.25 8.69042 1.79917 9.17833C2.34833 9.66667 3.2325 9.66667 5 9.66667C6.76792 9.66667 7.65167 9.66667 8.20083 9.17833C8.75 8.69042 8.75 7.07125 8.75 5.5"
                  stroke="#939393"
                  stroke-width="0.6"
                />
                <path
                  d="M6.10703 6.41717L8.61036 5.66634C8.85828 5.59176 8.98245 5.55467 9.0612 5.46551C9.07663 5.4481 9.09057 5.42942 9.10286 5.40967C9.16536 5.30842 9.16536 5.17926 9.16536 4.92009C9.16536 3.89926 9.16536 3.38884 8.88495 3.04426C8.83102 2.97795 8.77042 2.91735 8.70411 2.86342C8.35953 2.58301 7.84911 2.58301 6.82828 2.58301H3.16953C2.1487 2.58301 1.63828 2.58301 1.2937 2.86342C1.22703 2.91759 1.16661 2.97801 1.11286 3.04426C0.832031 3.38884 0.832031 3.89926 0.832031 4.92009C0.832031 5.17926 0.832031 5.30842 0.894531 5.40967C0.907031 5.42926 0.920781 5.44801 0.936198 5.46551C1.01536 5.55467 1.13911 5.59176 1.38703 5.66634L3.89036 6.41717M2.70703 2.58301C3.04995 2.57467 3.3987 2.35592 3.51536 2.03301L3.52995 1.99009L3.54036 1.95801C3.55786 1.90509 3.56703 1.87884 3.5762 1.85551C3.63506 1.70918 3.73423 1.58256 3.8622 1.49036C3.99017 1.39817 4.14168 1.34418 4.29911 1.33467C4.3237 1.33301 4.35203 1.33301 4.40703 1.33301H5.58953C5.64495 1.33301 5.67286 1.33301 5.69786 1.33467C5.8553 1.34418 6.00681 1.39817 6.13478 1.49036C6.26274 1.58256 6.36192 1.70918 6.42078 1.85551C6.43036 1.87884 6.43911 1.90551 6.45661 1.95801L6.46745 1.99009C6.47495 2.01217 6.47828 2.02342 6.48203 2.03301C6.5987 2.35592 6.94703 2.57467 7.28995 2.58301"
                  stroke="#939393"
                  stroke-width="0.6"
                />
                <path
                  d="M5.83203 5.70801H4.16536C4.11011 5.70801 4.05712 5.72996 4.01805 5.76903C3.97898 5.8081 3.95703 5.86109 3.95703 5.91634V6.81717C3.95704 6.85878 3.96951 6.89943 3.99283 6.93389C4.01614 6.96834 4.04924 6.99503 4.08786 7.01051L4.37953 7.12717C4.77699 7.28621 5.22041 7.28621 5.61786 7.12717L5.90953 7.01051C5.94815 6.99503 5.98125 6.96834 6.00457 6.93389C6.02789 6.89943 6.04035 6.85878 6.04036 6.81717V5.91634C6.04036 5.86109 6.01842 5.8081 5.97935 5.76903C5.94028 5.72996 5.88728 5.70801 5.83203 5.70801Z"
                  stroke="#939393"
                  stroke-width="0.6"
                  stroke-linecap="round"
                />
              </svg>
              {employmentType}
            </Box>
          </Stack>
        </Stack>
        <Stack direction="row" gap="10px">
          <Box
            onClick={() => {
              navigate(`/recruitment/candidate/all/${id}`);
            }}
            sx={{
              backgroundColor: "white",
              color: "#171717",
              textAlign: "center",
              fontStyle: "normal",
              borderRadius: "6px",
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: "normal",
              height: "max-content",
              letterSpacing: "0.2px",
              paddingX: "10px",
              paddingY: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            Find Candidate
          </Box>
          <Box
            sx={{
              backgroundColor: "#51B15C",
              color: "white",
              textAlign: "center",
              fontStyle: "normal",
              height: "max-content",
              borderRadius: "10px",
              fontSize: "12px",
              fontWeight: 600,
              lineHeight: "normal",
              letterSpacing: "0.2px",
              paddingX: "10px",
              paddingY: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => setIsOpen(true)}
          >
            Jobs Detail
          </Box>
        </Stack>
      </Stack>

      <Card sx={{ marginTop: "20px" }}>
        <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
          <Box display={"flex"} justifyContent={"space-between"}>
            {/* --- Search --- */}
            <Search
              placeholder={
                lang === "Id"
                  ? "Ketik disini untuk mencari"
                  : "Type here to search"
              }
              setSearch={(e) => setKeyword(e)}
              page={page}
              setPage={(e) => setPage(e)}
            />

            <Stack direction="row" alignItems={"center"} gap={5}>
              <Stack direction="row" gap="6px" alignItems="center">
                <Typography color="primary" fontSize="12px" fontWeight={800}>
                  Total Candidates :
                </Typography>
                <Box
                  borderRadius="50%"
                  backgroundColor="#51B15C"
                  width={"20px"}
                  height={"20px"}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 800,
                      color: "white",
                    }}
                  >
                    {total}
                  </Typography>
                </Box>
              </Stack>
              <Stack direction="row" gap="10px">
                <SelectStatus
                  selected={selectedStatus}
                  setSelected={setSelectedStatus}
                />
                <SelectStage
                  selected={selectedStage}
                  setSelected={setSelectedStage}
                />
                <SelectYear
                  selectedYear={selectedYear}
                  setSelectedYear={setSelectedYear}
                  year={year}
                />
              </Stack>
            </Stack>
          </Box>

          {/* --- Table --- */}
          <Box sx={{ mt: "24px", mb: "16px" }}>
            <TableComponent
              isLoading={isLoading}
              tbody={attendance}
              TableHead={
                <RecruitmentThead
                  thead={DataTableCandidate}
                  setOrder={setOrder}
                  setDirection={setDirection}
                  direction={direction}
                />
              }
              TableBody={
                <RecruitmentTbody
                  tbody={attendance}
                  setData={setattendance}
                  onSuccess={fetchData}
                />
              }
              col={6}
            />
          </Box>

          {/* --- Pagination --- */}
          <Box display={"flex"} justifyContent={"space-between"}>
            <Stack direction="row" gap="30px">
              <Stack direction="row" gap="10px" alignItems="center">
                <Typography
                  sx={{
                    color: "#171717",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.2px",
                  }}
                >
                  Bad
                </Typography>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="21"
                  fill="none"
                  viewBox="0 0 24 21"
                >
                  <path
                    fill="#171717"
                    fillRule="evenodd"
                    d="M5.291 3.82A3.024 3.024 0 018.315.796h3.024a3.024 3.024 0 013.023 3.024v.206c.94.086 1.872.199 2.796.337 1.465.22 2.495 1.494 2.495 2.94v3.057c0 1.22-.74 2.37-1.95 2.773a24.92 24.92 0 01-7.876 1.27 24.914 24.914 0 01-7.876-1.27C.74 12.73 0 11.58 0 10.36V7.303c0-1.446 1.03-2.722 2.495-2.94.929-.14 1.861-.251 2.796-.337V3.82zm7.56 0v.09a49.877 49.877 0 00-6.048 0v-.09a1.512 1.512 0 011.512-1.512h3.024A1.512 1.512 0 0112.85 3.82zm-3.024 8.314a.756.756 0 100-1.512.756.756 0 000 1.512z"
                    clipRule="evenodd"
                  ></path>
                  <path
                    fill="#171717"
                    d="M.754 17.073v-2.818c.23.125.47.23.719.312a26.431 26.431 0 008.352 1.347c2.915 0 5.725-.472 8.352-1.346.254-.084.494-.19.719-.313v2.818c0 1.463-1.056 2.75-2.543 2.946-2.137.284-4.316.43-6.528.43-2.183 0-4.364-.143-6.528-.43-1.488-.197-2.543-1.483-2.543-2.946z"
                  ></path>
                  <rect
                    width="12.095"
                    height="12.095"
                    x="11.906"
                    y="8.166"
                    fill="#fff"
                    rx="6.047"
                  ></rect>
                  <path
                    fill="#171717"
                    d="M17.953 13.546l2.333-2.333.667.667-2.333 2.333 2.333 2.334-.667.666-2.333-2.333-2.334 2.333-.666-.667 2.334-2.333-2.334-2.333.666-.666 2.334 2.333z"
                  ></path>
                </svg>
              </Stack>
              <Stack direction="row" gap="10px" alignItems="center">
                <Typography
                  sx={{
                    color: "#171717",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.2px",
                  }}
                >
                  Recommended
                </Typography>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="22"
                  fill="none"
                  viewBox="0 0 24 22"
                >
                  <path
                    fill="#171717"
                    fillRule="evenodd"
                    d="M5.291 3.936c0-.833.319-1.632.886-2.22a2.968 2.968 0 012.138-.92h3.024c.801 0 1.57.33 2.138.92.567.588.885 1.387.885 2.22v.214c.94.09 1.872.207 2.796.35 1.465.228 2.495 1.552 2.495 3.053v3.174c0 1.268-.74 2.462-1.95 2.88a24.08 24.08 0 01-7.876 1.319 24.073 24.073 0 01-7.876-1.318C.74 13.189 0 11.995 0 10.728V7.552c0-1.5 1.03-2.826 2.495-3.053.929-.144 1.861-.26 2.796-.35v-.214zm7.56 0v.094a48.035 48.035 0 00-6.048 0v-.094c0-.417.16-.816.443-1.11.283-.295.668-.46 1.069-.46h3.024c.4 0 .785.165 1.069.46.283.294.442.693.442 1.11zM9.826 12.57c.2 0 .393-.083.534-.23a.8.8 0 00.222-.555.801.801 0 00-.222-.556.742.742 0 00-.534-.23c-.2 0-.393.083-.535.23a.8.8 0 00-.221.556.8.8 0 00.221.555c.142.147.334.23.535.23z"
                    clipRule="evenodd"
                  ></path>
                  <path
                    fill="#171717"
                    d="M.754 17.698V14.77c.23.13.47.24.719.325a25.54 25.54 0 008.352 1.398c2.915 0 5.725-.49 8.352-1.397a4.3 4.3 0 00.719-.325v2.926c0 1.52-1.056 2.855-2.543 3.06a47.678 47.678 0 01-6.528.446c-2.183 0-4.364-.149-6.528-.447-1.488-.204-2.543-1.54-2.543-3.06z"
                  ></path>
                  <rect
                    width="12.095"
                    height="12.56"
                    x="11.906"
                    y="8.449"
                    fill="#fff"
                    rx="6.047"
                  ></rect>
                  <path
                    fill="#171717"
                    fillRule="evenodd"
                    d="M14.953 13.73l-1 1 3 3 5-5-1-1-4 4-2-2z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Stack>
              <Stack direction="row" gap="10px" alignItems="center">
                <Typography
                  sx={{
                    color: "#171717",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.2px",
                  }}
                >
                  Hold
                </Typography>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="21"
                  fill="none"
                  viewBox="0 0 24 21"
                >
                  <path
                    fill="#171717"
                    fillRule="evenodd"
                    d="M5.291 3.82A3.024 3.024 0 018.315.796h3.024a3.024 3.024 0 013.023 3.024v.206c.94.086 1.872.199 2.796.337 1.465.22 2.495 1.494 2.495 2.94v3.057c0 1.22-.74 2.37-1.95 2.773a24.92 24.92 0 01-7.876 1.27 24.914 24.914 0 01-7.876-1.27C.74 12.73 0 11.58 0 10.36V7.303c0-1.446 1.03-2.722 2.495-2.94.929-.14 1.861-.251 2.796-.337V3.82zm7.56 0v.09a49.877 49.877 0 00-6.048 0v-.09a1.512 1.512 0 011.512-1.512h3.024A1.512 1.512 0 0112.85 3.82zm-3.024 8.314a.756.756 0 100-1.512.756.756 0 000 1.512z"
                    clipRule="evenodd"
                  ></path>
                  <path
                    fill="#171717"
                    d="M.754 17.073v-2.818c.23.125.47.23.719.312a26.431 26.431 0 008.352 1.347c2.915 0 5.725-.472 8.352-1.346.254-.084.494-.19.719-.313v2.818c0 1.463-1.056 2.75-2.543 2.946-2.137.284-4.316.43-6.528.43-2.183 0-4.364-.143-6.528-.43-1.488-.197-2.543-1.483-2.543-2.946z"
                  ></path>
                  <rect
                    width="12.095"
                    height="12.095"
                    x="11.906"
                    y="8.166"
                    fill="#fff"
                    rx="6.047"
                  ></rect>
                  <path
                    stroke="#171717"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.8"
                    d="M15.02 15.28v-2.934a.533.533 0 011.066 0v1.867-2.933a.533.533 0 111.067 0v2.666-3.2a.533.533 0 011.066 0v3.2m0 .017v-2.417a.533.533 0 111.067 0v3.733"
                  ></path>
                  <path
                    stroke="#171717"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.8"
                    d="M15.02 15.28c0 1.956 1.066 2.933 2.533 2.933 1.466 0 2.061-.66 2.4-1.467l.878-2.4c.111-.3.06-.58-.198-.726-.258-.147-.598-.071-.738.195l-.609 1.465"
                  ></path>
                </svg>
              </Stack>
              <Stack direction="row" gap="10px" alignItems="center">
                <Typography
                  sx={{
                    color: "#171717",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.2px",
                  }}
                >
                  Unstatus
                </Typography>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  fill="none"
                  viewBox="0 0 20 21"
                >
                  <path
                    fill="#171717"
                    fillRule="evenodd"
                    d="M5.295 3.82A3.024 3.024 0 018.32.796h3.023a3.024 3.024 0 013.024 3.024v.206c.94.086 1.872.199 2.796.337 1.465.22 2.495 1.494 2.495 2.94v3.057c0 1.22-.74 2.37-1.95 2.773a24.92 24.92 0 01-7.876 1.27 24.914 24.914 0 01-7.876-1.27C.744 12.73.004 11.58.004 10.36V7.303c0-1.446 1.03-2.722 2.495-2.94.929-.14 1.861-.251 2.796-.337V3.82zm7.56 0v.09a49.877 49.877 0 00-6.048 0v-.09A1.512 1.512 0 018.32 2.308h3.023a1.512 1.512 0 011.512 1.512zM9.83 12.134a.756.756 0 100-1.512.756.756 0 000 1.512z"
                    clipRule="evenodd"
                  ></path>
                  <path
                    fill="#171717"
                    d="M.758 17.073v-2.818c.23.125.47.23.718.312a26.431 26.431 0 008.353 1.347c2.914 0 5.724-.472 8.352-1.346.254-.084.494-.19.718-.313v2.818c0 1.463-1.055 2.75-2.542 2.946-2.137.284-4.316.43-6.528.43-2.183 0-4.364-.143-6.528-.43-1.488-.197-2.543-1.483-2.543-2.946z"
                  ></path>
                </svg>
              </Stack>
            </Stack>
            <Stack direction="row">
              <RowPerPage
                setPageSize={setPageSize}
                pageSize={pageSize}
                setPage={setPage}
              />
              <Pagination page={page} setPage={setPage} lastPage={lastPage} />
            </Stack>
          </Box>
        </Box>
        <ModalJobsDescription
          isOpen={isOpen}
          detail={{ title, description }}
          onClose={() => setIsOpen(false)}
        />
      </Card>
    </Box>
  );
};

export default TableCandidate;
