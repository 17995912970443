import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getEmployeesById } from "../../../../../configs/axios/apiPath";
import { ImgNote } from "../../../../../assets/employee";

const EmployeeNote = () => {
  const path = useLocation();
  const pathname = path.pathname;
  const paths = pathname.split("/");
  const id = paths[4];

  const [dataEmployee, setDataEmployee] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        include: "note",
      };
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await getEmployeesById(id, params);

        setDataEmployee(data.note);
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id, navigate]);

  return (
    <Box mt={"30px"}>
      {isLoading ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          my={"30px"}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {dataEmployee?.length !== 0 ? (
            <Box>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"18px"}
                lineHeight={"25px"}
                letterSpacing={"1px"}
                color={"#51B15C"}
                mb={"16px"}
              >
                Note
              </Typography>
              {dataEmployee?.map((item, idx) => (
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"15px"}
                  lineHeight={"20px"}
                  letterSpacing={"1px"}
                  color={"#171717"}
                  key={idx}
                >
                  {item.note}
                </Typography>
              ))}
            </Box>
          ) : (
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <img
                src={ImgNote}
                alt="cvSaya"
                width={"327px"}
                style={{ paddingTop: "30px", paddingBottom: "30px" }}
              />
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"16px"}
                lineHeight={"22px"}
                letterSpacing={"1px"}
                color={"#939393"}
              >
                No records yet
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default EmployeeNote;
