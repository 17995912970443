import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Card } from "../../../../../../components/atoms";
import { useSelector } from "react-redux";
import { getCompany } from "../../../../../../features/companies";
import {
  addShiftRecurring,
  deleteShiftRecurring,
  getEmployeesShift,
  getShift,
} from "../../../../../../configs/axios/apiPath";
import { useLocation, useNavigate } from "react-router-dom";
import { IconDelete } from "../../../../../../assets/commons";
import { ArrowBackIos } from "@mui/icons-material";
import debounce from "lodash/debounce";

const EditShiftOccuring = () => {
  const [isLoading, setIsLoading] = useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });

  const [shift, setShift] = useState([]);
  const [recurring, setRecurring] = useState([]);
  const [status, setStatus] = useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });

  const [selectedShift, setSelectedShift] = useState({
    sunday: null,
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
  });

  const company = useSelector(getCompany);
  const navigate = useNavigate();

  const path = useLocation();
  const pathname = path.pathname;
  const paths = pathname.split("/");
  const employeeId = paths[4];

  const handleSelectShift = async (event, value, day) => {
    setIsLoading((prevState) => ({
      ...prevState,
      [day.toLowerCase()]: true,
    }));
    setSelectedShift((prevState) => ({
      ...prevState,
      [day.toLowerCase()]: value,
    }));

    event.preventDefault();

    let shiftId = value.id;

    const dayToNumber = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };

    const days = dayToNumber[day];

    if (shiftId && employeeId && days !== null) {
      const data = {
        employee_id: employeeId,
        day: days,
        shift_id: shiftId,
      };

      try {
        const response = await addShiftRecurring(data);
        if (response.data.meta.code === 20000) {
          setStatus((prevStatus) => ({ ...prevStatus, sunday: true }));
        }
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          localStorage.removeItem("cvDataLogin");
          navigate(`/users`);
        }
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
        };
        const {
          data: { data },
        } = await getShift({
          page_size: 1000,
          company_id: company,
        });
        setShift(data.shifts);
        try {
        } catch (error) {
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        }
      }
    };
    fetchData();
  }, [company, navigate]);

  // const onSearchShift = debounce(async (search) => {
  //   const {
  //     data: { data },
  //   } = await getShift({
  //     company_id: company,
  //     search,
  //   });
  //   setShift(data.shifts);
  // }, 700);

  const handleDelete = async (id, day, event) => {
    setIsLoading((prevState) => ({
      ...prevState,
      [day.toLowerCase()]: true,
    }));
    event.currentTarget.disabled = true;
    if (id) {
      try {
        const { data } = await deleteShiftRecurring(id);
        data?.data &&
          setStatus((prevState) => ({
            ...prevState,
            [day.toLowerCase()]: false,
          }));
        setSelectedShift((prevState) => ({
          ...prevState,
          [day.toLowerCase()]: null,
        }));
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      }
    }
  };
  // --- Shift Recurring List ---
  useEffect(() => {
    const fetchData = async () => {
      if (employeeId) {
        const params = {
          type: "recurring",
          year: "2023",
        };
        try {
          const {
            data: { data },
          } = await getEmployeesShift(employeeId, params);
          setRecurring(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading({
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
          });
        }
      }
    };
    fetchData();
  }, [employeeId, navigate, status]);
  return (
    <Card sx={{ p: "20px" }}>
      <Box p={"20px"}>
        <Box
          borderBottom={"0.2px solid #939393"}
          p={"0px 0px 15px 0px"}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIos color="primary" />
          </IconButton>
          <Typography
            fontWeight={600}
            fontSize={"20px"}
            lineHeight={"27px"}
            letterSpacing={"0.01em"}
            color={"#171717"}
          >
            Edit
          </Typography>
        </Box>

        <Grid container columns={2} mt={"20px"} spacing={2}>
          <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
            {recurring &&
              recurring.map((item, idx) => (
                <Box key={idx} mt={"20px"}>
                  <InputLabel
                    style={{
                      fontFamily: "sans-serif",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "13px",
                      lineHeight: "18px",
                      letterSpacing: "1px",
                      marginBottom: "6px",
                    }}
                  >
                    {item.profile}
                  </InputLabel>

                  <Grid
                    container
                    columns={8}
                    spacing={2}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item xl={7} lg={6} md={6} sm={6} xs={6}>
                      <Autocomplete
                        noOptionsText="Shift empty"
                        blurOnSelect
                        // onInputChange={(v) => onSearchShift(v.target.value)}
                        options={shift}
                        value={
                          item.id === null
                            ? item.profile === "Sunday"
                              ? selectedShift.sunday
                              : item.profile === "Monday"
                              ? selectedShift.monday
                              : item.profile === "Tuesday"
                              ? selectedShift.tuesday
                              : item.profile === "Wednesday"
                              ? selectedShift.wednesday
                              : item.profile === "Thursday"
                              ? selectedShift.thursday
                              : item.profile === "Friday"
                              ? selectedShift.friday
                              : selectedShift.saturday
                            : shift.find(
                                (shiftItem) => shiftItem.id === item.shift_id
                              ) ?? null
                        }
                        getOptionLabel={(option) =>
                          `${option.name} : ${option?.clock_in.substring(
                            0,
                            option?.clock_in.length - 3
                          )} - ${option?.clock_out.substring(
                            0,
                            option?.clock_out.length - 3
                          )}` ?? ""
                        }
                        onChange={(event, value) =>
                          handleSelectShift(event, value, item.profile)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Shift"
                            InputLabelProps={{ htmlFor: "shift-input" }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xl={1} lg={2} md={2} sm={2} xs={2}>
                      <Box
                        sx={{
                          alignItems: "center",
                          width: "88px",
                          background:
                            item.shift !== "-"
                              ? "rgba(81, 177, 92, 0.2)"
                              : "rgba(255, 29, 29, 0.2)",
                          color: item.shift !== "-" ? "#51B15C" : "#FF1D1D",
                          py: "6px",
                          border:
                            !isLoading[item.profile.toLowerCase()] &&
                            (item.shift !== "-"
                              ? "0.4px solid #51B15C"
                              : "0.4px solid #FF1D1D"),
                          borderRadius: "6px",
                          position: "relative",
                        }}
                      >
                        {isLoading[item.profile.toLowerCase()] ? (
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <CircularProgress size="1.5rem" />
                          </Box>
                        ) : (
                          <>
                            {item.id !== null && (
                              <IconButton
                                sx={{
                                  p: "0px",
                                  position: "absolute",
                                  top: -8,
                                  right: -8,
                                }}
                                onClick={(event) =>
                                  handleDelete(item.id, item.profile, event)
                                }
                              >
                                <IconDelete />
                              </IconButton>
                            )}
                            <Typography
                              fontFamily={"sans-serif"}
                              fontStyle={"normal"}
                              fontWeight={600}
                              fontSize={"10px"}
                              lineHeight={"12px"}
                              letterSpacing={"0.02em"}
                              textAlign={"center"}
                            >
                              {item.profile === "Sunday"
                                ? item.shift !== "-"
                                  ? "Active"
                                  : "Not Active"
                                : item.profile === "Monday"
                                ? item.shift !== "-"
                                  ? "Active"
                                  : "Not Active"
                                : item.profile === "Tuesday"
                                ? item.shift !== "-"
                                  ? "Active"
                                  : "Not Active"
                                : item.profile === "Wednesday"
                                ? item.shift !== "-"
                                  ? "Active"
                                  : "Not Active"
                                : item.profile === "Thursday"
                                ? item.shift !== "-"
                                  ? "Active"
                                  : "Not Active"
                                : item.profile === "Friday"
                                ? item.shift !== "-"
                                  ? "Active"
                                  : "Not Active"
                                : item.shift !== "-"
                                ? "Active"
                                : "Not Active"}
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))}
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default EditShiftOccuring;
