import {
  Box,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { capitalizeFirstLetter } from '../../../../utils/format';
import { IconHRCalendar } from '../../../../assets/sideNav/icons';
import { useEffect } from 'react';

export default function PayrollDetailTitle({ item, data, setEmployeeId }) {
  const [selectPayroll, setSelectPayroll] = useState('');
  const dispatch = useDispatch();
  const [value, setValue] = useState(() => {
    const parsedData = JSON.parse(localStorage.getItem('cvSayaData'));
    const id = parsedData && parsedData.idEmployee;
    return id ? parseInt(id) : '';
  });

  const handleChange = (event) => {
    setSelectPayroll(event.target.value);
    setValue(event.target.value);
  };

  const handleNavigate = (id) => {
    setEmployeeId(id);
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('cvSayaData'));

    if (!data || !data.idEmployee) {
      if (value !== '') {
        const newData = { ...data, idEmployee: value.toString() };
        localStorage.setItem('cvSayaData', JSON.stringify(newData));
      }
    } else if (value !== '') {
      data.idEmployee = value.toString();
      localStorage.setItem('cvSayaData', JSON.stringify(data));
    }
  }, [value]);
  return (
    <Box
      display={'flex'}
      alignItems={'start'}
      justifyContent={'space-between'}
      mb={'16px'}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'start'}
        justifyContent={'center'}
      >
        <Typography
          fontSize={'16px'}
          fontWeight={600}
          lineHeight={'19px'}
          letterSpacing={'1px'}
          color={'#171717'}
          mb={'4px'}
        >
          {item?.name}
        </Typography>
        <Typography
          fontSize={'14px'}
          fontWeight={400}
          lineHeight={'16px'}
          letterSpacing={'1px'}
          color={'#939393'}
          mb={'6px'}
        >
          {item?.position?.name}
        </Typography>
        <Typography
          fontSize={'12px'}
          fontWeight={600}
          lineHeight={'14px'}
          letterSpacing={'1px'}
          color={'#171717'}
        >
          Employee ID: {item?.id} | Employee Type:{' '}
          {capitalizeFirstLetter(item?.type)}
        </Typography>
      </Box>

      <Select
        value={selectPayroll}
        onChange={handleChange}
        sx={{
          background: '#f9f9f9',
          height: '30px',
          '.MuiSelect-root': {
            border: '1px solid red',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
        }}
      >
        {data &&
          data.map((item, idx) => (
            <MenuItem
              value={item}
              key={idx}
              onClick={() => handleNavigate(item?.id)}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                {item?.payroll_period?.name} (
                {moment(item?.payroll_period?.started).format('DD/MMM/YYYY')}-
                {moment(item?.payroll_period?.ended).format('DD/MMM/YYYY')})
              </Box>
            </MenuItem>
          ))}
      </Select>
    </Box>
  );
}
