import { Box } from "@mui/material"
import { SummaryChartActivities, SummaryTableActivities } from "../../../../components/organisms/project"

const IndexActivities = () => {
  return(
    <Box>
      <SummaryChartActivities/>
      <SummaryTableActivities/>
    </Box>
  )
}

export default IndexActivities