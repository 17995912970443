import React, { useEffect, useState, useRef } from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Box, width } from "@mui/system";
import "./calendar.css";
import Card from "../card";
import { Avatar, AvatarGroup, Popover, Typography, Stack } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import { getCompany } from "../../../features/companies";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getLanguage } from "../../../features/language";
import { fetchIndexCalendar } from "../../../features/calendar";
import { ModalDelete } from "../../molecules/modal";
import { handleAction } from "../../../utils/function/action";
import { getCalendarById } from "../../../configs/axios/apiPath";

import LinearProgress from "@mui/material/LinearProgress";
import { IconDelete, IconEdit } from "../../../assets/commons";
import TooltipsAction from "../tooltips/tooltipsAction";
import { IconDate } from "../../../assets/coreHr/awards";
import { IconTime } from "../../../assets/hrCalendar";
import dayjs from "dayjs";
var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);
function useCurrentMinute() {
  const [minutes, setMinutes] = useState(dayjs().format("mm"));

  useEffect(() => {
    const updateMinutes = () => {
      setMinutes(dayjs().format("mm"));
    };

    const now = dayjs();
    const secondsUntilNextMinute = 60 - now.second();

    // Set the initial interval to update after remaining seconds until the next minute
    setTimeout(() => {
      updateMinutes();

      // Set a repeating interval to update every 60 seconds
      const repeatingIntervalId = setInterval(updateMinutes, 60000);

      // Clear the repeating interval when the component unmounts
      return () => {
        clearInterval(repeatingIntervalId);
      };
    }, secondsUntilNextMinute * 1000);
  }, []);

  return minutes;
}
function getColor(color, type) {
  switch (color) {
    case "red":
      return type === "primary" ? "#E40A0A" : "#FFE8E8";

    case "blue":
      return type === "primary" ? "#352CCF" : "#E7EFFA";

    case "green":
      return type === "primary" ? "#51B15C" : "#EEF7EF";
    default:
      return type === "primary" ? "#939393" : "#F3F3F3";
  }
}

const MyCalendar = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const itemId = searchParams.get("id");
  const startDate = searchParams.get("start_date");
  const currentMinute = useCurrentMinute();

  const [isAddEventOpen, setIsAddEventOpen] = useState(false);
  const [newEventTitle, setNewEventTitle] = useState("");
  const [idToShow, setIdToShow] = useState();
  const [newEventDate, setNewEventDate] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idEvent, setIdEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeDate, setActiveDate] = useState(
    dayjs(startDate ?? undefined).format("YYYY-MM"),
  );
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [popoverAnchorEventEl, setPopoverAnchorEventEl] = useState(null);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const openPopover = Boolean(popoverAnchorEl);
  const openPopoverEvents = Boolean(popoverAnchorEventEl);
  const id = openPopover ? "simple-popover" : undefined;
  const calendarRef = useRef(null);
  const eventsRef = useRef(null);

  const dispatch = useDispatch();
  const events = useSelector((state) => state.calendar.data);
  const eventDetail = popoverAnchorEventEl
    ? events.find(
        (e) => e.event_id === parseInt(popoverAnchorEventEl.getAttribute("id")),
      )
    : null;
  const handleClick = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setPopoverAnchorEl(null);
  };

  const handleClickEvent = (event) => {
    if (isMoreOpen) {
      const gridcellWithChildrenDataIdMinusOne = document.querySelector(
        `[data-id="${event.currentTarget.getAttribute("id")}"]`,
      );
      setPopoverAnchorEl(gridcellWithChildrenDataIdMinusOne);
    }
    setPopoverAnchorEventEl(event.currentTarget);
    setIdToShow(parseInt(event.currentTarget.getAttribute("id")));
  };

  const handleCloseEvent = () => {
    setPopoverAnchorEventEl(null);
  };

  const closeAddEventForm = () => {
    setIsAddEventOpen(false);
  };

  useEffect(() => {
    if (company) {
      const params = {
        company_id: company,
        date: activeDate,
      };
      setIsLoading(true);
      try {
        dispatch(fetchIndexCalendar(params));
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate("/users");
        }
      } finally {
        setIsLoading(false);
      }
    }
  }, [dispatch, company, navigate, activeDate, currentMinute]);

  const handleNavigate = (url) => {
    navigate("/calendar-hr/hr-calendar/add");
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!idToShow) return;
      try {
        await getCalendarById(idToShow);
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      }
    };
    fetchData();
  }, [idToShow]);

  useEffect(() => {
    if (
      itemId &&
      startDate &&
      events.length > 0 &&
      dayjs(activeDate).isSame(startDate, "month")
    ) {
      setActiveDate(dayjs(startDate).format("YYYY-MM"));
      calendarRef.current.calendar.gotoDate(
        dayjs(startDate).format("YYYY-MM-DD"),
      );
      const elementWithAriaDateValue = document.getElementById(
        dayjs(startDate).format("YYYY-MM-DD"),
      );
      if (elementWithAriaDateValue) {
        elementWithAriaDateValue.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        setTimeout(() => {
          setPopoverAnchorEl(elementWithAriaDateValue);
          setTimeout(() => {
            const elementEvent = document.getElementById(itemId);
            setPopoverAnchorEventEl(elementEvent);
          }, 100);
        }, 500);
      }
    }
  }, [itemId, startDate, events, activeDate]);
  return (
    <Card sx={{ marginTop: "20px" }}>
      <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
        <Box sx={{ mt: "24px", mb: "16px" }}>
          <FullCalendar
            ref={calendarRef}
            locale={lang === "Id" ? "id" : "en"}
            dayMaxEvents={3}
            headerToolbar={{
              start: "today",
              center: "prevYear prev title next nextYear",
              end: "addEvent",
            }}
            customButtons={{
              today: {
                click: () => {
                  navigate("/calendar-hr/hr-calendar");
                  calendarRef.current.calendar.today();
                  setActiveDate(
                    dayjs(calendarRef.current.calendar.getDate()).format(
                      "YYYY-MM",
                    ),
                  );
                },
                text: "Today",
              },
              next: {
                click: () => {
                  navigate("/calendar-hr/hr-calendar");
                  calendarRef.current.calendar.next();
                  setActiveDate(
                    dayjs(calendarRef.current.calendar.getDate()).format(
                      "YYYY-MM",
                    ),
                  );
                },
              },

              nextYear: {
                click: () => {
                  navigate("/calendar-hr/hr-calendar");
                  calendarRef.current.calendar.nextYear();
                  setActiveDate(
                    dayjs(calendarRef.current.calendar.getDate()).format(
                      "YYYY-MM",
                    ),
                  );
                },
              },

              prev: {
                click: () => {
                  calendarRef.current.calendar.prev();
                  setActiveDate(
                    dayjs(calendarRef.current.calendar.getDate()).format(
                      "YYYY-MM",
                    ),
                  );
                },
              },

              prevYear: {
                click: () => {
                  calendarRef.current.calendar.prevYear();
                  setActiveDate(
                    dayjs(calendarRef.current.calendar.getDate()).format(
                      "YYYY-MM",
                    ),
                  );
                },
              },
              addEvent: {
                text: "+ Add",
                click: handleNavigate,
              },
            }}
            plugins={[dayGridPlugin]}
            events={events}
            editable={true}
            droppable={true}
            eventDisplay="block" // Atau "auto" atau "list"
            displayEventTime={true}
            moreLinkClick={(args) => {
              setIsMoreOpen(true);
            }}
            eventDidMount={(e) => {
              if (e.event.extendedProps.event_id === -1) {
                setTimeout(() => {
                  const gridcellWithChildrenDataIdMinusOne =
                    document.querySelector(
                      '[role="gridcell"]:has([data-id="-1"])',
                    );
                  gridcellWithChildrenDataIdMinusOne.children[0].children[0].addEventListener(
                    "click",
                    (e) => {
                      setPopoverAnchorEl(e.target.children[1]);
                    },
                  );
                  if (
                    gridcellWithChildrenDataIdMinusOne.children[0].children[0]
                      .children.length === 1
                  ) {
                    const newParagraph = document.createElement("div");
                    newParagraph.textContent = events.find(
                      (e) => e.event_id === -1,
                    )?.title;
                    newParagraph.style.overflow = "hidden";
                    newParagraph.style.textOverflow = "ellipsis";
                    newParagraph.style.whiteSpace = "nowrap";
                    newParagraph.id = dayjs(e.event.start).format("YYYY-MM-DD");
                    newParagraph.setAttribute("data-id", "-1");
                    newParagraph.setAttribute("data-date", e.event.start);
                    gridcellWithChildrenDataIdMinusOne.children[0].children[0].appendChild(
                      newParagraph,
                    );

                    gridcellWithChildrenDataIdMinusOne.children[0].children[0].classList.add(
                      "fc-holiday",
                    );
                  }
                }, 100);
              }
            }}
            eventContent={({ event, isFuture }) => {
              return (
                <Box
                  aria-describedby={id}
                  onClick={handleClick}
                  data-date={event.start}
                  id={dayjs(event.start).format("YYYY-MM-DD")}
                  data-id={event.extendedProps.event_id}
                  sx={{
                    display:
                      event.extendedProps.event_id === -1 ? "none" : undefined,
                  }}
                >
                  <Typography
                    sx={{
                      color: getColor(
                        event.extendedProps.event_color,
                        "primary",
                      ),
                      fontWeight: 600,
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {event.title}
                  </Typography>

                  <Stack direction="row" alignItems="center" gap={2}>
                    <Typography
                      sx={{
                        color: getColor(
                          event.extendedProps.event_color,
                          "primary",
                        ),
                      }}
                    >
                      {dayjs(event.start).format("HH:mm")} -{" "}
                      {event.end ? dayjs(event.end).format("HH:mm") : "00:00"}
                    </Typography>

                    <AvatarGroup
                      max={4}
                      sx={{
                        "& .MuiAvatar-root": {
                          width: 20,
                          height: 20,
                          fontSize: 12,
                        },
                      }}
                    >
                      {event.extendedProps.members
                        .filter((e) => Boolean(e))
                        .map((member) => (
                          <Avatar
                            alt={member.name}
                            src={member.profile_picture}
                          />
                        ))}
                    </AvatarGroup>
                  </Stack>
                </Box>
              );
            }}
          />
        </Box>
        <Popover
          id={id}
          open={openPopover}
          anchorEl={popoverAnchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box
            display={"flex"}
            border="0.6px solid #51B15C"
            borderRadius="4px"
            flexDirection={"column"}
            gap={1}
            width="400px"
            padding={"10px"}
          >
            {/* tanggal */}
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
              color={"#51B15C"}
              fontWeight={700}
            >
              {popoverAnchorEl &&
                dayjs(popoverAnchorEl.getAttribute("data-date")).format(
                  "DD MMMM",
                )}
            </Box>

            {events
              .filter((e) => {
                return (
                  dayjs(e.start).format("YYYY-MMMM-DD") ===
                  dayjs(
                    popoverAnchorEl
                      ? popoverAnchorEl.getAttribute("data-date")
                      : "0000-00-00",
                  ).format("YYYY-MMMM-DD")
                );
              })
              .sort((a, b) => a.start < b.start)
              .map((e) => (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  onClick={handleClickEvent}
                  ref={eventsRef}
                  id={e.event_id}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: getColor(e.color, "secondary"),
                      padding: "16px",
                      borderRadius: "4px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                        gap: "5px",
                      }}
                    >
                      <Typography
                        fontFamily={"sans-serif"}
                        fontStyle={"normal"}
                        fontWeight={600}
                        fontSize={"12px"}
                        lineHeight={"14px"}
                        color={getColor(e.color, "primary")}
                        letterSpacing={"0.02em"}
                        textTransform={"none"}
                        sx={{
                          width: "100%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {e.title}
                      </Typography>
                      <Typography
                        fontFamily={"sans-serif"}
                        fontStyle={"normal"}
                        fontWeight={400}
                        fontSize={"12px"}
                        lineHeight={"14px"}
                        color={getColor(e.color, "primary")}
                        letterSpacing={"0.02em"}
                        textTransform={"none"}
                      >
                        {dayjs(e.start).format("HH:mm")} -{" "}
                        {dayjs(e.end).format("HH:mm")}
                      </Typography>
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      {e.event_id !== -1 ? (
                        <LinearProgress
                          variant="determinate"
                          value={e.percentage}
                          sx={{
                            bgcolor: "white",
                            borderRadius: "8px", // Adding rounded corners
                            "& .MuiLinearProgress-barColorPrimary": {
                              bgcolor: getColor(e.color, "primary"),
                            },
                          }}
                        />
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </Popover>
        <Popover
          id={id}
          open={openPopoverEvents}
          anchorEl={popoverAnchorEventEl}
          onClose={handleCloseEvent}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {eventDetail && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={1}
              padding="10px"
              minWidth="250px"
            >
              {/* Section 1 */}
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                marginBottom={1}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"15px"}
                  lineHeight={"20px"}
                  color="#000000"
                  letterSpacing={"0.05em"}
                  maxWidth={"300px"}
                  sx={{ overflowWrap: "break-word" }}
                  marginRight={1}
                >
                  {eventDetail.title}
                </Typography>

                {eventDetail.event_id !== -1 ? (
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    {/* --- Delete --- */}
                    <TooltipsAction
                      title="Delete"
                      // open={isTooltipDelete && item.id === idTooltipDelete}
                      arrow
                      placement={"bottom-end"}
                    >
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          handleAction(
                            eventDetail.event_id,
                            setIsModalOpen,
                            setIsModalOpen,
                            setIdEvent,
                            "delete",
                          )
                        }
                      >
                        <IconDelete />
                      </Box>
                    </TooltipsAction>

                    {/* --- Edit --- */}
                    <TooltipsAction
                      title="Edit"
                      // open={isTooltipEdit && item.id === idTooltipEdit}
                      arrow
                      placement={"bottom-end"}
                    >
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(
                            "/calendar-hr/hr-calendar/" +
                              eventDetail.event_id.toString(),
                          );
                        }}
                      >
                        <IconEdit />
                      </Box>
                    </TooltipsAction>
                  </Box>
                ) : null}
              </Box>
              {/* Section 2 */}
              <Box display={"flex"} flexDirection={"column"} gap={1}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  gap={1}
                >
                  <IconDate />
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={400}
                    fontSize={"12px"}
                    lineHeight={"14px"}
                    color="#000000"
                    letterSpacing={"0.02em"}
                    textTransform={"none"}
                  >
                    {dayjs(eventDetail.start).format("dddd, D MMMM YYYY")}
                  </Typography>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  gap={1}
                >
                  <IconTime />
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={400}
                    fontSize={"12px"}
                    lineHeight={"14px"}
                    color="#000000"
                    letterSpacing={"0.02em"}
                    textTransform={"none"}
                  >
                    {dayjs(eventDetail.start).format("HH:mm")} -{" "}
                    {dayjs(eventDetail.end).format("HH:mm")}
                  </Typography>
                </Box>
              </Box>

              {/* Section 3 */}
              <Box>
                {eventDetail.event_id !== -1 ? (
                  <LinearProgress
                    variant="determinate"
                    value={eventDetail.percentage}
                    sx={{
                      bgcolor: "white",
                      border: "1px solid rgba(128, 128, 128, 0.7)",
                      height: 10,
                      marginTop: 1,
                      borderRadius: "8px", // Adding rounded corners
                      "& .MuiLinearProgress-barColorPrimary": {
                        bgcolor: getColor(eventDetail.color, "primary"),
                      },
                    }}
                  />
                ) : null}
              </Box>
              {/* Section 4 */}

              {eventDetail.event_id !== -1 ? (
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  marginTop={1}
                  gap={1}
                >
                  <AvatarGroup
                    max={4}
                    sx={{
                      "& .MuiAvatar-root": {
                        width: 24,
                        height: 24,
                        fontSize: 15,
                      },
                    }}
                  >
                    {eventDetail.members
                      .filter((e) => Boolean(e))
                      .map((member) => (
                        <Avatar
                          alt={member.name}
                          src={member.profile_picture}
                        />
                      ))}
                  </AvatarGroup>

                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={600}
                    fontSize={"14px"}
                    lineHeight={"14px"}
                    color="#000000"
                    letterSpacing={"0.02em"}
                    textTransform={"none"}
                  >
                    {eventDetail.members.filter((e) => Boolean(e)).length}{" "}
                    Members
                  </Typography>
                </Box>
              ) : null}

              {/* Section 5 */}
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={1}
                marginTop={1}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"14px"}
                  lineHeight={"14px"}
                  color="#000000"
                  letterSpacing={"0.02em"}
                  textTransform={"none"}
                >
                  Description
                </Typography>
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={400}
                  fontSize={"12px"}
                  lineHeight={"14px"}
                  color="#000000"
                  letterSpacing={"0.02em"}
                  textTransform={"none"}
                >
                  {eventDetail.description}
                </Typography>
              </Box>
            </Box>
          )}
        </Popover>
        <Box
          display={"flex"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"start"}
          gap={5}
        >
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <div
              style={{
                backgroundColor: "#51B15C",
                width: 10,
                height: 10,
                borderRadius: "100%",
              }}
            ></div>{" "}
            Procces
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <div
              style={{
                backgroundColor: "#C8C8CC",
                width: 10,
                height: 10,
                borderRadius: "100%",
              }}
            ></div>{" "}
            Attend
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <div
              style={{
                backgroundColor: "#E40A0A",
                width: 10,
                height: 10,
                borderRadius: "100%",
              }}
            ></div>{" "}
            Missed
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <div
              style={{
                backgroundColor: "#352CCF",
                width: 10,
                height: 10,
                borderRadius: "100%",
              }}
            ></div>{" "}
            Upcoming
          </div>
        </Box>

        {isAddEventOpen && (
          <div className="add-event-form">
            <input
              type="text"
              placeholder="Event Title"
              value={newEventTitle}
              onChange={(e) => setNewEventTitle(e.target.value)}
            />
            <input
              type="datetime-local"
              value={newEventDate}
              onChange={(e) => setNewEventDate(e.target.value)}
            />
            {/* <button onClick={handleAddEvent}>Add Event</button> */}
            <button onClick={closeAddEventForm}>Cancel</button>
          </div>
        )}
      </Box>
      <ModalDelete
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={"Calendar"}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        id={idEvent}
        data={events}
        onSuccess={() => {
          const params = {
            company_id: company,
            date: activeDate ?? dayjs().format("YYYY-MM"),
          };
          setIsLoading(true);
          try {
            dispatch(fetchIndexCalendar(params));
            handleClose();
            handleCloseEvent();
          } catch (error) {
            console.error(error);
            if (error.response.status === 401) {
              localStorage.removeItem("cvSayaLogin");
              navigate("/users");
            }
          } finally {
            setIsLoading(false);
          }
        }}
        setData={() => {}}
      />
    </Card>
  );
};

export default MyCalendar;
