import { Card, Typography, Divider, Grid, Stack } from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import { TextField, InputFile } from "../../../../components/atoms";
import { getLanguage } from "../../../../features/language";
import { useSelector } from "react-redux";
import {
  putCandidateInterview,
} from "../../../../configs/axios/apiPath";

import {  useState } from "react";

import { useNavigate, useLocation, useParams } from "react-router-dom";
const RecruitmentCandidateNote = () => {
  const lang = useSelector(getLanguage);
  const navigate = useNavigate();
  const { candidateId, id } = useParams();


  const [isLoading, setIsLoading] = useState(false);
  const [note, setNote] = useState();

  const handlePost = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await putCandidateInterview(
        { vacancy_id: id, candidate_id: candidateId },
        { note }
      );
      navigate(`/recruitment/candidate/${id}`);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card sx={{ padding: "20px" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        marginBottom={"16px"}
        alignItems="center"
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"20px"}
          lineHeight={"27px"}
          letterSpacing={"0.01em"}
          color={"#171717"}
        >
          Send Note
        </Typography>
      </Stack>

      <Divider />
      <form onSubmit={handlePost}>
        <Grid container spacing={"16px"} marginTop="16px">
          <Grid item xs={12}>
            <TextField
              label={lang !== "Id" ? "Note" : "Catatan"}
              fullWidth
              required
              type="text"
              size="small"
              onChange={(e) => {
                setNote(e.target.value);
              }}
              variant="outlined"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          sx={{ marginTop: "30px" }}
          loading={isLoading}
        >
          {lang === "Id" ? "Simpan" : "Save"}
        </LoadingButton>
      </form>
    </Card>
  );
};

export default RecruitmentCandidateNote;
