import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  Stack,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconDate, IconEmployee } from '../../../../assets/coreHr/awards';
import {
  IconFile,
  IconPaper,
  IconExpectedBudget,
} from '../../../../assets/benefit/reimbursement';
import { Card, TextField, InputFile } from '../../../../components/atoms';
import {
  getListEmployee,
  postReimbursement,
  uploadReimbursement,
} from '../../../../configs/axios/apiPath';
import { getCompany } from '../../../../features/companies';
import { getLanguage } from '../../../../features/language';

const BenefitReimbursementAdd = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [fileBlob, setFileBlob] = useState('');
  const [isSubmitted, setIsSubmit] = useState(false);
  const [total, setTotal] = useState(1);

  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const lang = useSelector(getLanguage);
  const placeHolderId = 'Ketik disini';
  const placeHolderEn = 'Type here';

  const [isLoading, setIsLoading] = useState(false);
  /* const MAX_IMAGE_SIZE = 1 * 1024 * 1024; */
  const [errorMsg, setErrorMsg] = useState('');
  /* const [errors, setErrors] = useState({}); */
  const [formData, setFormData] = useState({});

  // --- Handle Input Change ---
  const handleInputChange = (_, value) => {
    setFormData({ ...formData, inputValue: value });
  };

  // --- Handle Selected Employee ---
  const handleSelectEmployee = (_, value) => {
    setFormData({ ...formData, selectedEmployee: value });
  };
  const handleInputChange2 = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    /* setErrors((prevErrors) => ({ */
    /*   ...prevErrors, */
    /*   [name]: false, */
    /* })); */
  };

  // --- Handle Upload Image ---
  /* const handleFileChange = async (event) => { */
  /*   event.preventDefault(); */
  /*   const file = event.target.files[0]; */
  /**/
  /*   if (file) { */
  /*     if (file.size > MAX_IMAGE_SIZE) { */
  /*       const msgId = */
  /*         "Ukuran file melebihi batas maksimum. Silakan pilih file yang lebih kecil."; */
  /*       const msgEn = */
  /*         "File size exceeds the maximum limit. Please choose a smaller file."; */
  /*       if (lang === "Id") { */
  /*         setErrorMsg(msgId); */
  /*       } else { */
  /*         setErrorMsg(msgEn); */
  /*       } */
  /*       return; */
  /*     } */
  /*   } */
  /* }; */
  const handleFileUpload = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          data: { data },
        } = await uploadReimbursement({ file: fileBlob[0] });
        handlePost(data.file_url);
        resolve();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
        reject(error);
      } finally {
        setIsLoading(false);
      }
    });
  };
  // --- Handle Submit ---
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmit(true);
    try {
      setIsLoading(true);
      await handleFileUpload();
    } catch (error) {
      console.error(error);
    }
  };
  const handlePost = async (file_url) => {
    const data = {
      company_id: company,
      employee_id: formData.selectedEmployee?.id,
      reason: formData.reason,
      amount: formData.expected_budget,
      date: formData.date,
      file_url,
      description: formData.description,
    };
    setIsLoading(true);
    try {
      await postReimbursement(data);
      navigate('/benefit/reimbursement');
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        localStorage.removeItem('cvSayaLogin');
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          page_size: total,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListEmployee(params);

          setEmployeeData(data.employee);
          if (data.page_info.total > 0) {
            setTotal(data.page_info.total);
          }
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, total]);
  return (
    <Card>
      <Box p={'20px'}>
        <Box>
          <Typography
            fontFamily={'sans-serif'}
            fontStyle={'normal'}
            fontWeight={600}
            fontSize={'20px'}
            lineHeight={'27px'}
            letterSpacing={'0.01em'}
            color={'#171717'}
            marginBottom="15px"
          >
            {lang === 'Id' ? 'Tambah' : 'Add'}
          </Typography>
          <Divider />
        </Box>
        <form
          onSubmit={handleSubmit}
          style={{
            marginTop: '20px',
            fontWeight: '600px',
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '0.01em',
            color: '#171717',
          }}
        >
          {/* --- List Employee --- */}
          <Stack spacing={2}>
            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <Autocomplete
                  onChange={handleSelectEmployee}
                  onInputChange={handleInputChange}
                  options={employeeData}
                  getOptionLabel={(employee) => employee.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={lang === 'Id' ? 'Karyawan' : 'Employee'}
                      fullWidth
                      required
                      variant="outlined"
                      size="small"
                      placeholder={
                        lang === 'Id' ? placeHolderId : placeHolderEn
                      }
                      icon={
                        isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <IconEmployee />
                        )
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>

            {/* --- Date --- */}
            <Grid container columns={2}>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <TextField
                  label={lang === 'Id' ? 'Tanggal' : 'Date'}
                  fullWidth
                  required
                  type="date"
                  size="small"
                  variant="outlined"
                  onChange={handleInputChange2}
                  name="date"
                  icon={<IconDate />}
                />
              </Grid>
            </Grid>
            <Grid container columns={2}>
              {/* --- Reason --- */}
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <TextField
                  label={lang === 'Id' ? 'Alasan' : 'Reason'}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  placeholder={lang === 'Id' ? placeHolderId : placeHolderEn}
                  onChange={handleInputChange2}
                  name="reason"
                  icon={<IconPaper />}
                />
              </Grid>
            </Grid>
            <Grid container columns={2}>
              {/* --- Expected Budget --- */}
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <TextField
                  label={lang === 'Id' ? 'Ekspetasi budget' : 'Expected Budget'}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  type="number"
                  placeholder={lang === 'Id' ? placeHolderId : placeHolderEn}
                  onChange={handleInputChange2}
                  name="expected_budget"
                  icon={<IconExpectedBudget />}
                />
              </Grid>
            </Grid>

            {/* --- Image --- */}
            <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
              <InputFile
                onChange={(data) => setFileBlob(data)}
                value={fileBlob}
                setValue={setFileBlob}
                icon={<IconFile />}
                label={lang === 'Id' ? 'File' : 'File'}
                accept=".gif,.png,.pdf,.txt,.doc,.docx"
                allowedTypes={['gif', 'png', 'pdf', 'txt', 'doc', 'docx']}
              />
              {isSubmitted && errorMsg && (
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={400}
                  fontSize={'12px'}
                  lineHeight={'15px'}
                  letterSpacing={'1px'}
                  color={'red'}
                  my={'5px'}
                >
                  {errorMsg}
                </Typography>
              )}
            </Box>

            {/* --- Description --- */}
            <Grid container columns={2}>
              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                <TextField
                  label={lang === 'Id' ? 'Deskripsi' : 'Description'}
                  fullWidth
                  multiline
                  rows={4}
                  required
                  variant="outlined"
                  size="small"
                  name="description"
                  placeholder={lang === 'Id' ? placeHolderId : placeHolderEn}
                  onChange={handleInputChange2}
                  icon={<IconPaper />}
                />
              </Grid>
            </Grid>
          </Stack>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: '136px', marginTop: '21px' }}
          >
            {lang === 'Id' ? 'Simpan' : 'Save'}
          </Button>
        </form>
      </Box>
    </Card>
  );
};

export default BenefitReimbursementAdd;
