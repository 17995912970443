import { Box, Card, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../../../features/language';
import AttendanceChart from './attendanceChart';
import SelectFrequency from './selectFrequency';

const HomepageAttendance = () => {
  const lang = useSelector(getLanguage);

  return (
    <Card sx={{ padding: '20px  25px', mt: '20px' }}>
      <Box display={'flex'} justifyContent={'space-between'}>
        {/* --- Title --- */}
        <Typography
          fontWeight={600}
          fontSize={'20px'}
          lineHeight={'27px'}
          letterSpacing={'0.01em'}
          fontStyle={'normal'}
          fontFamily={'sans-serif'}
          color={'#171717'}
        >
          {lang === 'Id' ? 'Ringkasan Kehadiran' : 'Summary Attendance'}
        </Typography>
        {/* --- Select Frequency --- */}
        <SelectFrequency />
      </Box>

      {/* <LineChart /> */}
      <AttendanceChart />
    </Card>
  );
};

export default HomepageAttendance;
