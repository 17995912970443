import "./style.css";
import { useEffect, useState } from "react";

export default function SimpleTooltipContent({ sources = [] }) {
  return sources.map((a) => (
    <div className="tooltip-img">
      <div className="graph-tooltip-content">
        <div className="content-img">
          <img src={a.src} className="img" />
        </div>
        <div className="content-text">
          <p className="title-tooltip">{a.title}</p>
          <p className="desc-tooltip">{a.desc}</p>
        </div>
      </div>
    </div>
  ));
}
