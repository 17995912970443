import {
  IconCoreHR,
  IconDashboard,
  IconEmployee,
  IconFile,
  IconHRCalendar,
  IconInvoice,
  IconManageLeaves,
  IconManajerProyek,
  IconOrganization,
  IconPayroll,
  IconPerformanace,
  IconPerkiraan,
  IconRecruitment,
  IconTask,
  IconTimesheet,
  IconBenefit,
  IconDepartment,
  IconExport,
} from "../../../assets/sideNav/icons";

const MenuSideNavbar = [
  {
    title: "Dashboard",
    judul: "Dashboard",
    path: "/dashboard",
    icon: <IconDashboard />,
  },
  {
    title: "Employee",
    judul: "Karyawan",
    path: "/employee",
    icon: <IconEmployee />,
  },
  {
    title: "Payroll",
    judul: "Pembayaran",
    path: "/payroll",
    icon: <IconPayroll />,
  },
  {
    title: "Benefit",
    judul: "Keuntungan",
    path: "/benefit",
    icon: <IconBenefit />,
  },
  {
    title: "Core",
    judul: "Core",
    path: "/core-hr",
    icon: <IconCoreHR />,
  },
  {
    title: "Department",
    judul: "Department",
    path: "/organization",
    icon: <IconDepartment />,
  },
  {
    title: "Timesheet",
    judul: "Timesheet",
    path: "/timesheet",
    icon: <IconTimesheet />,
  },
  {
    title: "Calendar",
    judul: "HR Kalender",
    path: "/calendar-hr",
    icon: <IconHRCalendar />,
  },
  // {
  //   title: 'Task',
  //   judul: 'Projek',
  //   path: '/task',
  //   icon: <IconTask />,
  // },
  // {
  //   title: 'File',
  //   judul: 'File',
  //   path: '/file',
  //   icon: <IconFile />,
  // },
  // {
  //   title: 'Invoice',
  //   judul: 'Invoice',
  //   path: '/invoice',
  //   icon: <IconInvoice />,
  // },
  // {
  //   title: 'Manage Leaves',
  //   judul: 'Kelola Cabang',
  //   path: '/manage-leaves',
  //   icon: <IconManageLeaves />,
  // },
  {
    title: "Project",
    judul: "Manejer Proyek",
    path: "/manager-project",
    icon: <IconManajerProyek />,
  },
  // {
  //   title: 'Estimation',
  //   judul: 'Perkiraan',
  //   path: '/estimation',
  //   icon: <IconPerkiraan />,
  // },
  {
    title: "Recruitment",
    judul: "Rekrutmen",
    path: "/recruitment",
    icon: <IconRecruitment />,
  },

  {
    title: "Report",
    judul: "Report",
    path: "/report",
    icon: <IconExport />,
  },
  // {
  //   title: 'Performance',
  //   judul: 'Performa',
  //   path: '/performance',
  //   icon: <IconPerformanace />,
  // },
];

export default MenuSideNavbar;
