import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAnnouncementById,
  getListDepartments,
  postAnnouncement,
  updateAnnouncementById,
} from "../../../../configs/axios/apiPath";
import { getLanguage } from "../../../../features/language";
import { getCompany } from "../../../../features/companies";
import {
  IconCalendar,
  IconDescription,
} from "../../../../assets/coreHr/awards";
import { IconNote } from "../../../../assets/coreHr/announcement";
import { Card } from "../../../../components/atoms";
import { ValidateArray } from "../../../../utils/validate";
import CustomEditor from "../../../../components/organisms/editor";

const CoreHRAnnouncementEdit = () => {
  const [deptData, setDeptData] = useState([]);
  const [selectedDept, setSelectedDept] = useState([]);

  const [name, setName] = useState("");
  const [start, setStart] = useState("");
  const [description, setDescription] = useState(null);

  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const lang = useSelector(getLanguage);
  const placeholderId = "Ketik disini";
  const placeholderEn = "Type here";

  const [isFocusTitle, setIsFocusTitle] = useState(false);
  const [isFocusDepartment, setIsFocusDeparment] = useState(false);
  const [isFocusStart, setIsFocusStart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fixedOptions = [];

  const path = useLocation().pathname;
  const splitPath = path.split("/");
  const id = splitPath[4];

  //   --- Handle Submit ---
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      title: name,
      body: description,
      company_id: company,
      implementation_date: start,
      departments: selectedDept.map((obj) => obj.id),
    };
    setIsLoading(true);
    try {
      const response = await updateAnnouncementById(data, id);
      if (response.data.meta.code === 20000) {
        navigate("/core-hr/announcement");
      }
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  //   --- List Departments ---
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListDepartments(params);
          setDeptData(data?.departments);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company]);

  // --- Set Placeholder ---
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getAnnouncementById(id);
          setName(data?.title);
          setSelectedDept(data?.departments);
          setStart(data?.implementation_date);
          setDescription(data?.body);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, id]);
  return (
    <Card>
      <Box p={"20px"}>
        <Box borderBottom={"0.2px solid #939393"} p={"0px 0px 15px 0px"}>
          <Typography
            fontWeight={"600px"}
            fontSize={"20px"}
            lineHeight={"27px"}
            letterSpacing={"0.01em"}
            color={"#171717"}
          >
            Edit
          </Typography>
        </Box>
        <form
          onSubmit={handleSubmit}
          style={{
            marginTop: "20px",
            fontWeight: "600px",
            fontSize: "13px",
            lineHeight: "18px",
            letterSpacing: "0.01em",
            color: "#171717",
          }}
        >
          {/* --- Title --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={"flex"} flexDirection={"column"} mb={"16px"}>
                <InputLabel
                  style={{
                    fontFamily: "sans-serif",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    marginBottom: "6px",
                  }}
                >
                  {lang === "Id" ? "Judul" : "Title"}
                </InputLabel>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  placeholder={lang === "Id" ? placeholderId : placeholderEn}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  onFocus={() => setIsFocusTitle(false)}
                  onBlur={() => setIsFocusTitle(true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {isFocusTitle ? <IconNote /> : ""}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: {
                        xl: "60px",
                        lg: "45px",
                        md: "45px",
                        sm: "60px",
                        xs: "60px",
                      },
                      borderRadius: {
                        xl: "16px",
                        lg: "10px",
                        md: "16px",
                        sm: "16px",
                        xs: "16px",
                      },
                      background: "#f9f9f9",
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Departments --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                mb={
                  isFocusDepartment && !ValidateArray(selectedDept)
                    ? "0px"
                    : "16px"
                }
              >
                <InputLabel style={{ marginBottom: "5px" }}>
                  {lang === "Id" ? "Departemen" : "Department"}
                </InputLabel>

                <Autocomplete
                  multiple
                  value={selectedDept}
                  onChange={(event, newValue) => {
                    setSelectedDept([
                      ...fixedOptions,
                      ...newValue.filter(
                        (option) => fixedOptions.indexOf(option) === -1
                      ),
                    ]);
                  }}
                  options={deptData}
                  getOptionLabel={(option) => option.name}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        label={option.name}
                        {...getTagProps({ index })}
                        disabled={fixedOptions.indexOf(option) !== -1}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      variant="outlined"
                      size="medium"
                      placeholder={
                        lang === "id" ? placeholderId : placeholderEn
                      }
                      onFocus={() => setIsFocusDeparment(false)}
                      onBlur={() => setIsFocusDeparment(true)}
                      sx={{
                        background: "#f9f9f9",
                        "& .MuiOutlinedInput-root": {
                          background: "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                      }}
                      //   InputProps={{
                      //     ...params.InputProps,
                      //     startAdornment: (
                      //       <InputAdornment
                      //         position="start"
                      //         sx={{ padding: '7px' }}
                      //       >
                      //         {isFocusDepartment ? <IconDept />: ''}
                      //       </InputAdornment>
                      //     ),
                      //   }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          {isFocusDepartment && !ValidateArray(selectedDept) && (
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontWeight={400}
              fontSize={"12px"}
              lineHeight={"15px"}
              letterSpacing={"1px"}
              color={"red"}
              my={"5px"}
            >
              {lang === "Id" ? "Tidak boleh kosong" : "Can't empty"}
            </Typography>
          )}
          {/* --- Date --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={"flex"} flexDirection={"column"} mb={"16px"}>
                <InputLabel
                  style={{
                    fontFamily: "sans-serif",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    marginBottom: "6px",
                  }}
                >
                  {lang === "Id"
                    ? "Tanggal Implementasi"
                    : "Implementation Date"}
                </InputLabel>
                <TextField
                  required
                  fullWidth
                  type="date"
                  variant="outlined"
                  size="small"
                  value={start}
                  onChange={(e) => setStart(e.target.value)}
                  onFocus={() => setIsFocusStart(false)}
                  onBlur={() => setIsFocusStart(true)}
                  sx={{
                    background: "#f9f9f9",
                    "& .MuiOutlinedInput-root": {
                      background: "transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {isFocusStart ? <IconCalendar /> : ""}
                      </InputAdornment>
                    ),
                    style: {
                      height: "40px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Description --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                <InputLabel
                  style={{
                    fontFamily: "sans-serif",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    marginBottom: "6px",
                  }}
                >
                  {lang === "Id" ? "Deskripsi" : "Description"}
                </InputLabel>
                {description ? (
                  <CustomEditor
                    content={description}
                    setDescription={setDescription}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Grid>

          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => setIsFocusDeparment(true)}
            sx={{ width: "136px", marginTop: "21px" }}
          >
            {lang === "Id" ? "Simpan" : "Save"}
          </Button>
        </form>
      </Box>
    </Card>
  );
};

export default CoreHRAnnouncementEdit;
