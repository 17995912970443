import React, { useEffect, useState } from "react";
import DataTooltips from "../../../homepage/homepageAttendance/attendanceChart/dataTooltips";
import { Card, LineChart, DatePicker } from "../../../../atoms";
import SelectFrequency from "../../../../organisms/homepage/homepageAttendance/selectFrequency";
import { Box, Typography, Stack, Select, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCompany } from "../../../../../features/companies";
import {
  getSummaryRequest,
  getListDepartments,
} from "../../../../../configs/axios/apiPath";
import dayjs from "dayjs";

const ChartSumarry = () => {
  const company = useSelector(getCompany);

  const [deptData, setDeptData] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [frequency, setFrequency] = useState("Daily");
  const [dataChart, setDataChart] = useState({ labels: [], datasets: [] });
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          page_size: 9999999,
          company_id: company,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListDepartments(params);
          setSelectedDepartment(data?.departments[0].id);
          setDeptData(data?.departments);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company]);

  useEffect(() => {
    async function fetchData() {
      if (company && selectedDepartment && startDate && endDate) {
        const params = {
          company_id: company,
          department_id: selectedDepartment,
          start_date: dayjs(startDate).format("YYYY-MM-DD"),
          end_date: dayjs(endDate).format("YYYY-MM-DD"),
          timeframe: frequency.toLowerCase(),
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getSummaryRequest(params);
          const chartData = { labels: [], datasets: [] };
          chartData.labels = data[0].values.map((value) => {
            return value.x_axis_value;
          });
          data.employees.forEach((i) => {
            const dataset = {
              label: i.name,
              data: [],
              fill: false,
              tension: 0.1,
              pointStyle: "circle",
              pointRadius: 10,
              pointHoverRadius: 15,
            };
            i.values.map((value) => dataset.data.push(value.y_axix_value));
            chartData.datasets.push(dataset);
          });
          setDataChart(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [company, endDate, frequency, navigate, startDate, selectedDepartment]);

  const dataTooltips = DataTooltips;
  return (
    <Card sx={{ padding: "20px  25px", mt: "20px" }}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box sx={{ display: "flex" }}>
          <Typography fontWeight={600} fontSize={20}>
            Summarie
          </Typography>
          <Typography fontWeight={600} fontSize={20} sx={{ color: "#939393" }}>
            (s)
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center" gap="6px">
          <Select
            onChange={(value) => {
              setSelectedDepartment(value.target.value);
            }}
            value={selectedDepartment}
            sx={{
              background: "#f9f9f9",
              height: "30px",
              ".MuiSelect-root": {
                border: "1px solid red",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
            }}
          >
            {deptData &&
              deptData.map((item, idx) => (
                <MenuItem value={item.id} key={idx}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    {item.name}
                  </Box>
                </MenuItem>
              ))}
          </Select>
          <SelectFrequency
            value={frequency}
            onChange={(value) => setFrequency(value)}
          />
          <DatePicker
            onChange={(dateRange) => setDateRange(dateRange)}
            value={dateRange}
          />
        </Stack>
      </Box>
      <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
        <LineChart data={dataChart} dataTooltips={dataTooltips} />
      </Box>
    </Card>
  );
};

export default ChartSumarry;
