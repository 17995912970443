const DataTableAccount = [
  {
    title: "Employee",
    judul: "Karyawan",
    url: "employee",
  },
  {
    title: "Account Name",
    judul: "Nama Akun",
    url: "date",
  },
  {
    title: "Account Number",
    judul: "Nomor Akun",
    url: "reason",
  },
  {
    title: "Action",
    judul: "Aksi",
    url: "aksi",
  },
];

export default DataTableAccount;
