import {
  Box,
  InputLabel,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  Autocomplete,
  Select,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import debounce from "lodash/debounce";
import React, { useEffect, useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddressModal from "./modal-address";
import {
  getCity,
  getListMarriageStatus,
  getProvinces,
  getSubDistrict,
  getVillage,
} from "../../../../configs/axios/apiPath";
import { IconDocs } from "../../../../assets/employee";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

export default function FirstStep({
  currentData,
  setCurrentData,
  provinces,
  setProvinces,
  city,
  setCity,
  SubDistrict,
  setSubDistrict,
  villages,
  setVillages,
  dataMaritalStatus,
  maritalStatusSelected,
  setMaritalStatusSelected,
  setMaritalStatusId,

  dataReligion,
  religionSelected,
  setReligionSelected,
  setReligionId,
  renderSelectOptions,
}) {
  const [country, setCountry] = useState("62");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataProvinces, setDataProvinces] = useState([]);
  const [inputProvince, setInputProvince] = useState("");

  const [dataCity, setDataCity] = useState([]);
  const [inputCity, setInputCity] = useState("");

  const [dataSubDistrict, setDataSubDistrict] = useState([]);
  const [inputSubDistrict, setInputSubDistrict] = useState("");

  const [dataVillages, setDataVillages] = useState([]);
  const [inputVillages, setInputVillages] = useState("");

  const [searchAddress, setSearchAddress] = useState("");
  const [loadingAddress, setLoadingAddress] = useState(false);

  useEffect(() => {
    if (country) {
      fetchProvince(searchAddress);
    }

    if (provinces) {
      fetchCity(searchAddress);
    }

    if (city) {
      fetchSubDistrict(searchAddress);
    }

    if (SubDistrict) {
      fetchVillages(searchAddress);
    }
  }, [country, provinces, city, SubDistrict]);

  async function fetchProvince(search) {
    const params = {
      page_size: 99999,
      keywords: search,
    };
    setLoadingAddress(true);
    try {
      const {
        data: { data },
      } = await getProvinces(params, country);
      setDataProvinces(data?.page_data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAddress(false);
    }
  }

  async function fetchCity(search) {
    const params = {
      page_size: 99999,
      keywords: search,
    };
    setLoadingAddress(true);
    try {
      const {
        data: { data },
      } = await getCity(params, provinces.code);
      setDataCity(data?.page_data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAddress(false);
    }
  }

  async function fetchSubDistrict(search) {
    const params = {
      page_size: 99999,
      keywords: search,
    };
    setLoadingAddress(true);
    try {
      const {
        data: { data },
      } = await getSubDistrict(params, city.code);
      setDataSubDistrict(data?.page_data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAddress(false);
    }
  }

  async function fetchVillages(search) {
    const params = {
      page_size: 99999,
      keywords: search,
    };
    setLoadingAddress(true);
    try {
      const {
        data: { data },
      } = await getVillage(params, SubDistrict.code);
      setDataVillages(data?.page_data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAddress(false);
    }
  }

  const debounceAddress = useRef(
    debounce((value) => {
      fetchProvince(value);
      fetchCity(value);
      fetchSubDistrict(value);
      fetchVillages(value);
    }, 1000)
  ).current;

  const handleSubmit = () => {
    const data = {
      province_code: provinces.code,
      city_code: city.code,
      subdistrict_code: currentData.subdistrict_code,
      village_code: currentData.village_code,
      address: currentData.address,
    };
    setIsModalOpen(false);
  };

  const FormLabel = ({ label }) => (
    <InputLabel
      style={{
        fontFamily: "sans-serif",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "22px",
        letterSpacing: "0.02em",
        color: "#171717",
        marginBottom: "10px",
      }}
    >
      {label}
    </InputLabel>
  );

  return (
    <Box>
      <Box
        mt={3}
        //margin
        display="flex"
      >
        <Grid container columns={2} spacing={2}>
          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="No. KTP (NIK) *" />
              <Box display={"flex"}>
                <TextField
                  required
                  type="number"
                  variant="outlined"
                  size="large"
                  fullWidth
                  placeholder="Masukkan Nomor KTP"
                  value={currentData.ktp}
                  onChange={(e) => setCurrentData("ktp", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconDocs />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="No. BPJSTK" />
              <Box display={"flex"}>
                <TextField
                  variant="outlined"
                  size="large"
                  fullWidth
                  type="number"
                  value={currentData.bpjstk}
                  placeholder="Masukkan Nomor BPJS Ketenagakerjaan"
                  onChange={(e) => setCurrentData("bpjstk", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconDocs />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        mt={3}
        //margin
        display="flex"
      >
        <Grid container columns={2} spacing={2}>
          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="No. BPJS Kes" />
              <Box display={"flex"}>
                <TextField
                  variant="outlined"
                  size="large"
                  fullWidth
                  type="number"
                  value={currentData.bpjs_kes}
                  placeholder="Masukkan Nomor BPJS Kesehatan"
                  onChange={(e) => setCurrentData("bpjs_kes", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconDocs />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Name of Bank *" />
              <Box display={"flex"}>
                <TextField
                  required
                  variant="outlined"
                  size="large"
                  fullWidth
                  placeholder="Masukkan Nama Bank"
                  value={currentData.bank_name}
                  onChange={(e) => setCurrentData("bank_name", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconDocs />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        mt={3}
        //margin
        display="flex"
      >
        <Grid container columns={2} spacing={2}>
          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Account Name *" />
              <Box display={"flex"}>
                <TextField
                  required
                  placeholder="Masukkan Nama Akun Bank"
                  variant="outlined"
                  size="large"
                  fullWidth
                  value={currentData.account_name}
                  onChange={(e) =>
                    setCurrentData("account_name", e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconDocs />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="No. Rek *" />
              <Box display={"flex"}>
                <TextField
                  required
                  type="number"
                  variant="outlined"
                  size="large"
                  placeholder="Masukkan Nomor Rekening"
                  fullWidth
                  value={currentData.no_rek}
                  onChange={(e) => setCurrentData("no_rek", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconDocs />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        mt={3}
        //margin
        display="flex"
      >
        <Grid container columns={2} spacing={2}>
          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Name Of Branch Bank *" />
              <Box display={"flex"}>
                <TextField
                  required
                  placeholder="Masukkan Nama Cabang Bank"
                  variant="outlined"
                  size="large"
                  fullWidth
                  value={currentData.branch_name}
                  onChange={(e) =>
                    setCurrentData("branch_name", e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconDocs />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="No NPWP *" />
              <Box display={"flex"}>
                <TextField
                  variant="outlined"
                  size="large"
                  fullWidth
                  type="number"
                  placeholder="Isi 0 jika tidak ada"
                  value={currentData.npwp}
                  onChange={(e) => setCurrentData("npwp", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconDocs />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        mt={3}
        //margin
        display="flex"
      >
        <Grid container columns={2} spacing={2}>
          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Gender" />
              <Select
                style={{ width: "100%" }}
                MenuProps={{ style: { maxHeight: 350 } }}
                value={currentData.gender}
                onChange={(v) => setCurrentData("gender", v.target.value)}
              >
                {renderSelectOptions(
                  [
                    { id: "P", name: "P" },
                    { id: "L", name: "L" },
                  ],
                  {
                    label: "name",
                    value: "id",
                  }
                )}
              </Select>
            </Box>
          </Grid>
          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Religion" />
              <Autocomplete
                id="auto-religion"
                sx={{ sx: { width: "100%" } }}
                onChange={(v, value) => {
                  setReligionSelected(value);

                  const mid = JSON.stringify(value.id);
                  setReligionId(mid);
                }}
                options={dataReligion}
                value={religionSelected}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    placeholder="Pilih Agama"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconDocs />
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                )}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        mt={3}
        //margin
        display="flex"
      >
        <Grid container columns={2} spacing={2}>
          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Birth Location" />
              <Box display={"flex"}>
                <TextField
                  required
                  placeholder="Masukkan Nama Cabang Bank"
                  variant="outlined"
                  size="large"
                  fullWidth
                  value={currentData.birth_location}
                  onChange={(e) =>
                    setCurrentData("birth_location", e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconDocs />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Birth Date" />
              <DatePicker
                sx={{ width: "100%" }}
                onChange={(dt) => setCurrentData("birth_date", dt)}
                value={dayjs(currentData.birth_date)}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        mt={3}
        //margin
        display="flex"
      >
        <Grid container columns={2} spacing={2}>
          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Marital status" />
              <Autocomplete
                id="auto-marital-status"
                sx={{ sx: { width: "100%" } }}
                onChange={(v, value) => {
                  setMaritalStatusSelected(value);

                  const mid = JSON.stringify(value.id);
                  setMaritalStatusId(mid);
                }}
                options={dataMaritalStatus}
                value={maritalStatusSelected}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    placeholder="Pilih Status Pernikahan"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconDocs />
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Phone" />
              <Box display={"flex"}>
                <TextField
                  type="number"
                  variant="outlined"
                  size="large"
                  fullWidth
                  value={currentData.num_phone}
                  onChange={(e) => setCurrentData("num_phone", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconDocs />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        mt={3}
        //margin
        display="flex"
      >
        <Grid container columns={2} spacing={2}>
          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Email" />
              <Box display={"flex"}>
                <TextField
                  variant="outlined"
                  size="large"
                  fullWidth
                  value={currentData.email}
                  onChange={(e) => setCurrentData("email", e.target.value)}
                  placeholder="Masukkan Email"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconDocs />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Address (According to KTP) *" />
              <Box display={"flex"}>
                <TextField
                  required
                  variant="outlined"
                  size="large"
                  fullWidth
                  placeholder="Masukkan Alamat"
                  value={currentData.address}
                  onChange={(e) => setCurrentData("address", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconDocs />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setIsModalOpen(true)}
                          edge="end"
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Blood Type" />
              <Box display={"flex"}>
                <TextField
                  variant="outlined"
                  size="large"
                  fullWidth
                  placeholder="Masukkan Huruf A, B, AB atau O"
                  value={currentData.blood_type}
                  onChange={(e) => {
                    const isLetters = (str) => /^[O, A, B, AB]*$/.test(str);
                    if (isLetters(e.target.value.toUpperCase())) {
                      setCurrentData(
                        "blood_type",
                        e.target.value.toUpperCase()
                      );
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconDocs />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <AddressModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        {...{
          currentData,
          setCurrentData,
          provinces,
          setProvinces,
          dataProvinces,
          inputProvince,
          setInputProvince,
          city,
          setCity,
          dataCity,
          inputCity,
          setInputCity,
          SubDistrict,
          setSubDistrict,
          dataSubDistrict,
          inputSubDistrict,
          setInputSubDistrict,
          villages,
          setVillages,
          dataVillages,
          inputVillages,
          setInputVillages,
          loadingAddress,
        }}
      />
    </Box>
  );
}
