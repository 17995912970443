import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
  Modal,
  Alert,
  Switch,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataLogin from '../../../../data/dataLogin';
import { getCompany } from '../../../../features/companies';
import {
  getImageDocument,
  getListEmployee,
  getListWarningTypes,
  postImageDocument,
  postWarning,
} from '../../../../configs/axios/apiPath';
import { getLanguage } from '../../../../features/language';
import { Card } from '../../../../components/atoms';
import {
  IconDate,
  IconDescription,
  IconEmployee,
  IconImage,
  IconAlert,
  IconPen,
} from '../../../../assets/coreHr/awards';
import { ValidateString } from '../../../../utils/validate';
import { IconClose } from '../../../../assets/commons';
import CustomEditor from '../../../../components/organisms/editor';
import {
  SubmitDataReason,
  getListWarningReason,
} from '../../../../configs/axios/apiPath/fetchCommon/fetchWarningReason';
import { previewWarningLetter } from '../../../../configs/axios/apiPath/fetchCoreHR/fetchCoreHRWarning';

const CoreHRWarningAdd = () => {
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [warningTypeData, setWarningTypeData] = useState([]);
  const [selectedWarning, setSelectedWarning] = useState(null);
  const [validationType, setValidationType] = useState(false);
  const [inputPromotionValue, setInputPromotionValue] = useState('');
  const [reasonData, setReasonData] = useState([]);
  const [selectedReason, setSelectedReason] = useState([]);
  const [inputReasonValue, setInputReasonValue] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [valueReason, setValueReason] = useState('');
  const [loadingAddReason, setLoadingAddReason] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [start, setStart] = useState('');
  const [alert, setAlert] = useState('');

  const [selectedSuperior, setSelectedSuperior] = useState([]);
  const [inputSuperiorValue, setInputSuperiorValue] = useState('');
  const [isNotification, setIsNotification] = useState(false);
  const [isLoadingAddWarning, setIsLoadingAddWarning] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openModalReason, setOpenModalReason] = useState(false);

  const company = useSelector(getCompany);
  const [description, setDescription] = useState('');

  // ----- pagination
  const [total, setTotal] = useState(1);

  // *---------------------------- API -----------------------------------------------
  // --- List Employee ---
  const fetchData = async () => {
    if (company) {
      const params = {
        company_id: company,
        page_size: 999999, //TODO: fix to set search in auto complete
      };
      try {
        const {
          data: { data },
        } = await getListEmployee(params);

        setEmployeeData(data.employee);
        // if (data.page_info.total > 0) {
        //   setTotal(data.page_info.total);
        // }
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
      }
    }
  };
  //Reason
  const fetchDataReason = async () => {
    if (company) {
      const params = {
        company_id: company,
      };
      try {
        const {
          data: { data },
        } = await getListWarningReason(params);
        setReasonData(data);
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
      }
    }
  };

  useEffect(() => {
    if (selectedEmployee !== null) {
      setValidationType(false);
      setSelectedWarning(null);
    } else {
      setValidationType(true);
      setSelectedWarning(null);
    }
  }, [selectedEmployee]);

  //   --- Warning Type ---
  useEffect(() => {
    const fetchDataType = async () => {
      if (company) {
        const params = {
          company_id: company,
          employee_id: selectedEmployee?.id,
        };
        try {
          const {
            data: { data },
          } = await getListWarningTypes(params);
          setWarningTypeData(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        }
      }
    };
    fetchDataType();
  }, [company, selectedEmployee]);

  useEffect(() => {
    fetchData();
    fetchDataReason();
  }, [company]);

  const handleAddReason = async (e) => {
    e.preventDefault();
    const params = {
      company_id: company,
      description: otherReason,
      value: Number(valueReason),
    };

    setLoadingAddReason(true);

    try {
      await SubmitDataReason(params);
      setOpenModalReason(!openModalReason);
      setAlert(true);
      fetchDataReason();
      setLoadingAddReason(false);
      setTimeout(() => {
        setAlert(false);
      }, 5000);
    } catch (error) {
      console.error(error.response.status);
    }
  };
  const handlePreview = async (e) => {
    e.preventDefault();

    const params = {
      employee_id: selectedEmployee?.id,
      warning_type_id: selectedWarning?.id,
      publish_date: start,
      is_notification: isNotification ? 1 : 0,
      reason_ids: selectedReason?.map((item) => item?.id),
    };
    setLoadingPreview(true);

    try {
      const {
        data: { data },
      } = await previewWarningLetter(params);
      setDescription(data?.content);
      setLoadingPreview(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPreview(false);
    }
    setOpenModal(true);
  };

  const handleSubmit = async () => {
    const params = {
      employee_id: selectedEmployee?.id,
      warning_type_id: selectedWarning?.id,
      reason_ids: selectedReason?.map((item) => item?.id),
      publish_date: start,
      superior_ids: selectedSuperior?.map((item) => item?.id),
      content: description,
      is_notification: isNotification ? 1 : 0,
    };
    setIsLoadingAddWarning(true);
    try {
      const response = await postWarning(params);
      setIsLoadingAddWarning(false);

      if (response.data.meta.code === 20000) {
        navigate('/core-hr/warning');
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        localStorage.removeItem('cvSayaLogin');
        navigate(`/users`);
      }
    }
  };

  return (
    <Card>
      <Box p={'20px'}>
        <Box borderBottom={'0.2px solid #939393'} p={'0px 0px 15px 0px'}>
          <Typography
            fontWeight={'600px'}
            fontSize={'20px'}
            lineHeight={'27px'}
            letterSpacing={'0.01em'}
            color={'#171717'}
          >
            {lang === 'Id' ? 'Tambah' : 'Add'}
          </Typography>
        </Box>
        {alert && (
          <Alert variant="outlined" severity="success" sx={{ mt: 2 }}>
            New reason success added
          </Alert>
        )}
        <form
          onSubmit={handlePreview}
          style={{
            marginTop: '20px',
            fontWeight: '600px',
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '0.01em',
            color: '#171717',
          }}
        >
          <Grid container columns={12} spacing={2}>
            <Grid item md={7} xs={12}>
              <InputLabel
                style={{
                  marginBottom: '5px',
                  fontSize: '13px',
                  color: '#171717',
                  fontWeight: 600,
                }}
              >
                {lang === 'Id' ? 'Karyawan' : 'Employee'}
              </InputLabel>
              <Autocomplete
                disablePortal
                value={selectedEmployee}
                onChange={(_, value) => setSelectedEmployee(value)}
                inputValue={inputValue}
                onInputChange={(_, value) => setInputValue(value)}
                options={employeeData}
                getOptionLabel={(employee) => employee.name}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    placeholder={
                      lang === 'Id' ? 'Pilih Karyawan' : 'Select Employee'
                    }
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconEmployee />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                sx={{
                  borderRadius: '8px',
                  background: '#f9f9f9',
                  '& .MuiOutlinedInput-root': {
                    background: 'transparent',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                }}
              />
            </Grid>
            <Grid item md={7} xs={12}>
              <InputLabel
                style={{
                  marginBottom: '5px',
                  fontSize: '13px',
                  color: '#171717',
                  fontWeight: 600,
                }}
              >
                {lang === 'Id' ? 'Tipe' : 'Type'}
              </InputLabel>
              <Autocomplete
                disabled={validationType}
                disablePortal
                value={selectedWarning}
                onChange={(_, value) => setSelectedWarning(value)}
                inputValue={inputPromotionValue}
                onInputChange={(_, value) => setInputPromotionValue(value)}
                options={warningTypeData}
                getOptionLabel={(warning) => warning.name}
                renderInput={(params) => (
                  <TextField
                    helperText={
                      validationType ? (
                        <span style={{ color: '#A33A3A' }}>
                          select employee first
                        </span>
                      ) : (
                        ''
                      )
                    }
                    required
                    {...params}
                    placeholder={lang === 'Id' ? 'Pilih Tipe' : 'Select Type'}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconAlert />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                sx={{
                  borderRadius: '8px',
                  background: '#f9f9f9',
                  '& .MuiOutlinedInput-root': {
                    background: 'transparent',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                }}
              />
            </Grid>
            <Grid item md={7} xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <InputLabel
                  style={{
                    marginBottom: '5px',
                    fontSize: '13px',
                    color: '#171717',
                    fontWeight: 600,
                  }}
                >
                  {lang === 'Id' ? 'Alasan' : 'Reason'}
                </InputLabel>
                <Box
                  sx={{
                    background: '#51B15C',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpenModalReason(true)}
                >
                  <AddIcon style={{ fontSize: '20px', color: '#FFF' }} />
                </Box>
              </Box>
              <Autocomplete
                multiple
                disablePortal
                value={selectedReason}
                onChange={(_, value) => setSelectedReason(value)}
                inputValue={inputReasonValue}
                onInputChange={(_, value) => setInputReasonValue(value)}
                options={reasonData}
                getOptionLabel={(option) => option?.description}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      lang === 'Id' ? 'Pilih Alasan' : 'Select Reason'
                    }
                    // InputProps={{
                    //   ...params.InputProps,
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <IconPen />
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                )}
                sx={{
                  borderRadius: '8px',
                  background: '#f9f9f9',
                  '& .MuiOutlinedInput-root': {
                    background: 'transparent',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                }}
              />
            </Grid>
            <Grid item md={7} xs={12}>
              <InputLabel
                style={{
                  marginBottom: '5px',
                  fontSize: '13px',
                  color: '#171717',
                  fontWeight: 600,
                }}
              >
                {lang === 'Id' ? 'Tanggal Terbit' : 'Publish Date'}
              </InputLabel>
              <TextField
                required
                fullWidth
                type="date"
                variant="outlined"
                value={start}
                onChange={(e) => setStart(e.target.value)}
                sx={{
                  borderRadius: '8px',
                  background: '#f9f9f9',
                  '& .MuiOutlinedInput-root': {
                    background: 'transparent',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconDate />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={7} xs={12}>
              <InputLabel
                style={{
                  marginBottom: '5px',
                  fontSize: '13px',
                  color: '#171717',
                  fontWeight: 600,
                }}
              >
                {lang === 'Id'
                  ? 'Atasan yang dapat melihat'
                  : 'Notifiable Superior'}
              </InputLabel>
              <Autocomplete
                multiple
                aria-required
                disablePortal
                value={selectedSuperior}
                onChange={(_, value) => setSelectedSuperior(value)}
                inputValue={inputSuperiorValue}
                onInputChange={(_, value) => setInputSuperiorValue(value)}
                options={employeeData}
                getOptionLabel={(superior) => superior.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      lang === 'Id' ? 'Pilih Atasan' : 'Select Superior'
                    }
                    // InputProps={{
                    //   ...params.InputProps,
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <IconEmployee />
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                )}
                sx={{
                  borderRadius: '8px',
                  background: '#f9f9f9',
                  '& .MuiOutlinedInput-root': {
                    background: 'transparent',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <InputLabel
                  style={{
                    marginBottom: '5px',
                    fontSize: '13px',
                    color: '#171717',
                    fontWeight: 600,
                  }}
                >
                  {lang === 'Id' ? 'Pemberitahuan' : 'Notification'}
                </InputLabel>
                <Switch
                  checked={isNotification}
                  onChange={() => {
                    setIsNotification(!isNotification);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Button
            disabled={loadingPreview}
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: '136px', marginTop: '21px' }}
          >
            Preview
          </Button>
        </form>
      </Box>

      {/* new reason */}
      <Modal
        open={openModalReason}
        onClose={() => setOpenModalReason(!openModalReason)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            padding: '20px 30px',
            borderRadius: '16px',
          }}
        >
          <form
            onSubmit={handleAddReason}
            style={{
              fontWeight: '600px',
              fontSize: '13px',
              lineHeight: '18px',
              letterSpacing: '0.01em',
              color: '#171717',
            }}
          >
            <IconClose
              onClick={() => setOpenModalReason(!openModalReason)}
              style={{ cursor: 'pointer', float: 'right' }}
            />
            <Grid container columns={12} spacing={2}>
              <Grid item xs={12}>
                <InputLabel
                  style={{
                    marginBottom: '5px',
                    fontSize: '13px',
                    color: '#171717',
                    fontWeight: 600,
                  }}
                >
                  {lang === 'Id' ? 'Masukkan Alasan Anda' : 'Type Your Reason'}
                </InputLabel>
                <TextField
                  required
                  fullWidth
                  multiline
                  minRows={4}
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  style={{
                    marginBottom: '5px',
                    fontSize: '13px',
                    color: '#171717',
                    fontWeight: 600,
                  }}
                >
                  {lang === 'Id' ? 'Nilai' : 'Value'}
                </InputLabel>
                <TextField
                  type="number"
                  required
                  fullWidth
                  value={valueReason}
                  onChange={(e) => setValueReason(e.target.value)}
                />
              </Grid>
            </Grid>

            <Button
              disabled={loadingAddReason}
              variant="contained"
              color="primary"
              type="submit"
              sx={{ width: '136px', marginTop: '21px', float: 'right' }}
            >
              {lang === 'Id' ? 'Simpan' : 'Save'}
            </Button>
          </form>
        </Box>
      </Modal>

      {/* preview */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(!openModal)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
            padding: '20px 30px',
            borderRadius: '16px 8px 8px 16px',
            minHeight: '500px',
            minWidth: '500px',
            maxHeight: '700px',
            overflow: 'scroll',
            overflowX: 'hidden',
            overflowY: 'unset',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '24px',
            }}
          >
            <Typography
              sx={{ fontSize: '20px', fontWeight: 600, color: '#171717' }}
            >
              Warning Preview
            </Typography>
            <IconClose
              onClick={() => setOpenModal(!openModal)}
              style={{ cursor: 'pointer' }}
            />
          </Box>
          <CustomEditor
            content={description}
            setDescription={setDescription}
            type={'memo'}
          />
          <Button
            disabled={isLoadingAddWarning}
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: '136px', marginTop: '21px', float: 'right' }}
            onClick={handleSubmit}
          >
            {lang === 'Id' ? 'Simpan' : 'Save'}
          </Button>
        </Box>
      </Modal>
    </Card>
  );
};

export default CoreHRWarningAdd;
