import { API } from "../../../API";

/** List Board With Task of Project */
export const getListBoardWithTask = (id) => API.get(`projects/${id}/boards`);

/** List Board */
export const getListBoard = (id) => API.get(`projects/${id}/boards/list`);

/** Add New Board */
export const addNewBoard = (id, data) => API.post(`projects/${id}/boards`, data);

/** Change Board Background */
export const changeBoardBackground = (id, data) => API.put(`projects/${id}/boards/images`, data);