const DataTableOfficeFacillities = [
  {
    title: "Office Item",
    judul: "Item Kantor",
    url: "employee",
  },
  {
    title: "Responsible Employee",
    judul: "Karyawan Yang Bertanggung Jawab",
    url: "date",
  },
  {
    title: "Price",
    judul: "Harga",
    url: "date",
  },
  {
    title: "Receive Date",
    judul: "Tanggal Diterima",
    url: "reason",
  },
  {
    title: "Action",
    judul: "Aksi",
    url: "aksi",
  },
];

export default DataTableOfficeFacillities;
