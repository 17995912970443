import { API } from "../../../API";

// --- List Office Facilities ---
export const getListEmployeeFacillities = (params) =>
  API.get("employee-facilities", { params: params });

export const getSummaryEmployeeFacillities = (params) =>
  API.get("employee-facilities/summary", { params: params });

export const getSummaryDetailEmployeeFacillities = (params) =>
  API.get("employee-facilities/summary-detail", { params: params });

// --- Post Office Facilities ---
export const postEmployeeFacillities = (data) => API.post("employee-facilities", data);

// --- Get Office Facilities By Id ---
export const getEmployeeFacillitiesById = (id) => API.get(`employee-facilities/${id}`);

// --- Update Office Facilities By Id ---
export const updateEmployeeFacillitiesById = (data, id) =>
  API.put(`employee-facilities/${id}`, data);

// --- Delete Office Facilities By Id ---
export const deleteEmployeeFacillitiesById = (id) =>
  API.delete(`employee-facilities/${id}`);
