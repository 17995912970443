import { Box, Stack } from "@mui/material";

export default function StageInformation({ currentStage }) {
  // Define the stageOrder array.
  let stageOrder = ["screening", "test", "interview", "accepted", "hired"];
  if (currentStage === "rejected") {
    stageOrder[4] = "rejected";
  }

  // Get the index of the current stage in the stageOrder array.
  const currentIndex = stageOrder.indexOf(currentStage);

  return (
    <Stack direction="row" gap="10px">
      {stageOrder.map((stage, index) => (
        <Box
          key={stage}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "24px",
            height: "24px",
            borderRadius: "100px",
            background:
              index === currentIndex
                ? stage === "rejected"
                  ? "#E40A0A"
                  : "#51B15C"
                : index < currentIndex // Check if the stage is past
                ? "#EEF7EF" // Set background color for past stages
                : "#EEE", // Default background color
            color:
              index === currentIndex
                ? "#fff"
                : index < currentIndex
                ? "#51B15C"
                : "#171717", // Set font color
            fontFamily: "Open Sans",
            fontSize: "10px",
            fontStyle: "normal",
            fontWeight: index === currentIndex ? "600" : "400",
            lineHeight: "normal",
            paddingX: index === currentIndex && "16px",
            textTransform: "capitalize",
          }}
        >
          {`${index + 1} ${index === currentIndex ? stage : ""}`}
        </Box>
      ))}
    </Stack>
  );
}
