import React, { useEffect } from "react";
import {
  Autocomplete,
  Button,
  Card,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import { getLanguage } from "../../../../features/language";
import { useSelector } from "react-redux";
import { IconDeptText, IconSearch } from "../../../../assets/department";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCompany } from "../../../../features/companies";
import {
  getDepartmentById,
  getListEmployee,
  updateDepartmentById,
} from "../../../../configs/axios/apiPath";

const DepartmentEdit = () => {
  const lang = useSelector(getLanguage);

  const [dept, setDept] = useState("");

  const [managerSelected, setManagerSelected] = useState(null);
  const [managerId, setManagerId] = useState("");

  const company = useSelector(getCompany);

  const [isLoading, setIsLoading] = useState(false);

  const [dataManager, setDataManager] = useState([]);

  const Navigate = useNavigate();

  // -- mengambil id --
  const path = useLocation().pathname;
  const splitPath = path.split("/");
  const id = splitPath[4];

  useEffect(() => {
    if (company) {
      async function getData() {
        try {
          const { data } = await getDepartmentById(id);
          setDept(data?.data?.name);
          setManagerSelected(data?.data?.manager?.name);
          setManagerId(data?.data?.manager?.id);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            Navigate(`/users`);
          }
        }
      }

      getData();

      async function fectDataManager() {
        const params = {
          company_id: company,
          position: "Manager",
        };
        setIsLoading(true);
        try {
          const { data } = await getListEmployee(params);

          const setManager = data.data.employee.map((options) => ({
            id: options.id,
            label: options.name,
          }));
          setDataManager(setManager);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            Navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
      fectDataManager();
    }
  }, [Navigate, company, id]);

  const handelSubmit = async (e) => {
    e.preventDefault();

    if (company) {
      const data = {
        name: dept,
        company_id: company,
        manager_id: managerId,
      };

      try {
        await updateDepartmentById(data, id);
        Navigate("/organization");
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          Navigate(`/users`);
        }
      }
    }
  };

  const handleChangeManager = (e, value) => {
    setManagerSelected(value);

    const mid = JSON.stringify(value.id, null, " ");
    setManagerId(mid);
  };

  return (
    <>
      <Card sx={{ marginTop: "20px" }}>
        <Box padding={"20px"}>
          <form onSubmit={handelSubmit}>
            <Box borderBottom={"0.2px solid #939393"} p={"0px 0px 15px 0px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"20px"}
                lineHeight={"27px"}
                letterSpacing={"0.01em"}
                color={"#171717"}
              >
                {lang === "Id" ? "Ubah" : "Edit"}
              </Typography>
            </Box>
            {/* -- autocomplete department -- */}
            <Box padding={"10px"}>
              <Typography
                fontFamily={"Open Sans"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"17.7px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"2px"}
              >
                {lang === "Id" ? "Dept" : "Dept"}
              </Typography>
              <TextField
                value={dept}
                size="small"
                sx={{
                  backgroundColor: "#F7F7F7",
                  width: "300px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconDeptText />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setDept(e.target.value)}
                required
              />
            </Box>

            {/* -- autocomplete Manager -- */}
            <Box padding={"10px"}>
              <Typography
                fontFamily={"Open Sans"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"17.7px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"2px"}
              >
                {lang === "Id" ? "Menejer" : "Manager"}
              </Typography>
              <Autocomplete
                id="auto-manager"
                options={dataManager}
                onChange={handleChangeManager}
                value={managerSelected}
                sx={{
                  width: 300,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      backgroundColor: "#F7F7F7",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconSearch />
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                )}
              />
            </Box>
            {/* -- Button Save -- */}
            <Box padding={"10px"}>
              <Button
                variant="contained"
                color="primary"
                sx={{ textTransform: "none" }}
                type="submit"
              >
                {lang === "Id" ? "Simpan" : "Save"}
              </Button>
            </Box>
          </form>
        </Box>
      </Card>
    </>
  );
};

export default DepartmentEdit;
