import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'

const ProjectDiscussion = () => {
  return(
    <Box>
      <Outlet/>
    </Box>
  )
}

export default ProjectDiscussion