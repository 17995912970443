import { Box, Button, TextField, Typography } from "@mui/material"

const CardMoveList = () => {
  return(
    <Box 
      sx={{
        width: '210px',
        padding: '10px',
      }}
    >
      <Typography sx={{
          textAlign: 'center',
          fontWeight: '400',
          fontSize: '14px',
          marginBottom: '10px',
        }}  
      >
        Move List
      </Typography>
      
      <TextField
        required
        fullWidth
        variant="outlined"
        size='small'
        placeholder="Position"
        sx={{
          '& .MuiOutlinedInput-root': {
            background: '#f9f9f9',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#51B15C',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#51B15C',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#51B15C',
            },
          },
        }}
      />
      <Box display={'flex'} gap={2} marginTop={'10px'}>
        <Button 
          variant="contained" 
          sx={{
            width: '60px', 
            height: '32px',
          }}
        >
          <Typography
            fontFamily={'sans-serif'}
            fontStyle={'normal'}
            fontWeight={400}
            fontSize={'14px'}
            color="primary.contrastText"
            textTransform={'none'}
          >
          Move
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}

export default CardMoveList