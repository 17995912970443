import {IconDepartment, IconLocation, IconOrganization, IconCompanyPolicy } from './../../../assets/department';

const MenuOrganization = [
  {
    id: 0,
    title: 'Department',
    judul: 'Departemen',
    description: '',
    deskripsi: '',
    icon: <IconDepartment />,
  },
  {
    id: 1,
    title: 'Location',
    judul: 'Lokasi',
    description: '',
    deskripsi: '',
    icon: <IconLocation />,
  },
  {
    id: 2,
    title: 'Organization',
    judul: 'Organisasi',
    description: '',
    deskripsi: '',
    icon: <IconOrganization />,
  },
  {
    id: 3,
    title: 'Company Policy',
    judul: 'Kebijakan Perusahaan',
    description: '',
    deskripsi: '',
    icon: <IconCompanyPolicy />,
  },
];

export default MenuOrganization;
