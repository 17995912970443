import { API } from "../../../API";

// --- List Department ---
export const getListDepartment = (params) =>
  API.get("departments", { params: params });

// --- Post Department ---
export const postDepartment = (data) => API.post("departments", data);

// --- Get Lov Department By Company ---
export const getDepartmentByCompany = (companyID, keyword = "") =>
  API.get("departments", { params: { keyword, company_id: companyID } });

// --- Get Department By Id ---
export const getDepartmentById = (id) => API.get(`departments/${id}`);

// --- Update Department By Id ---
export const updateDepartmentById = (data, id) =>
  API.put(`departments/${id}`, data);

// --- Delete Department By Id ---
export const deleteDepartmentById = (id) => API.delete(`departments/${id}`);
