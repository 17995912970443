import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  IconDelete,
  IconDetail,
  IconEdit,
} from "../../../../../../assets/commons";
import { TooltipsAction } from "../../../../../atoms";
import {
  handleTooltipClose,
  handleTooltipOpen,
} from "../../../../../../utils/function/tooltips";
import { ModalDelete } from "../../../../modal";
import { handleAction } from "../../../../../../utils/function/action";
import { getAwardsById } from "../../../../../../configs/axios/apiPath";
import CoreHRAwardsDetail from "../../../../../../pages/coreHR/awards/detail";

const AwardsTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [idEmployee, setIdEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState([]);

  const [idTooltipDetail, setIdTooltipDetail] = useState("");
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);
  const [idTooltipDelete, setIdTooltipDelete] = useState("");
  const [isTooltipDelete, setIsTooltipDelete] = useState(false);
  const [idTooltipEdit, setIdTooltipEdit] = useState("");
  const [isTooltipEdit, setIsTooltipEdit] = useState(false);

  // --- Handle Edit ---
  const handleEdit = (id) => {
    navigate(`/core-hr/awards/edit/${id}`);
  };

  // --- Load Detail ---
  useEffect(() => {
    async function fetchData() {
      if (idEmployee !== null) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getAwardsById(idEmployee);
          setDetail(data);
        } catch (error) {
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [idEmployee]);

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Award Name --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "start",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item.award_name}
              </Typography>
            </td>
            {/* --- Employee Name --- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "start",
                verticalAlign: "middle",
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"start"}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <img
                  src={item.profile_picture}
                  alt={item.name}
                  width={30}
                  style={{ borderRadius: "50%" }}
                />
                <Box ml={"10px"}>
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={400}
                    fontSize={"14px"}
                    lineHeight={"130%"}
                    color={"#171717"}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={600}
                    fontSize={"10px"}
                    lineHeight={"11px"}
                    letterSpacing={"0.01em"}
                    color={"#939393"}
                  >
                    {item.department}
                  </Typography>
                </Box>
              </Box>
            </td>
            {/* --- Gift Name --- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item.gift_name}
              </Typography>
            </td>
            {/* --- Date --- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {moment(item.date).format("D MMM")}
              </Typography>
            </td>
            {/* --- Action --- */}
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                {/* --- Detail --- */}
                <TooltipsAction
                  title="Detail"
                  open={isTooltipDetail && item.id === idTooltipDetail}
                  arrow
                  placement={"bottom-end"}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        "detail"
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDetail,
                        setIdTooltipDetail
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDetail)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>

                {/* --- Delete --- */}
                <TooltipsAction
                  title="Delete"
                  open={isTooltipDelete && item.id === idTooltipDelete}
                  arrow
                  placement={"bottom-end"}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        "delete"
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDelete,
                        setIdTooltipDelete
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDelete)}
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>

                {/* --- Edit --- */}
                <TooltipsAction
                  title="Edit"
                  open={isTooltipEdit && item.id === idTooltipEdit}
                  arrow
                  placement={"bottom-end"}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleEdit(item.id)}
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipEdit,
                        setIdTooltipEdit
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipEdit)}
                  >
                    <IconEdit />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
      {/* --- Modal Handle --- */}
      {idEmployee !== null && modal === "detail" ? (
        <CoreHRAwardsDetail
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          detail={detail}
          isLoading={isLoading}
        />
      ) : modal === "delete" ? (
        <ModalDelete
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={"Awards"}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          id={idEmployee}
          data={tbody}
          setData={setData}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AwardsTbody;
