import React from "react";
import Data from "../../homepage/homepageAttendance/attendanceChart/dataMain";
import DataTooltips from "../../homepage/homepageAttendance/attendanceChart/dataTooltips";
import { Card, LineChart } from "../../../atoms";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../features/language";
import SelectFrequency from "../../homepage/homepageAttendance/selectFrequency";

const ChartAttendances = () => {
  const data = Data;
  const dataTooltips = DataTooltips;
  const lang = useSelector(getLanguage);
  return (
    <Card sx={{ padding: "20px  25px", mt: "20px" }}>
      <Box display={"flex"} justifyContent={"space-between"}>
        {/* --- Title --- */}
        <Typography
          fontWeight={600}
          fontSize={"20px"}
          lineHeight={"27px"}
          letterSpacing={"0.01em"}
          fontStyle={"normal"}
          fontFamily={"sans-serif"}
          color={"#171717"}
        >
          {lang === "Id" ? "Ringkasan" : "Summary"}
        </Typography>
        {/* --- Select Frequency --- */}
        <SelectFrequency />
      </Box>
      {/* Line chart */}
      <LineChart data={data} dataTooltips={dataTooltips} />
    </Card>
  );
};

export default ChartAttendances;
