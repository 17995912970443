import { Box, Typography } from "@mui/material";
import { IconApprovedBy } from "../../../../assets/coreHr/permission";
import moment from "moment";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../features/language";
import DocumentUrl from "../../../../configs/url";

export default function DoneStep({ detail }) {
  const lang = useSelector(getLanguage);

  const StatusName = {
    100: "Request",
    202: "Approved",
    200: "Verified",
    301: "Rejected",
  };

  return (
    <Box mt={"20px"} component={"form"} noValidate autoComplete="off">
      {/* --- Name Employee --- */}
      <Box
        mb={"20px"}
        p={"0px"}
        sx={{
          position: "relative",
        }}
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontSize={"13px"}
          fontWeight={600}
          lineHeight={"18px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"6px"}
        >
          {lang === "Id" ? "Employee" : "Employee"}
        </Typography>
        <Box
          style={{
            width: "100%",
            background: "#f7f7f7",
            borderRadius: "4px",
            border: "1px solid transparent",
            display: "inline-block",
          }}
        >
          <Typography mx={"10px"} py={"10px"}>
            {detail?.employee?.name}
          </Typography>
        </Box>
      </Box>
      {/* --- Date --- */}
      <Box
        mb={"20px"}
        p={"0px"}
        sx={{
          position: "relative",
        }}
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontSize={"13px"}
          fontWeight={600}
          lineHeight={"18px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"6px"}
        >
          {lang === "Id" ? "Tanggal" : "Date"}
        </Typography>
        <Box
          style={{
            width: "100%",
            background: "#f7f7f7",
            borderRadius: "4px",
            border: "1px solid transparent",
            display: "inline-block",
          }}
        >
          <Typography mx={"10px"} py={"10px"}>
            {moment(detail?.correction_date).format("D MMMM YYYY")}
          </Typography>
        </Box>
      </Box>

      {/* --- Type --- */}
      <Box
        mb={"20px"}
        p={"0px"}
        sx={{
          position: "relative",
        }}
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontSize={"13px"}
          fontWeight={600}
          lineHeight={"18px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"6px"}
        >
          {lang === "Id" ? "Tipe" : "Type"}
        </Typography>
        <Box
          style={{
            width: "100%",
            background: "#f7f7f7",
            borderRadius: "4px",
            border: "1px solid transparent",
            display: "inline-block",
          }}
        >
          <Typography mx={"10px"} py={"10px"}>
            {(detail?.type && (detail?.type).toUpperCase().replace("_", " ")) ??
              "-"}
          </Typography>
        </Box>
      </Box>

      {/* --- Award Image --- */}
      <Box
        mb={"20px"}
        p={"0px"}
        sx={{
          position: "relative",
        }}
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontSize={"13px"}
          fontWeight={600}
          lineHeight={"18px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"6px"}
        >
          {lang === "Id" ? "Gambar" : "Image"}
        </Typography>
        <img
          src={DocumentUrl(detail?.document?.id)}
          alt={detail?.name}
          width={"100%"}
          height={"auto"}
        />
      </Box>

      {/* --- Description --- */}
      <Box
        mb={"20px"}
        p={"0px"}
        sx={{
          position: "relative",
        }}
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontSize={"13px"}
          fontWeight={600}
          lineHeight={"18px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"6px"}
        >
          {lang === "Id" ? "Alasan" : "Reason"}
        </Typography>
        <Box
          style={{
            width: "100%",
            background: "#f7f7f7",
            borderRadius: "4px",
            border: "1px solid transparent",
            display: "inline-block",
          }}
        >
          <Typography
            mx={"10px"}
            py={"10px"}
            height={"auto"}
            maxHeight={"160px"}
            sx={{ overflowY: "scroll" }}
          >
            {detail?.description}
          </Typography>
        </Box>
      </Box>

      {/* --- Status --- */}
      <Box
        mb={"20px"}
        p={"0px"}
        sx={{
          position: "relative",
        }}
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontSize={"13px"}
          fontWeight={600}
          lineHeight={"18px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"6px"}
        >
          {lang === "Id" ? "Status" : "Status"}
        </Typography>
        <Box
          style={{
            width: "100%",
            background: "#f7f7f7",
            borderRadius: "4px",
            border: "1px solid transparent",
            display: "inline-block",
          }}
        >
          <Typography
            mx={"10px"}
            py={"10px"}
            height={"auto"}
            maxHeight={"160px"}
            sx={{ overflowY: "scroll" }}
          >
            {StatusName[detail?.status]}
          </Typography>
        </Box>
      </Box>

      {/* --- Verified By / Rejected By --- */}
      <Box mt={"10px"} component={"form"} noValidate autoComplete="off">
        {/* --- Approved By / Rejected By --- */}

        {detail?.status === 200 && (
          <Box
            mb={"20px"}
            p={"0px"}
            sx={{
              position: "relative",
            }}
          >
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"18px"}
              letterSpacing={"1px"}
              color={"#171717"}
              mb={"6px"}
            >
              {lang === "Id" ? "Diverifikasi Oleh" : "Verified By"}
            </Typography>
            <Box
              style={{
                width: "100%",
                background: "#f7f7f7",
                borderRadius: "4px",
                border: "1px solid transparent",
                display: "inline-block",
              }}
            >
              <Typography mx={"10px"} py={"10px"}>
                {detail?.statuses?.length === 3
                  ? detail?.statuses[2]?.name
                  : null}{" "}
                <IconApprovedBy />
              </Typography>
            </Box>
          </Box>
        )}

        {detail?.status === 301 && (
          <Box
            mb={"20px"}
            p={"0px"}
            sx={{
              position: "relative",
            }}
          >
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"18px"}
              letterSpacing={"1px"}
              color={"#171717"}
              mb={"6px"}
            >
              {lang === "Id" ? "Ditolak Oleh" : "Rejected By"}
            </Typography>
            <Box
              style={{
                width: "100%",
                background: "#f7f7f7",
                borderRadius: "4px",
                border: "1px solid transparent",
                display: "inline-block",
              }}
            >
              <Typography mx={"10px"} py={"10px"}>
                {detail?.statuses?.length === 2
                  ? detail?.statuses[1]?.name
                  : null}{" "}
                <IconApprovedBy />
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
