import React, { useEffect, useState } from "react";
import DataTooltips from "../../../homepage/homepageAttendance/attendanceChart/dataTooltips";
import { Card, LineChart, DatePicker } from "../../../../atoms";
import SelectFrequency from "../../../../organisms/homepage/homepageAttendance/selectFrequency";
import { Box, Typography, Stack } from "@mui/material";
import { getSummaryEmployeeFacillities } from "../../../../../configs/axios/apiPath";
import { getCompany } from "../../../../../features/companies";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const ChartSumarry = () => {
  const company = useSelector(getCompany);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [frequency, setFrequency] = useState("Daily");
  const [dataChart, setDataChart] = useState({ labels: [], datasets: [] });
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const dataTooltips = DataTooltips;

  useEffect(() => {
    async function fetchData() {
      if (company) {
        /* const params = { */
        /*   company_id: company, */
        /*   year: selectedYear, */
        /*   keywords: keyword, */
        /*   page, */
        /*   page_size: pageSize, */
        /*   order, */
        /*   direction, */
        /* }; */
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getSummaryEmployeeFacillities({
            timeframe: frequency.toLowerCase(),
            start_date: dayjs(startDate).format("YYYY-MM-DD"),
            end_date: dayjs(endDate).format("YYYY-MM-DD"),
            company_id: company,
          });
          const chartData = { labels: [], datasets: [] };
          chartData.labels = data[0].values.map((value) => {
            return value.x_axis_value;
          });
          data.forEach((i) => {
            const dataset = { label: i.name, data: [] };
            i.values.map((value) => dataset.data.push(value.y_axix_value));
            chartData.datasets.push(dataset);
          });
          setDataChart(chartData);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [company, endDate, frequency, navigate, startDate]);
  return (
    <Card sx={{ padding: "20px  25px", mt: "20px" }}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box sx={{ display: "flex" }}>
          <Typography fontWeight={600} fontSize={20}>
            Summarie
          </Typography>
          <Typography fontWeight={600} fontSize={20} sx={{ color: "#939393" }}>
            (s)
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center" gap="6px">
          <SelectFrequency
            value={frequency}
            onChange={(value) => setFrequency(value)}
          />
          <DatePicker
            onChange={(dateRange) => setDateRange(dateRange)}
            value={dateRange}
          />
        </Stack>
      </Box>
      <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
        <LineChart data={dataChart} dataTooltips={dataTooltips} />
      </Box>
    </Card>
  );
};

export default ChartSumarry;
