import { Box, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  IconDetail,
  IconLock,
  IconUnlock,
} from "../../../../../assets/commons";
// import { SearchTooltips, TooltipsAction } from '../../../../../atoms';
import { Search, TooltipsAction } from "../../../../atoms";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import {
  handleTooltipClose,
  handleTooltipOpen,
} from "../../../../../utils/function/tooltips";
import {
  getEmployeesMember,
  setActivationEmployee,
} from "../../../../../configs/axios/apiPath";

const TableManageEmployeeTbody = ({ tbody, setData, refreshCurrentData }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [idTooltipDetail, setIdTooltipDetail] = useState("");
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);
  const [idTooltipStatAction, setIdTooltipStatAction] = useState("");
  const [isTooltipStatAction, setIsTooltipStatAction] = useState(false);
  const [idTooltipDept, setIdTooltipDept] = useState("");
  const [isTooltipDept, setIsTooltipDept] = useState(false);
  const [idTooltipStatus, setIdTooltipStatus] = useState("");
  const [isTooltipStatus, setIsTooltipStatus] = useState(false);
  const [isTooltipMember, setIsTooltipMember] = useState(false);
  const [idTooltipMember, setIdTooltipMember] = useState(null);

  const [search, setSearch] = useState("");
  const [dataMember, setDataMember] = useState([]);

  // --- Handle Detail ---
  const handleDetail = (id) => {
    navigate(`/employee/employee/detail/${id}`);
  };

  const handleActiveEmployee = async (id, currentActivation) => {
    if (isLoading) return null;

    setIsLoading(true);
    try {
      await setActivationEmployee(id);

      setIsLoading(false);
      refreshCurrentData(id, +!currentActivation);
    } catch (error) {
      setIsLoading(false);
      console.error(error.response.status);
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    }
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      width: "255px",
      height: "360px",
      maxHeight: "360px",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      overflowY: "auto",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.primary,
    },
  }));

  // --- Employee Member ---
  useEffect(() => {
    async function fetchData() {
      if (idTooltipMember !== null) {
        const params = {
          search: search,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getEmployeesMember(idTooltipMember, params);
          setDataMember(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [search, idTooltipMember]);

  const handleClick = (id) => {
    setIsTooltipMember(!isTooltipMember);
    setIdTooltipMember(id);
  };

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- No --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {idx + 1}
              </Typography>
            </td>
            {/* --- Name --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "start",
                verticalAlign: "middle",
              }}
            >
              <Box display={"flex"} alignItems={"center"}>
                <img
                  src={item?.profile_picture}
                  name={item?.name}
                  width={30}
                  height={30}
                  style={{ borderRadius: "50%" }}
                />
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={400}
                  fontSize={"14px"}
                  lineHeight={"130%"}
                  color={"#171717"}
                  ml={"10px"}
                >
                  {item.name}
                </Typography>

                <LightTooltip
                  title={
                    <Box my={"20px"}>
                      <Box my={"20px"}>
                        <Search
                          placeholder={search}
                          setSearch={(e) => setSearch(e)}
                        />
                      </Box>

                      {!isLoading ? (
                        <Box>
                          {dataMember.map((item) => (
                            <Box
                              key={item.id}
                              borderTop={"0.2px solid #C8C8CC"}
                              borderBottom={"border: 0.2px solid #C8C8CC"}
                              padding={"10px"}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <img
                                src={item.profile_picture}
                                alt={item.name}
                                width={20}
                                style={{ borderRadius: "50%" }}
                              />
                              <Box
                                display={"flex"}
                                flexDirection={"column"}
                                ml={"10px"}
                              >
                                <Typography
                                  fontFamily={"sans-serif"}
                                  fontWeight={400}
                                  fontStyle={"normal"}
                                  fontSize={"12px"}
                                  lineHeight={"130%"}
                                  color={"#171717"}
                                  mb={"1px"}
                                >
                                  {item.name}
                                </Typography>
                                <Typography
                                  fontFamily={"sans-serif"}
                                  fontStyle={"normal"}
                                  fontWeight={600}
                                  fontSize={"8px"}
                                  lineHeight={"130%"}
                                  color={"#939393"}
                                >
                                  {item.position}
                                </Typography>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      ) : (
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"center"}
                        >
                          <CircularProgress />
                        </Box>
                      )}
                    </Box>
                  }
                  open={isTooltipMember && item.id === idTooltipMember}
                  arrow
                  placement={"bottom-start"}
                >
                  <Box onClick={() => handleClick(item.id)} ml={"10px"}>
                    {item.lead_icon_url && (
                      <img
                        src={item.lead_icon_url}
                        alt={item.name}
                        width={30}
                        style={{ borderRadius: "50%" }}
                      />
                    )}
                  </Box>
                </LightTooltip>
              </Box>
            </td>
            {/* --- Position --- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item?.position}
              </Typography>
            </td>
            {/* --- Dept --- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              {/* --- Detail --- */}
              <TooltipsAction
                title={item.dept}
                open={isTooltipDept && item.id === idTooltipDept}
                arrow
                placement={"bottom-end"}
              >
                <Box
                  sx={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    handleTooltipOpen(
                      item.id,
                      setIsTooltipDept,
                      setIdTooltipDept
                    )
                  }
                  onMouseLeave={() => handleTooltipClose(setIsTooltipDept)}
                >
                  <img
                    src={item?.dept_icon_url}
                    name={item?.dept}
                    width={30}
                    height={30}
                    style={{ borderRadius: "50%" }}
                  />
                </Box>
              </TooltipsAction>
            </td>
            {/* --- Service --- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item?.service}
              </Typography>
            </td>
            {/* --- Status --- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              {/* --- Detail --- */}
              <TooltipsAction
                title={item.status}
                open={isTooltipStatus && item.id === idTooltipStatus}
                arrow
                placement={"bottom-end"}
              >
                <Box
                  sx={{ cursor: "pointer" }}
                  onMouseEnter={() =>
                    handleTooltipOpen(
                      item.id,
                      setIsTooltipStatus,
                      setIdTooltipStatus
                    )
                  }
                  onMouseLeave={() => handleTooltipClose(setIsTooltipStatus)}
                >
                  <img
                    src={item?.employment_icon_url}
                    name={item?.dept}
                    width={30}
                    height={30}
                  />
                </Box>
              </TooltipsAction>
            </td>
            {/* --- Action --- */}
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                {/* --- Detail --- */}
                <TooltipsAction
                  title="Detail"
                  open={isTooltipDetail && item.id === idTooltipDetail}
                  arrow
                  placement={"bottom-end"}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleDetail(item.id)}
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDetail,
                        setIdTooltipDetail
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDetail)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>
                <TooltipsAction
                  title={
                    item.is_active === 1
                      ? "Active (Click to disabled user)"
                      : "Non-Active (Click to enabled user) "
                  }
                  open={isTooltipStatAction && item.id === idTooltipStatAction}
                  arrow
                  placement={"bottom-end"}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleActiveEmployee(item.id, item.is_active)
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipStatAction,
                        setIdTooltipStatAction
                      )
                    }
                    onMouseLeave={() =>
                      handleTooltipClose(setIsTooltipStatAction)
                    }
                  >
                    {item.is_active === 1 ? <IconUnlock /> : <IconLock />}
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

export default TableManageEmployeeTbody;
