import { API } from "../../API";
// --- List Report ---
export const getListReport = (params) =>
  API.get("export/available", { params: params });

export const getExportStatus = (params, responseType) =>
  API.get("export", { params: params, responseType: responseType });

// --- Post Report ---
export const postReport = (data) =>
  API.post("export", data, { responseType: "blob" });
