import {
	Button,
	Card,
	InputAdornment,
	OutlinedInput,
	TextField,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../features/language";
import { IconDescription } from "../../../../assets/commons";
import { IconHRCalendar } from "../../../../assets/sideNav/icons";
import { useNavigate } from "react-router-dom";
import { getCompany } from "../../../../features/companies";
import {
	getListDepartment,
	postCompanyPolicies,
} from "../../../../configs/axios/apiPath";
import { SelectDepartment } from "../../../../components/molecules/select";

export default function AddCompanyPolicy() {
	const lang = useSelector(getLanguage);
	const [isLoading, setIsLoading] = useState(false);
	const [namePolicy, setNamePolicy] = useState("");
	// eslint-disable-next-line no-unused-vars
	const [startDate, setStartDate] = useState("");
	const [description, setDescription] = useState("");

	const [department, setDepartment] = useState([]);
	const [departmentSelected, setDepartmentSelected] = useState(null);
	const [inputDepartment, setInputDepartment] = useState(null);

	const company = useSelector(getCompany);
	const navigate = useNavigate();

	useEffect(() => {
		if (company) {
			async function getDepartment() {
				const params = {
					company_id: company,
				};
				setIsLoading(true);
				try {
					const {
						data: { data },
					} = await getListDepartment(params);
					setDepartment(data?.departments);
				} catch (error) {
					//console.error(error.response.status);
					if (error.response.status === 401) {
						localStorage.removeItem("cvSayaLogin");
						navigate(`/users`);
					}
				} finally {
					setIsLoading(false);
				}
			}
			getDepartment();
		}
	}, [company, navigate]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		const data = {
			name: namePolicy,
			department_id: departmentSelected.id,
			description: description,
		};
		setIsLoading(true);
		try {
			await postCompanyPolicies(data);
			navigate(`/organization/company-policy`);
		} catch (error) {
			if (error.response.status === 401) {
				localStorage.removeItem("cvSayaLogin");
				navigate(`/users`);
			}
		} finally {
			setIsLoading(false);
		}
	};
	return (
		<Card sx={{ marginTop: "20px" }}>
			<Box padding={"20px"}>
				<form onSubmit={handleSubmit}>
					<Box borderBottom={"0.2px solid #939393"} p={"0px 0px 15px 0px"}>
						<Typography
							fontFamily={"sans-serif"}
							fontStyle={"normal"}
							fontWeight={600}
							fontSize={"20px"}
							lineHeight={"27px"}
							letterSpacing={"0.01em"}
							color={"#171717"}
						>
							{lang === "Id" ? "Tambah" : "Add"}
						</Typography>
					</Box>
					{/* -- Name Policy -- */}
					<Box padding={"10px"} sx={{ width: "300px" }}>
						<Typography
							fontFamily={"Open Sans"}
							fontStyle={"normal"}
							fontWeight={600}
							fontSize={"13px"}
							lineHeight={"17.7px"}
							letterSpacing={"1px"}
							color={"#171717"}
							mb={"2px"}
						>
							{lang === "Id" ? "Name Policy" : "Name Policy"}
						</Typography>
						<TextField
							size="small"
							fullWidth
							sx={{
								background: "#f9f9f9",
								"& .MuiOutlinedInput-root": {
									background: "transparent",
									"& fieldset": {
										borderColor: "transparent",
									},
									"&:hover fieldset": {
										borderColor: "transparent",
									},
									"&:hover:not(.Mui-disabled) fieldset": {
										borderColor: "transparent",
									},
									"&.Mui-disabled fieldset": {
										borderColor: "transparent",
									},
									"&.Mui-focused fieldset": {
										borderColor: "transparent",
									},
								},
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<IconDescription />
									</InputAdornment>
								),
							}}
							onChange={(e) => setNamePolicy(e.target.value)}
							required
						/>
					</Box>

					{/* -- autocomplete Department -- */}
					<Box padding={"10px"} sx={{ width: "300px" }}>
						<Typography
							fontFamily={"Open Sans"}
							fontStyle={"normal"}
							fontWeight={600}
							fontSize={"13px"}
							lineHeight={"17.7px"}
							letterSpacing={"1px"}
							color={"#171717"}
							mb={"2px"}
						>
							{lang === "Id" ? "Departemen" : "Department"}
						</Typography>
						<SelectDepartment
							data={department}
							inputChange={setInputDepartment}
							inputValue={inputDepartment}
							setValue={setDepartmentSelected}
							value={departmentSelected}
							backgroundColor={"#f7f7f7"}
						/>
					</Box>

					{/* -- Start Date -- */}
					{/* <Box
            padding={"10px"}
            sx={{
              width: "300px",
            }}
          >
            <Typography
              fontFamily={"Open Sans"}
              fontStyle={"normal"}
              fontWeight={600}
              fontSize={"13px"}
              lineHeight={"17.7px"}
              letterSpacing={"1px"}
              color={"#171717"}
              mb={"2px"}
            >
              {lang === "Id" ? "Tanggal Mulai" : "Start Date"}
            </Typography>
            <TextField
              size="small"
              fullWidth
              type="date"
              sx={{
                background: "#f9f9f9",
                "& .MuiOutlinedInput-root": {
                  background: "transparent",
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover:not(.Mui-disabled) fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-disabled fieldset": {
                    borderColor: "transparent",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconHRCalendar />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setStartDate(e.target.value)}
              required
            />
          </Box> */}

					{/* -- Description -- */}
					<Box padding={"10px"}>
						<Typography
							fontFamily={"Open Sans"}
							fontStyle={"normal"}
							fontWeight={600}
							fontSize={"13px"}
							lineHeight={"17.7px"}
							letterSpacing={"1px"}
							color={"#171717"}
							mb={"2px"}
						>
							{lang === "Id" ? "Deskripsi" : "Description"}
						</Typography>
						<OutlinedInput
							fullWidth
							required
							multiline
							minRows={5}
							variant="outlined"
							size="small"
							value={description}
							onChange={(e) => setDescription(e.target.value)}
							startAdornment={
								<InputAdornment
									position="start"
									sx={{
										position: "absolute",
										top: 23,
									}}
								>
									<IconDescription />
								</InputAdornment>
							}
							sx={{
								fontSize: "normal",
								background: "#f9f9f9",
								"& .MuiOutlinedInput-root": {
									background: "transparent",
								},
								"&:hover .MuiOutlinedInput-notchedOutline": {
									borderColor: "transparent",
								},
								"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
									borderColor: "transparent",
								},
								"& .MuiOutlinedInput-notchedOutline": {
									borderColor: "transparent",
								},
								"& textarea": {
									paddingLeft: "24px",
								},
							}}
						/>
					</Box>

					{/* -- Button Save -- */}
					<Box padding={"10px"}>
						<Button
							variant="contained"
							color="primary"
							sx={{ textTransform: "none" }}
							disabled={isLoading}
							type="submit"
						>
							{lang === "Id" ? "Simpan" : "Save"}
						</Button>
					</Box>
				</form>
			</Box>
		</Card>
	);
}
