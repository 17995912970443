import { Box, Divider, IconButton, Popover, Typography } from "@mui/material";
import {
  IconDoubleArrowDown,
  IconDoubleArrowUp,
  IconEditTable,
} from "../../../../../assets/commons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addStaticMenu,
  changeStaticMenu,
  getStaticMenus,
} from "../../../../../features/navigation";
import { MenuSideNavbar } from "../../../../../data/menu";
import { updateStickyMenu } from "../../../../../configs/axios/apiPath/stickyMenu";

const color = {
  active: "",
  unactive: "#C4C4C4",
};
const EditTable = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const staticMenu = useSelector(getStaticMenus);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const filterMenus = (data) => {
    dispatch(changeStaticMenu(data));
    const menu = staticMenu.filter((item) => item !== data);
    updateData(menu);
  };
  const addMenus = (data) => {
    dispatch(addStaticMenu(data));
    const menu = [...staticMenu, data];
    updateData(menu);
  };

  const updateData = async (menu) => {
    try {
      const data = await updateStickyMenu({ menu });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box
      sx={{
        background: "#51B15C",
        width: "30px",
        height: "30px",
        borderRadius: "50px",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        ml: "8px",
        cursor: "pointer",
      }}
    >
      {/* --- Icon --- */}
      <IconButton color="inherit" onClick={handleClick} sx={{ p: "0px" }}>
        <IconEditTable />
      </IconButton>

      {/* main */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ maxWidth: "414px", minHeight: "330px", padding: "20px" }}>
          {/* title */}
          <Box>
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, color: "#171717" }}
            >
              Edit Menu
            </Typography>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 400, color: "#C4C4C4" }}
            >
              Click on the 3 menus you want
            </Typography>
          </Box>

          {/* menus static */}
          <Box sx={{ display: "flex", gap: 4, mt: 4 }}>
            {MenuSideNavbar.filter((item) =>
              staticMenu.includes(item?.title)
            ).map((data, idx) => (
              <Box
                key={idx}
                sx={{
                  display: data?.title === "Dashboard" && "none",
                  width: "76px",
                }}
              >
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    padding: "3px ",
                    background: "#F7F7F7",
                    borderRadius: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "14px",
                      height: "14px",
                      borderRadius: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#FFE5E5",
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                    }}
                  >
                    <IconDoubleArrowDown
                      onClick={() => filterMenus(data?.title)}
                    />
                  </Box>
                  {React.cloneElement(data.icon)}
                </Box>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#171717" }}
                >
                  {data.title}
                </Typography>
              </Box>
            ))}
          </Box>

          <Divider sx={{ my: 3 }} />
          {/* menus  */}
          <Box
            sx={{
              display: "flex",
              gap: 4,
              flexFlow: "row",
              flexWrap: "wrap",
            }}
          >
            {MenuSideNavbar.filter(
              (item) => !staticMenu.includes(item.title)
            ).map((data, idx) => (
              <Box
                key={idx}
                sx={{
                  width: "76px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "40px",
                    height: "40px",
                    padding: "3px ",
                    background: "#F7F7F7",
                    borderRadius: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "14px",
                      height: "14px",
                      borderRadius: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#DCEFDE",
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                    }}
                  >
                    {staticMenu.length >= 4 ? (
                      <IconDoubleArrowUp />
                    ) : (
                      <IconDoubleArrowUp
                        onClick={() => addMenus(data?.title)}
                      />
                    )}
                  </Box>
                  {React.cloneElement(data.icon, {
                    style: {
                      fill:
                        staticMenu.length >= 4 ? color.unactive : color.active,
                    },
                  })}
                </Box>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: staticMenu.length >= 4 ? "#C4C4C4" : "#171717",
                  }}
                >
                  {data?.title}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
export default EditTable;
