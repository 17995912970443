export const dataTableEmployeeManage = [
  {
    title: 'No',
    judul: 'No',
    url: 'name',
  },
  {
    title: 'Name',
    judul: 'Nama',
    url: 'name',
  },
  {
    title: 'Position',
    judul: 'Posisi',
    url: 'position',
  },
  {
    title: 'Dept',
    judul: 'Departemen',
    url: 'dept',
  },
  {
    title: 'Service',
    judul: 'Layanan',
    url: 'service',
  },
  {
    title: 'Status',
    judul: 'Status',
    url: 'status',
  },
  {
    title: 'Action',
    judul: 'Aksi',
    url: 'name',
  },
];
