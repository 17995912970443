const DataTableOvertime = [
  {
    title: "Name",
    judul: "Nama",
    url: "title",
  },
  {
    title: "Date",
    judul: "Tanggal",
    url: "title",
  },
  {
    title: "Time",
    judul: "Waktu",
    url: "date",
  },
  {
    title: "Description",
    judul: "Deskripsi",
    url: "title",
  },
  {
    title: "Status",
    judul: "Status",
    url: "title",
  },
  {
    title: "Action",
    judul: "Aksi",
    url: "title",
  },
];

export default DataTableOvertime;
