const DataTableReimbursement = [
  {
    title: "Employee",
    judul: "Karyawan",
    url: "employee",
  },
  {
    title: "Date",
    judul: "Tanggal",
    url: "date",
  },
  {
    title: "Reason",
    judul: "Alasan",
    url: "reason",
  },
  {
    title: "Action",
    judul: "Aksi",
    url: "aksi",
  },
];

export default DataTableReimbursement;
