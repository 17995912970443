import { API } from "../../../API";
// --- List Attendance ---
export const getListCorrection = (params) =>
  API.get("timesheet/corrections", { params: params });

// --- Post Attendance ---
export const postCorrection = (data) => API.post("timesheet/corrections", data);

// --- Get Attendance By Id ---
export const getCorrectionById = (id, params) =>
  API.get(`timesheet/corrections/${id}`, { params: params });

// --- Update Attendance By Id ---
export const updateCorrectionById = (data, id) =>
  API.put(`timesheet/corrections/${id}`, data);

// --- Update Attendance Status By Id ---
export const updateCorrectionStatusById = (data, id) =>
  API.put(`timesheet/corrections/${id}/approve`, data);

// --- Delete Attendance By Id ---
export const deleteCorrectionById = (id) =>
  API.delete(`timesheet/corrections/${id}`);

export const uploadImageCorrection = (data) =>
  API.post(`timesheet/corrections/upload`, data);
