import { Box, IconButton, Typography } from '@mui/material';
import { IconEmoji, IconLogoLg } from '../../../../assets/loginPage/icons';

const LoginContentTitle = () => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      sx={{
        width: {
          xl: '467px',
          lg: '467px',
          md: '467px',
          sm: '467px',
        },
      }}
    >
      {/* --- Logo --- */}
      <IconLogoLg />
      <Box mt={'20px'}>
        {/* --- Welcome --- */}
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          mb={'6px'}
        >
          <Typography
            fontFamily={'sans-serif'}
            fontStyle={'normal'}
            fontWeight={600}
            fontSize={'24px'}
            lineHeight={'33px'}
            letterSpacing={'0.02em'}
            color={'#171717'}
          >
            Hai Selamat Datang
          </Typography>
          <IconButton sx={{ padding: '0px', mx: '12px' }}>
            <IconEmoji />
          </IconButton>
          <IconButton sx={{ padding: '0px' }}>
            <IconEmoji />
          </IconButton>
        </Box>
        {/* --- Description --- */}
        <Typography
          fontFamily={'sans-serif'}
          fontStyle={'normal'}
          fontWeight={400}
          fontSize={'14px'}
          lineHeight={'22px'}
          letterSpacing={'0.02em'}
          color={'#c4c4c4'}
          textAlign={'center'}
        >
          Platform Manajemen Perusahaan kami menjamin efisiensi sumber daya
          manusia dan operasional, mendukung pertumbuhan dengan mudah
          meningkatkan produktivitas.
        </Typography>
      </Box>
    </Box>
  );
};

export default LoginContentTitle;
