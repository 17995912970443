import React from 'react';
import { Box, Typography } from '@mui/material';
import { Card } from '../../../atoms';

const CardApplicants = ({ icon, title, children }) => {
  return (
    <Card>
      <Box p={'11px 20px'} display={'flex'} justifyContent={'space-between'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'start'}
          justifyContent={'start'}
        >
          <Typography
            fontWeight={'600px'}
            fontSize={'12px'}
            letterSpacing={'0.04rem'}
            lineHeight={'14px'}
            borderRadius={'100px'}
            padding={'4px 16px'}
            color={'#51B15C'}
            backgroundColor={'rgba(81, 177, 92, 0.1)'}
          >
            {title}
          </Typography>
          <Box>{children}</Box>
        </Box>
        <Box sx={{ cursor: 'pointer' }}>
          <Box>{icon}</Box>
        </Box>
      </Box>
    </Card>
  );
};

export default CardApplicants;
