import { createSlice } from '@reduxjs/toolkit';
import { getListStickyMenu } from '../../configs/axios/apiPath/stickyMenu';

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: {
    navigations: 'Dashboard',
    staticMenu: [],
  },
  reducers: {
    loadAllData: (state, action) => {
      state.staticMenu = action.payload;
    },
    setNavigation: (state, action) => {
      state.navigations = action.payload;
    },
    changeStaticMenu: (state, action) => {
      state.staticMenu = state.staticMenu.filter(
        (menu) => menu !== action.payload
      );
    },
    addStaticMenu: (state, action) => {
      state.staticMenu = [...state.staticMenu, action.payload];
    },
  },
});

export const { loadAllData, setNavigation, changeStaticMenu, addStaticMenu } =
  navigationSlice.actions;
export const getNavigation = (state) => state.navigation.navigations;

// list menu
export const getStaticMenus = (state) => state.navigation.staticMenu;

// ! ------------------------- API ---------------------------------
export const fetchListStickyMenu = () => (dispatch) => {
  const getData = async () => {
    try {
      const {
        data: { data },
      } = await getListStickyMenu();
      dispatch(loadAllData(data?.menu));
    } catch (err) {
      console.error(err);
    }
  };

  getData();
};

export default navigationSlice.reducer;
