import { Autocomplete, TextField } from "@mui/material";

const DepartmentAutocomplete = ({
  departmentOptions,
  handleDepartmentChange,
  department,
}) => {
  return (
    <Autocomplete
      id="department-autoselect"
      options={departmentOptions}
      blurOnSelect
      getOptionLabel={(option) => option.name}
      onChange={(event, value) => handleDepartmentChange(value)}
      value={
        departmentOptions.find((item) => item.id === department) ??
        departmentOptions[0] ??
        null
      }
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="outlined"
          sx={{
            width: 250,
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
            },
            "& .MuiOutlinedInput-root": {
              padding: "0px 0px 0px 0px !important",
            },
            borderRadius: "8px",
            "& .MuiInputBase-root": {
              height: "30px",
            },
            background: "#f9f9f9",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
          }}
        />
      )}
    />
  );
};

export default DepartmentAutocomplete;
