import {
  Box,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

const SelectYear = ({ selected, setSelected }) => {
  const year = [
    { label: "Status", value: "Status" },
    { label: "Recommended", value: "Recommended" },
    { label: "Hold", value: "Hold" },
    { label: "Bad", value: "Bad" },
    { label: "Unstatus", value: "Unstatus" },
  ];
  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  return (
    <Select
      value={selected}
      onChange={handleChange}
      sx={{
        background: "#f9f9f9",
        height: "30px",
        ".MuiSelect-root": {
          border: "1px solid red",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      }}
      label="placeholder"
    >
      {year &&
        year.map((item, idx) => (
          <MenuItem value={item.value} key={idx}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {item.label}
            </Box>
          </MenuItem>
        ))}
    </Select>
  );
};

export default SelectYear;
