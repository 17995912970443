const DataTableWarning = [
  {
    title: 'Employee Name',
    judul: 'Nama Karyawan',
    url: 'name',
  },
  {
    title: 'Remaining Time',
    judul: 'Sisa Waktu',
    url: 'remaining',
  },
  {
    title: 'Warning Date',
    judul: 'Tanggal Peringatan',
    url: 'date',
  },
  {
    title: 'Reason',
    judul: 'Alasan',
    url: 'reason',
  },
  {
    title: 'Warning Type',
    judul: 'Tipe Peringatan',
    url: 'warning',
  },
  {
    title: 'Status',
    judul: 'Status',
    url: 'status',
  },
  {
    title: 'Action',
    judul: 'Aksi',
    url: 'action',
  },
];

export default DataTableWarning;
