import React, { useEffect } from "react";
import {
  Autocomplete,
  Button,
  Card,
  InputAdornment,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { Box } from "@mui/system";

import { getLanguage } from "../../../../features/language";
import { useSelector } from "react-redux";
import { IconDeptText, IconSearch } from "../../../../assets/department";
import { useState } from "react";
import { postDepartment } from "../../../../configs/axios/apiPath/fetchOrganization/fetchDepartment";
import { getCompany } from "../../../../features/companies";
import { useNavigate } from "react-router-dom";
import { getListEmployee } from "../../../../configs/axios/apiPath";

const DepartmentAdd = () => {
  const lang = useSelector(getLanguage);

  const [dataManager, setDataManager] = useState([]);
  const [managerSelected, setManagerSelected] = useState(null);
  const [managerId, setManagerId] = useState("");
  const [nameDept, setNameDept] = useState("");

  const company = useSelector(getCompany);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function fectDataManager() {
      if (company) {
        const params = {
          company_id: company,
          position: "Manager",
        };
        setIsLoading(true);
        try {
          const { data } = await getListEmployee(params);

          const setManager = data.data.employee.map((options) => ({
            id: options.id,
            label: options.name,
          }));
          setDataManager(setManager);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }
    fectDataManager();
  }, [company, navigate]);

  const _filterOptions = createFilterOptions();
  const filterOptions = (options, state) => {
    const result = _filterOptions(options, state);

    if (result.length === 0) {
      return _filterOptions(options, {
        ...state,
        getOptionLabel: (o) => o.id.toString(),
      });
    }

    return result;
  };

  const handelSubmit = async (e) => {
    e.preventDefault();

    const id = JSON.stringify(managerSelected.id, null, "");
    const data = {
      company_id: company,
      manager_id: id,
      name: nameDept,
      icon_url:
        "http://localhost:8002/storage/department-icon/0585e37fb1cd4572b4eee134dcac85f8.png",
    };

    try {
      const res = await postDepartment(data);
      // if(res?.data?.meta?.code === 20000){
      navigate("/organization");
      // }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    }
  };

  const handleChangeManager = (e, value) => {
    setManagerSelected(value);
  };

  const handleInputManager = (e, value) => {
    setManagerId(value);
  };

  return (
    <>
      <Card sx={{ marginTop: "20px" }}>
        <Box padding={"20px"}>
          <form onSubmit={handelSubmit}>
            <Box borderBottom={"0.2px solid #939393"} p={"0px 0px 15px 0px"}>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"20px"}
                lineHeight={"27px"}
                letterSpacing={"0.01em"}
                color={"#171717"}
              >
                {lang === "Id" ? "Tambah" : "Add"}
              </Typography>
            </Box>
            {/* -- autocomplete department -- */}
            <Box padding={"10px"}>
              <Typography
                fontFamily={"Open Sans"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"17.7px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"2px"}
              >
                {lang === "Id" ? "Dept" : "Dept"}
              </Typography>
              <TextField
                size="small"
                sx={{
                  backgroundColor: "#F7F7F7",
                  width: "300px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconDeptText />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setNameDept(e.target.value)}
                required
              />
            </Box>

            {/* -- autocomplete Manager -- */}
            <Box padding={"10px"}>
              <Typography
                fontFamily={"Open Sans"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"17.7px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"2px"}
              >
                {lang === "Id" ? "Menejer" : "Manager"}
              </Typography>
              <Autocomplete
                id="auto-manager"
                options={dataManager}
                filterOptions={filterOptions}
                onChange={handleChangeManager}
                value={managerSelected}
                getOptionLabel={(man) => man.label}
                inputValue={managerId}
                onInputChange={handleInputManager}
                sx={{
                  width: 300,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      backgroundColor: "#F7F7F7",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconSearch />
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                )}
              />
            </Box>
            {/* -- Button Save -- */}
            <Box padding={"10px"}>
              <Button
                variant="contained"
                color="primary"
                sx={{ textTransform: "none" }}
                disabled={isLoading}
                type="submit"
              >
                {lang === "Id" ? "Simpan" : "Save"}
              </Button>
            </Box>
          </form>
        </Box>
      </Card>
    </>
  );
};

export default DepartmentAdd;
