import { Box, Button, Grid, Typography, Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IconExpectedBudget } from '../../../../assets/benefit/reimbursement';
import { Card, TextField } from '../../../../components/atoms';
import { getCompany } from '../../../../features/companies';
import { getLanguage } from '../../../../features/language';

const BenefitJourneyUpdateBudget = () => {
  const [expectedBudget, setExpectedBudget] = useState();
  const [additionalBudget, setAdditionalBudget] = useState();
  const [isSubmitted, setIsSubmit] = useState(false);

  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const lang = useSelector(getLanguage);
  const placeHolderId = 'Ketik disini';
  const placeHolderEn = 'Type here';

  const [isLoading, setIsLoading] = useState(false);

  // --- Handle Submit ---
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmit(true);
    setIsLoading(true);
    if (company) {
      try {
        navigate('/core-hr');
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Card>
      <Box p={'20px'}>
        <Box>
          <Typography
            fontFamily={'sans-serif'}
            fontStyle={'normal'}
            fontWeight={600}
            fontSize={'20px'}
            lineHeight={'27px'}
            letterSpacing={'0.01em'}
            color={'#171717'}
            marginBottom="15px"
          >
            {lang === 'Id' ? 'Tambah' : 'Add'}
          </Typography>
          <Divider />
        </Box>
        <form
          onSubmit={handleSubmit}
          style={{
            marginTop: '20px',
            fontWeight: '600px',
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '0.01em',
            color: '#171717',
          }}
        >
          <Stack spacing={2}>
            {/* --- Expected Budget --- */}
            <Grid container columns={2}>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <TextField
                  label={lang === 'Id' ? 'Ekspetasi Biaya' : 'Expected Budget'}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  type="number"
                  placeholder={lang === 'Id' ? placeHolderId : placeHolderEn}
                  value={expectedBudget}
                  onChange={(event) => setExpectedBudget(event.target.value)}
                  icon={<IconExpectedBudget />}
                />
              </Grid>
            </Grid>
            <Grid container columns={2}>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <TextField
                  label={lang === 'Id' ? 'Biaya Tambahan' : 'Additional Budget'}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  type="number"
                  placeholder={lang === 'Id' ? placeHolderId : placeHolderEn}
                  value={additionalBudget}
                  onChange={(event) => setAdditionalBudget(event.target.value)}
                  icon={<IconExpectedBudget />}
                />
              </Grid>
            </Grid>
          </Stack>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: '136px', marginTop: '21px' }}
          >
            {lang === 'Id' ? 'Simpan' : 'Save'}
          </Button>
        </form>
      </Box>
    </Card>
  );
};

export default BenefitJourneyUpdateBudget;
