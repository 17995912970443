import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { En, LogoDanmogot } from '../../../../../assets/topNav/img';
import { SelectLang } from '../../../../../data/select';
import { setLanguage } from '../../../../../features/language';

const SelectLanguage = () => {
  const dispatch = useDispatch();
  const lang = SelectLang;
  const parsedData = JSON.parse(localStorage.getItem('cvSayaData'));
  const language = parsedData && parsedData.lang;

  const [selectedLang, setSelectedLang] = useState(
    language ? language : lang[0]
  );

  // --- Hande Change ---
  const handleChange = (event) => {
    const selected = event.target.value;
    let parsedData = JSON.parse(localStorage.getItem('cvSayaData'));

    if (!parsedData) {
      parsedData = { lang: selected };
    } else {
      parsedData.lang = selected;
    }

    localStorage.setItem('cvSayaData', JSON.stringify(parsedData));
    setSelectedLang(selected);
  };

  useEffect(() => {
    langHandle(selectedLang);
  });

  // --- Set company to redux ---
  const langHandle = (langName) => {
    dispatch(setLanguage(langName));
  };

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      sx={{ width: '50px', textAlign: 'center' }}
    >
      <FormControl>
        <Select
          value={selectedLang}
          onChange={handleChange}
          IconComponent={''}
          sx={{
            height: '30px',
            '.MuiSelect-root': {
              border: '1px solid red',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          }}
        >
          {lang &&
            lang.map((item, idx) => (
              <MenuItem value={item} key={idx}>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <img
                    src={item === 'Id' ? LogoDanmogot : En}
                    alt={item === 'Id' ? 'Bahasa Indonesia' : 'English'}
                    // style={{ marginRight: '6px' }}
                  />
                  {/* <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontWeight={600}
                    fontSize={'16px'}
                    lineHeight={'22px'}
                    letterSpacing={'0.04em'}
                    color={'#171717'}
                  >
                    {item} 
                  </Typography> */}
                </Box>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectLanguage;
