import { API } from "../../../API";

// --- List calendar Index ---
export const getListCalendar = (params) =>
  API.get('calendar', { params: params });

  // --- List calendar ---
export const getIndexCalendar = (params) =>
  API.get('calendar/view', { params: params });

// --- Post calendar ---
export const postCalendar = (data) => API.post('calendar', data);

// --- Get calendar By Id ---
export const getCalendarById = (id) => API.get(`calendar/${id}`);

// --- Update calendar By Id ---
export const updateCalendarById = (data, id) =>
  API.put(`calendar/${id}`, data);

// --- Delete calendar By Id ---
export const deleteCalendarById = (id) =>
  API.delete(`calendar/${id}`);
