import {
  Box,
  Button,
  Typography,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Controller,
  Autocomplete,
  TextField,
} from "@mui/material";
import SelectYear from "../../../../molecules/year";
import { useState } from "react";
import { getLanguage } from "../../../../../features/language";
import { getCompany } from "../../../../../features/companies";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import {
  Card,
  Pagination,
  RowPerPage,
  Search,
  ButtonDetail,
} from "../../../../atoms";

import { IconPlus } from "../../../../../assets/commons";
import { useEffect } from "react";
import { DataTableRequest } from "../../../../../data/table/recruitment";
import TableComponent from "../../../../molecules/table/mainTable";
import {
  RecruitmentTbody,
  RecruitmentThead,
} from "../../../../molecules/table/tableRecruitment/request";
import {
  getListRequest,
  getListDepartments,
} from "../../../../../configs/axios/apiPath";

const TableRequest = () => {
  let isList = useLocation().pathname.includes("list");
  const yearNow = moment().format("YYYY");
  const year = [];
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [selectedYear, setSelectedYear] = useState(yearNow);

  const parsedData = JSON.parse(localStorage.getItem("cvSayaData"));
  const idDepartment = parsedData && parsedData.department_id;
  const [selectedDepartment, setSelectedDepartment] = useState(
    idDepartment ? idDepartment : ""
  );

  const [isLoading, setIsLoading] = useState(false);
  const [jobRequestData, setJobRequestData] = useState([]);
  const [deptData, setDeptData] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [order, setOrder] = useState("name");
  const [direction, setDirection] = useState("-");
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);
  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }

  const handleNavigationClick = (url) => {
    navigate("/recruitment/request/add");
  };

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          page_size: 9999999,
          company_id: company,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListDepartments(params);
          setSelectedDepartment(data?.departments[0].id);
          setDeptData(data?.departments);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company]);

  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          year: selectedYear,
          department_id: selectedDepartment,
          company_id: company,
          page: page,
          page_size: pageSize,
          keyword,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListRequest(params);
          setLastPage(data?.page_info?.last_page);
          setJobRequestData(data?.job_requests);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [
    company,
    direction,
    keyword,
    navigate,
    order,
    page,
    pageSize,
    selectedYear,
    selectedDepartment,
  ]);

  return (
    <Card sx={{ marginTop: "20px" }}>
      <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
        <Box display={"flex"} justifyContent={"space-between"}>
          {/* --- Search --- */}
          <Search
            placeholder={
              lang === "Id"
                ? "Ketik disini untuk mencari"
                : "Type here to search"
            }
            setSearch={(e) => setKeyword(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />
          <Box display={"flex"} sx={{ alignItems: "center" }} gap="10px">
            {/* --- Selected Year --- */}

            <Autocomplete
              id="demo-simple-autocomplete"
              options={deptData} // Replace deptData with your data source
              getOptionLabel={(option) => option.name} // Provide a function to extract the label from each option
              onChange={(event, newValue) => {
                setSelectedDepartment(newValue ? newValue.id : null);
                let parsedData = JSON.parse(localStorage.getItem("cvSayaData"));

                if (!parsedData) {
                  parsedData = {
                    department_id: newValue ? newValue.id : null,
                  };
                } else {
                  parsedData.idPayroll = newValue ? newValue.id : null;
                }

                localStorage.setItem("cvSayaData", JSON.stringify(parsedData));
              }}
              value={
                deptData.find((item) => item.id === selectedDepartment) ?? null
              } // Use find to set the initial value based on the selectedDepartment
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  sx={{
                    width: 250,
                    "& .MuiInputBase-root": {
                      height: "30px",
                    },
                    background: "#f9f9f9",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                  }}
                />
              )}
            />

            <SelectYear
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              year={year}
            />

            {/* --- Add --- */}
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              onClick={() => handleNavigationClick("add")}
              sx={{
                borderRadius: "4px",
              }}
            >
              <Box display={"flex"} sx={{ alignItems: "center" }}>
                <IconPlus />
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"14px"}
                  color="primary.contrastText"
                  letterSpacing={"0.02em"}
                  marginLeft={"10px"}
                  textTransform={"none"}
                >
                  {lang === "Id" ? "Tambah" : "Add"}
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>

        {/* --- Table --- */}
        <Box sx={{ mt: "24px", mb: "16px" }}>
          <TableComponent
            isLoading={isLoading}
            tbody={jobRequestData}
            TableHead={
              <RecruitmentThead
                thead={DataTableRequest}
                setOrder={setOrder}
                setDirection={setDirection}
                direction={direction}
              />
            }
            TableBody={
              <RecruitmentTbody
                tbody={jobRequestData}
                setData={setJobRequestData}
              />
            }
            col={5}
          />
        </Box>

        {/* --- Pagination --- */}
        <Box display={"flex"} justifyContent={"space-between"}>
          {isList ? (
            <Stack direction="row" gap="30px">
              <Stack direction="row" gap="10px" sx={{ alignItems: "center" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="14"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    fill="#171717"
                    fillRule="evenodd"
                    d="M6.017.836c.056-.003.114-.003.19-.003h1.92A1.833 1.833 0 019.97 2.162l.002.006a1.093 1.093 0 00.422.498c1.435.002 2.185.028 2.701.449.107.087.204.183.29.29.448.55.448 1.367.448 3 0 .415 0 .622-.1.784a.656.656 0 01-.066.09c-.127.142-.325.201-.722.32l-3.112.934V8A1.333 1.333 0 008.5 6.666H5.833A1.333 1.333 0 004.5 8v.533L1.388 7.6C.991 7.48.793 7.42.667 7.278A.668.668 0 01.6 7.19C.5 7.027.5 6.82.5 6.406c0-1.634 0-2.45.449-3.002.086-.106.183-.203.289-.289.517-.42 1.266-.447 2.702-.449L4 2.624c.178-.136.306-.307.361-.456.026-.076.044-.133.066-.186A1.833 1.833 0 016.017.836zm3.029 1.709c.016.041.033.081.053.121H5.234c.02-.04.037-.08.053-.121v-.002l.004-.007.003-.008.003-.008.002-.006.002-.004.002-.008.003-.008.001-.004.002-.005.002-.005.001-.004c.026-.077.034-.102.042-.12a.833.833 0 01.723-.522l.143-.001h1.892l.143.001c.322.02.603.223.724.522l.041.12.001.004.002.005.002.005.001.004.003.008.002.008.002.004.002.006.003.008.003.008.003.007.001.002h.001zM8.5 7.666H5.833A.333.333 0 005.5 8V9.44a.333.333 0 00.21.31l.466.186a2.666 2.666 0 001.981 0l.467-.187a.333.333 0 00.21-.309V8a.333.333 0 00-.334-.334zM4.507 9.58L1.17 8.578c.02 2.076.141 3.988.874 4.64C2.924 14 4.34 14 7.167 14s4.242 0 5.121-.782c.733-.651.855-2.564.875-4.64L9.826 9.58a1.333 1.333 0 01-.83 1.1l-.467.187c-.875.35-1.85.35-2.724 0l-.467-.187a1.334 1.334 0 01-.83-1.1h-.001z"
                    clipRule="evenodd"
                  ></path>
                  <rect
                    width="9.333"
                    height="9.333"
                    x="7.168"
                    fill="#fff"
                    rx="4.667"
                  ></rect>
                  <path stroke="#171717" d="M9.836 2.667l4 4m-4 0l4-4"></path>
                </svg>
                <Typography
                  sx={{
                    color: "#171717",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.2px",
                  }}
                >
                  Unposted
                </Typography>
              </Stack>
              <Stack direction="row" gap="10px" sx={{ alignItems: "center" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="14"
                  fill="none"
                  viewBox="0 0 18 14"
                >
                  <path
                    fill="#171717"
                    fillRule="evenodd"
                    d="M6.083.678C6.14.675 6.2.675 6.276.675H8.22a1.855 1.855 0 011.866 1.345l.001.006a1.107 1.107 0 00.427.504c1.453.002 2.211.029 2.734.454.108.088.206.186.293.293.454.558.454 1.385.454 3.038 0 .42 0 .628-.101.792a.669.669 0 01-.068.09c-.128.145-.328.205-.73.326l-3.15.945v-.54a1.35 1.35 0 00-1.349-1.35H5.898a1.35 1.35 0 00-1.35 1.35v.54l-3.15-.945c-.4-.12-.602-.18-.73-.325a.675.675 0 01-.067-.09C.5 6.942.5 6.733.5 6.314c0-1.653 0-2.48.454-3.038.088-.108.186-.206.293-.293.523-.425 1.281-.452 2.734-.454l.061-.043c.18-.138.31-.31.366-.46A1.855 1.855 0 016.083.678zm3.066 1.73c.016.041.034.082.054.122H5.29c.02-.04.037-.08.054-.123v-.002l.003-.006.004-.008.003-.009.002-.006.002-.004.002-.008.003-.008.001-.004.002-.005.002-.005.001-.004c.026-.078.034-.103.042-.122a.843.843 0 01.732-.528l.145-.001h1.915l.145.001c.325.02.61.226.732.528l.042.122.001.004.001.005.002.005.002.004.002.008.003.008.001.004.003.006.003.009.003.008.003.006.001.002h.001zM8.596 7.59H5.898a.337.337 0 00-.338.338v1.459a.337.337 0 00.212.313l.472.188a2.699 2.699 0 002.006 0l.472-.188a.337.337 0 00.212-.313v-1.46a.337.337 0 00-.338-.337zM4.555 9.527L1.179 8.513c.02 2.101.143 4.037.885 4.696.89.791 2.32.791 5.183.791 2.862 0 4.294 0 5.183-.79.742-.66.865-2.596.885-4.697L9.938 9.526a1.35 1.35 0 01-.84 1.113l-.473.19a3.71 3.71 0 01-2.756 0l-.473-.19a1.349 1.349 0 01-.84-1.113h-.001z"
                    clipRule="evenodd"
                  ></path>
                  <rect
                    width="9.446"
                    height="9.446"
                    x="7.922"
                    fill="#fff"
                    rx="4.723"
                  ></rect>
                  <path
                    fill="#171717"
                    fillRule="evenodd"
                    d="M14.036 6.25l.004-.01.605-3.047v-.01c0-.076-.029-.142-.09-.182a.253.253 0 00-.158-.034.54.54 0 00-.166.042h-.003l-3.37 1.323h-.001a.452.452 0 00-.099.05c-.043.03-.126.101-.112.212.012.093.075.151.118.181a.416.416 0 00.081.045l.007.003h.003l.59.2a.36.36 0 00.011.111l.296 1.12a.302.302 0 00.571.04l.461-.493.792.608.012.004c.072.032.139.042.2.033a.3.3 0 00.147-.063.351.351 0 00.099-.128l.002-.004V6.25zM11.75 5.057a.06.06 0 01.026-.066l2-1.27s.118-.072.113 0c0 0 .021.012-.042.07l-1.563 1.509a.056.056 0 00-.016.027l-.223.85-.295-1.12z"
                    clipRule="evenodd"
                  ></path>
                </svg>

                <Typography
                  sx={{
                    color: "#171717",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.2px",
                  }}
                >
                  Posted
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <ButtonDetail onclick={() => navigate("list")} />
          )}
          <Stack direction="row">
            <RowPerPage
              setPageSize={setPageSize}
              pageSize={pageSize}
              setPage={setPage}
            />
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
          </Stack>
        </Box>
      </Box>
    </Card>
  );
};

export default TableRequest;
