import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
  Grid,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { selectEmployeeDetail } from "../../../../data/select";
import {
  IconBank,
  IconDocument,
  IconJob,
  IconNote,
  IconProfile,
  IconResume,
} from "../../../../assets/employee";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuEmployeeDetail } from "../../../../data/menu";
import { Buttons } from "../../../atoms";

const SelectEmployee = ({ setValue }) => {
  const navigate = useNavigate();
  const parsedData = JSON.parse(localStorage.getItem("cvSayaData"));
  const selectEmployee = parsedData && parsedData.selectEmployee;

  const path = useLocation().pathname;
  const splitPath = path.split("/");
  const id = splitPath[4];
  const id_edit = splitPath[6];

  const [selectedEmployee, setSelectedEmployee] = useState(
    selectEmployee ? selectEmployee : selectEmployeeDetail[0]
  );

  // --- Hande Change ---
  const handleChange = (event) => {
    const selected = event.target.value;
    const url = selected.toLowerCase();
    let parsedData = JSON.parse(localStorage.getItem("cvSayaData"));

    if (!parsedData) {
      parsedData = { selectEmployee: selected, employeeDetailMenu: "0" };
    } else {
      parsedData.selectEmployee = selected;
      parsedData.employeeDetailMenu = "0";
    }

    localStorage.setItem("cvSayaData", JSON.stringify(parsedData));

    setSelectedEmployee(selected);
    setValue(0);
    navigate(
      selected === "profile"
        ? `/employee/employee/detail/${id}`
        : `/employee/employee/detail/${id}/${url}`
    );
  };

  const handleEditEmployee = () => {
    navigate(`/employee/employee/edit/${id}`);
  };

  return (
    <Box>
      <Grid container columns={2} spacing={0}>
        <Box>
          <Buttons
            onClick={handleEditEmployee}
            color="primary"
            variant="contained"
            // type="submit"
            sx={{
              mr: "10px",
              borderRadius: "8px",
              width: "80px",
            }}
          >
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontWeight={700}
              fontSize={"12px"}
              lineHeight={"17px"}
              color={"#fff"}
              textTransform={"none"}
            >
              Edit
            </Typography>
          </Buttons>
        </Box>

        <Box>
          <FormControl>
            <Select
              value={selectedEmployee}
              onChange={handleChange}
              sx={{
                height: "30px",
                ".MuiSelect-root": {
                  border: "1px solid red",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff",
                },
              }}
            >
              {/* <menuitem */}
              {selectEmployeeDetail &&
                selectEmployeeDetail.map((item, idx) => (
                  <MenuItem value={item} key={idx}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <IconButton
                        sx={{
                          mr: "5px",
                          p: "0px",
                        }}
                      >
                        {React.cloneElement(
                          item === "Profile" ? (
                            <IconProfile />
                          ) : item === "Document" ? (
                            <IconDocument />
                          ) : item === "Job" ? (
                            <IconJob />
                          ) : item === "Note" ? (
                            <IconNote />
                          ) : item === "Resume" ? (
                            <IconResume />
                          ) : (
                            <IconBank />
                          ),
                          {
                            style: {
                              fill:
                                item === selectedEmployee
                                  ? "#51B15C"
                                  : "#303030",
                            },
                          }
                        )}
                      </IconButton>
                      <Typography
                        fontFamily={"sans-serif"}
                        fontStyle={"normal"}
                        fontWeight={600}
                        fontSize={"12px"}
                        lineHeight={"16px"}
                        letterSpacing={"0.01em"}
                        color={item === selectEmployee ? "#51B15C" : "#171717"}
                      >
                        {item}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Box>
  );
};

export default SelectEmployee;
