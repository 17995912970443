import { configureStore } from '@reduxjs/toolkit';
import {
  companyReducer,
  idEmployeeReducer,
  idPayrollReducer,
  languageReducer,
  navigationReducer,
  calendarIndexReducer,
} from '.';

const Store = configureStore({
  reducer: {
    navigation: navigationReducer,
    language: languageReducer,
    company: companyReducer,
    idEmployee: idEmployeeReducer,
    idPayroll: idPayrollReducer,
    calendar: calendarIndexReducer,
  },
});

export default Store;
