import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import {
  IconDelete,
  IconDetail,
  IconEdit,
} from '../../../../../../assets/commons';
import { IconDescription } from '../../../../../../assets/coreHr/promotion';
import { Tooltips, TooltipsAction } from '../../../../../atoms';
import { capitalizeFirstLetter } from '../../../../../../utils/format';
import {
  handleTooltipClose,
  handleTooltipOpen,
} from '../../../../../../utils/function/tooltips';
import { handleAction } from '../../../../../../utils/function/action';
import { ModalDelete } from '../../../../modal';
import CoreHRPromotionDetail from '../../../../../../pages/coreHR/promotions/detail';
import { getPromotionById } from '../../../../../../configs/axios/apiPath';
import { useSelector } from 'react-redux';
import { getCompany } from '../../../../../../features/companies';

const PromotionTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [idEmployee, setIdEmployee] = useState(null);
  const [detail, setDetail] = useState([]);

  const [idTooltip, setIdTooltip] = useState('');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [idTooltipDetail, setIdTooltipDetail] = useState('');
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);
  const [idTooltipDelete, setIdTooltipDelete] = useState('');
  const [isTooltipDelete, setIsTooltipDelete] = useState(false);
  const [idTooltipEdit, setIdTooltipEdit] = useState('');
  const [isTooltipEdit, setIsTooltipEdit] = useState(false);

  // --- Handle Edit ---
  const handleEdit = (id) => {
    navigate(`/core-hr/promotion/edit/${id}`);
  };

  // --- Load Detail ---
  useEffect(() => {
    async function fetchData() {
      if (idEmployee) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getPromotionById(idEmployee);
          setDetail(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('data');
            navigate(`/`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [idEmployee]);

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Employee Name --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'start',
                verticalAlign: 'middle',
              }}
            >
              <Box
                display={'flex'}
                flexDirection={'start'}
                justifyContent={'start'}
                alignItems={'center'}
              >
                <img
                  src={item.employee?.profile_picture}
                  alt={item.employee?.name}
                  width={30}
                  style={{ borderRadius: '50%' }}
                />
                <Box ml={'10px'}>
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontWeight={400}
                    fontSize={'14px'}
                    lineHeight={'130%'}
                    color={'#171717'}
                  >
                    {item.employee?.name}
                  </Typography>
                </Box>
              </Box>
            </td>
            {/* --- Promotion Name --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'start',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item.promotion?.name}
              </Typography>
            </td>
            {/* --- Date of Appointment --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'start',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {moment(item?.date_of_appointment).format('D MMM')}
              </Typography>
            </td>
            {/* --- Description --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Tooltips
                title={item.description}
                open={isTooltipOpen && item.id === idTooltip}
                arrow
                placement={'bottom-end'}
              >
                <Button
                  onMouseEnter={() =>
                    handleTooltipOpen(item.id, setIsTooltipOpen, setIdTooltip)
                  }
                  onMouseLeave={() => handleTooltipClose(setIsTooltipOpen)}
                >
                  <IconDescription />
                </Button>
              </Tooltips>
            </td>
            {/* --- Status --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontSize={'10px'}
                fontWeight={600}
                textAlign={'center'}
                paddingY={'6px'}
                borderRadius={'6px'}
                letterSpacing={'0.02rem'}
                lineHeight={'13px'}
                style={{
                  backgroundColor:
                    item.status === 'approved'
                      ? 'rgba(81, 177, 92, 0.2)'
                      : item.status === 'pending'
                      ? 'rgba(207, 136, 18, 0.2)'
                      : item.status === 'rejected'
                      ? 'rgba(255, 29, 29, 0.2)'
                      : 'transparent',
                  color:
                    item.status === 'approved'
                      ? '#51B15C'
                      : item.status === 'pending'
                      ? '#CF8812'
                      : item.status === 'rejected'
                      ? '#FF1D1D'
                      : '#171717',
                }}
              >
                {capitalizeFirstLetter(item.status)}
              </Typography>
            </td>
            {/* --- Action --- */}
            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={1}
              >
                {/* --- Detail --- */}
                <TooltipsAction
                  title="Detail"
                  open={isTooltipDetail && item.id === idTooltipDetail}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        'detail'
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDetail,
                        setIdTooltipDetail
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDetail)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>

                {/* --- Delete --- */}
                <TooltipsAction
                  title="Delete"
                  open={isTooltipDelete && item.id === idTooltipDelete}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        'delete'
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDelete,
                        setIdTooltipDelete
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDelete)}
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>

                {/* --- Edit --- */}
                <TooltipsAction
                  title="Edit"
                  open={isTooltipEdit && item.id === idTooltipEdit}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleEdit(item.id)}
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipEdit,
                        setIdTooltipEdit
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipEdit)}
                  >
                    <IconEdit />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
      {/* --- Modal Handle --- */}
      {idEmployee !== null && modal === 'detail' && company ? (
        <CoreHRPromotionDetail
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          detail={detail}
          isLoading={isLoading}
          id={idEmployee}
          setIsLoading={setIsLoading}
          company={company}
        />
      ) : modal === 'delete' ? (
        <ModalDelete
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={'Promotion'}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          id={idEmployee}
          data={tbody}
          setData={setData}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default PromotionTbody;
