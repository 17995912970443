import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { IconDelete, IconEdit } from '../../../../../assets/commons';
import { TooltipsAction } from '../../../../atoms';
import {
  handleTooltipClose,
  handleTooltipOpen,
} from '../../../../../utils/function/tooltips';
import { ModalDelete } from '../../../../molecules/modal';
import { handleAction } from '../../../../../utils/function/action';
import { getAwardsById } from '../../../../../configs/axios/apiPath';

const TableScheduleTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState('');
  const [idEmployee, setIdEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState([]);

  const [idTooltipDelete, setIdTooltipDelete] = useState('');
  const [isTooltipDelete, setIsTooltipDelete] = useState(false);
  const [idTooltipEdit, setIdTooltipEdit] = useState('');
  const [isTooltipEdit, setIsTooltipEdit] = useState(false);

  // --- Handle Edit ---
  const handleEdit = (id) => {
    navigate(`/employee/schedule/edit/${id}`);
  };

  // --- Load Detail ---
  useEffect(() => {
    async function fetchData() {
      if (idEmployee !== null) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getAwardsById(idEmployee);
          setDetail(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [idEmployee]);

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- No --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {idx + 1}
              </Typography>
            </td>
            {/* --- Shift --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'start',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item.name}
              </Typography>
            </td>
            {/* --- Clock In --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.clock_in}
              </Typography>
            </td>
            {/* --- Clock Out --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.clock_out}
              </Typography>
            </td>
            {/* --- Start Break --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.start_break}
              </Typography>
            </td>
            {/* --- End Break --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.end_break}
              </Typography>
            </td>
            {/* --- Break Duration --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.break_duration}
              </Typography>
            </td>
            {/* --- Action --- */}
            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={1}
              >
                {/* --- Edit --- */}
                <TooltipsAction
                  title="Edit"
                  open={isTooltipEdit && item.id === idTooltipEdit}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleEdit(item.id)}
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipEdit,
                        setIdTooltipEdit
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipEdit)}
                  >
                    <IconEdit />
                  </Box>
                </TooltipsAction>

                {/* --- Delete --- */}
                {/* <TooltipsAction
                  title="Delete"
                  open={isTooltipDelete && item.id === idTooltipDelete}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        'delete'
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDelete,
                        setIdTooltipDelete
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDelete)}
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction> */}
              </Box>
            </td>
          </tr>
        ))}
      </tbody>

      {/* --- Modal Handle --- */}

      <ModalDelete
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={'Shift'}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        id={idEmployee}
        data={tbody}
        setData={setData}
      />
    </>
  );
};

export default TableScheduleTbody;
