import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  Pagination,
  RowPerPage,
  Search,
} from "../../../../components/atoms";
import { getCompany } from "../../../../features/companies";

import TableComponent from "../../../../components/molecules/table/mainTable";
import { getLanguage } from "../../../../features/language";
import {
  DepartmentListTbody,
  DepartmentListThead,
} from "../../../../components/molecules/table/tableOrganization/departmentList";
import { DataTableDepartmentList } from "../../../../data/table/organization";
import { dataDepartmentListFooter } from "../../../../data/footer/dataDepartmentListFooter";
import { IconExport } from "../../../../assets/employee";
import { getListEmployees } from "../../../../configs/axios/apiPath/fetchEmployee/fetchEmployeeManage";
import { ExportList } from "..";

export default function DepartmentList() {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [employee, setEmployee] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [total, setTotal] = useState(100);
  const [lastPage, setLastPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const lang = useSelector(getLanguage);

  const path = useLocation();
  const pathname = path.pathname;
  const paths = pathname.split("/");
  const dept = paths[4].toLowerCase();

  const [exportDocument, setExportDocument] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleExportDocument = () => {
    setIsModalOpen(true);
    setExportDocument("pdf");
  };

  useEffect(() => {
    if (company) {
      const fetchEmployee = async () => {
        const params = {
          company_id: company,
          page: page,
          page_size: rowsPerPage,
          search: keyword,
          department_id: dept,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListEmployees(params);
          setEmployee(data?.employees);
          setLastPage(data?.page_info?.last_page);
          setTotal(data?.page_info?.total);
        } catch (error) {
          //console.error(error);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      };

      fetchEmployee();
    }
  }, [company, dept, keyword, navigate, page, rowsPerPage]);

  return (
    <Card
      sx={{
        mt: "20px",
      }}
    >
      <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Search
            placeholder="Type here to search"
            setSearch={(e) => setKeyword(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />

          {/* start select export */}
          <Select
            input={
              <OutlinedInput
                startAdornment={
                  <InputAdornment position="start">
                    <IconExport />
                    <Typography
                      fontFamily={"Open Sans"}
                      fontWeight={600}
                      fontSize={"12px"}
                      lineHeight={"16.34px"}
                      sx={{
                        marginLeft: "5px",
                      }}
                    >
                      Export
                    </Typography>
                  </InputAdornment>
                }
              />
            }
            sx={{
              background: "#f9f9f9",
              height: "30px",
              ".MuiSelect-root": {
                border: "1px solid red",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
            }}
          >
            <MenuItem onClick={handleExportDocument}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                Pdf
              </Box>
            </MenuItem>
          </Select>
          {/* end select export */}
        </Box>
        <Box sx={{ mt: "24px", mb: "16px" }}>
          <TableComponent
            isLoading={isLoading}
            tbody={employee}
            TableHead={<DepartmentListThead thead={DataTableDepartmentList} />}
            TableBody={<DepartmentListTbody tbody={employee} />}
            col={6}
          />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {/* --- Icon Footers --- */}
          <Box display={"flex"} alignItems={"center"}>
            {dataDepartmentListFooter.map((item, idx) => (
              <Box display={"flex"} alignItems={"center"} key={idx} mr={"30px"}>
                <IconButton sx={{ p: "0px", mr: "10px" }}>
                  {item.icon}
                </IconButton>
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"130%"}
                  color="#171717"
                >
                  {lang === "Id" ? item.judul : item.title}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <RowPerPage
              setPageSize={setRowsPerPage}
              pageSize={rowsPerPage}
              setPage={setPage}
            />
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
          </Box>
        </Box>
      </Box>
      {exportDocument === "pdf" ? (
        <ExportList
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          company={company}
          department={dept}
          isLoading={isLoading}
          data={employee}
        />
      ) : (
        ""
      )}
    </Card>
  );
}
