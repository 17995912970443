import { Box } from "@mui/material";
import SelectYear from "../../../../molecules/year";
import { useState } from "react";
import { getLanguage } from "../../../../../features/language";
import { getCompany } from "../../../../../features/companies";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Card, Pagination, RowPerPage, Search } from "../../../../atoms";
import { useEffect } from "react";
import { getListAccount } from "../../../../../configs/axios/apiPath";
import { DataTableAccount } from "../../../../../data/table/benefit";
import TableComponent from "../../../../molecules/table/mainTable";
import {
  AccountTbody,
  AccountThead,
} from "../../../../molecules/table/tableBenefit/account";

const TableAccount = () => {
  const yearNow = moment().format("YYYY");
  const year = [];
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [selectedYear, setSelectedYear] = useState(yearNow);
  const [isLoading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [order, setOrder] = useState("name");
  const [direction, setDirection] = useState("-");
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);

  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }

  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          company_id: company,
          search: "",
          /* sort: `${direction}${order}`, */
          keywords: keyword,
          year: selectedYear,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListAccount(params);
          setLastPage(data?.page_info?.last_page);
          setAccounts(data?.page_data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [company, direction, keyword, navigate, order, selectedYear]);

  return (
    <Card sx={{ marginTop: "20px" }}>
      <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
        <Box display={"flex"} justifyContent={"space-between"}>
          {/* --- Search --- */}
          <Search
            placeholder={
              lang === "Id"
                ? "Ketik disini untuk mencari"
                : "Type here to search"
            }
            setSearch={(e) => setKeyword(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />

          <Box display={"flex"} alignItems={"center"}>
            {/* --- Selected Year --- */}
            <SelectYear
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              year={year}
            />
          </Box>
        </Box>

        {/* --- Table --- */}
        <Box sx={{ mt: "24px", mb: "16px" }}>
          <TableComponent
            isLoading={isLoading}
            tbody={accounts}
            TableHead={
              <AccountThead
                thead={DataTableAccount}
                setOrder={setOrder}
                setDirection={setDirection}
                direction={direction}
              />
            }
            TableBody={
              <AccountTbody
                tbody={accounts}
                setData={setAccounts}
              />
            }
            col={4}
          />
        </Box>

        {/* --- Pagination --- */}
        <Box display={"flex"} justifyContent={"end"}>
          <RowPerPage
            setPageSize={setPageSize}
            pageSize={pageSize}
            setPage={setPage}
          />
          <Pagination page={page} setPage={setPage} lastPage={lastPage} />
        </Box>
      </Box>
    </Card>
  );
};

export default TableAccount;
