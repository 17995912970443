import { Box } from "@mui/material"
import { ForgetPasswordContentFormLogin, ForgetPasswordContentTitle } from "../../components/organisms/forgetPassword"

const ForgetPasswordPage = () => {
    return (
        <Box 
            display={'flex'} 
            justifyContent={'center'} 
            alignItems={'center'}
            flexDirection={'column'}
        >
            <ForgetPasswordContentTitle />
            <ForgetPasswordContentFormLogin />   
        </Box>
    )
}

export default ForgetPasswordPage