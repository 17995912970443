const handleProjectLabel = (status) => {
  let label = '';
  let color = '';

  if (status === 'done') {
    color = '#51B15C';
    label = 'Done';
  } else if (status === 'process') {
    color = '#CF8812';
    label = 'Process';
  } else if (status === 'delayed') {
    color = '#352CCF';
    label = 'Delayed';
  } else if (status === 'canceled') {
    color = '#FF1D1D';
    label = 'Canceled';
  }

  return {label, color}
};

export default handleProjectLabel