import { Box } from '@mui/material';
import React from 'react';
import {
  TableAttendance,
  ChartAttendance,
  TableAttendances,
  ChartAttendances
} from '../../../../components/organisms/timesheet';

const TimesheetAttendanceDashboard = () => {
  return (
    <Box>
      <ChartAttendances />
      <TableAttendances/>
    </Box>
  );
};

export default TimesheetAttendanceDashboard;
