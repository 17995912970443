import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguage } from '../../../../../features/language';
import { Divider, Typography } from '@mui/material';
import { setNavigation } from '../../../../../features/navigation';
import { Outlet, useNavigate } from 'react-router-dom';
import { convertToDashCase } from '../../../../../utils/format';
import { useEffect } from 'react';
import { useState } from 'react';
import SelectEmployee from '../../../select/selectEmployeeDetail';
import { MenuEmployeeDetail } from '../../../../../data/menu';

const EmployeeDetailTabPanel = () => {
  const [value, setValue] = useState(() => {
    const parsedData = JSON.parse(localStorage.getItem('cvSayaData'));
    const menu = parsedData && parsedData.employeeDetailMenu;
    return menu ? parseInt(menu) : 0;
  });

  const lang = useSelector(getLanguage);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const menus = MenuEmployeeDetail.filter((item) => item.id === value);

    if (lang === 'Id') {
      navigate(`${menus.title}`);
      dispatch(setNavigation(menus[0].judul));
    } else {
      navigate(`${menus[0].title}`);
      dispatch(setNavigation(menus[0].title));
    }
  }, [value, lang]);

  const handleChange = (event, newValue) => {
    const title = MenuEmployeeDetail[newValue].title.toLowerCase();
    const url = convertToDashCase(title);
    const navigation =
      lang === 'Id'
        ? MenuEmployeeDetail[newValue].judul
        : MenuEmployeeDetail[newValue].title;

    setValue(newValue);
    navigate(`${url}`);
    dispatch(setNavigation(navigation));
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('cvSayaData'));
    data.employeeDetailMenu = value.toString();
    localStorage.setItem('cvSayaData', JSON.stringify(data));
  }, [value]);

  return (
    <Box width={'100%'}>
      {/* --- Tab Panel --- */}
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box
          sx={{
            maxWidth: {
              xl: '96vw',
              lg: '96vw',
              md: '89vw',
              xs: '75vw',
              sm: '84vw',
            },
            p: '0px',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="coreHR"
          >
            {MenuEmployeeDetail.map((item, idx) => (
              <Tab
                label={
                  <Box display={'flex'} alignItems={'center'}>
                    {/* --- Title --- */}
                    <Box
                      ml={'16px'}
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'start'}
                    >
                      <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontWeight={600}
                        fontSize={'14px'}
                        lineHeight={'19px'}
                        textTransform={'none'}
                        mb={'2px'}
                      >
                        {lang === 'Id' ? item.judul : item.title}
                      </Typography>
                    </Box>
                  </Box>
                }
                key={idx}
              />
            ))}
          </Tabs>
        </Box>
        {/* --- Select --- */}
        <SelectEmployee setValue={setValue} />
      </Box>
      <Divider />

      {/* --- Outlet --- */}
      <Outlet />
    </Box>
  );
};

export default EmployeeDetailTabPanel;
