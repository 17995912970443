import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { IconLocationProvince } from "../../../../assets/department";

export default function SelectVillage({
  data,
  setValue,
  value,
  inputValue,
  inputChange,
  loading,
}) {
  return (
    <>
      <Autocomplete
        id="country-autocomplete"
        loading={loading}
        loadingText={<div style={{ textAlign: "center" }}>loading ...</div>}
        options={data}
        getOptionLabel={(d) => d.name}
        inputValue={inputValue}
        onChange={(e, value) => setValue(value)}
        value={value}
        onInputChange={(e, value) => inputChange(value)}
        renderOption={(params, option) => {
          return (
            <li {...params} key={option.code}>
              {option.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <IconLocationProvince />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </>
  );
}
