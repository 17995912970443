import { Box, Button, TextField, Typography, CircularProgress } from "@mui/material"
import { addNewBoard } from "../../../../configs/axios/apiPath/fetchProject/fetchBoard";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

const CardAddListBoard = (props) => {
  const navigate = useNavigate();
  const [name, setName] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const addBoard = async (resolve, reject) => {
   

    if (name === '' ) return;

    const postData = {"name" : name };


    setIsLoading(true);
    try {
      const {
          data : {data}
        } = await addNewBoard(props.projectId, postData);
         props.fetchHandle()
        props.closeHandle()
        resolve()
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
  }
  return(
    <Box 
      sx={{
        width: '260px',
        height: '85px',
        padding: '10px',
      }}
    >
      <TextField
        required
        fullWidth
        variant="outlined"
        size='small'
        onChange={e => setName(e.target.value)}
        placeholder="Enter New List"
        sx={{
          '& .MuiOutlinedInput-root': {
            background: '#f9f9f9',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#51B15C',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#51B15C',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#51B15C',
            },
          },
        }}
      />
      <Box display={'flex'} gap={2} marginTop={'10px'}>
        <Button 
          variant="contained" 
          sx={{
            width: '60px', 
            height: '32px',
          }}
          onClick={addBoard}
        >
          <Typography
            fontFamily={'sans-serif'}
            fontStyle={'normal'}
            fontWeight={700}
            fontSize={'10px'}
            color="primary.contrastText"
            textTransform={'none'}
          >
           {isLoading ? (
              <CircularProgress color="inherit" size={16} />
            ) : (
              <div>Add</div>
            )}
          </Typography>
        </Button>
        <Button 
          variant="contained" 
          onClick={props.closeHandle}
          sx={{
            width: '60px', 
            height: '32px', 
            color: '#939393', 
            bgcolor: "#F7F7F7",
            '&:hover': {
              background: 'none',
            }
          }}
        >
          <Typography
            fontFamily={'sans-serif'}
            fontStyle={'normal'}
            fontWeight={700}
            fontSize={'10px'}
            textTransform={'none'}
          >
          Cancel
          </Typography>
        </Button>
      </Box>
    </Box>
  )
}

export default CardAddListBoard