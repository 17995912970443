import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  Stack,
} from '@mui/material';
import moment from 'moment';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { Card, Modals } from '../../../../components/atoms';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../../../features/language';
import { IconClose } from '../../../../assets/commons';
import {
  IconImage,
  IconFile,
} from '../../../../assets/benefit/officeFacillities';
import {
  deleteDocumentOfficeFacillitiesById,
  getDocumentOfficeFacillitiesById,
  updateOfficeFacillitiesById,
} from '../../../../configs/axios/apiPath';
import FileSaver from 'file-saver';
import { useNavigate } from 'react-router-dom';

const BenefitOfficeFacillitiesDetail = ({
  isOpen,
  onClose,
  detail,
  isLoading,
}) => {
  const lang = useSelector(getLanguage);
  const [idDocument, setIdDocument] = useState();
  const [isLoadingDocument, setIsLoadingDocument] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [document, setDocument] = useState();
  const [documentBlob, setDocumentBlob] = useState();
  const navigate = useNavigate();
  const handleDelete = async () => {
    try {
      setIsLoadingDelete(true);
      await updateOfficeFacillitiesById(
        {
          document_ids: detail.documents.filter(
            (document) => document.id !== idDocument
          ),
        },
        detail.id
      );
      deleteDocumentOfficeFacillitiesById(idDocument);
    } catch (error) {
      console.error(error.response.status);
      if (error.response.status === 401) {
        localStorage.removeItem('cvSayaLogin');
        navigate(`/users`);
      }
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const handleDownload = () => {
    FileSaver.saveAs(
      documentBlob,
      detail.documents.find((document) => document.id === idDocument)
        .original_file_name
    );
  };

  useEffect(() => {
    async function fetchData() {
      if (idDocument) {
        setIsLoadingDocument(true);
        try {
          const { data } = await getDocumentOfficeFacillitiesById(
            {},
            idDocument
          );
          setDocument(URL.createObjectURL(data));
          setDocumentBlob(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoadingDocument(false);
        }
      }
    }

    fetchData();
  }, [idDocument, navigate]);

  return (
    <Modals
      open={isOpen}
      onClose={() => {
        setIdDocument();
        onClose();
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: '80vh',
          overflowY: 'auto',
          bgcolor: 'background.paper',
        }}
      >
        <Card>
          <Box p={'30px'}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '7px',
                position: 'sticky',
                top: 0,
                bgcolor: 'background.paper',
                p: '10px 0px 10px 0px',
                zIndex: 999,
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontSize={'20px'}
                fontWeight={600}
                lineHeight={'27px'}
                color={'#171717'}
              >
                {!idDocument ? (lang === 'Id' ? 'Detail' : 'Details') : ''}
              </Typography>
              <IconButton sx={{ p: '0px' }} onClick={onClose}>
                <IconClose />
              </IconButton>
            </Box>

            {!isLoading ? (
              idDocument && document ? (
                <Box position="relative" width="100%">
                  <img
                    alt="preview"
                    src={document}
                    width={'100%'}
                    style={{ borderRadius: '8px', objectFit: 'cover' }}
                  />
                  <Stack
                    position="absolute"
                    right="20px"
                    top="20px"
                    direction="row"
                    gap="20px"
                  >
                    <Button
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={handleDelete}
                      startIcon={
                        <svg
                          width="13"
                          height="14.625"
                          viewBox="0 0 8 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M6.55394 3.18792C6.71914 3.20152 6.84234 3.34592 6.82914 3.51113C6.82674 3.53833 6.60994 6.22277 6.48514 7.34878C6.40754 8.04759 5.94593 8.4728 5.24912 8.4856C4.71591 8.4948 4.2015 8.5 3.6987 8.5C3.15669 8.5 2.62828 8.494 2.10547 8.4832C1.43666 8.47 0.973855 8.03639 0.898253 7.35158C0.772252 6.21557 0.556648 3.53793 0.554648 3.51113C0.541048 3.34592 0.66425 3.20112 0.829452 3.18792C0.992255 3.18352 1.13946 3.29792 1.15266 3.46272C1.15393 3.48009 1.24207 4.57355 1.33812 5.65544L1.35742 5.87134C1.40579 6.4091 1.45483 6.92583 1.49466 7.28558C1.53746 7.67479 1.74747 7.87559 2.11787 7.88319C3.11789 7.90439 4.1383 7.90559 5.23832 7.88559C5.63192 7.87799 5.84473 7.68119 5.88873 7.28278C6.01273 6.16517 6.22873 3.48992 6.23113 3.46272C6.24433 3.29792 6.39034 3.18272 6.55394 3.18792ZM4.53823 0.5C4.90543 0.5 5.22824 0.747604 5.32304 1.10241L5.42464 1.60682C5.45747 1.77217 5.60258 1.89292 5.7706 1.89558L7.08331 1.89562C7.24891 1.89562 7.38331 2.03002 7.38331 2.19563C7.38331 2.36123 7.24891 2.49563 7.08331 2.49563L5.78232 2.49557C5.7803 2.49561 5.77828 2.49563 5.77625 2.49563L5.76649 2.49523L1.61667 2.49558C1.61345 2.49561 1.61022 2.49563 1.60698 2.49563L1.60082 2.49523L0.300004 2.49563C0.134402 2.49563 0 2.36123 0 2.19563C0 2.03002 0.134402 1.89562 0.300004 1.89562L1.61242 1.89522L1.65283 1.89266C1.80335 1.87313 1.92844 1.75882 1.95899 1.60682L2.05619 1.12041C2.15499 0.747604 2.4778 0.5 2.845 0.5H4.53823ZM4.53823 1.10001H2.845C2.749 1.10001 2.6646 1.16441 2.6402 1.25681L2.547 1.72482C2.53515 1.7841 2.5179 1.84123 2.49583 1.89573H4.88752C4.86542 1.84123 4.84813 1.7841 4.83623 1.72482L4.73903 1.23841C4.71863 1.16441 4.63423 1.10001 4.53823 1.10001Z"
                            fill="white"
                          />
                        </svg>
                      }
                    >
                      Delete
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleDownload}
                      fontSize="8px"
                      startIcon={
                        <svg
                          width="14.625"
                          height="14.625"
                          viewBox="0 0 9 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.38281 6.5L1.88281 4L2.58281 3.275L3.88281 4.575V0.5H4.88281V4.575L6.18281 3.275L6.88281 4L4.38281 6.5ZM0.382812 8.5V6H1.38281V7.5H7.38281V6H8.38281V8.5H0.382812Z"
                            fill="white"
                          />
                        </svg>
                      }
                    >
                      Download
                    </Button>
                  </Stack>
                </Box>
              ) : (
                <Box
                  mt={'20px'}
                  component={'form'}
                  noValidate
                  autoComplete="off"
                >
                  {/* Item */}
                  <Box
                    mb={'20px'}
                    p={'0px'}
                    sx={{
                      position: 'relative',
                    }}
                  >
                    <Typography
                      fontFamily={'sans-serif'}
                      fontStyle={'normal'}
                      fontSize={'13px'}
                      fontWeight={600}
                      lineHeight={'18px'}
                      letterSpacing={'1px'}
                      color={'#171717'}
                      mb={'6px'}
                    >
                      {lang === 'Id' ? 'Barang' : 'Item'}
                    </Typography>
                    <Box
                      style={{
                        width: '100%',
                        background: '#f7f7f7',
                        borderRadius: '4px',
                        border: '1px solid transparent',
                        display: 'inline-block',
                      }}
                    >
                      <Typography mx={'10px'} py={'10px'}>
                        {detail?.name}
                      </Typography>
                    </Box>
                  </Box>
                  {/* --- Responsible Employee --- */}
                  <Box
                    mb={'20px'}
                    p={'0px'}
                    sx={{
                      position: 'relative',
                    }}
                  >
                    <Typography
                      fontFamily={'sans-serif'}
                      fontStyle={'normal'}
                      fontSize={'13px'}
                      fontWeight={600}
                      lineHeight={'18px'}
                      letterSpacing={'1px'}
                      color={'#171717'}
                      mb={'6px'}
                    >
                      {lang === 'Id'
                        ? 'Karyawan Yang Bertanggung Jawab'
                        : 'Responsible Employee'}
                    </Typography>
                    <Box
                      style={{
                        width: '100%',
                        background: '#f7f7f7',
                        borderRadius: '4px',
                        border: '1px solid transparent',
                        display: 'inline-block',
                      }}
                    >
                      <Typography mx={'10px'} py={'10px'}>
                        {detail?.responsible_employee.name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    mb={'20px'}
                    p={'0px'}
                    sx={{
                      position: 'relative',
                    }}
                  >
                    <Typography
                      fontFamily={'sans-serif'}
                      fontStyle={'normal'}
                      fontSize={'13px'}
                      fontWeight={600}
                      lineHeight={'18px'}
                      letterSpacing={'1px'}
                      color={'#171717'}
                      mb={'6px'}
                    >
                      {lang === 'Id' ? 'Harga' : 'Price'}
                    </Typography>
                    <Box
                      style={{
                        width: '100%',
                        background: '#f7f7f7',
                        borderRadius: '4px',
                        border: '1px solid transparent',
                        display: 'inline-block',
                      }}
                    >
                      <Typography mx={'10px'} py={'10px'}>
                        Rp {Number(detail?.price).toLocaleString('ID-id')}*
                      </Typography>
                    </Box>
                  </Box>
                  {/* --- Receive Date --- */}
                  <Box
                    mb={'20px'}
                    p={'0px'}
                    sx={{
                      position: 'relative',
                    }}
                  >
                    <Typography
                      fontFamily={'sans-serif'}
                      fontStyle={'normal'}
                      fontSize={'13px'}
                      fontWeight={600}
                      lineHeight={'18px'}
                      letterSpacing={'1px'}
                      color={'#171717'}
                      mb={'6px'}
                    >
                      {lang === 'Id' ? 'Tanggal' : 'Date'}
                    </Typography>
                    <Box
                      style={{
                        width: '100%',
                        background: '#f7f7f7',
                        borderRadius: '4px',
                        border: '1px solid transparent',
                        display: 'inline-block',
                      }}
                    >
                      <Typography mx={'10px'} py={'10px'}>
                        {moment(detail?.date).format('DD MMM YYYY')}
                      </Typography>
                    </Box>
                  </Box>

                  {/* --- File --- */}
                  <Box
                    mb={'20px'}
                    p={'0px'}
                    sx={{
                      position: 'relative',
                    }}
                  >
                    <Typography
                      fontFamily={'sans-serif'}
                      fontStyle={'normal'}
                      fontSize={'13px'}
                      fontWeight={600}
                      lineHeight={'18px'}
                      letterSpacing={'1px'}
                      color={'#171717'}
                      mb={'6px'}
                    >
                      File
                    </Typography>
                    <Box
                      sx={{
                        width: '100%',
                        background: '#f7f7f7',
                        borderRadius: '4px',
                        border: '1px solid transparent',
                        display: 'flex',
                        justifyContent: 'space-between',
                        px: '12px',
                        py: '15px',
                      }}
                    >
                      <Box sx={{ display: 'flex' }}>
                        {detail?.documents.map((document) => (
                          <Stack
                            direction="column"
                            maxWidth={'80px'}
                            alignItems="center"
                            onClick={() => setIdDocument(document.id)}
                          >
                            {document.mime_type.includes('image') ? (
                              <IconImage />
                            ) : (
                              <IconFile />
                            )}
                            <Typography
                              sx={{ fontSize: '8px', textAlign: 'center' }}
                            >
                              {document.original_file_name}
                            </Typography>
                          </Stack>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                  {/* --- Description --- */}
                  <Box
                    mb={'20px'}
                    p={'0px'}
                    sx={{
                      position: 'relative',
                    }}
                  >
                    <Typography
                      fontFamily={'sans-serif'}
                      fontStyle={'normal'}
                      fontSize={'13px'}
                      fontWeight={600}
                      lineHeight={'18px'}
                      letterSpacing={'1px'}
                      color={'#171717'}
                      mb={'6px'}
                    >
                      {lang === 'Id' ? 'Deskripsi' : 'Description'}
                    </Typography>
                    <Box
                      style={{
                        width: '100%',
                        background: '#f7f7f7',
                        borderRadius: '4px',
                        border: '1px solid transparent',
                        display: 'inline-block',
                      }}
                    >
                      <Typography
                        mx={'10px'}
                        py={'10px'}
                        height={'auto'}
                        maxHeight={'160px'}
                        sx={{ overflowY: 'scroll' }}
                      >
                        {detail?.description}
                      </Typography>
                    </Box>
                  </Box>

                  {/* --- Close --- */}
                  <Button
                    variant="contained"
                    disableRipple
                    onClick={onClose}
                    fullWidth
                    sx={{ height: '62px' }}
                  >
                    <Typography
                      fontFamily={'sans-serif'}
                      fontStyle={'normal'}
                      fontSize={'20px'}
                      fontWeight={'700px'}
                      lineHeight={'27px'}
                      letterSpacing={'1px'}
                      textTransform={'none'}
                    >
                      {lang === 'Id' ? 'Keluar' : 'Close'}
                    </Typography>
                  </Button>
                </Box>
              )
            ) : (
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <CircularProgress color="secondary" />
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </Modals>
  );
};

export default BenefitOfficeFacillitiesDetail;
