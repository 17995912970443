import {
  Autocomplete,
  Box,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
  Grid,
  InputAdornment,
  Stepper,
  Step,
  StepButton,
  StepLabel,
  Button,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";

export default function SecondStep({ currentData, setCurrentData }) {
  const FormLabel = ({ label }) => (
    <InputLabel
      style={{
        fontFamily: "sans-serif",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "22px",
        letterSpacing: "0.02em",
        color: "#171717",
        marginBottom: "10px",
      }}
    >
      {label}
    </InputLabel>
  );

  return (
    <Box>
      <Box mt={3}>
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={700}
          fontSize={"13px"}
          lineHeight={"32px"}
          color={"#939393"}
          textTransform={"none"}
        >
          Family Members
        </Typography>
      </Box>

      <Box display="flex">
        <Grid container columns={2} spacing={2}>
          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Husband/Wife Name" />
              <Box display={"flex"}>
                <TextField
                  variant="outlined"
                  size="large"
                  fullWidth
                  value={currentData.husband_wife}
                  placeholder="Masukkan Nama Suami / Istri"
                  onChange={(e) =>
                    setCurrentData("husband_wife", e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DescriptionIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Child 1" />
              <Box display={"flex"}>
                <TextField
                  variant="outlined"
                  size="large"
                  fullWidth
                  value={currentData.child_1}
                  placeholder="Masukkan Nama Anak Pertama"
                  onChange={(e) => setCurrentData("child_1", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DescriptionIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        mt={3}
        //margin
        display="flex"
      >
        <Grid container columns={2} spacing={2}>
          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Child 2" />
              <Box display={"flex"}>
                <TextField
                  variant="outlined"
                  size="large"
                  fullWidth
                  value={currentData.child_2}
                  placeholder="Masukkan Nama Anak Kedua"
                  onChange={(e) => setCurrentData("child_2", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DescriptionIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>

          <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
            <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
              <FormLabel label="Child 3" />
              <Box display={"flex"}>
                <TextField
                  variant="outlined"
                  size="large"
                  fullWidth
                  value={currentData.child_3}
                  placeholder="Masukkan Nama Anak Ketiga"
                  onChange={(e) => setCurrentData("child_3", e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DescriptionIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
