function RupiahFormatter(number) {
  const newNumber = number.replaceAll('.', '').replace('Rp', '');
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: 0,
  }).format(newNumber);
}

export default RupiahFormatter;
