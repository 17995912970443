export const DataPayrollHead = [
    {
        title: 'Salary Name',
        judul: 'Nama Gaji',
    },
    {
        title: 'Start Date',
        judul: 'Tanggal Dimulai',
    },
    {
        title: 'End Date',
        judul: 'Tanggal Berakhir',
    },
    {
        title: 'Working Day',
        judul: 'Hari Kerja',
    },
    {
        title: 'Action',
        judul: 'Aksi',
    },
]