import { Box, Grid, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getEmployeesById } from "../../../../../configs/axios/apiPath";
import { getCompany } from "../../../../../features/companies";
import { Card } from "../../../../../components/atoms";

const EmployeeBank = () => {
  const path = useLocation();
  const pathname = path.pathname;
  const paths = pathname.split("/");
  const id = paths[4];

  const [dataEmployee, setDataEmployee] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const company = useSelector(getCompany);

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        include: "bankAccount",
      };
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await getEmployeesById(id, params);
        console.info(data);
        setDataEmployee(data);
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id, navigate]);

  return (
    <Grid container columns={4} spacing={2} sx={{ mt: "30px" }}>
      <Grid item xl={1} lg={1} md={2} sm={4} xs={4}>
        <Card sx={{ p: "14px 30px" }}>
          <Typography
            fontFamily={"sans-serif"}
            fontStyle={"normal"}
            fontWeight={600}
            fontSize={"18px"}
            lineHeight={"25px"}
            letterSpacing={"1px"}
            color={"#171717"}
            mb={"20px"}
          >
            Bank Account
          </Typography>
          {dataEmployee.bank_account === null ? (
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontWeight={600}
              fontSize={"12px"}
              lineHeight={"16px"}
              color={"#939393"}
              mb={"4px"}
            >
              The data is still Empty
            </Typography>
          ) : (
            <Box>
              {/* --- Account Number --- */}
              <Box mb={"16px"}>
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#939393"}
                  mb={"3px"}
                >
                  Account Number
                </Typography>
                {isLoading ? (
                  <Skeleton variant="text" height={30} width={"15vw"} />
                ) : (
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={600}
                    fontSize={"15px"}
                    lineHeight={"20px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                  >
                    {dataEmployee?.bank_account?.account_number
                      ? dataEmployee?.bank_account?.account_number
                      : "-"}
                  </Typography>
                )}
              </Box>
              {/* --- Bank Name --- */}
              <Box mb={"16px"}>
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#939393"}
                  mb={"3px"}
                >
                  Bank Name
                </Typography>
                {isLoading ? (
                  <Skeleton variant="text" height={30} width={"15vw"} />
                ) : (
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={600}
                    fontSize={"15px"}
                    lineHeight={"20px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                  >
                    {dataEmployee?.bank_account?.bank_name
                      ? dataEmployee?.bank_account?.bank_name
                      : "-"}
                  </Typography>
                )}
              </Box>
              {/* --- On behalf of --- */}
              <Box mb={"16px"}>
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#939393"}
                  mb={"3px"}
                >
                  On behalf of
                </Typography>
                {isLoading ? (
                  <Skeleton variant="text" height={30} width={"15vw"} />
                ) : (
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={600}
                    fontSize={"15px"}
                    lineHeight={"20px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                  >
                    {dataEmployee?.employee?.name}
                  </Typography>
                )}
              </Box>
              {/* --- Provide Salary --- */}
              <Box mb={"16px"}>
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#939393"}
                  mb={"3px"}
                >
                  Provide Salary
                </Typography>
                {isLoading ? (
                  <Skeleton variant="text" height={30} width={"15vw"} />
                ) : (
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={600}
                    fontSize={"15px"}
                    lineHeight={"20px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                  >
                    {company}
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default EmployeeBank;
