import { Avatar, Box, SvgIcon, Tooltip, Typography } from "@mui/material"
import { IconNotes } from "../../../../../../assets/commons";
import AvatarGroupStyled from "../../../../avatar/avatarGroup";
import { IconProjectStatus } from "../../../../../../assets/project/icons";
import ProgressBarStyled from "../../../../progress/barProgress";


const ListProjectTbody = ({ tbody }) => {
  return(
    <tbody>
      {tbody.map((item, idx) => (
        <tr key={idx}>

          {/* --- Project Name --- */}
          <td
            style={{
              padding: '10px',
              textAlign: 'start',
              verticalAlign: 'middle',
            }}
          >
            <Box display={'flex'} alignItems={'center'}>
              <Avatar alt={item.name} src={item.imageUrl}/>
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
                ml={'10px'}
              >
                {item.name}
              </Typography>
            </Box>
          </td>

          {/* --- Due Date --- */}
          <td
            style={{
              padding: '10px 12px',
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
          >
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontWeight={400}
              fontSize={'14px'}
              lineHeight={'130%'}
              color={'#171717'}
            >
              {item.due_date}
            </Typography>
          </td>

          {/* --- Status --- */}
          <td
            style={{
              padding: '10px 12px',
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
          >
            <IconProjectStatus color={"white"}/>
          </td>

          {/* --- Detail Project --- */}
          <td
            style={{
              padding: '10px 12px',
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
          >
            <Tooltip title={item.detail} arrow placement="bottom-end">
              <SvgIcon>
                <IconNotes/>
              </SvgIcon>
            </Tooltip>
          </td>

          {/* --- Assignee --- */}
          <td
            style={{
              padding: '10px 12px',
              alignItems: 'center',
              verticalAlign: 'middle',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AvatarGroupStyled 
                width={24}
                height={24}
                maxShow={4}
                avatarData={item.assignee}
              />
            </Box>
          </td>

          {/* --- Project Progress --- */}
          <td
            style={{
              padding: '10px 30px',
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
          >
            <ProgressBarStyled
              progress={item.percentage}
              status={item.status}
            />
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default ListProjectTbody