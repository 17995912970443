const DataTableDepartment = [
    {
      title: 'Dept',
      judul: 'Departemen',
      url: 'name',
    },
    {
      title: 'Manager',
      judul: 'Menejer',
      url: 'menager',
    },
    {
      title: 'Employee',
      judul: 'Karyawan',
      url: 'employee',
    },
    {
      title: 'Action',
      judul: 'Aksi',
      url: 'name',
    },
  ];
  
  export default DataTableDepartment;