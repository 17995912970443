import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconCalendar, IconTime } from '../../../../assets/topNav/icons';
import DataLogin from '../../../../data/dataLogin';
import { getNavigation } from '../../../../features/navigation';
import { createNavigation } from '../../../../utils/format';

const NavigationTopbar = () => {
  const navigation = useSelector(getNavigation);
  const navigate = useNavigate();
  const getData = DataLogin();
  const path = useLocation().pathname;
  const navMain = createNavigation(path);

  //   --- Date ---
  const [currentDate, setCurrentDate] = useState('');
  const [currentTime, setCurrentTime] = useState('');
  const [greeting, setGreeting] = useState('');
  const [currentDateTimeFormat, setCurrentDateTimeFormat] = useState(
    new Date()
  );

  //   --- Time ---
  useEffect(() => {
    setInterval(() => setCurrentDateTimeFormat(new Date()), 1000);
  }, []);

  //   --- Set Time ---
  useEffect(() => {
    setCurrentDate(dayjs(currentDateTimeFormat).format('dddd,D MMMM YYYY'));
    setCurrentTime(dayjs(currentDateTimeFormat).format('HH:mm:ss'));
    const hour = Number(dayjs(currentDateTimeFormat).format('H'));

    if (hour >= 5 && hour < 12) {
      setGreeting('Morning');
    } else if (hour >= 12 && hour < 17) {
      setGreeting('Afternoon');
    } else if (hour >= 17 && hour <= 24) {
      setGreeting('Evening');
    } else if (hour >= 0 && hour < 5) {
      setGreeting('Night');
    }
  }, [currentDateTimeFormat]);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleNavigateMain = (path) => {
    const data = JSON.parse(localStorage.getItem('cvSayaData'));
    data.coreHRMenu = 0;
    localStorage.setItem('cvSayaData', JSON.stringify(data));
    navigate(path);
  };

  return (
    <Box>
      {navigation === 'Dashboard' ? (
        <Box display={'flex'} flexDirection={'column'}>
          <Typography
            fontFamily={'sans-serif'}
            fontStyle={'normal'}
            fontWeight={600}
            fontSize={'24px'}
            lineHeight={'33px'}
            color={'#171717'}
          >
            {greeting}, {getData.namaLengkap}
          </Typography>
          <Box display={'flex'} alignItems={'center'} mt={'5px'}>
            <IconCalendar />
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontWeight={400}
              fontSize={'12px'}
              lineHeight={'16px'}
              color={'#989898'}
              ml={'8px'}
              mr={'22px'}
            >
              {currentDate}
            </Typography>
            <IconTime />
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontWeight={400}
              fontSize={'12px'}
              lineHeight={'16px'}
              color={'#989898'}
              ml={'8px'}
            >
              {currentTime}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontWeight={600}
              fontSize={'24px'}
              lineHeight={'33px'}
              color={'#171717'}
            >
              {navigation}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', fontWeight: 400, color: '#939393' }}
            >
              (Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.)
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} mt={'7px'}>
            {navMain.map((item, idx) => (
              <Box key={idx} display={'flex'} alignItems={'center'}>
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontSize={'12px'}
                  fontWeight={400}
                  lineHeight={'15px'}
                  letterSpacing={'0.02em'}
                  color={'#171717'}
                  mr={'7px'}
                >
                  /
                </Typography>
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontSize={'12px'}
                  fontWeight={400}
                  lineHeight={'15px'}
                  letterSpacing={'0.02em'}
                  color={
                    idx + 1 === navMain.length ? 'primary.main' : '#171717'
                  }
                  mr={'7px'}
                  sx={{ cursor: 'pointer' }}
                  onClick={
                    idx === 0
                      ? () => handleNavigateMain(item.path)
                      : () => handleNavigate(item.path)
                  }
                >
                  {item.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NavigationTopbar;
