import {
  IconAttendance,
  IconCorrection,
  IconHistory,
} from '../../../assets/timesheet';

const MenuTimesheet = [
  {
    id: 0,
    title: 'Attendance',
    judul: 'Kehadiran',
    icon: <IconAttendance />,
  },
  {
    id: 1,
    title: 'Attendance Correction',
    judul: 'Koreksi Kehadiran',
    icon: <IconCorrection />,
  },
  {
    id: 2,
    title: 'Attendance History',
    judul: 'Riwayat Kehadiran',
    icon: <IconHistory />,
  },
];

export default MenuTimesheet;
