import {
  Box,
  Card,
  Typography,
  Divider,
  Grid,
  Button,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Stack,
  MenuItem,
  Avatar,
  Menu,
  MenuList,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField, InputFile } from "../../../../components/atoms";
import { getLanguage } from "../../../../features/language";
import { useSelector } from "react-redux";
import { IconFile, IconPaper } from "../../../../assets/benefit/reimbursement";
import { IconDept, IconMoney } from "../../../../assets/recruitment/jobs";
import { IconEmployee } from "../../../../assets/coreHr/awards";
import {
  getListRequest,
  getListEmployeeType,
  postVacancy,
  uploadVacancy,
  getListVacancy,
  getVacancyById,
  getDocumentOfficeFacillitiesById,
} from "../../../../configs/axios/apiPath";

import { useEffect, useState } from "react";
import { getCompany } from "../../../../features/companies";

import { useNavigate } from "react-router-dom";

const getPicture = async (id) => {
  if (!id) return null;
  try {
    const response = await getDocumentOfficeFacillitiesById({}, id);
    return response.data;
  } catch (err) {
    return null;
  }
};
const RecruitmentJobsAdd = () => {
  const lang = useSelector(getLanguage);
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [fileBlob, setFileBlob] = useState("");
  const [idVacancy, setIdVacancy] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [formData, setFormData] = useState();
  const [vacancyData, setVacancyData] = useState([]);

  const company = useSelector(getCompany);

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        try {
          const {
            data: { data },
          } = await getListEmployeeType();
          setTypeData(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company]);
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          page_size: 9999999,
          year: new Date().getFullYear(),
          posted: 0,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListRequest(params);

          setRequestData(data?.job_requests);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company]);

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          page_size: 9999999,
          company_id: company,
          year: new Date().getFullYear(),
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListVacancy(params);
          setVacancyData(data?.job_vacancies);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company]);

  useEffect(() => {
    async function fetchData() {
      if (idVacancy) {
        try {
          const {
            data: { data },
          } = await getVacancyById(idVacancy);

          const a = {
            request_by: {
              id: data.request_id,
              position: data.position,
              department: data.department,
              request_by: data.request_by,
              status: "posted",
            },
            type: data.employment_type,
            description: data.detail,
          };
          const blob = await getPicture(data?.document?.id);
          setFileBlob([blob]);

          setFormData(a);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        }
      }
    }

    fetchData();
  }, [idVacancy, navigate]);

  const handlePost = async (id) => {
    const data = {
      company_id: company,
      job_request_id: formData.request_by.id,
      employment_type_id: formData.type.id,
      detail: formData.description,
      document_id: id,
      urgent: formData.urgent ? formData.urgent : 0,
      min_salary: formData.min_salary.replaceAll(".", "").replace("Rp ", ""),
      max_salary: formData.max_salary.replaceAll(".", "").replace("Rp ", ""),
    };

    setIsLoading(true);
    try {
      await postVacancy(data);
      navigate("/recruitment/vacancies");
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    return new Promise(async (resolve, reject) => {
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await uploadVacancy({ file: fileBlob[0] });
        handlePost(data.id);
        setIsLoading(false);
        resolve();
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    });
  };

  const rupiah = (number) => {
    const newNumber = number.replaceAll(".", "").replace("Rp", "");
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      maximumFractionDigits: 0,
    }).format(newNumber);
  };

  return (
    <Card sx={{ padding: "20px" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        marginBottom={"16px"}
        alignItems="center"
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"20px"}
          lineHeight={"27px"}
          letterSpacing={"0.01em"}
          color={"#171717"}
        >
          Add
        </Typography>
        <Button
          variant="container"
          color="primary"
          startIcon={<SearchIcon />}
          sx={{
            boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.10)",
            borderRadius: "8px",
            textTransform: "none",
          }}
          onClick={handleClick}
        >
          Find Available Jobs
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{ marginX: "6px" }}
        >
          <MenuList sx={{ marginX: "6px" }}>
            {vacancyData.map((e, index) => (
              <Box key={index}>
                <Divider />
                <MenuItem
                  onClick={() => {
                    setIdVacancy(e.id);
                    handleClose();
                  }}
                >
                  {e.position.name}
                </MenuItem>
              </Box>
            ))}
          </MenuList>
        </Menu>
      </Stack>

      <Divider />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={"16px"}>
          <Grid item xs={6}>
            <Stack>
              {!idVacancy ? (
                <Autocomplete
                  onChange={(_, value) => {
                    if (value) {
                      setFormData((prev) => ({
                        ...prev,
                        request_by: value,
                      }));
                    } else {
                      setFormData((prev) => ({
                        ...prev,
                        request_by: undefined,
                      }));
                    }
                  }}
                  options={requestData}
                  getOptionLabel={(option, index) =>
                    option.request_by.name + " - " + option.position.name
                  }
                  value={
                    requestData?.find(
                      (e) => e?.id === formData?.request_by?.id
                    ) ?? null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={lang === "Id" ? "Request" : "Request"}
                      fullWidth
                      required
                      variant="outlined"
                      size="small"
                      icon={<IconEmployee />}
                    />
                  )}
                  renderOption={(props, option, state, ownerState) => (
                    <MenuItem {...props} key={option.id}>
                      <Stack direction="row" gap="10px">
                        <Avatar
                          src={option.request_by.profile_picture}
                          marginRight="10px"
                        />
                        <Stack>
                          <Typography>
                            {ownerState.getOptionLabel(option)}
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--gray-SOLID, #939393)",
                              fontSize: "10px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                            }}
                          >
                            {option.position.name}
                          </Typography>
                        </Stack>
                      </Stack>
                    </MenuItem>
                  )}
                />
              ) : (
                <TextField
                  label={lang === "Id" ? "Request" : "Request"}
                  fullWidth
                  required
                  value={formData?.request_by?.request_by.name}
                  variant="outlined"
                  size="small"
                  icon={<IconEmployee />}
                />
              )}

              <FormControlLabel
                label={
                  <Typography
                    sx={{
                      color: "#C4C4C4",
                      fontSize: "14",
                    }}
                  >
                    Urgent
                  </Typography>
                }
                control={
                  <Checkbox
                    size="small"
                    sx={{
                      color: "#c4c4c4",
                      "&.Mui-checked": {
                        color: "#c4c4c4",
                      },
                    }}
                  />
                }
                onChange={(e) => {
                  const urgent = e.target.checked ? 1 : 0;
                  setFormData((prev) => ({ ...prev, urgent }));
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <TextField
              label={lang === "Id" ? "Dept" : "Dept"}
              fullWidth
              required
              type="text"
              size="small"
              variant="outlined"
              value={formData?.request_by?.department.name}
              icon={<IconDept />}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <TextField
              label={lang === "Id" ? "Posisi" : "Position"}
              fullWidth
              required
              type="text"
              size="small"
              variant="outlined"
              value={formData?.request_by?.position.name}
              icon={<IconDept />}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Autocomplete
              onChange={(_, value) => {
                if (value) {
                  setFormData((prev) => ({
                    ...prev,
                    type: value,
                  }));
                } else {
                  setFormData((prev) => ({
                    ...prev,
                    type: undefined,
                  }));
                }
              }}
              options={typeData}
              value={typeData?.find((e) => e.id === formData?.type?.id) ?? null}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={lang === "Id" ? "Tipe Pekerjaan" : "Type Jobs"}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  icon={<IconPaper />}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <TextField
              label={
                lang === "Id" ? "Kisaran Gaji Terendah" : "Range Salary Min"
              }
              fullWidth
              required
              type="text"
              size="small"
              variant="outlined"
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  min_salary: rupiah(e.target.value),
                }));
              }}
              value={formData?.min_salary}
              icon={<IconMoney />}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={
                lang === "Id" ? "Kisaran Gaji Tertinggi" : "Range Salary Max"
              }
              fullWidth
              required
              type="text"
              size="small"
              variant="outlined"
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  max_salary: rupiah(e.target.value),
                }));
              }}
              value={formData?.max_salary}
              icon={<IconMoney />}
            />
          </Grid>
          <Grid item xs={12}>
            <InputFile
              onChange={(data) => setFileBlob(data)}
              value={fileBlob}
              setValue={setFileBlob}
              icon={<IconFile />}
              label={lang === "Id" ? "Attachment" : "Attachment"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={lang === "Id" ? "Detail Pekerjaan" : "Jobs Detail"}
              fullWidth
              required
              type="text"
              value={formData?.description}
              size="small"
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }));
              }}
              variant="outlined"
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          sx={{ marginTop: "30px" }}
          loading={isLoading}
        >
          {lang === "Id" ? "Simpan" : "Save"}
        </LoadingButton>
      </form>
    </Card>
  );
};

export default RecruitmentJobsAdd;
