import { createSlice } from '@reduxjs/toolkit';

const idEmployeeSlice = createSlice({
  name: 'idEmployee',
  initialState: {
    id: 0,
  },
  reducers: {
    setIdEmployee: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const { setIdEmployee } = idEmployeeSlice.actions;
export const getIdEmployee = (state) => state.idEmployee.id;

export default idEmployeeSlice.reducer;
