import { API } from "../../../API";

// --- List Level ---
export const getListLevels = (params) => API.get("levels", { params: params });

// --- Get Level By ID ---
export const getLevelsById = (id) => API.get(`levels/${id}`);

// --- Update Level ---
export const updateLevelsById = (data, id) => API.put(`levels/${id}`, data);

// --- Delete Level ---
export const deleteLevelsById = (id) => API.delete(`levels/${id}`);
