import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const CoreHRAwardsIndex = () => {
    return (
        <Box mt='20px'>
            <Outlet />
        </Box>
    )
}

export default CoreHRAwardsIndex;