import {
  Box,
  InputAdornment,
  InputLabel,
  TextField as TextFieldMui,
  Typography,
} from "@mui/material";
const TextField = ({
  icon,
  label,
  error,
  errorMessage,
  InputProps,
  sx,
  ...props
}) => (
  <Box display={"flex"} flexDirection={"column"} width="full" sx={sx}>
    <InputLabel
      style={{
        fontFamily: "sans-serif",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "18px",
        letterSpacing: "1px",
        marginBottom: "6px",
      }}
    >
      {label}
    </InputLabel>
    <TextFieldMui
      {...props}
      error={error}
      InputProps={{
        ...InputProps,
        startAdornment: (
          <InputAdornment
            position="start"
            sx={
              props.multiline &&
              props.rows > 1 && { alignSelf: "start", marginTop: "10px" }
            }
          >
            {icon}
          </InputAdornment>
        ),
        style: {
          paddingLeft: "15px",
          paddingRight: "15px",
        },
      }}
      sx={{
        borderRadius: "8px",
        background: "#F7F7F7",
        borderColor: "transparent",
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      }}
    />
    {errorMessage && error && (
      <Typography
        fontFamily={"sans-serif"}
        fontStyle={"normal"}
        fontWeight={400}
        fontSize={"12px"}
        lineHeight={"15px"}
        letterSpacing={"1px"}
        color={"red"}
        my={"5px"}
      >
        {errorMessage}
      </Typography>
    )}
  </Box>
);

export default TextField;
