import {
  Button,
  Card,
  Grid,
  InputAdornment,
  TextField,
  InputLabel,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../features/language";
import {
  IconEditLocation,
  IconLocationPhone,
} from "../../../../assets/department";
import {
  getCity,
  getCountries,
  getLocationById,
  getProvinces,
  updateLocationById,
} from "../../../../configs/axios/apiPath";
import {
  SelectCity,
  SelectCountry,
  SelectDepartment,
  SelectProvince,
} from "../../../../components/molecules/select";

import { getCompany } from "../../../../features/companies";
import { getListDepartment } from "../../../../configs/axios/apiPath";
import { useLocation, useNavigate } from "react-router-dom";

const LocationEdit = () => {
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);
  const [dataCountries, setDataCountries] = useState([]);
  const [dataProvinces, setDataProvinces] = useState([]);
  const [dataCity, setDataCity] = useState([]);
  const [dataLocation, setDataLocation] = useState([]);

  const [department, setDepartment] = useState([]);
  const [idDept, setIdDept] = useState(null);
  const [inputDept, setInputDept] = useState("");

  const [locationName, setLocationName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [country, setCountry] = useState(null);
  const [inputCountry, setInputCountry] = useState("");

  const [provinces, setProvinces] = useState(null);
  const [inputProvince, setInputProvince] = useState("");

  const [city, setCity] = useState(null);
  const [inputCity, setInputCity] = useState("");

  const navigate = useNavigate();

  // -- mengambil id --
  const path = useLocation().pathname;
  const splitPath = path.split("/");
  const id = splitPath[4];

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (company || country || provinces) {
      const fetchDepartment = async () => {
        const params = {
          page_size: 99999,
          company_id: company,
        };

        try {
          const {
            data: { data },
          } = await getListDepartment(params);
          setDepartment(data?.departments);
          const idD = department.find((c) => c.name === dataLocation?.name);
          setIdDept(idD);
        } catch (error) {
          //console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          const params = {
            page_size: 99999,
            keywords: null,
          };

          try {
            const {
              data: { data },
            } = await getCountries(params);
            setDataCountries(data);
            const dCountry = data.find(
              (c) => c?.name === dataLocation.country_name
            );
            setCountry(dCountry);

            if (dataCountries.length) {
              const params = {
                page_size: 99999,
                keywords: null,
              };

              try {
                const {
                  data: { data },
                } = await getProvinces(params, dCountry.code);
                setDataProvinces(data?.page_data);
                const dProv = data.page_data.find(
                  (p) => p?.name === dataLocation.province_name
                );
                setProvinces(dProv);

                if (dataProvinces.length) {
                  const params = {
                    page_size: 99999,
                    keywords: null,
                  };

                  try {
                    const {
                      data: { data },
                    } = await getCity(params, dProv.code);
                    setDataCity(data?.page_data);
                    const dCity = data?.page_data?.find(
                      (ci) => ci?.name === dataLocation.city_name
                    );
                    setCity(dCity);
                  } catch (error) {
                    console.error(error);
                  }
                }
              } catch (error) {
                console.error(error);
              }
            }
          } catch (error) {
            // console.error(error);
          }
        }
      };

      fetchDepartment();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, dataCountries.length, dataProvinces.length, navigate]);

  useEffect(() => {
    if (country) {
      async function fetchProvince() {
        const params = {
          page_size: 99999,
          keywords: null,
        };

        try {
          const {
            data: { data },
          } = await getProvinces(params, country.code);
          setDataProvinces(data?.page_data);
        } catch (error) {
          console.error(error);
        }
      }
      fetchProvince();
    }

    if (provinces) {
      async function fetchCountry() {
        const params = {
          page_size: 99999,
          keywords: null,
        };

        try {
          const {
            data: { data },
          } = await getCity(params, provinces.code);
          setDataCity(data?.page_data);
        } catch (error) {
          console.error(error);
        }
      }
      fetchCountry();
    }
  }, [country, provinces]);

  useEffect(() => {
    /** get location by id */
    async function fetchLocationById() {
      if (id) {
        try {
          const {
            data: { data },
          } = await getLocationById(id);
          setDataLocation(data);
          setLocationName(data?.location_name);
          setPhoneNumber(data?.phone_number);
        } catch (error) {
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchLocationById();
  }, [id, navigate]);

  useEffect(() => {
    if (!locationName) {
      setLocationName(dataLocation?.location_name);
    }
    if (!idDept) {
      const idD = department.find((c) => c.name === dataLocation?.name);
      setIdDept(idD);
    }
    if (!country) {
      const co = dataCountries.find(
        (c) => c.name === dataLocation?.country_name
      );
      setCountry(co);
    }
    if (!provinces) {
      const pro = dataProvinces.find(
        (c) => c.name === dataLocation?.province_name
      );
      setProvinces(pro);
    }
    if (!city) {
      const cit = dataCity.find((c) => c.name === dataLocation?.city_name);
      setCity(cit);
    }
  }, [
    city,
    country,
    dataCity,
    dataCountries,
    dataLocation?.city_name,
    dataLocation?.country_name,
    dataLocation?.location_name,
    dataLocation?.name,
    dataLocation?.province_name,
    dataProvinces,
    department,
    idDept,
    locationName,
    provinces,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      department_id: idDept.id,
      location_name: locationName,
      phone_number: phoneNumber,
      country_id: country.code,
      province_id: provinces.code,
      city_id: city.code,
    };

    try {
      await updateLocationById(data, id);
      navigate(`/organization/location`);
    } catch (error) {
      navigate(`/organization/location`);
    }
  };

  const handleChangeCountry = (e, value) => {
    setCountry(value);
  };

  const FormLabel = ({ label }) => (
    <InputLabel
      style={{
        fontFamily: "sans-serif",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "22px",
        letterSpacing: "0.02em",
        color: "#171717",
        marginBottom: "10px",
      }}
    >
      {label}
    </InputLabel>
  );

  return (
    <Card sx={{ mt: "10px" }}>
      <Box padding={"20px"}>
        <Box borderBottom={"0.2px solid #939393"} p={"0px 0px 15px 0px"}>
          <Typography
            fontFamily={"sans-serif"}
            fontStyle={"normal"}
            fontWeight={600}
            fontSize={"20px"}
            lineHeight={"27px"}
            letterSpacing={"0.01em"}
            color={"#171717"}
          >
            {lang === "Id" ? "Edit" : "Edit"}
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" paddingTop={4}>
            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                  <FormLabel label="Location Name" />
                  <TextField
                    fullWidth
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconEditLocation />
                        </InputAdornment>
                      ),
                    }}
                    required
                    value={locationName}
                    onChange={(e) => setLocationName(e.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                  <FormLabel label="Department" />
                  <SelectDepartment
                    data={department}
                    inputChange={setInputDept}
                    inputValue={inputDept}
                    setValue={setIdDept}
                    value={idDept}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                  <FormLabel label="Telephone" />
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconLocationPhone />
                        </InputAdornment>
                      ),
                    }}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                  <FormLabel label="Country" />
                  <SelectCountry
                    data={dataCountries}
                    inputChange={setInputCountry}
                    inputValue={inputCountry}
                    setValue={handleChangeCountry}
                    value={country}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                  <FormLabel label="City" />
                  <SelectCity
                    data={dataCity}
                    setValue={setCity}
                    value={city}
                    inputChange={setInputCity}
                    inputValue={inputCity}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                  <FormLabel label="Province" />
                  <SelectProvince
                    data={dataProvinces}
                    setValue={setProvinces}
                    value={provinces}
                    inputChange={setInputProvince}
                    inputValue={inputProvince}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box mt={"20px"}>
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading}
                color="primary"
                sx={{ textTransform: "none" }}
                size="small"
              >
                {lang === "Id" ? "Simpan" : "Save"}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Card>
  );
};

export default LocationEdit;
