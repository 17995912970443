import React, { useState } from "react";
import { Card } from "../../../../components/atoms";
import MainLayouts from "./main-layout";
import AddEmployeeLayout from "./add-employee-layout";
import { Box } from "@mui/material";

export default function EmployeeManageDashboard() {
  const [currentView, setView] = useState("main");

  const onSetView = (key, callback) => {
    setView(key);
    return typeof callback === "function" ? callback() : null;
  };
  return (
    <Card>
      <Box p={"25px 22px"}>
        {currentView === "main" && <MainLayouts onChangeView={onSetView} />}
        {currentView === "add-employee" && (
          <AddEmployeeLayout onChangeView={onSetView} />
        )}
      </Box>
    </Card>
  );
}
