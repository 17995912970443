import { Box, Grid, Typography } from '@mui/material';
import { Card } from '../../../atoms';
import { formatNumber } from '../../../../utils/format';

export default function PayrollDetailCard({ item }) {
  return (
    <Grid container columns={3} spacing={2} mb={'20px'}>
      {/* --- Employee Salary Types --- */}
      <Grid item xl={1} lg={1} md={3} sm={3} xs={3}>
        <Card
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box padding={'15px 20px 22px 20px'}>
            <Typography
              fontSize={'14px'}
              fontWeight={600}
              lineHeight={'19px'}
              letterSpacing={'1px'}
              color={'#CF8812'}
              mb={'22px'}
            >
              Employee's Salary Types
            </Typography>
            {item?.employee_salary &&
              item?.employee_salary.map((detail, idx) => (
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  mb={'9px'}
                  key={idx}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={400}
                    lineHeight={'15px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                  >
                    {detail?.name}
                  </Typography>
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={400}
                    lineHeight={'15px'}
                    letterSpacing={'1px'}
                    color={
                      detail?.salary_type?.type === 'deduction'
                        ? '#FB4C4C'
                        : '#171717'
                    }
                  >
                    {formatNumber(detail?.amount)}
                  </Typography>
                </Box>
              ))}
          </Box>
          <Box
            sx={{
              background: 'rgba(207, 136, 18, 0.1)',
            }}
            padding={'16px 20px'}
            borderRadius={'0px 0px 8px 8px'}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Typography
              fontSize={'12px'}
              fontWeight={'600px'}
              lineHeight={'16px'}
              letterSpacing={'1px'}
              color={'#CF8812'}
            >
              Total Employee Salary
            </Typography>
            <Typography
              fontSize={'14px'}
              fontWeight={'700px'}
              lineHeight={'19px'}
              letterSpacing={'1px'}
              color={'#CF8812'}
            >
              {formatNumber(
                item?.employee_salary?.reduce?.((a, b) => {
                  let multiply = 1;
                  if (b.salary_type?.type === 'deduction') multiply = -1;

                  return a + b.amount * multiply;
                }, 0)
              )}
            </Typography>
          </Box>
        </Card>
      </Grid>
      {/* --- Allowance --- */}
      <Grid item xl={1} lg={1} md={3} sm={3} xs={3}>
        <Card
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box padding={'15px 20px 22px 20px'}>
            <Typography
              fontSize={'14px'}
              fontWeight={600}
              lineHeight={'19px'}
              letterSpacing={'1px'}
              color={'#51B15C'}
              mb={'22px'}
            >
              Allowance
            </Typography>
            {item?.calculations &&
              item?.calculations.map((detail, idx) => (
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  mb={'9px'}
                  key={idx}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={400}
                    lineHeight={'15px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                  >
                    {detail?.name}
                  </Typography>
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={400}
                    lineHeight={'15px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                  >
                    {formatNumber(detail?.amount)}
                  </Typography>
                </Box>
              ))}
          </Box>
          <Box
            sx={{
              background: 'rgba(81, 177, 92, 0.1)',
            }}
            padding={'16px 20px'}
            borderRadius={'0px 0px 8px 8px'}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Typography
              fontSize={'12px'}
              fontWeight={'600px'}
              lineHeight={'16px'}
              letterSpacing={'1px'}
              color={'#51B15C'}
            >
              Total Allowance
            </Typography>
            <Typography
              fontSize={'14px'}
              fontWeight={'700px'}
              lineHeight={'19px'}
              letterSpacing={'1px'}
              color={'#51B15C'}
            >
              {formatNumber(
                item?.calculations?.reduce?.((a, b) => a + b.amount, 0)
              )}
            </Typography>
          </Box>
        </Card>
      </Grid>
      {/* --- Deduction --- */}
      <Grid item xl={1} lg={1} md={3} sm={3} xs={3}>
        <Card
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box padding={'15px 20px 22px 20px'}>
            <Typography
              fontSize={'14px'}
              fontWeight={600}
              lineHeight={'19px'}
              letterSpacing={'1px'}
              color={'#FF1D1D'}
              mb={'22px'}
            >
              Deduction
            </Typography>
            {item.deductions &&
              item.deductions.map((detail, idx) => (
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  mb={'9px'}
                  key={idx}
                >
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={400}
                    lineHeight={'15px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                  >
                    {detail?.name}
                  </Typography>
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontSize={'13px'}
                    fontWeight={400}
                    lineHeight={'15px'}
                    letterSpacing={'1px'}
                    color={'#171717'}
                  >
                    {formatNumber(detail?.amount)}
                  </Typography>
                </Box>
              ))}
          </Box>
          <Box
            sx={{
              background: 'rgba(255, 29, 29, 0.1)',
            }}
            padding={'16px 20px'}
            borderRadius={'0px 0px 8px 8px'}
            display={'flex'}
            justifyContent={'space-between'}
          >
            <Typography
              fontSize={'12px'}
              fontWeight={'600px'}
              lineHeight={'16px'}
              letterSpacing={'1px'}
              color={'#FF1D1D'}
            >
              Total Deduction
            </Typography>
            <Typography
              fontSize={'14px'}
              fontWeight={'700px'}
              lineHeight={'19px'}
              letterSpacing={'1px'}
              color={'#FF1D1D'}
            >
              {formatNumber(
                item?.deductions?.reduce?.((a, b) => a + b.amount, 0)
              )}
            </Typography>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
