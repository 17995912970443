import { Box, Divider, Typography } from "@mui/material"
import ActivityLog from "../../../../molecules/log/activity"
import CardAttachmentFileOfTask from "../../../../molecules/card/project/CardAttachmentFileOfTask"
import { useEffect, useState } from "react"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useNavigate } from "react-router-dom"
import { showDetailTask } from "../../../../../configs/axios/apiPath/fetchProject"
import dayjs from "dayjs"

const SideDetailTask = ({projectId, boardId, taskId}) => {
  const navigate = useNavigate();
  
  const [isLoading, setIsLoading] = useState(false);
  const [detailTask, setDetailtask] = useState([])
  const [value, setValue] = useState(dayjs(detailTask?.due_date));

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      try {
        const {
          data: { data },
        } = await showDetailTask(projectId, boardId, taskId)
        setDetailtask(data?.task)
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  },[projectId, boardId, taskId, navigate])

  return(
    <Box 
      display={'flex'}
      flexDirection={'column'}
      sx={{
        width: 'full', 
        height: '100%',
        backgroundColor: 'white',
      }}
    >
      <Box 
        display={'flex'} 
        alignItems={'center'}
        width={'full'} 
        height={'45px'} 
        padding={'20px'}
      >
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '22px',
            color: '#171717'
          }}
        >
          History
        </Typography>
      </Box>
      <Box
        display={'flex'} 
        alignItems={'center'}
        width={'full'}
        paddingX={'20px'}
      >
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '18px',
            color: '#171717',
            marginBottom: '10px',
          }}
        >
          Attachments
        </Typography>
      </Box>
        {
          detailTask?.history?.attachments?.map((item, idx) => (
            <Box key={idx}>
              <Box
                width={'full'}
                paddingX={'20px'}
                paddingY={'10px'}
              >
                <CardAttachmentFileOfTask
                  asset={item.attachment_id}
                  fileName={item.attachment_detail.original_file_name}
                  date={item.created_at}
                />
              </Box>
              <Divider/>
            </Box>
          ))
        }
      <Box
        display={'flex'} 
        alignItems={'center'}
        width={'full'}
        paddingX={'20px'}
        paddingTop={'20px'}
      >
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '18px',
            color: '#171717',
            marginBottom: '10px',
          }}
        >
          Activities
        </Typography>
      </Box>

      {
        detailTask?.history?.activities?.map((item, idx) => (
          <Box>
            <Box
              width={'full'}
              paddingX={'20px'}
              paddingY={'10px'}
            >
              <ActivityLog
                picture={item.employee.profile_picture}
                name={item.employee.name}
                log={item.message}
                time={item.created_at}
              />
            </Box>
            <Divider/>
          </Box>
        ))
      }
      
      <Box 
        display={'flex'} 
        flexDirection={'column'}
        height={'45px'} 
        padding={'20px'}
        gap={2}
      >
        <Typography
          sx={{
            fontWeight: '500',
            fontSize: '22px',
            color: '#171717'
          }}
        >
          Due Dates
        </Typography>
        <Box width={'230px'}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              value={value}
              onChange={(newValue) => setValue(newValue)}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    </Box>
  )
}

export default SideDetailTask