import { Avatar, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useEffect, useState } from "react";
import { getImageDocument } from "../../../../configs/axios/apiPath";
import DataLogin from "../../../../data/dataLogin";
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const DiscussionLog = ({picture, name, time, chat, asset, assetDetail}) => {
  const getData = DataLogin();

  const [attachment, setAttachment] = useState('')

  useEffect(() => {
    const getAttachment = async() => {
      try {
        const response = await getImageDocument(asset, getData.token);
        const contentType = response.headers['content-type'];
        const blob = new Blob([response.data], { type: contentType });
        const objectURL = URL.createObjectURL(blob);
        setAttachment(objectURL);

      } catch (error) {
        console.error(error.response.status);      
      }
    }

    getAttachment()
  },[asset, getData.token])

  return(
    <Box
      display={'flex'}
      width={'full'}
      paddingY={'10px'}
      marginY={'10px'}
      gap={2}
      borderRadius={'10px'}
    >
      <Avatar
        alt={name}
        src={picture}
        sx={{
          width: '30px',
          height: '30px'
        }}
      />
      <Box>
        <Box display={"flex"} gap={2}>
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '14px',
              color: '#171717',
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '14px',
              color: '#606060',
            }}
          >
            {time}
          </Typography>
        </Box>
        {
          (asset && assetDetail) ? 
          (
            <Box width={'385px'} padding={'10px'} sx={{backgroundColor:'white'}}>
              {
                assetDetail.mime_type === "video/mp4" ? (
                  <video
                    width={'100%'}
                    height={'100px'}
                    controls
                    loop
                    style={{borderRadius:'10px'}}
                  >
                    <source
                      src={attachment}
                      type="video/mp4"
                    />
                  </video>
                )
                : assetDetail.mime_type === "image/png" ?
                (
                  <img
                    alt="name"
                    src={attachment}
                    width={'100%'}
                    height={'100px'}
                    loading="lazy"
                    style={{borderRadius:'10px', objectFit: 'cover'}}
                  />
                )
                :
                (
                  <Box
                    height={'50px'}
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box
                      display={'flex'}
                      gap={1.5}
                    >
                      <Box 
                        width={'40px'} 
                        height={'40px'} 
                        sx={{
                          backgroundColor: '#51B15C',
                          borderRadius: '10px' 
                        }} display={'flex'} 
                        justifyContent={'center'} 
                        alignItems={'center'}>
                        <TextSnippetOutlinedIcon sx={{color:"#FFFFFF"}}/>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: '600',
                            fontSize: '13px',
                            color: '#171717',
                          }}
                        >
                          {assetDetail.original_file_name}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: '400',
                            fontSize: '10px',
                            color: '#939393',
                          }}
                        >
                          {assetDetail.size}
                        </Typography>

                      </Box>
                    </Box>
                    <FileDownloadOutlinedIcon/>
                  </Box> 
                )
              }
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#171717',
                }}
              >
                {chat}
              </Typography>
            </Box>
          )
          :
          (
            <Box width={'385px'} padding={'10px'} sx={{backgroundColor:'white'}}>
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '14px',
                  color: '#171717',
                }}
              >
                {chat}
              </Typography>
            </Box>
          )
        }
      </Box>
    </Box>
  )
}

export default DiscussionLog