import { API } from "../../../API";

export const getListJourney = (params) =>
  API.get("employee-journey", { params: params });

export const getJourneySummary = (params) =>
  API.get("employee-journey/summary", { params: params });

export const postJourney = (data) => API.post("employee-journey", data);

export const uploadJourney = (data) =>
  API.postForm("employee-journey/upload", data);

export const getJourneyById = (id) => API.get(`employee-journey/${id}`);

export const getTrackJourneyById = (params, id) =>
  API.get(`employee-journey/employee/${id}/track`, { params });

export const updateJourneyById = (data, id) =>
  API.put(`employee-journey/${id}`, data);

export const deleteJourneyById = (id) => API.delete(`employee-journey/${id}`);
