import { Box, Divider, IconButton, TextField } from "@mui/material"
import { Search } from "../../../../atoms"
import { useEffect, useState } from "react";
import AvatarGroupStyled from "../../../../molecules/avatar/avatarGroup";
import DiscussionLog from "../../../../molecules/log/discussion";
import { BackgroundBoards } from "../../../../../assets/project/img";
import ActivityLog from "../../../../molecules/log/activity";
import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router-dom";
import { listDiscussion } from "../../../../../configs/axios/apiPath/fetchProject";

const MainDiscussion = ({dataProject}) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [startDate, setStartDate] = useState('2023-06-17');
  const [endDate, setEndDate] = useState('2023-06-17');
  const [detailDiscussion, setDetailDiscussion] = useState([]);
  const [memberProject, setMemberProject] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      try {
        const params = {
          start_date: startDate,
          end_date: endDate,
          include: "project,attachments",
          page_size: pageSize
        }
        const {
          data: {data},
        } = await listDiscussion(dataProject.id, params)
        setDetailDiscussion(data?.discussions)
        setMemberProject(data?.project?.assignee)
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false)
      }
    }
    fetchData();
  },[dataProject.id, startDate, endDate, pageSize, navigate]) 

  return(
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={{
        width: 'full', 
        height: '100%',
        backgroundColor: 'white',
      }}
    >
      <Box 
        display={'flex'} 
        justifyContent={'space-between'} 
        alignItems={'center'} 
        width={'full'} 
        height={'45px'} 
        padding={'20px'}
      >
        <Search
          placeholder="Type here to search"
          page={page}
          setPage={(e) => setPage(e)}
        />
        <AvatarGroupStyled
          width={'28px'}
          height={'28px'}
          maxShow={4}
          avatarData={memberProject}
        />
      </Box>
      <Divider/>

      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        paddingX={'20px'}
        paddingY={'10px'}
        margin={'20px'} 
        gap={1}
        sx={{
          backgroundColor: '#F7F7F7',
          borderRadius: '20px',
        }}
      >
        {/* Log and chat */}
        {
          detailDiscussion?.map((item, idx) => (
            (item.type === "activity" || item.type === "comment") ? (
              <ActivityLog
                key={idx}
                picture={item.employee.profile_picture}
                name={item.employee.name}
                log={item.message}
                time={item.created_at}
              />
            )
            :
            <DiscussionLog
              key={idx}
              picture={item.employee.profile_picture}
              name={item.employee.name}
              time={item.created_at}
              chat={item.message}
              asset={item.attachment_id}
              assetDetail={item.attachment_detail}
            />
          ))
        }

        <Box marginY={'10px'} justifyContent={'center'} width={'full'}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type Something..."
            margin="normal"
            InputProps={{
              startAdornment: (
                <IconButton edge="start" color="inherit" aria-label="microphone">
                  <MicIcon />
                </IconButton>
              ),
              endAdornment: (
                <IconButton edge="end" color="inherit" aria-label="send">
                  <SendIcon />
                </IconButton>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                background: 'white',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#51B15C',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#51B15C',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default MainDiscussion