import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { Button, IconButton, Slider, Stack, Tooltip, Typography } from '@mui/material';
import { setNavigation } from '../../../../features/navigation';
import { Outlet, useNavigate } from 'react-router-dom';
import { convertToDashCase } from '../../../../utils/format';
import { MenuProject } from '../../../../data/menu';
import AvatarGroupStyled from '../../avatar/avatarGroup';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { SelectProjectStatus } from '../../select';
import AddMember from '../../../organisms/project/addMember';
import handleProjectLabel from '../../../../utils/format/projectLabel';

const ProjectTabPanel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const statusInfo = handleProjectLabel()

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataModal, setDataModal] = useState([]);
  const handleModal = (member) => {
    setIsModalOpen(true)
    setDataModal(member)
  }

  const [value, setValue] = useState(() => {
    const parsedData = JSON.parse(localStorage.getItem('cvSayaData'));
    const menu = parsedData && parsedData.projectMenu;
    return menu ? parseInt(menu) : 0;
  });

  const [isDetail, setIsDetail] = useState(false)
  const [dataProject, setDataProject] = useState([])


  const handleChange = (event, newValue) => {
    const title = MenuProject[newValue].title.toLowerCase();
    const url = convertToDashCase(title);
    const navigation = MenuProject[newValue].title;

    setValue(newValue);
    navigate(`${url}`);
    dispatch(setNavigation(navigation));
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('cvSayaData'));
    data.projectMenu = value.toString();
    localStorage.setItem('cvSayaData', JSON.stringify(data));
    
    const detail = JSON.parse(localStorage.getItem('cvSayaProject'));
    if(detail){
      setIsDetail(detail.isProvide)
      setDataProject(detail.itemProject)
    }

  }, [value]);

  return (
    <>
    <Box>
      {/* Title & Member Project */}
      
      {
        isDetail && (
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box display={'flex'}>
              <Typography
                sx={{
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '20px',
                  marginRight: '20px',
                }}
              >
                {dataProject.title}
              </Typography>
              <SelectProjectStatus selectedStatus={dataProject.status}/>
              <Box 
                sx={{
                  height: '30px',
                  paddingX: '10px',
                  borderRadius: '10px',
                  backgroundColor: handleProjectLabel(dataProject.status).color,
                  marginLeft: '15px',
                }}
              >
                <Tooltip
                  arrow
                  PopperProps={{
                    disablePortal: true,
                  }}
                  title={
                    <Stack spacing={1} padding={2} alignItems="end">
                      <Slider
                        value={dataProject.percentage}
                        disabled={false}
                        marks
                        max={100}
                        min={0}
                        step={10}
                        size="medium"
                        valueLabelDisplay="on"
                        sx={{
                          width: 150,
                          marginTop: '10px', 
                        }}
                      />
                      <Button 
                        variant="contained" 
                        size="small"
                        sx={{
                          width: '40%',
                        }}
                      >
                        Save
                      </Button>
                    </Stack>
                  }
                >
                  <Typography color={'white'} marginTop={'3px'}>
                    Persentage: {` ${Math.round(dataProject.percentage)}%`}
                  </Typography>
                </Tooltip> 
              </Box>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <IconButton 
                sx={{marginRight: '10px', p: '2px'}}
                onClick={() => handleModal(dataProject.member)}
              >
                <AddSharpIcon sx={{
                    color: '#51B15C',
                    backgroundColor: '#51B15C80',
                    borderRadius: '50%'
                  }}
                />
              </IconButton>
              <AvatarGroupStyled
                width={24}
                height={24}
                maxShow={4}
                avatarData={dataProject.member}
              />
            </Box>
          </Box>
        )
      }

      {/* --- Tab Panel --- */}
      <Box
        sx={{
          maxWidth: {
            xl: '96vw',
            lg: '96vw',
            md: '89vw',
            xs: '75vw',
            sm: '84vw',
          },
          p: '0px',
          mb: '20px'
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="coreHR"
        >
          {MenuProject.map((item, idx) => (
            <Tab
              label={
                <Box display={'flex'} alignItems={'center'}>
                  {/* --- Title --- */}
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontWeight={600}
                    fontSize={'14px'}
                    lineHeight={'19px'}
                    textTransform={'none'}
                    mr={'110px'}
                  >
                    {item.title}
                  </Typography>
                </Box>
              }
              key={idx}
            />
          ))}
        </Tabs>
      </Box>

      {/* --- Outlet --- */}
      <Outlet />
    </Box>
    {/* Modal Handler */}
    <AddMember
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      memberProject={dataModal}
    />
    </>
  );
};

export default ProjectTabPanel;
