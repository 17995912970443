import React, { useEffect, useRef } from "react";
import {
  Autocomplete,
  Button,
  Card,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import debounce from "lodash/debounce";
import { getLanguage } from "../../../../features/language";
import { useSelector } from "react-redux";
import { IconDeptText, IconSearch } from "../../../../assets/department";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCompany } from "../../../../features/companies";
import {
  getListDepartment,
  getListLevels,
  getListPosition,
  getPositionById,
  updatePositionById,
} from "../../../../configs/axios/apiPath";
import { IconBack } from "../../../../assets/employee";

const PostionEdit = () => {
  const Navigate = useNavigate();

  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);
  const [isLoading, setIsLoading] = useState(false);

  const [deptSelected, setDeptSelected] = useState(null);
  const [deptId, setDeptId] = useState("");
  const [dataDept, setDataDept] = useState([]);
  const [dataSuperior, setDataSuperior] = useState([]);
  const [levelSelected, setLevelSelected] = useState(null);
  const [levelId, setLevelId] = useState("");
  const [dataLevel, setDataLevel] = useState([]);

  const [superiorId, setSuperiorId] = useState([]);
  const [position, setPosition] = useState("");

  const path = useLocation().pathname;
  const splitPath = path.split("/");
  const id = splitPath[5];
  const [defaultValue, setDefaultValue] = useState([]);

  const [searchDept, setSearchDept] = useState("");
  const [loadingDept, setLoadingDept] = useState(false);

  useEffect(() => {
    if (company) {
      async function getData() {
        try {
          const { data } = await getPositionById(id);
          setPosition(data?.data?.name);
          setDeptSelected(data?.data?.department?.name);
          setDeptId(data?.data?.department_id);
          setLevelSelected(data?.data?.level?.name);
          setLevelId(data?.data?.level?.id);
          setSuperiorId(data?.data?.superiors?.map((item) => item?.id));
          //console.log(data?.data?.superiors?.map((item) => item?.name));
        } catch (error) {
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            Navigate(`/users`);
          }
        }
      }

      getData();

      fectDataDept(searchDept);

      async function fectDataLevel() {
        const params = {
          company_id: company,
        };
        setIsLoading(true);
        try {
          const { data } = await getListLevels(params);

          const setLevel = data.data.levels.map((options) => ({
            id: options.id,
            label: options.name,
          }));
          setDataLevel(setLevel);
        } catch (error) {
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            Navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
      fectDataLevel();

      async function fectDataPosition() {
        if (company) {
          const params = {
            page_size: 99999,
            company_id: company,
          };
          setIsLoading(true);
          try {
            const { data } = await getListPosition(params);
            console.log(data);
            const setPosition = data.data.positions.map((options) => ({
              id: options.id,
              label: options.name,
              company_id: company,
            }));
            console.log(setPosition);
            setDataSuperior(setPosition);
          } catch (error) {
            if (error.response.status === 401) {
              localStorage.removeItem("cvSayaLogin");
              Navigate(`/users`);
            }
          } finally {
            setIsLoading(false);
          }
        }
      }
      fectDataPosition();
    }
  }, [Navigate, company, id]); //TODO: Position

  const fectDataDept = async (search) => {
    const params = {
      page_size: 10,
      company_id: company,
      keyword: search,
    };
    setLoadingDept(true);
    setIsLoading(true);
    try {
      const { data } = await getListDepartment(params);

      const setDept = data.data.departments.map((options) => ({
        id: options.id,
        label: options.name,
      }));
      setDataDept(setDept);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        Navigate(`/users`);
      }
    } finally {
      setLoadingDept(false);
      setIsLoading(false);
    }
  };

  const debounceDept = useRef(
    debounce((value) => {
      fectDataDept(value);
    }, 1000)
  ).current;

  const handelSubmit = async (e) => {
    e.preventDefault();

    if (company) {
      const superiorsID = defaultValue?.map((item) => ({
        superior_id: item?.id,
      }));

      const data = {
        company_id: company,
        name: position,
        department_id: deptId?.id,
        level_id: levelId,
        superiors: superiorsID,
      };
      console.log(data);
      try {
        const res = await updatePositionById(data, id);
        Navigate("/organization/department/position");
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          Navigate(`/users`);
        }
      }
    }
  };

  const handleChangeDept = (e, value) => {
    setDeptSelected(value);

    //const mid = JSON.stringify(value.id, null, " ");
    setDeptId(value);
  };

  const handleChangeLevel = (e, value) => {
    setLevelSelected(value);

    const mid = JSON.stringify(value.id, null, " ");
    setLevelId(mid);
  };

  const handleChangeSuperior = (e, value) => {
    setDefaultValue(value);
  };
  useEffect(() => {
    const tempData = dataSuperior?.filter((item) =>
      superiorId?.includes(item?.id)
    );
    setDefaultValue(tempData);
  }, [superiorId, dataSuperior]);

  const handleBack = () => {
    Navigate(`/organization/department/position`);
  };

  return (
    <Card sx={{ marginTop: "20px" }}>
      <Box padding={"20px"}>
        <form onSubmit={handelSubmit}>
          <Box>
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontWeight={600}
              fontSize={"20px"}
              lineHeight={"27px"}
              letterSpacing={"0.01em"}
              color={"#171717"}
            >
              <IconButton size="large" onClick={() => handleBack()}>
                <IconBack />
              </IconButton>
              {lang === "Id" ? "Edit Posisi" : "Edit Position"}
            </Typography>
          </Box>
          <hr color={"#F1F1F1"} width="100%" />
          {/* -- autocomplete Position -- */}
          <Box padding={"10px"}>
            <Typography
              fontFamily={"Open Sans"}
              fontStyle={"normal"}
              fontWeight={600}
              fontSize={"13px"}
              lineHeight={"17.7px"}
              letterSpacing={"1px"}
              color={"#171717"}
              mb={"2px"}
            >
              {lang === "Id" ? "Posisi" : "Position"}
            </Typography>
            <TextField
              value={position}
              size="small"
              sx={{
                width: "500px",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconDeptText />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setPosition(e.target.value)}
              required
            />
          </Box>

          {/* -- autocomplete Dept -- */}
          <Box padding={"10px"}>
            <Typography
              fontFamily={"Open Sans"}
              fontStyle={"normal"}
              fontWeight={600}
              fontSize={"13px"}
              lineHeight={"17.7px"}
              letterSpacing={"1px"}
              color={"#171717"}
              mb={"2px"}
            >
              {lang === "Id" ? "Dept" : "Department"}
            </Typography>
            <Autocomplete
              loading={loadingDept}
              loadingText={
                <div style={{ textAlign: "center" }}>loading ...</div>
              }
              id="auto-dept"
              options={dataDept}
              onChange={handleChangeDept}
              value={deptSelected}
              onInputChange={(e, value) => debounceDept(value)}
              sx={{
                width: 300,
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    width: "500px",
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconSearch />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              )}
            />
          </Box>

          {/* -- autocomplete Superior -- */}
          <Box padding={"10px"}>
            <Typography
              fontFamily={"Open Sans"}
              fontStyle={"normal"}
              fontWeight={600}
              fontSize={"13px"}
              lineHeight={"17.7px"}
              letterSpacing={"1px"}
              color={"#171717"}
              mb={"2px"}
            >
              {"Superior"}
            </Typography>
            <Autocomplete
              value={defaultValue}
              multiple
              id="tags-outlined"
              options={dataSuperior}
              getOptionLabel={(man) => man.label}
              onChange={handleChangeSuperior}
              defaultValue={defaultValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    width: "500px",
                  }}
                />
              )}
            />
          </Box>

          {/* -- autocomplete Level -- */}
          <Box padding={"10px"}>
            <Typography
              fontFamily={"Open Sans"}
              fontStyle={"normal"}
              fontWeight={600}
              fontSize={"13px"}
              lineHeight={"17.7px"}
              letterSpacing={"1px"}
              color={"#171717"}
              mb={"2px"}
            >
              {lang === "Id" ? "Level" : "Level"}
            </Typography>
            <Autocomplete
              id="auto-dept"
              options={dataLevel}
              onChange={handleChangeLevel}
              value={levelSelected}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  sx={{
                    width: "500px",
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconSearch />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
              )}
            />
          </Box>

          {/* -- Button Save -- */}
          <Box padding={"10px"}>
            <Button
              variant="contained"
              color="primary"
              sx={{ textTransform: "none" }}
              type="submit"
            >
              {lang === "Id" ? "Simpan" : "Save"}
            </Button>
          </Box>
        </form>
      </Box>
    </Card>
  );
};

export default PostionEdit;
