import { IconHRCalendar, IconCalendarHistory } from '../../../assets/hrCalendar';

const MenuHRCalendar = [
  {
    id: 0,
    title: 'HR Calendar',
    judul: 'Kalender HR',
    icon: <IconHRCalendar />,
  },
  {
    id: 1,
    title: 'History Calender',
    judul: 'Riwayat Kalender',
    icon: <IconCalendarHistory />,
  },
];

export default MenuHRCalendar;
