import { SettingsRemoteSharp } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconPlus } from "../../../../assets/commons";
import {
  Card,
  Pagination,
  RowPerPage,
  Search,
} from "../../../../components/atoms";
import TableComponent from "../../../../components/molecules/table/mainTable";
import {
  ResignTbody,
  ResignThead,
} from "../../../../components/molecules/table/tableCoreHR/resign";
import SelectYear from "../../../../components/molecules/year";
import { getListResign } from "../../../../configs/axios/apiPath";
import { dataResignFooter } from "../../../../data/footer";
import { DataTableResign } from "../../../../data/table/coreHR";
import { getCompany } from "../../../../features/companies";
import { getLanguage } from "../../../../features/language";
import { ChartAttendance } from "../../../../components/organisms/timesheet";

const CoreHRResignDashboard = () => {
  const yearNow = moment().format("YYYY");
  const year = [];
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [selectedYear, setSelectedYear] = useState(yearNow);
  const [isLoading, setIsLoading] = useState(false);
  const [resign, setResign] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [order, setOrder] = useState("resignation_date");
  const [direction, setDirection] = useState("desc");
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);

  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }

  // --- handle navigation ---
  const handleNavigationClick = (url) => {
    navigate("/core-hr/resign/add");
  };

  // --- show table ---
  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          company_id: company,
          year: selectedYear,
          order_by: order,
          direction: direction,
          keyword,
          page,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListResign(params);
          setLastPage(data?.page_info?.last_page);
          setResign(data?.resignation);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [
    page,
    keyword,
    company,
    pageSize,
    selectedYear,
    order,
    direction,
    keyword,
  ]);

  return (
    <Card sx={{ marginTop: "20px" }}>
      <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
        <Box my={"30px"}>
          <ChartAttendance />
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          {/* --- Search --- */}
          <Search
            placeholder={
              lang === "Id"
                ? "Ketik disini untuk mencari"
                : "Type here to search"
            }
            setSearch={(e) => setKeyword(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />
          <Box display={"flex"} alignItems={"center"}>
            {/* --- Selected Year --- */}
            <SelectYear
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              year={year}
            />

            {/* --- Add --- */}
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              onClick={() => handleNavigationClick("add")}
              sx={{
                borderRadius: "4px",
                marginLeft: "10px",
              }}
            >
              <Box display={"flex"} alignItems={"center"}>
                <IconPlus />
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"14px"}
                  color="primary.contrastText"
                  letterSpacing={"0.02em"}
                  marginLeft={"10px"}
                  textTransform={"none"}
                >
                  {lang === "Id" ? "Akhiri" : "Terminate"}
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>

        {/* --- Table --- */}
        <Box sx={{ mt: "24px", mb: "16px" }}>
          <TableComponent
            isLoading={isLoading}
            tbody={resign}
            TableHead={
              <ResignThead
                thead={DataTableResign}
                setOrder={setOrder}
                setDirection={setDirection}
                direction={direction}
              />
            }
            TableBody={<ResignTbody tbody={resign} setData={setResign} />}
            col={6}
          />
        </Box>

        {/* --- Footers --- */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {/* --- Icon Footers --- */}
          <Box display={"flex"} alignItems={"center"}>
            {dataResignFooter.map((item, idx) => (
              <Box display={"flex"} alignItems={"center"} key={idx} mr={"30px"}>
                <IconButton sx={{ p: "0px", mr: "10px" }}>
                  {item.icon}
                </IconButton>
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"130%"}
                  color="#171717"
                >
                  {lang === "Id" ? item.judul : item.title}
                </Typography>
              </Box>
            ))}
          </Box>
          {/* --- Pagination --- */}
          <Box display={"flex"} justifyContent={"end"}>
            <RowPerPage
              setPageSize={setPageSize}
              pageSize={pageSize}
              setPage={setPage}
            />
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default CoreHRResignDashboard;
