import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getCompany } from '../../../../../../features/companies';
import {
  addShiftOneTime,
  deleteShiftOneTime,
  getEmployeesShift,
} from '../../../../../../configs/axios/apiPath';
import {
  IconCalendar,
  IconEdit,
  IconArrowDown,
} from '../../../../../../assets/commons';
import { IconExport } from '../../../../../../assets/employee';
import { Buttons } from '../../../../../../components/atoms';
import TableComponent from '../../../../../../components/molecules/table/mainTable';
import {
  EmployeeShiftTbody,
  EmployeeShiftThead,
} from '../../../../../../components/molecules/table/tableEmployee';
import { dataTableEmployeeShift } from '../../../../../../data/table/employee';
import AddShiftOneTime from '../../../../employeeManage/manageDetailEmployee/shift/addOneTime';
import { EmployeeOneTimeShiftTbody } from '../../../../../../components/molecules/table/tableEmployee/employeeShift/shiftTbody';
import dayjs from 'dayjs';

const EmployeeShift = () => {
  const [recurring, setRecurring] = useState(true);
  const [flag, setFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shift, setShift] = useState([]);
  const company = useSelector(getCompany);
  const navigate = useNavigate();
  const yearNow = moment().format('YYYY');
  const year = [];
  const [selectedYear, setSelectedYear] = useState(yearNow);
  const [isModal, setIsModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState('');
  const [dataOneTime, setDataOneTime] = useState([{ values: [], shift: null }]);

  const path = useLocation();
  const pathname = path.pathname;
  const paths = pathname.split('/');
  const id = paths[4];

  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const options = { day: 'numeric', month: 'short' };
    return date.toLocaleDateString('id-ID', options);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const paramsRecurring = {
          type: recurring ? 'recurring' : 'onetime',
        };
        const paramsOnetime = {
          type: recurring ? 'recurring' : 'onetime',
          year: selectedYear,
        };
        setIsLoading(true);
        const params = recurring ? paramsRecurring : paramsOnetime;
        try {
          const {
            data: { data },
          } = await getEmployeesShift(id, params);
          const formattedData = recurring
            ? data
            : data.map((shift) => {
                const profile = formatDate(shift.profile);
                return {
                  ...shift,
                  profile,
                };
              });
          setShift(formattedData);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, recurring, navigate, id, selectedYear, flag]);

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleEdit = () => {
    navigate(`/employee/employee/detail/${id}/shift/edit-shift/${id}`);
  };

  const handleAddOneTime = () => {
    setIsModal(true);
    setModal('addOneTime');
  };

  const handleSubmitOneTime = async (data) => {
    const tempData = data.map((item) => {
      return {
        shift_id: item.shift.id,
        values: item.values.map((value) => dayjs(value).format('YYYY-MM-DD')),
      };
    });

    const params = {
      employee_id: Number(id),
      multiple: true,
      shifts: tempData,
    };

    try {
      const response = await addShiftOneTime(params);
      if (response.data.meta.code === 20000) {
        setIsModal(false);
        setFlag((prevState) => !prevState);
        setDataOneTime([{ values: [], shift: null }]);
      }
    } catch (error) {
      console.error(error.response.status);
      if (error.response.status === 401) {
        localStorage.removeItem('cvSayaLogin');
        navigate(`/users`);
      }
    }
  };

  const handleDelete = async (id, event) => {
    event.currentTarget.disabled = true;
    if (id) {
      try {
        await deleteShiftOneTime(id);
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
      } finally {
        setFlag((prevState) => !prevState);
      }
    }
  };

  return (
    <Box mt={'30px'}>
      {/* --- Navbar --- */}
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        {/* --- Recurring --- */}
        <Box display={'flex'}>
          {/* --- Set Recurring --- */}
          <Box
            sx={{
              border: recurring ? '1px solid #51B15C' : '1px solid #939393',
              background: recurring ? '#51B15C' : '#fff',
              width: '126px',
              py: '12px',
              borderRadius: '20px 0px 0px 20px',
              cursor: 'pointer',
            }}
            onClick={() => setRecurring(true)}
          >
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontWeight={600}
              fontSize={'12px'}
              lineHeight={'16px'}
              color={recurring ? '#fff' : '#939393'}
              textAlign={'center'}
            >
              Recurring
            </Typography>
          </Box>
          {/* --- Set One Time --- */}
          <Box
            sx={{
              background: !recurring ? '#51B15C' : '#fff',
              border: !recurring ? '1px solid #51B15C' : '1px solid #939393',
              width: '126px',
              py: '12px',
              borderRadius: '0px 20px 20px 0px',
              cursor: 'pointer',
            }}
            onClick={() => setRecurring(false)}
          >
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontWeight={600}
              fontSize={'12px'}
              lineHeight={'16px'}
              color={!recurring ? '#fff' : '#939393'}
              textAlign={'center'}
            >
              One Time
            </Typography>
          </Box>
        </Box>

        {/* --- Export --- */}
        <Box display={'flex'} alignItems={'center'} borderRadius={'6px'}>
          {recurring ? (
            // --- Recurring Right ---
            <Box>
              <IconButton sx={{ p: '0px', mr: '10px' }} onClick={handleEdit}>
                <IconEdit />
              </IconButton>
            </Box>
          ) : (
            // --- One Time Right ---
            <Box display={'flex'} alignItems={'center'}>
              <Select
                value={selectedYear}
                onChange={handleChange}
                input={
                  <OutlinedInput
                    startAdornment={
                      <InputAdornment position="start">
                        <IconCalendar />
                      </InputAdornment>
                    }
                  />
                }
                sx={{
                  background: '#f9f9f9',
                  height: '30px',
                  '.MuiSelect-root': {
                    border: '1px solid red',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'transparent',
                  },
                }}
              >
                {year &&
                  year.map((item, idx) => (
                    <MenuItem value={item} key={idx}>
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        {item}
                      </Box>
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          )}
          {/* export hidden */}
          {/* <Box
            width={'92px'}
            boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
            ml={'22px'}
            py={'10px'}
            px={'15px'}
          >
            <Box display={'flex'} alignItems={'center'}>
              <IconButton sx={{ p: '0px' }}>
                <IconExport />
              </IconButton>
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={600}
                fontSize={'12px'}
                lineHeight={'14px'}
                color={'#171717'}
                mx={'9px'}
              >
                Export
              </Typography>
              <IconButton sx={{ p: '0px' }}>
                <IconArrowDown />
              </IconButton>
            </Box>
          </Box> */}
          {/* --- Add Schedule --- */}
          {!recurring && (
            <Buttons
              type="submit"
              variant="contained"
              sx={{
                borderRadius: '4px',
                marginLeft: '20px',
              }}
            >
              <Box
                onClick={handleAddOneTime}
                display={'flex'}
                alignItems={'center'}
              >
                <IconCalendar />
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={600}
                  fontSize={'12px'}
                  lineHeight={'14px'}
                  color="primary.contrastText"
                  letterSpacing={'0.02em'}
                  marginLeft={'10px'}
                  p={'3px'}
                  textTransform={'none'}
                >
                  Add Shift
                </Typography>
              </Box>
            </Buttons>
          )}
        </Box>
      </Box>

      {/* --- Content --- */}
      <Box mt={'16px'}>
        {isLoading ? (
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            my={'30px'}
          >
            <CircularProgress />
          </Box>
        ) : recurring ? (
          shift.length > 0 ? (
            <TableComponent
              isLoading={isLoading}
              tbody={shift}
              TableHead={<EmployeeShiftThead thead={dataTableEmployeeShift} />}
              TableBody={
                <EmployeeShiftTbody tbody={shift} setData={setShift} />
              }
              col={3}
            />
          ) : (
            'No'
          )
        ) : (
          <TableComponent
            isLoading={isLoading}
            tbody={shift}
            TableHead={<EmployeeShiftThead thead={dataTableEmployeeShift} />}
            TableBody={
              <EmployeeOneTimeShiftTbody
                tbody={shift}
                handleDelete={handleDelete}
              />
            }
            col={3}
          />
        )}
      </Box>
      {modal === 'addOneTime' ? (
        <AddShiftOneTime
          isOpen={isModal}
          onClose={() => setIsModal(false)}
          handleSubmitOneTime={handleSubmitOneTime}
          data={dataOneTime}
          setData={setDataOneTime}
        />
      ) : (
        <AddShiftOneTime
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          data={dataOneTime}
          setData={setDataOneTime}
          //   title={'Shift'}
          //   path={'shift'}
        />
      )}
    </Box>
  );
};

export default EmployeeShift;
