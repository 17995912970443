import React from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { Card, Modals } from '../../../../components/atoms';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../../../features/language';
import { IconClose } from '../../../../assets/commons';
import { Document, PDFDownloadLink, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    table: { 
      display: "table", 
      width: "auto", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderRightWidth: 0, 
      borderBottomWidth: 0 
    }, 
    tableRow: { 
      margin: "auto", 
      flexDirection: "row" 
    }, 
    tableCol: { 
      width: "20%", 
      borderStyle: "solid", 
      borderWidth: 1, 
      borderLeftWidth: 0, 
      borderTopWidth: 0 
    }, 
    tableCell: { 
      margin: "auto", 
      marginTop: 5, 
      fontSize: 10 
    },
    mb20: { 
        marginBottom: '20px',
      }
  });

const ExportList = ({ isOpen, onClose, data, isLoading, company, department }) => {
  const lang = useSelector(getLanguage)

  const ExportData = () => {
    return (
        <Document>
            <Page size={'A4'} style={styles.body}>
            <View>
                <Text style={styles.mb20 }>Data Employee {company} - {data[0]?.dept}</Text> 
            </View>
            <View style={styles.table}> 
                <View style={styles.tableRow}> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>No</Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Name</Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Position</Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Services</Text> 
                </View> 
                <View style={styles.tableCol}> 
                    <Text style={styles.tableCell}>Status</Text> 
                </View> 
                </View>
                {data?.map((data, idx) => (
                    <View id={idx} style={styles.tableRow}> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{idx + 1}</Text> 
                    </View> 
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}> {data?.name} </Text> 
                    </View> 
                    <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{data?.position}</Text> 
                    </View>
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{data?.service}</Text> 
                    </View>
                    <View style={styles.tableCol}> 
                        <Text style={styles.tableCell}>{data?.status}</Text> 
                    </View> 
                    </View> 
                ))}
                
            </View>
            </Page>
        </Document>
    );
  }

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "559px",
          transform: "translate(-50%, -50%)",
          maxHeight: "722px",
          overflowY: "auto",
          bgcolor: "background.paper",
          borderRadius: "16px",
          gap: "10px",
        }}
      >
        <Card>
          <Box p={"30px"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: "7px",
                position: "sticky",
                top: 0,
                bgcolor: "background.paper",
                p: "10px 0px 10px 0px",
                zIndex: 999,
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"27px"}
                color={"#171717"}
              >
                {lang === "Id" ? "Export PDF" : "Export PDF"}
              </Typography>
              <IconButton
                sx={{ p: "0px", width: "30px", height: "30px" }}
                onClick={onClose}
              >
                <IconClose />
              </IconButton>
            </Box>

           { !isLoading ? (
            <>
                <PDFDownloadLink document={<ExportData />} fileName={`${company}-${department}.pdf`} >
                <Typography
                    fontFamily={"sans-serif"}
                    fontSize={"14px"}
                    fontWeight={600}
                    lineHeight={"17px"}
                    color={"#171717"}
                    sx={{
                        textAlign: 'center',
                        mt: '20px',
                    }}
                >
                    Download PDF
                </Typography>
                </PDFDownloadLink>
            </>
           ) : (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress color="secondary" />
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </Modals>
  );
};

export default ExportList;
