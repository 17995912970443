const DataTableCandidate = [
  {
    title: "Candidate Name",
    judul: "Nama Kandidat",
  },
  {
    title: "Stages",
    judul: "Tahapan",
    url: "stages",
  },
  {
    title: "Aplication Date",
    judul: "Tanggal melamar",
  },
  {
    title: "Attachment",
    judul: "Lampiran",
  },
  {
    title: "Status",
    judul: "Status",
    url: "status",
  },
  {
    title: "Action",
    judul: "Aksi",
  },
];

export default DataTableCandidate;
