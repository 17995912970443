import { Box, Divider, List } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { IconSetting } from '../../../../assets/sideNav/icons';
import { MenuSideNavbar } from '../../../../data/menu';
import ListMenu from '../listContent';
import { useSelector } from 'react-redux';
import { getLanguage } from '../../../../features/language';
import { getStaticMenus } from '../../../../features/navigation';
import ListStaticMenu from '../listContent/listStaticMenu';

const MainContent = ({ open }) => {
  const lang = useSelector(getLanguage);

  const staticMenu = useSelector(getStaticMenus);

  // --- Get Pathname ---
  const { pathname } = useLocation();
  const currentPath = pathname === '/' ? 'root' : pathname.split('/')[1];

  //   --- Icon Theme ---
  const theme = useTheme();
  const color = {
    active: theme.palette.primary.main,
    unactive: '#C4C4C4',
  };

  return (
    <List>
      {MenuSideNavbar?.filter((item) => staticMenu.includes(item?.title)).map(
        (item, idx) => (
          // --- Manu Utama ---
          <ListStaticMenu
            open={open}
            Symbol={item.icon}
            idx={idx}
            name={lang === 'Id' ? item.judul : item.title}
            to={item.path}
            active={
              item.path.slice(1) === currentPath ||
              (item.path.slice(1) === 'dashboard' && currentPath === 'root')
            }
            key={idx}
          >
            {React.cloneElement(item.icon, {
              style: {
                fill:
                  item.path.slice(1) === currentPath ||
                  (item.path.slice(1) === 'dashboard' && currentPath === 'root')
                    ? color.active
                    : color.unactive,
              },
            })}
          </ListStaticMenu>
        )
      )}
      {MenuSideNavbar?.filter((item) => !staticMenu.includes(item.title)).map(
        (item, idx) => (
          // --- Manu Utama ---
          <ListMenu
            open={open}
            Symbol={item.icon}
            name={lang === 'Id' ? item.judul : item.title}
            to={item.path}
            active={
              item.path.slice(1) === currentPath ||
              (item.path.slice(1) === 'dashboard' && currentPath === 'root')
            }
            key={idx}
          >
            {React.cloneElement(item.icon, {
              style: {
                fill:
                  item.path.slice(1) === currentPath ||
                  (item.path.slice(1) === 'dashboard' && currentPath === 'root')
                    ? color.active
                    : color.unactive,
              },
            })}
          </ListMenu>
        )
      )}

      {/* --- Menu Setting --- */}
      <Box mt={'100px'}>
        <ListMenu
          open={open}
          Symbol={<IconSetting />}
          name="Setting"
          to="setting"
          active={currentPath === 'setting'}
        >
          <IconSetting
            style={{
              fill: currentPath === 'setting' ? color.active : color.unactive,
            }}
          />
        </ListMenu>
      </Box>
    </List>
  );
};
export default MainContent;
