import { Box } from '@mui/material';
import MainRouting from '../../routes/mainRouting';
import SidebarHomepage from './sideNav';
import TopbarHomepage from './topNav';
import { useState } from 'react';

const MainLayout = () => {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ display: 'flex' }}>
      <SidebarHomepage {...{ open, setOpen }} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          position: 'relative',
          maxWidth: open ? 'calc(100% - 240px)' : '100%',
        }}
      >
        <TopbarHomepage />
        <Box sx={{ p: 2, bgcolor: '#F9F9F9', minHeight: '100vh' }}>
          <Box sx={{ maxWidth: '100%', mx: 'auto' }}>
            <MainRouting />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
