import { Box, Divider, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getEmployeesById } from "../.././../../../configs/axios/apiPath";

const EmployeeJob = () => {
  const path = useLocation();
  const pathname = path.pathname;
  const paths = pathname.split("/");
  const id = paths[4];

  const [dataEmployee, setDataEmployee] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        include: "jobs",
      };
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await getEmployeesById(id, params);

        setDataEmployee(data?.jobs);
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id, navigate]);

  return (
    <Box mt={"30px"}>
      {/* ---Position --- */}
      <Box>
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"18px"}
          lineHeight={"25px"}
          color={"#51B15C"}
          mb={"10px"}
        >
          Position
        </Typography>
        <Box>
          {dataEmployee?.length !== 0 ? (
            <Box>
              {/* --- Job Position --- */}
              <Box mt={"16px"}>
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#939393"}
                  mb={"4px"}
                >
                  Job Position
                </Typography>

                {isLoading ? (
                  <Skeleton variant="text" height={30} width={"20vw"} />
                ) : (
                  <Box>
                    {dataEmployee?.salary?.amount === null ? (
                      <Typography
                        fontFamily={"sans-serif"}
                        fontStyle={"normal"}
                        fontWeight={600}
                        fontSize={"12px"}
                        lineHeight={"16px"}
                        letterSpacing={"1px"}
                        color={"#939393"}
                        mb={"4px"}
                      >
                        The data is still Empty
                      </Typography>
                    ) : (
                      <Typography
                        fontFamily={"sans-serif"}
                        fontStyle={"normal"}
                        fontWeight={600}
                        fontSize={"15px"}
                        lineHeight={"20px"}
                        letterSpacing={"1px"}
                        color={"#171717"}
                      >
                        {dataEmployee?.position?.name}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
              <Box mt={"16px"}>
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#939393"}
                  mb={"4px"}
                >
                  The reason why choose this position
                </Typography>
                {isLoading ? (
                  <Skeleton variant="text" height={30} width={"20vw"} />
                ) : (
                  <Box>
                    {dataEmployee?.salary?.amount === null ? (
                      <Typography
                        fontFamily={"sans-serif"}
                        fontStyle={"normal"}
                        fontWeight={600}
                        fontSize={"12px"}
                        lineHeight={"16px"}
                        letterSpacing={"1px"}
                        color={"#939393"}
                        mb={"4px"}
                      >
                        The data is still Empty
                      </Typography>
                    ) : (
                      <Typography
                        fontFamily={"sans-serif"}
                        fontStyle={"normal"}
                        fontWeight={600}
                        fontSize={"15px"}
                        lineHeight={"20px"}
                        letterSpacing={"1px"}
                        color={"#171717"}
                      >
                        {dataEmployee?.position?.reas}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontWeight={600}
              fontSize={"12px"}
              lineHeight={"16px"}
              color={"#939393"}
              mb={"4px"}
            >
              The data is still Empty
            </Typography>
          )}
        </Box>
      </Box>

      <Divider
        orientation="horizontal"
        flexItem
        sx={{ borderColor: "#c4c4c4", my: "20px" }}
      />

      {/* --- Salary --- */}
      <Box>
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"18px"}
          lineHeight={"25px"}
          color={"#51B15C"}
          mb={"10px"}
        >
          Salary
        </Typography>
        <Box>
          {dataEmployee?.length !== 0 ? (
            <Box>
              {/* --- Job Position --- */}
              <Box mt={"16px"}>
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#939393"}
                  mb={"4px"}
                >
                  Expected Salary
                </Typography>

                {isLoading ? (
                  <Skeleton variant="text" height={30} width={"20vw"} />
                ) : (
                  <Box>
                    {dataEmployee?.salary?.amount === null ? (
                      <Typography
                        fontFamily={"sans-serif"}
                        fontStyle={"normal"}
                        fontWeight={600}
                        fontSize={"12px"}
                        lineHeight={"16px"}
                        letterSpacing={"1px"}
                        color={"#939393"}
                        mb={"4px"}
                      >
                        The data is still Empty
                      </Typography>
                    ) : (
                      <Typography
                        fontFamily={"sans-serif"}
                        fontStyle={"normal"}
                        fontWeight={600}
                        fontSize={"15px"}
                        lineHeight={"20px"}
                        letterSpacing={"1px"}
                        color={"#171717"}
                      >
                        IDR FormatNumber(dataEmployee?.salary?.amount)
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
              <Box mt={"16px"}>
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"16px"}
                  letterSpacing={"1px"}
                  color={"#939393"}
                  mb={"4px"}
                >
                  The reason of the expected salary
                </Typography>
                {isLoading ? (
                  <Skeleton variant="text" height={30} width={"20vw"} />
                ) : (
                  <Box>
                    {dataEmployee?.salary?.amount === null ? (
                      <Typography
                        fontFamily={"sans-serif"}
                        fontStyle={"normal"}
                        fontWeight={600}
                        fontSize={"12px"}
                        lineHeight={"16px"}
                        letterSpacing={"1px"}
                        color={"#939393"}
                        mb={"4px"}
                      >
                        The data is still Empty
                      </Typography>
                    ) : (
                      <Typography
                        fontFamily={"sans-serif"}
                        fontStyle={"normal"}
                        fontWeight={600}
                        fontSize={"15px"}
                        lineHeight={"20px"}
                        letterSpacing={"1px"}
                        color={"#171717"}
                      >
                        IDR FormatNumber(dataEmployee?.salary?.reason)
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontWeight={600}
              fontSize={"12px"}
              lineHeight={"16px"}
              color={"#939393"}
              mb={"4px"}
            >
              The data is still Empty
            </Typography>
          )}
        </Box>
      </Box>

      <Divider
        orientation="horizontal"
        flexItem
        sx={{ borderColor: "#c4c4c4", my: "20px" }}
      />
    </Box>
  );
};

export default EmployeeJob;
