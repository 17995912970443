import { useEffect, useState } from 'react';
import {
  Card,
  Typography,
  Divider,
  Grid,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from '../../../../components/atoms';
import { getLanguage } from '../../../../features/language';
import { useSelector } from 'react-redux';
import { IconName } from '../../../../assets/recruitment/jobs';
import { IconEmployee } from '../../../../assets/coreHr/awards';
import {
  getListDepartments,
  getListPosition,
  postRequest,
} from '../../../../configs/axios/apiPath';
import { getCompany } from '../../../../features/companies';
import { useNavigate } from 'react-router-dom';

const RecruitmentJobsAdd = () => {
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const [deptData, setDeptData] = useState([]);
  const [positionData, setPositionData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (company && formData?.department_id) {
        const params = {
          company_id: company,
          departments: [formData?.department_id],
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListPosition(params);
          setPositionData(data?.positions);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, formData?.department_id]);
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          page_size: 9999999,
          company_id: company,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListDepartments(params);
          setDeptData(data?.departments);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company]);

  const navigate = useNavigate();

  const handleInputChange = (_, value) => {
    setFormData({ ...formData, inputValue: value });
  };

  // --- Handle Selected Employee ---
  const handleInputChange2 = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSubmit = async () => {
    const data = {
      company_id: company,
      position_id: formData?.position_id,
      description: formData?.description,
    };

    setIsLoading(true);
    try {
      await postRequest(data);
      navigate('/recruitment/request');
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        localStorage.removeItem('cvSayaLogin');
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Card sx={{ padding: '20px' }}>
      <Typography
        fontFamily={'sans-serif'}
        fontStyle={'normal'}
        fontWeight={600}
        fontSize={'20px'}
        lineHeight={'27px'}
        letterSpacing={'0.01em'}
        color={'#171717'}
        marginBottom="15px"
      >
        Add
      </Typography>
      <Divider />
      <form>
        <Grid container spacing={'16px'}>
          <Grid item xs={6}>
            <Autocomplete
              onChange={(_, value) => {
                if (value) {
                  setFormData((prev) => ({
                    ...prev,
                    department_id: value.id,
                    position_id: undefined,
                  }));
                } else {
                  setFormData((prev) => ({
                    ...prev,
                    department_id: undefined,
                    position_id: undefined,
                  }));
                }
              }}
              onInputChange={handleInputChange}
              options={deptData}
              getOptionLabel={(employee) => employee.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={lang === 'Id' ? 'Dept' : 'Dept'}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  icon={<IconEmployee />}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Autocomplete
              onChange={(_, value) => {
                if (value) {
                  setFormData((prev) => ({ ...prev, position_id: value.id }));
                } else {
                  setFormData((prev) => ({ ...prev, position_id: undefined }));
                }
              }}
              value={positionData?.find(
                (data) => data.id === formData.position_id
              )}
              onInputChange={handleInputChange}
              options={positionData}
              getOptionLabel={(employee) => employee.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={lang === 'Id' ? 'Posisi' : 'Position'}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  icon={<IconName />}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} />

          <Grid item xs={12}>
            <TextField
              label={lang === 'Id' ? 'Deskripsi' : 'Description'}
              fullWidth
              required
              type="text"
              onChange={handleInputChange2}
              size="small"
              variant="outlined"
              multiline
              name="description"
              rows={4}
            />
          </Grid>
        </Grid>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={
            !formData?.department_id ||
            !formData?.position_id ||
            !formData?.description
          }
          sx={{ marginTop: '30px' }}
          onClick={handleSubmit}
          loading={isLoading}
        >
          {lang === 'Id' ? 'Simpan' : 'Save'}
        </LoadingButton>
      </form>
    </Card>
  );
};

export default RecruitmentJobsAdd;
