import { Box, Typography } from '@mui/material';

export default function NoteHandle({ data }) {
  return (
    <Box
      variant="body2"
      color="textSecondary"
      width={'218px'}
      sx={{
        textAlign: 'left',
        border: '0.2px solid #C4C4C4',
        p: '10px 20px',
      }}
    >
      <Typography
        mb={'10px'}
        fontWeight={600}
        fontSize={'14px'}
        lineHeight={'16px'}
        fontFamily={'sans-serif'}
        fontStyle={'normal'}
        color={'#171717'}
      >
        Note
      </Typography>
      {data.map((item, idx) => (
        <Typography
          mb={'6px'}
          fontWeight={400}
          fontFamily={'sans-serif'}
          fontStyle={'normal'}
          fontSize={'10px'}
          lineHeight={'11px'}
          letterSpacing={'0.01em'}
          color={'#171717'}
          key={idx}
        >
          {item.name} ( {item.clock_in} - {item.clock_out})
        </Typography>
      ))}
    </Box>
  );
}
