import { LineChart } from "../../../../atoms";
// import Data from "./dataMain";
// import DataTooltips from "./dataTooltips";
import mockDataset from "./dataSourceTemplate.json";

const AttendanceChart = () => {
  return <LineChart {...mockDataset} />;
};

export default AttendanceChart;
