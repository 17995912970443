import { Box, IconButton, Typography } from '@mui/material';
import { IconNext, IconPrev } from '../../../assets/commons';

const Pagination = ({ page, setPage, lastPage }) => {
  const last = [];
  for (let i = 1; i <= lastPage; i++) {
    last.push(i);
  }

  const renderPageNumbers = () => {
    const visiblePages = 2; // Number of pages to show on each side of the current page.
    const pagesToShow = [];

    if (lastPage <= 5) {
      // If there are 5 or fewer pages, show all pages.
      pagesToShow.push(...last);
    } else if (page <= visiblePages + 2) {
      // If the current page is within the first 4 pages, don't add ellipsis.
      pagesToShow.push(...last.slice(0, visiblePages + 2));
      pagesToShow.push('...');
      pagesToShow.push(lastPage);
    } else if (page >= lastPage - visiblePages - 1) {
      // If the current page is within the last 4 pages, don't add ellipsis.
      pagesToShow.push(1);
      pagesToShow.push('...');
      pagesToShow.push(...last.slice(lastPage - visiblePages - 1));
    } else {
      // Show ellipsis on both sides of the current page.
      pagesToShow.push(1);
      pagesToShow.push('...');
      pagesToShow.push(page - 1, page, page + 1);
      pagesToShow.push('...');
      pagesToShow.push(lastPage);
    }

    return pagesToShow.map((item, idx) => (
      <Box
        p={'6px 8px'}
        borderRadius={'4px'}
        ml={'8px'}
        sx={{
          background:
            item === page
              ? '#51B15C'
              : item === '...'
              ? 'transparent'
              : '#f7f7f7',
          color:
            item === page ? '#fff' : item === '...' ? '#303030' : '#303030',
          cursor: item === '...' ? 'default' : 'pointer',
        }}
        key={idx}
        onClick={() => {
          if (item !== '...') {
            setPage(item);
          }
        }}
      >
        <Typography
          fontSize={'10px'}
          fontWeight={'700px'}
          letterSpacing={'0.02em'}
          lineHeight={'11px'}
          sx={{ cursor: item === '...' ? 'default' : 'pointer' }}
        >
          {item}
        </Typography>
      </Box>
    ));
  };

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'end'}>
      <Box
        onClick={() => {
          if (page - 1 < 1) {
            setPage(1);
          } else {
            setPage(page - 1);
          }
        }}
        sx={{ cursor: 'pointer' }}
      >
        <IconButton sx={{ p: '0px' }} disabled={page - 1 < 1}>
          <IconPrev />
        </IconButton>
      </Box>
      {renderPageNumbers()}
      <IconButton
        sx={{ p: '0px', ml: '8px', cursor: 'pointer' }}
        onClick={() => {
          setPage((prevPage) => {
            const nextPage = prevPage + 1;
            if (nextPage > lastPage) {
              return lastPage;
            }
            return nextPage;
          });
        }}
        disabled={page + 1 > lastPage}
      >
        <IconNext />
      </IconButton>
    </Box>
  );
};

export default Pagination;
