import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { getLanguage } from "../../../../features/language";
import { getCompany } from "../../../../features/companies";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Pagination,
  RowPerPage,
  Search,
} from "../../../../components/atoms";
import { useEffect } from "react";
import {
  getListDepartments,
  getListPosition,
} from "../../../../configs/axios/apiPath";
import DepartmentAutocomplete from "../../../../components/organisms/timesheet/departmentAutocomplete";
import { IconPlus } from "../../../../assets/commons";
import TableComponent from "../../../../components/molecules/table/mainTable";
import {
  PositionListTbody,
  PositionListThead,
} from "../../../../components/molecules/table/tableOrganization/positionList";
import { DataTablePositionList } from "../../../../data/table/organization";

const PositionList = () => {
  let localData = JSON.parse(localStorage.getItem("cvSayaData"));
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [position, setPosition] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [order, setOrder] = useState("name");
  const [direction, setDirection] = useState("-");
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);

  const [department, setDepartment] = useState(localData?.departmentId ?? "");
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const handlePositionAdd = (url) => {
    navigate("/organization/department/position/add");
  };

  // useEffect(() => {
  //   const fetchDept = async () => {
  //     if (company) {
  //       const params = {
  //         page_size: pageSize,
  //         page: page,
  //         company_id: company,
  //       };
  //       setIsLoading(true);
  //       try {
  //         const {
  //           data: { data },
  //         } = await getListDepartments(params);
  //         setLastPage(data?.page_info?.last_page);
  //         !department && setDepartment(data?.departments[0].id);
  //         setDepartmentOptions(data?.departments);
  //       } catch (error) {
  //         console.error(error.response.status);
  //         if (error.response.status === 401) {
  //           localStorage.removeItem("cvSayaLogin");
  //           navigate(`/users`);
  //         }
  //       } finally {
  //         setIsLoading(false);
  //       }
  //     }
  //   };
  //   fetchDept();
  // }, [company]);

  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          page_size: pageSize,
          page: page,
          company_id: company,
          department_id: department,
          search: keyword,
          sort: `${direction}${order}`,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListPosition(params);
          setLastPage(data?.page_info?.last_page);
          setPosition(data?.positions);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [company, direction, order, keyword, department, page, pageSize]);

  const handleDepartmentChange = (value) => {
    if (value) {
      setDepartment(value.id);
      localData = { ...localData, departmentId: value.id };
      localStorage.setItem("cvSayaData", JSON.stringify(localData));
    }
  };

  return (
    <Card sx={{ marginTop: "20px" }}>
      <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
        <Box display={"flex"} justifyContent={"space-between"}>
          {/* --- Search --- */}
          <Search
            placeholder={
              lang === "Id"
                ? "Ketik disini untuk mencari"
                : "Type here to search"
            }
            setSearch={(e) => setKeyword(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />

          <Box display={"flex"} alignItems={"center"} gap={"10px"}>
            {/* --- Department --- */}
            {/* <DepartmentAutocomplete
              departmentOptions={departmentOptions}
              department={department}
              handleDepartmentChange={handleDepartmentChange}
            /> */}

            {/* --- Add --- */}
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              onClick={() => handlePositionAdd("add-position")}
              sx={{
                borderRadius: "4px",
                marginLeft: "10px",
              }}
            >
              <Box display={"flex"} alignItems={"center"}>
                <IconPlus />
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"14px"}
                  color="primary.contrastText"
                  letterSpacing={"0.02em"}
                  marginLeft={"10px"}
                  textTransform={"none"}
                >
                  {lang === "Id" ? "Tambah" : "Add"}
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>

        {/* Table */}
        <Box sx={{ mt: "24px", mb: "16px" }}>
          <TableComponent
            isLoading={isLoading}
            tbody={position}
            TableHead={
              <PositionListThead
                thead={DataTablePositionList}
                setOrder={setOrder}
                setDirection={setDirection}
                direction={direction}
              />
            }
            TableBody={
              <PositionListTbody tbody={position} setData={setPosition} />
            }
            col={3}
          />
        </Box>

        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"end"}
          alignItems={"center"}
        >
          {/* --- Pagination --- */}
          <Box display={"flex"} justifyContent={"end"}>
            <RowPerPage
              setPageSize={setPageSize}
              pageSize={pageSize}
              setPage={setPage}
            />
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default PositionList;
