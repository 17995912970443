import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  Stack,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { Card, Modals } from "../../../../components/atoms";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../features/language";
import { IconClose } from "../../../../assets/commons";
import moment from "moment";
import { updateStatusVacancyById } from "../../../../configs/axios/apiPath/fetchRecruitment/fetchVacancy";
import { useNavigate } from "react-router-dom";

const RecruitmentJobsDetail = ({
  isOpen,
  onClose,
  detail,
  isLoading,
  handleChangeData,
}) => {
  const lang = useSelector(getLanguage);
  const [status, setStatus] = useState("");

  const navigate = useNavigate();
  const rupiah = (number) => {
    const newNumber = number.replaceAll(".", "").replace("Rp", "");
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      maximumFractionDigits: 0,
    }).format(newNumber);
  };

  useEffect(() => {
    detail?.status && setStatus(detail?.status);
  }, [detail]);

  const handleChangeStatus = async () => {
    try {
      await updateStatusVacancyById(detail?.id);
      handleChangeData();
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    }
  };

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "50%",
          transform: "translate(-50%, -50%)",
          padding: "10px 2px 10px 0px",
          bgcolor: "background.paper",
          borderRadius: "18px",
        }}
      >
        <Box
          sx={{
            maxHeight: "80vh",
            overflowY: "auto",
            borderRadius: "18px",
            border: "none",
          }}
        >
          <Card sx={{ border: "none" }}>
            <Box p={"30px"}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: "7px",
                  position: "sticky",
                  top: 0,
                  bgcolor: "background.paper",
                  p: "10px 0px 10px 0px",
                  zIndex: 999,
                }}
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontSize={"20px"}
                  fontWeight={600}
                  lineHeight={"27px"}
                  color={"#171717"}
                >
                  {lang === "Id" ? "Detail" : "Details"}
                </Typography>
                <IconButton sx={{ p: "0px" }} onClick={onClose}>
                  <IconClose />
                </IconButton>
              </Box>

              {!isLoading ? (
                <Box
                  mt={"20px"}
                  component={"form"}
                  noValidate
                  autoComplete="off"
                >
                  {/* --- Employee --- */}
                  <Box
                    mb={"20px"}
                    p={"0px"}
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography
                        fontFamily={"sans-serif"}
                        fontStyle={"normal"}
                        fontSize={"13px"}
                        fontWeight={600}
                        lineHeight={"18px"}
                        letterSpacing={"1px"}
                        color={"#171717"}
                        mb={"6px"}
                      >
                        {lang === "Id" ? "Permintaan" : "Request"}
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(detail?.urgent)}
                            size="small"
                          />
                        }
                        label="Urgent"
                        sx={{
                          color: Boolean(detail?.urgent)
                            ? "#51B15C"
                            : "#171717",
                        }}
                      />
                    </Stack>
                    <Box
                      style={{
                        width: "100%",
                        background: "#f7f7f7",
                        borderRadius: "4px",
                        border: "1px solid transparent",
                        display: "inline-block",
                      }}
                    >
                      <Typography mx={"10px"} py={"10px"}>
                        {detail?.request_by?.name}
                      </Typography>
                    </Box>
                  </Box>
                  {/* --- Dept --- */}
                  <Box
                    mb={"20px"}
                    p={"0px"}
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontSize={"13px"}
                      fontWeight={600}
                      lineHeight={"18px"}
                      letterSpacing={"1px"}
                      color={"#171717"}
                      mb={"6px"}
                    >
                      Dept
                    </Typography>
                    <Box
                      style={{
                        width: "100%",
                        background: "#f7f7f7",
                        borderRadius: "4px",
                        border: "1px solid transparent",
                        display: "inline-block",
                      }}
                    >
                      <Stack
                        direction="row"
                        gap="10px"
                        alignItems="center"
                        py={"10px"}
                      >
                        {/* <img */}
                        {/*   src={detail?.department.icon_url} */}
                        {/*   style={{ width: 20, height: 20 }} */}
                        {/*   alt="icon" */}
                        {/* /> */}

                        <Typography mx={"10px"}>
                          {detail?.department?.name}
                        </Typography>
                      </Stack>
                    </Box>
                  </Box>
                  {/* --- Position --- */}
                  <Box
                    mb={"20px"}
                    p={"0px"}
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontSize={"13px"}
                      fontWeight={600}
                      lineHeight={"18px"}
                      letterSpacing={"1px"}
                      color={"#171717"}
                      mb={"6px"}
                    >
                      {lang === "Id" ? "Posisi" : "Position"}
                    </Typography>
                    <Box
                      style={{
                        width: "100%",
                        background: "#f7f7f7",
                        borderRadius: "4px",
                        border: "1px solid transparent",
                        display: "inline-block",
                      }}
                    >
                      <Typography mx={"10px"} py={"10px"}>
                        {detail?.position.name}
                      </Typography>
                    </Box>
                  </Box>
                  {/* --- Posted --- */}
                  <Box
                    mb={"20px"}
                    p={"0px"}
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontSize={"13px"}
                      fontWeight={600}
                      lineHeight={"18px"}
                      letterSpacing={"1px"}
                      color={"#171717"}
                      mb={"6px"}
                    >
                      {lang === "Id" ? "Diposting" : "Posted"}
                    </Typography>
                    <Box
                      style={{
                        width: "100%",
                        background: "#f7f7f7",
                        borderRadius: "4px",
                        border: "1px solid transparent",
                        display: "inline-block",
                      }}
                    >
                      <Typography mx={"10px"} py={"10px"}>
                        {moment(detail?.created_at).format("DD MMMM YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    mb={"20px"}
                    p={"0px"}
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontSize={"13px"}
                      fontWeight={600}
                      lineHeight={"18px"}
                      letterSpacing={"1px"}
                      color={"#171717"}
                      mb={"6px"}
                    >
                      {lang === "Id" ? "Tipe Pekerjaan" : "Type Jobs"}
                    </Typography>
                    <Box
                      style={{
                        width: "100%",
                        background: "#f7f7f7",
                        borderRadius: "4px",
                        border: "1px solid transparent",
                        display: "inline-block",
                      }}
                    >
                      <Typography mx={"10px"} py={"10px"}>
                        {detail?.employment_type?.name}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    mb={"20px"}
                    p={"0px"}
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontSize={"13px"}
                      fontWeight={600}
                      lineHeight={"18px"}
                      letterSpacing={"1px"}
                      color={"#171717"}
                      mb={"6px"}
                    >
                      Status
                    </Typography>
                    <Box
                      style={{
                        width: "100%",
                        background: "#f7f7f7",
                        borderRadius: "4px",
                        border: "1px solid transparent",
                        display: "inline-block",
                      }}
                    >
                      <Select
                        id="change-status"
                        value={status}
                        sx={{
                          width: "100%",
                          height: "45px",

                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                        }}
                        onChange={(e) => {
                          setStatus(e.target.value);
                          handleChangeStatus();
                        }}
                      >
                        <MenuItem value={"open"}>Open</MenuItem>
                        <MenuItem value={"closed"}>Closed</MenuItem>
                      </Select>
                    </Box>
                  </Box>
                  <Box
                    mb={"20px"}
                    p={"0px"}
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontSize={"13px"}
                      fontWeight={600}
                      lineHeight={"18px"}
                      letterSpacing={"1px"}
                      color={"#171717"}
                      mb={"6px"}
                    >
                      Jobs Detail
                    </Typography>
                    <Box
                      style={{
                        width: "100%",
                        background: "#f7f7f7",
                        borderRadius: "4px",
                        border: "1px solid transparent",
                        display: "inline-block",
                      }}
                    >
                      <Typography mx={"10px"} py={"10px"}>
                        {detail?.detail}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    mb={"20px"}
                    p={"0px"}
                    sx={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "row",
                      gap: "30px",
                    }}
                  >
                    <Box
                      width={"50%"}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        fontFamily={"sans-serif"}
                        fontStyle={"normal"}
                        fontSize={"13px"}
                        fontWeight={600}
                        lineHeight={"18px"}
                        letterSpacing={"1px"}
                        color={"#171717"}
                        mb={"6px"}
                      >
                        Min Salary
                      </Typography>
                      <Box
                        style={{
                          width: "100%",
                          background: "#f7f7f7",
                          borderRadius: "4px",
                          border: "1px solid transparent",
                          display: "inline-block",
                        }}
                      >
                        <Typography mx={"10px"} py={"10px"} width={"50%"}>
                          {detail?.min_salary
                            ? rupiah(detail?.min_salary.toString())
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      width={"50%"}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        fontFamily={"sans-serif"}
                        fontStyle={"normal"}
                        fontSize={"13px"}
                        fontWeight={600}
                        lineHeight={"18px"}
                        letterSpacing={"1px"}
                        color={"#171717"}
                        mb={"6px"}
                      >
                        Max Salary
                      </Typography>
                      <Box
                        style={{
                          width: "100%",
                          background: "#f7f7f7",
                          borderRadius: "4px",
                          border: "1px solid transparent",
                          display: "inline-block",
                        }}
                      >
                        <Typography mx={"10px"} py={"10px"} width={"50%"}>
                          {detail?.max_salary
                            ? rupiah(detail?.max_salary.toString())
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  {/* --- Close --- */}
                  <Button
                    variant="contained"
                    disableRipple
                    onClick={onClose}
                    fullWidth
                    sx={{ height: "62px" }}
                  >
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontSize={"20px"}
                      fontWeight={"700px"}
                      lineHeight={"27px"}
                      letterSpacing={"1px"}
                      textTransform={"none"}
                    >
                      {lang === "Id" ? "Keluar" : "Close"}
                    </Typography>
                  </Button>
                </Box>
              ) : (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <CircularProgress color="secondary" />
                </Box>
              )}
            </Box>
          </Card>
        </Box>
      </Box>
    </Modals>
  );
};

export default RecruitmentJobsDetail;
