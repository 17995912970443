import {
  IconFreelance,
  IconFulltime,
  IconHybrid,
  IconParttime,
  IconRemote,
} from '../../../assets/employee';

const dataEmployeeFooter = [
  {
    title: 'Hybrid',
    judul: 'Hybrid',
    icon: <IconHybrid />,
  },
  {
    title: 'Fulltime',
    judul: 'Penuh Waktu',
    icon: <IconFulltime />,
  },
  {
    title: 'Remote',
    judul: 'Remote',
    icon: <IconRemote />,
  },
  {
    title: 'Freelance',
    judul: 'Pekerja Lepas',
    icon: <IconFreelance />,
  },
  {
    title: 'Part time',
    judul: 'Paruh Waktu',
    icon: <IconParttime />,
  },
];

export default dataEmployeeFooter;
