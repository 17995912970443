import "./style.css";
import { useEffect, useState } from "react";

export default function SimpleTooltipContent({ sources = [] }) {
  return sources.map((a) => (
    <div className="tooltip-simple">
      <div className="graph-tooltip-content">
        <p className="title-tooltip">{a.title}</p>
        <p className="desc-tooltip">{a.desc}</p>
      </div>
    </div>
  ));
}
