import React from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { Card, Modals } from "../../../../components/atoms";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../features/language";
import { IconClose } from "../../../../assets/commons";

const PositionDetail = ({ isOpen, onClose, detail, isLoading }) => {
  const lang = useSelector(getLanguage);

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "559px",
          transform: "translate(-50%, -50%)",
          maxHeight: "722px",
          overflowY: "auto",
          bgcolor: "background.paper",
          borderRadius: "16px",
          gap: "10px",
        }}
      >
        <Card>
          <Box p={"30px"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: "7px",
                position: "sticky",
                top: 0,
                bgcolor: "background.paper",
                p: "10px 0px 10px 0px",
                zIndex: 999,
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"27px"}
                color={"#171717"}
              >
                {lang === "Id" ? "Detail Posisi" : "Position Detail"}
              </Typography>
              <IconButton sx={{ p: "0px" }} onClick={onClose}>
                <IconClose />
              </IconButton>
            </Box>

            {!isLoading ? (
              <Box mt={"20px"} component={"form"} noValidate autoComplete="off">
                {/* --- Name --- */}
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    {lang === "Id" ? "Nama" : "Name"}
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      background: "#f7f7f7",
                      borderRadius: "4px",
                      border: "1px solid transparent",
                      display: "inline-block",
                    }}
                  >
                    <Typography mx={"10px"} py={"10px"}>
                      {detail?.name}
                    </Typography>
                  </Box>
                </Box>

                {/* --- Department --- */}
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    {lang === "Id" ? "Departemen" : "Department"}
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      background: "#f7f7f7",
                      borderRadius: "4px",
                      border: "1px solid transparent",
                      display: "inline-block",
                    }}
                  >
                    <Typography mx={"10px"} py={"10px"}>
                      {detail?.department?.name !== null
                        ? detail?.department?.name
                        : "-"}
                    </Typography>
                  </Box>
                </Box>

                {/* --- Level --- */}
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    {lang === "Id" ? "Level" : "Level"}
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      background: "#f7f7f7",
                      borderRadius: "4px",
                      border: "1px solid transparent",
                      display: "inline-block",
                    }}
                  >
                    <Typography mx={"10px"} py={"10px"}>
                      {detail?.level?.name}
                    </Typography>
                  </Box>
                </Box>

                {/* --- Close --- */}
                <Button
                  variant="contained"
                  disableRipple
                  onClick={onClose}
                  fullWidth
                  sx={{
                    height: "fixed(62px)",
                    width: "fixed(497px)",
                    borderRadius: "8px",
                    padding: "20px, 10px, 20px, 10px",
                    gap: "10px",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"20px"}
                    fontWeight={"700px"}
                    lineHeight={"27px"}
                    letterSpacing={"1px"}
                    textTransform={"none"}
                  >
                    {lang === "Id" ? "Keluar" : "Close"}
                  </Typography>
                </Button>
              </Box>
            ) : (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress color="secondary" />
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </Modals>
  );
};

export default PositionDetail;
