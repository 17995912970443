import { Box, Button, Typography } from '@mui/material';
import SelectYear from '../../../molecules/year';
import { useState } from 'react';
import { getLanguage } from '../../../../features/language';
import { getCompany } from '../../../../features/companies';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  ButtonDetail,
  Card,
  Pagination,
  RowPerPage,
  Search,
} from '../../../atoms';
import { IconPlus } from '../../../../assets/commons';
import { useEffect } from 'react';
import {
  getListCorrection,
  getListDepartments,
} from '../../../../configs/axios/apiPath';
import { DataTableAttendanceCorrection } from '../../../../data/table/timesheet';
import TableComponent from '../../../molecules/table/mainTable';
import {
  AttendanceTheadCorrection,
  AttendanceTbodyCorrection,
} from '../../../molecules/table/tableTimesheet/attendanceCorrection';
import { AttendanceCorrectionAdd } from '../../../../pages/timesheet/attandanceCorrection';
import DepartmentAutocomplete from '../departmentAutocomplete';

const TableAttendanceCorrection = () => {
  let localData = JSON.parse(localStorage.getItem('cvSayaData'));
  const yearNow = moment().format('YYYY');
  const year = [];
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [selectedYear, setSelectedYear] = useState(yearNow);
  const [isLoading, setIsLoading] = useState(false);
  const [correction, setCorrection] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [order, setOrder] = useState('name');
  const [direction, setDirection] = useState('-');
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [flag, setFlag] = useState(false);

  const [department, setDepartment] = useState(localData?.departmentId ?? '');
  const [departmentOptions, setDepartmentOptions] = useState([]);

  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }

  const handleCorrectionAdded = () => {
    setFlag((prev) => !prev);
    setIsModalOpen(false);
  };

  const handleNavigationClick = (url) => {
    navigate('/timesheet/attendance-correction/list-attendance-correction');
  };

  const handleDepartmentChange = (value) => {
    if (value) {
      setDepartment(value.id);
      localData = { ...localData, departmentId: value.id };
      localStorage.setItem('cvSayaData', JSON.stringify(localData));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          page_size: pageSize,
          page: page,
          company_id: company,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListDepartments(params);
          const defaultDepartment = {
            id: null,
            name: 'ALL DEPARTMENT',
            icon_url: null,
            total_employee: 0,
            manager: null,
          };

          const newDepartments = [defaultDepartment, ...data?.departments];

          !department && setDepartment(newDepartments[0].id);
          setDepartmentOptions(newDepartments);
          setLastPage(data?.page_info?.last_page);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company]);

  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          page_size: pageSize,
          page: page,
          company_id: company,
          department_id: department,
          year: selectedYear,
          search: keyword,
          sort: `${direction}${order}`,
          // keyword: keyword,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListCorrection(params);
          setLastPage(data?.page_info?.last_page);
          setCorrection(data?.corrections);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [
    company,
    direction,
    order,
    keyword,
    selectedYear,
    department,
    flag,
    page,
    pageSize,
  ]);

  return (
    <Card sx={{ marginTop: '20px' }}>
      <Box display={'flex'} flexDirection={'column'} p={'23px 22px'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          {/* --- Search --- */}
          <Search
            placeholder={
              lang === 'Id'
                ? 'Ketik disini untuk mencari'
                : 'Type here to search'
            }
            setSearch={(e) => setKeyword(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />

          <Box display={'flex'} alignItems={'center'} gap={'10px'}>
            {/* --- Department --- */}
            <DepartmentAutocomplete
              departmentOptions={departmentOptions}
              department={department}
              handleDepartmentChange={handleDepartmentChange}
            />
            <SelectYear
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              year={year}
            />

            {/* --- Add --- */}
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              onClick={() => setIsModalOpen(true)}
              sx={{
                borderRadius: '4px',
                marginLeft: '10px',
              }}
            >
              <Box display={'flex'} alignItems={'center'}>
                <IconPlus />
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={600}
                  fontSize={'12px'}
                  lineHeight={'14px'}
                  color="primary.contrastText"
                  letterSpacing={'0.02em'}
                  marginLeft={'10px'}
                  textTransform={'none'}
                >
                  {lang === 'Id' ? 'Tambah' : 'Add'}
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>

        {/* Table */}
        <Box sx={{ mt: '24px', mb: '16px' }}>
          <TableComponent
            isLoading={isLoading}
            tbody={correction}
            TableHead={
              <AttendanceTheadCorrection
                thead={DataTableAttendanceCorrection}
                setOrder={setOrder}
                setDirection={setDirection}
                direction={direction}
              />
            }
            TableBody={
              <AttendanceTbodyCorrection
                tbody={correction}
                setData={setCorrection}
              />
            }
            col={8}
          />
        </Box>

        <Box
          display={'flex'}
          width={'100%'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          {/* --- Navigate --- */}
          <Box display={'flex'} justifyContent={'start'}>
            <ButtonDetail onclick={() => handleNavigationClick()} />
          </Box>

          {/* --- Pagination --- */}
          <Box display={'flex'} justifyContent={'end'}>
            <RowPerPage
              setPageSize={setPageSize}
              pageSize={pageSize}
              setPage={setPage}
            />
            <Pagination page={page} setPage={setPage} lastPage={lastPage} />
          </Box>
        </Box>
      </Box>
      <AttendanceCorrectionAdd
        isOpen={isModalOpen}
        handleCorrectionAdded={handleCorrectionAdded}
        onClose={() => setIsModalOpen(false)}
        data={data}
        setData={setData}
      />
    </Card>
  );
};

export default TableAttendanceCorrection;
