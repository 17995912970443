import React from 'react';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Buttons, Card } from '../../../../../../components/atoms';
import { IconSearch } from '../../../../../../assets/commons';
import { IconActive } from '../../../../../../assets/employee';

const AddShiftOccuring = () => {
  const [sunday, setSunday] = useState('');
  const [monday, setMonday] = useState('');
  const [tuesday, setTuesday] = useState('');
  const [wednesday, setWednesday] = useState('');
  const [thursday, setThursday] = useState('');
  const [friday, setFriday] = useState('');
  const [saturday, setSaturday] = useState('');
  const [sundayStatus, setSundayStatus] = useState(false);
  const [mondayStatus, setMondayStatus] = useState(false);
  const [tuesdayStatus, setTuesdayStatus] = useState(false);
  const [wednesdayStatus, setWednesdayStatus] = useState(false);
  const [thursdayStatus, setThursdayStatus] = useState(false);
  const [fridayStatus, setFridayStatus] = useState(false);
  const [saturdayStatus, setSaturdayStatus] = useState(false);
  const [sundayIsFocus, setSundayIsFocus] = useState(false);
  const [mondayIsFocus, setMondayIsFocus] = useState(false);
  const [tuesdayIsFocus, setTuesdayIsFocus] = useState(false);
  const [wednesdayIsFocus, setWednesdayIsFocus] = useState(false);
  const [thursdayIsFocus, setThursdayIsFocus] = useState(false);
  const [fridayIsFocus, setFridayIsFocus] = useState(false);
  const [saturdayIsFocus, setSaturdayIsFocus] = useState(false);

  return (
    <Card sx={{ p: '20px' }}>
      <Box p={'20px'}>
        <Box borderBottom={'0.2px solid #939393'} p={'0px 0px 15px 0px'}>
          <Typography
            fontWeight={600}
            fontSize={'20px'}
            lineHeight={'27px'}
            letterSpacing={'0.01em'}
            color={'#171717'}
          >
            Add
          </Typography>
        </Box>
        <form
        //   onSubmit={handleSubmit}
        >
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              {/* --- Monday --- */}
              <Box
                display={'flex'}
                flexDirection={'column'}
                mb={'9px'}
                mt={'35px'}
              >
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  Monday
                </InputLabel>
                {/* --- Monday --- */}
                <Grid container columns={8} spacing={2}>
                  <Grid item xl={7} lg={7} md={6} sm={7} xs={7}>
                    <TextField
                      variant="outlined"
                      value={monday}
                      onFocus={() => setMondayIsFocus(true)}
                      onBlur={() => setMondayIsFocus(false)}
                      placeholder="Type here"
                      onChange={(event) => setMonday(event.target.value)}
                      fullWidth
                      type="medium"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {!mondayIsFocus && monday === '' ? (
                              <IconSearch />
                            ) : (
                              ''
                            )}
                          </InputAdornment>
                        ),
                      }}
                      required
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          background: '#f9f9f9',
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                        },
                      }}
                    />
                  </Grid>
                  {/* --- Status --- */}
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={2}
                    sm={1}
                    xs={1}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <Box
                      sx={{
                        alignItems: 'center',
                        width: '88px',
                        background: mondayStatus
                          ? 'rgba(81, 177, 92, 0.2)'
                          : 'rgba(255, 29, 29, 0.2)',
                        color: mondayStatus ? '#51B15C' : '#FF1D1D',
                        py: '6px',
                        border: mondayStatus
                          ? '0.4px solid #51B15C'
                          : '0.4px solid #FF1D1D',
                        borderRadius: '6px',
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                      onClick={() => setMondayStatus(!mondayStatus)}
                    >
                      <IconButton
                        sx={{
                          p: '0px',
                          position: 'absolute',
                          top: -8,
                          right: -8,
                        }}
                      >
                        <IconActive />
                      </IconButton>
                      <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontWeight={600}
                        fontSize={'10px'}
                        lineHeight={'12px'}
                        letterSpacing={'0.02em'}
                        textAlign={'center'}
                      >
                        {mondayStatus ? 'Active' : 'Not Active'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* --- Tuesday --- */}
              <Box
                display={'flex'}
                flexDirection={'column'}
                mb={'9px'}
                mt={'35px'}
              >
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  Tuesday
                </InputLabel>
                {/* --- Tuesday --- */}
                <Grid container columns={8} spacing={2}>
                  <Grid item xl={7} lg={7} md={6} sm={7} xs={7}>
                    <TextField
                      variant="outlined"
                      value={tuesday}
                      onFocus={() => setTuesdayIsFocus(true)}
                      onBlur={() => setTuesdayIsFocus(false)}
                      placeholder="Type here"
                      onChange={(event) => setTuesday(event.target.value)}
                      fullWidth
                      type="medium"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {!tuesdayIsFocus && tuesday === '' ? (
                              <IconSearch />
                            ) : (
                              ''
                            )}
                          </InputAdornment>
                        ),
                      }}
                      required
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          background: '#f9f9f9',
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                        },
                      }}
                    />
                  </Grid>
                  {/* --- Status --- */}
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={2}
                    sm={1}
                    xs={1}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <Box
                      sx={{
                        alignItems: 'center',
                        width: '88px',
                        background: tuesdayStatus
                          ? 'rgba(81, 177, 92, 0.2)'
                          : 'rgba(255, 29, 29, 0.2)',
                        color: tuesdayStatus ? '#51B15C' : '#FF1D1D',
                        py: '6px',
                        border: tuesdayStatus
                          ? '0.4px solid #51B15C'
                          : '0.4px solid #FF1D1D',
                        borderRadius: '6px',
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                      onClick={() => setTuesdayStatus(!tuesdayStatus)}
                    >
                      <IconButton
                        sx={{
                          p: '0px',
                          position: 'absolute',
                          top: -8,
                          right: -8,
                        }}
                      >
                        <IconActive />
                      </IconButton>
                      <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontWeight={600}
                        fontSize={'10px'}
                        lineHeight={'12px'}
                        letterSpacing={'0.02em'}
                        textAlign={'center'}
                      >
                        {tuesdayStatus ? 'Active' : 'Not Active'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* --- Wednesday --- */}
              <Box
                display={'flex'}
                flexDirection={'column'}
                mb={'9px'}
                mt={'35px'}
              >
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  Wednesday
                </InputLabel>
                {/* --- Wednesday --- */}
                <Grid container columns={8} spacing={2}>
                  <Grid item xl={7} lg={7} md={6} sm={7} xs={7}>
                    <TextField
                      variant="outlined"
                      value={wednesday}
                      onFocus={() => setWednesdayIsFocus(true)}
                      onBlur={() => setWednesdayIsFocus(false)}
                      placeholder="Type here"
                      onChange={(event) => setWednesday(event.target.value)}
                      fullWidth
                      type="medium"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {!wednesdayIsFocus && wednesday === '' ? (
                              <IconSearch />
                            ) : (
                              ''
                            )}
                          </InputAdornment>
                        ),
                      }}
                      required
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          background: '#f9f9f9',
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                        },
                      }}
                    />
                  </Grid>
                  {/* --- Status --- */}
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={2}
                    sm={1}
                    xs={1}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <Box
                      sx={{
                        alignItems: 'center',
                        width: '88px',
                        background: wednesdayStatus
                          ? 'rgba(81, 177, 92, 0.2)'
                          : 'rgba(255, 29, 29, 0.2)',
                        color: wednesdayStatus ? '#51B15C' : '#FF1D1D',
                        py: '6px',
                        border: wednesdayStatus
                          ? '0.4px solid #51B15C'
                          : '0.4px solid #FF1D1D',
                        borderRadius: '6px',
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                      onClick={() => setWednesdayStatus(!wednesdayStatus)}
                    >
                      <IconButton
                        sx={{
                          p: '0px',
                          position: 'absolute',
                          top: -8,
                          right: -8,
                        }}
                      >
                        <IconActive />
                      </IconButton>
                      <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontWeight={600}
                        fontSize={'10px'}
                        lineHeight={'12px'}
                        letterSpacing={'0.02em'}
                        textAlign={'center'}
                      >
                        {wednesdayStatus ? 'Active' : 'Not Active'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* --- Thursday --- */}
              <Box
                display={'flex'}
                flexDirection={'column'}
                mb={'9px'}
                mt={'35px'}
              >
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  Thursday
                </InputLabel>
                {/* --- Thursday --- */}
                <Grid container columns={8} spacing={2}>
                  <Grid item xl={7} lg={7} md={6} sm={7} xs={7}>
                    <TextField
                      variant="outlined"
                      value={thursday}
                      onFocus={() => setThursdayIsFocus(true)}
                      onBlur={() => setThursdayIsFocus(false)}
                      placeholder="Type here"
                      onChange={(event) => setThursday(event.target.value)}
                      fullWidth
                      type="medium"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {!thursdayIsFocus && thursday === '' ? (
                              <IconSearch />
                            ) : (
                              ''
                            )}
                          </InputAdornment>
                        ),
                      }}
                      required
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          background: '#f9f9f9',
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                        },
                      }}
                    />
                  </Grid>
                  {/* --- Status --- */}
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={2}
                    sm={1}
                    xs={1}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <Box
                      sx={{
                        alignItems: 'center',
                        width: '88px',
                        background: thursdayStatus
                          ? 'rgba(81, 177, 92, 0.2)'
                          : 'rgba(255, 29, 29, 0.2)',
                        color: thursdayStatus ? '#51B15C' : '#FF1D1D',
                        py: '6px',
                        border: thursdayStatus
                          ? '0.4px solid #51B15C'
                          : '0.4px solid #FF1D1D',
                        borderRadius: '6px',
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                      onClick={() => setThursdayStatus(!thursdayStatus)}
                    >
                      <IconButton
                        sx={{
                          p: '0px',
                          position: 'absolute',
                          top: -8,
                          right: -8,
                        }}
                      >
                        <IconActive />
                      </IconButton>
                      <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontWeight={600}
                        fontSize={'10px'}
                        lineHeight={'12px'}
                        letterSpacing={'0.02em'}
                        textAlign={'center'}
                      >
                        {thursdayStatus ? 'Active' : 'Not Active'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* --- Friday --- */}
              <Box
                display={'flex'}
                flexDirection={'column'}
                mb={'9px'}
                mt={'35px'}
              >
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  Friday
                </InputLabel>
                {/* --- Friday --- */}
                <Grid container columns={8} spacing={2}>
                  <Grid item xl={7} lg={7} md={6} sm={7} xs={7}>
                    <TextField
                      variant="outlined"
                      value={friday}
                      onFocus={() => setFridayIsFocus(true)}
                      onBlur={() => setFridayIsFocus(false)}
                      placeholder="Type here"
                      onChange={(event) => setFriday(event.target.value)}
                      fullWidth
                      type="medium"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {!fridayIsFocus && friday === '' ? (
                              <IconSearch />
                            ) : (
                              ''
                            )}
                          </InputAdornment>
                        ),
                      }}
                      required
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          background: '#f9f9f9',
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                        },
                      }}
                    />
                  </Grid>
                  {/* --- Status --- */}
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={2}
                    sm={1}
                    xs={1}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <Box
                      sx={{
                        alignItems: 'center',
                        width: '88px',
                        background: fridayStatus
                          ? 'rgba(81, 177, 92, 0.2)'
                          : 'rgba(255, 29, 29, 0.2)',
                        color: fridayStatus ? '#51B15C' : '#FF1D1D',
                        py: '6px',
                        border: fridayStatus
                          ? '0.4px solid #51B15C'
                          : '0.4px solid #FF1D1D',
                        borderRadius: '6px',
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                      onClick={() => setFridayStatus(!fridayStatus)}
                    >
                      <IconButton
                        sx={{
                          p: '0px',
                          position: 'absolute',
                          top: -8,
                          right: -8,
                        }}
                      >
                        <IconActive />
                      </IconButton>
                      <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontWeight={600}
                        fontSize={'10px'}
                        lineHeight={'12px'}
                        letterSpacing={'0.02em'}
                        textAlign={'center'}
                      >
                        {fridayStatus ? 'Active' : 'Not Active'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* --- Saturday --- */}
              <Box
                display={'flex'}
                flexDirection={'column'}
                mb={'9px'}
                mt={'35px'}
              >
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  Saturday
                </InputLabel>
                {/* --- Saturday --- */}
                <Grid container columns={8} spacing={2}>
                  <Grid item xl={7} lg={7} md={6} sm={7} xs={7}>
                    <TextField
                      variant="outlined"
                      value={saturday}
                      onFocus={() => setSaturdayIsFocus(true)}
                      onBlur={() => setSaturdayIsFocus(false)}
                      placeholder="Type here"
                      onChange={(event) => setSaturday(event.target.value)}
                      fullWidth
                      type="medium"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {!saturdayIsFocus && saturday === '' ? (
                              <IconSearch />
                            ) : (
                              ''
                            )}
                          </InputAdornment>
                        ),
                      }}
                      required
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          background: '#f9f9f9',
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                        },
                      }}
                    />
                  </Grid>
                  {/* --- Status --- */}
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={2}
                    sm={1}
                    xs={1}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <Box
                      sx={{
                        alignItems: 'center',
                        width: '88px',
                        background: saturdayStatus
                          ? 'rgba(81, 177, 92, 0.2)'
                          : 'rgba(255, 29, 29, 0.2)',
                        color: saturdayStatus ? '#51B15C' : '#FF1D1D',
                        py: '6px',
                        border: saturdayStatus
                          ? '0.4px solid #51B15C'
                          : '0.4px solid #FF1D1D',
                        borderRadius: '6px',
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                      onClick={() => setSaturdayStatus(!saturdayStatus)}
                    >
                      <IconButton
                        sx={{
                          p: '0px',
                          position: 'absolute',
                          top: -8,
                          right: -8,
                        }}
                      >
                        <IconActive />
                      </IconButton>
                      <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontWeight={600}
                        fontSize={'10px'}
                        lineHeight={'12px'}
                        letterSpacing={'0.02em'}
                        textAlign={'center'}
                      >
                        {saturdayStatus ? 'Active' : 'Not Active'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* --- Sunday --- */}
              <Box
                display={'flex'}
                flexDirection={'column'}
                mb={'9px'}
                mt={'35px'}
              >
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  Sunday
                </InputLabel>
                {/* --- Sunday --- */}
                <Grid container columns={8} spacing={2}>
                  <Grid item xl={7} lg={7} md={6} sm={7} xs={7}>
                    <TextField
                      variant="outlined"
                      value={sunday}
                      onFocus={() => setSundayIsFocus(true)}
                      onBlur={() => setSundayIsFocus(false)}
                      placeholder="Type here"
                      onChange={(event) => setSunday(event.target.value)}
                      fullWidth
                      type="medium"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {!sundayIsFocus && sunday === '' ? (
                              <IconSearch />
                            ) : (
                              ''
                            )}
                          </InputAdornment>
                        ),
                      }}
                      required
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          background: '#f9f9f9',
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                        },
                      }}
                    />
                  </Grid>
                  {/* --- Status --- */}
                  <Grid
                    item
                    xl={1}
                    lg={1}
                    md={2}
                    sm={1}
                    xs={1}
                    display={'flex'}
                    alignItems={'center'}
                  >
                    <Box
                      sx={{
                        alignItems: 'center',
                        width: '88px',
                        background: sundayStatus
                          ? 'rgba(81, 177, 92, 0.2)'
                          : 'rgba(255, 29, 29, 0.2)',
                        color: sundayStatus ? '#51B15C' : '#FF1D1D',
                        py: '6px',
                        border: sundayStatus
                          ? '0.4px solid #51B15C'
                          : '0.4px solid #FF1D1D',
                        borderRadius: '6px',
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                      onClick={() => setSundayStatus(!sundayStatus)}
                    >
                      <IconButton
                        sx={{
                          p: '0px',
                          position: 'absolute',
                          top: -8,
                          right: -8,
                        }}
                      >
                        <IconActive />
                      </IconButton>
                      <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontWeight={600}
                        fontSize={'10px'}
                        lineHeight={'12px'}
                        letterSpacing={'0.02em'}
                        textAlign={'center'}
                      >
                        {sundayStatus ? 'Active' : 'Not Active'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* --- Buttons --- */}
              <Buttons
                type="submit"
                variant={'contained'}
                sx={{
                  borderRadius: '4px',
                  width: '136px',
                  mt: '30px',
                }}
              >
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={600}
                  fontSize={'16px'}
                  lineHeight={'22px'}
                  color="primary.contrastText"
                  letterSpacing={'0.01em'}
                  py={'7px'}
                  textTransform={'none'}
                >
                  Submit
                </Typography>
              </Buttons>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Card>
  );
};

export default AddShiftOccuring;
