import { Box, IconButton, Typography } from "@mui/material"
import { IconKey, IconLogoLg } from "../../../../assets/loginPage/icons"

const ForgetPasswordContentTitle = () => {
    return (
        <Box 
            display={'flex'} 
            flexDirection={'column'}
            alignItems={'center'} 
            sx={{
                width: {
                    xl: '467px',
                    lg: '467px',
                    md: '467px',
                    sm: '467px',
                }
            }}
        >
            {/* --- Logo --- */}
            <IconLogoLg />
            <Box       
                mt={'20px'}
            >
                {/* --- Welcome --- */}
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mb={'6px'}>
                    <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontWeight={600}
                        fontSize={'24px'}
                        lineHeight={'33px'}
                        letterSpacing={'0.02em'}
                        color={'#171717'}
                    >Lupa Password</Typography>
                    <IconButton sx={{padding: '0px', mx: '12px'}}>
                        <IconKey />
                    </IconButton>
                </Box>
                {/* --- Description --- */}
                <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontWeight={400}
                    fontSize={'16px'}
                    lineHeight={'22px'}
                    letterSpacing={'0.02em'}
                    color={'#c4c4c4'}
                    textAlign={'center'}
                >
                    Masukkan Alamat email anda dan kami akan mengirimkan anda tautan untuk mereset kata sandi
                </Typography>
            </Box>
        </Box>
    )
}

export default ForgetPasswordContentTitle