import React from 'react'
import { MyCalendar } from '../../../../components/atoms'
import ChartCalendar from '../../../../components/organisms/calendar/chartSummary'
import { Box } from '@mui/system'

export default function HRCalendarDashboard() {
  return (
    <Box>
      <ChartCalendar/>
      <MyCalendar/>
    </Box>
  )
}
