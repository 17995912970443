import { Badge, Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { IconNotif } from "../../../../../assets/topNav/icons";

const Notification = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const [notifications, setNotifications] = useState([
        {
        id: 1,
        message: 'Ini adalah notifikasi pertama',
        read: false,
        },
        {
        id: 2,
        message: 'Ini adalah notifikasi kedua',
        read: false,
        },
    ]);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleNotificationSelect = (id) => () => {
        setNotifications((prevNotifications) =>
        prevNotifications.map((notif) =>
            notif.id === id ? { ...notif, read: true } : notif
        )
        );
    };

 return (
    <Box>
        {/* --- Icon --- */}
        <IconButton color="inherit" onClick={handleMenuOpen} sx={{ p: '0px' }}>
          <Badge badgeContent={5} color="error" sx={{ cursor: 'pointer' }}>
            <IconNotif color="action" />
          </Badge>
        </IconButton>
        {/* --- Dropdown --- */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mx: '16px',
            }}
          >
            <Typography
              variant="subtitle1"
              color="inherit"
              fontWeight={'400px'}
              fontSize={'16px'}
              sx={{ color: '#171717' }}
            >
              Notifications
            </Typography>
            <Typography
              fontWeight={'600px'}
              fontSize={'12px'}
              sx={{ color: '#51B15C' }}
            >
              Select
            </Typography>
          </Box>
          {notifications.map((notif) => (
            <MenuItem
              key={notif.id}
              onClick={handleNotificationSelect(notif.id)}
            >
              <Typography
                variant="body1"
                color={notif.read ? 'textSecondary' : 'textPrimary'}
              >
                {notif.message}
              </Typography>
            </MenuItem>
          ))}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mx: '16px',
            }}
          >
            <Box display={'flex'}>
              {/* <IconCheck /> */}
              <Typography
                fontWeight={'600px'}
                fontSize={'12px'}
                style={{ color: '#51B15C' }}
                ml={'6px'}
              >
                Mark as read
              </Typography>
            </Box>
            <Typography
              fontWeight={'600px'}
              fontSize={'12px'}
              sx={{ color: '#51B15C' }}
            >
              See All
            </Typography>
          </Box>
        </Menu>
      </Box>
 )
}

export default Notification;