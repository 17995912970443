import {
  Button,
  Card,
  InputLabel,
  InputAdornment,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../features/language";
import {
  IconEditLocation,
  IconLocationPhone,
} from "../../../../assets/department";
import {
  getCity,
  getCountries,
  getProvinces,
  postLocation,
} from "../../../../configs/axios/apiPath";
import {
  SelectCity,
  SelectCountry,
  SelectDepartment,
  SelectProvince,
} from "../../../../components/molecules/select";

import { getCompany } from "../../../../features/companies";
import { getListDepartment } from "../../../../configs/axios/apiPath";
import { useNavigate } from "react-router-dom";

const AddLocation = () => {
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);
  const [dataCountries, setDataCountries] = useState([]);
  const [dataProvinces, setDataProvinces] = useState([]);
  const [dataCity, setDataCity] = useState([]);

  const [department, setDepartment] = useState([]);
  const [idDept, setIdDept] = useState(null);
  const [inputDept, setInputDept] = useState(null);

  const [locationName, setLocationName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [country, setCountry] = useState(null);
  const [inputCountry, setInputCountry] = useState("");

  const [provinces, setProvinces] = useState(null);
  const [inputProvince, setInputProvince] = useState("");

  const [city, setCity] = useState(null);
  const [inputCity, setInputCity] = useState("");

  const navigate = useNavigate();

  async function fetchProvinces(id) {
    const params = {
      page_size: 99999,
      keywords: null,
    };

    try {
      const {
        data: { data },
      } = await getProvinces(params, id);
      setDataProvinces(data?.page_data);
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchCity(id) {
    const params = {
      page_size: 99999,
      keywords: null,
    };

    try {
      const {
        data: { data },
      } = await getCity(params, id);
      setDataCity(data?.page_data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function fetchCountries() {
      const params = {
        page_size: 99999,
        keywords: null,
      };

      try {
        const {
          data: { data },
        } = await getCountries(params);
        setDataCountries(data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchCountries();

    if (country) {
      fetchProvinces(country.code);
    }

    if (provinces) {
      fetchCity(provinces.code);
    }

    if (company) {
      const fetchDepartment = async () => {
        const params = {
          page_size: 99999,
          company_id: company,
        };

        try {
          const {
            data: { data },
          } = await getListDepartment(params);
          setDepartment(data?.departments);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        }
      };

      fetchDepartment();
    }
  }, [company, country, navigate, provinces]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      department_id: idDept.id,
      location_name: locationName,
      phone_number: phoneNumber,
      country_id: country.code,
      province_id: provinces.code,
      city_id: city.code,
    };

    try {
      await postLocation(data);
      navigate(`/organization/location`);
    } catch (error) {
      console.error(error);
      navigate(`/organization/location`);
    }
  };

  const FormLabel = ({ label }) => (
    <InputLabel
      style={{
        fontFamily: "sans-serif",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "22px",
        letterSpacing: "0.02em",
        color: "#171717",
        marginBottom: "10px",
      }}
    >
      {label}
    </InputLabel>
  );

  return (
    <Card sx={{ mt: "10px" }}>
      <Box padding={"20px"}>
        <Box borderBottom={"0.2px solid #939393"} p={"0px 0px 15px 0px"}>
          <Typography
            fontFamily={"sans-serif"}
            fontStyle={"normal"}
            fontWeight={600}
            fontSize={"20px"}
            lineHeight={"27px"}
            letterSpacing={"0.01em"}
            color={"#171717"}
          >
            {lang === "Id" ? "Tambah" : "Add"}
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" paddingTop={4}>
            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                  <FormLabel label="Location Name" />
                  <TextField
                    fullWidth
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconEditLocation />
                        </InputAdornment>
                      ),
                    }}
                    required
                    onChange={(e) => setLocationName(e.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                  <FormLabel label="Department" />
                  <SelectDepartment
                    data={department}
                    inputChange={setInputDept}
                    inputValue={inputDept}
                    setValue={setIdDept}
                    value={idDept}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                  <FormLabel label="Telephone" />
                  <TextField
                    fullWidth
                    type="number"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconLocationPhone />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                  <FormLabel label="Country" />
                  <SelectCountry
                    data={dataCountries}
                    inputChange={setInputCountry}
                    inputValue={inputCountry}
                    setValue={(e, value) => setCountry(value)}
                    value={country}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                  <FormLabel label="Province" />
                  <SelectProvince
                    data={dataProvinces}
                    setValue={setProvinces}
                    value={provinces}
                    inputChange={setInputProvince}
                    inputValue={inputProvince}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                  <FormLabel label="City" />
                  <SelectCity
                    data={dataCity}
                    setValue={setCity}
                    value={city}
                    inputChange={setInputCity}
                    inputValue={inputCity}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box mt={"20px"}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ textTransform: "none" }}
                size="small"
              >
                {lang === "Id" ? "Simpan" : "Save"}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Card>
  );
};

export default AddLocation;
