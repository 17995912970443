import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  getOvertimeById,
  updateOvertimeById,
} from "../../../../../../configs/axios/apiPath";
import { IconDelete, IconDetail } from "../../../../../../assets/commons";
import {
  IconDesc,
  IconApprovedOvertime,
  IconRequestOvertime,
  IconRejectedOvertime,
  IconVerifiedOvertime,
  IconDoneOvertime,
} from "../../../../../../assets/coreHr/overtime";
import { ModalDelete } from "../../../../modal";
import { TooltipsAction, Tooltips } from "../../../../../atoms";
import {
  handleTooltipClose,
  handleTooltipOpen,
} from "../../../../../../utils/function/tooltips";
import { handleAction } from "../../../../../../utils/function/action";
import { getCompany } from "../../../../../../features/companies";
import CoreHROvertimeDetail from "../../../../../../pages/coreHR/overtime/detail";
import { useLocation, useNavigate } from "react-router-dom";

const OvertimeTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [idEmployee, setIdEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState([]);
  const company = useSelector(getCompany);
  const [idTooltipDetail, setIdTooltipDetail] = useState("");
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);
  const [idTooltipDelete, setIdTooltipDelete] = useState("");
  const [isTooltipDelete, setIsTooltipDelete] = useState(false);
  const [idTooltipDept, setIdTooltipDept] = useState("");
  const [isTooltipDept, setIsTooltipDept] = useState(false);

  const StatusIconMap = {
    100: <IconRequestOvertime />,
    200: <IconApprovedOvertime />,
    201: <IconDoneOvertime />,
    202: <IconVerifiedOvertime />,
    301: <IconRejectedOvertime />,
  };
  useEffect(() => {
    async function fetchData() {
      if (company && idEmployee !== null) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getOvertimeById(idEmployee);
          setDetail(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }
    fetchData();
  }, [idEmployee]);

  // --- Handle Reject ---
  const handleReject = async (event) => {
    event.preventDefault();
    const data = {
      status: event.currentTarget.value,
    };
    setIsLoading(true);
    try {
      const response = await updateOvertimeById(data, detail?.id);

      if (response.data.meta.code === 20000) {
        setIsModalOpen(false);
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerified = async (event) => {
    event.preventDefault();
    const data = {
      status: event.currentTarget.value,
    };
    setIsLoading(true);
    try {
      const response = await updateOvertimeById(data, detail?.id);

      if (response.data.meta.code === 20000) {
        setIsModalOpen(false);
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Name Employee --- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "start",
                verticalAlign: "middle",
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"start"}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <img
                  src={item.employee.profile_picture}
                  alt={item.employee.name}
                  width={30}
                  style={{ borderRadius: "50%" }}
                />
                <Box ml={"10px"}>
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={400}
                    fontSize={"14px"}
                    lineHeight={"130%"}
                    color={"#171717"}
                  >
                    {item.employee.name}
                  </Typography>
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={600}
                    fontSize={"10px"}
                    lineHeight={"11px"}
                    letterSpacing={"0.01em"}
                    color={"#939393"}
                  >
                    {item.department}
                  </Typography>
                </Box>
              </Box>
            </td>

            {/* --- Date --- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {moment(item?.date).format("DD MMM")}
              </Typography>
            </td>
            {/* --- Time --- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item?.time}
              </Typography>
            </td>

            {/* --- Description --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Tooltips title={item.description} arrow placement={"bottom-end"}>
                <Box>
                  <IconDesc />
                </Box>
              </Tooltips>
            </td>
            {/* --- Status --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Box>{StatusIconMap[item.status]}</Box>
            </td>
            {/* --- Action --- */}
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                {/* --- Detail --- */}
                <TooltipsAction
                  title="Detail"
                  open={isTooltipDetail && item.id === idTooltipDetail}
                  arrow
                  placement={"bottom-end"}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        "detail"
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDetail,
                        setIdTooltipDetail
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDetail)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>

                {/* --- Delete --- */}
                <TooltipsAction
                  title="Delete"
                  open={isTooltipDelete && item.id === idTooltipDelete}
                  arrow
                  placement={"bottom-end"}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        "delete"
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDelete,
                        setIdTooltipDelete
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDelete)}
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
      {/* --- Modal Handle --- */}
      {company && idEmployee !== null && modal === "detail" ? (
        <CoreHROvertimeDetail
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          detail={detail}
          isLoading={isLoading}
          handleVerified={handleVerified}
          handleReject={handleReject}
        />
      ) : modal === "delete" ? (
        <ModalDelete
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={"Overtime"}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          id={idEmployee}
          data={tbody}
          setData={setData}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default OvertimeTbody;
