export const dataGridApplicants = [
    {
      title: 'Flutter Developer',
      name: 'Mahmudi Zukri',
      application: 'Application : January 10, 2023 | 16 : 23 : 13',
    },
    {
      title: 'UI/UX Designer',
      name: 'Astrid',
      application: 'Application : January 10, 2023 | 12 : 01 : 23',
    },
    {
      title: 'Backend Developer',
      name: 'Taufik kamil',
      application: 'Application : January 10, 2023 | 10 : 10 : 42',
    },
  ];
  