import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  IconClose,
  IconDetailForm,
  IconQuit,
} from "../../../../assets/commons";
import { Card, Modals } from "../../../../components/atoms";
import { getListEmployee } from "../../../../configs/axios/apiPath";
import { getCompany } from "../../../../features/companies";
import { getLanguage } from "../../../../features/language";
import { useEffect } from "react";
import {
  IconDate,
  IconEmployee,
  IconImage,
} from "../../../../assets/coreHr/awards";
import { ValidateString } from "../../../../utils/validate";
import {
  postCorrection,
  uploadImageCorrection,
} from "../../../../configs/axios/apiPath/fetchTimesheet/fetchTimesheetCorrection";
import DocumentUrl from "../../../../configs/url";

export default function AttendanceCorrectionAdd({
  isOpen,
  onClose,
  handleCorrectionAdded,
}) {
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [total, setTotal] = useState(1);
  const [isFocusEmployee, setIsFocusEmployee] = useState(false);
  const [isImageClick, setIsImageClick] = useState(false);
  const [images, setImages] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [isFocusGift, setIsFocusGift] = useState(false);
  const [isFocusDate, setIsFocusDate] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [type, setType] = useState("clock_in");

  const company = useSelector(getCompany);
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const placeHolderId = "Ketik disini";
  const placeHolderEn = "Type here";

  const MAX_IMAGE_SIZE = 1 * 1024 * 1024;
  const [errorMsg, setErrorMsg] = useState("");

  // --- Handle Upload Image ---
  const handleFileChange = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (file) {
      // Validasi tipe file
      if (!file.type.startsWith("image/")) {
        const msgId =
          "Jenis file tidak valid. Hanya file gambar yang diizinkan.";
        const msgEn = "Invalid file type. Only image files are allowed.";
        if (lang === "Id") {
          setErrorMsg(msgId);
        } else {
          setErrorMsg(msgEn);
        }
        return;
      }

      // Validasi ukuran file
      if (file.size > MAX_IMAGE_SIZE) {
        const msgId =
          "Ukuran file melebihi batas maksimum. Silakan pilih file yang lebih kecil.";
        const msgEn =
          "File size exceeds the maximum limit. Please choose a smaller file.";
        if (lang === "Id") {
          setErrorMsg(msgId);
        } else {
          setErrorMsg(msgEn);
        }
        return;
      }

      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("file", file);

        const { data } = await uploadImageCorrection(formData);

        const fullUrl = DocumentUrl(`${data?.data?.id}?width=50&height=50`);
        setDocumentId(data?.data?.id);
        setImages(fullUrl);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  // --- Hande Image Button ---
  const handleImageButton = () => {
    setErrorMsg("");
    setIsImageClick(true);
  };

  // --- Handle Delete Image ---
  const handleDeleteImage = () => {
    setImages("");
    setDocumentId("");
  };

  // --- Handle Submit ---
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      employee_id: selectedEmployee?.id,
      description,
      correction_date: date,
      document_id: documentId,
      type,
    };
    setIsLoading(true);
    if (company) {
      try {
        const response = await postCorrection(data);

        response.status === 200 && handleCorrectionAdded();
        setImages("");
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  // --- Handle Selected Employee ---
  const handleSelectEmployee = (event, value) => {
    setSelectedEmployee(value);
  };

  // --- List Employee ---
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          page_size: total,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListEmployee(params);

          setEmployeeData(data.employee);
          if (data.page_info.total > 0) {
            setTotal(data.page_info.total);
          }
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, total]);

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Card>
          <Box p={"30px"}>
            {/* --- Title --- */}
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={"7px"}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontSize={"16px"}
                fontWeight={600}
                lineHeight={"22px"}
                color={"#171717"}
              >
                {lang === "Id" ? "Koreksi Kehadiran" : "Attendance Correction"}
              </Typography>
              <IconButton sx={{ p: "0px" }} onClick={onClose}>
                <IconQuit />
              </IconButton>
            </Box>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ borderColor: "#c4c4c4" }}
            />

            <form onSubmit={handleSubmit} style={{ marginBottom: "17px" }}>
              <Box mt={"20px"}>
                {!isLoading ? (
                  <p
                    style={{ textAlign: "center", color: "red" }}
                    className={errMsg ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                ) : (
                  <Box textAlign={"center"} my={"5px"}>
                    <CircularProgress color="secondary" />
                  </Box>
                )}
              </Box>
              <Box mb={"20px"} mt={"20px"}>
                <Grid container columns={1} spacing={2}>
                  {/* --- Name --- */}
                  <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                    <Box display={"flex"} flexDirection={"column"} mb={"16px"}>
                      <InputLabel style={{ marginBottom: "5px" }}>
                        {lang === "Id" ? "Nama Karyawan" : "Employee Name"}
                      </InputLabel>
                      <Autocomplete
                        value={selectedEmployee}
                        onChange={handleSelectEmployee}
                        inputValue={inputValue}
                        onInputChange={handleInputChange}
                        options={employeeData}
                        getOptionLabel={(employee) => employee.name}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder={
                              lang === "Id" ? placeHolderId : placeHolderEn
                            }
                            onFocus={() => setIsFocusEmployee(false)}
                            onBlur={() => setIsFocusEmployee(true)}
                            InputLabelProps={{ shrink: false }}
                            sx={{
                              background: "#f9f9f9",
                              "& .MuiOutlinedInput-root": {
                                background: "transparent",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "transparent",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "transparent",
                                },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "transparent",
                              },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  sx={{ pl: "7px" }}
                                >
                                  {isLoading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : (
                                    isFocusEmployee && <IconEmployee />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Grid>

                  {/* --- Date --- */}
                  <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                    <Box display={"flex"} flexDirection={"column"} mb={"16px"}>
                      <InputLabel
                        style={{
                          fontFamily: "sans-serif",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "13px",
                          lineHeight: "18px",
                          letterSpacing: "1px",
                          marginBottom: "6px",
                        }}
                      >
                        {lang === "Id" ? "Tanggal" : "Date"}
                      </InputLabel>
                      <TextField
                        fullWidth
                        required
                        type="date"
                        size="small"
                        variant="outlined"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        onFocus={() => setIsFocusDate(false)}
                        onBlur={() => setIsFocusDate(true)}
                        sx={{
                          background: "#f9f9f9",
                          "& .MuiOutlinedInput-root": {
                            background: "transparent",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent",
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {isFocusDate ? <IconDate /> : ""}
                            </InputAdornment>
                          ),
                          style: {
                            height: "40px",
                            paddingLeft: "15px",
                            paddingRight: "15px",
                          },
                        }}
                      />
                    </Box>
                  </Grid>

                  {/* --- Type --- */}
                  <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                    <Box display={"flex"} flexDirection={"column"} mb={"16px"}>
                      <InputLabel
                        style={{
                          fontFamily: "sans-serif",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "13px",
                          lineHeight: "18px",
                          letterSpacing: "1px",
                          marginBottom: "6px",
                        }}
                      >
                        {lang === "Id" ? "Tipe" : "Type"}
                      </InputLabel>
                      <Select
                        value={type}
                        displayEmpty
                        size="small"
                        sx={{
                          background: "#f9f9f9",
                          "& .MuiOutlinedInput-root": {
                            background: "transparent",
                            "& fieldset": {
                              borderColor: "transparent",
                            },
                            "&:hover fieldset": {
                              borderColor: "transparent",
                            },
                            "&:hover:not(.Mui-disabled) fieldset": {
                              borderColor: "transparent",
                            },
                            "&.Mui-disabled fieldset": {
                              borderColor: "transparent",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "transparent",
                            },
                          },
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <MenuItem value={"clock_in"}>CLOCK IN</MenuItem>
                        <MenuItem value={"clock_out"}>CLOK OUT</MenuItem>
                        <MenuItem value={"start_break"}>START BREAK</MenuItem>
                        <MenuItem value={"end_break"}>END BREAK</MenuItem>
                      </Select>
                    </Box>
                  </Grid>

                  {/* --- Detail --- */}
                  <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                    <Box display={"flex"} flexDirection={"column"} mb={"16px"}>
                      <InputLabel
                        style={{
                          fontFamily: "sans-serif",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "13px",
                          lineHeight: "18px",
                          letterSpacing: "1px",
                          marginBottom: "6px",
                        }}
                      >
                        {lang === "Id" ? "Deskripsi" : "Description"}
                      </InputLabel>
                      <TextField
                        fullWidth
                        required
                        variant="outlined"
                        size="small"
                        placeholder={
                          lang === "Id" ? placeHolderId : placeHolderEn
                        }
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        onFocus={() => setIsFocusGift(false)}
                        onBlur={() => setIsFocusGift(true)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {isFocusGift ? <IconDetailForm /> : ""}
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            height: {
                              xl: "60px",
                              lg: "45px",
                              md: "45px",
                              sm: "60px",
                              xs: "60px",
                            },
                            borderRadius: {
                              xl: "16px",
                              lg: "10px",
                              md: "16px",
                              sm: "16px",
                              xs: "16px",
                            },
                            background: "#f9f9f9",
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "transparent",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "transparent",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "transparent",
                            },
                          },
                        }}
                      />
                    </Box>
                  </Grid>

                  {/* --- Document --- */}
                  <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                    <Box display={"flex"} flexDirection={"column"} mb={"16px"}>
                      <InputLabel style={{ marginBottom: "5px" }}>
                        {lang === "Id" ? "Dokumen" : "Document"}
                      </InputLabel>
                      <Grid container columns={2} spacing={2}>
                        <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                          <TextField
                            fullWidth
                            required
                            variant="outlined"
                            size="small"
                            placeholder={
                              lang === "Id" ? placeHolderId : placeHolderEn
                            }
                            value={documentId}
                            onChange={(e) => setDocumentId(e.target.value)}
                            disabled
                            sx={{
                              background: "#f9f9f9",
                              "& .MuiOutlinedInput-root": {
                                background: "transparent",
                                "& fieldset": {
                                  borderColor: "transparent",
                                },
                                "&:hover fieldset": {
                                  borderColor: "transparent",
                                },
                                "&:hover:not(.Mui-disabled) fieldset": {
                                  borderColor: "transparent",
                                },
                                "&.Mui-disabled fieldset": {
                                  borderColor: "transparent",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "transparent",
                                },
                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {isImageClick ? <IconImage /> : ""}
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Button
                                    variant="contained"
                                    disabled={isLoading}
                                    component="label"
                                    onClick={handleImageButton}
                                    sx={{
                                      background: "#fff",
                                      borderRadius: "8px",
                                      my: "5px",
                                      color: "#171717",
                                      fontWeight: "400px",
                                      fontSize: "12px",
                                      lineHeight: "14px",
                                      letterSpacing: "0.02em",
                                    }}
                                  >
                                    {lang === "Id" ? "Unggah" : "Browse"}
                                    <input
                                      hidden
                                      accept="image/*"
                                      multiple
                                      type="file"
                                      onChange={handleFileChange}
                                    />
                                  </Button>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={2}
                          xs={2}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <Box ml={"10px"}>
                            {ValidateString(images) ? (
                              <Box position="relative" width={50}>
                                <img
                                  src={images}
                                  alt="image"
                                  width={50}
                                  height={50}
                                  style={{ borderRadius: "8px" }}
                                />
                                <IconButton
                                  size="small"
                                  onClick={() => handleDeleteImage()}
                                  sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    backgroundColor: "white",
                                    p: "0px",
                                  }}
                                >
                                  <IconClose />
                                </IconButton>
                              </Box>
                            ) : isLoading ? (
                              <Box textAlign={"center"} my={"5px"}>
                                <CircularProgress color="warning" size={30} />
                              </Box>
                            ) : (
                              <Box>
                                <Typography
                                  fontFamily={"sans-serif"}
                                  fontStyle={"normal"}
                                  fontWeight={600}
                                  fontSize={"10px"}
                                  lineHeight={"14px"}
                                  letterSpacing={"1px"}
                                  color={"#171717"}
                                >
                                  {lang === "Id"
                                    ? "Tidak ada yg dipilih"
                                    : "No File Choosen"}
                                </Typography>
                                <Typography
                                  fontFamily={"sans-serif"}
                                  fontStyle={"normal"}
                                  fontWeight={400}
                                  fontSize={"8px"}
                                  lineHeight={"11px"}
                                  letterSpacing={"1px"}
                                  color={"#303030"}
                                >
                                  {lang === "Id"
                                    ? "Unggah hanya file png, jpg, jpeg dan ukuran maksimal 1Mb"
                                    : "Upload files only png, jpg, jpeg and size 1Mb"}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                      {isImageClick && !ValidateString(images) && (
                        <Typography
                          fontFamily={"sans-serif"}
                          fontStyle={"normal"}
                          fontWeight={400}
                          fontSize={"12px"}
                          lineHeight={"15px"}
                          letterSpacing={"1px"}
                          color={"red"}
                          my={"5px"}
                        >
                          {lang === "Id" ? "Harus diisi" : "Can't Be Empty"}
                        </Typography>
                      )}
                      {errorMsg && (
                        <Typography
                          fontFamily={"sans-serif"}
                          fontStyle={"normal"}
                          fontWeight={400}
                          fontSize={"12px"}
                          lineHeight={"15px"}
                          letterSpacing={"1px"}
                          color={"red"}
                          my={"5px"}
                        >
                          {errorMsg}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
                <Button
                  variant="contained"
                  disableRipple
                  type="submit"
                  sx={{
                    width: "73px",
                    height: "26px",
                    verticalAlign: "middle",
                  }}
                >
                  <Typography
                    fontSize={"10px"}
                    fontWeight={700}
                    lineHeight={"14px"}
                    letterSpacing={"1px"}
                    color={"#fff"}
                    textTransform={"none"}
                  >
                    {lang === "Id" ? "Tambahkan" : "Submit"}
                  </Typography>
                </Button>
              </Box>
            </form>
          </Box>
        </Card>
      </Box>
    </Modals>
  );
}
