import React from 'react';
import { Box, Typography } from '@mui/material';
import { Card } from '../../../atoms';

const CardProject = ({ icon, title, children }) => {
  return (
    <Card>
      <Box sx={{ p: '10px 20px' }}>
        <Typography
          fontWeight={'600'}
          fontSize={'16'}
          letterSpacing={'0.01rem'}
          lineHeight={'22px'}
          color={'#171717'}
        >
          {title}
        </Typography>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box>{children}</Box>
          <Box>{icon}</Box>
        </Box>
      </Box>
    </Card>
  );
};

export default CardProject;
