import { createSlice } from '@reduxjs/toolkit';

const payrollIdSlice = createSlice({
  name: 'payrollId',
  initialState: {
    id: 0,
  },
  reducers: {
    setPayrollId: (state, action) => {
      state.id = action.payload;
    },
  },
});

export const { setPayrollId } = payrollIdSlice.actions;
export const getPayrollId = (state) => state.payrollId.id;

export default payrollIdSlice.reducer;
