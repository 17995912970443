import { Box, Button, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, RowPerPage, Search } from "../../../../components/atoms";
import TableComponent from "../../../../components/molecules/table/mainTable";
import { getListEmployees } from "../../../../configs/axios/apiPath";
import { getLanguage } from "../../../../features/language";
import { dataTableEmployeeManage } from "../../../../data/table/employee/employeeManage";
import { dataEmployeeFooter } from "../../../../data/footer";
import { getCompany } from "../../../../features/companies";
import {
  TableManageEmployeeTbody,
  TableEmployeeManageThead,
} from "../../../../components/organisms/employee/employeeManage";
import { AddingEmployee } from "../../../../assets/employee";

export default function MainEmployeeManageDashboard({ onChangeView }) {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sortir, setSortir] = useState("name");
  const [lastPage, setLastPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const company = useSelector(getCompany);
  const navigate = useNavigate();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataEmployee, setDataEmployee] = useState([]);
  const [name, setName] = useState("");
  const [dept, setDept] = useState("");
  const [status, setStatus] = useState("");
  const [position, setPosition] = useState("");
  const [service, setService] = useState("");

  const lang = useSelector(getLanguage);

  const refreshCurrentData = (id, active) => {
    setDataEmployee(
      dataEmployee.map((a) => (a.id === id ? { ...a, is_active: active } : a))
    );
  };

  const fetchData = async () => {
    if (company) {
      const params = {
        page: page,
        page_size: pageSize,
        company_id: company,
        search: search,
        position: position,
        service: service,
        name: name,
        dept: dept,
        status: status,
      };
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await getListEmployees(params);
        setLastPage(data.page_info.last_page);
        setDataEmployee(data.employees);
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem("data");
          navigate(`/`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  // --- Fetch Employee --
  useEffect(() => {
    fetchData();
  }, [
    page,
    rowsPerPage,
    company,
    search,
    position,
    service,
    name,
    dept,
    status,
    pageSize,
  ]);

  const last = [];
  for (let i = 1; i <= lastPage; i++) {
    last.push(i);
  }

  return (
    <Box display={"flex"} flexDirection={"column"}>
      {/* --- Heading --- */}
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Search
          placeholder="Type here to search"
          setSearch={(e) => setSearch(e)}
          page={page}
          setPage={(e) => setPage(e)}
        />
        <Button
          variant="contained"
          disableRipple
          onClick={() => onChangeView("add-employee")}
          sx={{ height: "35px", padding: "8px 12px" }}
        >
          <AddingEmployee />
          <Typography
            fontSize={"12px"}
            fontWeight={"700px"}
            lineHeight={"13.62"}
            letterSpacing={"0.02em"}
            textTransform={"none"}
          >
            Employee
          </Typography>
        </Button>
      </Box>
      {/* --- Table --- */}
      <Box mt={"24px"} mb={"16px"}>
        <TableComponent
          isLoading={isLoading}
          tbody={dataEmployee}
          TableHead={
            <TableEmployeeManageThead
              thead={dataTableEmployeeManage}
              sortir={sortir}
              setSortir={setSortir}
            />
          }
          TableBody={
            <TableManageEmployeeTbody
              refreshCurrentData={refreshCurrentData}
              tbody={dataEmployee}
            />
          }
          col={7}
        />
      </Box>
      {/* --- Footers --- */}
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {/* --- Icon Footers --- */}
        <Box display={"flex"} alignItems={"center"}>
          {dataEmployeeFooter.map((item, idx) => (
            <Box display={"flex"} alignItems={"center"} key={idx} mr={"20px"}>
              <IconButton sx={{ p: "0px", mr: "6px" }}>{item.icon}</IconButton>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"12px"}
                lineHeight={"130%"}
                color="#171717"
              >
                {lang === "Id" ? item.judul : item.title}
              </Typography>
            </Box>
          ))}
        </Box>
        {/* --- Row Per Page --- */}
        <Box display={"flex"} justifyContent={"end"}>
          <RowPerPage
            setPageSize={setPageSize}
            pageSize={pageSize}
            setPage={setPage}
          />
          <Pagination page={page} setPage={setPage} lastPage={lastPage} />
        </Box>
      </Box>
    </Box>
  );
}
