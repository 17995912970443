export const MenuEmployeeDetail = [
  {
    id: 0,
    title: 'Profile',
    judul: 'Profil',
  },
  {
    id: 1,
    title: 'Attendance',
    judul: 'Kehadiran',
  },
  {
    id: 2,
    title: 'Shift',
    judul: 'Shift',
  },
  {
    id: 3,
    title: 'Benefit',
    judul: 'Benefit',
  },
  // {
  //   id: 4,
  //   title: 'Performance',
  //   judul: 'Performa',
  // },
];
