import { Box, Divider, Typography } from "@mui/material"
import { BackgroundBoards } from "../../../../../assets/project/img"
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ActivityLog from "../../../../molecules/log/activity";
import MenuAttachmentFileOfDiscussion from "../../../../molecules/menu/project/menuAttachmentFileOfDiscussion";
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { listDiscussion } from "../../../../../configs/axios/apiPath/fetchProject";
import countSizeOfFiles from "../../../../../utils/format/countSizeOfFiles";

const SideDiscussion = ({dataProject}) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [startDate, setStartDate] = useState('2023-06-17');
  const [endDate, setEndDate] = useState('2023-06-17');
  const [detailProject, setDetailProject] = useState([])
  const [memberProject, setMemberProject] = useState([]);
  const [attachment, setAttachment] = useState([])

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      try {
        const params = {
          start_date: startDate,
          end_date: endDate,
          include: "project,attachments",
          page_size: pageSize
        }
        const {
          data: {data},
        } = await listDiscussion(dataProject.id, params)
        setDetailProject(data?.project)
        setMemberProject(data?.project?.assignee)
        setAttachment(data?.attachments)
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false)
      }
    }
    fetchData();
  },[dataProject.id, startDate, endDate, pageSize, navigate]) 

  return(
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={{
        width: 'full', 
        height: '100%',
        backgroundColor: 'white',
      }}
    >
      <Box padding={'20px'} width={'full'} textAlign={'center'}>
        <img
          alt="test"
          src={BackgroundBoards}
          width={'80px'}
          height={'80px'}
          loading="lazy"
          style={{borderRadius: '50%'}}
        />
        <Typography
          sx={{
            marginTop: '20px',
            fontWeight: '600',
            fontSize: '20px',
            color: '#171717'
          }}
        >
          {detailProject?.name}
        </Typography>
        <Typography
          sx={{
            marginTop: '10px',
            fontWeight: '400',
            fontSize: '12px',
            color: '#171717'
          }}
        >
          {detailProject?.detail}
        </Typography>
      </Box>

      <Box>
        <Box 
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'full'}
          paddingX={'20px'}
          paddingY={'10px'}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '16px',
                color: '#171717',
              }}
            >
              Members
            </Typography>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '12px',
                color: '#939393',
              }}
            >
              {`${memberProject?.length} members`}
            </Typography>
          </Box>
          <KeyboardArrowDownOutlinedIcon/>
        </Box>

        {
          memberProject?.map((item, idx) => (
            <Box key={idx}>
              <Box
                width={'full'}
                paddingX={'20px'}
                paddingY={'10px'}
              >
                <ActivityLog
                  picture={item.profile_picture}
                  name={item.name}
                  time={item.position.name}
                />
              </Box>
              <Divider/>
            </Box>
          ))
        }
        
        <Typography
          sx={{
            marginTop: '20px',
            fontWeight: '600',
            fontSize: '12px',
            color: '#51B15C',
            paddingX: '20px',
          }}
        >
          See more
        </Typography>
      </Box>

      <Box marginTop={'20px'}>
        <Box 
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={'full'}
          paddingX={'20px'}
          paddingY={'10px'}
        >
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '16px',
              color: '#171717',
            }}
          >
            Attachment
          </Typography>
          <KeyboardArrowDownOutlinedIcon/>
        </Box>
      </Box>

      <MenuAttachmentFileOfDiscussion
        picture={<TextSnippetOutlinedIcon/>}
        title={'Document'}
        subtitile={`${attachment?.document?.length ?? 0} files - ${countSizeOfFiles(attachment?.document ?? [])}MB`}
        data={attachment.document}
      />
      <MenuAttachmentFileOfDiscussion
        picture={<InsertPhotoOutlinedIcon/>}
        title={'Photo'}
        subtitile={`${attachment?.photo?.length ?? 0} files - ${countSizeOfFiles(attachment?.photo ?? [])}MB`}
        data={attachment.photo}
      />
      <MenuAttachmentFileOfDiscussion
        picture={<PlayCircleOutlinedIcon/>}
        title={'Video'}
        subtitile={`${attachment?.video?.length ?? 0} files - ${countSizeOfFiles(attachment?.video ?? [])}MB`}
        data={attachment.video}
      />
      <MenuAttachmentFileOfDiscussion
        picture={<AttachmentOutlinedIcon/>}
        title={'Links'}
        subtitile={`${attachment?.links?.length ?? 0} files`}
        data={attachment.links}
      />            

    </Box>
  )
}

export default SideDiscussion