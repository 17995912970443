import { Box, Button, Typography } from '@mui/material';
import {  useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {  useEffect, useState } from 'react';
import moment from 'moment';
import { IconDelete, IconDetail } from '../../../../../../assets/commons';
import { IconResign, IconTerminate } from '../../../../../../assets/coreHr/resign';
import { Tooltips, TooltipsAction } from '../../../../../atoms';
import { IconDescription } from '../../../../../../assets/coreHr/promotion';
import { capitalizeFirstLetter } from '../../../../../../utils/format';
import { handleTooltipClose, handleTooltipOpen } from '../../../../../../utils/function/tooltips';
import { getLanguage } from '../../../../../../features/language';
import { handleAction } from '../../../../../../utils/function/action';
import { ModalDelete } from '../../../../modal';
import CoreHRResignDetail from '../../../../../../pages/coreHR/resign/detail';
import { getResignById } from '../../../../../../configs/axios/apiPath';

const ResignTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [idEmployee, setIdEmployee] = useState(null);
  const [detail, setDetail] = useState([])

  const [idTooltip, setIdTooltip] = useState('');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [idTooltipDetail, setIdTooltipDetail] = useState('');
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);
  const [idTooltipDelete, setIdTooltipDelete] = useState('');
  const [isTooltipDelete, setIsTooltipDelete] = useState(false);
  const [idTooltipType, setIdTooltipType] = useState('');
  const [isTooltipType, setIsTooltipType] = useState(false);

  // --- Handle Detail ---
  useEffect(() => {
    async function fetchData() {
      if (idEmployee !== null) {
        setIsLoading(true)
        try {
          const {
            data: {data}
          } = await getResignById(idEmployee);

          setDetail(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false)
        }
      }
    }

    fetchData();
  }, [idEmployee]);

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Employee Name --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'start',
                verticalAlign: 'middle',
              }}
            >
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'start'}
              >
                <Box display={'flex'} alignItems={'center'}>
                  <img
                    src={item?.profile_picture}
                    width={30}
                    style={{ borderRadius: '50%' }}
                    alt={item.name}
                  />
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontWeight={item.notice_status === 0 ? 400 : 600}
                    fontSize={'14px'}
                    lineHeight={'130%'}
                    color={item.notice_status === 0 ? '#171717' : '#CF8812'}
                    ml={'10px'}
                  >
                    {item.name}
                  </Typography>
                </Box>
                {/* <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={() => setOpen(false)}
                  open={track && item.id === id}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={item.note}
                  placement="bottom-start"
                  sx={{
                    '& .css-6jmnki-MuiTooltip-tooltip': {
                      backgroundColor: 'white',
                      width: '200px',
                      height: '160px',
                    },
                  }}
                >
                  <IconButton
                    sx={{ p: '0px' }}
                    onClick={() => handleTrackOpen(item.id)}
                  >
                    <IconNotif />
                  </IconButton>
                </Tooltip> */}
              </Box>
            </td>
            {/* --- Resign Type --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
               <TooltipsAction
                title={
                  item.resign_type === 200 ? (
                    lang === 'Id'? 'Mengakhiri': 'Terminate'
                  ) : item.resign_type === 100 ? (
                    lang === 'Id'? 'Berhenti': 'Resign'
                  ) : (
                    ''
                  )
                }
                open={isTooltipType && item.id === idTooltipType}
                arrow
                placement={'bottom-end'}
              >
                <Button 
                  onMouseEnter={() => handleTooltipOpen(item.id, setIsTooltipType, setIdTooltipType )} 
                  onMouseLeave={()=>handleTooltipClose(setIsTooltipType)}
                >
                  {item.resign_type === 200 ? (
                    <IconTerminate />
                  ) : item.resign_type === 100 ? (
                    <IconResign />
                  ) : (
                    ''
                  )}
                </Button>
              </TooltipsAction>
            </td>
            
            {/* --- Resign Date --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {moment(item.resignation_date).format('D MMM')}
              </Typography>
            </td>
            {/* --- Note --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Tooltips
                title={item.note}
                open={isTooltipOpen && item.id === idTooltip}
                arrow
                placement={'bottom-end'}
              >
                <Button 
                  onMouseEnter={() => handleTooltipOpen(item.id, setIsTooltipOpen, setIdTooltip )} 
                  onMouseLeave={()=>handleTooltipClose(setIsTooltipOpen)}
                >
                  <IconDescription />
                </Button>
              </Tooltips>
            </td>
            {/* --- Status --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Box display={'flex'} justifyContent={'center'}>
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={600}
                  fontSize={'10px'}
                  lineHeight={'11px'}
                  letterSpacing={'0.02rem'}
                  paddingY={'6px'}
                  borderRadius={'6px'}
                  width={'88px'}
                  textAlign={'center'}
                  style={{
                    backgroundColor:
                      item.status === 'acknowledged'
                        ? 'rgba(81, 177, 92, 0.2)'
                        : item.status === 'pending'
                        ? 'rgba(207, 136, 18, 0.2)'
                        : item.status === 'canceled'
                        ? 'rgba(255, 29, 29, 0.2)'
                        : 'transparent',
                    color:
                      item.status === 'acknowledged'
                        ? '#51B15C'
                        : item.status === 'pending'
                        ? '#CF8812'
                        : item.status === 'canceled'
                        ? '#FF1D1D'
                        : '#171717',
                  }}
                >
                  {capitalizeFirstLetter(item?.status)}
                </Typography>
              </Box>
            </td>
            {/* --- Action --- */}
            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
            <Box 
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={1}
              >
                {/* --- Detail --- */}
                <TooltipsAction
                  title='Detail'
                  open={isTooltipDetail && item.id === idTooltipDetail}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{cursor: 'pointer'}}
                    onClick={() => handleAction(item.id, setIsModalOpen, setModal, setIdEmployee, 'detail')}
                    onMouseEnter={() => handleTooltipOpen(item.id, setIsTooltipDetail, setIdTooltipDetail )} 
                    onMouseLeave={()=>handleTooltipClose(setIsTooltipDetail)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>

                {/* --- Delete --- */}
                <TooltipsAction
                  title='Delete'
                  open={isTooltipDelete && item.id === idTooltipDelete}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{cursor: 'pointer'}}
                    onClick={() => handleAction(item.id, setIsModalOpen, setModal, setIdEmployee, 'delete')}
                    onMouseEnter={() => handleTooltipOpen(item.id, setIsTooltipDelete, setIdTooltipDelete )} 
                    onMouseLeave={()=>handleTooltipClose(setIsTooltipDelete)}
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
      {/* --- Modal Handle --- */}
      {idEmployee !== null &&
        modal === 'detail' ? (
          <CoreHRResignDetail
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            detail={detail}
            isLoading={isLoading}
            id={idEmployee}
            setIsLoading={setIsLoading}
          />
        ) : modal === 'delete' ? (
          <ModalDelete
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            title={'Resign'}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            id={idEmployee}
            data={tbody}
            setData={setData}
          />
        ) : (
          ''
        )
      }
    </>
  );
};

export default ResignTbody;
