import { IconJobs, IconRequest, IconCandidate} from "../../../assets/recruitment/menuNavigation"

const MenuRecruitment = [
  {
    id: 0,
    title: "Request",
    judul: "Permintaan",
    icon: <IconRequest />,
  },
  {
    id: 1,
    title: "Vacancies",
    judul: "Pekerjaan",
    icon: <IconJobs />,
  },
  {
    id: 2,
    title: "Candidate",
    judul: "Kandidat",
    icon: <IconCandidate />,
  },
];

export default MenuRecruitment;
