import React, { useState, useEffect } from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCompany } from '../../../../../features/companies';
import { getEmployeePerformance } from '../../../../../configs/axios/apiPath';
import CardCompany from '../../cardEmployee/cardCompany';
import Chart from 'react-google-charts';
import NoteHandle from '../../cardEmployee/noteHandle';
import { ScheduleOptions } from '../../cardEmployee/employee/Schedule';
import { DataEmployeeCompany } from '../../cardEmployee/employeeCompany';
import { ZeroOptions } from '../../cardEmployee/employee/Zero';
import { CompanyOptions } from '../../cardEmployee/employee/Company';

export default function PerformancePieCard() {
  const [dataDept, setDataDept] = useState([]);
  const [dataShift, setDataShift] = useState([]);
  const [shift, setShift] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const [totalShift, setTotalShift] = useState(0);
  const [totalDept, setTotalDept] = useState(0);

  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          company_id: company,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getEmployeePerformance(params);
          const shifts = data.shifts;
          setShift(shifts);
          handleTotalShift(shifts);

          const depts = data.departments;
          handleTotalDept(depts);
          setIsLoading(true);

          const dataShift = [['Shift', 'Employee']];
          shifts.forEach((shift) => {
            dataShift.push([shift.name, shift.total]);
          });

          setDataShift(dataShift);

          const dataDept = [['Departments', 'Employee']];
          depts.forEach((dept) => {
            dataDept.push([dept.name, dept.employee_count]);
          });

          setDataShift(dataShift);
          setDataDept(dataDept);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('data');
            navigate(`/`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [company]);

  const handleTotalShift = (item) => {
    let total = 0;
    for (const shift of item) {
      total += shift.total;
    }
    setTotalShift(total);
  };

  const handleTotalDept = (item) => {
    let total = 0;
    for (const dept of item) {
      total += dept.total_employee;
    }
    setTotalDept(total);
  };

  return (
    <Box mt={'30px'}>
      <Grid container columns={2} spacing={2}>
        <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
          <CardCompany title="Schedule">
            {!isLoading ? (
              totalShift > 0 ? (
                <Box>
                  <Chart
                    chartType="PieChart"
                    data={dataShift}
                    options={ScheduleOptions}
                  />
                  <NoteHandle data={shift} />
                </Box>
              ) : dataShift.length > 0 ? (
                <Box justifyContent={'space-between'}>
                  <Chart
                    chartType="PieChart"
                    data={DataEmployeeCompany}
                    options={ZeroOptions}
                  />
                  <NoteHandle data={shift} />
                </Box>
              ) : (
                <Box>
                  <Chart
                    chartType="PieChart"
                    data={dataShift}
                    // options={ScheduleOptions}
                  />
                </Box>
              )
            ) : (
              <Skeleton variant="rectangular" width={'100%'} height={60} />
            )}
          </CardCompany>
        </Grid>
        <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
          <CardCompany title="Dept">
            {/* {!isLoading ? (
                        totalShift > 0 ? (
                        <Box>
                            <Chart
                            chartType="PieChart"
                            data={dataDept}
                            options={CompanyOptions}
                            />
                        </Box>
                        ) : dataDept.length > 1 ? (
                        <Box>
                            <Chart
                            chartType="PieChart"
                            data={DataEmployeeCompany}
                            options={ZeroOptions}
                            />
                        </Box>
                        ) : (
                        <Box>
                            <Chart
                            chartType="PieChart"
                            data={dataDept}
                            options={ScheduleOptions}
                            />
                        </Box>
                        )
                        ) : (
                        <Skeleton variant="rectangular" width={'100%'} height={60} />
                        )} */}
            <Chart
              chartType="PieChart"
              data={dataDept}
              options={CompanyOptions}
            />
          </CardCompany>
        </Grid>
      </Grid>
    </Box>
  );
}
