export const dataEmployeeAttendanceFooter = [
  {
    title: 'Present',
    judul: 'Hadir',
  },
  {
    title: 'Not Present',
    judul: 'Tidak Hadir',
  },
  {
    title: 'Permission',
    judul: 'Izin',
  },
  {
    title: 'Holiday',
    judul: 'Libur',
  },
  {
    title: 'Late',
    judul: 'Terlambat',
  },
];
