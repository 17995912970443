import {
  IconEmployee,
  IconEmployeeLeave,
  IconNewEmployee,
} from '../../../assets/employee';

const DataPerformanceCard = [
  {
    title: 'Employee',
    judul: 'Karyawan',
    icon: <IconEmployee />,
  },
  {
    title: 'New Employee',
    judul: 'Karyawan Baru',
    icon: <IconNewEmployee />,
  },
  {
    title: 'Employee Leave',
    judul: 'Cuti Karyawan',
    icon: <IconEmployeeLeave />,
  },
];

export default DataPerformanceCard;
