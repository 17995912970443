import React, { useEffect, useState } from "react";
import DataTooltips from "../../../homepage/homepageAttendance/attendanceChart/dataTooltips";
import { Card, LineChart, DatePicker } from "../../../../atoms";
import SelectFrequency from "../../../../organisms/homepage/homepageAttendance/selectFrequency";
import { Box, Typography, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCompany } from "../../../../../features/companies";
import { getSummaryVacancy } from "../../../../../configs/axios/apiPath";
import dayjs from "dayjs";

const ChartSumarry = () => {
  const company = useSelector(getCompany);

  const [isLoading, setIsLoading] = useState(false);
  const [frequency, setFrequency] = useState("Daily");
  const [dataChart, setDataChart] = useState({ labels: [], datasets: [] });
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      if (company && startDate && endDate) {
        const params = {
          company_id: company,
          start_date: dayjs(startDate).format("YYYY-MM-DD"),
          end_date: dayjs(endDate).format("YYYY-MM-DD"),
          timeframe: frequency.toLowerCase(),
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getSummaryVacancy(params);
          setDataChart(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [company, endDate, frequency, navigate, startDate]);

  const dataTooltips = DataTooltips;
  return (
    <Card sx={{ padding: "20px  25px", mt: "20px" }}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box sx={{ display: "flex" }}>
          <Typography fontWeight={600} fontSize={20}>
            Summarie
          </Typography>
          <Typography fontWeight={600} fontSize={20} sx={{ color: "#939393" }}>
            (s)
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center" gap="6px">
          <SelectFrequency
            value={frequency}
            onChange={(value) => setFrequency(value)}
          />
          <DatePicker
            onChange={(dateRange) => setDateRange(dateRange)}
            value={dateRange}
          />
        </Stack>
      </Box>
      <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
        <LineChart {...dataChart} />
      </Box>
    </Card>
  );
};

export default ChartSumarry;
