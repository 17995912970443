import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconCalendar, IconQuit } from "../../../../assets/commons";
import { Card, Modals } from "../../../../components/atoms";
import { postPayrollPeriods } from "../../../../configs/axios/apiPath";
import { getCompany } from "../../../../features/companies";
import { getLanguage } from "../../../../features/language";
import { dateFormat } from "../../../../utils/format";

export default function PayrollListAdd({
  isOpen,
  onClose,
  data,
  setData,
  notifyChangeData,
}) {
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [workingDay, setWorkingDay] = useState("26");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const company = useSelector(getCompany);
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      name: name,
      started_at: dateFormat(startDate),
      ended_at: dateFormat(endDate),
      company_id: company,
      working_day_count: workingDay,
    };
    setIsLoading(true);
    if (company) {
      try {
        const response = await postPayrollPeriods(data);
        if (response.data.meta.code === 20000) {
          notifyChangeData();
          onClose();
          // const newData = [...data];
          // const filteredData = newData.filter(item => item.id !== id);
          // setData(filteredData);
        }
      } catch (error) {
        console.error(error);
        const errorMessage = "End date must be greater than start date";
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        } else {
          setErrMsg(errorMessage);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Card>
          <Box p={"30px"}>
            {/* --- Title --- */}
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={"7px"}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontSize={"16px"}
                fontWeight={600}
                lineHeight={"22px"}
                color={"#171717"}
              >
                {lang === "Id"
                  ? "Tambahkan Periode Pembayaran"
                  : "Add Payroll Period"}
              </Typography>
              <IconButton sx={{ p: "0px" }} onClick={onClose}>
                <IconQuit />
              </IconButton>
            </Box>
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ borderColor: "#c4c4c4" }}
            />

            <form onSubmit={handleSubmit} style={{ marginBottom: "17px" }}>
              <Box mt={"20px"}>
                {!isLoading ? (
                  <p
                    style={{ textAlign: "center", color: "red" }}
                    className={errMsg ? "errmsg" : "offscreen"}
                    aria-live="assertive"
                  >
                    {errMsg}
                  </p>
                ) : (
                  <Box textAlign={"center"} my={"5px"}>
                    <CircularProgress color="secondary" />
                  </Box>
                )}
              </Box>
              <Box mb={"20px"} mt={"20px"}>
                {/* --- Name --- */}
                <Grid container columns={2} spacing={2}>
                  <Grid item xl={1} xs={1}>
                    <InputLabel style={{ marginBottom: "5px" }}>
                      {lang === "Id" ? "Nama" : "Name"}
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Grid>
                  {/* --- Start Date --- */}
                  <Grid item xl={1} xs={1}>
                    <InputLabel style={{ marginBottom: "5px" }}>
                      {lang === "Id" ? "Tanggal Dimulai" : "Start Date"}
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="date"
                      required
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box mb={"20px"}>
                {/* --- End Date --- */}
                <Grid container columns={2} spacing={2}>
                  <Grid item xl={1} xs={1}>
                    <InputLabel style={{ marginBottom: "5px" }}>
                      {lang === "Id" ? "Tanggal Berakhir" : "End Date"}
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      required
                      value={endDate}
                      type="date"
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Grid>
                  {/* --- Working Day --- */}
                  <Grid item xl={1} xs={1}>
                    <InputLabel style={{ marginBottom: "5px" }}>
                      {lang === "Id" ? "Hari Kerja" : "Working Day"}
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      fullWidth
                      disabled
                      required
                      value={workingDay}
                      type="number"
                      onChange={(e) => setWorkingDay(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{ paddingRight: "3px" }}
                          >
                            <IconCalendar />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box display={"flex"} justifyContent={"end"} mt={"20px"}>
                <Button
                  variant="contained"
                  disableRipple
                  type="submit"
                  sx={{
                    width: "73px",
                    height: "26px",
                    verticalAlign: "middle",
                  }}
                >
                  <Typography
                    fontSize={"10px"}
                    fontWeight={700}
                    lineHeight={"14px"}
                    letterSpacing={"1px"}
                    color={"#fff"}
                    textTransform={"none"}
                  >
                    {lang === "Id" ? "Tambahkan" : "Submit"}
                  </Typography>
                </Button>
              </Box>
            </form>
          </Box>
        </Card>
      </Box>
    </Modals>
  );
}
