import { LinearProgress, styled } from "@mui/material"

const ProgressBarStyled = ({progress, status}) => {
  const StyledBarProgress = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
  }));

  const val = Math.round(progress)

  const handleStatus = () => {
    if (status === 'done') {
      return 'success';
    } else if (status === 'process') {
      return 'warning';
    } else if (status === 'delayed') {
      return 'info';
    } else if (status === 'canceled') {
      return 'error';
    }
  };
  
  return(
    <StyledBarProgress
      variant="determinate" 
      value={val}
      color={handleStatus()}
    />
  )
}

export default ProgressBarStyled