const DataTableAwards = [
    {
      title: 'Award Name',
      judul: 'Nama Penghargaan',
      url: 'award_name',
    },
    {
      title: 'Name',
      judul: 'Nama',
      url: 'name',
    },
    {
      title: 'Gift Name',
      judul: 'Nama Pemberian',
      url: 'gift_name',
    },
    {
      title: 'Date',
      judul: 'Tanggal',
      url: 'date',
    },
    {
      title: 'Action',
      judul: 'Aksi',
      url: 'award_name',
    },
  ];
  
  export default DataTableAwards;