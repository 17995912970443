import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLocationById } from '../../../../../../configs/axios/apiPath';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { TooltipsAction } from '../../../../../atoms';
import {
  IconDelete,
  IconDetail,
  IconEdit,
} from '../../../../../../assets/commons';
import LocationDetail from '../../../../../../pages/organization/location/detail';
import ModalDelete from '../../../../modal/modalDelete';
import { handleAction } from '../../../../../../utils/function/action';
import {
  handleTooltipClose,
  handleTooltipOpen,
} from '../../../../../../utils/function/tooltips';

const LocationTbody = ({ tbody, setData }) => {
  const [idTooltipDetail, setIdTooltipDetail] = useState('');
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);
  const [idTooltipDelete, setIdTooltipDelete] = useState('');
  const [isTooltipDelete, setIsTooltipDelete] = useState(false);
  const [idTooltipEdit, setIdTooltipEdit] = useState('');
  const [isTooltipEdit, setIsTooltipEdit] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState('');
  const [locationId, setLocationId] = useState(null);
  const [detail, setDetail] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchDataDetail() {
      if (locationId) {
        try {
          const {
            data: { data },
          } = await getLocationById(locationId);
          setDetail(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        }
      }
    }

    fetchDataDetail();
  }, [locationId, navigate]);

  const handleEdit = (id) => {
    navigate(`/organization/location/edit/${id}`);
  };

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            <td>
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'15px'}
                color={'#171717'}
              >
                {item.location_name}
              </Typography>
              <Typography
                fontFamily={'open-sans'}
                fontStyle={'normal'}
                fontWeight={600}
                fontSize={'8px'}
                lineHeight={'10.4px'}
                color={'#939393'}
              >
                Department - {item.name}
              </Typography>
            </td>
            <td>
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'15px'}
                color={'#171717'}
              >
                {item.city_name}
              </Typography>
            </td>
            <td>
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'15px'}
                color={'#171717'}
              >
                {item.country_name}
              </Typography>
            </td>
            <td>
              {/** action */}
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={1}
              >
                {/* --- Detail --- */}
                <TooltipsAction
                  title="Detail"
                  open={isTooltipDetail && item.id === idTooltipDetail}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setLocationId,
                        'detail'
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDetail,
                        setIdTooltipDetail
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDetail)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>

                {/* --- Delete --- */}
                <TooltipsAction
                  title="Delete"
                  open={isTooltipDelete && item.id === idTooltipDelete}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setLocationId,
                        'delete'
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDelete,
                        setIdTooltipDelete
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDelete)}
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>

                {/* --- Edit --- */}
                <TooltipsAction
                  title="Edit"
                  open={isTooltipEdit && item.id === idTooltipEdit}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleEdit(item.id)}
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipEdit,
                        setIdTooltipEdit
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipEdit)}
                  >
                    <IconEdit />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
      {/** modal */}
      {modal === 'detail' ? (
        <LocationDetail
          detail={detail}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      ) : modal === 'delete' ? (
        <ModalDelete
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={'location'}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          id={locationId}
          data={tbody}
          setData={setData}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default LocationTbody;
