export const dataTablePayrollHistory = [
  {
    title: 'Payment Invoice',
    judul: 'Faktur Pembayaran',
    path: 'name',
  },
  {
    title: 'Amount',
    judul: 'Jumlah',
    path: 'name',
  },
  {
    title: 'Date',
    judul: 'Tanggal',
    path: 'name',
  },
  {
    title: 'Status',
    judul: 'Status  ',
    path: 'name',
  },
  {
    title: 'Action',
    judul: 'Aksi',
    path: 'name',
  },
];
