import { API } from "../../../API";

export const getListTravelMode = (params) =>
  API.get("travel-mode", { params: params });

export const postTravelMode = (data) => API.post("employee-journey", data);

export const uploadTravelMode = (data) =>
  API.postForm("travel-mode/image", data);

export const getTravelModeById = (id) => API.get(`employee-journey/${id}`);

export const updateTravelModeById = (data, id) =>
  API.put(`travel-mode/${id}`, data);

export const deleteTravelModeById = (id) => API.delete(`employee-journey/${id}`);
