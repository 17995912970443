import { Box, Divider, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { getEmployeesById } from "../../../../../configs/axios/apiPath/fetchEmployee/fetchEmployeeManage";

const EmployeeResume = () => {
  const path = useLocation();
  const pathname = path.pathname;
  const paths = pathname.split("/");
  const id = paths[4];

  const [dataEmployee, setDataEmployee] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        include: "resume",
      };
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await getEmployeesById(id, params);
        setDataEmployee(data?.resume);
      } catch (error) {
        console.error(error.response.status);
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [id, navigate]);

  return (
    <Box mt={"30px"}>
      {/* ---Summary --- */}
      <Box>
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"18px"}
          lineHeight={"25px"}
          color={"#51B15C"}
          mb={"10px"}
        >
          Summary
        </Typography>
        {isLoading ? (
          <Skeleton variant="text" height={40} width={"30vw"} />
        ) : (
          <Box>
            {dataEmployee?.speciality?.length !== 0 ? (
              <Box>
                {dataEmployee?.speciality?.map((item, idx) => (
                  <Box key={idx} mt={"16px"}>
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontWeight={600}
                      fontSize={"12px"}
                      lineHeight={"16px"}
                      letterSpacing={"1px"}
                      color={"#939393"}
                      mb={"4px"}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"12px"}
                lineHeight={"16px"}
                color={"#939393"}
                mb={"4px"}
              >
                The data is still Empty
              </Typography>
            )}
          </Box>
        )}
      </Box>

      <Divider
        orientation="horizontal"
        flexItem
        sx={{ borderColor: "#c4c4c4", my: "20px" }}
      />

      {/* --- Experience --- */}
      <Box>
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"18px"}
          lineHeight={"25px"}
          color={"#51B15C"}
          mb={"10px"}
        >
          Experience
        </Typography>
        {isLoading ? (
          <Skeleton variant="text" height={40} width={"30vw"} />
        ) : (
          <Box>
            {dataEmployee?.experience?.length !== 0 ? (
              <Box>
                {dataEmployee?.experience?.map((item, idx) => (
                  <Box key={idx} mt={"16px"}>
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontWeight={600}
                      fontSize={"12px"}
                      lineHeight={"16px"}
                      letterSpacing={"1px"}
                      color={"#939393"}
                      mb={"4px"}
                    >
                      Job Positions
                    </Typography>
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontWeight={600}
                      fontSize={"15px"}
                      lineHeight={"20px"}
                      letterSpacing={"1px"}
                      color={"#171717"}
                      mb={"16px"}
                    >
                      {item.position}
                    </Typography>
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontWeight={600}
                      fontSize={"12px"}
                      lineHeight={"16px"}
                      letterSpacing={"1px"}
                      color={"#939393"}
                      mb={"4px"}
                    >
                      Years
                    </Typography>
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontWeight={600}
                      fontSize={"15px"}
                      lineHeight={"20px"}
                      letterSpacing={"1px"}
                      color={"#171717"}
                    >
                      {item.year}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"12px"}
                lineHeight={"16px"}
                color={"#939393"}
                mb={"4px"}
              >
                The data is still Empty
              </Typography>
            )}
          </Box>
        )}
      </Box>

      <Divider
        orientation="horizontal"
        flexItem
        sx={{ borderColor: "#c4c4c4", my: "20px" }}
      />

      {/* --- Certification--- */}
      <Box mb={"20px"}>
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"18px"}
          lineHeight={"25px"}
          color={"#51B15C"}
          mb={"10px"}
        >
          Certification
        </Typography>
        {isLoading ? (
          <Skeleton variant="text" height={40} width={"30vw"} />
        ) : (
          <Box>
            {dataEmployee?.certificate?.length !== 0 ? (
              <Box>
                {dataEmployee?.certificate?.map((item, idx) => (
                  <Box key={idx} mt={"16px"}>
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontWeight={600}
                      fontSize={"12px"}
                      lineHeight={"16px"}
                      letterSpacing={"1px"}
                      color={"#939393"}
                      mb={"4px"}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"12px"}
                lineHeight={"16px"}
                color={"#939393"}
                mb={"4px"}
              >
                The data is still Empty
              </Typography>
            )}
          </Box>
        )}
      </Box>

      <Divider
        orientation="horizontal"
        flexItem
        sx={{ borderColor: "#c4c4c4", my: "20px" }}
      />

      {/* --- Education --- */}
      <Box>
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"18px"}
          lineHeight={"25px"}
          color={"#51B15C"}
          mb={"10px"}
        >
          Education
        </Typography>
        {isLoading ? (
          <Skeleton variant="text" height={40} width={"30vw"} />
        ) : (
          <Box>
            {dataEmployee?.education?.length !== 0 ? (
              <Box>
                {dataEmployee?.education?.map((item, idx) => (
                  <Box key={idx} mt={"16px"}>
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontWeight={600}
                      fontSize={"12px"}
                      lineHeight={"16px"}
                      letterSpacing={"1px"}
                      color={"#939393"}
                      mb={"4px"}
                    >
                      {item.start_at} - {item.ended_at}
                    </Typography>
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontWeight={600}
                      fontSize={"15px"}
                      lineHeight={"20px"}
                      letterSpacing={"1px"}
                      color={"#171717"}
                    >
                      {item.instance}
                    </Typography>
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontWeight={600}
                      fontSize={"12px"}
                      lineHeight={"16px"}
                      letterSpacing={"1px"}
                      color={"#939393"}
                    >
                      {item.degree}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"12px"}
                lineHeight={"16px"}
                color={"#939393"}
                mb={"4px"}
              >
                The data is still Empty
              </Typography>
            )}
          </Box>
        )}
      </Box>

      <Divider
        orientation="horizontal"
        flexItem
        sx={{ borderColor: "#c4c4c4", my: "20px" }}
      />

      {/* --- Certification--- */}
      <Box mb={"20px"}>
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"18px"}
          lineHeight={"25px"}
          color={"#51B15C"}
          mb={"10px"}
        >
          Certification
        </Typography>
        {isLoading ? (
          <Skeleton variant="text" height={40} width={"30vw"} />
        ) : (
          <Box>
            {dataEmployee?.certificate?.length !== 0 ? (
              <Box>
                {dataEmployee?.certificate?.map((item, idx) => (
                  <Box key={idx} mt={"16px"}>
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontWeight={600}
                      fontSize={"12px"}
                      lineHeight={"16px"}
                      letterSpacing={"1px"}
                      color={"#939393"}
                      mb={"4px"}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"12px"}
                lineHeight={"16px"}
                color={"#939393"}
                mb={"4px"}
              >
                The data is still Empty
              </Typography>
            )}
          </Box>
        )}
      </Box>

      <Divider
        orientation="horizontal"
        flexItem
        sx={{ borderColor: "#c4c4c4" }}
      />
    </Box>
  );
};

export default EmployeeResume;
