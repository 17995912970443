import { Box, Button, Typography } from "@mui/material";
import SelectYear from "../../../../molecules/year";
import { useState } from "react";
import { getLanguage } from "../../../../../features/language";
import { getCompany } from "../../../../../features/companies";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Card, Pagination, RowPerPage, Search } from "../../../../atoms";
import { IconPlus } from "../../../../../assets/commons";
import { useEffect } from "react";
import {
  getListEmployeeFacillities,
} from "../../../../../configs/axios/apiPath";
import { DataTableEmployeeFacillities } from "../../../../../data/table/benefit";
import TableComponent from "../../../../molecules/table/mainTable";
import {
  EmployeeFacilitiesTbody,
  EmployeeFacilitiesThead,
} from "../../../../molecules/table/tableBenefit/employeeFacillities";

const TableEmployeeFacilities = () => {
  const yearNow = moment().format("YYYY");
  const year = [];
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [selectedYear, setSelectedYear] = useState(yearNow);
  const [order, setOrder] = useState();
  const [direction, setDirection] = useState("desc");
  const [isLoading, setIsLoading] = useState(false);
  const [employeeFacillities, setEmployeeFacillities] = useState([]);
  const [lastPage, setLastPage] = useState();
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);

  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }

  const handleNavigationClick = (url) => {
    navigate("add");
  };

  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          company_id: company,
          year: selectedYear,
          keywords: keyword,
          page,
          page_size: pageSize,
          order,
          direction,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListEmployeeFacillities(params);
          setLastPage(data?.page_info?.last_page);
          setEmployeeFacillities(data?.employee_facilities);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [company, direction, keyword, navigate, order, page, pageSize, selectedYear]);

  return (
    <Card sx={{ marginTop: "20px" }}>
      <Box display={"flex"} flexDirection={"column"} p={"23px 22px"}>
        <Box display={"flex"} justifyContent={"space-between"}>
          {/* --- Search --- */}
          <Search
            placeholder={
              lang === "Id"
                ? "Ketik disini untuk mencari"
                : "Type here to search"
            }
            setSearch={(e) => setKeyword(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />

          <Box display={"flex"} alignItems={"center"}>
            {/* --- Selected Year --- */}
            <SelectYear
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              year={year}
            />

            {/* --- Add --- */}
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              onClick={() => handleNavigationClick("add")}
              sx={{
                borderRadius: "4px",
                marginLeft: "10px",
              }}
            >
              <Box display={"flex"} alignItems={"center"}>
                <IconPlus />
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={600}
                  fontSize={"12px"}
                  lineHeight={"14px"}
                  color="primary.contrastText"
                  letterSpacing={"0.02em"}
                  marginLeft={"10px"}
                  textTransform={"none"}
                >
                  {lang === "Id" ? "Tambah" : "Add"}
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>

        {/* --- Table --- */}
        <Box sx={{ mt: "24px", mb: "16px" }}>
          <TableComponent
            isLoading={isLoading}
            tbody={employeeFacillities}
            TableHead={
              <EmployeeFacilitiesThead
                thead={DataTableEmployeeFacillities}
                setOrder={setOrder}
                setDirection={setDirection}
                direction={direction}
                orderBy={order}
              />
            }
            TableBody={
              <EmployeeFacilitiesTbody
                tbody={employeeFacillities}
                setData={setEmployeeFacillities}
              />
            }
            col={5}
          />
        </Box>

        {/* --- Pagination --- */}
        <Box display={"flex"} justifyContent={"end"}>
          <RowPerPage
            setPageSize={setPageSize}
            pageSize={pageSize}
            setPage={setPage}
          />
          <Pagination page={page} setPage={setPage} lastPage={lastPage} />
        </Box>
      </Box>
    </Card>
  );
};

export default TableEmployeeFacilities;
