import React, { useEffect, useRef } from "react";
import {
  Autocomplete,
  Button,
  Card,
  Chip,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { Box } from "@mui/system";
import debounce from "lodash/debounce";
import { getLanguage } from "../../../../features/language";
import { useSelector } from "react-redux";
import { IconDeptText, IconSearch } from "../../../../assets/department";
import { useState } from "react";
import { getListDepartment } from "../../../../configs/axios/apiPath/fetchOrganization/fetchDepartment";
import { getCompany } from "../../../../features/companies";
import { useNavigate } from "react-router-dom";
import {
  getListLevels,
  getListPosition,
  postPosition,
} from "../../../../configs/axios/apiPath";
import { IconBack } from "../../../../assets/employee";

const PositionAdd = () => {
  const lang = useSelector(getLanguage);
  const [dataLevel, setDataLevel] = useState([]);
  const [dataDept, setDataDept] = useState([]);
  const [dataSuperior, setDataSuperior] = useState([]);

  const [levelSelected, setLevelSelected] = useState(null);
  const [deptSelected, setDeptSelected] = useState(null);
  const [superiorSelected, setSuperiorSelected] = useState(null);

  const [levelId, setLevelId] = useState("");
  const [deptId, setDeptId] = useState("");
  const [superiorId, setSuperiorId] = useState("");

  const [namePosition, setNamePosition] = useState("");

  const company = useSelector(getCompany);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [searchDept, setSearchDept] = useState("");
  const [loadingDept, setLoadingDept] = useState(false);

  useEffect(() => {
    async function fectDataLevel() {
      if (company) {
        const params = {
          company_id: company,
        };
        setIsLoading(true);
        try {
          const { data } = await getListLevels(params);

          const setLevel = data.data.levels.map((options) => ({
            id: options.id,
            label: options.name,
            company_id: company,
          }));
          setDataLevel(setLevel);
        } catch (error) {
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }
    fectDataLevel();
  }, [company, navigate]);

  useEffect(() => {
    fectDataDept(searchDept);
  }, [company, navigate]);

  async function fectDataDept(search) {
    if (company) {
      const params = {
        page_size: 10,
        company_id: company,
        keyword: search,
      };
      setLoadingDept(true);
      setIsLoading(true);
      try {
        const { data } = await getListDepartment(params);
        const setDept = data.data.departments.map((options) => ({
          id: options.id,
          label: options.name,
          company_id: company,
        }));

        setDataDept(setDept);
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      } finally {
        setLoadingDept(false);
        setIsLoading(false);
      }
    }
  }

  const debounceDept = useRef(
    debounce((value) => {
      fectDataDept(value);
    }, 1000)
  ).current;

  useEffect(() => {
    async function fectDataPosition() {
      if (company) {
        const params = {
          page_size: 99999,
          company_id: company,
        };
        setIsLoading(true);
        try {
          const { data } = await getListPosition(params);
          const setPosition = data.data.positions.map((options) => ({
            id: options.id,
            label: options.name,
            company_id: company,
          }));
          setDataSuperior(setPosition);
        } catch (error) {
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }
    fectDataPosition();
  }, [company, navigate]);

  const _filterOptions = createFilterOptions();
  const filterOptions = (options, state) => {
    const result = _filterOptions(options, state);

    if (result.length === 0) {
      return _filterOptions(options, {
        ...state,
        getOptionLabel: (o) => o.id.toString(),
      });
    }

    return result;
  };

  const handelSubmit = async (e) => {
    e.preventDefault();

    const superiorsID = superiorSelected?.map((item) => ({
      superior_id: item?.id,
    }));

    const dept_id = JSON.stringify(deptSelected.id, null, "");
    const level_id = JSON.stringify(levelSelected.id, null, "");
    const data = {
      company_id: company,
      department_id: dept_id,
      name: namePosition,
      level_id: level_id,
      superiors: superiorsID,
    };
    try {
      const res = await postPosition(data);
      navigate("/organization/department/position");
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    }
  };

  const handleChangeLevel = (e, value) => {
    setLevelSelected(value);
  };

  const handleInputLevel = (e, value) => {
    setLevelId(value);
  };

  const handleChangeDept = (e, value) => {
    setDeptSelected(value);

    setDeptId(value);
  };

  const handleChangeSuperior = (e, value) => {
    setSuperiorSelected(value);
  };

  const handleInputDept = (e, value) => {
    setDeptId(value);

    debounceDept(value);
  };

  const handleInputSuperior = (e, value) => {
    setSuperiorId(value);
  };

  const handleBack = () => {
    navigate(`/organization/department/position`);
  };

  return (
    <>
      <Card sx={{ marginTop: "20px" }}>
        <Box padding={"20px"}>
          <form onSubmit={handelSubmit}>
            <Box>
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"20px"}
                lineHeight={"27px"}
                letterSpacing={"0.01em"}
                color={"#171717"}
              >
                <IconButton size="large" onClick={() => handleBack()}>
                  <IconBack />
                </IconButton>
                {lang === "Id" ? "Tambah Posisi" : "Add Position"}
              </Typography>
            </Box>
            <hr color={"#F1F1F1"} width="100%" />

            {/* -- Position -- */}
            <Box padding={"10px"}>
              <Typography
                fontFamily={"Open Sans"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"17.7px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"2px"}
              >
                {lang === "Id" ? "Posisi" : "Position"}
              </Typography>
              <TextField
                size="small"
                sx={{
                  width: "500px",
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconDeptText />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setNamePosition(e.target.value)}
                required
              />
            </Box>

            {/* -- autocomplete Dept -- */}
            <Box padding={"10px"}>
              <Typography
                fontFamily={"Open Sans"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"17.7px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"2px"}
              >
                {lang === "Id" ? "Departemen" : "Department"}
              </Typography>
              <Autocomplete
                loading={loadingDept}
                loadingText={
                  <div style={{ textAlign: "center" }}>loading ...</div>
                }
                id="auto-dept"
                options={dataDept}
                filterOptions={filterOptions}
                onChange={handleChangeDept}
                value={deptSelected}
                getOptionLabel={(man) => man.label}
                inputValue={deptId}
                onInputChange={handleInputDept}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      width: "500px",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconDeptText />
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                )}
              />
            </Box>

            {/* -- autocomplete Superior -- */}
            <Box padding={"10px"}>
              <Typography
                fontFamily={"Open Sans"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"17.7px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"2px"}
              >
                {"Superior"}
              </Typography>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={dataSuperior}
                getOptionLabel={(man) => man.label}
                onChange={handleChangeSuperior}
                onInputChange={handleInputSuperior}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      width: "500px",
                    }}
                  />
                )}
              />
            </Box>

            {/* -- Level -- */}
            <Box padding={"10px"}>
              <Typography
                fontFamily={"Open Sans"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"13px"}
                lineHeight={"17.7px"}
                letterSpacing={"1px"}
                color={"#171717"}
                mb={"2px"}
              >
                {lang === "Id" ? "Level" : "Level"}
              </Typography>
              <Autocomplete
                id="level"
                options={dataLevel}
                filterOptions={filterOptions}
                onChange={handleChangeLevel}
                value={levelSelected}
                getOptionLabel={(man) => man.label}
                inputValue={levelId}
                onInputChange={handleInputLevel}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    sx={{
                      width: "500px",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconDeptText />
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                )}
              />
            </Box>
            {/* -- Button Save -- */}
            <Box padding={"10px"}>
              <Button
                variant="contained"
                color="primary"
                sx={{ textTransform: "none" }}
                disabled={isLoading}
                type="submit"
              >
                {lang === "Id" ? "Simpan" : "Save"}
              </Button>
            </Box>
          </form>
        </Box>
      </Card>
    </>
  );
};

export default PositionAdd;
