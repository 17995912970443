import { Avatar, Card, Grid, Typography } from "@mui/material"

const CardMember = ({dataMember}) => {
  return(
    <Grid item xs={2}>
      <Card sx={{height: '260px', display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
        <Avatar 
          alt={dataMember?.name} 
          src={dataMember?.profile_picture}
          sx={{
            width: '100px',
            height: '100px',
          }}
        />
        <Typography sx={{color: '#171717', fontWeight: '600', fontSize: '14px', marginTop: '16px', marginBottom: '10px'}}>
          {dataMember?.name}
        </Typography>
        <Typography sx={{color: '#939393', fontWeight: '600', fontSize: '10px'}}>
          Backend Developer
        </Typography>                  
      </Card>
    </Grid>
  )
}

export default CardMember