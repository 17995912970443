import { Autocomplete, Box, Button, CircularProgress, Grid, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IconDescription, IconEmployee, IconImage } from '../../../../../assets/coreHr/awards';
import { Card } from '../../../../../components/atoms';
import { getListEmployee, postImage } from '../../../../../configs/axios/apiPath';
import { postTask, postTaskDesign, postAttachmentProject } from '../../../../../configs/axios/apiPath/fetchProject';
import DataLogin from '../../../../../data/dataLogin';
import { getCompany } from '../../../../../features/companies';
import { getLanguage } from '../../../../../features/language';
import { ValidateDescription } from '../../../../../utils/validate';

const AddTask = () => {
  const [inputValue, setInputValue] = useState('');
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [searchParams] = useSearchParams();

  const [total, setTotal] = useState(1);
  const [images, setImages] = useState('');
  const [description, setDescription] = useState('');
  const [isFocusEmployee, setIsFocusEmployee] = useState(false);
  const [isFocusDescription, setIsFocusDescription] = useState(false);
  const [isfocus, setIsFocus] = useState(false)
  const [isImageClick, setIsImageClick] = useState(false)

  const [title, setTitle] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [employee, setEmployee] = useState([])
  const [fileBlob, setFileBlob] = useState('');

  const getData = DataLogin();
  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const lang = useSelector(getLanguage);
  const placeHolderId = 'Ketik disini';
  const placeHolderEn = 'Type here';
  
  const [isLoading, setIsLoading] = useState(false);
  const MAX_IMAGE_SIZE = 1 * 1024 * 1024;
  const [errorMsg, setErrorMsg] = useState('');

  // -- function date formatter post
  function dateFormatPost (date) {
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year ='' + d.getFullYear(),
    hour = '' +d.getHours(),
    minute = ''+d.getMinutes(),
    second = ''+ d.getSeconds();

    if (month.length < 2) 
      month = '0' + month;
    if (day.length < 2) 
      day = '0' + day;
    if (hour.length < 2) 
      hour = '0' + hour;
    if (minute.length < 2) 
      minute = '0' + minute;
    if (second.length < 2) 
      second = '0' + second;

    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
  }
  // --- Handle Input Change ---
  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  // --- Handle Selected Employee ---
  const handleSelectEmployee = (event, value) => {
    setEmployee([])
    setSelectedEmployee(value);
    setEmployee([value.id])
  };


  const handleImageUpload = (taskId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append('file', images);
        const {
          data : {data}
        } = await postAttachmentProject(formData, getData.token);
        await postTaskDesign(searchParams.get('px'), searchParams.get('bx'), taskId, {"image_id" : data.id})
        navigate("/manager-project/detail");
        resolve()
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
        reject(error);
      } finally {
        setIsLoading(false);
      }
    });
  };

  const handlePost = async (resolve, reject) => {

    if (title === '' || startDate === '' || endDate === '' || images === '' || employee.length === 0 || description === '' || description.length <= 10) return;

    const postData = {
      "name" : title,
      "start_date" : dateFormatPost(startDate),
      "end_date" : dateFormatPost(endDate),
      "description" : description,
      "is_complement" : 0,
      "employee_ids" : employee
    };

    setIsLoading(true);
    try {
      const {
          data : {data}
        } = await postTask(searchParams.get('px'), searchParams.get('bx'), postData);
      handleImageUpload(data.id)
          
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // --- List Employee ---
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          page_size: total
        }
        setIsLoading(true)
        try {
          const {
            data: { data },
          } = await getListEmployee(params);

          setEmployeeData(data.employee);
          if (data.page_info.total > 0) {
            setTotal(data.page_info.total);
          }
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false)
        }
      }
    };
    fetchData();
  }, [company, total]);


  // --- Handle Upload Image ---
  const handleFileChange = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (file) {
      // Validasi tipe file
      if (!file.type.startsWith('image/')) {
        const msgId = 'Jenis file tidak valid. Hanya file gambar yang diizinkan.'
        const msgEn = 'Invalid file type. Only image files are allowed.';
        if (lang === 'Id'){
          setErrorMsg(msgId) 
        } else {
          setErrorMsg(msgEn)
        }
        return;
      }

      // Validasi ukuran file
      if (file.size > MAX_IMAGE_SIZE) {
        const msgId = 'Ukuran file melebihi batas maksimum. Silakan pilih file yang lebih kecil.'
        const msgEn =
          'File size exceeds the maximum limit. Please choose a smaller file.';
        if (lang === 'Id'){
          setErrorMsg(msgId) 
        } else {
          setErrorMsg(msgEn)
        }
        return;
      }

      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append('image', file);
        // const {
        //   data : {data}
        // } = await postImage(formData, getData.token);
        setImages(file);

      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return(
    <Card>
      <Box p={'20px'}>
        <Box borderBottom={'0.2px solid #939393'} p={'0px 0px 15px 0px'}>
          <Typography
            fontFamily={'sans-serif'}
            fontStyle={'normal'}
            fontWeight={600}
            fontSize={'20px'}
            lineHeight={'27px'}
            letterSpacing={'0.01em'}
            color={'#171717'}
          >
            Add
          </Typography>
        </Box>
        <form
          style={{
            marginTop: '20px',
            fontWeight: '600px',
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '0.01em',
            color: '#171717',
          }}
          onSubmit={handleImageUpload}
        >
          {/* --- Title Task --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  Task Title
                </InputLabel>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  onChange={e => setTitle(e.target.value)}
                  size='small'
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      background: '#f9f9f9',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      },
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Start Date --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  Start Date
                </InputLabel>
                <TextField
                  fullWidth
                  required
                  type="datetime-local"
                  size='small'
                  onChange={e => setStartDate(new Date(e.target.value))}
                  variant='outlined'
                  sx={{
                    background: '#f9f9f9',
                    '& .MuiOutlinedInput-root': {
                      background: 'transparent',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- End Date --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  End Date
                </InputLabel>
                <TextField
                  fullWidth
                  required
                  type="datetime-local"
                  size='small'
                  variant='outlined'
                  onChange={e => setEndDate(new Date(e.target.value))}
                  sx={{
                    background: '#f9f9f9',
                    '& .MuiOutlinedInput-root': {
                      background: 'transparent',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Image --- */}
          <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
            <InputLabel style={{ marginBottom: '5px' }}>
              Image
            </InputLabel>
            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  size='small'
                  value={images.name}
                  disabled
                  sx={{
                    background: '#f9f9f9',
                    '& .MuiOutlinedInput-root': {
                      background: 'transparent',
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover:not(.Mui-disabled) fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-disabled fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {isImageClick ? <IconImage />: ''}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          variant="contained"
                          disabled={isLoading}
                          component="label"
                          sx={{
                            background: '#fff',
                            borderRadius: '8px',
                            my: '5px',
                            color: '#171717',
                            fontWeight: '400px',
                            fontSize: '12px',
                            lineHeight: '14px',
                            letterSpacing: '0.02em',
                          }}
                        >
                          Browse
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={handleFileChange}
                          />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* --- List Employee --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel style={{ marginBottom: '5px' }}>
                  Employee
                </InputLabel>
                <Autocomplete
                  value={selectedEmployee}
                  onChange={handleSelectEmployee}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  options={employeeData}
                  getOptionLabel={(employee) => employee.name}
                  
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      variant="outlined"
                      size='small'
                      placeholder={lang === 'Id'? placeHolderId: placeHolderEn}
                      onFocus={() => setIsFocusEmployee(false)}
                      onBlur={()=>setIsFocusEmployee(true)}
                      InputLabelProps={{ shrink: false }}
                      sx={{
                        background: '#f9f9f9',
                        '& .MuiOutlinedInput-root': {
                          background: 'transparent',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                      }}
                      
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start" sx={{ pl: '7px' }}>
                            {/* {isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              isFocusEmployee && <IconEmployee />
                            )} */}
                            <IconEmployee />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Description --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  Description
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  required
                  multiline
                  minRows={5}
                  variant="outlined"
                  size="small"
                  placeholder={lang === 'Id'? placeHolderId: placeHolderEn}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  onFocus={() => setIsFocusDescription(false)}
                  onBlur={() => setIsFocusDescription(true)}
                  onClick={()=>setIsFocus(true)}
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{
                        position: 'absolute',
                        top: 23,
                      }}
                    >
                      {/* {isFocusDescription ? <IconDescription />: ''} */}
                      <IconDescription />
                    </InputAdornment>
                  }
                  sx={{
                    fontSize: 'normal',
                    background: '#f9f9f9',
                    '& .MuiOutlinedInput-root': {
                      background: 'transparent',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '& textarea': {
                      paddingLeft: '24px',
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          {
            isfocus && 
            !ValidateDescription(description) && 
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'15px'}
                letterSpacing={'1px'}
                color={'red'}
                my={'5px'}
              >
                "Minimals 10 characters"
              </Typography>    
          }
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            // type="submit"
            // onClick={()=>setIsImageClick(true)}
            onClick={handlePost}
            sx={{ width: '136px', marginTop: '21px' }}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <div>Save</div>
            )}
            
          </Button>
        </form>
        
      </Box>
    </Card>
  )
}

export default AddTask