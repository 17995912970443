import {
  IconApproved,
  IconRejected,
  IconReview,
  IconVerified,
} from "../../../assets/coreHr/permission";

const dataPermissionFooter = [
  {
    title: "Request",
    judul: "Permintaan",
    icon: <IconReview />,
  },
  {
    title: "Approved",
    judul: "Disetujui",
    icon: <IconApproved />,
  },
  {
    title: "Verified",
    judul: "Verifikasi",
    icon: <IconVerified />,
  },
  {
    title: "Rejected",
    judul: "Ditolak",
    icon: <IconRejected />,
  },
];

export default dataPermissionFooter;
