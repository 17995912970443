import { Box, Autocomplete, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import { getLanguage } from '../../../../features/language';
import { getCompany } from '../../../../features/companies';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  Card,
  Pagination,
  RowPerPage,
  Search,
} from '../../../../components/atoms';
// import { IconPlus } from '../../../../assets/commons';
import {
  getListAttendance,
  getListDepartments,
} from '../../../../configs/axios/apiPath';
import { DataTableAttendance } from '../../../../data/table/timesheet';
import TableComponent from '../../../../components/molecules/table/mainTable';
import {
  AttendanceTbody,
  AttendanceThead,
} from '../../../../components/molecules/table/tableTimesheet/attendance';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DepartmentAutocomplete from '../../../../components/organisms/timesheet/departmentAutocomplete';

const AttendanceList = () => {
  let localData = JSON.parse(localStorage.getItem('cvSayaData'));
  const yearNow = moment().format('YYYY');
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [attendance, setattendance] = useState([]);
  const [lastPage, setLastPage] = useState();
  const [order, setOrder] = useState('name');
  const [direction, setDirection] = useState('-');
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);
  const [datePicker, setDatePicker] = useState(
    localData?.datePicker ? dayjs(localData?.datePicker) : dayjs()
  );
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [department, setDepartment] = useState(localData?.departmentId ?? '');

  const handleDepartmentChange = (value) => {
    if (value) {
      setDepartment(value.id);
      localData = { ...localData, departmentId: value.id };
      localStorage.setItem('cvSayaData', JSON.stringify(localData));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          page_size: 9999999,
          company_id: company,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListDepartments(params);

          const defaultDepartment = {
            id: null,
            name: 'ALL DEPARTMENT',
            icon_url: null,
            total_employee: 0,
            manager: null,
          };
          const newDepartments = [defaultDepartment, ...data?.departments];

          !department && setDepartment(newDepartments[0].id);
          setDepartmentOptions(newDepartments);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company]);

  useEffect(() => {
    async function fetchData() {
      if (company) {
        const params = {
          page: page,
          company_id: company,
          department_id: department,
          date: datePicker.format('YYYY-MM-DD'),
          search: keyword,
          sort: `${direction}${order}`,
          page_size: pageSize,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListAttendance(params);
          setLastPage(data?.page_info?.last_page);
          setattendance(data?.presences);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [
    company,
    direction,
    order,
    keyword,
    department,
    datePicker,
    page,
    pageSize,
  ]);

  return (
    <Card sx={{ marginTop: '20px' }}>
      <Box display={'flex'} flexDirection={'column'} p={'23px 22px'}>
        <Box display={'flex'} justifyContent={'space-between'}>
          {/* --- Search --- */}
          <Search
            placeholder={
              lang === 'Id'
                ? 'Ketik disini untuk mencari'
                : 'Type here to search'
            }
            setSearch={(e) => setKeyword(e)}
            page={page}
            setPage={(e) => setPage(e)}
          />

          <Box display={'flex'} alignItems={'center'} gap={'10px'}>
            {/* {select department} */}
            <DepartmentAutocomplete
              departmentOptions={departmentOptions}
              department={department}
              handleDepartmentChange={handleDepartmentChange}
            />

            {/* --- Datepicker --- */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={datePicker}
                onChange={(value) => {
                  setDatePicker(value);
                  localData = {
                    ...localData,
                    datePicker: value.format('YYYY-MM-DD'),
                  };
                  localStorage.setItem('cvSayaData', JSON.stringify(localData));
                }}
                inputRef={{
                  border: 'none',
                }}
                sx={{
                  background: '#F7F7F7',
                  borderRadius: '8px',
                  paddingLeft: '4px',
                  width: '140px',
                }}
                format="DD/MMM/YY"
                slotProps={{ textField: { variant: 'standard' } }}
              />
            </LocalizationProvider>
          </Box>
        </Box>

        {/* --- Table --- */}
        <Box sx={{ mt: '24px', mb: '16px' }}>
          <TableComponent
            isLoading={isLoading}
            tbody={attendance}
            TableHead={
              <AttendanceThead
                thead={DataTableAttendance}
                setOrder={setOrder}
                setDirection={setDirection}
                direction={direction}
              />
            }
            TableBody={
              <AttendanceTbody tbody={attendance} setData={setattendance} />
            }
            col={8}
          />
        </Box>

        {/* --- Pagination --- */}
        <Box display={'flex'} justifyContent={'end'}>
          <RowPerPage
            setPageSize={setPageSize}
            pageSize={pageSize}
            setPage={setPage}
          />
          <Pagination page={page} setPage={setPage} lastPage={lastPage} />
        </Box>
      </Box>
    </Card>
  );
};

export default AttendanceList;
