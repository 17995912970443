import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  IconPlus,
  IconEdit,
  IconDelete,
  IconClose,
} from "../../../../../assets/commons";
import { Buttons, Card } from "../../../../../components/atoms";
import { useLocation } from "react-router-dom";
import {
  addBenefitEmployee,
  deleteBenefitEmployee,
  getBenefitEmployee,
  getSalaryType,
} from "../../../../../configs/axios/apiPath/fetchEmployee/benefit";
import {
  DeleteIcon,
  IconBenefitEmployee,
} from "../../../../../assets/employee";
import { FormatRupiah } from "@arismun/format-rupiah";
import RupiahFormatter from "../../../../../components/atoms/rupiahFormatter";
import { IconBenefit } from "../../../../../assets/sideNav/icons";

const EmployeeBenefit = () => {
  const companyId = localStorage.getItem("cvSayaData");
  const newCompanyId = JSON.parse(companyId);
  const path = useLocation().pathname;
  const splitPath = path.split("/");
  const id = splitPath[4];
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [salaryTypeDatas, setSalaryTypeDatas] = useState([]);
  const [salarySelected, setSalarySelected] = useState("");
  const [amount, setAmount] = useState("");
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);

  // ! detail to edit
  const [openEdit, setOpenEdit] = useState(false);
  const [detail, setDetail] = useState(null);
  const [amountEdit, setAmountEdit] = useState("");
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  // ! delete
  const [companyTypeId, setCompanyTypeId] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const getDataBenefitEmployee = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await getBenefitEmployee(id);
      setDatas(data);
      setLoading(false);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataBenefitEmployee();
  }, []);

  const addBenefit = async () => {
    const params = {
      company_id: newCompanyId?.company,
    };
    try {
      const {
        data: { data },
      } = await getSalaryType(params);
      setSalaryTypeDatas(data);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    addBenefit();
  }, []);

  const handleAdd = async () => {
    const convertAmount = amount.replace(/[^,\d]/g, "");

    const params = {
      company_salary_type_id: salarySelected?.id,
      amount: Number(convertAmount),
      amount_type: "fixed",
    };
    setIsLoadingAdd(true);
    try {
      await addBenefitEmployee(id, params);
      setOpenAdd(!openAdd);
      setIsLoadingAdd(false);
      getDataBenefitEmployee();
      setAmount("");
      setSalarySelected("");
    } catch (err) {
      console.error(err);
      setIsLoadingAdd(false);
    }
  };

  const handleEdit = async () => {
    const convertAmount = amountEdit.replace(/[^,\d]/g, "");
    const params = {
      company_salary_type_id: detail?.id,
      amount: Number(convertAmount),
      amount_type: "fixed",
    };

    setIsLoadingEdit(true);
    try {
      await addBenefitEmployee(id, params);
      setOpenEdit(!openEdit);
      setIsLoadingEdit(false);
      getDataBenefitEmployee();
      setAmountEdit("");
      setDetail("");
    } catch (err) {
      console.error(err);
      setIsLoadingEdit(false);
    }
  };

  const handleDelete = async () => {
    const params = {
      company_salary_type_id: companyTypeId,
    };
    setIsLoadingDelete(!isLoadingDelete);
    try {
      await deleteBenefitEmployee(id, params);
      setOpenDelete(!openDelete);
      setIsLoadingDelete(!isLoadingDelete);
      getDataBenefitEmployee();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  return (
    <Box>
      {/* --- Title --- */}
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginTop={2}
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"18px"}
          lineHeight={"25px"}
          letterSpacing={"1px"}
          color={"#171717"}
        >
          Benefits
        </Typography>
        <Buttons
          type="submit"
          onClick={() => setOpenAdd(!openAdd)}
          sx={{
            borderRadius: "4px",
            background: "#51B15C",
            padding: "10px",
          }}
          variant="contained"
        >
          <Box display={"flex"} alignItems={"center"}>
            <IconPlus />
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontWeight={600}
              fontSize={"14px"}
              lineHeight={"14px"}
              // color="primary.contrastText"
              color={"#FFF"}
              letterSpacing={"0.02em"}
              marginLeft={"10px"}
              textTransform={"none"}
            >
              Add
            </Typography>
          </Box>
        </Buttons>
      </Box>
      {/* --- Benefits --- */}
      <Grid container columns={4} spacing={2} sx={{ mt: "17px" }}>
        {loading ? (
          <Grid item xs={4}>
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Box>
          </Grid>
        ) : datas?.length === 0 ? (
          <Grid item xs={4}>
            <Typography sx={{ textAlign: "center", padding: "60px 0px" }}>
              <IconBenefitEmployee />

              <Typography
                sx={{ fontSize: "16px", color: "#9E9D9D", textAlign: "center" }}
              >
                No records yet
              </Typography>
            </Typography>
          </Grid>
        ) : (
          datas?.map((item, idx) => (
            <Grid item xl={2} lg={2} md={2} sm={1} xs={1} key={idx}>
              <Card sx={{ padding: "20px" }}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"start"}
                >
                  {/* --- Title === */}
                  <Box>
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontWeight={600}
                      fontSize={"16px"}
                      lineHeight={"22px"}
                      letterSpacing={"1px"}
                      color={"#171717"}
                      mb={"16px"}
                    >
                      {item?.name}
                    </Typography>
                    <Typography
                      fontFamily={"sans-serif"}
                      fontStyle={"normal"}
                      fontWeight={600}
                      fontSize={"24px"}
                      lineHeight={"33px"}
                      letterSpacing={"1px"}
                      color={"#171717"}
                    >
                      {item?.amount?.toString().length >= 6 ? (
                        <>
                          <FormatRupiah
                            value={`${String(item?.amount).slice(0, -3)}`}
                          />
                          *
                        </>
                      ) : (
                        <FormatRupiah value={item?.amount} />
                      )}
                    </Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <IconButton sx={{ p: "0px", mr: "10px" }}>
                      <IconDelete
                        onClick={() => {
                          setCompanyTypeId(item?.company_salary_type_id);
                          setOpenDelete(!openDelete);
                        }}
                      />
                    </IconButton>
                    <IconButton
                      sx={{ p: "0px" }}
                      onClick={() => {
                        setOpenEdit(!openEdit);
                        setDetail({
                          id: item?.company_salary_type_id,
                          name: item?.name,
                        });
                        setAmountEdit(item?.amount);
                      }}
                    >
                      <IconEdit />
                    </IconButton>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))
        )}
      </Grid>

      {/* dialog add */}
      <Dialog open={openAdd} onClose={() => setOpenAdd(!openAdd)}>
        <DialogTitle sx={{ minWidth: "400px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, color: "#000" }}
            >
              Add New Benefits
            </Typography>
            <IconClose
              onClick={() => setOpenAdd(!openAdd)}
              sx={{ cursor: "pointer" }}
            />
          </Box>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Grid container columns={12} spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                options={salaryTypeDatas}
                getOptionLabel={(option) => option.salary_type?.name}
                onChange={(event, newValue) => {
                  setSalarySelected(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Company salary type"
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disableClearable
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                label="Amount*"
                variant="outlined"
                fullWidth
                // value={amount}
                value={RupiahFormatter(amount?.toString() ?? "0")}
                onChange={(e) =>
                  e.target.value.length <= 14 && setAmount(e.target.value)
                }
              />
            </Grid>
            <Grid
              item={12}
              sx={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
              }}
            >
              <Button type="submit" variant="contained" onClick={handleAdd}>
                <Typography
                  sx={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  {isLoadingAdd ? (
                    <CircularProgress size={18} sx={{ color: "#FFF" }} />
                  ) : (
                    "Submit"
                  )}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* edit benefit */}
      <Dialog open={openEdit} onClose={() => setOpenEdit(!openEdit)}>
        <DialogTitle sx={{ minWidth: "400px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, color: "#000" }}
            >
              Edit Benefits
            </Typography>
            <IconClose onClick={() => setOpenEdit(!openEdit)} />
          </Box>
          <Divider />
        </DialogTitle>

        <DialogContent>
          <Grid container columns={12} spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                value={detail?.name}
                options={salaryTypeDatas}
                getOptionLabel={(option) =>
                  option.salary_type?.name || detail?.name
                }
                onChange={(event, newValue) => {
                  setDetail(newValue?.salary_type);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Company salary type"
                  />
                )}
                // isOptionEqualToValue={(option, value) => option.id === value.id}
                disableClearable
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                label="Amount*"
                variant="outlined"
                fullWidth
                value={RupiahFormatter(amountEdit?.toString() ?? "0")}
                onChange={(e) =>
                  e.target.value.length <= 14 && setAmountEdit(e.target.value)
                }
              />
            </Grid>
            <Grid
              item={12}
              sx={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
              }}
            >
              <Button type="submit" variant="contained" onClick={handleEdit}>
                <Typography
                  sx={{
                    textTransform: "none",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  {isLoadingEdit ? (
                    <CircularProgress size={18} sx={{ color: "#FFF" }} />
                  ) : (
                    "Submit"
                  )}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* dialog delete */}
      <Dialog open={openDelete} onClose={() => setOpenDelete(!openDelete)}>
        <DialogTitle sx={{ minWidth: "400px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1,
              gap: 2,
            }}
          >
            <DeleteIcon />
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, color: "#000" }}
            >
              Delete Benefits
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontSize: "16px", fontWeight: 300 }}>
            Are you sure you want to delete it ? You can`t undo this action
          </Typography>

          <Box
            sx={{
              display: "flex",
              mt: 5,
              alignItems: "center",
              gap: 2,
              justifyContent: "end",
            }}
          >
            <Typography
              sx={{ color: "#9E9D9D", cursor: "pointer" }}
              onClick={() => setOpenDelete(!openDelete)}
            >
              Cancel
            </Typography>
            <Button onClick={handleDelete} color="error" variant="contained">
              <Typography
                sx={{
                  textTransform: "none",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                {isLoadingDelete ? (
                  <CircularProgress size={18} sx={{ color: "#FFF" }} />
                ) : (
                  "Delete"
                )}
              </Typography>
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EmployeeBenefit;
