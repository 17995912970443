import { Grid } from '@mui/material';
import React from 'react';
import GridApplicant from './page/Applicants';
import GridProject from './page/Project';

const HomepageProject = () => {
  return (
    <Grid container columns={4} spacing={2}>
      <GridProject/>
      <GridApplicant />
    </Grid>
  );
};

export default HomepageProject;
