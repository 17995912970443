import { Box, Typography, Grid, Button } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../features/language";
import DocumentUrl from "../../../../configs/url";

export default function VerifiedStep({ detail, handleVerified, handleReject }) {
  const lang = useSelector(getLanguage);

  const StatusName = {
    100: "Request",
    202: "Approved",
    200: "Verified",
    301: "Rejected",
  };

  return (
    <Box mt={"20px"} component={"form"} noValidate autoComplete="off">
      {/* --- Name Employee --- */}
      <Box
        mb={"20px"}
        p={"0px"}
        sx={{
          position: "relative",
        }}
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontSize={"13px"}
          fontWeight={600}
          lineHeight={"18px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"6px"}
        >
          {lang === "Id" ? "Employee" : "Employee"}
        </Typography>
        <Box
          style={{
            width: "100%",
            background: "#f7f7f7",
            borderRadius: "4px",
            border: "1px solid transparent",
            display: "inline-block",
          }}
        >
          <Typography mx={"10px"} py={"10px"}>
            {detail?.employee?.name}
          </Typography>
        </Box>
      </Box>
      {/* --- Date --- */}
      <Box
        mb={"20px"}
        p={"0px"}
        sx={{
          position: "relative",
        }}
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontSize={"13px"}
          fontWeight={600}
          lineHeight={"18px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"6px"}
        >
          {lang === "Id" ? "Tanggal" : "Date"}
        </Typography>
        <Box
          style={{
            width: "100%",
            background: "#f7f7f7",
            borderRadius: "4px",
            border: "1px solid transparent",
            display: "inline-block",
          }}
        >
          <Typography mx={"10px"} py={"10px"}>
            {moment(detail?.correction_date).format("D MMMM YYYY")}
          </Typography>
        </Box>
      </Box>

      {/* --- Type --- */}
      <Box
        mb={"20px"}
        p={"0px"}
        sx={{
          position: "relative",
        }}
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontSize={"13px"}
          fontWeight={600}
          lineHeight={"18px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"6px"}
        >
          {lang === "Id" ? "Tipe" : "Type"}
        </Typography>
        <Box
          style={{
            width: "100%",
            background: "#f7f7f7",
            borderRadius: "4px",
            border: "1px solid transparent",
            display: "inline-block",
          }}
        >
          <Typography mx={"10px"} py={"10px"}>
            {detail?.type}
          </Typography>
        </Box>
      </Box>

      {/* --- Award Image --- */}
      <Box
        mb={"20px"}
        p={"0px"}
        sx={{
          position: "relative",
        }}
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontSize={"13px"}
          fontWeight={600}
          lineHeight={"18px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"6px"}
        >
          {lang === "Id" ? "Gambar" : "Image"}
        </Typography>
        <img
          src={DocumentUrl(detail?.document?.id)}
          alt={detail?.name}
          width={"100%"}
          height={"auto"}
        />
      </Box>

      {/* --- Description --- */}
      <Box
        mb={"20px"}
        p={"0px"}
        sx={{
          position: "relative",
        }}
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontSize={"13px"}
          fontWeight={600}
          lineHeight={"18px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"6px"}
        >
          {lang === "Id" ? "Alasan" : "Reason"}
        </Typography>
        <Box
          style={{
            width: "100%",
            background: "#f7f7f7",
            borderRadius: "4px",
            border: "1px solid transparent",
            display: "inline-block",
          }}
        >
          <Typography
            mx={"10px"}
            py={"10px"}
            height={"auto"}
            maxHeight={"160px"}
            sx={{ overflowY: "scroll" }}
          >
            {detail?.description}
          </Typography>
        </Box>
      </Box>

      {/* --- Status --- */}
      <Box
        mb={"20px"}
        p={"0px"}
        sx={{
          position: "relative",
        }}
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontSize={"13px"}
          fontWeight={600}
          lineHeight={"18px"}
          letterSpacing={"1px"}
          color={"#171717"}
          mb={"6px"}
        >
          {lang === "Id" ? "Status" : "Status"}
        </Typography>
        <Box
          style={{
            width: "100%",
            background: "#f7f7f7",
            borderRadius: "4px",
            border: "1px solid transparent",
            display: "inline-block",
          }}
        >
          <Typography
            mx={"10px"}
            py={"10px"}
            height={"auto"}
            maxHeight={"160px"}
            sx={{ overflowY: "scroll" }}
          >
            {StatusName[detail?.status]}
          </Typography>
        </Box>
      </Box>

      <Grid container columns={2} spacing={2}>
        {/* --- Button Reject --- */}
        <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
          <Button
            variant="outlined"
            disableRipple
            onClick={handleReject}
            value={301}
            fullWidth
            sx={{ height: "42px" }}
            color="error"
          >
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontSize={"20px"}
              fontWeight={"700px"}
              lineHeight={"27px"}
              letterSpacing={"1px"}
              textTransform={"none"}
              color={"#E40A0A"}
            >
              {lang === "Id" ? "Tolak" : "Reject"}
            </Typography>
          </Button>
        </Grid>
        {/* --- Button Approved --- */}
        <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
          <Button
            variant="outlined"
            disableRipple
            onClick={handleVerified}
            value={200}
            fullWidth
            sx={{ height: "42px" }}
          >
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontSize={"20px"}
              fontWeight={"700px"}
              lineHeight={"27px"}
              letterSpacing={"1px"}
              textTransform={"none"}
              color={"#51B15C"}
            >
              {lang === "Id" ? "Verifikasi" : "Verify"}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
