import { Box } from "@mui/material";
import React from "react";
import {
  ChartRecruitment,
} from "../../../../components/organisms/recruitment";
import {
  TableRequest,
} from "../../../../components/organisms/recruitment";
const BenefitAccountDashboard = () => {
  return (
    <Box>
      <ChartRecruitment />
      <TableRequest />
    </Box>
  );
};

export default BenefitAccountDashboard;
