import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCompany } from '../../../../features/companies';
import {
  IconDate,
  IconDescription,
  IconEmployee,
} from '../../../../assets/coreHr/awards';
import { Card } from '../../../../components/atoms';
import {
  getListEmployee,
  getListPosition,
  postPromotion,
} from '../../../../configs/axios/apiPath';
import { getLanguage } from '../../../../features/language';

const CoreHRPromotionAdd = () => {
  const [inputValue, setInputValue] = useState('');
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [inputPromotionValue, setInputPromotionValue] = useState('');
  const [promotionData, setPromotionData] = useState([]);
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [totalEmployee, setTotalEmployee] = useState(1);
  const [totalPosition, setTotalPosition] = useState(1);
  const [start, setStart] = useState('');
  const [description, setDescription] = useState('');

  const [isFocusEmployee, setIsFocusEmployee] = useState(false);
  const [isFocusPromotion, setIsFocusPromotion] = useState(false);
  const [isFocusDate, setIsFocusDate] = useState(false);
  const [isFocusDescription, setIsFocusDescription] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const lang = useSelector(getLanguage);
  const placeholderId = 'Ketik disini';
  const placeHolderEn = 'Type Here';

  // --- Handle Submit ---
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      employee_id: selectedEmployee?.id,
      promotion_id: selectedPromotion?.id,
      date_of_appointment: start,
      description: description,
    };
    setIsLoading(true);
    try {
      const response = await postPromotion(data);

      if (response.data.meta.code === 20000) {
        navigate('/core-hr/promotion');
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        localStorage.removeItem('cvSayaLogin');
        navigate(`/users`);
      }
    }
  };

  // --- Handle Iput Change ---
  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  // --- Handle Promotion Changae ---
  const handlePromotionChange = (event, value) => {
    setInputPromotionValue(value);
  };

  // --- Handle Selected Employee ---
  const handleSelectEmployee = (event, value) => {
    setSelectedEmployee(value);
  };

  // --- Handle Selected Promotion ----
  const handleSelectPromotion = (event, value) => {
    setSelectedPromotion(value);
  };

  // --- List Employee ---
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          page_size: totalEmployee,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListEmployee(params);

          setEmployeeData(data?.employee);
          if (data.page_info?.total > 0) {
            setTotalEmployee(data.page_info?.total);
          }
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, totalEmployee]);

  //   --- Position ---
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          page_size: totalPosition,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListPosition(params);
          setPromotionData(data.positions);
          if (data.page_info?.total > 0) {
            setTotalPosition(data.page_info?.total);
          }
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, totalPosition]);

  return (
    <Card>
      <Box p={'20px'}>
        <Box borderBottom={'0.2px solid #939393'} p={'0px 0px 15px 0px'}>
          <Typography
            fontWeight={'600px'}
            fontSize={'20px'}
            lineHeight={'27px'}
            letterSpacing={'0.01em'}
            color={'#171717'}
          >
            {lang === 'Id' ? 'Tambah' : 'Add'}
          </Typography>
        </Box>
        <form
          onSubmit={handleSubmit}
          style={{
            marginTop: '20px',
            fontWeight: '600px',
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '0.01em',
            color: '#171717',
          }}
        >
          {/* --- Employee --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel style={{ marginBottom: '5px' }}>
                  {lang === 'Id' ? 'Karyawan' : 'Employee'}
                </InputLabel>
                <Autocomplete
                  value={selectedEmployee}
                  onChange={handleSelectEmployee}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  options={employeeData}
                  getOptionLabel={(employee) => employee.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: false }}
                      placeholder={
                        lang === 'Id' ? placeholderId : placeHolderEn
                      }
                      sx={{
                        background: '#f9f9f9',
                        '& .MuiOutlinedInput-root': {
                          background: 'transparent',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                      }}
                      onFocus={() => setIsFocusEmployee(false)}
                      onBlur={() => setIsFocusEmployee(true)}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            {isFocusEmployee ? <IconEmployee /> : ''}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Promotion --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel style={{ marginBottom: '5px' }}>
                  {lang === 'Id' ? 'Promosi' : 'Promotion'}
                </InputLabel>
                <Autocomplete
                  value={selectedPromotion}
                  onChange={handleSelectPromotion}
                  inputValue={inputPromotionValue}
                  onInputChange={handlePromotionChange}
                  options={promotionData}
                  getOptionLabel={(promotion) => promotion.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      size="small"
                      variant="outlined"
                      placeholder={
                        lang === 'Id' ? placeholderId : placeHolderEn
                      }
                      onFocus={() => setIsFocusPromotion(false)}
                      onBlur={() => setIsFocusPromotion(true)}
                      InputLabelProps={{ shrink: false }}
                      sx={{
                        background: '#f9f9f9',
                        '& .MuiOutlinedInput-root': {
                          background: 'transparent',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            {isFocusPromotion ? <IconEmployee /> : ''}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Date of Appointment --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  {lang === 'Id' ? 'Tanggal' : 'Date'}
                </InputLabel>
                <TextField
                  required
                  fullWidth
                  type="date"
                  size="small"
                  variant="outlined"
                  value={start}
                  onChange={(e) => setStart(e.target.value)}
                  onFocus={() => setIsFocusDate(false)}
                  onBlur={() => setIsFocusDate(true)}
                  sx={{
                    background: '#f9f9f9',
                    '& .MuiOutlinedInput-root': {
                      background: 'transparent',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {isFocusDate ? <IconDate /> : ''}
                      </InputAdornment>
                    ),
                    style: {
                      height: '40px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Description --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  {lang === 'Id' ? 'Deskripsi' : 'Description'}
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  required
                  multiline
                  minRows={5}
                  variant="outlined"
                  size="medium"
                  placeholder={lang === 'Id' ? placeholderId : placeHolderEn}
                  value={description}
                  onFocus={() => setIsFocusDescription(false)}
                  onBlur={() => setIsFocusDescription(true)}
                  onChange={(e) => setDescription(e.target.value)}
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{
                        position: 'absolute',
                        top: 23,
                      }}
                    >
                      {isFocusDescription ? <IconDescription /> : ''}
                    </InputAdornment>
                  }
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      background: 'transparent',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    background: '#F9F9F9',
                    '& textarea': {
                      paddingLeft: '24px',
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Button --- */}
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: '136px', marginTop: '21px' }}
          >
            {lang === 'Id' ? 'Simpan' : 'Save'}
          </Button>
        </form>
      </Box>
    </Card>
  );
};

export default CoreHRPromotionAdd;
