import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  IconCalendar,
  IconDescription,
  IconEmployee,
} from '../../../../assets/coreHr/awards';
import { Card } from '../../../../components/atoms';
import { getListEmployee, postResign } from '../../../../configs/axios/apiPath';
import DataLogin from '../../../../data/dataLogin';
import { getCompany } from '../../../../features/companies';
import { getLanguage } from '../../../../features/language';
import { ValidateString } from '../../../../utils/validate';

const CoreHRResignAdd = () => {
  const [inputValue, setInputValue] = useState('');
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [start, setStart] = useState('');
  const [description, setDescription] = useState('');
  const [total, setTotal] = useState('');
  const [selectedOption, setSelectedOption] = useState('');

  const [isFocusEmployee, setIsFocusEmployee] = useState(false);
  const [isFocusStart, setIsFocusStart] = useState(false);
  const [isFocusDescription, setIsFocusDescription] = useState(false);
  const [isFocusOption, setIsFocusOption] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const company = useSelector(getCompany);
  const lang = useSelector(getLanguage);
  const navigate = useNavigate();

  const placeholderId = 'Ketik disini';
  const placeholderEn = 'Type Here';

  // --- Handle Input Change ---
  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  // --- Handle Select Employee ---
  const handleSelectEmployee = (event, value) => {
    setSelectedEmployee(value);
  };

  // --- Handle Option Change ---
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // --- List Employee ---
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          page_size: total,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListEmployee(params);

          setEmployeeData(data.employee);
          if (data.page_info.total > 0) {
            setTotal(data.page_info.total);
          }
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, total]);

  //   --- Handle Submit ---
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      employee_id: selectedEmployee?.id,
      note: description,
      resignation_date: start,
      consideration: selectedOption,
    };
    setIsLoading(true);
    try {
      const response = await postResign(data);

      if (response.data.meta.code === 20000) {
        navigate('/core-hr/resign');
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        localStorage.removeItem('cvSayaLogin');
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // --- Handle Check Radio ---
  const handleCheckRadio = () => {
    setIsFocusOption(true);
  };
  return (
    <Card>
      <Box p={'20px'}>
        <Box borderBottom={'0.2px solid #939393'} p={'0px 0px 15px 0px'}>
          <Typography
            fontWeight={'600px'}
            fontSize={'20px'}
            lineHeight={'27px'}
            letterSpacing={'0.01em'}
            color={'#171717'}
          >
            {lang === 'Id' ? 'Tambah' : 'Add'}
          </Typography>
        </Box>
        <form
          onSubmit={handleSubmit}
          style={{
            marginTop: '20px',
            fontWeight: '600px',
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '0.01em',
            color: '#171717',
          }}
        >
          {/* --- Employee --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel style={{ marginBottom: '5px' }}>
                  {lang === 'Id' ? 'Karyawan' : 'Employee'}
                </InputLabel>
                <Autocomplete
                  value={selectedEmployee}
                  onChange={handleSelectEmployee}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  options={employeeData}
                  getOptionLabel={(employee) => employee.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: false }}
                      required
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder={
                        lang === 'Id' ? placeholderId : placeholderEn
                      }
                      onFocus={() => setIsFocusEmployee(false)}
                      onBlur={() => setIsFocusEmployee(true)}
                      sx={{
                        background: '#f9f9f9',
                        '& .MuiOutlinedInput-root': {
                          background: 'transparent',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            {isFocusEmployee ? <IconEmployee /> : ''}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Terminate date --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  {lang === 'Id' ? 'Tanggal Mengakhiri' : 'Terminate Date'}
                </InputLabel>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  type="date"
                  size="small"
                  value={start}
                  onChange={(e) => setStart(e.target.value)}
                  onFocus={() => setIsFocusStart(false)}
                  onBlur={() => setIsFocusStart(true)}
                  sx={{
                    background: '#f9f9f9',
                    '& .MuiOutlinedInput-root': {
                      background: 'transparent',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {isFocusStart ? <IconCalendar /> : ''}
                      </InputAdornment>
                    ),
                    style: {
                      height: '40px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Consideration Type --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                mb={
                  isFocusOption && !ValidateString(selectedOption)
                    ? '0px'
                    : '16px'
                }
              >
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  {lang === 'Id' ? 'Jenis Pertimbangan' : 'Consideration Type'}
                </InputLabel>
                <RadioGroup
                  aria-label="Planning"
                  name="planning"
                  value={selectedOption}
                  onChange={handleOptionChange}
                  row
                >
                  <FormControlLabel
                    value="fix"
                    control={<Radio />}
                    label={
                      <Typography variant="body1" color="textPrimary">
                        {lang === 'Id' ? 'Pasti' : 'Fix'}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="planning"
                    control={<Radio />}
                    label={
                      <Typography variant="body1" color="textPrimary">
                        {lang === 'Id' ? 'Rencana' : 'Planning'}
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Box>
            </Grid>
          </Grid>
          {isFocusOption && !ValidateString(selectedOption) && (
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontWeight={400}
              fontSize={'12px'}
              lineHeight={'15px'}
              letterSpacing={'1px'}
              color={'red'}
              my={'5px'}
            >
              {lang === 'Id' ? 'Pilih Salah Satu' : 'Select One'}
            </Typography>
          )}

          {/* --- Description --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  {lang === 'Id' ? 'Alasan Resign' : 'Resignation Reason'}
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  required
                  multiline
                  minRows={5}
                  variant="outlined"
                  size="small"
                  placeholder={lang === 'Id' ? placeholderId : placeholderEn}
                  value={description}
                  onFocus={() => setIsFocusDescription(false)}
                  onBlur={() => setIsFocusDescription(true)}
                  onChange={(e) => setDescription(e.target.value)}
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{
                        position: 'absolute',
                        top: 23,
                      }}
                    >
                      {isFocusDescription ? <IconDescription /> : ''}
                    </InputAdornment>
                  }
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      background: 'transparent',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    background: '#F9F9F9',
                    '& textarea': {
                      paddingLeft: '24px',
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Button --- */}
          <Button
            disabled={isLoading}
            onClick={handleCheckRadio}
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: '136px', marginTop: '21px' }}
          >
            {lang === 'Id' ? 'Simpan' : 'Save'}
          </Button>
        </form>
      </Box>
    </Card>
  );
};

export default CoreHRResignAdd;
