import {
  Box,
  Typography,
  Avatar,
  Stack,
  Popper,
  IconButton,
  ClickAwayListener,
  Paper,
} from '@mui/material';
import { TooltipsAction } from '../../../../../atoms';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
  IconDelete,
  IconDetail,
  IconEdit,
} from '../../../../../../assets/commons';
import { IconTracking } from '../../../../../../assets/benefit/journey';
import { BenefitJourneyDetail } from '../../../../../../pages/benefit';
import { ModalDelete } from '../../../../modal';
import { handleAction } from '../../../../../../utils/function/action';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent, {
  timelineContentClasses,
} from '@mui/lab/TimelineContent';
import TimelineDot, { timelineDotClasses } from '@mui/lab/TimelineDot';
import SelectYear from '../../../../../molecules/year';
import {
  getJourneyById,
  getTrackJourneyById,
} from '../../../../../../configs/axios/apiPath';
const JourneyTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const yearNow = moment().format('YYYY');
  const year = [];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openPopper, setOpenPopper] = useState(false);
  const [idEmployee, setIdEmployee] = useState(null);
  const [modal, setModal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(null);
  const [idTracking, setIdTracking] = useState(null);
  const [detail, setDetail] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [journeyHistory, setJourneyHistory] = useState();
  const [selectedYear, setSelectedYear] = useState(yearNow);
  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }

  const handleClick = (event, id) => {
    setIdTracking(id);
    setAnchorEl(event.currentTarget);
    setOpenPopper((prevOpen) => !prevOpen);
  };
  const handleClose = () => {};

  const handleEdit = (id) => {
    navigate(`/benefit/journey/edit/${id}`);
  };

  // --- Handle Detail ---
  const handleDetail = async (id) => {
    setIsModalOpen(true);
    setId(id);
    setModal('detail');
  };

  useEffect(() => {
    async function fetchData() {
      if (id !== null) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getJourneyById(id);
          setDetail(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [id, navigate]);

  useEffect(() => {
    async function fetchData() {
      if (idTracking !== null) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getTrackJourneyById({ year: selectedYear }, idTracking);
          setJourneyHistory(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [idTracking, navigate, selectedYear]);
  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Employee --- */}
            <td
              style={{
                padding: '10px',
                verticalAlign: 'middle',
              }}
            >
              <Stack direction="row" alignItems="center" gap="10px">
                <Avatar src={item.profile_picture} />
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={400}
                  fontSize={'14px'}
                  lineHeight={'130%'}
                  color={'#171717'}
                >
                  {item.name}
                </Typography>
              </Stack>
            </td>

            {/* --- Place Of Visit --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item?.place}
              </Typography>
            </td>
            {/* --- Status --- */}
            <td>
              <Box
                sx={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    backgroundColor:
                      item.status === 200 ? '#51B15C1A' : '#9393931A',
                    border:
                      item.status === 200
                        ? '0.4px solid #51B15C'
                        : '0.4px solid #939393',
                    color: item.status === 200 ? '#51B15C' : '#C4C4C4',
                    width: '80px',
                    py: '6px',
                    borderRadius: '4px',
                    textAlign: 'center',
                    fontSize: '12px',
                    fontWeight: item.status === 200 ? '600' : '400',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{ position: 'absolute', right: '-10px', top: '-10px' }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="7.99961" cy="7.99961" r="6.4" fill="white" />
                      <path
                        d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8.728 12.92C8.67209 12.9748 8.60133 13.012 8.52447 13.027C8.44762 13.042 8.36806 13.0341 8.29566 13.0043C8.22326 12.9744 8.1612 12.924 8.11718 12.8593C8.07315 12.7945 8.0491 12.7183 8.048 12.64V12H8C6.976 12 5.952 11.608 5.168 10.832C4.62482 10.2875 4.24996 9.59808 4.08823 8.84617C3.92649 8.09426 3.98474 7.31168 4.256 6.592C4.408 6.184 4.944 6.08 5.248 6.392C5.424 6.568 5.464 6.824 5.384 7.048C5.016 8.04 5.224 9.192 6.024 9.992C6.584 10.552 7.32 10.816 8.056 10.8V10.048C8.056 9.688 8.488 9.512 8.736 9.768L10.032 11.064C10.192 11.224 10.192 11.472 10.032 11.632L8.728 12.92ZM10.752 9.616C10.6693 9.5309 10.6126 9.42399 10.5885 9.3078C10.5644 9.19161 10.574 9.07096 10.616 8.96C10.984 7.968 10.776 6.816 9.976 6.016C9.416 5.456 8.68 5.184 7.952 5.2V5.952C7.952 6.312 7.52 6.488 7.272 6.232L5.968 4.944C5.808 4.784 5.808 4.536 5.968 4.376L7.264 3.08C7.31991 3.02518 7.39068 2.98798 7.46753 2.973C7.54438 2.95803 7.62394 2.96594 7.69634 2.99575C7.76874 3.02556 7.8308 3.07596 7.87482 3.14071C7.91885 3.20546 7.9429 3.28171 7.944 3.36V4.008C8.984 3.992 10.032 4.368 10.824 5.168C11.3672 5.7125 11.742 6.40192 11.9038 7.15383C12.0655 7.90574 12.0073 8.68832 11.736 9.408C11.584 9.824 11.056 9.928 10.752 9.616Z"
                        fill="#352CCF"
                      />
                    </svg>
                  </Box>
                  {item.status === 200 ? 'Active' : 'Not Active'}
                </Box>
              </Box>
            </td>

            {/* --- End Date --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {moment(item?.end_Date).format('D MMM')}
              </Typography>
            </td>
            {/* --- Action --- */}
            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={1}
              >
                <TooltipsAction title="Detail" arrow placement={'bottom-end'}>
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleDetail(item.id)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>

                {/* --- Delete --- */}
                <TooltipsAction title="Delete" arrow placement={'bottom-end'}>
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        'delete'
                      )
                    }
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>

                {/* --- Edit --- */}
                <TooltipsAction title="Edit" arrow placement={'bottom-end'}>
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleEdit(item.id)}
                  >
                    <IconEdit />
                  </Box>
                </TooltipsAction>
                <TooltipsAction title="Tracking" arrow placement={'bottom-end'}>
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={(event) => handleClick(event, item.employee_id)}
                  >
                    <IconTracking />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
      <Popper open={openPopper} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper>
            {isLoading ? (
              'Loading'
            ) : (
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                  [`& .${timelineDotClasses.root}`]: {
                    margin: 0,
                  },

                  [`& .${timelineContentClasses.root}`]: {
                    padding: '1px 0px 1px 12px',
                  },
                }}
              >
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot variant="outlined" />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography>Tracking</Typography>
                      <SelectYear
                        year={year}
                        selectedYear={selectedYear}
                        setSelectedYear={setSelectedYear}
                      />
                    </Box>
                  </TimelineContent>
                </TimelineItem>
                {journeyHistory &&
                  journeyHistory.map((journey, index) => (
                    <TimelineItem key={index}>
                      <TimelineSeparator>
                        <TimelineDot color="primary" />
                        {journeyHistory.length - 1 !== index && (
                          <TimelineConnector />
                        )}
                      </TimelineSeparator>
                      <TimelineContent>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Stack direction="row">
                            <Avatar
                              sx={{ width: 24, height: 24 }}
                              src={journey?.employe?.profile_picture}
                            />
                            <Stack ml="6px">
                              <Typography fontSize="10px">
                                {journey?.employe?.name}
                              </Typography>
                              <Typography
                                color="primary"
                                fontSize="10px"
                                fontWeight={600}
                              >
                                {moment(journey.start_date).format('D MMM')},{' '}
                                {journey.place}
                              </Typography>
                            </Stack>
                          </Stack>

                          <IconButton onClick={() => handleDetail(journey.id)}>
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 6C12 2.688 9.312 0 6 0C2.688 0 0 2.688 0 6C0 9.312 2.688 12 6 12C9.312 12 12 9.312 12 6ZM6 7.674V6.6H4.2C3.87 6.6 3.6 6.33 3.6 6C3.6 5.67 3.87 5.4 4.2 5.4H6V4.326C6 4.056 6.324 3.924 6.51 4.116L8.184 5.79C8.304 5.91 8.304 6.096 8.184 6.216L6.51 7.89C6.4677 7.93146 6.41408 7.95948 6.35589 7.97051C6.29769 7.98155 6.23754 7.97511 6.183 7.95201C6.12846 7.92891 6.08198 7.89018 6.04941 7.84071C6.01684 7.79124 5.99965 7.73323 6 7.674Z"
                                fill="#AE28C4"
                              />
                            </svg>
                          </IconButton>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
              </Timeline>
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
      {modal === 'detail' ? (
        <BenefitJourneyDetail
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          detail={detail}
          isLoading={isLoading}
        />
      ) : modal === 'delete' ? (
        <ModalDelete
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={'Journey'}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          id={idEmployee}
          data={tbody}
          setData={setData}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default JourneyTbody;
