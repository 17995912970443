import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguage } from '../../../../features/language';
import { IconButton, Typography } from '@mui/material';
import { setNavigation } from '../../../../features/navigation';
import { Outlet, useNavigate } from 'react-router-dom';
import { convertToDashCase } from '../../../../utils/format';
import { MenuPayroll } from '../../../../data/menu';

const PayrollTabPanel = () => {
  const [value, setValue] = React.useState(() => {
    const parsedData = JSON.parse(localStorage.getItem('cvSayaData'));
    const menu = parsedData && parsedData.payrollMenu;
    return menu ? parseInt(menu) : 0;
  });

  const lang = useSelector(getLanguage);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    const title = MenuPayroll[newValue].title.toLowerCase();
    const url = convertToDashCase(title);
    const navigation =
      lang === 'Id' ? MenuPayroll[newValue].judul : MenuPayroll[newValue].title;

    setValue(newValue);
    navigate(`${url}`);
    dispatch(setNavigation(navigation));
  };

  React.useEffect(() => {
    const data = JSON.parse(localStorage.getItem('cvSayaData'));
    if (data) {
      data.payrollMenu = value.toString();
      localStorage.setItem('cvSayaData', JSON.stringify(data));
    }
  }, [value]);

  return (
    <Box>
      {/* --- Tab Panel --- */}
      <Box
        sx={{
          maxWidth: {
            xl: '96vw',
            lg: '96vw',
            md: '89vw',
            xs: '75vw',
            sm: '84vw',
          },
          p: '0px',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="coreHR"
        >
          {MenuPayroll.map((item, idx) => (
            <Tab
              label={
                <Box display={'flex'} alignItems={'center'}>
                  {/* --- Icon --- */}
                  <IconButton
                    sx={{
                      mr: '5px',
                      border:
                        item.id === value
                          ? '1px solid #51B15C'
                          : '1px solid #303030',
                    }}
                  >
                    {React.cloneElement(item.icon, {
                      style: {
                        fill: item.id === value ? '#51B15C' : '#303030',
                      },
                    })}
                  </IconButton>
                  {/* --- Title --- */}
                  <Box
                    ml={'16px'}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'start'}
                  >
                    <Typography
                      fontFamily={'sans-serif'}
                      fontStyle={'normal'}
                      fontWeight={600}
                      fontSize={'14px'}
                      lineHeight={'19px'}
                      textTransform={'none'}
                      mb={'2px'}
                    >
                      {lang === 'Id' ? item.judul : item.title}
                    </Typography>
                    <Typography
                      fontFamily={'sans-serif'}
                      fontStyle={'normal'}
                      fontWeight={400}
                      fontSize={'12px'}
                      lineHeight={'16px'}
                      textTransform={'none'}
                      color={'#939393'}
                    >
                      {lang === 'Id' ? item.deskripsi : item.description}
                    </Typography>
                  </Box>
                </Box>
              }
              key={idx}
            />
          ))}
        </Tabs>
      </Box>
      {/* --- Outlet --- */}
      <Outlet />
    </Box>
  );
};

export default PayrollTabPanel;
