const DataTableAttendanceHistory = [
  {
    title: 'Name Employee',
    judul: 'Nama Karyawan',
    url: 'name',
  },
  { title: '1', judul: '1', url: 'name' },
  { title: '2', judul: '2', url: 'name' },
  { title: '3', judul: '3', url: 'name' },
  { title: '4', judul: '4', url: 'name' },
  { title: '5', judul: '5', url: 'name' },
  { title: '6', judul: '6', url: 'name' },
  { title: '7', judul: '7', url: 'name' },
  { title: '8', judul: '8', url: 'name' },
  { title: '9', judul: '9', url: 'name' },
  { title: '10', judul: '10', url: 'name' },
  { title: '11', judul: '11', url: 'name' },
  { title: '12', judul: '12', url: 'name' },
  { title: '13', judul: '13', url: 'name' },
  { title: '14', judul: '14', url: 'name' },
  { title: '15', judul: '15', url: 'name' },
  { title: '16', judul: '16', url: 'name' },
  { title: '17', judul: '17', url: 'name' },
  { title: '18', judul: '18', url: 'name' },
  { title: '19', judul: '19', url: 'name' },
  { title: '20', judul: '20', url: 'name' },
  { title: '21', judul: '21', url: 'name' },
  { title: '22', judul: '22', url: 'name' },
  { title: '23', judul: '23', url: 'name' },
  { title: '24', judul: '24', url: 'name' },
  { title: '25', judul: '25', url: 'name' },
  { title: '26', judul: '26', url: 'name' },
  { title: '27', judul: '27', url: 'name' },
  { title: '28', judul: '28', url: 'name' },
  { title: '29', judul: '29', url: 'name' },
  { title: '30', judul: '30', url: 'name' },
  { title: '31', judul: '31', url: 'name' },
];

export default DataTableAttendanceHistory;
