// calendarSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getIndexCalendar,
  postCalendar,
  getListCalendar,
} from "../../configs/axios/apiPath";

// fetch data calendar
export const fetchIndexCalendar = createAsyncThunk(
  "calendar/fetchIndex",
  async (params) => {
    const response = await getIndexCalendar(params);

    function getColor(color, type) {
      switch (color) {
        case "red":
          return type === "primary" ? "#E40A0A" : "#FFE8E8";

        case "blue":
          return type === "primary" ? "#352CCF" : "#E7EFFA";

        case "green":
          return type === "primary" ? "#51B15C" : "#EEF7EF";
        default:
          return type === "primary" ? "#939393" : "#F3F3F3";
      }
    }
    const events = response.data.data;

    const uniqueIds = new Set();
    return Object.values(events)
      .filter((arr) => arr.length > 0)
      .flatMap((events) =>
        events
          .filter((event) => !uniqueIds.has(event.id))
          .map((event) => {
            uniqueIds.add(event.id);
            return {
              event_id: event.id,
              event_color: event.color,
              title: event.name,
              start: event.start_date,
              end: event.end_date,
              classNames: [event.id === -1 ? "hidden" : undefined],
              description: event.description,
              backgroundColor: getColor(event.color, "secondary"),
              borderColor: getColor(event.color, "secondary"),
              ...event,
            };
          }),
      );
  },
);

// add data to calendar

export const createNewCalendar = createAsyncThunk(
  "calendar/createNew",
  async (calendarData) => {
    const config = {
      headers: {
        "Content-Type": "application/json", // Set the Content-Type header
      },
    };

    const response = await postCalendar(calendarData, config); // Pass the config object
    console.info(response.data.data);
    return response.data.data;
  },
);

const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIndexCalendar.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIndexCalendar.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchIndexCalendar.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createNewCalendar.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNewCalendar.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(createNewCalendar.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default calendarSlice.reducer;
