import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Card } from '../../../../components/atoms';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import {
  getComplaintsById,
  getImageDocument,
  getListEmployee,
  postComplaints,
  postImageDocument,
  updateComplaintsById,
} from '../../../../configs/axios/apiPath';
import { getCompany } from '../../../../features/companies';
import { getLanguage } from '../../../../features/language';
import {
  IconCalendar,
  IconDescription,
  IconEmployee,
  IconImage,
} from '../../../../assets/coreHr/awards';
import { IconName } from '../../../../assets/coreHr/complaint';
import { ValidateArray, ValidateString } from '../../../../utils/validate';
import DataLogin from '../../../../data/dataLogin';
import { IconClose } from '../../../../assets/commons';

const CoreHRComplaintEdit = () => {
  const [inputValue, setInputValue] = useState('');
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [total, setTotal] = useState(1);
  const [errorMsg, setErrorMsg] = useState('');
  const [dataImage, setDataImage] = useState([]);
  const [images, setImages] = useState('');
  const [temporaryImage, setTemporaryImage] = useState([]);

  const company = useSelector(getCompany);
  const navigate = useNavigate();
  const getData = DataLogin();
  const lang = useSelector(getLanguage);
  const placeholderId = 'Ketik disini';
  const placeholderEn = 'Type here';

  const [isLoading, setIsLoading] = useState(false);
  const [isFocusEmployee, setIsFocusEmployee] = useState(false);
  const [isFocusComplaint, setIsFocusComplaint] = useState(false);
  const [isFocusDate, setIsFocusDate] = useState(false);
  const [isFocusImage, setIsFocusImage] = useState(false);
  const [isFocusDescription, setIsFocusDescription] = useState(false);

  const MAX_IMAGE_SIZE = 1 * 1024 * 1024;

  const path = useLocation().pathname;
  const splitPath = path.split('/');
  const id = splitPath[4];

  // --- Handle Submit ---
  const handleSubmit = async (event) => {
    event.preventDefault();
    const ids = dataImage.map((item, _) => {
      return item;
    });

    if (company) {
      const data = {
        employee_id: selectedEmployee?.id,
        complaint_name: name,
        date: date,
        description: description,
        document_ids: ids,
      };

      setIsLoading(true);
      try {
        const response = await updateComplaintsById(data, id);

        if (
          response.data &&
          response.data.data &&
          response.data.data.image_url
        ) {
          setImages(response.data.data.image_url);
        } else {
          setImages(null);
        }
        navigate('/core-hr/complaint');
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          localStorage.removeItem('cvDataLogin');
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  // --- List Employee ---
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          page_size: total,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListEmployee(params);

          setEmployeeData(data.employee);
          if (data.page_info.total > 0) {
            setTotal(data.page_info.total);
          }
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, total]);

  // --- Handle Input Change ---
  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  // --- Handle Selected Employee ---
  const handleSelectEmployee = (event, value) => {
    setSelectedEmployee(value);
  };

  // --- Handle Upload Image ---
  const handleFileChange = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (file) {
      // Validasi tipe file
      if (!file.type.startsWith('image/')) {
        const msgId =
          'Jenis file tidak valid. Hanya file gambar yang diizinkan.';
        const msgEn = 'Invalid file type. Only image files are allowed.';
        if (lang === 'Id') {
          setErrorMsg(msgId);
        } else {
          setErrorMsg(msgEn);
        }
        return;
      }

      // Validasi ukuran file
      if (file.size > MAX_IMAGE_SIZE) {
        const msgId =
          'Ukuran file melebihi batas maksimum. Silakan pilih file yang lebih kecil.';
        const msgEn =
          'File size exceeds the maximum limit. Please choose a smaller file.';
        if (lang === 'Id') {
          setErrorMsg(msgId);
        } else {
          setErrorMsg(msgEn);
        }
        return;
      }
      const datas = {
        type: 10,
        file: file,
      };
      setIsLoading(true);
      try {
        const {
          data: { data },
        } = await postImageDocument(datas, getData.token);

        setDataImage([...dataImage, data.id]);
        fetchData(data.id);
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  // --- Show Image ---
  const fetchData = async (newId) => {
    setIsLoading(true);
    try {
      const response = await getImageDocument(newId, getData.token);
      const contentType = response.headers['content-type'];
      const blob = new Blob([response.data], { type: contentType });
      const objectURL = URL.createObjectURL(blob);
      setTemporaryImage([...temporaryImage, objectURL]);
    } catch (error) {
      console.error(error.response.status);
      if (error.response.status === 401) {
        localStorage.removeItem('cvSayaLogin');
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // --- Handle Delete Image ---
  const handleDeleteImage = (idx) => {
    const newTemporaryImage = [...temporaryImage];
    const newDataImage = [...temporaryImage];
    newTemporaryImage.splice(idx, 1);
    newDataImage.splice(idx, 1);
    setTemporaryImage(newTemporaryImage);
    setDataImage(newDataImage);
  };

  // --- handle validation image ---
  const handleValidationImage = () => {
    setIsFocusImage(true);
    setErrorMsg('');
  };

  // --- Placeholder ---
  useEffect(() => {
    const fetchPlaceholder = async () => {
      if (company && id) {
        const params = {
          company_id: company,
        };
        setIsLoading(true);

        try {
          const {
            data: { data },
          } = await getComplaintsById(params, id);
          const imageIds = data?.documents.map((document) => document.id);
          setName(data?.complaint_name);
          setSelectedEmployee(data.employee);
          setDate(data?.date);
          setDataImage(imageIds);
          setDescription(data?.description);
          const doc = data?.documents;
          const arrImage = [];
          for (let i = 0; i < doc.length; i++) {
            const imageBlob = await fetchData(doc[i].id);
            arrImage.push(imageBlob);
          }
          setTemporaryImage(arrImage);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvDataLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchPlaceholder();
  }, [company, id]);

  return (
    <Card>
      <Box p={'20px'}>
        <Box borderBottom={'0.2px solid #939393'} p={'0px 0px 15px 0px'}>
          <Typography
            fontWeight={'600px'}
            fontSize={'20px'}
            lineHeight={'27px'}
            letterSpacing={'0.01em'}
            color={'#171717'}
          >
            Edit
          </Typography>
        </Box>
        <form
          onSubmit={handleSubmit}
          style={{
            marginTop: '20px',
            fontWeight: '600px',
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '0.01em',
            color: '#171717',
          }}
        >
          {/* --- List Employee --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel style={{ marginBottom: '5px' }}>
                  {lang === 'Id' ? 'Karyawan' : 'Employee'}
                </InputLabel>
                <Autocomplete
                  value={selectedEmployee}
                  onChange={handleSelectEmployee}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  options={employeeData}
                  getOptionLabel={(employee) => employee.name}
                  onFocus={() => setIsFocusEmployee(false)}
                  onBlur={() => setIsFocusEmployee(true)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      fullWidth
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: false }}
                      placeholder={
                        lang === 'Id' ? placeholderId : placeholderEn
                      }
                      sx={{
                        background: '#f9f9f9',
                        '& .MuiOutlinedInput-root': {
                          background: 'transparent',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start" sx={{ pl: '7px' }}>
                            {isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              isFocusEmployee && <IconEmployee />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Name Complaint --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  {lang === 'Id' ? 'Nama Keluhan' : 'Name Complaints'}
                </InputLabel>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  placeholder={lang === 'Id' ? placeholderId : placeholderEn}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  onFocus={() => setIsFocusComplaint(false)}
                  onBlur={() => setIsFocusComplaint(true)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {isFocusComplaint ? <IconName /> : ''}
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      background: '#f9f9f9',
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                      },
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Date --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  {lang === 'Id' ? 'Tanggal Keluhan' : 'Complaint Date'}
                </InputLabel>
                <TextField
                  required
                  fullWidth
                  size="small"
                  variant="outlined"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  onFocus={() => setIsFocusDate(false)}
                  onBlur={() => setIsFocusDate(true)}
                  sx={{
                    background: '#f9f9f9',
                    '& .MuiOutlinedInput-root': {
                      background: 'transparent',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {isFocusDate ? <IconCalendar /> : ''}
                      </InputAdornment>
                    ),
                    style: {
                      height: '40px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Image --- */}
          <Box
            display={'flex'}
            flexDirection={'column'}
            mb={isFocusImage && !ValidateArray(temporaryImage) ? '0px' : '16px'}
          >
            <InputLabel style={{ marginBottom: '5px' }}>
              {lang === 'Id' ? 'Gambar keluhan' : 'Complaint Image'}
            </InputLabel>
            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                <TextField
                  fullWidth
                  required
                  disabled
                  size="small"
                  variant="outlined"
                  placeholder={lang === 'Id' ? placeholderId : placeholderEn}
                  value={dataImage}
                  onChange={(e) => setDataImage(e.target.value)}
                  onFocus={() => setIsFocusImage(true)}
                  onBlur={() => setIsFocusImage(false)}
                  sx={{
                    background: '#f9f9f9',
                    '& .MuiOutlinedInput-root': {
                      background: 'transparent',
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover:not(.Mui-disabled) fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-disabled fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {!isFocusImage ? <IconImage /> : ''}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button
                          disabled={isLoading}
                          onClick={handleValidationImage}
                          component="label"
                          style={{
                            background: '#fff',
                            boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.1)',
                          }}
                          sx={{
                            borderRadius: '8px',
                            my: '5px',
                          }}
                        >
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            width={'64px'}
                          >
                            <Typography
                              fontFamily={'sans-serif'}
                              fontStyle={'normal'}
                              fontWeight={400}
                              fontSize={'10px'}
                              lineHeight={'14px'}
                              letterSpacing={'0.02em'}
                              color={'#171717'}
                              textTransform={'none'}
                            >
                              {lang === 'Id' ? 'Unggah' : 'Browse'}
                            </Typography>
                            {isLoading && (
                              <CircularProgress
                                color="secondary"
                                sx={{ ml: '8px' }}
                                size={15}
                              />
                            )}
                          </Box>
                          <input
                            hidden
                            accept="image/*"
                            multiple
                            type="file"
                            onChange={handleFileChange}
                          />
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={2}
                xs={2}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box ml={'10px'}>
                  {ValidateArray(temporaryImage) ? (
                    <Box position="relative" width={50}>
                      <Box display={'flex'}>
                        {temporaryImage.map((item, idx) => (
                          <Box
                            position="relative"
                            width={50}
                            key={idx}
                            mr={'12px'}
                          >
                            <img
                              src={item}
                              alt="image"
                              width={50}
                              height={50}
                              style={{
                                borderRadius: '8px',
                              }}
                            />
                            <IconButton
                              size="small"
                              onClick={() => handleDeleteImage(idx)}
                              sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                backgroundColor: 'white',
                                p: '0px',
                              }}
                            >
                              <IconClose />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontWeight={600}
                        fontSize={'10px'}
                        lineHeight={'14px'}
                        letterSpacing={'1px'}
                        color={'#171717'}
                      >
                        {lang === 'Id'
                          ? 'Tidak ada file yang dipilih'
                          : 'No File Choosen'}
                      </Typography>
                      <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontWeight={400}
                        fontSize={'8px'}
                        lineHeight={'11px'}
                        letterSpacing={'1px'}
                        color={'#303030'}
                      >
                        {lang === 'Id'
                          ? 'Unggah hanya file png, jpg, jpeg dan ukuran maksimal 1Mb'
                          : 'Upload files only png, jpg, jpeg and size 1Mb'}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
            {isFocusImage && !ValidateArray(temporaryImage) && (
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'15px'}
                letterSpacing={'1px'}
                color={'red'}
                my={'5px'}
              >
                {lang === 'Id' ? 'Harus diisi' : "Can't Be Empty"}
              </Typography>
            )}
            {errorMsg && (
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'12px'}
                lineHeight={'15px'}
                letterSpacing={'1px'}
                color={'red'}
                my={'5px'}
              >
                {errorMsg}
              </Typography>
            )}
          </Box>

          {/* --- Description --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Box display={'flex'} flexDirection={'column'} mb={'16px'}>
                <InputLabel
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '13px',
                    lineHeight: '18px',
                    letterSpacing: '1px',
                    marginBottom: '6px',
                  }}
                >
                  {lang === 'Id' ? 'Alasan Resign' : 'Resignation Reason'}
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  required
                  multiline
                  minRows={5}
                  variant="outlined"
                  size="small"
                  placeholder={lang === 'Id' ? placeholderId : placeholderEn}
                  value={description}
                  onFocus={() => setIsFocusDescription(false)}
                  onBlur={() => setIsFocusDescription(true)}
                  onChange={(e) => setDescription(e.target.value)}
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{
                        position: 'absolute',
                        top: 23,
                      }}
                    >
                      {isFocusDescription ? <IconDescription /> : ''}
                    </InputAdornment>
                  }
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      background: 'transparent',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent',
                    },
                    background: '#F9F9F9',
                    '& textarea': {
                      paddingLeft: '24px',
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => setIsFocusImage(true)}
            sx={{ width: '136px', marginTop: '21px' }}
          >
            {lang === 'Id' ? 'Simpan' : 'Save'}
          </Button>
        </form>
      </Box>
    </Card>
  );
};

export default CoreHRComplaintEdit;
