import { Grid } from "@mui/material"
import { MainDetailTask, SideDetailTask } from "../../../../../components/organisms/project"
import { useParams, useSearchParams } from "react-router-dom"

const DetailTask = () => {
  const {id} = useParams();
  const [searchParams] = useSearchParams();

  return(
    <Grid container spacing={0.5}>
      <Grid item md={9} sm={12} xs={12}>
        <MainDetailTask
          projectId={searchParams.get('px')}
          boardId={searchParams.get('bx')}
          taskId={id}
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <SideDetailTask
          projectId={searchParams.get('px')}
          boardId={searchParams.get('bx')}
          taskId={id}
        />
      </Grid>      
    </Grid>
  )
}

export default DetailTask