import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar} from 'react-chartjs-2';
import { Box } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function LineChart({ data, dataTooltips }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        align: 'end',
      },
    },
  };

  return (
    <Box>
      <Box id="chartjs-tooltip"></Box>
      <Bar data={data} options={options} />
    </Box>
  );
}
