const DataTableLogView = [
    {
        title: 'User',
        judul: 'User',
        url: 'name',
    },  
    {
        title: 'Hours',
        judul: 'Jam',
        url: 'hour',
    },
    {
        title: 'IP',
        judul: 'IP',
        url: 'ip_address',
    },
    {
        title: 'Last Login',
        judul: 'Login Terakhir',
        url: 'date_login',
    },
    {
        title: 'Role',
        judul: 'Peran',
        url: 'roles',
    },
]

export default DataTableLogView;