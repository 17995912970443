export const ScheduleOptions = {
  sliceVisibilityThreshold: 0, // 20%
  chartArea: {
      width: '100%',
      height: '80%',
    },
  legend: {
    position: 'left',
  },
  pieSliceText: 'value',
};
