import React from "react";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
function getColor(name) {
  switch (name) {
    case "Recommended":
      return "#51B15C";

    case "Hold":
      return "#CF8812";

    case "Bad":
      return "#E40A0A";
    default:
      return "#ffffff";
  }
}

function getIcon(name) {
  switch (name) {
    case "Recommended":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="19"
          fill="none"
          viewBox="0 0 22 19"
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M5.503 3.27c0-.735.28-1.44.78-1.959C6.784.791 7.464.5 8.17.5h2.666c.707 0 1.386.292 1.886.811.5.52.781 1.224.781 1.958v.19c.83.078 1.65.181 2.466.308 1.292.201 2.2 1.369 2.2 2.693v2.8c0 1.117-.652 2.17-1.72 2.54a21.24 21.24 0 01-6.946 1.162c-2.36.002-4.706-.39-6.946-1.162C1.488 11.43.836 10.377.836 9.26v-2.8c0-1.324.908-2.493 2.2-2.693.82-.127 1.642-.23 2.467-.308v-.19zm6.667 0v.082a42.365 42.365 0 00-5.334 0V3.27c0-.367.14-.72.39-.979.25-.26.59-.405.944-.405h2.666c.354 0 .693.146.943.405.25.26.39.612.39.98zm-2.667 7.615a.655.655 0 00.471-.203.706.706 0 00.196-.49c0-.183-.07-.36-.196-.489a.654.654 0 00-.471-.203.654.654 0 00-.472.203.706.706 0 00-.195.49c0 .183.07.36.195.49.125.129.295.202.472.202z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#fff"
            d="M1.5 15.407v-2.58c.203.114.415.21.634.285A22.525 22.525 0 009.5 14.346c2.571 0 5.05-.432 7.367-1.233.224-.077.435-.174.633-.287v2.581c0 1.34-.93 2.518-2.242 2.698a42.128 42.128 0 01-11.515 0C2.43 17.925 1.5 16.747 1.5 15.407z"
          ></path>
          <rect
            width="10.667"
            height="11.077"
            x="11.332"
            y="7.25"
            fill="#51B15C"
            rx="5.334"
          ></rect>
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M13.664 11.79l-1 1 3 3 5-5-1-1-4 4-2-2z"
            clipRule="evenodd"
          ></path>
        </svg>
      );

    case "Bad":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="16"
          fill="none"
          viewBox="0 0 19 16"
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M4.43 3.108A2.268 2.268 0 016.696.841h2.268a2.268 2.268 0 012.267 2.267v.155c.706.065 1.404.15 2.097.253 1.1.165 1.872 1.12 1.872 2.205v2.292c0 .916-.555 1.778-1.464 2.08a18.69 18.69 0 01-5.906.952 18.684 18.684 0 01-5.907-.951C1.016 9.79.461 8.929.461 8.014V5.72c0-1.084.772-2.041 1.871-2.205a36.892 36.892 0 012.097-.253v-.155zm5.668 0v.068a37.406 37.406 0 00-4.535 0v-.068a1.134 1.134 0 011.134-1.133h2.268a1.134 1.134 0 011.133 1.133zM7.831 9.344a.567.567 0 100-1.133.567.567 0 000 1.133z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#fff"
            d="M1.027 13.048v-2.113c.173.094.353.172.54.234 2.02.67 4.135 1.012 6.263 1.01a19.82 19.82 0 006.264-1.01c.19-.063.37-.142.54-.234v2.113c0 1.098-.792 2.062-1.908 2.21a37.197 37.197 0 01-9.792 0c-1.115-.148-1.907-1.112-1.907-2.21z"
          ></path>
          <rect
            width="9.071"
            height="9.071"
            x="9.391"
            y="6.368"
            fill="#E40A0A"
            rx="4.535"
          ></rect>
          <path
            fill="#fff"
            d="M13.925 10.348l1.945-1.945.556.556-1.945 1.945 1.945 1.944-.556.556-1.945-1.945-1.944 1.945-.555-.556 1.944-1.945-1.944-1.944.555-.555 1.944 1.945v-.001z"
          ></path>
        </svg>
      );

    case "Hold":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          height="16"
          fill="none"
          viewBox="0 0 19 16"
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M4.93 2.608A2.268 2.268 0 017.196.341h2.268a2.268 2.268 0 012.267 2.267v.155c.706.065 1.404.15 2.097.253 1.1.165 1.872 1.12 1.872 2.205v2.292c0 .916-.555 1.778-1.464 2.08a18.69 18.69 0 01-5.906.952 18.683 18.683 0 01-5.907-.951C1.516 9.29.961 8.429.961 7.514V5.22c0-1.084.772-2.041 1.872-2.205a36.892 36.892 0 012.096-.253v-.155zm5.668 0v.068a37.406 37.406 0 00-4.535 0v-.068a1.134 1.134 0 011.134-1.133h2.268a1.134 1.134 0 011.133 1.133zM8.331 8.844a.567.567 0 100-1.133.567.567 0 000 1.133z"
            clipRule="evenodd"
          ></path>
          <path
            fill="#fff"
            d="M1.527 12.548v-2.113c.173.094.353.172.54.234 2.02.67 4.135 1.012 6.263 1.01a19.82 19.82 0 006.264-1.01c.19-.063.37-.142.54-.234v2.113c0 1.098-.792 2.062-1.908 2.21a37.197 37.197 0 01-9.792 0c-1.115-.148-1.907-1.112-1.907-2.21z"
          ></path>
          <rect
            width="9.071"
            height="9.071"
            x="9.891"
            y="5.868"
            fill="#CF8812"
            rx="4.535"
          ></rect>
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.4"
            d="M12.227 11.203v-2.2a.4.4 0 11.8 0v1.4-2.2a.4.4 0 01.8 0v2-2.4a.4.4 0 11.8 0v2.4m0 .013V8.403a.4.4 0 11.8 0v2.8"
          ></path>
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0.4"
            d="M12.227 11.204c0 1.467.8 2.2 1.9 2.2 1.1 0 1.546-.495 1.8-1.1l.658-1.8c.084-.226.046-.435-.148-.545-.194-.11-.449-.054-.554.146l-.457 1.099"
          ></path>
        </svg>
      );
    default:
      return (
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.8776 9.38311V12.6886C14.8776 13.5395 14.2655 14.2722 13.4216 14.3842C11.7984 14.5996 10.1425 14.7109 8.46094 14.7109C6.77939 14.7109 5.12351 14.5996 3.5003 14.3842C2.65642 14.2722 2.04431 13.5395 2.04431 12.6886V9.38311M14.8776 9.38311C15.0623 9.22261 15.21 9.02405 15.3107 8.80105C15.4114 8.57805 15.4626 8.3359 15.4609 8.09123V5.14891C15.4609 4.30814 14.8636 3.5817 14.0321 3.45725C13.1511 3.32536 12.2657 3.22496 11.3776 3.15626M14.8776 9.38311C14.7267 9.51144 14.5509 9.61255 14.3541 9.67866C12.4534 10.3093 10.4635 10.6297 8.46094 10.6275C6.45836 10.6294 4.46853 10.309 2.56775 9.67866C2.37584 9.61482 2.1981 9.51446 2.04431 9.38311M2.04431 9.38311C1.85962 9.22261 1.71186 9.02405 1.61117 8.80105C1.51048 8.57805 1.45924 8.3359 1.46098 8.09123V5.14891C1.46098 4.30814 2.05831 3.5817 2.88975 3.45725C3.77073 3.32536 4.65614 3.22496 5.54429 3.15626M11.3776 3.15626V2.46093C11.3776 1.9968 11.1932 1.55168 10.865 1.2235C10.5368 0.895311 10.0917 0.710938 9.6276 0.710938H7.29428C6.83015 0.710938 6.38504 0.895311 6.05685 1.2235C5.72866 1.55168 5.54429 1.9968 5.54429 2.46093V3.15626M11.3776 3.15626C9.43605 3.00621 7.48583 3.00621 5.54429 3.15626M8.46094 8.29422H8.46716V8.30045H8.46094V8.29422Z"
            stroke="#939393"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
  }
}
export default function StatusMenu({ onChange, value,disabled }) {
  const statusList = [
    {
      id: 1,
      name: "Recommended",
    },
    {
      id: 2,
      name: "Hold",
    },
    {
      id: 3,
      name: "Bad",
    },
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(value ? value : null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disabled={disabled}
        style={{
          cursor: "pointer",
          border: selected ? "none" : "0.4px solid var(--gray-SOLID, #939393)",
          borderRadius: "8px",
          background: getColor(
            statusList.find((e) => e.id === selected)?.name ?? ""
          ),
          display: "flex",
          padding: "10px 16px",
          justifyContent: "center",
          alignItems: "center",
          gap: "4px",
          color: selected ? "#FFF" : "#939393",
          fontFamily: "Open Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "normal",
        }}
      >
        {getIcon(statusList.find((e) => e.id === selected)?.name ?? "")}
        {statusList.find((e) => e.id === selected)?.name ?? "No Status yet"}
        <svg
          width="7"
          height="5"
          viewBox="0 0 7 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.99441 4.39529C3.72109 4.66861 3.27721 4.66861 3.00389 4.39529L0.205053 1.59645C0.00388728 1.39529 -0.0551506 1.09573 0.0541788 0.833335C0.163508 0.570945 0.417152 0.400391 0.701409 0.400391H6.29907C6.58114 0.400391 6.83697 0.570945 6.9463 0.833335C7.05563 1.09573 6.99441 1.39529 6.79543 1.59645L3.9966 4.39529H3.99441Z"
            fill={selected ? "white" : "#939393"}
          />
        </svg>
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {statusList.map((e) => (
          <MenuItem
            onClick={() => {
              setSelected(e.id);
              onChange(e.id);
              handleClose();
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <Typography>{e.name}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
