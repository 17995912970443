import { IconResign, IconTerminate } from "../../../assets/coreHr/resign";

const dataResignFooter = [
    {
        title: 'Resign',
        judul: 'Berhenti',
        icon: <IconResign />,
    },
    {
        title: 'Terminate',
        judul: 'Mengakhiri',
        icon: <IconTerminate />,
    },
]

export default dataResignFooter;