const MenuProject = [
  {
    id: 0,
    title: 'Boards'
  },
  {
    id: 0,
    title: 'Timeline'
  },
  {
    id: 0,
    title: 'Discussion'
  },
  {
    id: 0,
    title: 'Activities'
  }
];

export default MenuProject;