import React, { forwardRef } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const DatePicker = ({ value, onChange, format, selectRange }) => {
  const [startDate, endDate] = value;
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
      onClick={onClick}
      ref={ref}
      value={value}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            /* sx={ */
            /*   props.multiline && */
            /*   props.rows > 1 && { alignSelf: "start", marginTop: "10px" } */
            /* } */
          >
            <svg
              width="9"
              height="9"
              viewBox="0 0 9 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1887_22640)">
                <path
                  d="M7.15149 1.32478H6.79868V0.971958C6.79868 0.777908 6.63991 0.619141 6.44586 0.619141C6.25181 0.619141 6.09304 0.777908 6.09304 0.971958V1.32478H2.56487V0.971958C2.56487 0.777908 2.4061 0.619141 2.21205 0.619141C2.018 0.619141 1.85923 0.777908 1.85923 0.971958V1.32478H1.50642C1.11832 1.32478 0.800781 1.64231 0.800781 2.03041V7.67549C0.800781 8.06358 1.11832 8.38112 1.50642 8.38112H7.15149C7.53959 8.38112 7.85713 8.06358 7.85713 7.67549V2.03041C7.85713 1.64231 7.53959 1.32478 7.15149 1.32478ZM6.79868 7.67549H1.85923C1.66518 7.67549 1.50642 7.51672 1.50642 7.32267V3.08886H7.15149V7.32267C7.15149 7.51672 6.99272 7.67549 6.79868 7.67549Z"
                  fill="#51B15C"
                />
              </g>
              <defs>
                <clipPath id="clip0_1887_22640">
                  <rect
                    width="8.46761"
                    height="8.46761"
                    fill="white"
                    transform="translate(0.09375 0.265625)"
                  />
                </clipPath>
              </defs>
            </svg>
          </InputAdornment>
        ),
        style: {
          paddingLeft: '15px',
          paddingRight: '15px',
          fontWeight: 600,
        },
      }}
      sx={{
        color: 'red',
        borderRadius: '6px',
        minWidth: '256px',
        width: '100%',
        background: '#F7F7F7',
        borderColor: 'transparent',
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
      }}
    />
  ));
  return (
    <ReactDatePicker
      dateFormat={format || 'dd/MMM/yy'}
      selectsRange={selectRange || true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        onChange(update);
      }}
      customInput={<ExampleCustomInput />}
    />
  );
};

export default DatePicker;
