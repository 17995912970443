import { API } from "../../../API";

// --- List Overtime ---
export const getListOvertime = (params) =>
  API.get("overtimes", { params: params });

// --- Get Employee By Id ---
export const getOvertimeById = (id) => API.get(`overtimes/${id}`);

// --- Update Employee By Id ---
export const updateOvertimeById = (data, id) =>
  API.put(`overtimes/${id}/status`, data);

// --- Delete Employee By Id ---
export const deleteOvertimeById = (id) => API.delete(`overtimes/${id}`);
