import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { IconDelete, IconDetail, IconEdit } from '../../../../../../assets/commons';
import { IconDescription } from '../../../../../../assets/coreHr/promotion';
import { Tooltips, TooltipsAction } from '../../../../../atoms';
import { handleTooltipClose, handleTooltipOpen } from '../../../../../../utils/function/tooltips';
import { handleAction } from '../../../../../../utils/function/action';
import { ModalDelete } from '../../../../modal';
import { useSelector } from 'react-redux';
import { getCompany } from '../../../../../../features/companies';
import { getComplaintsById, getImageDocument } from '../../../../../../configs/axios/apiPath';
import DataLogin from '../../../../../../data/dataLogin';
import CoreHRComplaintDetail from '../../../../../../pages/coreHR/complaint/detail';

const ComplaintTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const getData = DataLogin();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [idEmployee, setIdEmployee] = useState(null);

  const [detail, setDetail] = useState([]);
  const [temporaryImage, setTemporaryImage] = useState([])

  const [idTooltip, setIdTooltip] = useState('');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [idTooltipDetail, setIdTooltipDetail] = useState('');
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);
  const [idTooltipDelete, setIdTooltipDelete] = useState('');
  const [isTooltipDelete, setIsTooltipDelete] = useState(false);
  const [idTooltipEdit, setIdTooltipEdit] = useState('');
  const [isTooltipEdit, setIsTooltipEdit] = useState(false);

  // --- Handle Edit ---
  const handleEdit = (id) => {
    navigate(`/core-hr/complaint/edit/${id}`);
  };

  // --- Data Detail ---
  useEffect(() => {
    async function fetchDataDetail() {
      if (company && idEmployee !== null) {
        const params = {
          company_id: company,
        }
        setIsLoading(true);

        try {
          const {
            data: {data}
          } = await getComplaintsById(params, idEmployee)
          const doc = data?.documents;
          const arrImage = [];
          for (let i = 0; i < doc.length; i++) {
            const imageBlob = await fetchData(doc[i].id);
            arrImage.push(imageBlob);
          }
          setDetail(data);
          setTemporaryImage(arrImage);

        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false)
        }
      }
    }

    fetchDataDetail();
  }, [company, idEmployee]);

  // --- Fetch Data ---
  const fetchData = async (newId) => {
    setIsLoading(true)
    try {
      const response = await getImageDocument(newId, getData.token);

      const contentType = response.headers['content-type'];
      const blob = new Blob([response.data], { type: contentType });
      const objectURL = URL.createObjectURL(blob);
      return objectURL;
    } catch (error) {
      console.error(error.response.status);
      if (error.response.status === 401) {
        localStorage.removeItem('data');
        navigate(`/`);
      }
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Employee Name --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'start',
                verticalAlign: 'middle',
              }}
            >
              <Box
                display={'flex'}
                flexDirection={'start'}
                justifyContent={'start'}
                alignItems={'center'}
              >
                <img
                  src={item.employee?.profile_picture}
                  alt={item.employee?.name}
                  width={30}
                  style={{ borderRadius: '50%' }}
                />
                <Box ml={'10px'}>
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontWeight={400}
                    fontSize={'14px'}
                    lineHeight={'130%'}
                    color={'#171717'}
                  >
                    {item.employee?.name}
                  </Typography>
                </Box>
              </Box>
            </td>
            {/* --- Complaint Name --- */}
            <td
              style={{
                padding: '10px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {item.complaint_name}
              </Typography>
            </td>
             {/* --- Date --- */}
             <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
              >
                {moment(item.date).format('D MMM')}
              </Typography>
            </td>
            {/* --- Description --- */}
            <td
              style={{
                padding: '10px 12px',
                textAlign: 'center',
                verticalAlign: 'middle',
              }}
            >
               <Tooltips
                title={item.description}
                open={isTooltipOpen && item.id === idTooltip}
                arrow
                placement={'bottom-end'}
              >
                <Button 
                  onMouseEnter={() => handleTooltipOpen(item.id, setIsTooltipOpen, setIdTooltip )} 
                  onMouseLeave={()=>handleTooltipClose(setIsTooltipOpen)}
                >
                  <IconDescription />
                </Button>
              </Tooltips>
            </td>
           
            {/* --- Action --- */}
            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
            <Box 
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={1}
              >
                {/* --- Detail --- */}
                <TooltipsAction
                  title='Detail'
                  open={isTooltipDetail && item.id === idTooltipDetail}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{cursor: 'pointer'}}
                    onClick={() => handleAction(item.id, setIsModalOpen, setModal, setIdEmployee, 'detail')} 
                    onMouseEnter={() => handleTooltipOpen(item.id, setIsTooltipDetail, setIdTooltipDetail )} 
                    onMouseLeave={()=>handleTooltipClose(setIsTooltipDetail)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>

                {/* --- Delete --- */}
                <TooltipsAction
                  title='Delete'
                  open={isTooltipDelete && item.id === idTooltipDelete}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{cursor: 'pointer'}}
                    onClick={() => handleAction(item.id, setIsModalOpen, setModal, setIdEmployee, 'delete')}
                    onMouseEnter={() => handleTooltipOpen(item.id, setIsTooltipDelete, setIdTooltipDelete )} 
                    onMouseLeave={()=>handleTooltipClose(setIsTooltipDelete)}
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>

                {/* --- Edit --- */}
                <TooltipsAction
                  title='Edit'
                  open={isTooltipEdit && item.id === idTooltipEdit}
                  arrow
                  placement={'bottom-end'}
                >
                  <Box
                    sx={{cursor: 'pointer'}}
                    onClick={() => handleEdit(item.id)} 
                    onMouseEnter={() => handleTooltipOpen(item.id, setIsTooltipEdit, setIdTooltipEdit )} 
                    onMouseLeave={()=>handleTooltipClose(setIsTooltipEdit)}
                  >
                    <IconEdit />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
      {/* --- Modal Handle --- */}
      {idEmployee !== null &&
        modal === 'detail' ? (
          <CoreHRComplaintDetail
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            detail={detail}
            isLoading={isLoading}
            images={temporaryImage}
          />
        ) : modal === 'delete' ? (
          <ModalDelete
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            title={'Complaint'}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            id={idEmployee}
            data={tbody}
            setData={setData}
          />
        ) : (
          ''
        )
      }
    </>
  );
};

export default ComplaintTbody;
