import { Box, Divider, IconButton, Menu, MenuItem, Popover, Typography } from "@mui/material"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useState } from "react";
import CardMoveList from "../../card/project/CardMoveList";

const MenuActionBoard = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverOpen = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null)
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null)
  }

  return(
    <Box>
      <IconButton
        onClick={handleOpen}
        sx={{ 
          "&:hover": {
            bgcolor: 'transparent',
          }
        }}
      >
        <MoreHorizIcon/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            borderRadius: '10px',
            width: '250px'
          },
        }}
      >
        <Box
          height={'35px'}
        >
          <Typography sx={{
              textAlign: 'center',
              fontWeight: '400',
              fontSize: '14px',
            }}
          >
            List Action
          </Typography>
        </Box>
        <Divider/>        
        <MenuItem divider={true} sx={{fontSize: '14px', fontWeight: '400'}}>Add Card</MenuItem>
        <MenuItem divider={true} sx={{fontSize: '14px', fontWeight: '400'}}>Copy List</MenuItem>
        <MenuItem divider={true} sx={{fontSize: '14px', fontWeight: '400'}} onClick={handlePopoverOpen}>Move List</MenuItem>
      </Menu>

      <Popover
        anchorEl={popoverAnchorEl}
        open={Boolean(popoverAnchorEl)}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            borderRadius: '10px',
          },
        }}
      >
        <CardMoveList/>
      </Popover>

    </Box>
  )
}

export default MenuActionBoard