import React from 'react';
import Chart from 'react-google-charts';
import { Box } from '@mui/material';
import CardCompany from '../../../../../components/organisms/employee/cardEmployee/cardCompany';

const data = [
  ["Year", "Sales", "Expenses", "Profit", "Margin"],
  ["2014", 1000, 400, 200, 450],
  ["2015", 1170, 460, 250, 150],
  ["2016", 660, 1120, 300, 250],
  ["2017", 1030, 540, 350, 430],
];

const ProfilePerformance = () => {
  return (
    <Box mt={"20px"}>
        <CardCompany>
        <Chart
          chartType="Bar"
          width="80%"
          height="400px"
          data={data}
        />
        </CardCompany>
    </Box>
  )
};

export default ProfilePerformance;
