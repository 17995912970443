import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCompany } from '../../../../../features/companies';
import { IconMoney, IconQuit } from '../../../../../assets/payroll';
import { Card, Modals } from '../../../../../components/atoms';
import { getLanguage } from '../../../../../features/language';
import { IconDescription } from '../../../../../assets/coreHr/awards';
import { getSalaryType, postAdhoc } from '../../../../../configs/axios/apiPath';
import { getCompanySalaryType } from '../../../../../configs/axios/apiPath/fetchPayroll/fetchPayrollList';
import RupiahFormatter from '../../../../../components/atoms/rupiahFormatter';

export default function AddAdhoc({ isOpen, onClose, id }) {
  const [name, setName] = useState('');
  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState('');
  const [salary, setSalary] = useState([]);
  const [selectedSalary, setSelectedSalary] = useState(null);
  const [description, setDescription] = useState();
  const [isFocusedDescription, setIsFocusedDescription] = useState(false);
  const [isFocusedAmount, setIsFocusedAmount] = useState(false);
  const [isFocusedName, setIsFocusedName] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const company = useSelector(getCompany);
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);

  // --- Handle Input Change ---
  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  // --- Handle Selected Employee ---
  const handleSelectSalary = (event, value) => {
    setSelectedSalary(value);
  };

  // --- handle submit --
  const handleSubmit = async (event) => {
    event.preventDefault();
    const convertAmount = amount.replace(/[^,\d]/g, '');

    setIsLoading(true);
    if (company) {
      setIsLoading(true);
      const data = {
        employee_id: id,
        company_salary_type_id: selectedSalary?.idCompany,
        name: name,
        date: date,
        amount: Number(convertAmount),
        note: description,
      };
      try {
        const response = await postAdhoc(data, id);
        if (response.data.meta?.code === 20000) {
          window.location.reload();
        }
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  //   --- Salary Type ---
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          employee_add_hoc: 1,
        };
        setIsLoading(true);
        try {
          const response = await getCompanySalaryType(params);
          const data = response.data.data;

          const newData = data?.map((item) => {
            const { salary_type, id } = item || {};
            return { ...salary_type, idCompany: id };
          });
          setSalary(newData);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, navigate]);

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Card>
          <Box p={'30px'}>
            {/* --- Title --- */}
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              mb={'7px'}
            >
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontSize={'16px'}
                fontWeight={600}
                lineHeight={'22px'}
                color={'#171717'}
              >
                {lang === 'Id' ? 'Tambahkan Ad hoc baru' : 'Add New Adhoc'}
              </Typography>
              <IconButton sx={{ p: '0px' }} onClick={onClose}>
                <IconQuit />
              </IconButton>
            </Box>
            {/* --- Divider --- */}
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ borderColor: '#c4c4c4' }}
            />

            <form
              onSubmit={handleSubmit}
              style={{
                marginTop: '20px',
                fontWeight: '600px',
                fontSize: '13px',
                lineHeight: '18px',
                letterSpacing: '0.01em',
                color: '#171717',
              }}
            >
              <Grid container columns={2} spacing={2}>
                {/* --- Salary Type --- */}
                <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                  <Box display={'flex'} flexDirection={'column'} mb={'9px'}>
                    <InputLabel style={{ marginBottom: '5px' }}>
                      {lang === 'Id' ? 'Tipe Gaji' : 'Salary Type'}
                    </InputLabel>
                    <Autocomplete
                      value={selectedSalary}
                      onChange={handleSelectSalary}
                      inputValue={inputValue}
                      onInputChange={handleInputChange}
                      options={salary}
                      getOptionLabel={(salary) => salary.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          InputLabelProps={{ shrink: false }}
                          sx={{
                            background: '#f9f9f9',
                            '& .MuiOutlinedInput-root': {
                              background: 'transparent',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: 'transparent',
                            },
                          }}
                          variant="outlined"
                        />
                      )}
                    />
                  </Box>
                </Grid>

                {/* --- Name  --- */}
                <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                  <Box display={'flex'} flexDirection={'column'} mb={'9px'}>
                    <InputLabel
                      style={{
                        fontFamily: 'sans-serif',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '13px',
                        lineHeight: '18px',
                        letterSpacing: '1px',
                        marginBottom: '6px',
                      }}
                    >
                      {lang === 'Id' ? 'Nama' : 'Name'}
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      value={name}
                      placeholder="Type here"
                      onChange={(event) => setName(event.target.value)}
                      onFocus={() => setIsFocusedName(true)}
                      onBlur={() => setIsFocusedName(false)}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {!isFocusedName && name === '' ? <IconMoney /> : ''}
                          </InputAdornment>
                        ),
                      }}
                      required
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          background: '#f9f9f9',
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid container columns={2} spacing={2}>
                {/* --- Amount --- */}
                <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                  <Box display={'flex'} flexDirection={'column'} mb={'9px'}>
                    <InputLabel
                      style={{
                        fontFamily: 'sans-serif',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '13px',
                        lineHeight: '18px',
                        letterSpacing: '1px',
                        marginBottom: '6px',
                      }}
                    >
                      {lang === 'Id' ? 'Jumlah' : 'Amount'}
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      value={RupiahFormatter(amount?.toString() ?? '0')}
                      // value={amount}
                      placeholder="Type here"
                      onChange={(e) =>
                        e.target.value.length <= 14 && setAmount(e.target.value)
                      }
                      // onChange={(event) => setAmount(event.target.value)}
                      onFocus={() => setIsFocusedAmount(true)}
                      onBlur={() => setIsFocusedAmount(false)}
                      fullWidth
                      type="text"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {!isFocusedAmount && amount === 0 ? (
                              <IconMoney />
                            ) : (
                              ''
                            )}
                          </InputAdornment>
                        ),
                      }}
                      required
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          background: '#f9f9f9',
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                        },
                      }}
                    />
                  </Box>
                </Grid>{' '}
                {/* --- Date --- */}
                <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
                  <Box display={'flex'} flexDirection={'column'} mb={'9px'}>
                    <InputLabel
                      style={{
                        fontFamily: 'sans-serif',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '13px',
                        lineHeight: '18px',
                        letterSpacing: '1px',
                        marginBottom: '6px',
                      }}
                    >
                      {lang === 'Id' ? 'Tanggal' : 'Date'}
                    </InputLabel>
                    <TextField
                      variant="outlined"
                      value={date}
                      placeholder="Type here"
                      onChange={(event) => setDate(event.target.value)}
                      fullWidth
                      type="date"
                      required
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          background: '#f9f9f9',
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent',
                          },
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>

              {/* --- Description --- */}
              <Grid container columns={2} spacing={2} mb={'21px'}>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                  <Box display={'flex'} flexDirection={'column'} mb={'9px'}>
                    <InputLabel
                      style={{
                        fontFamily: 'sans-serif',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        fontSize: '13px',
                        lineHeight: '18px',
                        letterSpacing: '1px',
                        marginBottom: '6px',
                      }}
                    >
                      {lang === 'Id' ? 'Deskripsi' : 'Description'}
                    </InputLabel>
                    <OutlinedInput
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      multiline
                      minRows={5}
                      value={description}
                      onFocus={() => setIsFocusedDescription(true)}
                      onBlur={() => setIsFocusedDescription(false)}
                      onChange={(e) => setDescription(e.target.value)}
                      startAdornment={
                        <InputAdornment
                          position="start"
                          sx={{
                            position: 'absolute',
                            top: 23,
                          }}
                        >
                          {!isFocusedDescription && description === '' ? (
                            <IconDescription />
                          ) : (
                            ''
                          )}
                        </InputAdornment>
                      }
                      sx={{
                        fontSize: 'normal',
                        background: '#f9f9f9',
                        '& .MuiOutlinedInput-root': {
                          background: 'transparent',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'transparent',
                        },
                        '& textarea': {
                          paddingLeft:
                            !isFocusedDescription && description === ''
                              ? '24px'
                              : '9px',
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>

              {/* --- Submit --- */}
              <Box display={'flex'} justifyContent={'end'}>
                <Button
                  disabled={isLoading}
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ width: '136px', textTransform: 'none' }}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Card>
      </Box>
    </Modals>
  );
}
