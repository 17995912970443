import {
  Box,
  Typography,
  Popper,
  ClickAwayListener,
  Paper,
  Stack,
  IconButton,
  Avatar,
} from "@mui/material";
import { TooltipsAction } from "../../../../../atoms";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  IconDelete,
  IconDetail,
  IconEdit,
} from "../../../../../../assets/commons";
import {
  IconItemUsed,
  IconTracking,
} from "../../../../../../assets/benefit/officeFacillities";
// import Timeline from '@mui/lab/Timeline';
import {
  Timeline,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  timelineContentClasses,
} from "@mui/lab";
import TimelineDot, { timelineDotClasses } from "@mui/lab/TimelineDot";
import { BenefitOfficeFacillitiesDetail } from "../../../../../../pages/benefit";
import { ModalDelete } from "../../../../modal";
import { handleAction } from "../../../../../../utils/function/action";
import SelectYear from "../../../../../molecules/year";
import {
  getOfficeFacillitiesById,
  getTrackOfficeFacillitiesById,
} from "../../../../../../configs/axios/apiPath";
import { timelineItemClasses } from "@mui/lab";
const OfficeFacillitiesTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();

  const yearNow = moment().format("YYYY");
  const year = [];
  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openPopper, setOpenPopper] = useState(false);
  const [idEmployee, setIdEmployee] = useState(null);
  const [modal, setModal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [idTracking, setIdTracking] = useState(null);
  const [detail, setDetail] = useState(null);
  const [journeyHistory, setJourneyHistory] = useState();

  const [selectedYear, setSelectedYear] = useState(moment().format("YYYY"));

  const handleEdit = (id) => {
    navigate(`edit/${id}`);
  };

  const handleClick = (event, id) => {
    setIdTracking(id);
    setAnchorEl(event.currentTarget);
    setOpenPopper((prevOpen) => !prevOpen);
  };

  const handleClose = () => {};

  // --- Handle Detail ---
  const handleDetail = async (id) => {
    setIsModalOpen(true);
    setId(id);
    setModal("detail");
  };

  useEffect(() => {
    async function fetchData() {
      if (id !== null) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getOfficeFacillitiesById(id);
          setDetail(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [id, navigate]);

  useEffect(() => {
    async function fetchData() {
      if (idTracking !== null) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getTrackOfficeFacillitiesById(
            { year: selectedYear },
            idTracking
          );
          setJourneyHistory(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [idTracking, navigate, selectedYear]);

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Office Item --- */}
            <td
              style={{
                padding: "10px",
                verticalAlign: "middle",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap="10px"
                justifyContent="center"
              >
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={400}
                  fontSize={"14px"}
                  lineHeight={"130%"}
                  color={"#171717"}
                  textAlign="center"
                >
                  {item.name}
                </Typography>
                {item.used && <IconItemUsed />}
              </Stack>
            </td>

            <td
              style={{
                padding: "10px",
                verticalAlign: "middle",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                gap="10px"
                justifyContent="center"
              >
                <Avatar src={item?.responsible_employee?.profile_picture} />
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={400}
                  fontSize={"14px"}
                  lineHeight={"130%"}
                  color={"#171717"}
                >
                  {item?.responsible_employee?.name}
                </Typography>
              </Stack>
            </td>
            {/* --- Status --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
                textAlign="center"
              >
                Rp {Number(item.price).toLocaleString("ID-id")}*
              </Typography>
            </td>
            {/* --- Receive Date --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {moment(item?.date).format("D MMM")}
              </Typography>
            </td>

            {/* --- Action --- */}
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                {/* "Tracking" */}
                <TooltipsAction title="Tracking" arrow placement={"bottom-end"}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={(event) => handleClick(event, item.id)}
                  >
                    <IconTracking />
                  </Box>
                </TooltipsAction>
                {/* --- Delete --- */}
                <TooltipsAction title="Delete" arrow placement={"bottom-end"}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        "delete"
                      )
                    }
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>
                {/* --- Edit --- */}
                <TooltipsAction title="Edit" arrow placement={"bottom-end"}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleEdit(item.id)}
                  >
                    <IconEdit />
                  </Box>
                </TooltipsAction>
                {/* Detail */}
                <TooltipsAction title="Detail" arrow placement={"bottom-end"}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleDetail(item.id)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
      <Popper open={openPopper} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
                [`& .${timelineDotClasses.root}`]: {
                  margin: 0,
                },

                [`& .${timelineContentClasses.root}`]: {
                  padding: "1px 0px 1px 12px",
                },
              }}
            >
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot variant="outlined" />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography>Tracking</Typography>
                    <SelectYear
                      year={year}
                      selectedYear={selectedYear}
                      setSelectedYear={setSelectedYear}
                    />
                  </Box>
                </TimelineContent>
              </TimelineItem>
              {journeyHistory &&
                journeyHistory.map((journey, index) => (
                  <TimelineItem key={index}>
                    <TimelineSeparator>
                      <TimelineDot color="primary" />
                      {journeyHistory.length - 1 !== index && (
                        <TimelineConnector />
                      )}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Stack direction="row">
                          <Avatar sx={{ width: 24, height: 24 }} />
                          <Stack ml="6px">
                            <Typography fontSize="10px">
                              {journey?.employee?.name}
                            </Typography>
                            <Typography
                              color="primary"
                              fontSize="10px"
                              fontWeight={600}
                            >
                              {moment(journey.start_date).format("D MMM")},{" "}
                            </Typography>
                          </Stack>
                        </Stack>

                        <IconButton onClick={() => handleDetail(journey.id)}>
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 6C12 2.688 9.312 0 6 0C2.688 0 0 2.688 0 6C0 9.312 2.688 12 6 12C9.312 12 12 9.312 12 6ZM6 7.674V6.6H4.2C3.87 6.6 3.6 6.33 3.6 6C3.6 5.67 3.87 5.4 4.2 5.4H6V4.326C6 4.056 6.324 3.924 6.51 4.116L8.184 5.79C8.304 5.91 8.304 6.096 8.184 6.216L6.51 7.89C6.4677 7.93146 6.41408 7.95948 6.35589 7.97051C6.29769 7.98155 6.23754 7.97511 6.183 7.95201C6.12846 7.92891 6.08198 7.89018 6.04941 7.84071C6.01684 7.79124 5.99965 7.73323 6 7.674Z"
                              fill="#AE28C4"
                            />
                          </svg>
                        </IconButton>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                ))}
            </Timeline>
          </Paper>
        </ClickAwayListener>
      </Popper>
      {modal === "detail" ? (
        <BenefitOfficeFacillitiesDetail
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          detail={detail}
          isLoading={isLoading}
        />
      ) : modal === "delete" ? (
        <ModalDelete
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={"Office Facillities"}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          id={idEmployee}
          data={tbody}
          setData={setData}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default OfficeFacillitiesTbody;
