import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IconDate, IconDescription } from "../../../../assets/coreHr/awards";
import { Card } from "../../../../components/atoms";
import {
  getListEmployee,
  getCalendarById,
  updateCalendarById,
} from "../../../../configs/axios/apiPath";
import { getCompany } from "../../../../features/companies";
import { getLanguage } from "../../../../features/language";
import { IconEvent, IconTime } from "../../../../assets/hrCalendar";
import dayjs from "dayjs";
const HRCalendarAdd = () => {
  const { id } = useParams();
  const [employeeData, setEmployeeData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);

  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [total, setTotal] = useState(1);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const lang = useSelector(getLanguage);
  const placeHolderId = "Ketik disini";
  const placeHolderEn = "Type here";

  // list data on input option

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getCalendarById(id);
          setName(data.name);
          setSelectedEmployee(data.members);
          setDate(dayjs(data.start_date).format("YYYY-MM-DD"));
          setTime(dayjs(data.start_date).format("HH:mm"));
          setEndDate(dayjs(data.end_date).format("YYYY-MM-DD"));
          setEndTime(dayjs(data.end_date).format("HH:mm"));
          setDescription(data.description);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, id, navigate]);
  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          page_size: total,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListEmployee(params);

          setEmployeeData(data.employee);
          if (data.page_info.total > 0) {
            setTotal(data.page_info.total);
          }
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, total]);

  // --- Handle Submit ---
  const handleSubmit = async (event) => {
    event.preventDefault();

    const calendarData = {
      name: name,
      start_date: date + " " + time,
      end_date: endDate + " " + endTime,
      description: description,
      employee_ids: selectedEmployee.map((e) => e.id),
    };

    setIsLoading(true);

    try {
      updateCalendarById(calendarData, id);
      navigate(`/calendar-hr`);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/calendar-hr`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card>
      <Box p={"20px"}>
        <Box borderBottom={"0.2px solid #939393"} p={"0px 0px 15px 0px"}>
          <Typography
            fontFamily={"sans-serif"}
            fontStyle={"normal"}
            fontWeight={600}
            fontSize={"20px"}
            lineHeight={"27px"}
            letterSpacing={"0.01em"}
            color={"#171717"}
          >
            {lang === "Id" ? "Edit" : "Edit"}
          </Typography>
        </Box>
        <form
          onSubmit={handleSubmit}
          style={{
            marginTop: "20px",
            fontWeight: "600px",
            fontSize: "13px",
            lineHeight: "18px",
            letterSpacing: "0.01em",
            color: "#171717",
          }}
        >
          {/* --- Name Event --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={"flex"} flexDirection={"column"} mb={"16px"}>
                <InputLabel
                  style={{
                    fontFamily: "sans-serif",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    marginBottom: "6px",
                  }}
                >
                  {lang === "Id" ? "Nama Event" : "Event Name"}
                </InputLabel>
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  size="small"
                  placeholder={lang === "Id" ? placeHolderId : placeHolderEn}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconEvent />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      background: "#f9f9f9",
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "transparent",
                      },
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Employee --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={"flex"} flexDirection={"column"} mb={"16px"}>
                <InputLabel style={{ marginBottom: "5px" }}>
                  {lang === "Id" ? "Karyawan" : "Employee"}
                </InputLabel>
                <Autocomplete
                  options={employeeData}
                  getOptionLabel={(employee) => employee.name}
                  multiple
                  onChange={(event, value) => setSelectedEmployee(value)}
                  value={selectedEmployee}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      <span>{option.name}</span>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      size="small"
                      placeholder={
                        lang === "Id" ? placeHolderId : placeHolderEn
                      }
                      InputLabelProps={{ shrink: false }}
                      sx={{
                        background: "#f9f9f9",
                        "& .MuiOutlinedInput-root": {
                          background: "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Start Date and End Date --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={"flex"} flexDirection={"column"} mb={"16px"}>
                <InputLabel
                  style={{
                    fontFamily: "sans-serif",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    marginBottom: "6px",
                  }}
                >
                  {lang === "Id" ? "Jam Mulai" : "Start Time"}
                </InputLabel>
                <TextField
                  fullWidth
                  required
                  type="time"
                  size="small"
                  variant="outlined"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  sx={{
                    background: "#f9f9f9",
                    "& .MuiOutlinedInput-root": {
                      background: "transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconTime />
                      </InputAdornment>
                    ),
                    style: {
                      height: "40px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={"flex"} flexDirection={"column"} mb={"16px"}>
                <InputLabel
                  style={{
                    fontFamily: "sans-serif",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    marginBottom: "6px",
                  }}
                >
                  {lang === "Id" ? "Jam Berakhir" : "End Time"}
                </InputLabel>
                <TextField
                  fullWidth
                  required
                  type="time"
                  size="small"
                  variant="outlined"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  sx={{
                    background: "#f9f9f9",
                    "& .MuiOutlinedInput-root": {
                      background: "transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconTime />
                      </InputAdornment>
                    ),
                    style: {
                      height: "40px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    },
                  }}
                />
              </Box>
            </Grid>
            {/* Start Date */}
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={"flex"} flexDirection={"column"} mb={"16px"}>
                <InputLabel
                  style={{
                    fontFamily: "sans-serif",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    marginBottom: "6px",
                  }}
                >
                  {lang === "Id" ? "Tanggal Mulai" : "Start Date"}
                </InputLabel>
                <DatePicker
                  format="DD-MM-YYYY"
                  fullWidth
                  type="date"
                  size="small"
                  variant="outlined"
                  slotProps={{
                    textField: { size: "small" },
                  }}
                  value={dayjs(date)}
                  onChange={(e) => setDate(dayjs(e).format("YYYY-MM-DD"))}
                  sx={{
                    background: "#f9f9f9",
                    "& .MuiOutlinedInput-root": {
                      background: "transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconDate />
                      </InputAdornment>
                    ),
                    style: {
                      height: "40px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    },
                  }}
                />
              </Box>
            </Grid>

            {/* End Date */}
            <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
              <Box display={"flex"} flexDirection={"column"} mb={"16px"}>
                <InputLabel
                  style={{
                    fontFamily: "sans-serif",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    marginBottom: "6px",
                  }}
                >
                  {lang === "Id" ? "Tanggal Berakhir" : "End Date"}
                </InputLabel>
                <DatePicker
                  format="DD-MM-YYYY"
                  required
                  type="date"
                  size="small"
                  variant="outlined"
                  value={dayjs(endDate)}
                  slotProps={{ textField: { size: "small" } }}
                  onChange={(e) => setEndDate(e)}
                  sx={{
                    background: "#f9f9f9",
                    "& .MuiOutlinedInput-root": {
                      background: "transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconDate />{" "}
                      </InputAdornment>
                    ),
                    style: {
                      height: "40px",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>

          {/* --- Description --- */}
          <Grid container columns={2} spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
              <Box display={"flex"} flexDirection={"column"} mb={"16px"}>
                <InputLabel
                  style={{
                    fontFamily: "sans-serif",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "13px",
                    lineHeight: "18px",
                    letterSpacing: "1px",
                    marginBottom: "6px",
                  }}
                >
                  {lang === "Id" ? "Deskripsi" : "Description"}
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  required
                  multiline
                  minRows={5}
                  variant="outlined"
                  size="small"
                  placeholder={lang === "Id" ? placeHolderId : placeHolderEn}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{
                        position: "absolute",
                        top: 23,
                      }}
                    >
                      <IconDescription />
                    </InputAdornment>
                  }
                  sx={{
                    fontSize: "normal",
                    background: "#f9f9f9",
                    "& .MuiOutlinedInput-root": {
                      background: "transparent",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "& textarea": {
                      paddingLeft: "24px",
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          {/* {isfocus && !ValidateDescription(description) && (
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontWeight={400}
              fontSize={"12px"}
              lineHeight={"15px"}
              letterSpacing={"1px"}
              color={"red"}
              my={"5px"}
            >
              {lang === "Id" ? "Minimal 10 karakter" : "Minimals 10 characters"}
            </Typography>
          )} */}
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            type="submit"
            sx={{ width: "136px", marginTop: "21px" }}
          >
            {lang === "Id" ? "Simpan" : "Save"}
          </Button>
        </form>
      </Box>
    </Card>
  );
};

export default HRCalendarAdd;
