import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  CoreHRTabPanel,
  EmployeeTabPanel,
  OrganizationTabPanel,
  HrCalendarTabPanel,
  PayrollTabPanel,
  TimesheetTabPanel,
  BenefitTabPanel,
  RecruitmentTabPanel,
} from '../../components/molecules/tab';
import { Homepage, NotFoundPage, ReportDashboard } from '../../pages';
import {
  CoreHRAnnouncementAdd,
  CoreHRAnnouncementDashboard,
  CoreHRAnnouncementEdit,
  CoreHRAnnouncementIndex,
  CoreHRPermissionDashboard,
  CoreHRPermissionIndex,
  CoreHROvertimeDashboard,
  CoreHROvertimeIndex,
  CoreHRAwardsAdd,
  CoreHRAwardsDashboard,
  CoreHRAwardsEdit,
  CoreHRAwardsIndex,
  CoreHRComplaintAdd,
  CoreHRComplaintDashboard,
  CoreHRComplaintIndex,
  CoreHRLogView,
  CoreHRPromotionAdd,
  CoreHRPromotionEdit,
  CoreHRPromotionIndex,
  CoreHRPromotionsDashboard,
  CoreHRResignAdd,
  CoreHRResignDashboard,
  CoreHRResignIndex,
  CoreHRWarningList,
  CoreHRWarningAdd,
  CoreHRWarningDashboard,
  CoreHRWarningEdit,
  CoreHRWarningIndex,
} from '../../pages/coreHR';

import {
  RecruitmentJobsDashboard,
  RecruitmentJobsAdd,
  RecruitmentJobsEdit,
  RecruitmentRequestDashboard,
  RecruitmentRequestAdd,
  RecruitmentRequestEdit,
  RecruitmentCandidateDashboard,
  RecruitmentCandidateDetail,
  RecruitmentAllCandidateDetail,
  RecruitmentCandidateMessage,
  RecruitmentCandidateInterview,
  RecruitmentCandidateNote,
} from '../../pages/recruitment';
import {
  BenefitReimbursementDashboard,
  BenefitReimbursementAdd,
  BenefitReimbursementEdit,
  BenefitAccountDashboard,
  BenefitJourneyAdd,
  BenefitJourneyEdit,
  BenefitJourneyDashboard,
  BenefitJourneyUpdateBudget,
  BenefitOfficeFacillitiesDashboard,
  BenefitOfficeFacillitiesAdd,
  BenefitOfficeFacillitiesEdit,
  BenefitEmployeeFacillitiesDashboard,
  BenefitEmployeeFacillitiesAdd,
  BenefitEmployeeFacillitiesEdit,
} from '../../pages/benefit';
import CoreHRComplaintEdit from '../../pages/coreHR/complaint/edit';
import ProtectedRoute from '../protectedRoute';
import {
  AttendanceCorrectionList,
  AttendanceList,
  TimesheetAttandanceCorrection,
  TimesheetAttandanceHistory,
  TimesheetAttandanceIndex,
  TimesheetAttendanceDashboard,
} from '../../pages/timesheet';
import {
  PayrollListDashboard,
  PayrollListIndex,
} from '../../pages/payroll/payrollList';
import { PayrollListHistory } from '../../pages/payroll/payrollHistory';
import {
  PayrollListDetailEmployee,
  PayrollListEmployee,
} from '../../pages/payroll/payrollList/payrollListDetail';
import { Outlets } from '../../components/atoms';

import {
  AddProject,
  AddTask,
  DetailTask,
  IndexActivities,
  IndexBoards,
  IndexDiscussion,
  IndexTimeline,
  ProjectActivities,
  ProjectBoards,
  ProjectDashboard,
  ProjectDiscussion,
  ProjectTimeline,
  SummaryProject,
} from '../../pages/project/index';
import ProjectTabPanel from '../../components/molecules/tab/projectTab';
import EditShiftOccuring from '../../pages/employee/employeeManage/manageDetailEmployee/shift/editOccuring';
import {
  DepartmentAdd,
  DepartmentDashboard,
  DepartmentEdit,
  DepartmentList,
} from '../../pages/organization/department';
import {
  PositionAdd,
  PositionEdit,
  PositionList,
} from '../../pages/organization/position';
import {
  DepartmentOrganization,
  LocationDashboard,
} from '../../pages/organization';
import { AddLocation, EditLocation } from '../../pages/organization/location';
import {
  AddCompanyPolicy,
  CompanyPolicyDashboard,
  EditCompanyPolicy,
} from '../../pages/organization/companyPolicy';
import { TableListAttendance } from '../../components/organisms/timesheet';
import {
  TableJobs,
  TableRequest,
} from '../../components/organisms/recruitment';
import HRCalendarDashboard from './../../pages/HRcalendar/hrCalendarList/dashboard/index';
import {
  HRCalendarAdd,
  HRCalendarEdit,
  HRCalendarHistory,
  HRCalendarIndex,
} from '../../pages/HRcalendar';

import {
  AddShiftOccuring,
  DetailDashboard,
  EmployeeAttendance,
  EmployeeBank,
  EmployeeBenefit,
  EmployeeDocument,
  EmployeeJob,
  EmployeeManageDashboard,
  EmployeeNote,
  EmployeePerformance,
  ProfilePerformance,
  EmployeeProfile,
  EmployeeResume,
  EmployeeSchedule,
  EmployeeScheduleAdd,
  EmployeeScheduleEdit,
  EmployeeShift,
} from '../../pages/employee';
import EditEmployeeLayout from '../../pages/employee/manage/edit';

const MainRouting = () => {
  return (
    <Routes>
      <Route index element={<ProtectedRoute page={<Homepage />} />} />
      <Route
        path="/dashboard"
        element={<ProtectedRoute page={<Homepage />} />}
      />

      {/* --- Employee --- */}
      <Route
        path="/employee"
        element={<ProtectedRoute page={<EmployeeTabPanel />} />}
      >
        <Route
          index
          element={<ProtectedRoute page={<EmployeePerformance />} />}
        />
        {/* --- Performance --- */}
        <Route
          path="employee-dashboard"
          element={<ProtectedRoute page={<EmployeePerformance />} />}
        />
        {/* --- Manage --- */}
        <Route path="employee" element={<ProtectedRoute page={<Outlets />} />}>
          <Route
            index
            element={<ProtectedRoute page={<EmployeeManageDashboard />} />}
          />

          <Route
            path="edit/:id"
            element={<ProtectedRoute page={<EditEmployeeLayout />} />}
          />

          {/* --- Manage Detail --- */}
          <Route
            path="detail/:id"
            element={<ProtectedRoute page={<DetailDashboard />} />}
          >
            <Route
              index
              element={<ProtectedRoute page={<EmployeeProfile />} />}
            />
            <Route
              path="profile"
              element={<ProtectedRoute page={<EmployeeProfile />} />}
            />
            <Route
              path="benefit"
              element={<ProtectedRoute page={<EmployeeBenefit />} />}
            />
            <Route
              path="note"
              element={<ProtectedRoute page={<EmployeeNote />} />}
            />
            <Route
              path="job"
              element={<ProtectedRoute page={<EmployeeJob />} />}
            />
            <Route
              path="resume"
              element={<ProtectedRoute page={<EmployeeResume />} />}
            />
            <Route
              path="document"
              element={<ProtectedRoute page={<EmployeeDocument />} />}
            />
            <Route
              path="bank"
              element={<ProtectedRoute page={<EmployeeBank />} />}
            />
            <Route
              path="attendance"
              element={<ProtectedRoute page={<EmployeeAttendance />} />}
            />
            <Route path="shift" element={<ProtectedRoute page={<Outlets />} />}>
              <Route
                index
                element={<ProtectedRoute page={<EmployeeShift />} />}
              />
              {/* --- Add Shift One Time --- */}
              <Route
                path={'add-shift'}
                element={<ProtectedRoute page={<AddShiftOccuring />} />}
              />
              {/* --- Edit Shift One Time --- */}
              <Route
                path={'edit-shift/:id'}
                element={<ProtectedRoute page={<EditShiftOccuring />} />}
              />
            </Route>
            <Route
              path="performance"
              element={<ProtectedRoute page={<ProfilePerformance />} />}
            />
          </Route>
        </Route>
        {/* --- Schedule --- */}
        <Route path="schedule" element={<ProtectedRoute page={<Outlets />} />}>
          <Route
            index
            element={<ProtectedRoute page={<EmployeeSchedule />} />}
          />
          <Route
            path="add"
            element={<ProtectedRoute page={<EmployeeScheduleAdd />} />}
          />
          <Route
            path="edit/:id"
            element={<ProtectedRoute page={<EmployeeScheduleEdit />} />}
          />
        </Route>
      </Route>

      {/* --- Payroll --- */}
      <Route
        path="/payroll"
        element={<ProtectedRoute page={<PayrollTabPanel />} />}
      >
        <Route
          index
          element={<ProtectedRoute page={<PayrollListDashboard />} />}
        />
        <Route
          path="payroll-period"
          element={<ProtectedRoute page={<PayrollListIndex />} />}
        >
          <Route
            index
            element={<ProtectedRoute page={<PayrollListDashboard />} />}
          />
          <Route
            path="detail/:id"
            element={<ProtectedRoute page={<Outlets />} />}
          >
            <Route
              index
              element={<ProtectedRoute page={<PayrollListEmployee />} />}
            />
            <Route
              path="detail-employee/:id"
              element={<ProtectedRoute page={<PayrollListDetailEmployee />} />}
            />
          </Route>
        </Route>
        <Route
          path="history-payslip"
          element={<ProtectedRoute page={<PayrollListHistory />} />}
        />
      </Route>

      <Route
        path="/benefit"
        element={<ProtectedRoute page={<BenefitTabPanel />} />}
      >
        <Route
          index
          element={<ProtectedRoute page={<BenefitReimbursementDashboard />} />}
        />
        <Route
          path="reimbursement"
          element={<ProtectedRoute page={<CoreHRAwardsIndex />} />}
        >
          <Route
            index
            element={
              <ProtectedRoute page={<BenefitReimbursementDashboard />} />
            }
          />
          <Route
            path="add"
            element={<ProtectedRoute page={<BenefitReimbursementAdd />} />}
          />
          <Route
            path="edit/:id"
            element={<ProtectedRoute page={<BenefitReimbursementEdit />} />}
          />
        </Route>
        <Route
          path="account"
          element={<ProtectedRoute page={<CoreHRAwardsIndex />} />}
        >
          <Route
            index
            element={<ProtectedRoute page={<BenefitAccountDashboard />} />}
          />
        </Route>
        <Route
          path="journey"
          element={<ProtectedRoute page={<CoreHRAwardsIndex />} />}
        >
          <Route
            index
            element={<ProtectedRoute page={<BenefitJourneyDashboard />} />}
          />
          <Route
            path="add"
            element={<ProtectedRoute page={<BenefitJourneyAdd />} />}
          />
          <Route
            path="update/:id"
            element={<ProtectedRoute page={<BenefitJourneyUpdateBudget />} />}
          />
          <Route
            path="edit/:id"
            element={<ProtectedRoute page={<BenefitJourneyEdit />} />}
          />
        </Route>
        <Route
          path="office-facilities"
          element={<ProtectedRoute page={<CoreHRAwardsIndex />} />}
        >
          <Route
            index
            element={
              <ProtectedRoute page={<BenefitOfficeFacillitiesDashboard />} />
            }
          />
          <Route
            path="add"
            element={<ProtectedRoute page={<BenefitOfficeFacillitiesAdd />} />}
          />
          <Route
            path="edit/:id"
            element={<ProtectedRoute page={<BenefitOfficeFacillitiesEdit />} />}
          />
        </Route>
        <Route
          path="employee-facilities"
          element={<ProtectedRoute page={<CoreHRAwardsIndex />} />}
        >
          <Route
            index
            element={
              <ProtectedRoute page={<BenefitEmployeeFacillitiesDashboard />} />
            }
          />
          <Route
            path="add"
            element={
              <ProtectedRoute page={<BenefitEmployeeFacillitiesAdd />} />
            }
          />
          <Route
            path="edit/:id"
            element={
              <ProtectedRoute page={<BenefitEmployeeFacillitiesEdit />} />
            }
          />
        </Route>
      </Route>

      {/* --- Core HR --- */}
      <Route
        path="/core-hr"
        element={<ProtectedRoute page={<CoreHRTabPanel />} />}
      >
        <Route
          index
          element={<ProtectedRoute page={<CoreHRAwardsDashboard />} />}
        />
        {/* --- Awards --- */}
        <Route
          path="awards"
          element={<ProtectedRoute page={<CoreHRAwardsIndex />} />}
        >
          <Route
            index
            element={<ProtectedRoute page={<CoreHRAwardsDashboard />} />}
          />
          <Route
            path="add"
            element={<ProtectedRoute page={<CoreHRAwardsAdd />} />}
          />
          <Route
            path="edit/:id"
            element={<ProtectedRoute page={<CoreHRAwardsEdit />} />}
          />
        </Route>
        {/* --- Resign --- */}
        <Route
          path="resign"
          element={<ProtectedRoute page={<CoreHRResignIndex />} />}
        >
          <Route
            index
            element={<ProtectedRoute page={<CoreHRResignDashboard />} />}
          />
          <Route
            path="add"
            element={<ProtectedRoute page={<CoreHRResignAdd />} />}
          />
        </Route>
        {/* --- Promotion --- */}
        <Route
          path="promotion"
          element={<ProtectedRoute page={<CoreHRPromotionIndex />} />}
        >
          <Route
            index
            element={<ProtectedRoute page={<CoreHRPromotionsDashboard />} />}
          />
          <Route
            path="add"
            element={<ProtectedRoute page={<CoreHRPromotionAdd />} />}
          />
          <Route
            path="edit/:id"
            element={<ProtectedRoute page={<CoreHRPromotionEdit />} />}
          />
        </Route>
        {/* --- Warning --- */}
        <Route
          path="warning"
          element={<ProtectedRoute page={<CoreHRWarningIndex />} />}
        >
          <Route
            index
            element={<ProtectedRoute page={<CoreHRWarningDashboard />} />}
          />
          <Route
            path="list"
            element={<ProtectedRoute page={<CoreHRWarningList />} />}
          />
          <Route
            path="add"
            element={<ProtectedRoute page={<CoreHRWarningAdd />} />}
          />
          <Route
            path="edit/:id"
            element={<ProtectedRoute page={<CoreHRWarningEdit />} />}
          />
        </Route>
        {/* --- Announcement --- */}
        <Route
          path="announcement"
          element={<ProtectedRoute page={<CoreHRAnnouncementIndex />} />}
        >
          <Route
            index
            element={<ProtectedRoute page={<CoreHRAnnouncementDashboard />} />}
          />
          <Route
            path="add"
            element={<ProtectedRoute page={<CoreHRAnnouncementAdd />} />}
          />
          <Route
            path="edit/:id"
            element={<ProtectedRoute page={<CoreHRAnnouncementEdit />} />}
          />
        </Route>
        {/* --- Permission --- */}
        <Route
          path="permission"
          element={<ProtectedRoute page={<CoreHRPermissionIndex />} />}
        >
          <Route
            index
            element={<ProtectedRoute page={<CoreHRPermissionDashboard />} />}
          />
        </Route>
        {/* --- Overtime --- */}
        <Route
          path="overtime"
          element={<ProtectedRoute page={<CoreHROvertimeIndex />} />}
        >
          <Route
            index
            element={<ProtectedRoute page={<CoreHROvertimeDashboard />} />}
          />
        </Route>
        {/* --- Log View --- */}
        <Route
          path="log-view"
          element={<ProtectedRoute page={<CoreHRLogView />} />}
        />
        {/* --- Complaint --- */}
        <Route
          path="complaint"
          element={<ProtectedRoute page={<CoreHRComplaintIndex />} />}
        >
          <Route
            index
            element={<ProtectedRoute page={<CoreHRComplaintDashboard />} />}
          />
          <Route
            path="add"
            element={<ProtectedRoute page={<CoreHRComplaintAdd />} />}
          />
          <Route
            path="edit/:id"
            element={<ProtectedRoute page={<CoreHRComplaintEdit />} />}
          />
        </Route>
        {/* --- Not Found --- */}
        <Route path="*" element={<NotFoundPage />} />
      </Route>

      {/* --- Timesheet --- */}
      <Route
        path="/timesheet"
        element={<ProtectedRoute page={<TimesheetTabPanel />} />}
      >
        <Route
          index
          element={<ProtectedRoute page={<TimesheetAttendanceDashboard />} />}
        />
        <Route
          path="attendance"
          element={<ProtectedRoute page={<TimesheetAttandanceIndex />} />}
        >
          <Route
            index
            element={<ProtectedRoute page={<TimesheetAttendanceDashboard />} />}
          />
          <Route
            path="/timesheet/attendance/list"
            element={<ProtectedRoute page={<AttendanceList />} />}
          />
        </Route>
        <Route
          path="attendance-correction"
          element={<ProtectedRoute page={<TimesheetAttandanceCorrection />} />}
        />
        <Route
          path="/timesheet/attendance-correction/list-attendance-correction"
          element={<ProtectedRoute page={<AttendanceCorrectionList />} />}
        />
        <Route
          path="attendance-history"
          element={<ProtectedRoute page={<TimesheetAttandanceHistory />} />}
        />
      </Route>

      {/* -- Organization -- */}
      <Route
        path="/organization"
        element={<ProtectedRoute page={<OrganizationTabPanel />} />}
      >
        <Route
          index
          element={<ProtectedRoute page={<DepartmentDashboard />} />}
        />

        {/* -- Organization #department -- */}

        <Route path="department">
          <Route
            index
            element={<ProtectedRoute page={<DepartmentDashboard />} />}
          />

          <Route
            path="add"
            element={<ProtectedRoute page={<DepartmentAdd />} />}
          />

          <Route
            path="edit/:id"
            element={<ProtectedRoute page={<DepartmentEdit />} />}
          />

          <Route
            path="detail/:id"
            element={<ProtectedRoute page={<DepartmentList />} />}
          />

          <Route
            path="position"
            element={<ProtectedRoute page={<PositionList />} />}
          />

          <Route
            path="position/add"
            element={<ProtectedRoute page={<PositionAdd />} />}
          />

          <Route
            path="position/edit/:id"
            element={<ProtectedRoute page={<PositionEdit />} />}
          />
        </Route>

        {/** Organization #location */}
        <Route path="location">
          <Route
            index
            element={<ProtectedRoute page={<LocationDashboard />} />}
          />

          <Route
            path="add"
            element={<ProtectedRoute page={<AddLocation />} />}
          />
          <Route
            path="edit/:id"
            element={<ProtectedRoute page={<EditLocation />} />}
          />
        </Route>

        {/** Organization #company-policy */}
        <Route path="company-policy">
          <Route
            index
            element={<ProtectedRoute page={<CompanyPolicyDashboard />} />}
          />
          <Route
            path="add"
            element={<ProtectedRoute page={<AddCompanyPolicy />} />}
          />
          <Route
            path="edit/:id"
            element={<ProtectedRoute page={<EditCompanyPolicy />} />}
          />
        </Route>

        {/** Organization #Organization */}
        <Route
          path="organization"
          element={<ProtectedRoute page={<DepartmentOrganization />} />}
        />
      </Route>

      {/* --- Project --- */}
      <Route
        path="manager-project"
        element={<ProtectedRoute page={<ProjectDashboard />} />}
      >
        <Route index element={<ProtectedRoute page={<SummaryProject />} />} />
        <Route path="add" element={<ProtectedRoute page={<AddProject />} />} />
        <Route
          path="detail"
          element={<ProtectedRoute page={<ProjectTabPanel />} />}
        >
          <Route index element={<ProtectedRoute page={<IndexBoards />} />} />

          {/* Boards */}
          <Route
            path="boards"
            element={<ProtectedRoute page={<ProjectBoards />} />}
          >
            <Route index element={<ProtectedRoute page={<IndexBoards />} />} />
            <Route
              path="task"
              element={<ProtectedRoute page={<AddTask />} />}
            />
            <Route
              path="task/:id"
              element={<ProtectedRoute page={<DetailTask />} />}
            />
          </Route>

          {/* Timeline */}
          <Route
            path="timeline"
            element={<ProtectedRoute page={<ProjectTimeline />} />}
          >
            <Route
              index
              element={<ProtectedRoute page={<IndexTimeline />} />}
            />
          </Route>

          {/* Discussion */}
          <Route
            path="discussion"
            element={<ProtectedRoute page={<ProjectDiscussion />} />}
          >
            <Route
              index
              element={<ProtectedRoute page={<IndexDiscussion />} />}
            />
          </Route>

          {/* Activities */}
          <Route
            path="activities"
            element={<ProtectedRoute page={<ProjectActivities />} />}
          >
            <Route
              index
              element={<ProtectedRoute page={<IndexActivities />} />}
            />
          </Route>
        </Route>
      </Route>

      <Route
        path="/recruitment"
        element={<ProtectedRoute page={<RecruitmentTabPanel />} />}
      >
        <Route
          index
          element={<ProtectedRoute page={<RecruitmentRequestDashboard />} />}
        />
        <Route
          path="vacancies"
          element={<ProtectedRoute page={<CoreHRAwardsIndex />} />}
        >
          <Route
            index
            element={<ProtectedRoute page={<RecruitmentJobsDashboard />} />}
          />
          <Route
            path="add"
            element={<ProtectedRoute page={<RecruitmentJobsAdd />} />}
          />
          <Route
            path="list"
            element={<ProtectedRoute page={<TableJobs />} />}
          />
          <Route
            path="edit/:id"
            element={<ProtectedRoute page={<RecruitmentJobsEdit />} />}
          />
        </Route>
        <Route
          path="request"
          element={<ProtectedRoute page={<CoreHRAwardsIndex />} />}
        >
          <Route
            index
            element={<ProtectedRoute page={<RecruitmentRequestDashboard />} />}
          />
          <Route
            path="add"
            element={<ProtectedRoute page={<RecruitmentRequestAdd />} />}
          />
          <Route
            path="list"
            element={<ProtectedRoute page={<TableRequest />} />}
          />
          <Route
            path="edit/:id"
            element={<ProtectedRoute page={<RecruitmentRequestEdit />} />}
          />
        </Route>
        <Route
          path="candidate"
          element={<ProtectedRoute page={<CoreHRAwardsIndex />} />}
        >
          <Route
            index
            element={
              <ProtectedRoute page={<RecruitmentCandidateDashboard />} />
            }
          />

          <Route path=":id">
            <Route
              index
              element={<ProtectedRoute page={<RecruitmentCandidateDetail />} />}
            />
            <Route
              path="message/:candidateId"
              element={
                <ProtectedRoute page={<RecruitmentCandidateMessage />} />
              }
            />
            <Route
              path="note/:candidateId"
              element={<ProtectedRoute page={<RecruitmentCandidateNote />} />}
            />
            <Route
              path="interview/:candidateId"
              element={
                <ProtectedRoute page={<RecruitmentCandidateInterview />} />
              }
            />
          </Route>
          <Route path="all">
            <Route
              path=":id"
              element={
                <ProtectedRoute page={<RecruitmentAllCandidateDetail />} />
              }
            />
          </Route>
        </Route>
      </Route>

      {/* Report Menu */}
      <Route
        path="/report"
        element={<ProtectedRoute page={<ReportDashboard />} />}
      />

      {/* HR Calendar */}
      <Route
        path="/calendar-hr"
        element={<ProtectedRoute page={<HrCalendarTabPanel />} />}
      >
        <Route
          index
          element={<ProtectedRoute page={<HRCalendarDashboard />} />}
        />
        <Route
          path="hr-calendar"
          element={<ProtectedRoute page={<HRCalendarIndex />} />}
        >
          <Route
            index
            element={<ProtectedRoute page={<HRCalendarDashboard />} />}
          />
          <Route
            path="add"
            element={<ProtectedRoute page={<HRCalendarAdd />} />}
          />
          <Route
            path=":id"
            element={<ProtectedRoute page={<HRCalendarEdit />} />}
          />
          <Route
            path="detail/:id"
            element={<ProtectedRoute page={<Outlets />} />}
          >
            <Route
              index
              element={<ProtectedRoute page={<PayrollListEmployee />} />}
            />
            <Route
              path="detail-employee/:id"
              element={<ProtectedRoute page={<PayrollListDetailEmployee />} />}
            />
          </Route>
        </Route>
        <Route
          path="history-calender"
          element={<ProtectedRoute page={<HRCalendarHistory />} />}
        />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default MainRouting;
