import { Grid } from "@mui/material"
import MainDiscussion from "../../../../components/organisms/project/discussion/mainDiscussion"
import SideDiscussion from "../../../../components/organisms/project/discussion/sideDiscussion"

const IndexDiscussion = () => {
    // Temp
    const fromLocal = JSON.parse(localStorage.getItem('cvSayaProject')).itemProject

    return(
    <Grid container spacing={0.5}>
      <Grid item md={9} sm={12} xs={12}>
        <MainDiscussion dataProject={fromLocal}/>
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <SideDiscussion dataProject={fromLocal}/>
      </Grid>
    </Grid>
  )
}

export default IndexDiscussion