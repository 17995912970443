import React, { useState } from "react";
import Data from "../../homepage/homepageAttendance/attendanceChart/dataMain";
import DataTooltips from "../../homepage/homepageAttendance/attendanceChart/dataTooltips";
import { Card, LineChart, DatePicker } from "../../../atoms";
import { Box, Typography, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../features/language";
import SelectFrequency from "../../homepage/homepageAttendance/selectFrequency";

const ChartCalendar = () => {
  const data = Data;
  const dataTooltips = DataTooltips;
  const lang = useSelector(getLanguage);
  const [frequency, setFrequency] = useState("Daily");
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  return (
    <Card sx={{ padding: "20px  25px", mt: "20px" }}>
      <Box display={"flex"} justifyContent={"space-between"} marginBottom={2}>
        {/* --- Title --- */}
        <Typography
          fontWeight={600}
          fontSize={"20px"}
          lineHeight={"27px"}
          letterSpacing={"0.01em"}
          fontStyle={"normal"}
          fontFamily={"sans-serif"}
          color={"#171717"}
        >
          {lang === "Id" ? "Ringkasan" : "Summary"}
        </Typography>
        {/* --- Select Frequency --- */}
        <Stack direction='row' alignItems='center' gap={2}>
          <SelectFrequency
            value={frequency}
            onChange={(value) => setFrequency(value)}
          />
          <DatePicker
            onChange={(dateRange) => setDateRange(dateRange)}
            value={dateRange}
          />
        </Stack>
      </Box>
      {/* Line chart */}
      <LineChart data={data} dataTooltips={dataTooltips} />;
    </Card>
  );
};

export default ChartCalendar;
