const DataTableJourney = [
  {
    title: "Employee Name",
    judul: "Nama Karyawan",
    url: "name",
  },
  {
    title: "Place Of Visit",
    judul: "Tempat Yang Di kunjungi",
    url: "place",
  },
  {
    title: "Status",
    judul: "Status",
    url: "status",
  },
  {
    title: "End Date",
    judul: "Tanggal Berakhir",
    url: "end_date",
  },
  {
    title: "Action",
    judul: "Aksi",
    url: "aksi",
  },
];

export default DataTableJourney;
