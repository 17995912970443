const DataTablePromotion = [
    {
        title: 'Employee Name',
        judul: 'Nama Karyawan',
        url: 'name',
    },
    {
        title: 'Promotion',
        judul: 'Promosi',
        url: 'promotion',
    },
    {
        title: 'Date Of Appoinment',
        judul: 'Tanggal Promosi',
        url: 'date',
    },
    {
        title: 'Description',
        judul: 'Deskripsi',
        url: 'name',
    },
    {
        title: 'Status',
        judul: 'Status',
        url: 'name',
    },
    {
        title: 'Action',
        judul: 'Aksi',
        url: 'name',
    },
]

export default DataTablePromotion;