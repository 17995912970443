import { Box, Typography } from "@mui/material"
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'

const CardChangeBackgroundBoard = () => {
  return(
    <Box 
      sx={{
        width: '240px',
        height: '170px',
        paddingX: '8px',
        paddingY: '20px',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
      >
        <Typography
          fontFamily={'sans-serif'}
          fontStyle={'normal'}
          fontWeight={400}
          fontSize={'12px'}
          textAlign={'center'}
        >
          Change Background
        </Typography>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            marginTop: '20px',
            borderStyle: 'dashed',
            borderColor: '#C4C4C4',
            borderRadius: '10px',
            width: 'full',
            height: '110px',
            
          }}
        >
          <AddPhotoAlternateIcon
            sx={{
              color: '#C4C4C4',
              width: '40px',
              height: '40px',
            }}
          />
          <Typography
            fontFamily={'sans-serif'}
            fontStyle={'normal'}
            fontWeight={400}
            fontSize={'8px'}
            textAlign={'center'}
            color={'#C4C4C4'}
            marginX={'20px'}
          >
            NB: Format file JPG, JPEG, PNG (Rasio 1:1, min. 360 x 360px, maks. 1MB).
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default CardChangeBackgroundBoard