import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { IconDashboardUp } from '../../../../assets/dashboard/icons';
import { DataDasboardCard } from '../../../../data/card';
import { getLanguage } from '../../../../features/language';
import { Card } from '../../../atoms';

const HomepageCard = () => {
  const lang = useSelector(getLanguage);

  return (
    <Grid container columns={4} spacing={2}>
      {DataDasboardCard.map((item, idx) => (
        <Grid item xl={1} lg={1} md={2} sm={2} xs={4} key={idx}>
          <Card sx={{ padding: '28px 30px' }}>
            <Box display={'flex'} flexDirection={'column'}>
              {/* --- Title --- */}
              <Box display={'flex'} mb={'10px'} alignItems={'center'}>
                {item.icon}
                <Typography
                  fontFamily={'sans-serif'}
                  fontStyle={'normal'}
                  fontWeight={600}
                  fontSize={'15px'}
                  lineHeight={'20px'}
                  color={'#171717'}
                  ml={'10px'}
                >
                  {lang === 'Id' ? item.judul : item.title}
                </Typography>
              </Box>
              {/* --- Description --- */}

              {item.title === 'Employee' ? (
                <Box display={'flex'} alignItems={'center'}>
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontWeight={400}
                    fontSize={'30px'}
                    lineHeight={'41px'}
                    color={'#171717'}
                    mr={'12px'}
                  >
                    1401
                  </Typography>
                  <Box>
                    <Box display={'flex'} alignItems={'center'}>
                      <IconDashboardUp />
                      <Typography
                        fontFamily={'sans-serif'}
                        fontStyle={'normal'}
                        fontWeight={600}
                        fontSize={'12px'}
                        lineHeight={'16px'}
                        color={'#51B15C'}
                        ml={'6px'}
                      >
                        +12
                      </Typography>
                    </Box>
                    <Typography
                      fontFamily={'sans-serif'}
                      fontStyle={'normal'}
                      fontWeight={600}
                      fontSize={'12px'}
                      lineHeight={'16px'}
                      color={'#939393'}
                      mt={'9px'}
                    >
                      One Last Month
                    </Typography>
                  </Box>
                </Box>
              ) : item.title === 'Total Salary Unpaid' ||
                item.title === 'Total Salary Paid' ? (
                <Box display={'flex'} alignItems={'center'}>
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontWeight={400}
                    fontSize={'20px'}
                    lineHeight={'27px'}
                    color={'#939393'}
                  >
                    Rp
                  </Typography>
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontWeight={400}
                    fontSize={'30px'}
                    lineHeight={'41px'}
                    color={'#171717'}
                    ml={'4px'}
                  >
                    {item.title === 'Total Salary Unpaid'
                      ? '560.000*'
                      : '120.000*'}
                  </Typography>
                </Box>
              ) : (
                <Box display={'flex'} alignItems={'center'}>
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontWeight={400}
                    fontSize={'30px'}
                    lineHeight={'41px'}
                    color={'#171717'}
                    mr={'4px'}
                  >
                    120
                  </Typography>
                  <Typography
                    fontFamily={'sans-serif'}
                    fontStyle={'normal'}
                    fontWeight={400}
                    fontSize={'20px'}
                    lineHeight={'27px'}
                    color={'#939393'}
                  >
                    Days
                  </Typography>
                </Box>
              )}
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default HomepageCard;
