const DataTooltips = [
    {
      label: 'My First Dataset',
      dataset: [
        {
          cordinat: '',
          name: 'Ramli',
          date: '01-01-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
  
          name: 'Ramli 1',
          date: '01-02-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
  
          name: 'Ramli 2',
          date: '01-03-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
          name: 'Ramli 3',
          date: '01-04-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
          name: 'Ramli 4',
          date: '01-05-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
          name: 'Ramli 5',
          date: '01-06-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
          name: 'Ramli 6',
          date: '01-01-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
      ],
    },
    {
      label: 'My Second Dataset',
      dataset: [
        {
          cordinat: '',
          name: 'Ramli 7',
          date: '01-01-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
  
          name: 'Ramli 8',
          date: '01-02-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
  
          name: 'Ramli 9',
          date: '01-03-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
          name: 'Ramli 10',
          date: '01-04-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
          name: 'Ramli 11',
          date: '01-05-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
          name: 'Ramli 12',
          date: '01-06-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
          name: 'Ramli 13',
          date: '01-01-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
      ],
    },
    {
      label: 'My Three Dataset',
      dataset: [
        {
          cordinat: '',
          name: 'Ramli 14',
          date: '01-01-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
  
          name: 'Ramli 15',
          date: '01-02-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
  
          name: 'Ramli 16',
          date: '01-03-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
          name: 'Ramli 17',
          date: '01-04-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
          name: 'Ramli 18',
          date: '01-05-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
          name: 'Ramli 19',
          date: '01-06-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
        {
          cordinat: '',
          name: 'Ramli 20',
          date: '01-01-2020',
          picture:
            'https://dev-cvsaya2.x5.com.au/storage/images/profile_picture/1681109232.png',
        },
      ],
    },
  ];

  export default DataTooltips