import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getStaticMenus, setNavigation } from '../../../../features/navigation';
import { styled } from '@mui/material/styles';

const ListMenu = ({ open, active = false, children, name, to }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  // --- Icon Theme ---
  const color = {
    active: theme.palette.primary.main,
    unactive: '#C4C4C4',
  };

  // --- Set Navigation ---
  const handleNavigationClick = (navigationName) => {
    let data = JSON.parse(localStorage.getItem('cvSayaData'));

    if (!data) {
      data = {
        coreHRMenu: 0,
        timesheetMenu: 0,
        employeeMenu: 0,
        payrollMenu: 0,
      };
    } else {
      data.coreHRMenu = 0;
      data.timesheetMenu = 0;
      data.employeeMenu = 0;
      data.payrollMenu = 0;
    }

    localStorage.setItem('cvSayaData', JSON.stringify(data));
    dispatch(setNavigation(navigationName));
  };

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 220,
      fontSize: 14,
      textAlign: 'left',
      marginLeft: '20px',
      background: '#FFFFFF',
      color: '#171717',
      boxShadow: '0px 4px 30px rgba(0,0,0, .1) ',
      padding: '10px',
      fontWeight: 400,
    },
  });

  return (
    <CustomWidthTooltip
      followCursor
      // title="Lorem ipsum, adalah teks standar yang ditempatkan untuk "
    >
      <Link
        to={to}
        onClick={() => handleNavigationClick(name)}
        style={{ color: '#000' }}
      >
        <ListItem
          disablePadding
          sx={[
            {
              display: 'block',
              '&:hover': {
                bgcolor: '#F3F8F3',
              },
              bgcolor: active ? '#F3F8F3' : 'none',
            },

            active && {
              borderLeft: 4,
              borderColor: theme.palette.primary.main,
            },
          ]}
        >
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                width: '20px',
                display: 'flex',
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {children}
            </ListItemIcon>
            <ListItemText
              primary={name}
              sx={[
                { opacity: open ? 1 : 0 },
                {
                  '.MuiTypography-root': {
                    fontWeight: active ? 700 : 400,
                    color: active ? color.active : color.unactive,
                  },
                },
              ]}
            />
          </ListItemButton>
        </ListItem>
      </Link>
    </CustomWidthTooltip>
  );
};

export default ListMenu;
