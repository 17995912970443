import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  Stack,
} from "@mui/material";
import moment from "moment";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { Card, Modals } from "../../../../components/atoms";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../features/language";
import { IconClose } from "../../../../assets/commons";
import {
  IconDownload,
  IconRightArrow,
} from "../../../../assets/benefit/reimbursement";
import FileSaver from "file-saver";

import { getFileReimbursement } from "../../../../configs/axios/apiPath";

const BenefitReimbursementDetail = ({ isOpen, onClose, detail, isLoading }) => {
  const lang = useSelector(getLanguage);

  return (
    <Modals open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "background.paper",
        }}
      >
        <Card>
          <Box p={"30px"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: "7px",
                position: "sticky",
                top: 0,
                bgcolor: "background.paper",
                p: "10px 0px 10px 0px",
                zIndex: 999,
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontSize={"20px"}
                fontWeight={600}
                lineHeight={"27px"}
                color={"#171717"}
              >
                {lang === "Id" ? "Detail" : "Details"}
              </Typography>
              <IconButton sx={{ p: "0px" }} onClick={onClose}>
                <IconClose />
              </IconButton>
            </Box>

            {!isLoading && detail ? (
              <Box mt={"20px"} component={"form"} noValidate autoComplete="off">
                {/* --- Employee --- */}
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    {lang === "Id" ? "Karyawan" : "Employee"}
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      background: "#f7f7f7",
                      borderRadius: "4px",
                      border: "1px solid transparent",
                      display: "inline-block",
                    }}
                  >
                    <Typography mx={"10px"} py={"10px"}>
                      {detail?.employee.name}
                    </Typography>
                  </Box>
                </Box>
                {/* --- Date --- */}
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    {lang === "Id" ? "Tanggal" : "Date"}
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      background: "#f7f7f7",
                      borderRadius: "4px",
                      border: "1px solid transparent",
                      display: "inline-block",
                    }}
                  >
                    <Typography mx={"10px"} py={"10px"}>
                      {moment(detail?.date).format("D MMMM YYYY")}
                    </Typography>
                  </Box>
                </Box>
                {/* --- Reason --- */}
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    {lang === "Id" ? "Alasan" : "Reason"}
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      background: "#f7f7f7",
                      borderRadius: "4px",
                      border: "1px solid transparent",
                      display: "inline-block",
                    }}
                  >
                    <Typography mx={"10px"} py={"10px"}>
                      {detail?.reason}
                    </Typography>
                  </Box>
                </Box>
                {/* --- Expected Budget --- */}
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    {lang === "Id"
                      ? "Anggaran yang Diharapkan"
                      : "Expected Budget"}
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      background: "#f7f7f7",
                      borderRadius: "4px",
                      border: "1px solid transparent",
                      display: "inline-block",
                    }}
                  >
                    <Typography mx={"10px"} py={"10px"}>
                      Rp {parseInt(detail?.amount).toLocaleString("ID-id")}*
                    </Typography>
                  </Box>
                </Box>

                {/* --- File --- */}
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    File
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      background: "#f7f7f7",
                      borderRadius: "4px",
                      border: "1px solid transparent",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography mx={"10px"} py={"10px"}>
                      {detail?.file_url.split("/").pop()}
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={"8px"}
                      sx={{
                        alignContent: "center",
                        alignItems: "center",
                        marginRight: "10px",
                      }}
                    >
                      <IconButton
                        sx={{
                          backgroundColor: "#E6E6E6",
                          borderRadius: "100%",
                          padding: "5px",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          height: "fit-content",
                        }}
                        onClick={() => window.open(detail?.file_url)}
                      >
                        <IconRightArrow />
                      </IconButton>
                      <IconButton
                        sx={{
                          backgroundColor: "#E6E6E6",
                          borderRadius: "100%",
                          padding: "5px",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          height: "fit-content",
                        }}
                        onClick={async () => {
                          let { data } = await getFileReimbursement({
                            url: detail?.file_url,
                          });
                          FileSaver.saveAs(
                            data,
                            detail?.file_url.split("/").pop()
                          );
                        }}
                      >
                        <IconDownload />
                      </IconButton>
                    </Stack>
                  </Box>
                </Box>
                {/* --- Description --- */}
                <Box
                  mb={"20px"}
                  p={"0px"}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"13px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    letterSpacing={"1px"}
                    color={"#171717"}
                    mb={"6px"}
                  >
                    {lang === "Id" ? "Deskripsi" : "Description"}
                  </Typography>
                  <Box
                    style={{
                      width: "100%",
                      background: "#f7f7f7",
                      borderRadius: "4px",
                      border: "1px solid transparent",
                      display: "inline-block",
                    }}
                  >
                    <Typography
                      mx={"10px"}
                      py={"10px"}
                      height={"auto"}
                      maxHeight={"160px"}
                      sx={{ overflowY: "scroll" }}
                    >
                      {detail?.description}
                    </Typography>
                  </Box>
                </Box>

                {/* --- Close --- */}
                <Button
                  variant="contained"
                  disableRipple
                  onClick={onClose}
                  fullWidth
                  sx={{ height: "62px" }}
                >
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontSize={"20px"}
                    fontWeight={"700px"}
                    lineHeight={"27px"}
                    letterSpacing={"1px"}
                    textTransform={"none"}
                  >
                    {lang === "Id" ? "Keluar" : "Close"}
                  </Typography>
                </Button>
              </Box>
            ) : (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress color="secondary" />
              </Box>
            )}
          </Box>
        </Card>
      </Box>
    </Modals>
  );
};

export default BenefitReimbursementDetail;
