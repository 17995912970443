import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card } from '../../../../../components/atoms';
import {
  getListEmployeePayslipById,
  getSelectPayrollPeriods,
  patchGeneratePaid,
} from '../../../../../configs/axios/apiPath';
import {
  PayrollDetailAdhoc,
  PayrollDetailBank,
  PayrollDetailCard,
  PayrollDetailTitle,
} from '../../../../../components/organisms/payroll';
import AddAdhoc from '../addAdhoc';

export default function PayrollListDetailEmployee() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [totalAmountAdhoc, setTotalAmountAdhoc] = useState(0);
  const [totalAmountAllowance, setTotalAmountAllowance] = useState(0);
  const [totalAmountDeduction, setTotalAmountDeduction] = useState(0);
  const [selectPayroll, setSelectPayroll] = useState('');
  const parsedData = JSON.parse(localStorage.getItem('cvSayaData'));
  const idPayslip = parsedData && parsedData.idPayslip;
  const [status, setStatus] = useState('');
  const [idPayroll, setIdPayroll] = useState(() => {
    const parsedData = JSON.parse(localStorage.getItem('cvSayaData'));
    return parsedData ? parsedData.idPayslip : null;
  });

  // --- Get Id ---
  const path = useLocation().pathname;
  const pathSegments = path.split('/');
  const lastSegment = pathSegments.pop();
  const idEmployee = parseInt(lastSegment);

  // --- Get Payroll Employee ---
  useEffect(() => {
    async function fetchData() {
      if (idPayroll !== null) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListEmployeePayslipById(idPayroll);
          setData(data);
          setStatus(data?.status);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [idPayroll, navigate]);

  // --- Get Select Payroll Period ---
  useEffect(() => {
    async function fetchData() {
      if (idEmployee) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getSelectPayrollPeriods(idEmployee);
          setSelectPayroll(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem('cvSayaLogin');
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }
    fetchData();
  }, [idEmployee, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const params = {
      generated_by: 5024,
    };
    setIsLoading(true);
    if (idEmployee !== null) {
      try {
        const response = await patchGeneratePaid(params, idPayslip);
        if (response.data.meta?.code === 20000) {
          navigate(`/payroll/payroll-period/detail/${pathSegments[4]}`);
        }
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          localStorage.removeItem('cvSayaLogin');
          navigate(`/users`);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setTotalAmountAllowance(
      data?.calculations?.reduce?.((a, b) => a + b.amount, 0)
    );

    setTotalAmountDeduction(
      data?.deductions?.reduce?.((a, b) => a + b.amount, 0)
    );
  }, [data]);
  return (
    <Card>
      <Box display={'flex'} flexDirection={'column'} p={'23px 22px'}>
        {/* --- Title --- */}
        <PayrollDetailTitle
          item={data.employee}
          data={selectPayroll}
          setEmployeeId={setIdPayroll}
        />
        {isLoading ? (
          <Box
            display={'flex'}
            width={'100%'}
            py={'30px'}
            justifyContent={'center'}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {/* --- Detail Card --- */}
            <PayrollDetailCard item={data} />

            {/* --- Adhoc --- */}
            {data.payslip_ad_hocs?.length === 0 ? (
              ''
            ) : (
              <Box mb="20px">
                <PayrollDetailAdhoc
                  item={data?.payslip_ad_hocs}
                  setTotalAmount={setTotalAmountAdhoc}
                  totalAmount={totalAmountAdhoc}
                  id={idPayroll}
                />
              </Box>
            )}

            {/* --- Detail Bank --- */}
            <PayrollDetailBank
              item={data?.bank_account}
              thp={data?.thp}
              generated={data?.generated_by?.name}
              paid={data?.paid_by?.name}
            />
          </Box>
        )}

        {/* --- Action --- */}
        <Box display={'flex'} justifyContent={'end'} mt={'16px'}>
          <Button
            variant="outlined"
            color="inherit"
            disabled={status === 'paid'}
            onClick={() => setIsModalOpen(true)}
          >
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontSize={'15px'}
              fontWeight={600}
              lineHeight={'20px'}
              letterSpacing={'1px'}
              color={'#171717'}
              textTransform={'none'}
            >
              Add Adhoc
            </Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={status === 'paid'}
            onClick={handleSubmit}
            style={{ marginLeft: '16px' }}
          >
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontSize={'15px'}
              fontWeight={600}
              lineHeight={'20px'}
              letterSpacing={'1px'}
              color={'#fff'}
              textTransform={'none'}
            >
              Generate
            </Typography>
          </Button>
        </Box>
      </Box>

      <AddAdhoc
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        id={idPayslip}
      />
    </Card>
  );
}
