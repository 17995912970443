import { Box, Typography, Avatar, Stack } from "@mui/material";
import { TooltipsAction, Tooltips } from "../../../../../atoms";
import { useNavigate } from "react-router-dom";
import {
  handleTooltipClose,
  handleTooltipOpen,
} from "../../../../../../utils/function/tooltips";
import moment from "moment";
import { useState, useEffect } from "react";
import {
  IconDelete,
  IconDetail,
  IconEdit,
} from "../../../../../../assets/commons";
import { IconReason } from "../../../../../../assets/benefit/reimbursement";
import { BenefitReimbursementDetail } from "../../../../../../pages/benefit";
import { ModalDelete } from "../../../../modal";
import { handleAction } from "../../../../../../utils/function/action";
import { getReimbursementById } from "../../../../../../configs/axios/apiPath";
const ReimbursementTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idEmployee, setIdEmployee] = useState(null);
  const [modal, setModal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(null);
  const [detail, setDetail] = useState(null);

  const handleEdit = (id) => {
    navigate(`/benefit/reimbursement/edit/${id}`);
  };

  // --- Handle Detail ---
  const handleDetail = (id) => {
    setIsModalOpen(true);
    setId(id);
    setModal("detail");
  };

  // --- Load Detail ---
  useEffect(() => {
    async function fetchData() {
      if (id !== null) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getReimbursementById(id);
          setDetail(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [id, navigate]);

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Employee --- */}
            <td
              style={{
                padding: "10px",
                verticalAlign: "middle",
              }}
            >
              <Stack direction="row" alignItems="center" gap="10px">
                <Avatar src={item.image_url} />
                <Typography
                  fontFamily={"sans-serif"}
                  fontStyle={"normal"}
                  fontWeight={400}
                  fontSize={"14px"}
                  lineHeight={"130%"}
                  color={"#171717"}
                >
                  {item.name}
                </Typography>
              </Stack>
            </td>

            {/* --- Date --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {moment(item?.date).format("D MMM")}
              </Typography>
            </td>
            {/* --- Reason --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Tooltips title={item.reason} arrow placement={"bottom-end"}>
                <Box>
                  <IconReason />
                </Box>
              </Tooltips>
              {/* {item?.reason} */}
            </td>

            {/* --- Action --- */}
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                <TooltipsAction title="Detail" arrow placement={"bottom-end"}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleDetail(item.id)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>
                {/* --- Edit --- */}
                <TooltipsAction title="Edit" arrow placement={"bottom-end"}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleEdit(item.id)}
                  >
                    <IconEdit />
                  </Box>
                </TooltipsAction>

                {/* --- Delete --- */}
                <TooltipsAction title="Delete" arrow placement={"bottom-end"}>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        "delete"
                      )
                    }
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
      {modal === "detail" ? (
        <BenefitReimbursementDetail
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          detail={detail}
          isLoading={isLoading}
        />
      ) : modal === "delete" ? (
        <ModalDelete
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={"Reimbursement"}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          id={idEmployee}
          data={tbody}
          setData={setData}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ReimbursementTbody;
