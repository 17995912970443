import { Box, CircularProgress, Typography } from "@mui/material"

const CircleProgressWithLabel = ({value, status}) => {
  const val = Math.round(value)

  const handleStatus = () => {
    if (status === 'done') {
      return 'success';
    } else if (status === 'process') {
      return 'warning';
    } else if (status === 'delayed') {
      return 'info';
    } else if (status === 'canceled') {
      return 'error';
    }
  };

  return (
    <Box 
      sx={{ 
        position: 'relative', 
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#E6E6E6',
        borderRadius: '50%',
        width: '50px',
        height: '50px'
      }}
    >
      <CircularProgress 
        variant="determinate"
        color={handleStatus()}
        value={val}
        size={50} 
        thickness={8}
      />
      <Box
        sx={{
          top: 8,
          left: 8,
          bottom: 8,
          right: 8,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          borderRadius: '50%'
        }}
      >
        <Typography 
          sx={{
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '12px',
          }}
        >
          {`${val}%`}
        </Typography>
      </Box>
    </Box>
  )
}

export default CircleProgressWithLabel