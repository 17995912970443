import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import moment from "moment";
import { IconDetail, IconEdit } from "../../../../../../assets/commons";
import { TooltipsAction } from "../../../../../atoms";
import {
  handleTooltipClose,
  handleTooltipOpen,
} from "../../../../../../utils/function/tooltips";
import PayrollListEdit from "../../../../../../pages/payroll/payrollList/payrollListEdit";

const PayrollListTbody = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idEmployee, setIdEmployee] = useState(null);

  const [idTooltipDetail, setIdTooltipDetail] = useState("");
  const [isTooltipDetail, setIsTooltipDetail] = useState(false);
  const [idTooltipEdit, setIdTooltipEdit] = useState("");
  const [isTooltipEdit, setIsTooltipEdit] = useState(false);

  // --- Handle Edit ---
  const handleDetail = (id) => {
    let dataToStore = {};

    // Periksa apakah data sudah ada di localStorage
    if (localStorage.getItem("cvSayaData")) {
      dataToStore = JSON.parse(localStorage.getItem("cvSayaData"));

      // Tambahkan idPayroll ke objek yang sudah ada
      dataToStore.idPayroll = id;
    } else {
      // Buat objek baru dengan idPayroll jika data belum ada di localStorage
      dataToStore = {
        idPayroll: id,
      };
    }

    localStorage.setItem("cvSayaData", JSON.stringify(dataToStore));
    navigate(`/payroll/payroll-period/detail/${id}`);
  };
  //   --- Handle Edit ---
  const handleEdit = (id) => {
    setIsModalOpen(true);
    setIdEmployee(id);
  };

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Salary Name --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item?.name}
              </Typography>
            </td>
            {/* --- Start Date --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {moment(item?.started_at).format("D MMM")}
              </Typography>
            </td>
            {/* --- End Date --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {moment(item?.ended_at).format("D MMM")}
              </Typography>
            </td>
            {/* --- Start Date --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item?.working_day_count} days
              </Typography>
            </td>

            {/* --- Action --- */}
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                {/* --- Edit --- */}
                <TooltipsAction
                  title="Edit"
                  open={isTooltipEdit && item.id === idTooltipEdit}
                  arrow
                  placement={"bottom-end"}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    display={"flex"}
                    alignItems={"center"}
                    onClick={() => handleEdit(item.id)}
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipEdit,
                        setIdTooltipEdit
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipEdit)}
                  >
                    <IconEdit />
                  </Box>
                </TooltipsAction>
                {/* --- Detail --- */}
                <TooltipsAction
                  title="Detail"
                  open={isTooltipDetail && item.id === idTooltipDetail}
                  arrow
                  placement={"bottom-end"}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleDetail(item.id)}
                    display={"flex"}
                    alignItems={"center"}
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDetail,
                        setIdTooltipDetail
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDetail)}
                  >
                    <IconDetail />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>

      {/* --- Modal Handle --- */}
      {idEmployee !== null && (
        <PayrollListEdit
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          data={tbody}
          setData={setData}
          id={idEmployee}
        />
      )}
    </>
  );
};

export default PayrollListTbody;
