import { useSelector } from "react-redux";
import { useState } from "react";
import {
  Autocomplete,
  Box,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
  Grid,
  InputAdornment,
  Button,
} from "@mui/material";
import { getLanguage } from "../../features/language";
import { Card } from "../../components/atoms";

import { useNavigate } from "react-router-dom";
import { getCompany } from "../../features/companies";
import { getExportStatus, postReport } from "../../configs/axios/apiPath";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const ReportDashboard = () => {
  let localData = JSON.parse(localStorage.getItem("cvSayaData"));
  const navigate = useNavigate();
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [isGenerateLoading, setIsGenerateLoading] = useState(false);

  const handleButtonExportClick = async () => {
    if (startDate && endDate && company) {
      const data = {
        company_id: company,
        module: "employees",
        start_date: startDate.format("YYYY-MM-DD"),
        end_date: endDate.format("YYYY-MM-DD"),
      };

      setIsGenerateLoading(true);
      try {
        const response = await postReport(data);
        const filename = `employees_${startDate.format(
          "DD MMMM YYYY"
        )}_${endDate.format("d MMMM YYYY")}.xlsx`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsGenerateLoading(false);
      } catch (error) {
        console.error(error);
        if (error?.response?.status === 401) {
          localStorage.removeItem("cvSayaLogin");
          navigate(`/users`);
        }
      }
    }
  };

  const FormLabel = ({ label }) => (
    <InputLabel
      style={{
        fontFamily: "sans-serif",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "13px",
        lineHeight: "22px",
        letterSpacing: "0.02em",
        color: "#171717",
        marginBottom: "10px",
      }}
    >
      {label}
    </InputLabel>
  );
  return (
    <Box>
      {/* --- Tab Panel --- */}
      <Box
        sx={{
          maxWidth: {
            xl: "96vw",
            lg: "96vw",
            md: "89vw",
            xs: "75vw",
            sm: "84vw",
          },
          p: "0px",
        }}
      >
        <Card sx={{ padding: "20px  25px", mt: "20px" }}>
          <Box display={"flex"}>
            {/* --- Title --- */}
            <Box sx={{ mt: "10px" }}>
              <Typography
                fontWeight={600}
                fontSize={"20px"}
                lineHeight={"27px"}
                letterSpacing={"0.01em"}
                fontStyle={"normal"}
                fontFamily={"sans-serif"}
                color={"#171717"}
              >
                {lang === "Id" ? "Ekspor Karyawan" : "Employee Export"}
              </Typography>
            </Box>
          </Box>
          <hr color={"#F1F1F1"} width="100%" />
          <Box display={"flex"} sx={{ mt: "20px" }}>
            <Grid container columns={2} spacing={2}>
              <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                  <FormLabel label="Date From*" />
                  <Box display={"flex"}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        selectsStart
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        startDate={startDate}
                        format="DD/MMM/YYYY"
                        sx={{
                          width: "100%",
                        }}
                        slotProps={{ textField: { variant: "outlined" } }}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Grid>

              <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                <Box display={"flex"} flexDirection={"column"} mb={"9px"}>
                  <FormLabel label="Date To*" />
                  <Box display={"flex"}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        selectsEnd
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        endDate={endDate}
                        startDate={startDate}
                        minDate={startDate}
                        format="DD/MMM/YYYY"
                        sx={{
                          width: "100%",
                        }}
                        slotProps={{ textField: { variant: "outlined" } }}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mt: "20px" }}>
            <Button
              variant="contained"
              disableRipple
              disabled={isGenerateLoading}
              type="submit"
              onClick={handleButtonExportClick}
              sx={{
                width: "110px",
                height: "35px",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={600}
                fontSize={"16px"}
                lineHeight={"20px"}
                color={"#fff"}
                textTransform={"none"}
              >
                Export
              </Typography>
            </Button>
          </Box>
        </Card>

        {/* <Card sx={{ padding: "20px  25px", mt: "20px" }}>
          <TableComponent
            isLoading={isLoading}
            tbody={report}
            TableHead={
              <ReportThead
                thead={DataTableReport}
                setOrder={setOrder}
                setDirection={setDirection}
                direction={direction}
              />
            }
            TableBody={<ReportTbody tbody={report} setData={setreport} />}
            col={8}
          />
        </Card> */}
      </Box>
    </Box>
  );
};

export default ReportDashboard;
