import {
  Card,
  Typography,
  Divider,
  Grid,
  Stack,
  Autocomplete,
  CircularProgress,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField, InputFile } from "../../../../components/atoms";
import { getLanguage } from "../../../../features/language";
import { useSelector } from "react-redux";
import { IconFile, IconPaper } from "../../../../assets/benefit/reimbursement";
import { IconDept } from "../../../../assets/recruitment/jobs";
import { IconDate } from "../../../../assets/coreHr/awards";
import { IconHour } from "../../../../assets/commons";
import {
  postMessageCandidate,
  getListEmployee,
  putCandidatetNextStage,
} from "../../../../configs/axios/apiPath";

import { useEffect, useState } from "react";
import { getCompany } from "../../../../features/companies";

import { useNavigate, useLocation, useParams } from "react-router-dom";
const RecruitmentCandidateInterview = () => {
  const lang = useSelector(getLanguage);
  const company = useSelector(getCompany);
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);
  const { candidateId, id } = useParams();
  const [total, setTotal] = useState(1);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name"); // "value1"

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState();

  const handlePost = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      setIsLoading(false);
      const body = {
        interviewed_at: `${formData.date} ${formData.time}:00`,
        interviewed_by: formData.interviewer_id,
      };
      await putCandidatetNextStage(
        {
          vacancy_id: id,
          candidate_id: candidateId,
        },
        body
      );
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    } finally {
      navigate(`/recruitment/candidate/${id}`);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (company) {
        const params = {
          company_id: company,
          page_size: total,
        };
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getListEmployee(params);

          setEmployeeData(data.employee);
          if (data.page_info.total > 0) {
            setTotal(data.page_info.total);
          }
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData();
  }, [company, total]);

  return (
    <Card sx={{ padding: "20px" }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        marginBottom={"16px"}
        alignItems="center"
      >
        <Typography
          fontFamily={"sans-serif"}
          fontStyle={"normal"}
          fontWeight={600}
          fontSize={"20px"}
          lineHeight={"27px"}
          letterSpacing={"0.01em"}
          color={"#171717"}
        >
          {lang === "id" ? "Wawancara" : "Interview"}
        </Typography>
      </Stack>

      <Divider />
      <form onSubmit={handlePost}>
        <Grid container spacing={"16px"}>
          <Grid item xs={6}>
            <Autocomplete
              options={employeeData}
              getOptionLabel={(employee) => employee.name}
              onChange={(_, option) => {
                setFormData((prev) => ({
                  ...prev,
                  interviewer_id: option.id,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={lang === "Id" ? "Pewawancara" : "interviewer"}
                  fullWidth
                  required
                  variant="outlined"
                  size="small"
                  icon={
                    isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <IconPaper />
                    )
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <TextField
              label={lang !== "Id" ? "Date" : "Tanggal"}
              fullWidth
              required
              type="date"
              size="small"
              variant="outlined"
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  date: e.target.value,
                }));
              }}
              icon={<IconDate />}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <TextField
              icon={<IconHour />}
              label={lang !== "Id" ? "Hour" : "Jam"}
              fullWidth
              required
              type="time"
              size="small"
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  time: e.target.value,
                }));
              }}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          sx={{ marginTop: "30px" }}
          loading={isLoading}
        >
          {lang === "Id" ? "Simpan" : "Save"}
        </LoadingButton>
      </form>
    </Card>
  );
};

export default RecruitmentCandidateInterview;
