import { Box, Grid, Typography } from '@mui/material';
import { Card } from '../../../atoms';
import { formatNumber } from '../../../../utils/format';

export default function PayrollDetailBank({ item, thp, generated, paid }) {
  return (
    <Grid container columns={2} spacing={2} mb={'20px'}>
      {/* --- Bank Account --- */}
      <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
        <Card sx={{ height: '100%' }}>
          <Box padding={'14px 30px 30px 30px'}>
            {/* --- Title --- */}
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontSize={'18px'}
              fontWeight={600}
              lineHeight={'25px'}
              letterSpacing={'1px'}
              color={'#171717'}
              mb={'20px'}
            >
              Bank Account
            </Typography>
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontSize={'12px'}
              fontWeight={600}
              lineHeight={'16px'}
              letterSpacing={'1px'}
              color={'#939393'}
              mb={'4px'}
            >
              Account Number
            </Typography>
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontSize={'15px'}
              fontWeight={600}
              lineHeight={'20px'}
              letterSpacing={'1px'}
              color={'#171717'}
              mb={'16px'}
            >
              {item?.account_number === null ? 'No Data' : item?.account_number}
            </Typography>
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontSize={'12px'}
              fontWeight={600}
              lineHeight={'16px'}
              letterSpacing={'1px'}
              color={'#939393'}
              mb={'4px'}
            >
              Bank Name
            </Typography>
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontSize={'15px'}
              fontWeight={600}
              lineHeight={'20px'}
              letterSpacing={'1px'}
              color={'#171717'}
              mb={'16px'}
            >
              {item?.bank_name === null ? 'No Data' : item?.bank_name}
            </Typography>
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontSize={'12px'}
              fontWeight={600}
              lineHeight={'16px'}
              letterSpacing={'1px'}
              color={'#939393'}
              mb={'4px'}
            >
              On behalf of
            </Typography>
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontSize={'15px'}
              fontWeight={600}
              lineHeight={'20px'}
              letterSpacing={'1px'}
              color={'#171717'}
              mb={'16px'}
            >
              {item?.account_name === null ? 'No data' : item?.account_name}
            </Typography>
          </Box>
        </Card>
      </Grid>
      {/* --- Take HomePay --- */}
      <Grid item xl={1} lg={1} md={1} sm={2} xs={2}>
        <Card sx={{ height: '100%' }}>
          <Box padding={'14px 20px 36px 20px'}>
            {/* --- Title --- */}
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontSize={'18px'}
              fontWeight={600}
              lineHeight={'25px'}
              letterSpacing={'1px'}
              color={'#51B15C'}
              mb={'16px'}
            >
              Take Homepay
            </Typography>
            {/* --- Amount --- */}
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontSize={'24px'}
              fontWeight={700}
              lineHeight={'33px'}
              letterSpacing={'1px'}
              color={thp > 0 ? '#51B15C' : '#F33A3A'}
              mb={'20px'}
            >
              {thp > 0 ? formatNumber(thp) : `-${formatNumber(Math.abs(thp))}`}
            </Typography>
            {/* --- Generated By --- */}
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontSize={'12px'}
              fontWeight={600}
              lineHeight={'16px'}
              letterSpacing={'1px'}
              color={'#939393'}
              mb={'4px'}
            >
              Generated By
            </Typography>
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontSize={'15px'}
              fontWeight={600}
              lineHeight={'20px'}
              letterSpacing={'1px'}
              color={'#171717'}
              mb={'16px'}
            >
              {generated === null ? '-' : generated}
            </Typography>
            {/* --- Paid By --- */}
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontSize={'12px'}
              fontWeight={600}
              lineHeight={'16px'}
              letterSpacing={'1px'}
              color={'#939393'}
              mb={'4px'}
            >
              Paid By
            </Typography>
            <Typography
              fontSize={'15px'}
              fontWeight={600}
              lineHeight={'20px'}
              letterSpacing={'1px'}
              color={'#171717'}
              mb={'16px'}
            >
              {paid === null ? '-' : paid}
            </Typography>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
