import { Avatar, Box, Typography } from "@mui/material"
import { IconDetail } from "../../../../../../assets/commons";
import { TooltipsAction } from "../../../../../atoms";

const ListActivitiesTbody = ({ tbody }) => {
  return(
    <tbody>
      {tbody.map((item, idx) => (
        <tr key={idx}>

          {/* --- Employee --- */}
          <td
            style={{
              padding: '10px',
              textAlign: 'start',
              verticalAlign: 'middle',
            }}
          >
            <Box display={'flex'} alignItems={'center'}>
              <Avatar alt={item.employee.name} src={item.employee.profile_picture}/>
              <Typography
                fontFamily={'sans-serif'}
                fontStyle={'normal'}
                fontWeight={400}
                fontSize={'14px'}
                lineHeight={'130%'}
                color={'#171717'}
                ml={'10px'}
              >
                {item.employee.name}
              </Typography>
            </Box>
          </td>

          {/* --- Activities --- */}
          <td
            style={{
              padding: '10px 12px',
              textAlign: 'start',
              verticalAlign: 'middle',
            }}
          >
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontWeight={400}
              fontSize={'14px'}
              lineHeight={'130%'}
              color={'#171717'}
            >
              {item.activities}
            </Typography>
          </td>

          {/* --- IP Address --- */}
          <td
            style={{
              padding: '10px 12px',
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
          >
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontWeight={400}
              fontSize={'14px'}
              lineHeight={'130%'}
              color={'#171717'}
            >
              {item.ip_address}
            </Typography>
          </td>

          {/* --- Date --- */}
          <td
            style={{
              padding: '10px 12px',
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
          >
            <Typography
              fontFamily={'sans-serif'}
              fontStyle={'normal'}
              fontWeight={400}
              fontSize={'14px'}
              lineHeight={'130%'}
              color={'#171717'}
            >
              {item.date}
            </Typography>
          </td>

          {/* --- Action --- */}
          <td
            style={{
              padding: '10px 12px',
              alignItems: 'center',
              verticalAlign: 'middle',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* --- Detail --- */}
              <TooltipsAction
                title='Detail'
                open={false}
                arrow
                placement={'bottom-end'}
              >
                <IconDetail />
              </TooltipsAction>
            </Box>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default ListActivitiesTbody