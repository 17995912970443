import { Box, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  IconDescription,
  IconDetail,
  IconDocument,
  IconDelete,
} from "../../../../../../assets/commons";
import { handleAction } from "../../../../../../utils/function/action";
import { getCorrectionById } from "../../../../../../configs/axios/apiPath";
import { AttendanceCorrectionDetail } from "../../../../../../pages/timesheet/attandanceCorrection";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import DocumentUrl from "../../../../../../configs/url";
import { useSelector } from "react-redux";
import { getCompany } from "../../../../../../features/companies";
import {
  handleTooltipClose,
  handleTooltipOpen,
} from "../../../../../../utils/function/tooltips";
import {
  IconReview,
  IconApproved,
  IconVerified,
  IconRejected,
} from "../../../../../../assets/timesheet/AttendanceCorrection";
import { updateCorrectionStatusById } from "../../../../../../configs/axios/apiPath/fetchTimesheet";
import { ModalDelete } from "../../../../modal";
import { TooltipsAction } from "../../../../../atoms";

const AttendanceTbodyCorrection = ({ tbody, setData }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [idEmployee, setIdEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState([]);
  const company = useSelector(getCompany);
  const [idTooltipDelete, setIdTooltipDelete] = useState("");
  const [isTooltipDelete, setIsTooltipDelete] = useState(false);

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 220,
      fontSize: 14,
      textAlign: "left",
      marginLeft: "20px",
      background: "#FFFFFF",
      color: "#171717",
      boxShadow: "0px 4px 30px rgba(0,0,0, .1) ",
      padding: "10px",
      fontWeight: 400,
    },
  });

  const StatusIconMap = {
    100: <IconReview />,
    202: <IconApproved />,
    200: <IconVerified />,
    301: <IconRejected />,
  };

  // --- Load Detail ---
  useEffect(() => {
    async function fetchData() {
      if (idEmployee !== null) {
        setIsLoading(true);
        try {
          const {
            data: { data },
          } = await getCorrectionById(idEmployee, { company_id: company });
          setDetail(data);
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [idEmployee]);

  // --- Handle Reject ---
  const handleReject = async (event) => {
    event.preventDefault();
    const data = {
      company_id: company,
      status: event.currentTarget.value,
    };
    setIsLoading(true);
    try {
      const response = await updateCorrectionStatusById(data, detail?.id);

      if (response.data.meta.code === 20000) {
        setIsModalOpen(false);
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerified = async (event) => {
    event.preventDefault();
    const data = {
      company_id: company,
      status: event.currentTarget.value,
    };
    setIsLoading(true);
    try {
      const response = await updateCorrectionStatusById(data, detail?.id);

      if (response.data.meta.code === 20000) {
        setIsModalOpen(false);
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        localStorage.removeItem("cvSayaLogin");
        navigate(`/users`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <tbody>
        {tbody.map((item, idx) => (
          <tr key={idx}>
            {/* --- Employee Name --- */}
            <td
              style={{
                padding: "10px",
                textAlign: "start",
                verticalAlign: "middle",
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"start"}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <img
                  src={item?.employee?.profile_picture}
                  alt={item?.employee?.name}
                  width={30}
                  height={30}
                  style={{ borderRadius: "50%" }}
                />
                <Box ml={"10px"}>
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={400}
                    fontSize={"14px"}
                    lineHeight={"130%"}
                    color={"#171717"}
                  >
                    {item.employee?.name}
                  </Typography>
                  <Typography
                    fontFamily={"sans-serif"}
                    fontStyle={"normal"}
                    fontWeight={600}
                    fontSize={"10px"}
                    lineHeight={"11px"}
                    letterSpacing={"0.01em"}
                    color={"#939393"}
                  >
                    {item?.department?.name}
                  </Typography>
                </Box>
              </Box>
            </td>
            {/* --- Approved By --- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item?.approved_by?.name ?? "-"}
              </Typography>
            </td>
            {/* --- Type --- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {(item?.type && (item?.type).toUpperCase().replace("_", " ")) ??
                  "-"}
              </Typography>
            </td>
            {/* --- Hour --- */}
            {/* <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {item?.hour}
              </Typography>
            </td> */}
            {/* --- Correction Date --- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {dayjs(item?.correction_date).format("DD MMM")}
              </Typography>
            </td>
            {/* --- Description--- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              <Box
                sx={{
                  cursor: "pointer",
                }}
              >
                <CustomWidthTooltip followCursor title={item?.description}>
                  <IconDescription />
                </CustomWidthTooltip>
              </Box>
            </td>
            {/* --- Document --- */}
            <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              {/* <img
                src={DocumentUrl(item?.document?.id)}
                alt="doc"
                width={50}
                height={50}
              /> */}
              {item?.document?.id !== null ? <IconDocument /> : null}
            </td>

            <td
              style={{
                padding: "10px 12px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              {" "}
              <Typography
                fontFamily={"sans-serif"}
                fontStyle={"normal"}
                fontWeight={400}
                fontSize={"14px"}
                lineHeight={"130%"}
                color={"#171717"}
              >
                {StatusIconMap[item.status]}
              </Typography>
            </td>
            {/* --- Action --- */}
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={1}
              >
                {/* --- Detail --- */}
                <CustomWidthTooltip followCursor title="Detail">
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        "detail"
                      )
                    }
                  >
                    <IconDetail />
                  </Box>
                </CustomWidthTooltip>

                {/* --- Delete --- */}
                <TooltipsAction
                  title="Delete"
                  open={isTooltipDelete && item.id === idTooltipDelete}
                  arrow
                  placement={"bottom-end"}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAction(
                        item.id,
                        setIsModalOpen,
                        setModal,
                        setIdEmployee,
                        "delete"
                      )
                    }
                    onMouseEnter={() =>
                      handleTooltipOpen(
                        item.id,
                        setIsTooltipDelete,
                        setIdTooltipDelete
                      )
                    }
                    onMouseLeave={() => handleTooltipClose(setIsTooltipDelete)}
                  >
                    <IconDelete />
                  </Box>
                </TooltipsAction>
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
      {/* --- Modal Handle --- */}
      {idEmployee !== null && modal === "detail" ? (
        <AttendanceCorrectionDetail
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          detail={detail}
          isLoading={isLoading}
          setData={setData}
          handleVerified={handleVerified}
          handleReject={handleReject}
        />
      ) : modal === "delete" ? (
        <ModalDelete
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={"Attendance Correction"}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          id={idEmployee}
          data={tbody}
          setData={setData}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default AttendanceTbodyCorrection;
