import { Box } from "@mui/material"
import { Card, Pagination, RowPerPage, Search } from "../../../atoms"
import TableComponent from "../../../molecules/table/mainTable"
import { useEffect, useState } from "react"
import SelectYear from "../../../molecules/year"
import moment from 'moment'
import { ListActivitiesTbody, ListActivitiesThead } from "../../../molecules/table/tableProject/listActivities"
import DataTableListActivities from "../../../../data/table/project/listActivities"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { getCompany } from "../../../../features/companies"
import { getActivity } from "../../../../configs/axios/apiPath/fetchProject/fetchActivity"

const SummaryTableActivities = () => {
  const navigate = useNavigate();
  const company = useSelector(getCompany);
  const yearNow = moment().format('YYYY');
  const year = [];

  const [selectedYear, setSelectedYear] = useState(yearNow);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [lastPage, setLastPage] = useState();
  const [keyword, setKeyword] = useState("");
  const [dataActivities, setDataActivities] = useState([]);

  for (let i = yearNow; i >= yearNow - 10; i--) {
    year.push(i);
  }

  useEffect(() => {
    async function fetchData() {
      if(company){
        const params = {
          company_id : company,
          page: page,
          page_size: pageSize,
          year: selectedYear,
          search: keyword,
        };
        setIsLoading(true)
        try {
          const {
            data: {data}
          } = await getActivity(params);
          setDataActivities(data?.activities)
          setLastPage(data?.page_info?.last_page)
        } catch (error) {
          console.error(error.response.status);
          if (error.response.status === 401) {
            localStorage.removeItem("cvSayaLogin");
            navigate(`/users`);
          }         
        } finally {
          setIsLoading(false)
        }
      }
    }
    fetchData()

  },[company, page, pageSize, yearNow, keyword, navigate, selectedYear])

  return(
    <Card sx={{ padding: '20px  25px', mt: '20px' }}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Box display={'flex'}>
          {/* --- Search --- */}
          <Search
            placeholder="Type here to search"
            page={page}
            setPage={(e) => setPage(e)}
            setSearch={(e) => setKeyword(e)}
          />
        </Box>
        <SelectYear
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          year={year}
        />
      </Box>

      {/* --- Table --- */}
      <Box sx={{ mt: '24px', mb: '16px' }}>
        <TableComponent
          isLoading={isLoading}
          tbody={dataActivities}
          TableHead={
            <ListActivitiesThead
                thead={DataTableListActivities}
            />
          }
          TableBody={
            <ListActivitiesTbody
              tbody={dataActivities} 
            />
          }
          col={5}
        />
      </Box>
    
      {/* --- Pagination --- */}
      <Box display={'flex'} justifyContent={'end'}>
        <RowPerPage
          setPageSize={setPageSize}
          pageSize={pageSize}
          setPage={setPage}
        />
        <Pagination page={page} setPage={setPage} lastPage={lastPage}/>
      </Box>
    </Card>
  )
}

export default SummaryTableActivities