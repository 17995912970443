const Template = {
  memo: `<p>
  <strong>INTERNAL MEMO</strong>
</p>
<p>
  <strong>[NOMOR]/HRD/${
    new Date().getMonth() + 1
  }/${new Date().getFullYear()}</strong>
</p>
<p>&nbsp;</p>
<p>Kepada&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : <strong>Karyawan PT.[NAMA PERUSAHAAN]&nbsp;</strong>
</p>
<p>Perihal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : <strong>[PERIHAL]</strong>
</p>
<p>&nbsp;</p>
<p>Sehubungan dengan [Perihal], dengan ini disampaikan beberapa hal sebagai berikut &nbsp;:</p>
<p>1. Informasi 1</p>
<p>2. Informasi 2</p>
<p>3. Informasi 3</p>
...
<p>&nbsp;</p>
<p>Demikian informasi diatas untuk dapat dijalankan bersama.</p>
<p>&nbsp;</p>
<p>Medan, ${new Date().toLocaleDateString("id-ID", {
    day: "numeric",
    month: "long",
    year: "numeric",
  })}</p>`,
};

export default Template;
