import { Box, Typography } from "@mui/material";
import { IconApprovedBy } from "../../../../assets/coreHr/permission";
import moment from "moment";
import { useSelector } from "react-redux";
import { getLanguage } from "../../../../features/language";
import DocumentUrl from "../../../../configs/url";

export default function DoneStep({ detail }) {
  const lang = useSelector(getLanguage);

  return (
    <>
      {/* --- Permission Information --- */}
      <Box mt={"10px"} noValidate autoComplete="off">
        <Typography
          fontFamily={"sans-serif"}
          fontSize={"13px"}
          fontWeight={600}
          lineHeight={"22px"}
          color={"#171717"}
        >
          {lang === "Id" ? "Informasi Perizinan" : "Permission Information"}
        </Typography>{" "}
      </Box>
      <Box mt={"10px"} noValidate autoComplete="off">
        {/* --- Title --- */}
        <Box
          mb={"20px"}
          p={"0px"}
          sx={{
            position: "relative",
          }}
        >
          <Typography
            fontFamily={"sans-serif"}
            fontStyle={"normal"}
            fontSize={"13px"}
            fontWeight={600}
            lineHeight={"18px"}
            letterSpacing={"1px"}
            color={"#171717"}
            mb={"6px"}
          >
            {lang === "Id" ? "Karyawan" : "Employee"}
          </Typography>
          <Box
            style={{
              width: "100%",
              background: "#f7f7f7",
              borderRadius: "4px",
              border: "1px solid transparent",
              display: "inline-block",
            }}
          >
            <Typography mx={"10px"} py={"10px"}>
              {detail?.employee?.name}
            </Typography>
          </Box>
        </Box>

        {/* --- Type --- */}
        <Box
          mb={"20px"}
          p={"0px"}
          sx={{
            position: "relative",
          }}
        >
          <Typography
            fontFamily={"sans-serif"}
            fontStyle={"normal"}
            fontSize={"13px"}
            fontWeight={600}
            lineHeight={"18px"}
            letterSpacing={"1px"}
            color={"#171717"}
            mb={"6px"}
          >
            {lang === "Id" ? "Tipe" : "Type"}
          </Typography>
          <Box
            style={{
              width: "100%",
              background: "#f7f7f7",
              borderRadius: "4px",
              border: "1px solid transparent",
              display: "inline-block",
            }}
          >
            <Typography mx={"10px"} py={"10px"}>
              {detail?.occasion}
            </Typography>
          </Box>
        </Box>

        {/* --- Date --- */}
        <Box
          mb={"20px"}
          p={"0px"}
          sx={{
            position: "relative",
          }}
        >
          <Typography
            fontFamily={"sans-serif"}
            fontStyle={"normal"}
            fontSize={"13px"}
            fontWeight={600}
            lineHeight={"18px"}
            letterSpacing={"1px"}
            color={"#171717"}
            mb={"6px"}
          >
            {lang === "Id" ? "Tanggal" : "Date"}
          </Typography>
          <Box
            style={{
              width: "100%",
              background: "#f7f7f7",
              borderRadius: "4px",
              border: "1px solid transparent",
              display: "inline-block",
            }}
          >
            <Typography mx={"10px"} py={"10px"}>
              {moment(detail?.start_date).format("DD MMM YY")} {"- "}
              {moment(detail?.end_date).format("DD MMM YY")}
            </Typography>
          </Box>
        </Box>

        {/* --- Reason --- */}
        <Box
          mb={"20px"}
          p={"0px"}
          sx={{
            position: "relative",
          }}
        >
          <Typography
            fontFamily={"sans-serif"}
            fontStyle={"normal"}
            fontSize={"13px"}
            fontWeight={600}
            lineHeight={"18px"}
            letterSpacing={"1px"}
            color={"#171717"}
            mb={"6px"}
          >
            {lang === "Id" ? "Deskripsi" : "Description"}
          </Typography>
          <Box
            style={{
              width: "100%",
              background: "#f7f7f7",
              borderRadius: "4px",
              border: "1px solid transparent",
              display: "inline-block",
            }}
          >
            <Typography
              mx={"10px"}
              py={"10px"}
              height={"auto"}
              maxHeight={"160px"}
              sx={{ overflowY: "scroll" }}
            >
              {detail?.description}
            </Typography>
          </Box>
        </Box>

        <Box
          mb={"20px"}
          p={"0px"}
          sx={{
            position: "relative",
          }}
        >
          <Typography
            fontFamily={"sans-serif"}
            fontStyle={"normal"}
            fontSize={"13px"}
            fontWeight={600}
            lineHeight={"18px"}
            letterSpacing={"1px"}
            color={"#171717"}
            mb={"6px"}
          >
            {lang === "Id" ? "Dokumen" : "Document"}
          </Typography>
          <img
            src={DocumentUrl(
              detail?.documents?.length > 0 ? detail?.documents[0]?.id : null
            )}
            alt={detail?.name}
            width={"100%"}
            height={198}
          />
        </Box>
      </Box>

      <Box>
        <hr color={"#F1F1F1"} />
        <Box mt={"20px"} noValidate autoComplete="off">
          <Typography
            fontFamily={"sans-serif"}
            fontSize={"13px"}
            fontWeight={600}
            lineHeight={"22px"}
            color={"#171717"}
          >
            {lang === "Id" ? "Task Assignment" : "Task Assignment"}
          </Typography>
        </Box>
        <Box mt={"10px"} component={"form"} noValidate autoComplete="off">
          {/* --- Task Assigned to --- */}
          <Box
            mb={"20px"}
            p={"0px"}
            sx={{
              position: "relative",
            }}
          >
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"18px"}
              letterSpacing={"1px"}
              color={"#171717"}
              mb={"6px"}
            >
              {lang === "Id" ? "Tugas Diberikan Kepada" : "Task assigned to"}
            </Typography>
            <Box
              style={{
                width: "100%",
                background: "#f7f7f7",
                borderRadius: "4px",
                border: "1px solid transparent",
                display: "inline-block",
              }}
            >
              <Typography mx={"10px"} py={"10px"}>
                {detail?.assignment_by === null
                  ? "-"
                  : detail?.assignment_by?.name}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <hr color={"#F1F1F1"} />
      <Box mt={"10px"} component={"form"} noValidate autoComplete="off">
        {/* --- Status --- */}
        <Box
          mb={"20px"}
          p={"0px"}
          sx={{
            position: "relative",
          }}
        >
          <Typography
            fontFamily={"sans-serif"}
            fontStyle={"normal"}
            fontSize={"13px"}
            fontWeight={600}
            lineHeight={"18px"}
            letterSpacing={"1px"}
            color={"#171717"}
            mb={"6px"}
          >
            {lang === "Id" ? "Status" : "Status"}
          </Typography>
          <Box
            style={{
              width: "100%",
              background: "#f7f7f7",
              borderRadius: "4px",
              border: "1px solid transparent",
              display: "inline-block",
            }}
          >
            <Typography mx={"10px"} py={"10px"}>
              {detail?.status}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* --- Verified By / Rejected By --- */}
      <Box mt={"10px"} component={"form"} noValidate autoComplete="off">
        {/* --- Approved By / Rejected By --- */}
        {detail?.status === "done" && (
          <Box
            mb={"20px"}
            p={"0px"}
            sx={{
              position: "relative",
            }}
          >
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"18px"}
              letterSpacing={"1px"}
              color={"#171717"}
              mb={"6px"}
            >
              {lang === "Id" ? "Diverifikasi Oleh" : "Verified By"}
            </Typography>
            <Box
              style={{
                width: "100%",
                background: "#f7f7f7",
                borderRadius: "4px",
                border: "1px solid transparent",
                display: "inline-block",
              }}
            >
              <Typography mx={"10px"} py={"10px"}>
                {detail?.map_statuses?.length === 4
                  ? detail?.map_statuses[2]?.employee?.name
                  : null}{" "}
                <IconApprovedBy />
              </Typography>
            </Box>
          </Box>
        )}

        {detail?.status === "verified" && (
          <Box
            mb={"20px"}
            p={"0px"}
            sx={{
              position: "relative",
            }}
          >
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"18px"}
              letterSpacing={"1px"}
              color={"#171717"}
              mb={"6px"}
            >
              {lang === "Id" ? "Diverifikasi Oleh" : "Verified By"}
            </Typography>
            <Box
              style={{
                width: "100%",
                background: "#f7f7f7",
                borderRadius: "4px",
                border: "1px solid transparent",
                display: "inline-block",
              }}
            >
              <Typography mx={"10px"} py={"10px"}>
                {detail?.map_statuses?.length === 4
                  ? detail?.map_statuses[2]?.employee?.name
                  : null}{" "}
                <IconApprovedBy />
              </Typography>
            </Box>
          </Box>
        )}

        {detail?.map_statuses?.length === 3 && (
          <Box
            mb={"20px"}
            p={"0px"}
            sx={{
              position: "relative",
            }}
          >
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"18px"}
              letterSpacing={"1px"}
              color={"#171717"}
              mb={"6px"}
            >
              {detail?.map_statuses?.length === 3 &&
              detail?.map_statuses[2]?.status === "rejected"
                ? "Rejected By"
                : "Approved By"}
            </Typography>
            <Box
              style={{
                width: "100%",
                background: "#f7f7f7",
                borderRadius: "4px",
                border: "1px solid transparent",
                display: "inline-block",
              }}
            >
              <Typography mx={"10px"} py={"10px"}>
                {detail?.map_statuses?.length === 3
                  ? detail?.map_statuses[2]?.employee?.name
                  : null}{" "}
                <IconApprovedBy />
              </Typography>
            </Box>
          </Box>
        )}

        {detail?.map_statuses?.length === 2 && (
          <Box
            mb={"20px"}
            p={"0px"}
            sx={{
              position: "relative",
            }}
          >
            <Typography
              fontFamily={"sans-serif"}
              fontStyle={"normal"}
              fontSize={"13px"}
              fontWeight={600}
              lineHeight={"18px"}
              letterSpacing={"1px"}
              color={"#171717"}
              mb={"6px"}
            >
              Rejected By
            </Typography>
            <Box
              style={{
                width: "100%",
                background: "#f7f7f7",
                borderRadius: "4px",
                border: "1px solid transparent",
                display: "inline-block",
              }}
            >
              <Typography mx={"10px"} py={"10px"}>
                {detail?.map_statuses?.length === 2
                  ? detail?.map_statuses[1]?.employee?.name
                  : null}{" "}
                <IconApprovedBy />
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
