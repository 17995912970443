const countSizeOfFiles = (data) => {
  const totalSizeInBytes = data?.reduce((total, item) => {
    const sizeInMb = parseFloat(item.size.split(" ")[0]);
    return total + sizeInMb
  }, 0)
  
  const totalSizeInMB = (totalSizeInBytes).toFixed(2);
  return parseFloat(totalSizeInMB);
}

export default countSizeOfFiles;